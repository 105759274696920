import { ISchemas } from '../../interfaces/schemas.interface';
import { IComponentController } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMultiOccurrenceTemplate } from '../ex-multi-occurrence-template/ex-multi-occurrence-template.controller';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';

interface IDataValueList extends IComponentController {
    cols: Array<string>;
    separateur: string;
    data: any;
    context: any;
    libelles: any;
    schemas: ISchemas;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    multiOccurrenceTemplateCtrl: IComposantMultiOccurrenceTemplate;
    dataSourceCtrl: IDataSourceController;
}

/* @ngInject */
export default function DataValueListController(defaults: IDefaultsService) {
    const vm: IDataValueList = this;

    vm.$onInit = function $onInit() {
        defaults(vm, {
            separateur: ", ",
            hideVide: true
        });
    }

    vm.afficherValeur = function afficherValeur(col: string): boolean {
        return Boolean(!vm.hideVide || (vm.data || (vm.dataSourceCtrl && vm.dataSourceCtrl.data) || (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data))[col]);
    }
}
