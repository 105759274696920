import {module} from 'angular';
import {IDataType, IDataTypeOptions} from './data-type.service';
import {IBoutonRadioDataTypeClass} from './bouton-radio-data-type.service';

export interface IFavorisDataTypeClass {
    new(options: IFavorisDataTypeOptions): IFavorisDataType;
}

export interface IFavorisDataTypeOptions extends IDataTypeOptions {
}

export interface IFavorisDataType extends IDataType {
}

export default module('core.services.favoris-data-type', []).factory('FavorisDataType', FavorisDataTypeFactory);

/* @ngInject */
function FavorisDataTypeFactory(BoutonRadioDataType: IBoutonRadioDataTypeClass): IFavorisDataTypeClass {
    class FavorisDataType extends BoutonRadioDataType implements IFavorisDataType {
        readonly template: string = `<ex-favoris data="vm.data" data-type="::vm.dataType"></ex-favoris>`;
    }
    return FavorisDataType;
}
