import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-titre', [])
    .component('exTitre', TitreFactory());

function TitreFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.LIBELLES,
            Binding.ICON.OPTIONAL,
            {
                lblTitre: '@?',
                noBorder: '<?',
                classeCouleur: '@?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence'
        },
        controllerAs: 'vm',
        template: require('./ex-titre.html')
    };
}
