/**
 * Ce filtre affiche la description d'une valeur de bouton RADIO
 */
import {module} from 'angular';
import {IDataType} from '../services/data-types/data-type.service';
import {IFilterLibelle} from './ex-libelle.filter';

export default module('core.filters.ex-radio', []).filter('exRadio', radioFilter);

export interface IFilterExRadio {
    (col: string, data: any, dataType: IDataType, libelles: any): string;
}

/* @ngInject */
function radioFilter(exLibelleFilter: IFilterLibelle): IFilterExRadio {
    return function (col: string, data: any, dataType: IDataType, libelles: any): string {
        const valeur = data[col];
        const boutonRadio = dataType.params.boutonsRadio.find((boutonRadio: {libelle: string, valeur: string}) => {
            return (boutonRadio.valeur === valeur);
        });

        if (boutonRadio) {
            return exLibelleFilter(boutonRadio.libelle, libelles);
        } else {
            return '';
        }
    };
}
