/**
 Comportement permettant de valider le format d'une donnée à l'aide d'un masque passé en paramètre.

 Le masque est interprété selon la logique suivante:
 X: alpha
 9: numérique
 Tout autre caractère doit rester tel quel
 */

import {IAttributes, IAugmentedJQuery, INgModelController, IScope, module} from 'angular';

export default module('core.behaviors.ex-masque-validation', [])
    .directive('exMasqueValidation', MasqueValidationDirective);

interface IMasqueAttributes extends IAttributes {
    exMasqueValidation: string;
}

/* @ngInject */
function MasqueValidationDirective() {
    return {
        restrict: 'A',
        link,
        require: 'ngModel'
    };

    function link($scope: IScope, tElement: IAugmentedJQuery, attrs: IMasqueAttributes, ngModelCtrl: INgModelController) {
        if (!attrs.exMasqueValidation) {
            return;
        } else {
            const regexMasque = formatMasqueRegex(attrs.exMasqueValidation);

            //On ajoute la validation du masque au champ.
            ngModelCtrl.$validators['pattern'] = ($modelValue, $viewValue) => {
                const value = $modelValue || $viewValue;

                if (!value) {
                    return true;
                } else {
                    return regexMasque.test(value);
                }
            };
        }
    }

    function formatMasqueRegex(masque: string): RegExp {
        let expression = '^';
        for (const symbole of masque) {
            if (symbole === '9') {
                expression += "[0-9]";
            } else if (symbole === 'X') {
                expression += "[a-zA-Z]";
            } else {
                expression += `\\${symbole}`;
            }
        }
        return RegExp(expression + "$");
    }
}