import {IComponentOptions, module} from 'angular';
import TranscludeTemplateController from './ex-transclude-template.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-transcludeTemplate', [
]).component('exTranscludeTemplate', TranscludeTemplateFactory());


function TranscludeTemplateFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                bloc: '@?',
                slot: '@',
                scope: '<?'
            }
        ),
        require: {
            templateCtrl: '?^exTemplate'
        },
        controller: TranscludeTemplateController,
        controllerAs: 'vm'
    };
}
