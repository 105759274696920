import {IComponentController, IScope} from 'angular';
import {IApiConfig} from '../../interfaces/api-config.interface';
import {IMultiOccurrence, IMultiOccurrenceClass} from '../../services/multi-occurrence.service';
import {IPaginationClass} from '../../services/pagination.service';
import {IDataType} from '../../services/data-types/data-type.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IComposantSelectionMultiple extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence;
    resourceParams: any;
    valueMap: any
    dataType: IDataType;
    groups: Array<any>;
    readonly: boolean;
    srccodref: string;
    updateSelection(data: any): void;
    getValue(data: any): any;
    retryErreur(): void;
    isLoading(): boolean;
}

/* @ngInject */
export default function SelectionMultipleController($scope: IScope,
                                                    MultiOccurrence: IMultiOccurrenceClass,
                                                    ApiConfig: IApiConfig,
                                                    Pagination: IPaginationClass) {
    const vm: IComposantSelectionMultiple = this;

    vm.$onInit = $onInit;
    vm.updateSelection = updateSelection;
    vm.getValue = getValue;
    vm.retryErreur = retryErreur;
    vm.isLoading = isLoading;

    function $onInit() {
        initListe();
    }

    function initListe() {
        const OPTIONS_PAR_PAGE = 100;

        if (vm.resourceParams instanceof Function)  {
            vm.resourceParams = vm.resourceParams();
        }

        const colonnesVisibles = [
            ...vm.dataType.params.description,
            vm.dataType.params.valeur
        ].filter((col: string) => col);

        const colonnesCachees = [vm.dataType.params.groupBy];

        vm.multiOccurrence = new MultiOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            resourceParams: {
                ...vm.resourceParams,
                srccod: vm.srccodref
            },
            srccod: `lov-${vm.dataType.params.source}`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            colonnesVisibles: Array.from(new Set(colonnesVisibles)),
            colonnesCachees,
            pagination: new Pagination({
                pageCourante: 1,
                nombreElementPage: OPTIONS_PAR_PAGE
            }),
            fonctions: {
                filtrer: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                selectionnerUnEtat: false,
                exportation: false,
                importation: false,
                nouveau: false
            }
        });

        initValue();
    }

    function initValue() {
        vm.multiOccurrence.once('ready', () => {
            $scope.$watch('vm.value', () => {
                updateValueMap();
            });
        });
    }

    function updateValueMap() {
        vm.valueMap = vm.value.reduce((map: any, data: any) => {
            const id = data[vm.multiOccurrence.cleint];
            map[id] = true;
            return map;
        }, {});
    }

    function updateSelection() {
        vm.value = Object.keys(vm.valueMap)
            .filter((id) => vm.valueMap[id])
            .map((id: any) => {
                const data = vm.multiOccurrence.dataList.find((data) => String(data[vm.multiOccurrence.cleint]) === id);
                return getValue(data);
            });
    }

    function getValue(data: any) {
        return {
            [vm.multiOccurrence.cleint]: data[vm.multiOccurrence.cleint]
        };
    }

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function isLoading(): boolean {
        return (
            (
                vm.multiOccurrence.initializing ||
                vm.multiOccurrence.fetchingDataList
            ) &&
            (
                !vm.multiOccurrence.initError &&
                !vm.multiOccurrence.dataListError
            )
        );
    }
}
