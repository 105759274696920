import {ISelecteur} from '../../services/selecteur.service';
import {IMultiOccurrence} from '../../services/multi-occurrence.service';
import {IAugmentedJQuery, IComponentController} from 'angular';
import {applyAutoScroll} from '../../utils/apply-auto-scroll.utils';
import {IDefaultsService} from '../../services/utils/defaults.service';

interface IDialogSelecteur extends IComponentController {
    selecteur: ISelecteur;
    libelles: any;
    multiOccurrence: IMultiOccurrence;
    lblTitre: string;
    icon: string;
    lblDescription: string;
    lblSelecteurLeft: string;
    lblSelecteurRight: string;
    confirm(): ISelecteur;
}
/* @ngInject */
export default function DialogSelecteurController($element: IAugmentedJQuery,
                                                  defaults: IDefaultsService) {
    const vm: IDialogSelecteur = this;

    vm.$onInit = function $onInit() {
        applyAutoScroll([$element.find('.ex-dialog-content').get(0)]);

        //init
        vm.confirm = confirm;

        defaults(vm, {
            icon: 'view_column',
            lblTitre: 'G_LBL_MSL_MOD_SELECT_TITRE',
            lblDescription: 'G_LBL_MSL_MOD_SELECT_DESC',
            lblSelecteurLeft: 'G_LBL_MSL_CHAMP_DISPONIBLE',
            lblSelecteurRight: 'G_LBL_MSL_CHAMP_AFFICHE'
        });
    }

    function confirm(): ISelecteur {
        return vm.selecteur;
    }
}
