import {IComponentController, IScope} from 'angular';
import {IApiConfig} from '../../interfaces/api-config.interface';
import {IMultiOccurrence, IMultiOccurrenceClass} from '../../services/multi-occurrence.service';
import {IPaginationClass} from '../../services/pagination.service';
import {ILovBoutonRadioDataType} from '../../services/data-types/lov-bouton-radio-data-type.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IDefaultsService} from '../../services/utils/defaults.service';

export interface IComposantLovBoutonRadio extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence;
    resourceParams: any;
    col: string;
    dataType: ILovBoutonRadioDataType;
    readonly: boolean;
    widthImage: string;
    direction: string;
    srccodref: string;
    data: any;
    cleValeur: string;
    getUrlImage(id: number | string): string;
    retryErreur(): void;
    isLoading(): boolean;
    getLayout(): string;
    getSecurityResourceParams(): any;
}

/* @ngInject */
export default function LovBoutonRadioController($scope: IScope,
                                                 MultiOccurrence: IMultiOccurrenceClass,
                                                 ApiConfig: IApiConfig,
                                                 defaults: IDefaultsService,
                                                 Pagination: IPaginationClass) {
    const vm: IComposantLovBoutonRadio = this;

    vm.$onInit = $onInit;
    vm.retryErreur = retryErreur;
    vm.isLoading = isLoading;
    vm.getLayout = getLayout;
    vm.getUrlImage = getUrlImage;
    vm.getSecurityResourceParams = getSecurityResourceParams;

    function $onInit() {
        defaults(vm, {
            titre: vm.col
        });
        initListe();
    }

    function initListe() {
        const OPTIONS_PAR_PAGE = 100;

        if (vm.resourceParams instanceof Function) {
            vm.resourceParams = vm.resourceParams();
        }

        const colonnesVisibles = [
            ...vm.dataType.params.description,
            ...(vm.dataType.params.details || []),
            vm.dataType.params.valeur
        ].filter((col: string) => col);

        const colonnesCachees = [vm.dataType.params.groupBy];

        vm.multiOccurrence = new MultiOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            resourceParams: {
                ...vm.resourceParams,
                srccod: vm.srccodref
            },
            srccod: `lov-${vm.dataType.params.source}`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            colonnesVisibles: Array.from(new Set(colonnesVisibles)),
            colonnesCachees,
            pagination: new Pagination({
                pageCourante: 1,
                nombreElementPage: OPTIONS_PAR_PAGE
            }),
            fonctions: {
                filtrer: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                selectionnerUnEtat: false,
                exportation: false,
                importation: false,
                nouveau: false
            }
        });

        vm.multiOccurrence.once('ready', () => {
           vm.cleValeur = getCleValeur();
        });

        if (vm.col) {
            vm.dataType.params.description.forEach((col) => {
                vm.data[`${vm.col}__${col}`] = vm.data[`${vm.col}__${col}`];
            });
            $scope.$watch('vm.data[vm.col]', (newVal, oldVal) => {
                if (newVal != oldVal) {
                    vm.dataType.params.description.forEach((col) => {
                        const valeur = vm.multiOccurrence.dataList.find((data) => data[vm.cleValeur] === newVal);
                        vm.data[`${vm.col}__${col}`] = valeur[col];
                    });
                }
            })
        }
    }

    function getUrlImage(data: any): string {
        if (vm.dataType.image) {
            vm.widthImage = vm.dataType.image.width || '100%';
            const colId = vm.dataType.image.colId;
            const colImage = vm.dataType.image.blob;
            const colExtension = vm.dataType.image.ext;
            return `${ApiConfig.ROOT}/image/${vm.multiOccurrence.getNomSourceDetails()}/${data[colId]}/${colImage}/${colExtension}`;
        }
    }

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function getSecurityResourceParams(): any {
        return {
            srccodref: vm.srccodref
        }
    }

    function isLoading(): boolean {
        return (
            (
                vm.multiOccurrence.initializing ||
                vm.multiOccurrence.fetchingDataList
            ) &&
            (
                !vm.multiOccurrence.initError &&
                !vm.multiOccurrence.dataListError
            )
        );
    }

    function getLayout(): string {
        if (vm.direction) {
            if (vm.direction === 'horizontal') {
                return 'row';
            } else {
                return 'column';
            }
        }
    }

    function getCleValeur(): string {
        return vm.dataType.params.valeur || vm.multiOccurrence.cleint;
    }
}
