import * as angular from 'angular';
import {module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';
import IStateService = angular.ui.IStateService;
import { IRouter } from '../../providers/router.provider';
import {IProfil} from '../../resources/profil.resource';

export interface IMenuItemForageClass {
    new(pageNom: string, parametresGetter?: IMenuItemForageParametresGetter, option?: IMenuItemForageOptions): IMenuItemForage;
    new(lblTitre: string, pageNom: string|IMenuItemForagePageNomFonction, parametresGetter?: IMenuItemForageParametresGetter, option?: IMenuItemForageOptions): IMenuItemForage;
}

interface IMenuItemForageParametresGetter {
    (data: any): object;
}

interface IMenuItemForagePageNomFonction {
    (data?: any): string;
}

export interface IMenuItemForage extends IMenuItem {}

export interface IMenuItemForageOptions extends IMenuItemOptions{}

export default module('core.services.menu-item-forage', []).factory('MenuItemForage', MenuItemForageFactory);

/* @ngInject */
function MenuItemForageFactory(MenuItem: IMenuItemClass,
                               $state: IStateService,
                               Router: IRouter,
                               profil: IProfil) {

    class MenuItemForage extends MenuItem implements IMenuItemForage {
        constructor() {
            const hasOptions = arguments.length >= 3 && !(arguments[arguments.length - 1] instanceof Function);
            let lblTitre: string;
            let pageNom: string|IMenuItemForagePageNomFonction;
            let parametresGetter: IMenuItemForageParametresGetter;
            let options: any;
            let texte: string;
            let fonction: Function;

            switch (arguments.length) {
                case 1:
                    pageNom = arguments[0];
                    break;
                case 2:
                    if (typeof arguments[1] === 'string') {
                        lblTitre = arguments[0];
                        pageNom = arguments[1];
                    } else {
                        texte = arguments[0];
                        fonction = arguments[1];
                    }
                    break;
                case 3:
                    if (hasOptions) {
                        pageNom = arguments[0];
                        parametresGetter = arguments[1];
                        options = arguments[2];
                    } else {
                        lblTitre = arguments[0];
                        pageNom = arguments[1];
                        parametresGetter = arguments[2];
                    }
                    break;
                case 4:
                    lblTitre = arguments[0];
                    pageNom = arguments[1];
                    parametresGetter = arguments[2];
                    options = arguments[3];
                    break;
            }

            // Par défaut, on retourne un objet vide
            options = options || {};
            lblTitre = lblTitre || '';

            if (!options.directionTooltip && options.iconButton) {
                const icon = options.icon instanceof Function ? options.icon({}) : options.icon;
                if (icon === 'create' || icon === 'edit') {
                    lblTitre = 'G_LBL_BTN_EDIT';
                } else if (icon === 'add_circle') {
                    lblTitre = 'G_LBL_BTN_NOUVEAU';
                } else {
                    lblTitre = 'G_LBL_BTN_OUVRIR';
                }

                options.directionTooltip = 'bottom';
            }

            super(lblTitre, (data: any, stateParams: any, goToPageDirectly: boolean = false) => {
                let parametres: any;
                let newPageNom: string;
                if (!pageNom) {
                    const fonctionReturnValue = fonction(data);
                    if (typeof fonctionReturnValue === 'string') {
                        newPageNom = fonctionReturnValue;
                        parametres = {};
                    } else {
                        newPageNom = texte;
                        parametres = fonctionReturnValue;
                    }
                } else if (pageNom instanceof Function) {
                    newPageNom = pageNom(data || {});
                }

                parametres = (typeof parametres === 'undefined' && parametresGetter) ?
                    (parametresGetter((data || {})) || {}) : (parametres || {});

                newPageNom = newPageNom || <string>pageNom;
                parametres.menuId = parametres.menuId || stateParams.menuId;

                if (!parametres.menuId) {
                    //Permet les forages à partir de la page d'accueil en se basant sur le premier item de menu disponible
                    const premierMenu = profil.profilsAcces[0].menus.find((menu) => menu.medcleintref);
                    const menucleref = [premierMenu.vaesysenttrtmen, premierMenu.mencleint, premierMenu.vaesysenttrt, premierMenu.medcleintref].join(',');
                    parametres.menuId = `${profil.profilsAcces[0].pracleint}-${menucleref}`;
                }

                if (!Router.isAnyRouteForName(newPageNom)) {
                    // Utilisation de console au lieu de $log pour pouvoir le voir en prod
                    console.warn("Tentative de forage vers un écran non converti pour le moment " + newPageNom);
                    return location.href;
                }
                if (goToPageDirectly) {
                    return $state.go(newPageNom, parametres, {inherit: false});
                } else {
                    return $state.href(newPageNom, parametres, {inherit: false});
                }
            }, {
                link: true,
                icon: 'exit_to_app',
                lblTitreValidation: 'G_LBL_FORAGE_IMPOSSIBLE',
                ...options
            });
        }
    }

    return MenuItemForage;
}
