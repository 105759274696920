import { IComponentOptions, module } from 'angular';
import EditionController from './ex-edition.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-edition', [
]).component('exEdition', DataFactory());

function DataFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA,
            Binding.OCCURRENCE.OPTIONAL,
            {
                formCtrl: '=?',
                formData: '=?',
                messagesErreur: '<?',
                lblTitre: '@',
                cancelAction: '&',
                saveAction: '&',
                saveAndNextAction: '&?',
                disableSave: '<?',
                editionOptions: '<?',
                showTitle: '<?',
                occurrenceEdition: '<?',
                isBoutonNouveau: '<?',
                cacherBoutonAnnuler: '<?',
                cacherBoutonClose: '<?',
                resetTitre: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence'
        },
        transclude: true,
        controller: EditionController,
        controllerAs: 'vm',
        template: require('./ex-edition.html')
    };
}
