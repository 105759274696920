import {IComponentOptions, IDirective, module} from 'angular';
import MonoOccurrenceEcranController from './ex-mono-occurrence-ecran.controller';
import Binding from '../../helpers/binding.helper';
import {ITemplateService} from '../../services/template.service';

export default module('core.components.ex-mono-occurrence-ecran', [])
    .directive('exMonoOccurrenceEcran', MonoOccurrenceEcranTemplateFactory)
    .component('exMonoOccurrenceEcran', MonoOccurrenceEcranFactory());


/* @ngInject */
function MonoOccurrenceEcranTemplateFactory(template: ITemplateService): IDirective {
    return template.directive;
}

function MonoOccurrenceEcranFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.FORMULAIRE,
            {
                monoOccurrenceOptions: '<?',
                monoOccurrence: '=?monoOccurrenceOut',
                largeurFormulaire: '<?',
                opened: '=?formulaireCardOpened',
                formCtrl: '=?',
                contentWidth: '<?',
                hasPanneauPage: '<?',
                messagesCycle: '=?messagesCycleOut',
                bandeauPortail: '<?',
                cycleHaut: '<?'
            }
        ),
        transclude: {
            texteAide: '?exMonoOccurrenceEcranTexteAide',
            maitre: '?exMonoOccurrenceEcranMaitre',
            gaucheEntete: '?exMonoOccurrenceEcranGaucheEntete',
            gauche: '?exMonoOccurrenceEcranGauche',
            droite: '?exMonoOccurrenceEcranDroite',
            haut: '?exMonoOccurrenceEcranHaut',
            bas: '?exMonoOccurrenceEcranBas',
            blocCreation: '?exMonoOccurrenceEcranCreation'
        },
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MonoOccurrenceEcranController,
        controllerAs: 'vm',
        template: require('./ex-mono-occurrence-ecran.html')
    };
}
