/**
 * Composant représentant un intervalle entre 2 champs du même type
 *
 */
import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import InputLovSelectionMultipleController from "./ex-input-lov-selection-multiple.controller";

export default module('app.components.ex-input-lov-selection-multiple', []).component('exInputLovSelectionMultiple', InputLovSelectionMultipleFactory());

function InputLovSelectionMultipleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.OCCURRENCE.OPTIONAL,
            Binding.VISIBLE,
            {
                date: '<',
                fonction: '@',
                simple: '<?',
                lblTitre: '@?',
                largeurModale: '@?',
                id: '<?',
                required: '<?ngRequired',
                resourceParams: '<?',
                nombreLibellesDetailsCacher: '<?',
                longueurFixe: '<?',
                srccodref: '@?',
                name: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            formCtrl: '^^form'
        },
        controllerAs: 'vm',
        controller: InputLovSelectionMultipleController,
        template: require('./ex-input-lov-selection-multiple.html')
    };
}
