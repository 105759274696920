import {IPromise, module} from 'angular';
import {ICycleFinanceClass} from '../resources/cycle-finance.resource';
import {ICycleRhClass, IMessageAvertissementRh} from '../resources/cycle-rh.resource';
import {IParametresSecuriteService} from './parametres-securite.service';
import {EventEmitter} from 'events';

export interface IMessagesCycleClass {
    new (options: IMessagesCycleServiceOptions): IMessagesCycle;
}

export interface IMessagesCycle extends EventEmitter {
    cycleCleint: string;
    id: any;
    messagesErreur: Array<string>;
    messagesAvertissement:  Array<IMessageAvertissementRh>;
    fetchingMessagesErreur: boolean;
    messagesErreurError: boolean;
    fetchingMessagesAvertissement: boolean;
    messagesAvertissementError: boolean;
    refreshMessages(srccod: string, stateParams: any): void;
}

export interface IMessagesCycleServiceOptions {
    cycleCleint: string;
    id: any;
}

export default module('core.services.messages-cycle', []).factory('MessagesCycle', MessagesCycleFactory);

/* @ngInject */
function MessagesCycleFactory(CycleFinance: ICycleFinanceClass,
                              CycleRh: ICycleRhClass,
                              parametresSecurite: IParametresSecuriteService): IMessagesCycleClass {
    class MessagesCycle extends EventEmitter implements IMessagesCycle {
        cycleCleint: string;
        id: number;
        messagesErreur: Array<string>;
        messagesAvertissement: Array<IMessageAvertissementRh>;
        fetchingMessagesErreur: boolean;
        messagesErreurError: boolean;
        fetchingMessagesAvertissement: boolean;
        messagesAvertissementError: boolean;

        constructor(options: IMessagesCycleServiceOptions) {
            super();
            this.cycleCleint = options.cycleCleint;
            this.id = options.id;
        }

        private fetchMessagesErreurCycleFinance(srccod: string, stateParams: any): IPromise<void> {
            const params = {
                doccleint: this.id,
                srccod,
                ...parametresSecurite(stateParams)
            };

            this.fetchingMessagesErreur = true;
            return CycleFinance.queryMessagesErreur(params).$promise
                .then((result: any) => {
                    this.messagesErreur = [result.msgErreur];
                    this.messagesErreurError = false;
                })
                .catch(() => {
                    this.messagesErreurError = true;
                })
                .finally(() => {
                    this.fetchingMessagesErreur = false;
                });
        }

        private fetchMessagesErreurCycleRh(srccod: string, stateParams: any): IPromise<void> {
            const trncleint = this.id;
            let employe;

            if (!trncleint) {
                employe = stateParams.employe;
            }

            const params = {
                trncleint,
                employe,
                srccod,
                ...parametresSecurite(stateParams)
            };

            this.fetchingMessagesErreur = true;
            return CycleRh.queryMessagesErreur(params).$promise
                .then((messagesErreur: Array<string>) => {
                    this.messagesErreur = messagesErreur;
                    this.messagesErreurError = false;
                })
                .catch(() => {
                    this.messagesErreurError = true;
                })
                .finally(() => {
                    this.fetchingMessagesErreur = false;
                    this.emit('messagesErreurUpdate');
                });
        }

        private fetchMessagesAvertissement(srccod: string, stateParams: any): IPromise<void> {
            const trncleint = this.id;
            let employe;

            if (!trncleint) {
                employe = stateParams.employe;
            }

            const params = {
                trncleint,
                employe,
                srccod,
                ...parametresSecurite(stateParams)
            };

            this.fetchingMessagesAvertissement = true;
            return CycleRh.queryMessagesAvertissement(params).$promise
                .then((messagesAvertissement: Array<any>) => {
                    this.messagesAvertissementError = false;
                    this.messagesAvertissement = messagesAvertissement;
                })
                .catch(() => {
                    this.messagesAvertissementError = true;
                })
                .finally(() => {
                    this.fetchingMessagesAvertissement = false;
                });
        }

        refreshMessages(srccod: string, stateParams: any) {
            if (this.cycleCleint === 'trncleint') {
                this.fetchMessagesErreurCycleRh(srccod, stateParams);
                this.fetchMessagesAvertissement(srccod, stateParams);
            }

            if (this.cycleCleint === 'doccleint') {
                this.fetchMessagesErreurCycleFinance(srccod, stateParams);
            }
        }
    }

    return MessagesCycle;
}
