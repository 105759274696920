import {IFilterNumber, module} from 'angular';

export default module('core.filters.ex-pad-start', []).filter('exPadStart', padStartFilter);

export interface IFilterExPadStart {
    (value: string, howMany: number, character: string, decimals: number): any;
}

/* @ngInject */
function padStartFilter(numberFilter: IFilterNumber): IFilterExPadStart {
    return (value: string, howMany: number, character: string, decimals: number): any => {
        if (value === null || typeof value === 'undefined') {
            return value;
        } else if (typeof decimals !== 'undefined') {
            const newValue = numberFilter(value, decimals);
            const longueur = howMany + 1 + decimals;//On doit compter le point et les décimales dans la longueur
            return String(newValue).padStart(longueur, character);
        } else {
            return String(value).padStart(howMany, character);
        }
    };
}
