import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantTabs } from '../ex-tabs/ex-tabs.controller';
import { IAugmentedJQuery, IComponentController, IScope, ITimeoutService } from 'angular';

export interface IComposantTab extends IComponentController {
    lblTitre: string;
    index: number;
    isInit: boolean;
    exTabsCtrl: IComposantTabs;
    ecranContextCtrl: IEcranContextController;
    flagAcces: boolean;
    bloc: string
    initAction(): void;
    getMultiTabs(dataList: any): void;
}

/* @ngInject */
export default function TabController($scope: IScope, $element: IAugmentedJQuery, defaults: IDefaultsService,$timeout: ITimeoutService) {
    const vm: IComposantTab = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.isInit = false;
        defaults(vm, {
            flagAcces: false
        });
        // On exécute l'action d'initialisation une seule fois, à la première ouverture
        if (vm.initAction) {
            const unregister = $scope.$watch('vm.exTabsCtrl.selected', (newValue) => {
                if (vm.index === newValue && !vm.isInit) {
                    vm.isInit = true;
                    vm.initAction();
                    unregister();
                }
            });
        }

        $scope.$watch('vm.exTabsCtrl.selected', (newValue) => {
            // On cache ou affiche l'élément lui-même
            $element.toggle(vm.index === newValue);
        });

        //on valide la sécurite par fonction afin d'éviter les nulls
        $timeout(() => {
            //s'il y a un bloc on valide l'acces sinon on affiche toujours le composant
            if (vm?.bloc) {
                const data = vm.exTabsCtrl.ecranContextCtrl.ecranDetails.fonctions[vm.bloc.toUpperCase()]
                if (data && data.flgacc === 1) {
                    vm.flagAcces = true
                }
            } else {
                vm.flagAcces = true
            }
        },0)
    }
}
