import {IHttpPromise, IHttpResponse, IHttpService, IPromise, module} from 'angular';
import {IDateLocalManager} from './date-locale-manager.service';
import {IApiConfig} from '../interfaces/api-config.interface';

export interface ILibellesClass {
    new(libelles: any): ILibelles;
    $libelles: any;
    libellesGlobaux: any;
    getLibelles(codeFilter: string): IHttpPromise<any>;
    initLibellesGlobaux(): any;
}

export interface ILibelles {
    $libelles: any;
    [key: string]: any
}

export default module('core.services.libelles', []).factory('Libelles', LibellesFactory);

/* @ngInject */
function LibellesFactory(ApiConfig: IApiConfig,
                         $http: IHttpService,
                         dateLocalManager: IDateLocalManager) {

    class Libelles implements ILibelles {
        $libelles: any;
        static libellesGlobaux: any = {};

        constructor(libelles: any) {
            Object.assign(this, libelles);
            this.$libelles = libelles;
        }

        public static initLibellesGlobaux(): any {
            return this.getLibelles().then((libelles: any)=> {
                if (libelles.APP_LBL_ONGLET) {
                    document.title = libelles.APP_LBL_ONGLET;
                }

                this.libellesGlobaux = libelles;
                dateLocalManager.initDateLocal(this.libellesGlobaux);
            });
        }

        public static getLibelles(): IPromise<ILibelles> {
            return $http.get(ApiConfig.URL + '/libelles')
                .then((response: IHttpResponse<any>) => new Libelles(response.data));
        }
    }

    return Libelles;
}
