/**
 * Ce comportement est une alternative au ng-disabled. L'élément à l'air désactivé, mais ne l'est pas réellement, ce qui
 * permet d'effectué une action, comme valider un formulaire.
 */
import {IAugmentedJQuery, IScope, module} from 'angular';

export default module('core.behaviors.ex-disabled', [])
    .directive('exDisabled', DisabledDirective);

/* @ngInject */
function DisabledDirective() {
    return {
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: any) {
        scope.$watch(attrs.exDisabled, (newValue: boolean) => {
            element.toggleClass('ex-disabled--disabled', Boolean(newValue));
            if (newValue) {
                element.attr('aria-disabled', 'aria-disabled');
                element.attr('md-ink-ripple', 'false');
            } else {
                element.removeAttr('aria-disabled');
                element.attr('md-ink-ripple', 'true');
            }
        });
    }
}
