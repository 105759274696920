import {IComponentOptions, module} from 'angular';
import ButtonMultiController from './ex-button-multi.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-button-multi', []).component('exButtonMulti', ButtonMultiFactory());

function ButtonMultiFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DISABLED,
            Binding.LIBELLES,
            {
                action: '<',
                classname: '@?class',
                offset: '@?',
                positionMode: '@?',
                busy: '<?',
                enregistrements: '<'
            }
        ),
        transclude: true,
        controller: ButtonMultiController,
        controllerAs: 'vm',
        template: require('./ex-button-multi.html')
    };
}
