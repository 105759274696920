import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IComponentController, IScope } from 'angular';
import { IFullscreen } from '../../services/fullscreen.service';

export interface IComposantCard extends IComponentController {
    lblTitre: string;
    bloc: string;
    fullscreen: IFullscreen;
    opened: boolean;
    fullscreenName: string;
    libelles: any;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    dataSourceCtrl: IDataSourceController;
    noHeaderBorder: boolean;
    contentClass: string;
    sticky: boolean;
    srccod?: any;
    openFullscreen(): void;
    getContentClass(): object;
}

/* @ngInject */
export default function CardController(defaults: IDefaultsService,
    fullscreen: IFullscreen,
    $scope: IScope) {
    const vm: IComposantCard = this;

    vm.$onInit = $onInit;
    vm.openFullscreen = openFullscreen;
    vm.getContentClass = getContentClass;

    function $onInit(): void {
        defaults(vm, {
            opened: true,
            collapsable: true,
            noHeader: false,
            srccod: (vm?.monoOccurrenceCtrl?.monoOccurrence?.srccod) ? vm.monoOccurrenceCtrl.monoOccurrence.srccod : "_"
        });

        vm.fullscreen = fullscreen;

        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);

        $scope.$on('exExpandableChange', () => {
            $scope.$broadcast('exGridReajusterLargeurColonne');
        });
    }

    function openFullscreen(): void {
        vm.fullscreen.open(vm.fullscreenName);
    }

    function getContentClass() {
        return Object.assign({
            'ex-card-content-sans-padding-top': vm.noHeaderBorder && !vm.lblTitre,
            'sticky-cycle-formulaire': (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrenceOptions && vm.monoOccurrenceCtrl.monoOccurrenceOptions.hideShadowBox) ? true : false
        }, vm.contentClass);
    }
}
