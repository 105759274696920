import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IAvisEmployesOptions {}

export interface IAvisEmployes {}

export interface IAvisEmployesResource extends IResourceClass<any> {
    new(data: IAvisEmployesOptions): IResource<IAvisEmployes>;
    getByEmploye(params: any): IResource<any>;
}

export default module('core.resources.avis-employes', [
    'core.services.api-config'
]).factory('AvisEmployesResource', AvisEmployesResourceFactory);

/* @ngInject */
function AvisEmployesResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IAvisEmployesResource {
    const params = {};

    const actions = {
        get: {
            url: `${ApiConfig.ROOT}/avis-employes/:srccod/:ids?`,
            method: 'get'
        },
        getByEmploye: {
            url: `${ApiConfig.ROOT}/avis-employes/:srccod/employe/:demcleint`,
            method: 'get'
        }
    };

    return <IAvisEmployesResource>$resource(`${ApiConfig.ROOT}/avis-employes`, params, actions);
}
