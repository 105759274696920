import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import PiecesJointesController from './ex-pieces-jointes.controller';

export default module('app.components.ex-pieces-jointes', []).component('exPiecesJointes', PiecesJointesFactory());

function PiecesJointesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            {
                monoOccurrence: '<',
                lblTitre: '@?',
                closeAction: '<?',
                compteur: '=',
                srccod: '@?',
                cleintref: '<?',
                cacherRecherche: '<?',
                resourceParams: '<?',
                consultationSeule: '<?',
                scrollToTop: '<?',
                multiOccurrence: '=?multiOccurrenceOut',
                ajoutRapide: '<?',
                saisieDescriptionCreation: '<?',
                allowsMultiple: '<?',
                collapsed: "<?"
            }
        ),
        require: {
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: PiecesJointesController,
        controllerAs: 'vm',
        template: require('./ex-pieces-jointes.html')
    };
}
