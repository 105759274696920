import {IAugmentedJQuery, IComponentController, ITimeoutService} from 'angular';
import {IMenu, MenuElement} from '../../services/menu/menu.service';
import {IMenuItem} from '../../services/menu/menu-item.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IEcranDetailsFonctions} from '../../resources/ecran-details.resource';
import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IComposantMonoOccurrenceEcran} from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import {IComposantMonoOccurrence} from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import {IComposantMultiOccurrence} from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import {IOccurrence} from '../../services/occurrence.service';
import {IDefaultsService} from '../../services/utils/defaults.service';

interface IComposantCardMenuList extends IComponentController {
    menu?: IMenu
    menuItems?: Array<MenuElement>;
    isMenuHidden: boolean;
    hasMenu: boolean;
    hasMenuItems: boolean;
    layout: string;
    monoOccurrenceCtrl?: IComposantMonoOccurrence;
    multiOccurrenceCtrl?: IComposantMultiOccurrence;
    monoOccurrenceEcranCtrl?: IComposantMonoOccurrenceEcran;
    occurrence?: IOccurrence;
    dataSourceCtrl?: IDataSourceController;
    ecranContextCtrl: IEcranContextController;
}

/* @ngInject */
export default function CardMenuListComponent($element: IAugmentedJQuery,
  $timeout: ITimeoutService,
                                              defaults: IDefaultsService) {
    const vm: IComposantCardMenuList = this;

    vm.$onInit = $onInit;

    function $onInit() {
        defaults(vm, {
            hasMenu: typeof vm.menu,
            hasMenuItems: typeof vm.menuItems,
            layout: 'column'
        });

        if (typeof vm.hasMenu === 'undefined' && typeof vm.hasMenuItems === 'undefined') {
            console.error('Le composant exMenuList à besoin d\'un menu ou de menuitems.');
        } else if (typeof vm.hasMenu !== 'undefined' && typeof vm.hasMenuItems === 'undefined') {
            vm.menuItems = vm.menu.listeMenuItem;
        }

        vm.occurrence = (vm.dataSourceCtrl && vm.dataSourceCtrl.occurrence) ||
                        (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence) ||
                        (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence);

        const fonctions = vm.ecranContextCtrl.ecranDetails.fonctions;

        // On affiche pas la carte si le menu n'est pas visible.
        if (typeof fonctions !== 'undefined') {
            vm.isMenuHidden = vm.menuItems.every((item: IMenuItem) => isFonctionHidden(item, fonctions) || isMenuItemHidden(item));
            if (vm.isMenuHidden) {
                $element.remove();
            }
        } else {
            vm.isMenuHidden = false;
        }

        
        if (!vm.isMenuHidden && vm.layout === 'row') {
          $timeout(() => {
            $element.find('.ex-card-menu-list--card').attr('wrap-content', '');
          });  
        }
    }

    function isMenuItemHidden(item: IMenuItem) {
        if (item.hidden instanceof Function || item.visible instanceof Function) {
            if (item.hidden) {
                return (<Function>item.hidden)();
            } else {
                return !(<Function>item.visible)({});
            }
        } else {

            return Boolean(item.hidden)|| Boolean(item.visible);
        }
    }

    function isFonctionHidden(item: IMenuItem, fonctions: {[key: string]: IEcranDetailsFonctions}) : boolean {
        const mnemonique = item.mnemonique || vm.occurrence && vm.occurrence.mnemonique;
        const formattedFonctionName = (mnemonique) ? `${mnemonique}.${item.fonction}` : item.fonction;
        const fonction = fonctions[formattedFonctionName.toUpperCase()];

        return fonction && !fonction.flgacc;
    }

}