import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CardInfosMonetairesController from './ex-card-infos-monetaires.controller';

export default module('core.components.ex-card-infos-monetaires', []).component('exCardInfosMonetaires', CardInfosMonetairesFactory());

function CardInfosMonetairesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE,
            {
                infosMonetaires: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '^^exMonoOccurrenceEcran'
        },
        controller: CardInfosMonetairesController,
        controllerAs: 'vm',
        template: require('./ex-card-infos-monetaires.html')
    };
}
