import {IComponentController, IFormController, IQService, IScope, ITimeoutService} from 'angular';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IDataType, IParamsLovsOptions} from '../../services/data-types/data-type.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../services/multi-occurrence.service';
import {IApiConfig} from '../../interfaces/api-config.interface';
import {ISourceDetailsManager} from '../../services/source-details-manager.service';
import IAugmentedJQuery = angular.IAugmentedJQuery;

interface IDialogEditionAvancee extends IComponentController {
    stateParams: any;
    lblTitre: string;
    icon: string;
    data: IDataEditionAvancee;
    formCtrl: IFormController;
    dataType: IDataType;
    value: string;
    lovs: Array<IParamsLovsOptions>
    srccodref: string;
    multiOccurrenceConfigList: Array<{lblTitre: string, multiOccurrenceOptions: IMultiOccurrenceOptions}>;
    openedList: Array<boolean>;
    confirm(): any;
    getColonnesDescription(srccod: string): Array<string>;
    getColonnesDetails(srccod: string): Array<string>;
    copie(multiOccurrence: IMultiOccurrence): void;
    isColonneClear(col: string, srccod: string): boolean;
}

interface IDataEditionAvancee {
    [index: string]: number|string;
}

/* @ngInject */
export default function DialogEditionAvanceeController(defaults: IDefaultsService,
                                                       ApiConfig: IApiConfig,
                                                       $q: IQService,
                                                       sourceDetailsManager: ISourceDetailsManager,
                                                       $element: IAugmentedJQuery,
                                                       $scope: IScope,
                                                       $timeout: ITimeoutService) {
    const vm: IDialogEditionAvancee = this;
    const LOVS_EDITION_AVANCEE_BASE: {[col: string]: any} = {
        bmt: {
            lblTitre: 'G_LBL_EDITION_AVANCEE_CHAMPS_FUSION',
            description: ['bmtvalrem'],
            colCopie: 'bmtvalrem'
        },
        bmr: {
            lblTitre: 'G_LBL_EDITION_AVANCEE_BANQUE_TEXTE',
            description: ['bmrcod', 'bmrmsg'],
            details: ['vacbmrcat_cod', 'vacbmrcat_dsc'],
            colCopie: 'bmrmsg'
        },
        bam: {
            lblTitre: 'G_LBL_EDITION_AVANCEE_BANQUE_TEXTE',
            description: ['bamcod', 'bammsg'],
            colCopie: 'bammsg'
        }
    };

    vm.confirm = confirm;
    vm.copie = copie;
    vm.getColonnesDescription = getColonnesDescription;
    vm.getColonnesDetails = getColonnesDetails;
    vm.isColonneClear = isColonneClear;

    function confirm(): string {
        return vm.value;
    }

    vm.$onInit = function $onInit() {
        vm.lovs = vm.dataType.params.lovs.map((lov: string|IParamsLovsOptions) => typeof lov === 'string' ? <IParamsLovsOptions>{lov: lov, ...LOVS_EDITION_AVANCEE_BASE[lov]} : lov);

        defaults(vm, {
            icon: 'rate_review',
            lblTitre: 'G_LBL_EDITION_AVANCEE',
            lblConfirm: 'G_LBL_BTN_APPLIQUER'
        });

        vm.multiOccurrenceConfigList = vm.lovs.map((lov: IParamsLovsOptions) => {
            const filtres = (vm.dataType.params.vaebmtent && lov.lov === 'bmt') ?
                [{colonne: 'vaebmtent', valeur: vm.dataType.params.vaebmtent, visible:false}] :
                [];

            return {
                lblTitre: lov.lblTitre,
                multiOccurrenceOptions: {
                    srccod: `lov-${lov.lov}`,
                    stateParams: vm.stateParams,
                    resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${lov.lov}`,
                    resourceParams: {
                        srccod: vm.srccodref
                    },
                    filtres,
                    colonnesVisibles: (lov.details || []).concat(lov.description),
                    pagination: {
                        nombreElementPage: 100
                    },
                    fonctions: {
                        nouveau: false,
                        edition: false,
                        supprime: false,
                        activeRowOnClick: true,
                        enregistrerUnEtat: false,
                        filtrer: false,
                        rechercheUnique: true,
                        rechercheParColonne: false,
                        exportation: false,
                        suiviModification: false
                    }
                }
            };
        });

        vm.openedList = vm.lovs.map((val: IParamsLovsOptions, index: number) => !index);

        // Pour éviter de créer une boucle infinie!
        let skipChanges: boolean = false;
        $scope.$watchCollection('vm.openedList', (newValue: Array<boolean>, oldValue: Array<boolean>) => {
            if (newValue !== oldValue && !skipChanges) {
                const newList = newValue.map((val: boolean, index: number) => val !== oldValue[index] ? val : false);
                skipChanges = true;
                vm.openedList = newList;
            } else {
                skipChanges = false;
            }
        });

        $timeout(() => {
            // On s'assure que le curseur soit à la fin du champ par défaut
            const textarea: HTMLTextAreaElement = <any>$element.find('textarea').get(0);
            const value = vm.value || '';
            textarea.setSelectionRange(value.length, value.length);
        });
    }

    function copie(multiOccurrence: IMultiOccurrence) {
        const col = multiOccurrence.srccod.split('lov-').pop();
        const lov = vm.lovs.find((lov: IParamsLovsOptions) => lov.lov === col);
        const colCopie: string = lov.colCopie;
        if (multiOccurrence.activeRowCleint) {
            const data = multiOccurrence.dataList.find((rowData: any) => {
                return rowData[multiOccurrence.forageCleint] === multiOccurrence.activeRowCleint;
            });

            if (data) {
                const textarea: HTMLTextAreaElement = <any>$element.find('textarea').get(0);
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                const value = vm.value || '';
                vm.value = value.substring(0, start) + data[colCopie] + value.substring(end);
            }
        }
    }

    function getColonnesDescription(srccod: string): Array<string> {
        const col = srccod.split('lov-').pop();
        const lov = vm.lovs.find((lov: IParamsLovsOptions) => lov.lov === col);
        return lov.description;
    }

    function getColonnesDetails(srccod: string): Array<string> {
        const col = srccod.split('lov-').pop();
        const lov = vm.lovs.find((lov: IParamsLovsOptions) => lov.lov === col);
        return lov.details;
    }

    function isColonneClear(col: string, srccod: string): boolean {
        const lovName = srccod.split('lov-').pop();
        const lov = vm.lovs.find((lov: IParamsLovsOptions) => lov.lov === lovName);
        return !!(lov.details || []).length && lov.description[0] === col;
    }
}
