import { IComponentOptions, module } from 'angular';
import Binding from '../../helpers/binding.helper';
import { IComposantEdition } from '../ex-edition/ex-edition.controller';
import SaisieController from './ex-saisie.controller';

export default module('core.components.ex-saisie', []).component('exSaisie', DataFactory());

export interface ISaisieController {
    editionCtrl: IComposantEdition;
    showTitle: boolean;
}

function DataFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON,
            {
                formCtrl: '=?',
                lblTitre: '@',
                cancelAction: '&?',
                showTitle: '<?',
                hideCloseIcon: '@',
                isCritereSuggere: '<?',
                cacherBoutonClose: '<?',
                resetTitre: '<?'
            }
        ),
        transclude: {
            content: 'exSaisieContent',
            actions: 'exSaisieActions'
        },
        controllerAs: 'vm',
        controller: SaisieController,
        template: require('./ex-saisie.html')
    };
}
