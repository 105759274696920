import { IHttpResponse, ILogService, IQService, module } from 'angular';
import { ISourceDetails } from '../resources/source-details.resource';
import { IRouter, IRouterProvider } from '../providers/router.provider';
import { IExceptions } from '../constants/exceptions.constant';
import { IProfil } from '../resources/profil.resource';
import { IEcranDetailsResourcesClass, IEcranDetailsResourcesEntite } from '../resources/ecran-details.resource';
import { ISourceDetailsManager } from '../services/source-details-manager.service';
import { IParametresSecuriteService } from '../services/parametres-securite.service';
import IStateProvider = angular.ui.IStateProvider;
import IStateService = angular.ui.IStateService;
import { IRoute } from '../interfaces/route.interface';

interface IEcranOptions {
    source: string;
    ecrans: Array<string>;
    url?: string;
    global?: boolean;
    params?: Array<string>;
    queryParams?: Array<string>;
    template?: string;
    libelles?: boolean;
    resource?: boolean;
    controller?: Function;
    login?: boolean;
}

export interface IRouteConfig {
    BASE_NAME: string;
    NAME: string;
    SOURCE: string;
    ECRANS: Array<string>;
    ECRAN_DEFAUT: string;
    URL: string;
    PARAMS: Array<string>;
    QUERY_PARAMS: Array<string>;
}

export default ecran;

function ecran(options: IEcranOptions) {
    const srccod = options.source.toLowerCase();
    const url = options.url || srccod;

    const ecranDefaut = options.ecrans[0];

    options.params = options.params || [];
    options.queryParams = options.params.filter(param => param !== 'id');

    options.queryParams.push('?employe');

    const route: IRouteConfig = {
        BASE_NAME: `secure.${srccod}`,
        NAME: `secure.${srccod}.ecran`,
        SOURCE: srccod.toUpperCase(),
        ECRANS: options.ecrans,
        ECRAN_DEFAUT: ecranDefaut,
        URL: `/${url}`,
        PARAMS: options.params,
        QUERY_PARAMS: options.queryParams
    };

    const ecranModule = module(`${options.global ? 'core' : 'app'}.` + route.NAME, [
        'core.providers.router'
    ]).config(config).constant(`${snakeToCamel(url)}Route`, route);

    return ecranModule;

    /* @ngInject */
    function config($stateProvider: IStateProvider,
        RouterProvider: IRouterProvider) {

        RouterProvider.registerRoute(route);

        const resolve: any = {};


        if (options.resource !== false) {
            resolve.paramsValidation = paramsValidationResolver;
            resolve.sourceDetailsResolver = sourceDetailsResourceResolver;
            resolve.ecranDetailsResolver = ecranDetailsResourceResolver;
        }

        $stateProvider
            .state(route.BASE_NAME, {
                url: getStateUrl(),
                abstract: true,
                params: {
                    srccod: route.SOURCE,
                    ecran: ecranDefaut,
                    route: route,
                    error: null,
                    isProfilEmploye: null,
                    verifierChangements: null,
                    pracleint: null,
                    menucleref: null,
                    ecrcleint: null
                },
                views: {
                    content: {
                        template: '<div ui-view layout-fill class="app-content"></div>'
                    }
                }
            })
            .state(route.NAME, {
                controller: `${srccod}Controller`,
                controllerAs: 'vm',
                templateUrl: `${srccod}.html`,
                resolve
            });
    }

    /* @ngInject */
    function paramsValidationResolver($stateParams: any,
        $q: IQService,
        Exceptions: IExceptions) {
        const missingParams = options.queryParams.some((param: string) => {
            // Les params optionnels commencent par ?
            if (param.startsWith('?')) {
                return false;
            } else {
                return $stateParams[param] == null;
            }
        });

        if (missingParams) {
            return $q.reject(Exceptions.INTROUVABLE);
        }
    }

    /* @ngInject */
    function sourceDetailsResourceResolver(ecranDetailsResolver: IEcranDetailsResourcesEntite,
        ecranSourceDetails: ISourceDetails,
        sourceDetailsManager: ISourceDetailsManager,
        $q: IQService,
        Exceptions: IExceptions,
        $stateParams: any,
        $log: ILogService,
        parametresSecurite: IParametresSecuriteService,
        appName: string,
        accueilRoute: IRoute,
        $state: IStateService) {

        $stateParams.ecrcleint = ecranDetailsResolver.ecrcleint;

        const [pracleint, menucleref] = ($stateParams.menuId || '').split('-');

        const params = {
            pracleint,
            menucleref,
            ...$stateParams,
            ecrcleint: ecranDetailsResolver.ecrcleint,
            cleint: $stateParams.id,
            ...parametresSecurite($stateParams)
        };

        const srccodref: string = null;

        return sourceDetailsManager.fetch(srccod, srccodref, params)
            .then((nouveauSourceDetails: ISourceDetails) => {
                Object.assign(ecranSourceDetails, nouveauSourceDetails);

                return ecranSourceDetails;
            })
            .catch((err: any) => {
                $log.error(err);

                if (err.status === 403 && appName === 'portail-employe') {
                    return $state.go(accueilRoute.NAME);
                }

                const exception = err.status === 403
                    ? Exceptions.ERREUR_ACCESS_ECRAN
                    : Exceptions.ERREUR_CHARGEMENT_ECRAN;

                return $q.reject(exception);
            })
            .then((data: ISourceDetails) => {
                const fonction = ecranDetailsResolver.fonctions[data.mnemonique.toUpperCase()];

                const prevenirAcces = (
                    fonction &&
                    (
                        !fonction.flgacc ||
                        (options.params.includes('id') && !($stateParams.id || $stateParams.employe) && !fonction.flgins)
                    )
                );

                if (prevenirAcces) {
                    return $q.reject(Exceptions.ERREUR_ACCESS_ECRAN);
                }

                return data;
            });
    }

    /* @ngInject */
    function ecranDetailsResourceResolver(EcranDetailsResource: IEcranDetailsResourcesClass,
        ecranDetails: IEcranDetailsResourcesEntite,
        profilResolver: IProfil,
        $q: IQService,
        Exceptions: IExceptions,
        $stateParams: any,
        $state: IStateService,
        Router: IRouter) {
        const ecrcod = `${route.SOURCE}-${$stateParams.ecran}`;

        // Si le menu id n'est pas présent, on essaie de trouver un menu id a utiliser, soit le premier que l'on trouve
        if (!$stateParams.menuId) {
            const { menuItem, profilAcces } = Router.getMenuByCode(ecrcod.toUpperCase(), profilResolver);

            // Si l'écran est directement dans le menu, c'est facile
            if (menuItem) {
                const menucleref = [menuItem.vaesysenttrtmen, menuItem.mencleint, menuItem.vaesysenttrt, menuItem.medcleintref].join(',');
                const params = Object.assign($stateParams, {
                    menuId: `${profilAcces.pracleint}-${menucleref}`
                });

                $state.transitionTo(route.NAME, params, {
                    location: 'replace'
                });

                // On ne resout pas la promise
                return $q.defer().promise;
            }
            // Si l'écran n'est pas dans le menu, il faut allé au serveur qui cherchera en se basant sur le forage
            else {
                return EcranDetailsResource.getMenuItem({ ecrcod }).$promise
                    .then((menuItemLocator) => {
                        const menucleref = [menuItemLocator.vaesysenttrtmen, menuItemLocator.mencleint, menuItemLocator.vaesysenttrt, menuItemLocator.medcleintref].join(',');

                        $state.transitionTo(route.NAME, {...$stateParams, menuId: `${menuItemLocator.pracleint}-${menucleref}`}, {
                            location: 'replace'
                        });

                        // On ne resout pas la promise
                        return $q.defer().promise;
                    })
                    .catch(() => {
                        return $q.reject(Exceptions.ERREUR_ACCESS_ECRAN);
                    });
            }
        }

        const [pracleint, menucleref] = ($stateParams.menuId || '').split('-');

        $stateParams.pracleint = pracleint;
        $stateParams.menucleref = menucleref;
        (window as any).isLoading = true;

        $stateParams.isProfilEmploye = Boolean($stateParams.employe);

        const params: any = {
            ecrcod,
            pracleint,
            menucleref,
            id: $stateParams.id,
            employe: $stateParams.employe
        };

        options.queryParams.forEach((param: string) => {
            const col = param.replace(/^\?/, '');
            params[col] = $stateParams[col];
        });

        return ecranDetails.$get(params)
            .then((ecranDetails) => {
                (window as any).isLoading = false;
                return ecranDetails;
            })
            .catch((response: IHttpResponse<any>) => {
                const isErreurSofe = (response.data && response.data.code && response.data.code.startsWith('SOFE-'));
                (window as any).isLoading = false;

                if (isErreurSofe) {
                    //Dans le cas d'une erreur Sofe, on retourne l'erreur pour avoir accès au message.
                    return $q.reject(response);
                } else {
                    const erreur = response.status === 403 ?
                        Exceptions.ERREUR_ACCESS_ECRAN :
                        Exceptions.ERREUR_CHARGEMENT_ECRAN;
                    return $q.reject(erreur);
                }
            });
    }

    function snakeToCamel(name: string) {
        const DASH_LOWERCASE_REGEXP = /-([a-z])/g;

        return name.replace(DASH_LOWERCASE_REGEXP, fnCamelCaseReplace);
    }

    function fnCamelCaseReplace(all: any, letter: string) {
        return letter.toUpperCase();
    }

    function getStateUrl(): string {
        const idInclu = options.params.includes('id');
        // Si un url est passé, on n'est pas sur un srccod, alors on ne veut as l'écran dans le url
        const ecranInclu = !options.url;

        let stateUrl = `menu/:menuId/${url}`;

        if (ecranInclu) {
            stateUrl = `${stateUrl}-{ecran:[0-9]*}`;
        }

        if (idInclu) {
            stateUrl = `${stateUrl}/:id`;
        } else {
            stateUrl = `${stateUrl}/`;
        }

        const query = getStateUrlQuery();
        return `/${stateUrl}${query}`;
    }

    function getStateUrlQuery(): string {
        // Permet à la page de définir des paramètres de requête
        if (options.params) {
            const params = options.queryParams.map((param: string) => param.replace(/^\?/, ''));
            return `?${params.join('&')}`;
        } else {
            return '';
        }
    }
}
