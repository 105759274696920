import * as angular from 'angular';
import {IAugmentedJQuery, IScope, module} from 'angular';

export default module('core.behaviors.ex-draggable', [])
    .directive('exDraggable', DraggableDirective);

interface IDraggableOptions {
    start?: Function;
    end?: Function;
    container: string;
}

/* @ngInject */
function DraggableDirective($document: any) {
    return {
        restrict: 'A',
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: any) {
        const DEPLACEMENT_MINIMUM = 15;
        const options: IDraggableOptions = attrs.exDraggable ? scope.$eval(attrs.exDraggable) : {};

        let startX: number;
        let startY: number;
        let initialMouseX: number;
        let initialMouseY: number;
        let xLimit: number;
        let yLimit: number;
        let dragging = false;
        let containerPosition: {top: number, left: number};

        element.bind('mousedown', (event) => {
            startX = element.prop('offsetLeft');
            startY = element.prop('offsetTop');
            initialMouseX = event.clientX;
            initialMouseY = event.clientY;

            $document.on({
                'mousemove.exDraggable': mousemove,
                'mouseup.exDraggable': mouseup
            });
        });

        scope.$on('$destroy', () => {
            $document.off('.exDraggable');
        });

        function mousemove(event: MouseEvent) {
            if (!dragging) {
                const distance = calculerDistance(initialMouseX, initialMouseY, event.clientX, event.clientY);
                if (distance > DEPLACEMENT_MINIMUM) {
                    startDragging();
                } else {
                    // On ne drag pas à moins qu'il y ait un déplacement minimum
                    return;
                }
            }

            const position = getNewPosition(event.clientX, event.clientY);
            element.css(position);
        }

        function mouseup() {
            dragging = false;

            if (options.end) {
                scope.$applyAsync(() => options.end());
            }

            $document.off('.exDraggable');
        }

        function startDragging() {
            dragging = true;

            if (options.start) {
                scope.$applyAsync(() => options.start());
            }

            if (options.container) {
                const container = (typeof options.container === 'string') ?
                    angular.element(options.container).last() : options.container;
                containerPosition = container.position();
                xLimit = container.width() + containerPosition.left - element.width();
                yLimit = container.height() + containerPosition.top - element.height();
            }
        }

        function getNewPosition(x: number, y: number) {
            let top = startY + y - initialMouseY;
            let left = startX + x - initialMouseX;

            // On restreint la position à l'intérieur du container
            if (options.container) {
                if (top > yLimit) {
                    top = yLimit;
                } else if (top < containerPosition.top) {
                    top = containerPosition.top;
                }

                if (left > xLimit) {
                    left = xLimit;
                } else if (left < containerPosition.left) {
                    left = containerPosition.left;
                }
            }

            return {top, left};
        }

        function calculerDistance(x1: number, y1: number, x2: number, y2: number) {
            return Math.sqrt(
                ((x2 - x1) ** 2) + ((y2 -y1) ** 2)
            );
        }
    }
}
