import { IComponentOptions, module } from 'angular';
import CardController from './ex-card.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-card', [
    'core.services.utils.defaults'
]).component('exCard', CardFactory());

function CardFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.COLLAPSABLE.OPTIONAL,
            Binding.NO_HEADER.OPTIONAL,
            Binding.MENU.OPTIONAL,
            {
                etatErreur: '<?',
                etatAvertissement: '<?',
                opened: '=?', // on utilise un custom, car celui "Binding.OPEND" ne peut pas être changé par un = question de performance
                noHeaderBorder: '<?',
                lblTitre: '@?',
                fullscreenName: '@?exFullscreen', // ceci est utilisé dans ex-fullscreen.behavior, ex-fullscreen.service et elle contient le nom
                options: '<?',
                contentClass: '<?',
                closeAction: '<?',
                sticky: '<?',
                bloc: '@?',
                srccod: '@?'
            }
        ),
        transclude: {
            content: 'exCardContent',
            contentFullRight: '?exCardContentFullRight',
            contentFullBottom: '?exCardContentFullBottom',
            actions: '?exCardActions',
            titre: '?exCardTitre'
        },
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controller: CardController,
        controllerAs: 'vm',
        template: require('./ex-card.html')
    };
}
