import { IComponentController, IFormController, ITimeoutService } from 'angular';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IStateService } from 'angular-ui-router';
import { IChangementManager } from '../../services/changement-manager.service';
import { IMultiOccurrenceClass } from '../../services/multi-occurrence.service';

export interface IEditionOptions {
    btnSaisieClassPrincipaux?: string | object;
    btnSaisieClassSecondaire?: string | object;
    btnSaisieClassSubPrincipaux?: string | object;
}

export interface IComposantEdition extends IComponentController {
    data: any;
    isBoutonNouveau: any;
    savedData: any;
    formCtrl: IFormController;
    cancelAction: Function;
    saveAction: Function;
    saveAndNextAction: Function;
    saving: boolean;
    savingAndNext: boolean;
    btnSaisieClassPrincipaux: string | object;
    btnSaisieClassSubPrincipaux: string | object;
    btnSaisieClassSecondaire: string | object;
    disableSave: boolean;
    editionOptions?: IEditionOptions;
    showTitle: boolean;
    occurrenceEdition: any;
    cacherBoutonAnnuler: boolean;
    cacherBoutonClose: boolean;
    resetTitre: boolean;
    lblTitre: any;
    cancel(): void;
    isBoutonSaveDisabled(): boolean;
    saveData($event: MouseEvent | KeyboardEvent): void;
    save($event: MouseEvent | KeyboardEvent): void;
    saveDataAndNext($event: MouseEvent | KeyboardEvent): void;
    saveAndNext($event: MouseEvent | KeyboardEvent): void;
    hasChangementsNonSauvegardes(): boolean;
}

/* @ngInject */
export default function EditionController($timeout: ITimeoutService,
    defaults: IDefaultsService,
    keyCodes: IKeyCodes,
    $state: IStateService,
    changementManager: IChangementManager,
    MultiOccurrence: IMultiOccurrenceClass) {
    const vm: IComposantEdition = this;

    vm.$onInit = $onInit;
    vm.cancel = cancel;
    vm.saveData = saveData;
    vm.save = save;
    vm.saveDataAndNext = saveDataAndNext;
    vm.saveAndNext = saveAndNext;
    vm.isBoutonSaveDisabled = isBoutonSaveDisabled;
    vm.hasChangementsNonSauvegardes = hasChangementsNonSauvegardes;
    vm.keydown = keydown;

    function $onInit() {
        defaults(vm, {
            saving: false,
            savingAndNext: false,
            showTitle: true,
            cacherBoutonClose: (vm?.cacherBoutonClose) ? true : false
        });

        vm.btnSaisieClassPrincipaux = (vm.editionOptions && vm.editionOptions.btnSaisieClassPrincipaux) ? vm.editionOptions.btnSaisieClassPrincipaux : 'md-raised ex-button ex-button-action';
        vm.btnSaisieClassSecondaire = (vm.editionOptions && vm.editionOptions.btnSaisieClassSecondaire) ? vm.editionOptions.btnSaisieClassSecondaire : 'md-raised ex-button-secondary';
        vm.btnSaisieClassSubPrincipaux = (vm.editionOptions && vm.editionOptions.btnSaisieClassSubPrincipaux) ? vm.editionOptions.btnSaisieClassSubPrincipaux : vm.btnSaisieClassPrincipaux;
    }

    function cancel() {
        vm.cancelAction();
    }

    function save($event: MouseEvent | KeyboardEvent) {
        $event.preventDefault();

        if (!vm.isBoutonSaveDisabled()) {
            vm.formCtrl.$commitViewValue();
            vm.saving = true
            // Il faut attendre avant les données mise à jour
            wait($event);
        }
    }

    async function wait($event: MouseEvent | KeyboardEvent) {
        //mettre en attente ChangeItemResource.save doit finaliser
        await executeWait().then(() => {
            //apres executer la function
            if (vm.formCtrl['$$sofeChangeItemsWaiting'] || vm.formCtrl['$$sofeValidateItemsWaiting']) {
                //si le temps n'est pas suffisant donc ejecuter de nouveau, et eviter de faire la recursivite
                save($event);
            } else {
                saveData($event);
            }
        });
    }

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    const executeWait = async () => {
        await delay(2000);
    }

    function saveAndNext($event: MouseEvent | KeyboardEvent) {
        $event.preventDefault();

        if (!vm.isBoutonSaveDisabled()) {
            vm.formCtrl.$commitViewValue();
            vm.savingAndNext = true
            waitSaveAndNext($event)
        }
    }

    async function waitSaveAndNext($event: MouseEvent | KeyboardEvent) {
        //mettre en attente ChangeItemResource.save doit finaliser
        await executeWait().then(() => {
            //apres executer la function
            if (vm.formCtrl['$$sofeChangeItemsWaiting'] || vm.formCtrl['$$sofeValidateItemsWaiting']) {
                saveAndNext($event);
            } else {
                saveDataAndNext($event);
            }
        });
    }

    function saveData($event: MouseEvent | KeyboardEvent) {
        $event.preventDefault();
        vm.formCtrl.$setSubmitted();

        if (vm.formCtrl.$valid && !vm.disableSave) {
            vm.saving = true;
            vm.saveAction({ $savedData: vm.formData, $event: $event })
                .finally(() => {
                    vm.saving = false;
                    //si la propriete reloadPage existe dans le multi on fait un reloading de la page, bug du composant ex-multi-occurrence-template avec piece adjointe
                    try {
                        const flag = vm?.occurrenceEdition?.multiOccurrence?.reloadPage || vm?.monoOccurrenceCtrl?.multiOccurrenceCtrl?.multiOccurrence?.reloadPage
                        if (flag) {
                            const stateParams = vm?.occurrenceEdition?.monoOccurrenceCtrl?.monoOccurrence?.stateParams || vm?.monoOccurrenceCtrl?.monoOccurrence?.stateParams;
                            if (stateParams) {
                                $state.transitionTo(stateParams.route.NAME, {
                                    id: stateParams.id,
                                    verifierChangements: false
                                }, {
                                    reload: true,
                                });
                            }
                        }
                    } catch (error) {
                        //s'il y a une erreur on fait rien
                    }
                });
        } else {
            vm.saving = false;
        }
    }

    function saveDataAndNext($event: MouseEvent | KeyboardEvent) {
        $event.preventDefault();
        vm.formCtrl.$setSubmitted();

        if (vm.formCtrl.$valid && !vm.disableSave) {
            vm.savingAndNext = true;
            vm.saveAndNextAction({ $savedData: vm.formData })
                .finally(() => vm.savingAndNext = false);
        } else {
            vm.savingAndNext = false;
        }
    }

    function isBoutonSaveDisabled() {
        return vm.saving || !vm.formCtrl || !vm.formCtrl.$valid || Boolean(vm.formCtrl.$pending) || vm.disableSave || !hasChangementsNonSauvegardes();
    }

    function hasChangementsNonSauvegardes(): boolean {
        let hasChangementsNonSauvegardesResult = true;
        try {
            //nouveau enregistrement 
            if (vm.isBoutonNouveau) {
                return true;
            }
            const data = vm.data || vm.formData;
            if (data) {
                // On va utiliser monoOccurrenceCtrl quand le isView est true, sinon on va utiliser l'occurrence qui va nous donner les informations nécessaires.
                const useOccurrenceData = vm.occurrence instanceof MultiOccurrence;
                if (vm.monoOccurrenceCtrl?.monoOccurrence?.data || useOccurrenceData) {
                    hasChangementsNonSauvegardesResult = changementManager.compareChangements(
                        data,
                        useOccurrenceData
                            ? vm.occurrence.activeRowCleint
                                ? vm.occurrence.getDataActiveRow()
                                : vm.occurrence.valeursDefaut || {}
                            : vm.monoOccurrenceCtrl?.monoOccurrence?.data,
                        useOccurrenceData ? vm.occurrence.schema : vm.monoOccurrenceCtrl?.monoOccurrence?.schema,
                        useOccurrenceData ? vm.occurrence.dataTypes : vm.monoOccurrenceCtrl?.monoOccurrence?.dataTypes
                    );
                }
            } else {
                hasChangementsNonSauvegardesResult = false;
            }
        } catch (error) {
        }

        return hasChangementsNonSauvegardesResult;
    }

    function keydown(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === "S") {
            event.preventDefault();
            vm.save(event);
        }
    }
}
