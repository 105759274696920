import angular from 'angular';
import { IComponentController } from 'angular';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IRoute } from '../../interfaces/route.interface';
import { ICycleRhClass, IProchainEtatRh } from '../../resources/cycle-rh.resource';
import { IMenuItemEcranClass } from '../../services/menu/menu-item-ecran.service';
import { IMenuItem } from '../../services/menu/menu-item.service';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import IResourceService = angular.resource.IResourceService;
import IDialogService = angular.material.IDialogService;
export interface IComposantCycleListActionsEtapes extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    initEtapesCycle(): void;
    isEtapesCycleEmpty(): boolean;
    prochainEtatMenuItem: IMenuItem;
    executeActionPossible(titre: any, message: any, eclcleintcib?: any): void;
    etapeVisible?: boolean;
}

interface IEtapeCycleActionsEvenement {
    [index: string]: any;
    isEtatActuel: boolean;
    isProchainEtat: boolean;
}
/* @ngInject */
export default function CycleListActionsEtapesController(ApiConfig: IApiConfig,
    $resource: IResourceService,
    parametresSecurite: IParametresSecuriteService,
    MenuItemEcran: IMenuItemEcranClass,
    mr2015Route: IRoute,
    $mdDialog: IDialogService,
    CycleRh: ICycleRhClass) {
    const vm: IComposantCycleListActionsEtapes = this;
    vm.$onInit = $onInit;
    vm.initEtapesCycle = initEtapesCycle;
    vm.isEtapesCycleEmpty = isEtapesCycleEmpty;
    vm.executeActionPossible = executeActionPossible;
    function $onInit() {

        refreshProchainEtat().then(() => {
            vm.etapeVisible = true;
            /*if (!(vm.monoOccurrence.isPortailEmploye) && !(vm.monoOccurrence.cycleCleint === 'trncleint')) {
                //dans le cas de doccleint on ajoute l'action 
                vm.etapeVisible = true;
            } else {
                //Si les indicateurs : ((référable = 0 et final = 0 et fermé = 0 et annulé = 0) ou (l’indicateur Accès Web MRCYR_ETAPE.CEPFLGACCEPL=1) )alors l’étape est visible (basée ou non)
                let etape = <IProchainEtatRh>vm.monoOccurrence.prochainEtat;
                //le flag approbation est utilisé dans plsql fub_droit_approbation
                if (etape.approbation) {
                    if ((!etape.cepflgstaref && !etape.cepflgstafin && !etape.cepflgstafer && !etape.cepflgstaann) || etape.cepflgaccepl) {
                        vm.etapeVisible = true;
                    }
                }
            }*/
        }).finally(() => {
            vm.initEtapesCycle();
        });

    }

    function initEtapesCycle(): void {
        let tmpEtapes: Array<IEtapeCycleActionsEvenement> = [];
        let cleEtatActuel = '';
        let requete;
        let cleDsc = '';

        if (vm.monoOccurrence.cycleCleint === 'doccleint') {
            cleEtatActuel = 'etdcleint';
            cleDsc = 'etddsc';

            requete = $resource(`${ApiConfig.ROOT}/cycles/finance/etapes/:doccleint`, {
                doccleint: vm.monoOccurrence.data.doccleint,
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            });
        } else {
            cleEtatActuel = 'eclcleint';
            cleDsc = 'ecldsceta';

            requete = $resource(`${ApiConfig.ROOT}/cycles/rh/actions-possibles/:trncleint`, {
                trncleint: vm.monoOccurrence.data.trncleint,
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            });
        }

        vm.initializingEtapes = true;

        requete.query().$promise.then((etapes: Array<any>) => {
            for (const etape of etapes) {
                if (etape.cepflgcycbas === undefined ? true : etape.cepflgcycbas || etape[cleEtatActuel] === vm.monoOccurrence.etatActuel[cleEtatActuel]) {
                    tmpEtapes.push(setArray(etape, cleDsc, cleEtatActuel));
                    /*
                    if (vm.monoOccurrence.isPortailEmploye && vm.monoOccurrence.cycleCleint === 'trncleint') {
                        //actions possibles visibles MRCYR_ETAPE.CEPFLGACCEPL=1 ou (référable = 0 et final = 0 et fermé = 0 et annulé = 0) basée ou non
                        if ((!etape.cepflgstaref && !etape.cepflgstafin && !etape.cepflgstafer && !etape.cepflgstaann) || etape.cepflgaccepl) {
                            tmpEtapes.push(setArray(etape, cleDsc, cleEtatActuel));
                        }
                    }else{
                        //dans le cas de doccleint on ajoute l'action
                        tmpEtapes.push(setArray(etape, cleDsc, cleEtatActuel));
                    }*/
                }
            }
            vm.etapesCycle = tmpEtapes;
            vm.erreurChargementEtapes = false;
        }).catch(() => vm.erreurChargementEtapes = true).finally(() => vm.initializingEtapes = false);
    }

    function refreshProchainEtat() {
        return vm.monoOccurrence.fetchEtatsCycle();
    }

    function setArray(etape: any, cleDsc: string, cleEtatActuel: string) {
        return Object.assign({
            dsc: etape[cleDsc],
            isEtatActuel: etape[cleEtatActuel] === vm.monoOccurrence.etatActuel[cleEtatActuel],
            isProchainEtat: etape[cleEtatActuel] === vm.monoOccurrence.prochainEtat.cleintpro
        }, etape)
    }

    function isEtapesCycleEmpty() {
        return (vm.etapesCycle?.length) ? false : true;
    }

    function executeActionPossible(titre: any, message: any, eclcleintcib?: any) {
        vm.prochainEtatMenuItem = getAction(titre, message, eclcleintcib);
    }

    function getAction(titre: string, message: string, _eclcleintcib: string) {

        const eclcleint = (_eclcleintcib) ? _eclcleintcib : (<IProchainEtatRh>vm.monoOccurrence.prochainEtat).cleintpro;
        vm.isModalOpen = true;
        const menuItem = new MenuItemEcran('LBL_TITRE', mr2015Route.NAME, () => ({
            id: vm.monoOccurrence.data.trncleint,
            trncleint: vm.monoOccurrence.data.trncleint,
            numcol: [vm.monoOccurrence.data.trncleint],
            eclcleint,
            flgselmul: 0,
            eclcleintcib: _eclcleintcib
        }), {
            appliquer: true,
            showActionsInDialog: true,
            customLblFermer: true,
            optionsDialog: {
                staticHauteur: 600,
                hideToolbar: true,
                titre: titre
            }
        });
        return menuItem.action(null, vm.formData)
            .then(function () { }, function (res: any) { })
            .catch(function () { }, function (res: any) { })
            .finally(() => {
                vm.isModalOpen = false;
            });
    }
}