/**
 * Permet de formatter la valeur de DateTime
 * Ex: Pour la recherche on veut substituer l'espace entre Date et Time par un 'T'
 */
export function formatStringDateTime(value: string): string {
    const splittedValue = value.split(' ');

    // Le résultat devrait être [date, time], sinon on retourne la valeur courante
    if (splittedValue.length !== 2) {
        return value;
    }

    return splittedValue.join('T');
}