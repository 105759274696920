import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IBlocDataTypeClass {
    new(options?: IBlocDataTypeOptions): IBlocDataType;
}

export interface IBlocDataTypeOptions extends IDataTypeOptions {
}

export interface IBlocDataType extends IDataType {
}

export default module('core.services.bloc-data-type', []).factory('BlocDataType', BlocDataTypeFactory);

/* @ngInject */
function BlocDataTypeFactory(DataType: IDataTypeClass): IBlocDataTypeClass {
    class BlocDataType extends DataType implements IBlocDataType {}

    return BlocDataType;
}
