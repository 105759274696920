import {IComponentOptions, module} from 'angular';
import CaseUserController from './ex-case-user.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-case-user', []).component('exCaseUser', CaseUserFactory());

/**
 * Représente une case contenant une user
 */
function CaseUserFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                colcod: '@?',
                colnom: '@?',
                colpos: '@?'
            }
        ),
        transclude: true,
        require: {
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: CaseUserController,
        template: require('./ex-case-user.html')
    };
}
