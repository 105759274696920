/**
 * Composant représentant un bouton avec la fonctionnalité de tri
 *
 * @param {ITri} tri   : fonctionnalité du tri
 * @param {string} nom : nom du bouton
 */
import {IComponentOptions, module} from 'angular';
import ButtonTriController from './ex-button-tri.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-button-tri', []).component('exButtonTri', ButtonTriFactory());

function ButtonTriFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.TRI,
            Binding.COL,
            Binding.LIBELLES,
            Binding.DISABLED,
            Binding.DATA_TYPE,
            Binding.SCHEMA_ITEM, {
                replacedValue: '@?',
                titre: "<?"
            }
        ),
        require: {
            multiOccurrenceCtrl: '?^^exMultiOccurrence',
            ecranContextCtrl: '^^exEcranContext',
            gridCtrl: '^^?exGrid'
        },
        controller: ButtonTriController,
        controllerAs: 'vm',
        template: require('./ex-button-tri.html')
    };
}
