/**
 * Composant permettant de genérer un filtre complet.
 *
 * @param {IFiltre} filtre   : obj qui contient les informations nécessaire à la construction d'un filtre.
 * @param {ISelecteur} selecteur : obj qui contient 2 listes:
 *                                                             colonneRight = liste des colonnes actif
 *                                                             colonneLeft  = liste des colonnes non-actif
 */
import {IComponentOptions, module} from 'angular';
import FiltreController from './ex-filtre.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-filtre', [
    'core.services.operateur'
]).component('exFiltre', FiltreFactory());

function FiltreFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.SELECTEUR,
            Binding.MULTI_OCCURRENCE,
            {
                operateurModifiable: '<',
                colonneModifiable: '<',
                colonne: '=',
                operateur: '=',
                valeurs: '=',
                radioValeur: '=',
                data: '=',
                isCritereSuggere: '<'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controller: FiltreController,
        controllerAs: 'vm',
        template: require('./ex-filtre.html')
    };
}
