import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IAugmentedJQuery, IComponentController} from 'angular';

interface IComposantCaseDescription extends IComponentController {
    col: string;
    dataSourceCtrl: IDataSourceController;
}

/* @ngInject */
export default function CaseDescriptionController($element: IAugmentedJQuery) {
    const vm: IComposantCaseDescription = this;

    vm.$onInit = $onInit;

    function $onInit() {
        // Si la case de description est vide, on la cache
        if (vm.col && !vm.dataSourceCtrl.data[vm.col]) {
            $element.hide();
        }
    }
}
