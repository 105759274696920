import { IComponentController, IFilterService, IScope, IRootScopeService, IAugmentedJQuery } from 'angular';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IEtatsResourcesEntite } from '../../resources/etat.resource';
import { IDialog } from '../../services/dialog.service';
import { IApiError } from '../../interfaces/api-error.interface';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';

interface IExEtats extends IComponentController {
    lblTitre: string;
    multiOccurrence: IMultiOccurrence;
    identifierNoneSelector: string;
    etatSelected: IEtatsResourcesEntite;
    busy: boolean;
    ecranContextCtrl: IEcranContextController;
    onEtatChange(): void;
    afficherConfirmerSuppression(ev: MouseEvent, etat: IEtatsResourcesEntite): void;
}

/* @ngInject */
export default function EtatsController(DialogConfirm: IDialog,
    $scope: IScope,
    exLibelleFilter: IFilterService,
    $rootScope: IRootScopeService,
    $element: IAugmentedJQuery,
    exFocus: IFocusService) {
    const vm: IExEtats = this;

    vm.$onInit = $onInit;
    vm.onEtatChange = onEtatChange;
    vm.afficherConfirmerSuppression = afficherConfirmerSuppression;

    //recevoir le message pour reinitialiser le dropdownlist au "Aucun"
    $scope.$on('vm.multiOccurrence.etat.reinitialiser', function () {
        localStorage.setItem(vm.etatSelected.gtecleref.trim(), '$NONE$');
        vm.etatSelected = vm.multiOccurrence.etatDefault;
    });

    //recevoir le message pour definir un etat, si l'etat n'existe pas on reninitialise
    $scope.$on('vm.multiOccurrence.etat.setEtatSansRechercher', function () {
        let flag = false;
        var etatSelected = +(localStorage.getItem(vm.multiOccurrence.etatSelected.gtecleref))

        if (typeof (etatSelected) !== "undefined") {
            var tmpGetEtat = getEtatById(etatSelected);
            if (typeof (tmpGetEtat) !== "undefined") {
                vm.etatSelected = tmpGetEtat;
            } else {
                flag = true;
            }
        } else {
            flag = true;
        }

        //reinitialiser si l'état n'existe pas
        if (flag) {
            $rootScope.$broadcast('vm.multiOccurrence.etat.reinitialiser');
        }
    });

    function $onInit() {
        // On surveille l'état sélectionné d'une seule direction, car on ne veut pas que ce composant le mette à jour directement, mais via une méthode
        $scope.$watch('vm.multiOccurrence.etatSelectedOriginal', (etatSelected: any) => {
            if (etatSelected) {
                vm.etatSelected = getEtatById(etatSelected.id)
                $rootScope.$broadcast('vm.multiOccurrence.etat.setEtatSansRechercher');
            }
        });
    }

    function onEtatChange() {
        vm.busy = true;
        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = vm.etatSelected.id === "$NONE$";
        vm.multiOccurrence.deleteParams("criteremultiple", false).then(() => {
            vm.multiOccurrence.updateSelectedEtat(vm.etatSelected).then(async () => {
                const etat = vm.etatSelected
                //on va remettre l'etat apres quelques millisecondes car il y a des appels (emit) qui reinitialisent le combo d'etat
                vm.busy = false;
                await vm.multiOccurrence.fetchDataList().then(() => { setTimeout(updateEtat, 200, etat) });
            }).catch((error: IApiError) => afficherEtatChangeErreur(error)).finally(() => { vm.busy = false });

            //sauvegarder l'etat selectionne
            localStorage.setItem(vm.etatSelected.gtecleref.trim(), vm.etatSelected.id.toString());
        })
    }

    //fonctionne à executer dans un SetTime
    function updateEtat(etat: any) {
        //on mets le focus dans un autre element pour bien afficher l'option séléctionné
        const div = $element.find('#ex-busy')
        exFocus(div)

        vm.etatSelected = etat;
        //si c'est l'état aucun on va mettre les valeurs par defaut 
        if (vm.etatSelected.id === "$NONE$" && vm?.multiOccurrence?.criteresSuggeres?.liste) {
            const data: any = vm.multiOccurrence.criteresSuggeres.liste.filter((el: any) => el.default != undefined)
            //on continue s'il y a criteres suggeres
            if (data && Array.isArray(data) && data.length > 0) {
                data.forEach((row: any) => {
                    //on cherche l'index de la colonne afin d'obtenir l'information
                    const index = vm.multiOccurrence.criteresSuggeres.liste.findIndex((el: any) => el.col == row.col)
                    const tmp: any = vm.multiOccurrence.criteresSuggeres.liste[index]
                    //on obtiens la valeur par dedaut et le nom du champ
                    const valeurDefault = tmp.default
                    const field = tmp.col
                    //on l'ajoute dans le etatSlected et le multi
                    vm.etatSelected.criteresSuggeresData[field] = valeurDefault
                    vm.multiOccurrence.etatSelected.criteresSuggeresData[field] = valeurDefault
                })
            }
            //on lance la recherche
            vm.multiOccurrence.fetchDataList();
        }
        //on valide si l'etat du composant est le meme que l'etat du multi sinon on execute la fonctionne de nouveau
        if (vm.etatSelected.nom !== vm.multiOccurrence.etatSelected.nom) {
            vm.etatSelected = getEtatById(vm.multiOccurrence.etatSelected.id);
        }
    }

    function afficherEtatChangeErreur(error: IApiError) {
        DialogConfirm.show({
            locals: {
                error,
                lblTitre: 'G_LBL_MSL_CRITERE_RECH',
                lblMessage: 'G_MSG_CHANG_ETAT',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    return vm.multiOccurrence.updateSelectedEtat(vm.etatSelected)
                },
            }
        }).catch(() => {
            vm.etatSelected = getEtatById(vm.multiOccurrence.etatSelected.id);
        });
    }

    function getEtatById(id: number | string) {
        if (id === vm.multiOccurrence.etatDefault.id) {
            return vm.multiOccurrence.etatDefault;
        } else if (vm.multiOccurrence.etatsPredefinis.length) {
            const etatPredefini = vm.multiOccurrence.getEtatPredefiniById(id);
            if (etatPredefini) {
                return etatPredefini;
            }
        }
        return vm.multiOccurrence.etats.find(etat => etat.id === id);
    }

    function afficherConfirmerSuppression(ev: MouseEvent, etat: IEtatsResourcesEntite) {
        ev.stopPropagation();
        $('.md-select-backdrop').click();
        DialogConfirm.show({
            targetEvent: ev,
            locals: {
                lblTitre: exLibelleFilter('G_LBL_MSL_MOD_ETAT_SUP_TITRE') + ' : ' + etat.nom,
                lblDescription: 'G_LBL_MSL_MOD_ETAT_SUP_DESC',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                lblMessage: 'G_MSG_SUPP_ETAT',
                icon: 'delete',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    return vm.multiOccurrence.deleteEtat(etat);
                }
            }
        });
    }
}
