import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CaseBoutonController from './ex-case-bouton.controller';

export default module('core.components.ex-case-bouton', []).component('exCaseBouton', CaseBoutonFactory());

function CaseBoutonFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.LIBELLES.OPTIONAL,
            Binding.DATA_TYPE,
            Binding.MENU,
            Binding.DATA,
            Binding.COL
        ),
        controller: CaseBoutonController,
        controllerAs: 'vm',
        template: require('./ex-case-bouton.html')
    };
}
