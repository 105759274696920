import { IComponentController } from 'angular';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {
    IMenuItemPieceJustificative,
    IMenuItemPieceJustificativeClass
} from '../../services/menu/menu-item-piece-justificative.service';
import { IMenuItemGenererPieceJustificativeClass } from '../..//services/menu/menu-item-generer-piece-justificative.service';
import { IMenuItem } from '../..//services/menu/menu-item.service';

export interface IDialogCycleTransactionnelInformationsController extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    menuItemPieceJustificative: IMenuItemPieceJustificative;
    menuItemGenererPieceJustificative: IMenuItem;
}

/* @ngInject */
export default function DialogCycleTransactionnelInformationsController(MenuItemPieceJustificative: IMenuItemPieceJustificativeClass,
    MenuItemGenererPieceJustificative: IMenuItemGenererPieceJustificativeClass) {
    const vm: IDialogCycleTransactionnelInformationsController = this;

    vm.$onInit = function $onInit() {
        vm.menuItemPieceJustificative = new MenuItemPieceJustificative(vm.monoOccurrence);
        vm.menuItemGenererPieceJustificative = new MenuItemGenererPieceJustificative(vm.monoOccurrence, vm.ecranContextCtrl);
    }
}
