import {IComponentOptions, module} from 'angular';

import NavigationItemController from './ex-navigation-item.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-navigation-item', []).component('exNavigationItem', NavigationItemFactory());

function NavigationItemFactory() : IComponentOptions {
    return {
        bindings: Binding(
            {
                rechercheRapide: '<',
                pracleint: '<',
                items: '<navigationItems',
                queryParams: '<?',
                vaesysapp: '<',
                cleFavoris: "<?"
            }
        ),
        controller: NavigationItemController,
        controllerAs: 'vm',
        template: require('./ex-navigation-item.html')
    };
}
