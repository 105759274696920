import { IAugmentedJQuery, IComponentController, IRootScopeService, IScope } from 'angular';
import { IBoutonRadioConfig, IDataType } from '../../services/data-types/data-type.service';
import { IFormulaireGroupeAccordeon } from '../../services/formulaire/formulaire-groupe-accordeon.service';
import {
    IFormulaireItem,
    IFormulaireItemClass,
    IFormulaireItemFormulaireConditionnel
} from '../../services/formulaire/formulaire-item.service';
import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceClass } from '../../services/multi-occurrence.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { ITemplateController } from '../../services/template.service';
import { IFormulaireTemplateSlot } from '../../services/formulaire/formulaire-template-slot.service';
import { IComportement } from '../../services/comportements.service';
import { IProfil } from '../../resources/profil.resource';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';

export interface IComposantFormulaireInput extends IComponentController {
    occurrence: IMonoOccurrence | IMultiOccurrence;
    currentScope: IScope;
    formulaire: IFormulaire;
    data: any;
    srccodref: string;
    // Détermine si le formulaire est enregistrable, ou plutôt un formulaire temporaire comme les critères suggérés
    enregistrable: boolean;
    templateCtrl: ITemplateController;
    champ: IFormulaireItem;
    dataType: IDataType;
    type: string;
    lblTitreImputation: string;
    col: string;
    selectedTab: number;
    getDecimalsValue(): number;
    getDirection(): string;
    getMasque(): string;
    getRadioBoutonFormulaire(boutonRadio: IBoutonRadioConfig): IFormulaire;
    getTitre(): string;
    hasRadioFormulaire(boutonRadio: { libelle: string, valeur: string }): boolean;
    hasTranscludeSlot(): boolean;
    isChampCritereSuggere(): boolean;
    isDisabled(): boolean;
    isHidden(): boolean;
    isOpened(): boolean;
    isReadonly(): boolean;
    getTexte(): boolean;
    onRadioChange(): void;
}

/* @ngInject */
export default function FormulaireInputController(defaults: IDefaultsService,
    profil: IProfil,
    exFocus: IFocusService,
    FormulaireItem: IFormulaireItemClass,
    MultiOccurrence: IMultiOccurrenceClass,
    $element: IAugmentedJQuery,
    $rootScope: IRootScopeService) {
    const vm: IComposantFormulaireInput = this;

    vm.$onInit = $onInit;
    vm.getDirection = getDirection;
    vm.getDecimalsValue = getDecimalsValue;
    vm.getMasque = getMasque;
    vm.getTitre = getTitre;
    vm.hasTranscludeSlot = hasTranscludeSlot;
    vm.isDisabled = isDisabled;
    vm.isReadonly = isReadonly;
    vm.isHidden = isHidden;
    vm.isChampCritereSuggere = isChampCritereSuggere;
    vm.isOpened = isOpened;
    vm.getTexte = getTexte;
    vm.hasRadioFormulaire = hasRadioFormulaire;
    vm.getRadioBoutonFormulaire = getRadioBoutonFormulaire;
    vm.onRadioChange = onRadioChange;

    function $onInit() {
        if (vm.col && !vm.champ) {
            vm.champ = vm.formulaire ? vm.formulaire.liste.find(((item: IFormulaireItem) => item.col === vm.col)) as IFormulaireItem || new FormulaireItem(vm.col, { onValueChange: vm.onValueChange }) : new FormulaireItem(vm.col);
        }
        if (!vm.champ.dataType) {
            vm.champ.dataType = getDataType();
        }

        defaults(vm, {
            enregistrable: true,
            type: getChampType(),
            srccodref: vm.occurrence.getSrccodref(),
            dataType: getDataType(),
            lblTitreImputation: profil.compagnie && profil.compagnie.imputation.libelle,
            alwaysHidden: vm.champ.hidden === true || (vm.occurrence.comportements[vm.champ.col] && vm.occurrence.comportements[vm.champ.col].hidden === true)
        });

        var champValidation = (vm.occurrence as IMonoOccurrence).reglesValidation[vm.champ.col];
        vm.champ.longueurFixe = champValidation?.flglngfix ? champValidation.lng : vm.champ.longueurFixe;

        //si le flag viderChamp est true on vide l'information du champs dans le formulaire
        if (vm.champ.viderChamp) {
            //si la lov a une valeur selectionee on vide les champs de code et de description
            if (vm.type === "champ-drop-down") {
                Object.keys(vm.data).forEach(key => {
                    if (key.includes(vm.champ.col)) {
                        vm.data[key] = "";
                    }
                })
            } else {
                //on met le champ en undefined le composant l'utilise pour la validation form.$valid
                if(vm.data[vm.champ.col]){
                    vm.data[vm.champ.col] = undefined;
                }
            }
        }
    }

    function getChampType(): string {
        if (vm.occurrence.isChampTexte(vm.champ)) {
            return 'champ-texte';
        } else if (vm.occurrence.isMenuItem(vm.champ)) {
            return 'menu-item';
        } else if (vm.occurrence.isDividerInput(vm.champ)) {
            return 'divider-input';
        } else if (vm.occurrence.isChampDefault(vm.champ)) {
            return 'champ-default';
        } else if (vm.occurrence.isChampNumber(vm.champ)) {
            return 'champ-number';
        } else if (vm.occurrence.isChampDescription(vm.champ) || vm.occurrence.isChampEditionAvancee(vm.champ)) {
            return 'champ-description';
        } else if (vm.occurrence.isChampDate(vm.champ)) {
            return 'champ-date';
        } else if (vm.occurrence.isChampHour(vm.champ)) {
            return 'champ-hour';
        } else if (vm.occurrence.isChampDateHour(vm.champ)) {
            return 'champ-date-hour';
        } else if (vm.occurrence.isChampSelectionMultiple(vm.champ)) {
            return 'champ-selection-multiple';
        } else if (vm.occurrence.isChampSelectionMultipleAdvanced(vm.champ)) {
            return 'champ-selection-multiple-advanced';
        } else if (vm.occurrence.isFormulaireLov(vm.champ)) {
            return 'champ-formulaire-lov';
        } else if (vm.occurrence.isChampDropdown(vm.champ)) {
            return 'champ-drop-down';
        } else if (vm.occurrence.isConditionnelInput(vm.champ)) {
            return 'champ-conditionnel';
        } else if (vm.occurrence.isGroupeAccordeon(vm.champ)) {
            return 'groupe-accordeon';
        } else if (vm.occurrence.isFormulaireGroupe(vm.champ)) {
            return 'champ-formulaire-groupe';
        } else if (vm.occurrence.isFormulaireTabs(vm.champ)) {
            return 'champ-formulaire-tabs';
        } else if (vm.occurrence.isChampBoolean(vm.champ)) {
            return 'champ-boolean';
        } else if (vm.occurrence.isChampRadioButton(vm.champ)) {
            return 'champ-radio-button';
        } else if (vm.occurrence.isChampLovBoutonRadio(vm.champ)) {
            return 'champ-lov-bouton-radio';
        } else if (vm.occurrence.isChampImputation(vm.champ)) {
            return 'champ-imputation';
        } else if (vm.occurrence.isFormulaireTemplateSlot(vm.champ)) {
            return 'formulaire-template-slot';
        } else if (vm.occurrence.isChampIntervalle(vm.champ)) {
            return 'champ-intervalle';
        } else if (vm.occurrence.isChampParametreMultiple(vm.champ)) {
            return 'champ-parametre-multiple';
        }
    }

    function getDirection(): string {
        if (vm.champ.direction) {
            if (vm.champ.direction === 'horizontal') {
                return 'row';
            } else {
                return 'column';
            }
        }
    }

    function getMasque(): string {
        return vm.champ.masque || (vm.dataType && vm.dataType.params && vm.dataType.params.masque ? vm.dataType.params.masque : null);
    }

    function getTitre(): string {
        if (vm.champ.titre instanceof Function) {
            return vm.champ.titre(vm.data);
        } else {
            return vm.champ.titre;
        }
    }

    function hasTranscludeSlot(): boolean {
        // TODO UQ This is REALLY BAD but we need this with shitty angular for templates to work in pe-ecran-container
        // Remove this ASAP
        vm.templateCtrl = vm.templateCtrl ? vm.templateCtrl : ($rootScope as any).templateCtrl;
        const templateSlot: IFormulaireTemplateSlot = <any>vm.champ;
        const bloc = (vm.occurrence.bloc || '$NONE$').toUpperCase();
        return Boolean(vm.templateCtrl && vm.templateCtrl.templates[bloc] && vm.templateCtrl.templates[bloc][templateSlot.slot]);
    }

    function getDataType(): IDataType {
        return vm.dataType || vm.champ.dataType || vm.occurrence.dataTypes[vm.champ.col];
    }

    function getDecimalsValue() {
        const dataTypeDecimals = vm.dataType && vm.dataType.params && vm.dataType.params.decimals;

        return (dataTypeDecimals !== undefined ? dataTypeDecimals : vm.occurrence.schema[vm.champ.col] && vm.occurrence.schema[vm.champ.col].decimals) || 0;
    }

    function isDisabled() {
        return vm.occurrence.isChampDisabled(vm.champ, vm.data);
    }

    function isReadonly() {
        const isMiseAJour = vm.data[vm.occurrence.cleint];
        const securityCycle = vm.formulaire && vm.formulaire.securityCycle;

        // On ne permet pas de modifier directement un champ d'historique de colonne. Il faut passer par la fenêtre à cet effet
        return (vm.champ.historiqueColonne && isMiseAJour) || vm.occurrence.isChampReadonly(vm.champ, isMiseAJour, vm.data, securityCycle, vm.enregistrable);
    }

    function isHidden() {
        return getHiddenValue(vm.champ, vm.data) || getHiddenValue(vm.occurrence.comportements[vm.champ.col], vm.data)
    }

    function getHiddenValue(champ: IFormulaireItem | IComportement, data: any) {
        if (!champ) {
            return false;
        } else if (champ.hidden instanceof Function) {
            return !data || champ.hidden(data);
        } else {
            return champ.hidden;
        }
    }

    function isChampCritereSuggere() {
        if (vm.occurrence instanceof MultiOccurrence) {
            return Boolean(vm.occurrence.criteresSuggeres && vm.occurrence.criteresSuggeres.liste.find((formulaireItem: IFormulaireItem) => formulaireItem === vm.champ));
        } else {
            return false;
        }
    }

    function isOpened(): boolean {
        const groupeAccordeon: IFormulaireGroupeAccordeon = <any>vm.champ;

        if (typeof groupeAccordeon.opened === 'undefined') {
            return !(vm.occurrence.id || (<IMonoOccurrence>vm.occurrence).noId) && !vm.isDisabled();
        } else if (groupeAccordeon.opened instanceof Function) {
            return groupeAccordeon.opened(vm.data);
        } else {
            return groupeAccordeon.opened;
        }
    }

    function getTexte() {
        return (vm.champ as any).texte instanceof Function ? (vm.champ as any).texte(vm.data) : (vm.champ as any).texte;
    }

    function hasRadioFormulaire(boutonRadio: IBoutonRadioConfig): boolean {
        return Boolean(
            vm.champ.formulairesConditionnels &&
            getRadioBoutonFormulaire(boutonRadio)
        );
    }

    function getRadioBoutonFormulaire(boutonRadio: IBoutonRadioConfig): IFormulaire {
        const radioForm = vm.champ.formulairesConditionnels.find((formConditionnel: IFormulaireItemFormulaireConditionnel) => {
            return formConditionnel.valeur === boutonRadio.valeur;
        });

        return radioForm && radioForm.formulaire;
    }

    function onRadioChange(): void {
        if (!(vm.isDisabled() || vm.isReadonly())) {
            const boutonRadio = vm.dataType.params.boutonsRadio.find((boutonRadio: IBoutonRadioConfig) => boutonRadio.valeur === vm.data[vm.champ.col]);
            if (!boutonRadio) return;

            if (hasRadioFormulaire(boutonRadio)) {
                exFocus($element.find(`md-radio-group[name-element="${vm.champ.col}"]`));
            }

            if (boutonRadio.autresValeurs) {
                Object.assign(vm.data, boutonRadio.autresValeurs);
            }

            if (vm.champ.onValueChange) {
                vm.champ.onValueChange(vm.data);
            }
        }
    }
}
