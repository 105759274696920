import {IComponentOptions, module} from 'angular';
import RechercheController from './ex-recherche.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-recherche', []).component('exRecherche', RechercheFactory());

function RechercheFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                disabledIntrospection: '=?',
                rechercheAutomatique: '=?',
                rechercheInput: '=?recherche',
                lblTitre: '<?',
                modifAllow: '<?',
                placeholderRecherche: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: RechercheController,
        controllerAs: 'vm',
        template: require('./ex-recherche.html')
    };
}
