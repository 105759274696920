import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogSelecteurController from './dialog-filtre.controller';

export default module('core.dialogs.dialog-filtre', [
    'core.services.dialog'
]).factory('DialogFiltre', DialogFiltreFactory);

/* @ngInject */
function DialogFiltreFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogSelecteurController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-filtre.html'),
       focusOnOpen: false
   });
}
