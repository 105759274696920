/**
 * Par défaut, les statuts d'erreur (400, 500, etc.) sont résolus comme des succès sur les promises du service $http et
 * sur les $resource. Cet interceptor permet de retourner une exception ce qui simplifie la gestion des erreurs dans
 * les composants.
 *
 * @note En utilisant cet interceptor, la response de succès reçoi une "response", pas les données. La raison pour cela
 * est que les callback passés via .query(params, callback) ne respecte pas les exceptions. Il vaut donc mieux utiliser
 * le $promise.then(). Ce dernier obtient en général seulement les données, mais pas les headers qui sont parfois
 * requises. En passant la "response", on peut donc gérer les requêtes sur une resource uniquement via la promise.
 */
import {IHttpInterceptor, IHttpResponse, IPromise, IQService, module} from 'angular';
import {IIsSuccessResponseService} from '../utils/is-success-response.service';

export default module('core.services.interceptors.error-handling-interceptor', []).factory('errorHandlingInterceptor', errorHandlingInterceptorFactory);

/* @ngInject */
function errorHandlingInterceptorFactory(isSuccessResponse: IIsSuccessResponseService, $q: IQService) : IHttpInterceptor {
    return {
        response(response: IHttpResponse<any>): IPromise<IHttpResponse<any>>|IHttpResponse<any> {
            if (isSuccessResponse(response.status)) {
                return response;
            } else {
                return $q.reject(response);
            }
        }
    };
}
