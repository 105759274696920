/**
 * Ce comportement ajoute un icône de chargement au début de l'élément ciblé lorsque la valeur passée est "true"
 */
import {element as angularElement, IAugmentedJQuery, ICompileService, IScope, module} from 'angular';

interface IExBusyBehavior extends IScope {
    busy: boolean;
    disabled: boolean;
}

export default module('core.behaviors.ex-busy', [])
    .directive('exBusy', BusyDirective);

/* @ngInject */
function BusyDirective($compile: ICompileService) {
    return {
        scope: {
            busy: '<exBusy',
            disabled: '<?ngDisabled'
        },
        compile
    };

    function compile() {
        return function (scope: IExBusyBehavior, element: IAugmentedJQuery) {
            const progress = angularElement(`<md-progress-circular ng-if="busy" md-diameter="16" class="m-r-8 ex-busy-loader"></md-progress-circular>`);
            $compile(progress)(scope);
            element.prepend(progress);

            scope.$watch('busy', (newValue: boolean = false) => {
                element.prop('disabled', newValue || Boolean(scope.disabled));
            });
        }
    }
}
