import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IStringDataTypeClass {
    new(options?: IStringDataTypeOptions): IStringDataType;
}

export interface IStringDataTypeOptions extends IDataTypeOptions {
    masque?: string;
    padStart?: {
        longueur: number;
        caractere: string;
        decimales?: string;
    }
}

export interface IStringDataType extends IDataType {
}

export default module('core.services.string-data-type', []).factory('StringDataType', StringDataTypeFactory);

/* @ngInject */
function StringDataTypeFactory(DataType: IDataTypeClass): IStringDataTypeClass {
    class StringDataType extends DataType implements IStringDataType {
        readonly template: string = `{{vm.data[vm.col]}}`;

        constructor(options: IStringDataTypeOptions = {}) {
            super(options);

            if (options.padStart) {
                if (options.padStart.decimales) {
                    this.template = `{{vm.data[vm.col] | exPadStart : ${options.padStart.longueur} : ${options.padStart.caractere} : ${options.padStart.decimales}}}`;
                } else {
                    this.template = `{{vm.data[vm.col] | exPadStart : ${options.padStart.longueur} : ${options.padStart.caractere}}}`;
                }
            } else {
                const masque = (options.masque || (options.params && options.params.masque));
                if (masque) {
                    this.template = `{{vm.data[vm.col] | exMasque : "${masque}"}}`;
                }

                if (options.params && options.params.trustAsHtml) {
                    this.template = `<span ng-bind-html="vm.data[vm.col] | exTrustedHtml"></span>`
                }
            }

            if (options.params && options.params.alignment) {
                this.alignment = options.params.alignment;
            }
        }
    }

    return StringDataType;
}
