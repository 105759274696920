import { IAugmentedJQuery, IComponentController, IFormController, IScope } from 'angular';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IMonoOccurrence, IMonoOccurrenceClass, IMonoOccurrenceOptions } from '../../services/mono-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IFormulaireItem } from '../../services/formulaire/formulaire-item.service';
import { IRegroupementController } from '../ex-regroupement/ex-regroupement.controller';

interface IComposantCardSaisie extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    name: string;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    monoOccurrence: IMonoOccurrence;
    formulaire: IFormulaire;
    regroupementCtrl: IRegroupementController;
}

/* @ngInject */
export default function CardSaisieController($scope: IScope,
    $element: IAugmentedJQuery,
    MonoOccurrence: IMonoOccurrenceClass) {
    const vm: IComposantCardSaisie = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit(): void {
        $scope.$watch('::vm.formCtrl', (newValue: IFormController) => {
            if (newValue) {
                vm.monoOccurrenceEcranCtrl.registerForm(newValue);
            }
        });

        vm.monoOccurrenceEcranCtrl.monoOccurrence.on('exMenuItemActionDataUpdate', onMenuItemActionDataUpdate);

        if (vm.monoOccurrenceOptions) {
            vm.monoOccurrence = new MonoOccurrence({
                element: $element,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                ...vm.monoOccurrenceOptions
            });
        } else {
            vm.monoOccurrence = vm.monoOccurrenceEcranCtrl.monoOccurrence;
        }

        if (vm.regroupementCtrl) {
            vm.regroupementCtrl.isToujoursVisible = true;
            vm.regroupementCtrl.register(vm.name);
        }
    }

    function $onDestroy() {
        vm.monoOccurrenceEcranCtrl.monoOccurrence.removeListener('exMenuItemActionDataUpdate', onMenuItemActionDataUpdate);
    }

    function onMenuItemActionDataUpdate(data: any) {
        vm.formulaire.liste.forEach((formulaireItem: IFormulaireItem) => {
            if (typeof data[formulaireItem.col] !== 'undefined') {
                vm.monoOccurrenceEcranCtrl.formData[formulaireItem.col] = data[formulaireItem.col];
            }
        })
    }
}
