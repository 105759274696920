import { IComponentOptions, module } from 'angular';
import AxeAffichageController from './ex-axe-affichage.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-axe-affichage', []).component('exAxeAffichage', AxeAffichageFactory());

function AxeAffichageFactory(): IComponentOptions {
    return {
        bindings: Binding(Binding.MULTI_OCCURRENCE, {
            clickRow: '<',
            doubleClickRow: '<',
            closeRow: '<',
            saveAction: '<',
            retryMonoOccurrenceErreur: '<',
            hasTranscludeDetails: '<',
            lblTitreEdition: '<',
            messagesErreur: '<',
            nbColsAffichable: '=?nbColsAffichableOut',
            newOpened: "=",
            newMonoOccurrence: "<"
        }),
        controller: AxeAffichageController,
        controllerAs: 'vm',
        template: require('./ex-axe-affichage.html')
    };
}
