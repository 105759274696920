import * as angular from 'angular';
import {IAttributes, IAugmentedJQuery, IScope, module} from 'angular';

interface IExStickyBehaviorOptions extends IStickyOptions, IScope {
}

export interface IStickyOptions {
    parentLimit?: string;
    scrollParent?: string;
    leftPadding?: number;
    noBottom?: boolean;
}

export default module('core.behaviors.ex-sticky', [])
    .directive('exSticky', StickyDirective);

let watcherId = 1;

/* @ngInject */
function StickyDirective($mdUtil: any) {
    return {
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) {
        const options: IExStickyBehaviorOptions = scope.$eval(attrs.exStickyOptions) || {};
        const scrollParentSelector = options.scrollParent || 'md-content[md-scroll-y]';
        const scrollParent = element.closest(scrollParentSelector);
        const placeholder = angular.element(`<div class="ex-sticky-placeholder"/>`);

        let sticking = false;
        let sticked = false;

        let parentLimitElement: IAugmentedJQuery = element.closest(options.parentLimit);

        if (attrs.exSticky) {
            scope.$watch(attrs.exSticky, (condition: boolean) => {
                if (condition) {
                    if (!sticking) {
                        startSticking();
                    }
                } else if (sticking) {
                    stopSticking();
                }
            });

            scope.$watch(() => sticked && placeholder.outerWidth(), (value: boolean|number) => {
                if (typeof value === 'number') {
                    unstick();
                    stick();
                }
            });

            scope.$on('$destroy', () => {
                stopSticking();
            });
        }

        function startSticking() {
            sticking = true;

            placeholder.insertAfter(element);

            if (!(<any>element).$$exStickyWatcherId) {
                (<any>element).$$exStickyWatcherId = watcherId;
                watcherId++;
            }

            scrollParent.on(`scroll.exSticky.${(<any>element).$$exStickyWatcherId}`, $mdUtil.throttle(onScroll, 50));

        }

        function stopSticking() {
            sticking = false;

            placeholder.remove();
            unstick();

            scrollParent.off(`scroll.exSticky.${(<any>element).$$exStickyWatcherId}`);
        }

        function onScroll() {
            if (!sticked) {
                const positionFromTop = element[0].getBoundingClientRect().top - scrollParent[0].getBoundingClientRect().top;
                if (positionFromTop < 0) {
                    if (parentLimitElement.length) {
                        const limitPosition = parentLimitElement[0].getBoundingClientRect().top;
                        if (limitPosition * -1 < parentLimitElement[0].getBoundingClientRect().bottom) {
                            stick();
                        }
                    } else {
                        stick();
                    }
                }
            } else {
                const positionFromTop = placeholder[0].getBoundingClientRect().top - scrollParent[0].getBoundingClientRect().top;
                if (positionFromTop > 0) {
                    unstick();
                } else if (parentLimitElement.length && !options.noBottom) {
                    const limitPosition = parentLimitElement[0].getBoundingClientRect().top;
                    if (limitPosition * -1 > parentLimitElement[0].getBoundingClientRect().bottom) {
                        unstick();
                    }
                }
            }
        }

        function stick() {
            const scrollTop = scrollParent.scrollTop();

            sticked = true;

            placeholder.width('100%');
            placeholder.height(element.outerHeight());
            placeholder.css('display', 'block');

            adjustPosition();

            element.addClass('ex-sticky-placeholder--sticked');

            scrollParent.scrollTop(scrollTop);
        }

        function unstick() {
            sticked = false;

            element.css({
                top: '',
                left: '',
                width: ''
            });

            element.removeClass('ex-sticky-placeholder--sticked');

            placeholder.css('display', '');
        }

        function adjustPosition() {
            const dialogParent = element.closest('ex-dialog');
            const elementPosition = element.offset();
            const scrollParentPosition = scrollParent.offset();
            const leftPadding = options.leftPadding || 0;

            element.css({
                top: (dialogParent.length) ? scrollParentPosition.top - dialogParent.offset().top : scrollParentPosition.top,
                left: (dialogParent.length) ? elementPosition.left + leftPadding - dialogParent.offset().left : elementPosition.left + leftPadding,
                width: element.width()
            });
        }
    }
}
