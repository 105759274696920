import * as angular from 'angular';
import {module} from 'angular';
import IDialogService = angular.material.IDialogService;
import IDialogOptions = angular.material.IDialogOptions;

export interface IDialogClass {
    new(options: IDialogOptions): IDialog;
}

export interface IDialog {
    show(option: IDialogOptions): angular.IPromise<any>;
    hide(): angular.IPromise<any>;
    options: IDialogOptions;
}

export default module('core.services.dialog', []).factory('Dialog', DialogFactory);

/* @ngInject */
function DialogFactory($mdDialog: IDialogService) {
    const DEFAULT_VALUES = {
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        multiple: true
    };

    class Dialog implements IDialog {
        options: IDialogOptions;

        constructor(options: IDialogOptions) {
            this.options = options;
        }

        show(options: IDialogOptions) {
            return $mdDialog.show(Object.assign({}, options, this.options, DEFAULT_VALUES, {
                locals: Object.assign({}, this.options.locals || {}, options.locals || {})
            }));
        }

        hide() {
            return $mdDialog.hide();
        }
    }

    return Dialog;
}
