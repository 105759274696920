import {module} from 'angular';
import {ISchemas} from '../../interfaces/schemas.interface';
import {IBlocDataTypeClass} from './bloc-data-type.service';
import {IBooleanDataTypeClass} from './boolean-data-type.service';
import {IBoutonRadioDataTypeClass} from './bouton-radio-data-type.service';
import {ICodePostalDataTypeClass} from './code-postal-data-type.service';
import {ICourrielDataTypeClass} from './courriel-data-type.service';
import {IDataTypeClass, IDataTypeMap} from './data-type.service';
import {IDateDataTypeClass} from './date-data-type.service';
import {IDateHeureDataTypeClass} from './date-heure-data-type.service';
import {IEditionAvanceeDataTypeClass} from './edition-avancee-data-type.service';
import {IFavorisDataTypeClass} from './favoris-data-type.service';
import {IHeureDataTypeClass} from './heure-data-type.service';
import {IHyperlienDataTypeClass} from './hyperlien-data-type.service';
import {IImputationDataTypeClass} from './imputation-data-type.service';
import {IIndicateurErreurDataTypeClass} from './indicateur-erreur-data-type.service';
import {ILovBoutonRadioDataTypeClass} from './lov-bouton-radio-data-type.service';
import {ILovDataTypeClass} from './lov-data-type.service';
import {ILovEntiteExterneDataTypeClass} from './lov-entite-externe-data-type.service';
import {ILovRequerantDataTypeClass} from './lov-requerant-data-type.service';
import {INumberDataTypeClass} from './number-data-type.service';
import {IPasswordDataTypeClass} from './password-data-type.service';
import {IPeriodeDataTypeClass} from './periode-data-type.service';
import {ISelectionMultipleDataTypeClass} from './selection-multiple-data-type.service';
import {ISequenceDataTypeClass} from './sequence-data-type.service';
import {IStringDataTypeClass} from './string-data-type.service';
import {ITelephoneDataTypeClass} from './telephone-data-type.service';
import {ITexteDataTypeClass} from './texte-data-type.service';
import {IBoutonDataTypeClass} from './bouton-data-type.service';
import {ISelectionSpecialiseeDataTypeClass} from './selection-specialisee-data-type.service';
import {ILovIntervenantDataTypeClass} from './lov-intervenant-data-type.service';
import {IStatutDataTypeClass} from './statut-data-type.service';
import {IImageDataTypeClass} from './image-data-type.service';
import {ISelectionMultipleAvanceeDataTypeClass} from './selection-multiple-avancee-data-type.service';
import {ICaseEditionAssisteeDataTypeClass} from './case-edition-assistee-data-type.service';
import {ICompteurPiecesJointesDataTypeClass} from './compteur-pieces-jointes-data-type.service';
import {IIntervalleDataTypeClass} from './intervalle-data-type.service';
import {IParametreMultipleDataTypeClass} from './parametre-multiple-data-type.service';
import { IDataTreeDataTypeClass } from './data-tree.service';

export interface IDataTypeManager {
    fromJson(serializedDataTypes: ISerializedDataTypeMap, schema: ISchemas): IDataTypeMap;
}

interface IDataTypeFactoryMap {
    [type: string]: IDataTypeClass;
}

export interface ISerializedDataTypeMap {
    [col: string]: IApiDataType;
}

export interface IApiDataType {
    type: string;
    sousType: string;
}

export default module('core.services.data-type-manager', []).factory('dataTypeManager', DataTypeFactory);

/* @ngInject */
function DataTypeFactory(BlocDataType: IBlocDataTypeClass,
                         BooleanDataType: IBooleanDataTypeClass,
                         BoutonRadioDataType: IBoutonRadioDataTypeClass,
                         CaseEditionAssisteeDataType: ICaseEditionAssisteeDataTypeClass,
                         CodePostalDataType: ICodePostalDataTypeClass,
                         CompteurPiecesJointesDataType: ICompteurPiecesJointesDataTypeClass,
                         CourrielDataType: ICourrielDataTypeClass,
                         DataTreeDataType: IDataTreeDataTypeClass,
                         DateDataType: IDateDataTypeClass,
                         DateHeureDataType: IDateHeureDataTypeClass,
                         EditionAvanceeDataType: IEditionAvanceeDataTypeClass,
                         FavorisDataType: IFavorisDataTypeClass,
                         HeureDataType: IHeureDataTypeClass,
                         BoutonDataType: IBoutonDataTypeClass,
                         HyperlienDataType: IHyperlienDataTypeClass,
                         ImageDataType: IImageDataTypeClass,
                         ImputationDataType: IImputationDataTypeClass,
                         IndicateurErreurDataType: IIndicateurErreurDataTypeClass,
                         IntervalleDataType: IIntervalleDataTypeClass,
                         LovBoutonRadioDataType: ILovBoutonRadioDataTypeClass,
                         LovDataType: ILovDataTypeClass,
                         LovEntiteExterneDataType: ILovEntiteExterneDataTypeClass,
                         LovIntervenantDataType: ILovIntervenantDataTypeClass,
                         LovRequerantDataType: ILovRequerantDataTypeClass,
                         NumberDataType: INumberDataTypeClass,
                         ParametreMultipleDataType: IParametreMultipleDataTypeClass,
                         PasswordDataType: IPasswordDataTypeClass,
                         PeriodeDataType: IPeriodeDataTypeClass,
                         SelectionMultipleDataType: ISelectionMultipleDataTypeClass,
                         SelectionMultipleAvanceeDataType: ISelectionMultipleAvanceeDataTypeClass,
                         SelectionSpecialiseeDataType: ISelectionSpecialiseeDataTypeClass,
                         SequenceDataType: ISequenceDataTypeClass,
                         StringDataType: IStringDataTypeClass,
                         StatutDataType: IStatutDataTypeClass,
                         TelephoneDataType: ITelephoneDataTypeClass,
                         TexteDataType: ITexteDataTypeClass): IDataTypeManager {

    class DataTypeManager {
        private readonly typesMap: IDataTypeFactoryMap = {};

        constructor() {
            this.typesMap = {
                bloc: BlocDataType,
                boolean: BooleanDataType,
                boutonRadio: BoutonRadioDataType,
                caseEditionAssistee: CaseEditionAssisteeDataType,
                codePostal: CodePostalDataType,
                compteurPiecesJointes: CompteurPiecesJointesDataType,
                courriel: CourrielDataType,
                dataTree: DataTreeDataType,
                date: DateDataType,
                dateHeure: DateHeureDataType,
                editionAvancee: EditionAvanceeDataType,
                favoris: FavorisDataType,
                heure: HeureDataType,
                bouton: BoutonDataType,
                hyperlien: HyperlienDataType,
                image: ImageDataType,
                imputation: ImputationDataType,
                indicateurErreur: IndicateurErreurDataType,
                intervalleDataType: IntervalleDataType,
                lov: LovDataType,
                lovBoutonRadio: LovBoutonRadioDataType,
                lovEntiteExterne: LovEntiteExterneDataType,
                lovIntervenant: LovIntervenantDataType,
                lovRequerant: LovRequerantDataType,
                number: NumberDataType,
                parametreMultiple: ParametreMultipleDataType,
                password: PasswordDataType,
                periode: PeriodeDataType,
                selectionMultiple: SelectionMultipleDataType,
                selectionMultipleAvancee: SelectionMultipleAvanceeDataType,
                selectionSpecialisee: SelectionSpecialiseeDataType,
                sequence: SequenceDataType,
                string: StringDataType,
                statut: StatutDataType,
                telephone: TelephoneDataType,
                texte: TexteDataType
            };
        }

        fromJson(serializedDataTypes: ISerializedDataTypeMap, schema: ISchemas): IDataTypeMap {
            return Object.keys(serializedDataTypes).reduce((dataTypes: IDataTypeMap, col: string) => {
                const DataTypeClass: IDataTypeClass = this.typesMap[serializedDataTypes[col].sousType] ?
                    this.typesMap[serializedDataTypes[col].sousType] :
                    this.typesMap[serializedDataTypes[col].type];
                dataTypes[col] = new DataTypeClass({
                    params: serializedDataTypes[col],
                    schemaItem: schema[col]
                });
                return dataTypes;
            }, {});
        }
    }

    return new DataTypeManager();
}
