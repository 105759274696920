import {IComponentOptions, module} from 'angular';
import SelectionEmployeController from './ex-selection-employe.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-selection-employe', []).component('exSelectionEmploye', SelectionEmployeFactory());

function SelectionEmployeFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            Binding.MONO_OCCURRENCE,
            {
                formData: '=?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '^^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: true,
        controller: SelectionEmployeController,
        controllerAs: 'vm',
        template: require('./ex-selection-employe.html')
    };
}
