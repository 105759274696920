import {IComponentController, ITimeoutService} from 'angular';
import {IMenu} from '../../services/menu/menu.service';
import {IMenuItem} from '../../services/menu/menu-item.service';
import {IOccurrence} from '../../services/occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceFonctionNouveauOptions
} from '../../services/multi-occurrence.service';
import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IMenuItemActionClass} from '../../services/menu/menu-item-action.service';
import {IFocusService} from '../../behaviors/ex-focus/ex-focus.behavior';

interface IComposantMenu extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    menu: IMenu;
    occurrence: IOccurrence;
    dataSourceCtrl: IDataSourceController;
    disabled: boolean;
    options: IMultiOccurrenceFonctionNouveauOptions;

    doAction(event: JQueryEventObject, menuItem: IMenuItem, data?: any): void;
    getForageLink(menuItem: IMenuItem): string;
    getMenuItemDisabled(menuItem: IMenuItem): boolean;
    isMenuSelection(): boolean;
    isNouveauAction(): boolean;
    isNouveauActionWithText(): boolean;
    isNouveauForageStatic(): boolean;
    isNouveauMenu(): boolean;
    nouveauMenuHasAnyMenuItem(): boolean;
}

/* @ngInject */
export default function BoutonNouveauController($timeout: ITimeoutService,
                                                defaults: IDefaultsService,
                                                exFocus: IFocusService,
                                                MenuItemAction: IMenuItemActionClass,
                                                MultiOccurrence: IMultiOccurrenceClass) {
    const vm: IComposantMenu = this;

    vm.$onInit = $onInit;
    vm.doAction = doAction;
    vm.getMenuItemDisabled = getMenuItemDisabled;
    vm.getForageLink = getForageLink;
    vm.isMenuSelection = isMenuSelection;
    vm.isNouveauAction = isNouveauAction;
    vm.isNouveauActionWithText = isNouveauActionWithText;
    vm.isNouveauForageStatic = isNouveauForageStatic;
    vm.isNouveauMenu = isNouveauMenu;
    vm.nouveauMenuHasAnyMenuItem = nouveauMenuHasAnyMenuItem;

    function $onInit() {
        defaults(vm, {
            options: {}
        });

        vm.menu.classItem = 'ex-bouton-nouveau-menu-item';
        vm.menu.icon = 'add_circle';
        vm.menu.lblTooltip = 'G_LBL_BTN_NOUVEAU';

        if ('lblTitre' in vm.menu.listeMenuItem[0] && !!vm.menu.listeMenuItem[0].lblTitre) {
            vm.menu.lblTooltip = vm.menu.listeMenuItem[0].lblTitre instanceof Function ? vm.menu.listeMenuItem[0].lblTitre({}) : vm.menu.listeMenuItem[0].lblTitre;
        }
    }

    function doAction(event: JQueryEventObject, menuItem: IMenuItem, data: any = {}) {
        menuItem.action(event, data, vm.occurrence);

        if (isNouveauMenuItemAction() && (<IMultiOccurrence>vm.occurrence).editionRapideActive) {
            const multiOccurrenceElement = (<IMultiOccurrence>vm.occurrence).element;
            vm.occurrence.once('dataListUpdate', () => {
                $timeout(() => {
                    exFocus(multiOccurrenceElement.find('.ex-grid-row:first'));
                }, 350);
            });
        }
    }

    function isNouveauMenuItemAction(): boolean {
        return Boolean(
            vm.occurrence instanceof MultiOccurrence &&
            vm.occurrence.actionsNouveaux &&
            vm.occurrence.actionsNouveaux.listeMenuItem.length === 1 &&
            vm.occurrence.actionsNouveaux.listeMenuItem[0] instanceof MenuItemAction
        );
    }

    function getForageLink(menuItem: IMenuItem) {
        return (vm.dataSourceCtrl) ? menuItem.action(vm.dataSourceCtrl.data, vm.ecranContextCtrl.stateParams) : menuItem.action(null, vm.ecranContextCtrl.stateParams);
    }

    function getMenuItemDisabled(menuItem: IMenuItem) {
        if (vm.disabled) {
            return vm.disabled;
        }

        if (!menuItem) {
            return false;
        }

        if (menuItem.disabled instanceof Function) {
            return menuItem.disabled();
        } else {
            return menuItem.disabled;
        }
    }

    function isMenuSelection(): boolean {
        return Boolean(
            vm.menu && vm.menu.listeMenuItem.length === 1 &&
            vm.options && vm.options.selectionSource
        );
    }

    function isNouveauAction(): boolean {
        return Boolean(vm.menu &&
            vm.menu.listeMenuItem.length === 1 &&
            !(<IMenuItem>vm.menu.listeMenuItem[0]).link &&
            (<IMenuItem>vm.menu.listeMenuItem[0]).iconButton
        );
    }

    function isNouveauActionWithText(): boolean {
        return Boolean(vm.menu &&
            vm.menu.listeMenuItem.length === 1 &&
            !(<IMenuItem>vm.menu.listeMenuItem[0]).link &&
            !(<IMenuItem>vm.menu.listeMenuItem[0]).iconButton
        );
    }

    function isNouveauForageStatic(): boolean {
        return Boolean(vm.menu &&
            vm.menu.listeMenuItem.length === 1 &&
            (<IMenuItem>vm.menu.listeMenuItem[0]).link
        );
    }

    function isNouveauMenu(): boolean {
        return Boolean(vm.menu && vm.menu.listeMenuItem.length > 1);
    }

    function nouveauMenuHasAnyMenuItem() : boolean {
        const fonctions = vm.ecranContextCtrl.ecranDetails.fonctions;
        const mnemonique = vm.ecranContextCtrl.ecranSourceDetails.mnemonique;
        const menuItems = vm.menu.listeMenuItem.filter((menuItem: IMenuItem) => menuItem.fonction);

        return !menuItems.length || menuItems.some((menuItem: IMenuItem) => {
            const key = `${mnemonique.toUpperCase()}.${menuItem.fonction}`;
            
            return !fonctions[key] || Boolean(fonctions[key].flgacc);
        });
    }
}