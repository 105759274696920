import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface ITelephoneDataTypeClass {
    new(options?: ITelephoneDataTypeOptions): ITelephoneDataType;
}

export interface ITelephoneDataTypeOptions extends IDataTypeOptions{
}

export interface ITelephoneDataType extends IDataType {
}

export default module('core.services.telephone-data-type', []).factory('TelephoneDataType', TelephoneDataTypeFactory);

/* @ngInject */
function TelephoneDataTypeFactory(DataType: IDataTypeClass): ITelephoneDataTypeClass {
    class TelephoneDataType extends DataType implements ITelephoneDataType {
        readonly template: string = `<a ng-if="vm.data[vm.col]" href="tel: {{vm.data[vm.col]}}">{{vm.data[vm.col]}}</a>`;
    }

    return TelephoneDataType;
}
