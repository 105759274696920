import { module } from 'angular';

export interface IKeyCodes {
    [key: string]: number;
    TAB: number;
    ENTER: number;
    ESCAPE: number;
    UP: number;
    DOWN: number;
    DELETE: number;
    S: number;
}

export enum KeyCodes {
    TAB = 9,
    ENTER = 13,
    ESCAPE = 27,
    UP = 38,
    DOWN = 40,
    DELETE = 46,
    S = 83
}

export default module('core.constants.key-codes', []).constant('keyCodes', KeyCodes);
