import { IAugmentedJQuery, IComponentController, IScope, element } from 'angular';

import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IOperateurService } from '../../services/operateur.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDataLinker } from '../../services/data-linker.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { formatStringDateTime } from '../../utils/data-time.utils';
import { KeyCodes } from "../../constants/key-codes.constant";

interface IComposantRecherche extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    columnFiltreRecherche: string;
    lblTitre: string;
    rechercheInput: string;
    multiOccurrence: IMultiOccurrence;
    cleLblRechercheGlobal: string;
    rechercheAutomatique: boolean;
    modifAllow: boolean;
    scopeColonnesHidden: any;
    dataType: IDataType;
    currentFiltreRechercheAutomatique: IFiltre
    applySearch(): void;
    useKeyBindings(event: KeyboardEvent): void;
    selectColonneFiltre(colonne: string): void;
    resetRecherche(): void;
    eraseSearch(): void;
    getPlaceholder(): string;
    filterColonneVisible(colonne: string): boolean;
    getEnteteLibelle(colonne: string): string;
}

/* @ngInject */
export default function RechercheController(defaults: IDefaultsService,
    $scope: IScope,
    $element: IAugmentedJQuery,
    Operateur: IOperateurService,
    Filtre: IFiltreClass,
    dataLinker: IDataLinker) {
    const vm: IComposantRecherche = this;

    vm.useKeyBindings = useKeyBindings;
    vm.applySearch = applySearch;
    vm.selectColonneFiltre = selectColonneFiltre;
    vm.resetRecherche = resetRecherche;
    vm.eraseSearch = eraseSearch;
    vm.getPlaceholder = getPlaceholder;
    vm.filterColonneVisible = filterColonneVisible;
    vm.getEnteteLibelle = getEnteteLibelle;

    vm.$onInit = function $onInit() {
        defaults(vm, {
            lblTitre: vm.multiOccurrence.fonctions.placeholderRecherche ? vm.multiOccurrence.fonctions.placeholderRecherche : 'G_LBL_BAR_FILTRE_TOUTES_COL',
            rechercheInput: '',
            modifAllow: true
        });

        vm.scopeColonnesHidden = $scope.$new();
        vm.scopeColonnesHidden.data = {};
        dataLinker.link($element, vm.scopeColonnesHidden.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        vm.columnFiltreRecherche = Filtre.CLE_RECHERCHE_GLOBALE;
        vm.cleLblRechercheGlobal = Filtre.CLE_RECHERCHE_GLOBALE;

        const isRechercheSimple = (!vm.multiOccurrence.fonctions.filtrer || vm.multiOccurrence.fonctions.rechercheUnique);

        vm.multiOccurrence.ready.then(() => {
            const positionFiltreSimple = vm.multiOccurrence.etatSelectedOriginal.filtres.length;
            const filtres = vm.multiOccurrence.etatSelected.filtres;
            if (isRechercheSimple && filtres[positionFiltreSimple] && filtres[positionFiltreSimple].getValeur()) {
                vm.rechercheInput = vm.multiOccurrence.etatSelected.filtres[positionFiltreSimple].getValeur();
                vm.multiOccurrence.rechercheVisible = true;
            }
        })
        if (vm.rechercheAutomatique) {
            $scope.$watch("vm.rechercheInput", (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    applySearch()
                }
            })
        }
    }

    function selectColonneFiltre(colonne: string) {
        vm.columnFiltreRecherche = colonne;
        vm.dataType = vm.multiOccurrence.dataTypes[colonne];

        positionneFocus();

        if (vm.rechercheAutomatique) {
            applySearch();
        }
    }

    function getEnteteLibelle(col: string) {
        return vm.multiOccurrence.getEnteteLibelle(col, vm.multiOccurrence.colonnesEnteteTitre, true);
    }

    function applySearch() {
        if (vm.multiOccurrence.fonctions.filtrer && !vm.multiOccurrence.fonctions.rechercheUnique) {
            applySearchFilter();
        } else {
            applySearchFilterRechercheSimple();
        }

        vm.multiOccurrence.emit("uqApplySearch")
    }

    function applySearchFilter() {
        if (vm.rechercheInput && vm.rechercheInput.length > 0 && !vm.rechercheAutomatique) {
            vm.multiOccurrence.etatSelected.addFiltre(new Filtre({
                colonne: vm.columnFiltreRecherche,
                operateur: Operateur.CONTIENT,
                valeur: formatInputValue(vm.rechercheInput),
                modifiable: vm.modifAllow
            }));

            if (vm.multiOccurrence.fonctions.filtrer && !vm.multiOccurrence.fonctions.rechercheUnique) {
                vm.resetRecherche();
            }

            vm.columnFiltreRecherche = vm.cleLblRechercheGlobal;
        } else if (vm.rechercheAutomatique) {
            if (vm.currentFiltreRechercheAutomatique) {
                vm.multiOccurrence.etatSelected.removeFiltre(vm.multiOccurrence.etatSelected.filtres.findIndex(filtre => filtre.id === vm.currentFiltreRechercheAutomatique.id))
                vm.currentFiltreRechercheAutomatique = undefined
            }

            if (vm.rechercheInput && vm.rechercheInput.length > 0) {
                vm.currentFiltreRechercheAutomatique = new Filtre({
                    colonne: vm.cleLblRechercheGlobal,
                    operateur: Operateur.CONTIENT,
                    valeur: formatInputValue(vm.rechercheInput),
                    modifiable: vm.modifAllow
                })
                vm.multiOccurrence.etatSelected.addFiltre(vm.currentFiltreRechercheAutomatique);
            }
        }
    }

    function applySearchFilterRechercheSimple() {
        if (vm.rechercheInput.length > 0) {
            const valeur = formatInputValue(vm.rechercheInput);

            const filtre = new Filtre({
                colonne: vm.columnFiltreRecherche,
                operateur: Operateur.CONTIENT,
                valeur,
                readOnly: true,
                visible: false
            });

            if (vm.multiOccurrence.etatSelected.filtres.length > vm.multiOccurrence.etatSelectedOriginal.filtres.length) {
                vm.multiOccurrence.etatSelected.replaceFiltre(filtre, vm.multiOccurrence.etatSelectedOriginal.filtres.length);
            } else {
                vm.multiOccurrence.etatSelected.addFiltre(filtre);
            }
        } else {
            eraseSearch();
        }

        if (!vm.rechercheAutomatique) {
            vm.columnFiltreRecherche = vm.cleLblRechercheGlobal;
        }
    }

    function formatInputValue(value: string) {
        const schemaItem = vm.multiOccurrence.getSchemaItem(vm.columnFiltreRecherche);

        if (schemaItem && schemaItem.type === 'date') {
            return formatStringDateTime(value);
        }

        return value;
    }

    function eraseSearch() {
        if (!vm.multiOccurrence.fonctions.filtrer || vm.multiOccurrence.fonctions.rechercheUnique) {
            if (vm.multiOccurrence.etatSelected.filtres.length > vm.multiOccurrence.etatSelectedOriginal.filtres.length) {
                vm.multiOccurrence.etatSelected.removeFiltre(vm.multiOccurrence.etatSelectedOriginal.filtres.length);
            }
        }
        resetRecherche();
        positionneFocus();
    }

    function resetRecherche() {
        vm.rechercheInput = '';
    }

    function useKeyBindings(event: KeyboardEvent) {
        const keyCode = event.which || event.keyCode;
        // On applique le filtre sur ENTER seulemnt en mode recherche complète
        if (!vm.rechercheAutomatique && keyCode === KeyCodes.ENTER && !element(event.target).hasClass('ex-introspection--active')) {
            event.preventDefault();
            vm.applySearch();
        }
    }

    function getPlaceholder() {
        return (vm.columnFiltreRecherche !== vm.cleLblRechercheGlobal) ? vm.columnFiltreRecherche : vm.lblTitre;
    }

    function filterColonneVisible(colonne: string) {
        const colonneParametre = vm.multiOccurrence.colonnesParametres[colonne];
        const hidden = colonneParametre && colonneParametre.hidden;

        if (hidden instanceof Function) {
            return !hidden(vm.scopeColonnesHidden.data);
        } else {
            return !hidden;
        }
    }

    function positionneFocus() {
        $element.find('.ex-recherche-input').focus();
    }
}
