import { IComponentOptions, module } from 'angular';
import CriteresSuggeresController from './ex-criteres-suggeres.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-criteres-suggeres', []).component('exCriteresSuggeres', CriteresSuggeresFactory());

function CriteresSuggeresFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                formCtrl: '=?',
                multiOccurrencesAdditionnels: '<?',
                criteresSuggeresVisibles: '=?',
                nouveauxCriteresSuggeres: '=?',
                options: '<?',
                multiOccurrenceOptionsPartage: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            multiOccurrenceCtrl: '^^?exMultiOccurrence',
            monoOccurrenceEcranCtrl: '^^?exMonoOccurrenceEcran'
        },
        controllerAs: 'vm',
        controller: CriteresSuggeresController,
        transclude: true,
        template: require('./ex-criteres-suggeres.html')
    };
}
