import {module} from 'angular';

export interface IMenuMessageClass {
    new (lblMessage: string): IMenuMessage;
}

export interface IMenuMessage {
    readonly lblMessage: string;
}


export default module('core.services.menu-message', []).factory('MenuMessage', MenuMessageFactory);

/* @ngInject */
function MenuMessageFactory() {
    class MenuMessage implements IMenuMessage {
        readonly lblMessage: string;

        constructor(lblMessage: string) {
            this.lblMessage = lblMessage;

        }
    }

    return MenuMessage;
}
