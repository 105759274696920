import { IAugmentedJQuery, IComponentController, IScope } from 'angular';
import { ISelecteur } from '../../services/selecteur.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

interface IComposantSelectColonne extends IComponentController {
    selecteur: ISelecteur;
    model: any;
    libelles: any;
    colonneNonSelectionnable: boolean;
    multiOccurrence: IMultiOccurrence;
    ecranContextCtrl: IEcranContextController;
    closeAction(): void;
    filterColonneVisible(colonne: string): boolean;
    getEnteteLibelle(colonne: string): string;
}
/* @ngInject */
export default function SelectColonneController($scope: IScope,
    $element: IAugmentedJQuery,
    dataLinker: IDataLinker) {
    const vm: IComposantSelectColonne = this;

    vm.$onInit = $onInit;
    vm.filterColonneVisible = filterColonneVisible;
    vm.getEnteteLibelle = getEnteteLibelle;

    function $onInit() {
        vm.scopeColonnesHidden = $scope.$new();
        vm.scopeColonnesHidden.data = {};
        dataLinker.link($element, vm.scopeColonnesHidden.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        vm.colonneNonSelectionnable = (vm.model !== '*' && !vm.selecteur.colonneRight.includes(vm.model) && !vm.selecteur.colonneLeft.includes(vm.model));
    }

    function getEnteteLibelle(col: string): string {
        return vm.multiOccurrence.getEnteteLibelle(col, vm.selecteur.colonneRegroupement, true);
    }

    function filterColonneVisible(colonne: string) {
        const colonneParametre = vm.multiOccurrence.colonnesParametres[colonne];
        const hidden = colonneParametre && colonneParametre.hidden;

        if (typeof hidden === 'function') {
            return !hidden(vm.scopeColonnesHidden.data);
        } else {
            return !Boolean(hidden);
        }
    }
}
