import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogAlertController from './dialog-alert.controller';

export default module('core.dialogs.dialog-alert', [
    'core.services.dialog'
]).factory('DialogAlert', DialogAlertFactory);

/* @ngInject */
function DialogAlertFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogAlertController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-alert.html')
   });
}
