import IDialogOptions = angular.material.IDialogOptions;
import IResourceService = angular.resource.IResourceService;
import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IScope,
    ITimeoutService
} from 'angular';
import { IDataImputation } from '../../dialogs/dialog-imputation/dialog-imputation.controller';
import { IDataType } from '../../services/data-types/data-type.service';
import { IDialog } from '../../services/dialog.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IFilterExDate } from '../../filters/ex-date.filter';
import { IProfil } from '../../resources/profil.resource';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IKeyCodes } from '../../constants/key-codes.constant';

interface IComposantInputImputation extends IComponentController {
    readonly isCritereRecherche: boolean;
    readonly champsImputation: Array<IChampImputation>;
    readonly lblTitre: string;
    readonly titre: string;
    readonly icon: string;

    ecranContextCtrl: IEcranContextController;
    value: string;
    data: IDataImputation;
    dataType: IDataType;
    nameElement: string;
    col: string;
    messageErreur: string;
    srccodref: string;
    formCtrl: IFormController;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    required: boolean;
    readonly: boolean;
    hidden: boolean;
    openingDialog: boolean;
    skipValidation: boolean;
    paramsSecurite: any;
    updating: boolean;
    cleints: Array<string>;
    openDialogImputationAction(ev: JQueryEventObject): void;
    getImputationMasque(): string;
    onKeydown(event: JQueryEventObject): void;
}

interface IChampImputation {
    vaedeicle: string;
    deilib: string;
}

export interface ILovParamsImputation {
    code: string;
    col: string;
    lblTitre: string;
}

/* @ngInject */
export default function InputImputationController(DialogImputation: IDialog,
    ApiConfig: IApiConfig,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    $resource: IResourceService,
    $scope: IScope,
    $timeout: ITimeoutService,
    defaults: IDefaultsService,
    exDateFilter: IFilterExDate,
    profil: IProfil,
    parametresSecurite: IParametresSecuriteService,
    keyCodes: IKeyCodes) {
    const vm: IComposantInputImputation = this;

    vm.$onInit = $onInit;
    vm.openDialogImputationAction = openDialogImputationAction;
    vm.onKeydown = onKeydown;
    vm.oldValuesContext = {};

    function $onInit() {
        defaults(vm, {
            enableCount: false,
            champsImputation: profil.compagnie.imputation.champs,
            icon: 'featured_play_list',
            titre: (!vm.lblTitre) ? profil.compagnie.imputation.libelle : vm.lblTitre,
            isCritereRecherche: isCritereRecherche(),
            paramsSecurite: { ...parametresSecurite(vm.ecranContextCtrl.stateParams), srccod: vm.srccodref },
            cleints: Object.keys(vm.dataType.params.cols).map(colImputation => vm.dataType.params.cols[colImputation])
        });

        if (vm.required) {
            $scope.$watch(`vm.formCtrl.${vm.nameElement}.$error.required`, requiredError => {
                if (requiredError) {
                    updateValues({ imp: vm.value });
                }
            })
        }

        $timeout(() => {
            if (vm.formCtrl[vm.nameElement]) {
                vm.formCtrl[vm.nameElement].$asyncValidators.validateImputation = async (modelValue: string, viewValue: string) => {
                    if ((vm.required || modelValue || viewValue) && !vm.readonly) {
                        if (vm.skipValidation) {
                            return vm.skipValidation = false;
                        } else {
                            return validateAction({ imp: viewValue, usicleint: vm.data.usicleint });
                        }
                    } else if (!modelValue || !viewValue) {
                        //Si le champ est vide, on s'assure que toutes les clés d'imputation sont à null
                        Object.keys(vm.dataType.params.cols).forEach(colImputation => {
                            vm.data[vm.dataType.params.cols[colImputation]] = null;
                        });
                    }
                };

                if (vm.value && !vm.readonly && !vm.hidden && vm.isValidatingAtLoad && vm.isValidatingAtLoad(vm.data)) {
                    //S'il y a une valeur au chargement du champ, on vérifie qu'elle est valide.
                    vm.formCtrl[vm.nameElement].$validate();
                }
            }
        });

        if (vm.dataType.params.valeursDefaut) {
            const expressionsToWatch: Array<string> = [];
            for (const col of vm.dataType.params.valeursDefaut.declencheurs) {
                if (vm.formCtrl.hasOwnProperty(col)) {
                    expressionsToWatch.push(`vm.data.${col}`);
                } else if (vm.monoOccurrenceEcranCtrl.monoOccurrence.schema.hasOwnProperty(col)) {
                    expressionsToWatch.push(`vm.monoOccurrenceEcranCtrl.monoOccurrence.data.${col}`);
                }
            }

            $scope.$watchGroup(expressionsToWatch, (newValues: Array<string | number>, oldValues: Array<string | number>) => {
                if (newValues.some((newValue, index) => newValue !== oldValues[index] && (newValue || oldValues[index]))) {
                    getDefaultValueFromDocument();
                }
            });
        }
    }

    function onKeydown(event: JQueryEventObject) {
        switch (event.which) {
            case keyCodes.DOWN:
            case keyCodes.UP:
                if (!event.ctrlKey && !event.metaKey) {
                    openDialogImputationAction(event);
                    break;
                }
        }
    }

    function isCritereRecherche() {
        return Boolean(
            (!vm.multiOccurrenceCtrl && !vm.monoOccurrenceEcranCtrl) ||
            (vm.multiOccurrenceCtrl &&
                vm.multiOccurrenceCtrl.multiOccurrence.criteresSuggeres &&
                vm.multiOccurrenceCtrl.multiOccurrence.criteresSuggeres.liste))
    }

    function openDialogImputationAction(ev: JQueryEventObject) {
        vm.openingDialog = true;
        if (!vm.formCtrl[vm.nameElement].$pending) {
            openDialogImputation(ev);
        } else {
            //On doit attendre que le champ soit mis à jour.
            $timeout(() => {
                openDialogImputationAction(ev);
            });
        }
    }

    function openDialogImputation(ev: JQueryEventObject) {
        const dialogOptions: IDialogOptions = {
            locals: {
                targetEvent: ev,
                lovsParams: getImputationLovsParams(),
                data: getDialogData(),
                resourceParams: getContextValues(),
                isCritereRecherche: vm.isCritereRecherche,
                readonly: vm.readonly,
                icon: vm.icon,
                srccodref: vm.srccodref,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranContext: vm.ecranContextCtrl,
                indsai: vm.dataType.params.indicateurSaisie,
                col: vm.col
            },
            multiple: true
        };

        DialogImputation.show(dialogOptions).then((data: IDataImputation) => {
            if (!vm.readonly) {
                vm.skipValidation = true;
                vm.formCtrl[vm.nameElement].$setValidity('validateImputation', true);
                updateValues({ ...data, usicleint: vm.data.usicleint });
            }
        }).finally(() => {
            exFocus($element);
            vm.openingDialog = false;
        });
    }

    function getDialogData() {
        const data = { ...vm.data };

        Object.keys(vm.dataType.params.cols).forEach((colImputation: string) => {
            data[colImputation] = data[vm.dataType.params.cols[colImputation]];
        });

        return data;
    }

    function getImputationLovsParams() {
        let lovsParams: Array<ILovParamsImputation> = [];

        vm.champsImputation.forEach((champ) => {
            const col = getColName(champ);
            lovsParams.push({
                col,
                code: champ.vaedeicle.toLowerCase(),
                lblTitre: champ.deilib
            });
        });
        return lovsParams;
    }

    function getColName(champ: IChampImputation) {
        return Object.keys(vm.dataType.params.cols).find(col => col.startsWith(champ.vaedeicle.toLowerCase()));
    }

    async function validateAction(data: IDataImputation) {
        vm.messageErreur = '';
        const imp = vm.value || data.imp;
        if (!imp || !imp.toString().trim()) {
            for (const champ of vm.champsImputation) {
                vm.data[`${champ.vaedeicle.toLowerCase()}cleint`] = null;
            }
            vm.data[vm.col] = null;
        } else {
            return validateData(data).then(updateValues).catch(erreur => {
                //Si l'erreur survient au chargement du champ, on doit le rendre invalide.
                vm.formCtrl[vm.nameElement].$setDirty();
                vm.formCtrl[vm.nameElement].$setTouched();

                //On vide les clés quand le champ est invalide
                for (const champ of vm.champsImputation) {
                    vm.data[`${champ.vaedeicle.toLowerCase()}cleint`] = null;
                }

                if (erreur && erreur.data && erreur.data.code === 'SOFE-20004') {
                    vm.messageErreur = erreur.data.message;
                }

                return Promise.reject()
            });
        }
    }

    function validateData(data: IDataImputation) {
        return $resource(`${ApiConfig.ROOT}/actions-imputation/validate/:col/:cle?`, {
            col: vm.col,
            ...data,
            ...getContextValues(),
            ...vm.paramsSecurite
        }).get().$promise
    }

    function getContextValues() {
        let contextValues = {};
        let ecranData: IDataImputation;

        if (vm.isCritereRecherche) {
            ecranData = vm.multiOccurrenceCtrl ? vm.multiOccurrenceCtrl.nouveauxCriteresSuggeres : vm.data; // Dans le cas où le champ est dans une modale, on se limite aux données de celle-ci.
        } else {
            ecranData = vm.monoOccurrenceEcranCtrl ? vm.monoOccurrenceEcranCtrl.monoOccurrence.data : vm.multiOccurrenceCtrl.nouveauxCriteresSuggeres;
        }

        ecranData = ecranData || {};

        const ecranContext = vm.dataType.params.context ? Object.keys(vm.dataType.params.context).reduce((values: any, col: string) => {
            const colName: string = vm.dataType.params.context[col];
            if (ecranData.hasOwnProperty(colName) || vm.data.hasOwnProperty(colName)) {
                if (col === 'docdat') {
                    const docdat = ecranData[colName] || vm.data[colName];
                    values[col] = exDateFilter(docdat.toString());
                } else if (col === 'usrcleintcre') { // ce champ doit absolument être pris dans l'enregistrement maître
                    if (typeof vm.monoOccurrenceEcranCtrl !== 'undefined') {
                        values[col] = ecranData[colName];
                    }
                } else {
                    values[col] = ecranData[colName] || vm.data[colName];
                }
            } else if (vm.ecranContextCtrl.stateParams.hasOwnProperty(colName)) {
                values[col] = vm.ecranContextCtrl.stateParams[colName];
            }

            // Relance la validation si l'un des champs du contexte change
            $scope.$watch(`vm.multiOccurrenceCtrl.nouveauxCriteresSuggeres.${colName}`, (value) => {
                if (vm.oldValuesContext[colName] !== value) {
                    vm.formCtrl[vm.nameElement].$validate();
                    vm.oldValuesContext[colName] = value;
                }
            });

            return values;
        }, {}) : {};

        Object.assign(contextValues, ecranContext);

        if (vm.isCritereRecherche) {
            const defaultContext = {
                intcleintreq: 0,
                indsai: 2 //Indique que la saisie de chacun des champs qui constituent l'imputation est optionnelle
            };
            contextValues = { ...defaultContext, ...ecranContext };
        }

        return contextValues;
    }

    function updateValues(data: IDataImputation) {
        Object.keys(vm.dataType.params.cols).forEach((colImputation: string) => {
            vm.data[vm.dataType.params.cols[colImputation]] = data[colImputation];
        });
        vm.formCtrl[vm.nameElement].$viewValue = data.imp;
        vm.formCtrl[vm.nameElement].$commitViewValue();
        vm.formCtrl[vm.nameElement].$render();//Affiche le changement dans le input
    }

    function getDefaultValueFromDocument() {
        vm.updating = true;
        const params = Object.keys(vm.dataType.params.valeursDefaut.cols).reduce((values: any, col: any) => {
            const colName = vm.dataType.params.valeursDefaut.cols[col];
            if (vm.monoOccurrenceEcranCtrl.monoOccurrence.data.hasOwnProperty(colName) || vm.data.hasOwnProperty(colName)) {
                values[col] = vm.monoOccurrenceEcranCtrl.monoOccurrence.data[colName] || vm.data[colName];
            }
            return values;
        }, {});

        $resource(`${ApiConfig.ROOT}/actions-imputation/default-value/`, {
            srccod: vm.monoOccurrenceEcranCtrl.monoOccurrence.srccod,
            imp: vm.value,
            ...params,
            ...vm.paramsSecurite
        }).get().$promise.then((result) => {
            if (result.imp !== vm.value) {
                vm.value = result.imp;
                delete result.imp;
                Object.assign(vm.data, result);
            }
        }).finally(() => {
            vm.updating = false;
        });
    }
}
