import { IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { ITranscludeSlotFunction } from '../../interfaces/transclude-function.interface';
import { IComponentController, IScope } from 'angular';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IDefaultsService } from '../../services/utils/defaults.service';
import isMobile from "../../constants/mobile.constant";

export interface IComposantCardMultiOccurrenceTemplate extends IComponentController {
    libelles: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    transcludeFn: ITranscludeSlotFunction;
    hasAccordeon: boolean;
    hasTotal: boolean;
    noHeader?: boolean;
    onCheckboxChange?: { (variables: { data: any }): { data: any } };
    emitCheckboxChange(data: any): void;
}

/* @ngInject */
export default function CardMultiTemplateController(defaults: IDefaultsService,
    $transclude: ITranscludeSlotFunction,
    $scope: IScope) {
    const vm: IComposantCardMultiOccurrenceTemplate = this;

    vm.$onInit = $onInit;
    vm.emitCheckboxChange = emitCheckboxChange;

    function $onInit() {
        defaults(vm, {
            etatErreur: false,
            etatAvertissement: false
        });
        vm.scopeId = $scope.$id;

        vm.libelles = vm.libelles || (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles);

        vm.hasAccordeon = (typeof vm.hasAccordeon !== 'undefined') ? vm.hasAccordeon : $transclude.isSlotFilled('accordeon');

        vm.hasTotal = $transclude.isSlotFilled('total');

        vm.multiOccurrenceOptions.fonctions = {
            exportation: !isMobile,
            recherche: true,
            rechercheParColonne: true,
            filtrer: true,
            enregistrerUnEtat: false,
            pagination: true,
            importation: false,
            afficherMenuSettingDansEntete: true,
            suiviModification: true,
            ...vm.multiOccurrenceOptions.fonctions
        };

        vm.multiOccurrenceOptions.pagination = {
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100],
            ...vm.multiOccurrenceOptions.pagination
        };
    }

    function emitCheckboxChange(data: any): void {
        if (!vm.onCheckboxChange) { return; }
        vm.onCheckboxChange({ data: data });
    }
}
