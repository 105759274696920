import { module } from 'angular';

export interface IOperateurService {
    new(nom: string, operatorConfig: IOperateurConfig): IOperateur;
    EGALE: IOperateur;
    EGALE_OU_NULL: IOperateur;
    EGALE_PAS: IOperateur;
    PLUS_GRAND: IOperateur;
    PLUS_GRAND_EGALE: IOperateur;
    PLUS_PETIT: IOperateur;
    PLUS_PETIT_EGALE: IOperateur;
    NULL: IOperateur;
    PAS_NULL: IOperateur;
    ENTRE: IOperateur;
    CONTIENT: IOperateur;
    PAS_CONTIENT: IOperateur;
    COMMENCE_PAR: IOperateur;
    PAS_COMMENCE_PAR: IOperateur;
    TERMINE_PAR: IOperateur;
    PAS_TERMINE_PAR: IOperateur;
    ALL_OPERATEURS: Array<IOperateur>
    BASIC_OPERATEURS: Array<IOperateur>
    STRING_OPERATEURS: Array<IOperateur>
    CREE_DEPUIS: IOperateur
    MODIF_DEPUIS: IOperateur
    CREE_MODIF_DEPUIS: IOperateur
    [key: string]: any;
}

interface IOperateurs {
    EGALE: IOperateurConfig;
    EGALE_OU_NULL: IOperateurConfig;
    EGALE_PAS: IOperateurConfig;
    PLUS_GRAND: IOperateurConfig;
    PLUS_GRAND_EGALE: IOperateurConfig;
    PLUS_PETIT: IOperateurConfig;
    PLUS_PETIT_EGALE: IOperateurConfig;
    NULL: IOperateurConfig;
    PAS_NULL: IOperateurConfig;
    ENTRE: IOperateurConfig;
    CONTIENT: IOperateurConfig;
    PAS_CONTIENT: IOperateurConfig;
    COMMENCE_PAR: IOperateurConfig;
    PAS_COMMENCE_PAR: IOperateurConfig;
    TERMINE_PAR: IOperateurConfig;
    PAS_TERMINE_PAR: IOperateurConfig;
    CREE_DEPUIS: IOperateurConfig
    MODIF_DEPUIS: IOperateurConfig
    CREE_MODIF_DEPUIS: IOperateurConfig
    [key: string]: IOperateurConfig;
}

interface IOperateurConfig {
    TEXTE?: string;
    TEXTE_PLURIEL?: string;
    TYPES: Array<string>;
    FORMATTER(col: string, val?: any, val2?: any): string;
}

export interface IOperateur {
    nom: string;
    texte: string;
    textePluriel?: string;
    types: Array<string>;
    format(col: string, val?: any, val2?: any): string;
    isAucuneValeurOperateur(): boolean;
    isValeurUniqueOperateur(): boolean;
    isValeurDoubleOperateur(): boolean;
    isRecherchePartielleOperateur(): boolean;
    isRechercheExacteOperateur(): boolean;
}

export default module('core.services.operateur', []).factory('Operateur', OperateursFactory);

const OperateurTypes = {
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date'
};

const Operateurs: IOperateurs = {
    EGALE: {
        TEXTE: 'G_LBL_OPERATEUR_EGALE',
        FORMATTER: (col: string, val: string) => `${col}{:}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    EGALE_OU_NULL: {
        FORMATTER: (col: string, val: string) => `${col}{:!}${val}`,
        TEXTE: 'G_LBL_OPERATEUR_EGALE_OU_NULL',
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    EGALE_PAS: {
        TEXTE: '≠',
        FORMATTER: (col: string, val: string) => `${col}{!:}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PLUS_GRAND: {
        TEXTE: 'G_LBL_OPERATEUR_PLUS_GRAND',
        FORMATTER: (col: string, val: string) => `${col}{>}${val}`,
        TYPES: [OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PLUS_GRAND_EGALE: {
        TEXTE: 'G_LBL_OPERATEUR_PLUS_GRAND_EGALE',
        FORMATTER: (col: string, val: string) => `${col}{>:}${val}`,
        TYPES: [OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PLUS_PETIT: {
        TEXTE: 'G_LBL_OPERATEUR_PLUS_PETIT',
        FORMATTER: (col: string, val: string) => `${col}{<}${val}`,
        TYPES: [OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PLUS_PETIT_EGALE: {
        TEXTE: 'G_LBL_OPERATEUR_PLUS_PETIT_EGALE',
        FORMATTER: (col: string, val: string) => `${col}{<:}${val}`,
        TYPES: [OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    NULL: {
        TEXTE: 'G_LBL_OPERATEUR_NULL',
        FORMATTER: (col: string) => col,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PAS_NULL: {
        TEXTE: 'G_LBL_OPERATEUR_PAS_NULL',
        FORMATTER: (col: string) => `!${col}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    ENTRE: {
        TEXTE: 'G_LBL_OPERATEUR_ENTRE',
        FORMATTER: (col: string, val: string, val2: string) => `${Operateurs.PLUS_GRAND_EGALE.FORMATTER(col, val)};${Operateurs.PLUS_PETIT_EGALE.FORMATTER(col, val2)}`,
        TYPES: [OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    CONTIENT: {
        TEXTE: 'G_LBL_OPERATEUR_CONTIENT',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_CONTIENT',
        FORMATTER: (col: string, val: string) => `${col}{~}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PAS_CONTIENT: {
        TEXTE: 'G_LBL_OPERATEUR_PAS_CONTIENT',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_PAS_CONTIENT',
        FORMATTER: (col: string, val: string) => `${col}{!~}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    COMMENCE_PAR: {
        TEXTE: 'G_LBL_OPERATEUR_COMMENCE_PAR',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_COMMENCE_PAR',
        FORMATTER: (col: string, val: string) => `${col}{^}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PAS_COMMENCE_PAR: {
        TEXTE: 'G_LBL_OPERATEUR_PAS_COMMENCE_PAR',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_PAS_COMMENCE_PAR',
        FORMATTER: (col: string, val: string) => `${col}{!^}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    TERMINE_PAR: {
        TEXTE: 'G_LBL_OPERATEUR_TERMINE_PAR',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_TERMINE_PAR',
        FORMATTER: (col: string, val: string) => `${col}{$}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    PAS_TERMINE_PAR: {
        TEXTE: 'G_LBL_OPERATEUR_PAS_TERMINE_PAR',
        TEXTE_PLURIEL: 'G_LBL_OPERATEUR_PLURIEL_PAS_TERMINE_PAR',
        FORMATTER: (col: string, val: string) => `${col}{!$}${val}`,
        TYPES: [OperateurTypes.STRING, OperateurTypes.NUMBER, OperateurTypes.DATE]
    },
    CREE_DEPUIS: {
        TEXTE: 'G_LBL_OPERATEUR_CREE_DEPUIS',
        FORMATTER: (col: string, val: string) => `datcre@${val}`,
        TYPES: []
    },
    MODIF_DEPUIS: {
        TEXTE: 'G_LBL_OPERATEUR_MODIF_DEPUIS',
        FORMATTER: (col: string, val: string) => `datmod@${val}`,
        TYPES: []
    },
    CREE_MODIF_DEPUIS: {
        TEXTE: 'G_LBL_OPERATEUR_CREE_MODIF_DEPUIS',
        FORMATTER: (col: string, val: string) => `datmod#${val}`,
        TYPES: []
    }
};

function OperateursFactory() {
    const Operateur: IOperateurService = class Operateur implements IOperateur {
        static readonly EGALE: IOperateur;
        static readonly EGALE_OU_NULL: IOperateur;
        static readonly EGALE_PAS: IOperateur;
        static readonly PLUS_GRAND: IOperateur;
        static readonly PLUS_GRAND_EGALE: IOperateur;
        static readonly PLUS_PETIT: IOperateur;
        static readonly PLUS_PETIT_EGALE: IOperateur;
        static readonly NULL: IOperateur;
        static readonly PAS_NULL: IOperateur;
        static readonly ENTRE: IOperateur;
        static readonly CONTIENT: IOperateur;
        static readonly PAS_CONTIENT: IOperateur;
        static readonly COMMENCE_PAR: IOperateur;
        static readonly PAS_COMMENCE_PAR: IOperateur;
        static readonly TERMINE_PAR: IOperateur;
        static readonly PAS_TERMINE_PAR: IOperateur;
        static readonly CREE_DEPUIS: IOperateur
        static readonly MODIF_DEPUIS: IOperateur
        static readonly CREE_MODIF_DEPUIS: IOperateur

        static readonly ALL_OPERATEURS: Array<IOperateur> = [];
        static readonly BASIC_OPERATEURS: Array<IOperateur> = [];
        static readonly STRING_OPERATEURS: Array<IOperateur> = [];

        readonly nom: string;
        readonly texte: string;
        readonly textePluriel?: string;
        readonly types: Array<string>;
        private readonly operateurConfig: IOperateurConfig;

        constructor(nom: string, operatorConfig: IOperateurConfig) {
            this.nom = nom;
            this.texte = operatorConfig.TEXTE;
            this.textePluriel = operatorConfig.TEXTE_PLURIEL;
            this.types = operatorConfig.TYPES;
            this.operateurConfig = operatorConfig;
        }

        format(col: string, val?: any, val2?: any): string {
            const valeur = (Array.isArray(val)) ? val.map((v: string) => encodeURIComponent(v)).join(',') : encodeURIComponent(val);
            return this.operateurConfig.FORMATTER(col, valeur, encodeURIComponent(val2));
        }

        isAucuneValeurOperateur(): boolean {
            return (this === Operateur.NULL || this === Operateur.PAS_NULL);
        }

        isValeurUniqueOperateur(): boolean {
            return !this.isValeurDoubleOperateur() && !this.isAucuneValeurOperateur();
        }

        isValeurDoubleOperateur(): boolean {
            return (this === Operateur.ENTRE);
        }

        isRecherchePartielleOperateur(): boolean {
            return (this === Operateur.CONTIENT || this === Operateur.PAS_CONTIENT);
        }

        isRechercheExacteOperateur(): boolean {
            return (this === Operateur.EGALE || this === Operateur.EGALE_PAS);
        }
    };

    // On défini les constantes des opérateurs
    Object.keys(Operateurs).forEach((operateurNom: string) => {
        Operateur[operateurNom] = new Operateur(operateurNom, Operateurs[operateurNom]);

        Operateur.ALL_OPERATEURS.push(Operateur[operateurNom]);
        if (!operateurNom.endsWith("DEPUIS")) {
            Operateur.BASIC_OPERATEURS.push(Operateur[operateurNom]);
        }
        if (Operateur[operateurNom].types.includes('string')) {
            Operateur.STRING_OPERATEURS.push(Operateur[operateurNom]);
        }
    });

    return Operateur;
}
