/**
 * Ce comportement sert à exposer le context d'écran général de l'application. Il est overridé à l'intérieur de dialogEcran.
 */
import {
    IAugmentedJQuery,
    ICompileService,
    IDirective, ILogService, IParseService,
    IPromise,
    IScope,
    module,
    copy
} from 'angular';
import {ISourceDetails} from '../../resources/source-details.resource';
import {IEcranDetailsResourcesClass, IEcranDetailsResourcesEntite} from '../../resources/ecran-details.resource';
import IInjectorService = angular.auto.IInjectorService;
import IStateService = angular.ui.IStateService;
import {IParametresSecuriteService} from "../../services/parametres-securite.service";
import {ISourceDetailsManager} from "../../services/source-details-manager.service";
import { IRoute } from '../../interfaces/route.interface';
import { IProfil } from '../../resources/profil.resource';
import { IAppTitreHelper } from '../../services/app-titre-helper.service';
import { IStateParamsService } from 'angular-ui-router';

export default module('core.behaviors.ex-ecran-context', [])
    .directive('exEcranContext', EcranContextDirective);

export interface IEcranContextController {
    ecranDetails: IEcranDetailsResourcesEntite;
    stateParams: IStateParamsService;
    ecranSourceDetails: ISourceDetails;
    transitionTo?: (stateName: string, params: any, options?: any) => void;
    confirmerQuitterPage?: () => IPromise<any>;
    fetchEcranDetails?: (srccod: string) => IPromise<IEcranDetailsResourcesEntite>;
}

/* @ngInject */
function EcranContextDirective(): IDirective {
    return {
        restrict: 'A',
        /* @ngInject */
        controller: function(appName: string,
                             accueilRoute: IRoute,
                             $injector: IInjectorService,
                             $attrs: any,
                             $compile: ICompileService,
                             $element: IAugmentedJQuery,
                             $log: ILogService,
                             $parse: IParseService,
                             $scope: IScope,
                             $state: IStateService, $stateParams: IStateParamsService,
                             parametresSecurite: IParametresSecuriteService,
                             sourceDetailsManager: ISourceDetailsManager,
                             EcranDetailsResource: IEcranDetailsResourcesClass,
                             profil: IProfil,
                             AppTitreHelper: IAppTitreHelper) {
            const vm: IEcranContextController = this;

            let context: IEcranContextController = $scope.$eval($attrs.exEcranContext);

            if (typeof context === 'string') {
                defineContextAction(context);
            } else {
                defineProperties(context);
            }

            function defineProperties(context: IEcranContextController) {
                Object.defineProperties(vm, {
                    stateParams: {
                        get() {
                            return context ?
                                context.stateParams :
                                $injector.get('$stateParams');
                        }
                    },
                    ecranDetails: {
                        get() {
                            const ecranDetails: any = context ?
                                context.ecranDetails :
                                $injector.get('ecranDetails');

                            if (!ecranDetails.fonctions) {
                                ecranDetails.fonctions = {};
                            }

                            const stateParams = $injector.get('$stateParams') as any;
                            if (ecranDetails.titre && $state.current.name !== accueilRoute.NAME && !stateParams.titreDejaAffiche) {
                                if (stateParams.ecrcleint === ecranDetails.ecrcleint) {
                                    AppTitreHelper.setTitrePage({titreEcran: ecranDetails.titre, codeEcran: stateParams.srccod.toUpperCase() + "-" + stateParams.ecran, titreApp: AppTitreHelper.getNomAppTitre(appName), cienomabr: profil.compagnie.cienomabr});
                                    stateParams.titreDejaAffiche = true
                                }
                            }

                            return ecranDetails;
                        }
                    },
                    ecranSourceDetails: {
                        get() {
                            return context ?
                                context.ecranSourceDetails :
                                $injector.get('ecranSourceDetails');
                        }
                    },
                    transitionTo: {
                        value: (stateName: string, params: any, options: any) => {
                            const ecranDetails: IEcranDetailsResourcesEntite = $injector.get('ecranDetails');

                            // Si on est dans le context principal
                            if (!(context && context.transitionTo) || ecranDetails.ecrcleint === context.ecranDetails.ecrcleint) {
                                $state.transitionTo(stateName, params, options);
                            } else {
                                context.transitionTo(stateName, params, options);
                            }
                        }
                    },
                    confirmerQuitterPage: {
                        set(confirmerQuitterPage: () => any) {
                            if (context) {
                                context.confirmerQuitterPage = confirmerQuitterPage;
                            }
                        },
                        get() {
                            return context && context.confirmerQuitterPage;
                        }
                    },
                    fetchEcranDetails: {
                        get() {
                            return fetchEcranDetails;
                        }
                    }
                });
            }

            function defineContextAction(context: IEcranContextController|string) {
                defineContext(<string>context)
                    .then((newContext: IEcranContextController) => {
                        defineProperties(newContext);
                        if (!$attrs.exEcranContextName) {
                            $log.error('L\'attribut ex-ecran-context-name est requis pour définir un contexte d\'écran');
                        }

                        const setter = $parse($attrs.exEcranContextName);
                        setter.assign($scope, vm);
                    })
                    .catch(() => {
                        injectGestionErreur(<string>context);
                    });
            }

            function defineContext(srccod: string): IPromise<IEcranContextController> {
                let contextEcranDetails: IEcranDetailsResourcesEntite;
                const stateParams = copy($injector.get('$stateParams')) as IStateParamsService;
                stateParams.srccod = srccod;

                return fetchEcranDetails(srccod)
                    .then((ecranDetails) => {
                        contextEcranDetails = ecranDetails;
                        stateParams.ecrcleint = ecranDetails.ecrcleint;
                        return fetchEcranSourceDetails(srccod, stateParams, ecranDetails);
                    })
                    .then((ecranSourceDetails: any) => {
                        return {
                            stateParams,
                            ecranSourceDetails,
                            ecranDetails: contextEcranDetails
                        };
                    });
            }

            function fetchEcranDetails(srccod: string): IPromise<IEcranDetailsResourcesEntite> {
                const stateParams: any = $injector.get('$stateParams');
                const ecrcod = `${srccod}-00-2`;
                const [pracleint, menucleref] = (stateParams.menuId || '').split('-');

                const params: any = {
                    ecrcod,
                    pracleint,
                    menucleref
                };

                return EcranDetailsResource.get(params).$promise;
            }

            function fetchEcranSourceDetails(srccod: string, stateParams: any, ecranDetails: IEcranDetailsResourcesEntite) {
                const params: any = {
                    ...$stateParams,
                    ...parametresSecurite(stateParams, ecranDetails)
                };

                if (stateParams.id) {
                    params.cleint = stateParams.id;
                }

                return sourceDetailsManager.fetch(srccod, null, params);
            }


            function injectGestionErreur(srccod: string) {
                const scopeErreur: any = $scope.$new();

                scopeErreur.vm.defineContextAction = defineContextAction;
                scopeErreur.vm.srccod = srccod;

                const html = `<ex-erreur-chargement class="m-t-40"
                                                    lbl-message="G_MSG_CHARG"
                                                    retry-action="vm.defineContextAction(vm.srccod)">
                              </ex-erreur-chargement>`;

                $element.html(html);

                $compile($element.children())(scopeErreur);
            }
        }
    };
}
