import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IMonoOccurrence, IMonoOccurrenceClass, IMonoOccurrenceOptions } from '../../services/mono-occurrence.service';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IAugmentedJQuery, IComponentController, IScope } from 'angular';
import { IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IComposantMonoOccurrence extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    formulaire: IFormulaire;
}

/* @ngInject */
export default function MonoOccurrenceController(MonoOccurrence: IMonoOccurrenceClass,
    $scope: IScope,
    $element: IAugmentedJQuery,
    dataLinker: IDataLinker) {
    const vm: IComposantMonoOccurrence = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        if (vm.multiOccurrenceCtrl) {
            vm.monoOccurrence = new MonoOccurrence({
                element: $element,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                srccod: vm.multiOccurrenceCtrl.multiOccurrence.srccod,
                multiOccurrenceParent: vm.multiOccurrenceCtrl.multiOccurrence,
                successActionSauvegarde: vm.multiOccurrenceCtrl.multiOccurrence.successActionSauvegarde,
                formulaire: vm.formulaire,
                champsTransactionnels: vm.multiOccurrenceCtrl.multiOccurrence.champsTransactionnels,
                id: vm.data ? vm.data[vm.multiOccurrenceCtrl.multiOccurrence.forageCleint] : undefined,
                nomSourceDetails: vm.multiOccurrenceCtrl.multiOccurrence.isView ? undefined : vm.multiOccurrenceCtrl.multiOccurrence.getNomSourceDetails(),
                isEdition: Boolean(vm.multiOccurrenceCtrl.multiOccurrence.fonctions.edition || vm.multiOccurrenceCtrl.multiOccurrence.fonctions.nouveau),
                resourceUrl: vm.multiOccurrenceCtrl.multiOccurrence.resourceUrl || (vm.multiOccurrenceCtrl.multiOccurrence.isView || vm.multiOccurrenceCtrl.multiOccurrence.fonctions.editionRapide ? undefined : vm.multiOccurrenceCtrl.multiOccurrence.getResourceUrl()),
                autoFetch: vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.autoFetch !== undefined ? vm.monoOccurrenceOptions.autoFetch : vm.multiOccurrenceCtrl.multiOccurrence.isView,
                resourceParams: {
                    ...vm.multiOccurrenceCtrl.multiOccurrence.getResourceParamsValues(),
                    ...vm.multiOccurrenceCtrl.multiOccurrence.getParentQueryParams()
                }
            });
            if (!vm.multiOccurrenceCtrl.multiOccurrence.isView) {
                //On simule qu'on a fait un fetch vu qu'on bypass pour utiliser les données du multi-occurrence
                vm.monoOccurrence.data = new vm.monoOccurrence.DataResource(vm.data);
                vm.monoOccurrence.data.$resolved = true;
            }
        } else if (vm.monoOccurrenceOptions) {
            vm.monoOccurrenceOptions.formulaire = vm.monoOccurrenceOptions.formulaire || vm.formulaire;

            // On supporte le forage par employé
            const supporteForageEmploye = (
                !vm.monoOccurrenceOptions.id &&
                (vm.ecranContextCtrl.ecranSourceDetails.schema.demcleint || vm.ecranContextCtrl.ecranSourceDetails.schema.eplcleint) &&
                vm.ecranContextCtrl.stateParams.employe
            );

            if (supporteForageEmploye) {
                vm.monoOccurrenceOptions.noId = true;
            }

            vm.monoOccurrence = new MonoOccurrence({
                element: $element,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                ...vm.monoOccurrenceOptions
            });
        } else {
            vm.monoOccurrence = new MonoOccurrence({
                element: $element,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                formulaire: vm.formulaire
            });
        }

        $scope.$on('$destroy', () => {
            vm.monoOccurrence.removeAllListeners();
        });

        vm.monoOccurrence.on('dataUpdate', linkData);
        vm.monoOccurrence.on('softDataUpdate', linkData);

        if (vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.ecranEnteteSticky) {
            headSticky()
        }
    }
    
    function headSticky() {
        $scope.$emit("vm.mono-occurrence.setSticky",vm)

    }
    function $onDestroy() {
        vm.monoOccurrence.removeListener('dataUpdate', linkData);
        vm.monoOccurrence.removeListener('softDataUpdate', linkData);
    }

    function linkData() {
        dataLinker.link($element, vm.monoOccurrence.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
    }
}

