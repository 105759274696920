import IDialogOptions = angular.material.IDialogOptions;
import { IAugmentedJQuery, IComponentController, IFormController, ITimeoutService } from 'angular';
import { IDataType } from '../../services/data-types/data-type.service';
import { IDialog } from '../../services/dialog.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantMonoOccurrenceEcran } from '../../components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IFiltre } from "../../services/filtre.service";
import { IOperateurService } from "../../services/operateur.service";

interface IComposantInputLovEntiteExterne extends IComponentController {
    ecranContextCtrl: IEcranContextController,
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran
    icon: string;
    data: IDataEntiteExterne;
    nameElement: string;
    col: string;
    dataType: IDataType;
    formCtrl: IFormController;
    filtresLov: Array<IFiltre>;
    srccodref: string;
    onKeydown(event: MouseEvent): void;
    openDialogLovEntiteExterneAction(event: MouseEvent): void;
    getLovEntiteExterneMasque(): string;
}

export interface IDataEntiteExterne {
    [index: string]: number | string;
}

/* @ngInject */
export default function InputLovEntiteExterneController(DialogLovEntiteExterne: IDialog,
    Operateur: IOperateurService,
    $element: IAugmentedJQuery,
    defaults: IDefaultsService,
    exFocus: IFocusService,
    $timeout: ITimeoutService,
    keyCodes: IKeyCodes) {
    const vm: IComposantInputLovEntiteExterne = this;

    vm.$onInit = $onInit;
    vm.openDialogLovEntiteExterneAction = openDialogLovEntiteExterneAction;
    vm.onKeydown = onKeydown;

    function $onInit() {
        defaults(vm, {
            lblTitre: 'G_LBL_ENTITE_EXTERNE',
            icon: 'featured_play_list',
            filtresLov: [
                {
                    colonne: 'type',
                    operateur: Operateur.EGALE,
                    valeur: [(vm.dataType.params.actifs as any)[0]]
                }
            ]
        });

    }

    function onKeydown(event: MouseEvent) {
        // Comme le bouton d'entité externe peut être visible en même temps que celui de la lov, on utilise la touche shift
        if (event.shiftKey && !event.metaKey && !event.ctrlKey) {
            switch (event.which) {
                case keyCodes.DOWN:
                case keyCodes.UP:
                    openDialogLovEntiteExterneAction(event);
                    break;
            }
        }
    }

    function openDialogLovEntiteExterneAction(ev: MouseEvent): void {
        if (!vm.updating) {
            openDialogLovEntiteExterne(ev);
        } else {
            //On doit attendre que le champ soit mis à jour.
            $timeout(() => {
                openDialogLovEntiteExterneAction(ev);
            });
        }
    }

    function openDialogLovEntiteExterne(ev: MouseEvent): void {
        const description = vm?.dataType?.params?.description || [];
        const dialogOptions: IDialogOptions = {
            locals: {
                forcerEtatPredefinisDepart: vm.resourceParams && vm.resourceParams.type,
                srccodref: vm.srccodref,
                icon: vm.icon,
                targetEvent: ev,
                readonly: vm.readonly,
                axeVisibiliteActifs: vm.dataType.params.actifs || [],
                ecranContext: vm.ecranContextCtrl,
                id: vm.col,
                description: description
            },
            multiple: true
        };
        DialogLovEntiteExterne.show(dialogOptions)
            .then((data: IDataEntiteExterne) => {
                vm.loading = true;
                updateValues(data);
            })
            .finally(() => {
                exFocus($element);
                vm.loading = false;
            });
    }

    function updateValues(data: IDataEntiteExterne) {
        Object.assign(vm.data, data);
        vm.formCtrl[vm.nameElement].$viewValue = data[vm.col];
        vm.formCtrl[vm.nameElement].$commitViewValue();
        vm.formCtrl[vm.nameElement].$render();//Affiche le changement dans le input
    }

}
