/**
 * Composant un multi-occurrence donc le contenu de chaque rangée est complètement personnalisable
 */
import {IAugmentedJQuery, IComponentController, IDirective, IScope, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MultiOccurrenceTemplateController, {IComposantMultiOccurrenceTemplate} from './ex-multi-occurrence-template.controller';
import {ITranscludeSlotFunction} from '../../interfaces/transclude-function.interface';
import {IComposantCardMultiOccurrenceTemplate} from '../ex-card-multi-template/ex-card-multi-template.controller';

export default module('core.components.ex-multi-occurrence-template', [])
    .directive('exMultiOccurrenceTemplate', MultiOccurrenceTemplateFactory);

interface IMultiOccurrenceTemplateScope extends IScope {
    vm: IComposantMultiOccurrenceTemplate;
}

interface IMultiOccurrenceTemplateRequiredControllers {
    cardMultiTemplateCtrl?: IComposantCardMultiOccurrenceTemplate;
    multiSelectionTemplateCtrl?: IComponentController;
    multiAnalyseTemplateCtrl?: IComponentController
}

function MultiOccurrenceTemplateFactory(): IDirective {
    return {
        restrict: 'E',
        scope: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            {
                multiOccurrence: '=?multiOccurrenceOut',
                hasAccordeonInCard: '<?',
                hasTotalInCard: '<?',
                hasContentWhitespace: '<?',
                lblTitreEdition: '@',
                forageOnClick: '<?',
                showMenuMore: '<?',
                onSaveNewAction: '<?',
                removeMenuMore: '<?',
                editionOptions: '<?',
                onCheckboxChange: '&?',
                brisCollaspable: '<?',
                draggable: '<?draggableSelecteur'
            }
        ),
        require: {
            blocCtrl: '?^^exBloc',
            dataSourceCtrl: '?^exDataSource',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            cardMultiTemplateCtrl: '?^exCardMultiTemplate',
            multiSelectionTemplateCtrl: '?^peMultiOccurrenceSelection',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            row: 'exMultiOccurrenceTemplateRow',
            bris: '?exMultiOccurrenceTemplateBris',
            beforeTable: '?exMultiOccurrenceTemplateBeforeTable',
            accordeon: '?exMultiOccurrenceTemplateAccordeon',
            total: '?exMultiOccurrenceTemplateTotal'
        },
        link(scope: IMultiOccurrenceTemplateScope,
             element: IAugmentedJQuery,
             attrs: any,
             controllers: IMultiOccurrenceTemplateRequiredControllers,
             transcludeFn: ITranscludeSlotFunction) {

            const parentController = controllers.cardMultiTemplateCtrl || controllers.multiSelectionTemplateCtrl;

            const currentTranscludeFn = parentController ?
                parentController.transcludeFn :
                transcludeFn;

            // Le template liste utilise un transclude avancé ou le contenu passé est utilisé pour rendre le contenu
            // d'une rangée. Pour cela, il faut lier le scope et insérer le contenu nous-mêmes.
            scope.vm.compileTemplate = function (index: number, rowScope: any, brisIndex: number = 0) {
                const newScope = getScope(rowScope);
                newScope.brisIndex = brisIndex;

                currentTranscludeFn(newScope, (clone: IAugmentedJQuery) => {
                    const parentElement = element.find(`.ex-multi-occurrence-template-row-container:nth-child(${brisIndex + 1})
                        .ex-multi-occurrence-template-row:nth-child(${index + 1})
                        .ex-multi-occurrence-template-row-content`).first();
                    parentElement.append(clone);
                }, null, 'row');
            };

            scope.vm.compileBrisTemplate = function (rowScope: any, brisIndex: number = 0) {
                const newScope = getScope(rowScope);
                newScope.brisIndex = brisIndex;

                // Le bris utilise toujours la fonction originale de transclude
                transcludeFn(newScope, (clone: IAugmentedJQuery) => {
                    const parentElement = element.find(`.ex-multi-occurrence-template-row-container:nth-child(${brisIndex + 1})
                        .ex-multi-occurrence-template-bris-container-transclude`).first();
                    parentElement.append(clone);
                    scope.vm.templateBrisInit = true;
                }, null, 'bris');
            };

            scope.vm.compileTotalTemplate = function (scope: any) {
                currentTranscludeFn(getTotalScope(scope), (clone: IAugmentedJQuery) => {
                    const parentElement = element.find('.ex-multi-occurrence-template-row--total-container').first();
                    parentElement.append(clone);
                }, null, 'total');
            };

            scope.vm.compileAccordeonTemplate = function (index: number, rowScope: any, brisIndex: number = 0) {
                const newScope = getScope(rowScope);

                currentTranscludeFn(newScope, (clone: IAugmentedJQuery) => {
                    const parentElement = element.find(`.ex-multi-occurrence-template-row-container:nth-child(${brisIndex + 1})
                        .ex-multi-occurrence-template-row:nth-child(${index + 1})
                        .ex-multi-occurrence-template-row-accordeon`).first();
                    parentElement.append(clone);

                    // On s'assure que le scope soit bien détruit
                    parentElement.on('$destroy', () => {
                        newScope.$destroy();
                    });
                }, null, 'accordeon');
            };

            function getTotalScope(scope: any) {
                const newScope: any = getParentScopeById();

                // On expose ces propriétés pour usage rapide
                newScope.data = scope.vm.multiOccurrence.dataList[0];
                newScope.multiOccurrence = scope.vm.multiOccurrence;
                newScope.libelles = scope.vm.multiOccurrence.libelles;

                return newScope;
            }

            function getScope(rowScope: any) {
                const newScope: any = getParentScopeById();

                // On ajoute les propriétés du scope de la rangé au scope transcludé
                const localProps = ['data', '$index', '$first', '$middle', '$last', '$event', '$odd', 'key'];
                Object.assign(newScope, localProps.reduce((props: any, prop: string) => {
                    props[prop] = rowScope[prop];
                    return props;
                }, {}));

                // On expose ces propriétés pour usage rapide
                newScope.multiOccurrence = rowScope.vm.multiOccurrence;
                newScope.libelles = rowScope.vm.multiOccurrence.libelles;

                return newScope;
            }

            function getParentScopeById() {
                let parent: any = scope;
                while(parentController && parent.$id !== parentController.scopeId) {
                    parent = parent.$parent;
                }
                return parent.$parent.$new();
            }
        },
        bindToController: true,
        controller: MultiOccurrenceTemplateController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-template.html')
    };
}
