import { module } from 'angular';
import { IDataType, IDataTypeClass, IDataTypeOptions } from './data-type.service';
import { IOperateurService } from '../operateur.service';

export interface IImputationDataTypeClass {
    new(options?: IImputationDataTypeOptions): IImputationDataType;
}

export interface IImputationDataTypeOptions extends IDataTypeOptions {
}

export interface IImputationDataType extends IDataType {
}

export default module('core.services.imputation-data-type', []).factory('ImputationDataType', ImputationDataTypeFactory);

/* @ngInject */
function ImputationDataTypeFactory(DataType: IDataTypeClass, Operateur: IOperateurService): IImputationDataTypeClass {
    return class ImputationDataType extends DataType implements IImputationDataType {
        readonly template: string = `{{vm.data[vm.col]}}<ex-bouton-imputation ng-if="vm.dataType.params.showView" class="ex-imputation-data-type" name-element="{{vm.col}}" col="{{vm.col}}" srccodref="{{vm.multiOccurrenceCtrl.multiOccurrence.getNomSourceDetails()}}" data="vm.data" data-type="vm.dataType"></ex-bouton-imputation>`;

        readonly operateurs = [
            Operateur.CONTIENT,
            Operateur.PAS_CONTIENT,
            Operateur.NULL,
            Operateur.PAS_NULL
        ];
    }
}
