import * as angular from 'angular';
import { IComponentController, ITimeoutService } from 'angular';
import { IHighlightService } from '../../services/utils/highlight.service';
import { IKeepInScrollService } from '../../services/utils/keep-in-scroll.service';
import { IComposantSommaireItem } from '../ex-sommaire/ex-sommaire.controller';
import IAugmentedJQuery = angular.IAugmentedJQuery;

interface IComposantSommaireSections extends IComponentController {
    scrollContainer: IAugmentedJQuery;
    goToElement(target: IComposantSommaireItem): void;
    hasSectionTitre(section: IComposantSommaireItem): boolean;
    hasChamps(section: IComposantSommaireItem): boolean;
}

/* @ngInject */
export default function SommaireSectionsController(highlight: IHighlightService,
    keepInScroll: IKeepInScrollService,
    $timeout: ITimeoutService) {
    const vm: IComposantSommaireSections = this;
    const TITRE_VALEUR_NULLE = '-';

    vm.goToElement = goToElement;
    vm.hasSectionTitre = hasSectionTitre;
    vm.hasChamps = hasChamps;

    function goToElement(target: IComposantSommaireItem) {
        const exCardCtrl = target.element.closest('ex-card').controller('exCard');
        const regroupementCtrl = target.element.closest('ex-regroupement').controller('exRegroupement');
        const isAccordeon = target.element.prop('tagName') === 'EX-ACCORDEON';
        const isRegroupement = target.element.prop('tagName') === 'EX-REGROUPEMENT';
        const inAccordeon = (target.accordeons && target.accordeons.length);
        let inClosedAccordeon = false;

        if (exCardCtrl && !exCardCtrl.opened) {
            exCardCtrl.opened = true;
        }

        if (regroupementCtrl && !regroupementCtrl.opened) {
            regroupementCtrl.opened = true;
        }

        if (isAccordeon || isRegroupement) {
            const accordeonCtrl = (isAccordeon) ?
                target.element.controller('exAccordeon') :
                target.element.controller('exRegroupement');
            if (!accordeonCtrl.opened) {
                accordeonCtrl.opened = true;
            }
        }

        if (inAccordeon) {
            target.accordeons.forEach((accordeon: HTMLElement) => {
                const exAccordeonCtrl = angular.element(accordeon).controller('exAccordeon');

                if (!exAccordeonCtrl.opened) {
                    inClosedAccordeon = true;
                    exAccordeonCtrl.opened = true;
                }
            });
        }

        // On veut réaligner le scroll sur l'élément ouvert
        keepInScroll(vm.scrollContainer, target.element);

        const highlightTimeout = inClosedAccordeon ? 350 : 0;
        const highlightTarget: any = target.element;

        $timeout(() => {
            highlight(highlightTarget);
        }, highlightTimeout);
    }

    function hasSectionTitre(section: IComposantSommaireItem) {
        return Boolean(
            section.titre && section.titre !== TITRE_VALEUR_NULLE
        );
    }

    function hasChamps(section: IComposantSommaireItem) {
        return section.champs &&
            section.champs.length &&
            section.champs.some((champ: IComposantSommaireItem) => champ.titre && champ.titre.trim().length);
    }
}
