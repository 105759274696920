/**
 * Ce filtre affiche la description d'une LOV
 */
import {module} from 'angular';
import {IDataType} from '../services/data-types/data-type.service';

export default module('core.filters.ex-lov', []).filter('exLov', lovFilter);

export interface IFilterExLov {
    (col: string, data: any, dataType: IDataType): string;
}

/* @ngInject */
function lovFilter(): IFilterExLov {
    return function (col: string, data: any, dataType: IDataType): string {

        return dataType.params.description
            .map((descriptionCol: string) => {
                const champ = `${col}__${descriptionCol}`;
                return data[champ];
            })
            .filter((value: string) => value)
            .join(' - ');
    };
}
