import { IAugmentedJQuery, IComponentController, IFormController, IScope } from 'angular';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IEtatsResourcesEntite } from '../../resources/etat.resource';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IDataLinker } from '../../services/data-linker.service';
import AxesAffichage from '../../classes/axe-affichage.class';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IFiltre } from '../../services/filtre.service';

export interface IComposantMultiOccurrence extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence
    loading: boolean;
    busy: boolean;
    openedCritere: boolean;
    axeList: string;
    nouveauxCriteresSuggeres: any;
    criteresSuggeresFormCtrl: IFormController;
    filtresCriteresVisible: boolean;
    paginationVisible: boolean;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;

    isRechercheVisible(): boolean;
    isDefaultCriteresSuggeres(): boolean;
    areCriteresDeRechercheVisibles(): boolean;
    showPagination(): boolean;
    toggleCriteresSuggeres(): void;
    toggleFiltresVisible(): void;
    hascritereSuggeresSelectionMultiple(): boolean;
}

/* @ngInject */
export default function MultiOccurrenceController($scope: IScope,
    $element: IAugmentedJQuery,
    exFocus: IFocusService,
    dataLinker: IDataLinker) {
    const vm: IComposantMultiOccurrence = this;
    const CRITERES_SUGGERES_SELECTOR = '.ex-criteres-suggeres';

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isRechercheVisible = isRechercheVisible;
    vm.isDefaultCriteresSuggeres = isDefaultCriteresSuggeres;
    vm.areCriteresDeRechercheVisibles = areCriteresDeRechercheVisibles;
    vm.showPagination = showPagination;
    vm.toggleCriteresSuggeres = toggleCriteresSuggeres;
    vm.toggleFiltresVisible = toggleFiltresVisible;
    vm.hascritereSuggeresSelectionMultiple = hascritereSuggeresSelectionMultiple;

    function $onInit() {
        $scope.$watch("vm.multiOccurrence", () => {
            vm.paginationVisible = isPaginationVisible()
            vm.busy = false;
            vm.openedCritere = vm.multiOccurrence.fonctions.criteresRechercheOpened;
            vm.axeList = AxesAffichage.AXES_AFFICHAGE.LIST;
            vm.multiOccurrence.startWatching($scope);
            vm.filtresCriteresVisible = true;

            if (vm.multiOccurrence.initialized) {
                linkData();
            } else {
                vm.multiOccurrence.once('ready', () => {
                    linkData();
                });
            }

            watchPaginationVisible();
            watchBlocsReloads();

            if (vm.multiOccurrence.criteresSuggeres) {
                vm.multiOccurrence.addListener('selectedEtatUpdate', onSelectedEtatUpdated);
            }
        })
        $scope.$watch('vm.multiOccurrence.etatSelected.pagination.pageCourante', onEtatChange);
        $scope.$watch('vm.multiOccurrence.etatSelected.pagination.nombreElementPage', onEtatChange);
        $scope.$watchCollection(() => vm.multiOccurrence.etatSelected && vm.multiOccurrence.etatSelected.tri.liste.map(tri => tri.nom + tri.typeTri), onEtatChange);

        // Filtres
        $scope.$watchCollection(() => vm.multiOccurrence.etatSelected && vm.multiOccurrence.etatSelected.filtres, (newValue, oldValue) => vm.multiOccurrence.onEtatParamsChange(onEtatChange, newValue, oldValue));
    }

    function onEtatChange(newValue: any, oldValue: any) {
        if (oldValue && newValue && newValue !== oldValue) {
            vm.multiOccurrence.updateDataList(true)
        }
    }

    function linkData() {
        // Si on est sur l'état par défaut, on affiche les critères suggérés
        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = vm.multiOccurrence.fonctions.criteresSuggeresVisibles !== undefined ? vm.multiOccurrence.fonctions.criteresSuggeresVisibles : vm.multiOccurrence.etatSelected.equal(vm.multiOccurrence.etatDefault) && Boolean(vm.multiOccurrence.criteresSuggeres);

        dataLinker.link(
            $element,
            vm.multiOccurrence.etatSelected.criteresSuggeresData,
            vm.ecranContextCtrl.stateParams,
            vm.ecranContextCtrl.ecranDetails
        );
    }

    function watchPaginationVisible() {
        if (vm.multiOccurrence.fonctions.pagination) {
            vm.multiOccurrence.on('dataListUpdate', () => {
                vm.paginationVisible = isPaginationVisible();
            });
        }
    }

    function watchBlocsReloads() {
        if (hasBlocsToWatch()) {
            vm.multiOccurrence.reloadWithBlocs
                .map((bloc: string) => bloc.toUpperCase())
                .forEach((bloc: string) => {
                    $scope.$watch(`::vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants.${bloc}`, (multiOccurrence: IMultiOccurrence) => {
                        if (multiOccurrence) {
                            multiOccurrence.once('dataListUpdate', () => {
                                // Pour éviter le double chargement initial
                                multiOccurrence.on('dataListUpdate', () => {
                                    if (!vm.multiOccurrence.multiOccurrenceParent || vm.multiOccurrence.multiOccurrenceParent.activeRowCleint) {
                                        vm.multiOccurrence.fetchDataList();
                                    }
                                });
                            });
                        }
                    });
                });
        }
    }

    function hasBlocsToWatch() {
        return Boolean(
            vm.monoOccurrenceEcranCtrl &&
            vm.multiOccurrence.reloadWithBlocs && vm.multiOccurrence.reloadWithBlocs.length
        );
    }

    function $onDestroy() {
        vm.multiOccurrence.removeAllListeners();
    }

    function onSelectedEtatUpdated(etatSelected: IEtatsResourcesEntite) {
        if (etatSelected.equal(vm.multiOccurrence.etatDefault) || !vm.multiOccurrence.hasCriteresSuggeresSaisis(etatSelected.criteresSuggeresData)) {
            // Si on retourne à l'état par défaut, on doit absolument réatblir l'état de défaut des paramètres suggérés
            if (vm.multiOccurrence.fonctions.criteresSuggeresVisibles && Boolean(vm.multiOccurrence.criteresSuggeres) && vm.multiOccurrence.etatSelected.equal(vm.multiOccurrence.etatDefault)) {
                vm.multiOccurrence.preventDataListUpdate = true;
                vm.multiOccurrence.dataList = null;

                if (vm.criteresSuggeresFormCtrl) {
                    vm.criteresSuggeresFormCtrl.$setPristine();
                    vm.criteresSuggeresFormCtrl.$setUntouched();
                    exFocus($element.find(CRITERES_SUGGERES_SELECTOR));
                }
            }
        } else {
            vm.multiOccurrence.preventDataListUpdate = false;
        }
    }

    function isRechercheVisible() {
        return !vm.multiOccurrence.initializing && vm.multiOccurrence.fonctions.recherche;
    }

    function isDefaultCriteresSuggeres() {
        return vm.multiOccurrence && vm.multiOccurrence.criteresSuggeres && !vm.multiOccurrence.fonctions.customCriteresSuggeres;
    }

    function areCriteresDeRechercheVisibles() {
        return vm.multiOccurrence.fonctions.filtrer && !vm.multiOccurrence.initializing && !vm.multiOccurrence.initError && ((vm.multiOccurrence.etatSelected.filtres.length && hasFiltresVisible()) || (vm.multiOccurrence.criteresSuggeres && !vm.multiOccurrence.fonctions.customCriteresSuggeres));
    }

    function hascritereSuggeresSelectionMultiple() {
        return true;
    }

    function hasFiltresVisible() {
        return vm.multiOccurrence.etatSelected.filtres.some((filtre: IFiltre) => filtre.visible);
    }

    function isPaginationVisible(): boolean {
        return Boolean((!vm.multiOccurrence.axesAffichage || vm.multiOccurrence.axesAffichage.axeActive.axeNom !== AxesAffichage.AXES_AFFICHAGE.CALENDAR) && vm.multiOccurrence.fonctions.pagination && vm.multiOccurrence.dataList);
    }

    function showPagination() {
        return (vm.multiOccurrence.fonctions.pagination && vm.paginationVisible && Boolean(vm.multiOccurrence.etatSelected.pagination.total));
    }

    function toggleCriteresSuggeres() {
        vm.multiOccurrence.fonctions.criteresSuggeresVisibles = !vm.multiOccurrence.fonctions.criteresSuggeresVisibles;
        vm.multiOccurrence.fetchDataList()
    }

    function toggleFiltresVisible() {
        vm.filtresCriteresVisible = !vm.filtresCriteresVisible;
    }

}
