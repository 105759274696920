/**
 * Ce filtre sert à créer des bind once binding qui attende une valeur en particulier avant d'arrêter de watcher
 */
import {module} from 'angular';

export default module('core.filters.ex-once', []).filter('exOnce', onceFilter);

export interface IFilterOnce {
    (value: string, data?: any): string;
}

/* @ngInject */
function onceFilter(): IFilterOnce {
    return (value: any, data: any): any => {
        if (value === data) {
            return true;
        }
    };
}
