import {IDialogAllStatus} from '../../constants/dialog.constant';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IApiError} from '../../interfaces/api-error.interface';
import {IAugmentedJQuery, IComponentController, IPromise, IQService} from 'angular';
import {IFocusService} from '../../behaviors/ex-focus/ex-focus.behavior';
import {IDialog} from "../../services/dialog.service";

export interface IDialogConfirm extends IComponentController {
    lblTitre: string;
    lblDescription: string;
    lblConfirm: string;
    lblMessage: string;
    lblCancel: string;
    icon: string;
    error: IApiError|boolean|IDialogConfirmOptions;
    errorCode: string;
    retry: boolean;
    closeOnError: boolean;
    confirming: boolean;
    hideCancelAction: boolean;
    confirm(): void|IPromise<any>;
    confirmAction(): IPromise<any>;
    setMaxWidth: boolean;
}

export interface IDialogConfirmOptions {
    lblTitre?: string;
    lblDescription?: string;
    lblConfirm?: string;
    lblCancel?: string;
    lblMessage?: string;
    icon?: string;
    error?: IApiError|boolean;
    errorCode?: string;
    retry?: boolean;
    closeOnError?: boolean;
    confirming?: boolean;
    hideCancelAction?: boolean;
    confirm?(): void|IPromise<any>;
    confirmAction?(): IPromise<any>;
}

/* @ngInject */
export default function DialogConfirmController(defaults: IDefaultsService,
                                                DialogStatus: IDialogAllStatus,
                                                DialogSelectionIntervention: IDialog,
                                                $q: IQService,
                                                $element: IAugmentedJQuery,
                                                exFocus: IFocusService) {
    const vm: IDialogConfirm = this;
    const ICON_ERROR = 'warning';
    const lblDefaulCancel = 'G_LBL_BTN_ANNULER';
    const lblDefaultConfirm = 'G_LBL_BTN_APPLIQUER';
    const lblReessayer = 'G_LBL_BTN_REESSAYER';
    const lblOk = 'G_LBL_BTN_OK';

    vm.$onInit = function $onInit() {
        defaults(vm, {
            error: null,
            retry: true,
            lblCancel: lblDefaulCancel,
            lblConfirm: lblDefaultConfirm,
            closeOnError: false,
            confirming: false,
            hideCancelAction: false
        });

        if (vm.error) {
            setError();
        }

        // On focus sur le bouton principal
        exFocus($element, {types: ['button[type="submit"]']});
    }

    // Init
    vm.confirm = confirm;

    function confirm(): void|IPromise<any> {
        if (vm.error && !vm.retry) {
            vm.confirmAction();
            return $q.resolve(DialogStatus.FERMER);
        }

        if (vm.confirmAction) {
            const confirmActionResult = vm.confirmAction();

            if (confirmActionResult && confirmActionResult.then) {
                vm.confirming = true;
                return confirmActionResult
                    .then(() => {
                        return DialogStatus.APPLIQUER;
                    })
                    .catch((error: IApiError|IDialogConfirmOptions) => {
                        if ((<any>error).interventionRequise) {
                            const locals = {
                                lblMessageErreur: 'G_LBL_ERROR_INTERVENTION',
                                data: {}
                            };
                            return DialogSelectionIntervention.show({locals})
                                .then(() => confirm())
                        } else {
                            vm.error = error;
                            const apiError = <IApiError>error;
                            vm.errorCode = apiError.data && apiError.data.code;

                            const newOptions: IDialogConfirmOptions = <IDialogConfirmOptions>error;
                            if (typeof newOptions.lblMessage !== 'undefined') {
                                vm.lblMessage = newOptions.lblMessage;
                            }

                            if (typeof newOptions.lblMessage !== 'undefined') {
                                vm.lblTitre = newOptions.lblTitre;
                            }

                            if (typeof newOptions.confirmAction !== 'undefined') {
                                vm.confirmAction = newOptions.confirmAction;
                            }

                            vm.lblCancel = (vm.confirmAction) ? lblDefaulCancel : lblOk;

                            setError();
                            return $q.reject(error)
                        }
                    })
                    .finally(() => {
                        this.confirming = false;
                    });
            } else {
                return $q.resolve(DialogStatus.APPLIQUER);
            }
        }
    }

    function setError() {
        vm.icon = ICON_ERROR;
        vm.lblConfirm = (vm.retry ? lblReessayer : lblOk);
    }
}
