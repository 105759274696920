import {module} from 'angular';
import DialogImputationController from './dialog-imputation.controller';
import {IDialogClass} from '../../services/dialog.service';

export default module('app.dialogs.dialog-imputation', [
    'core.services.dialog'
]).factory('DialogImputation', DialogImputationFactory);

/* @ngInject */
function DialogImputationFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogImputationController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-imputation.html'),
        focusOnOpen: false
    });
}
