import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogLovEntiteExterneController
    from '../../dialogs/dialog-lov-entite-externe/dialog-lov-entite-externe.controller';

export default module('app.dialogs.dialog-lov-entite-externe', [
    'core.services.dialog'
]).factory('DialogLovEntiteExterne', DialogLovEntiteExterneFactory);

/* @ngInject */
function DialogLovEntiteExterneFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogLovEntiteExterneController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-lov-entite-externe.html'),
        focusOnOpen: false
    });
}
