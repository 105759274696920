import {IQService, module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';
import {IApiError} from '../../interfaces/api-error.interface';
import {IMonoOccurrence, IMonoOccurrenceClass} from '../mono-occurrence.service';
import {INotificationHandler} from '../utils/notification-handler.service';
import {IParametresSecuriteService} from '../parametres-securite.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {ICycleFinanceClass} from '../../resources/cycle-finance.resource';
import {IDialog} from '../dialog.service';
import {IFormulaireClass} from '../formulaire/formulaire.service';
import {IFormulaireItemClass} from '../formulaire/formulaire-item.service';
import {ICycleRhClass} from '../../resources/cycle-rh.resource';
import {IFilterMessage} from '../../filters/ex-message.filter';

export interface IMenuItemGenererPieceJustificativeClass {
    new(monoOccurrence: IMonoOccurrence, ecranContextCtrl: IEcranContextController, option?: IMenuItemGenererPieceJustificativeOptions): IMenuItemGenererPieceJustificative;
}

export interface IMenuItemGenererPieceJustificative extends IMenuItem {
    monoOccurrence: IMonoOccurrence;
}

export interface IMenuItemGenererPieceJustificativeOptions extends IMenuItemOptions{

}

export default module('core.services.menu-item-generer-piece-justificative', []).factory('MenuItemGenererPieceJustificative', MenuItemPieceJustificativeFactory);

/* @ngInject */
function MenuItemPieceJustificativeFactory(MenuItem: IMenuItemClass,
                                           notificationHandler: INotificationHandler,
                                           parametresSecurite: IParametresSecuriteService,
                                           CycleFinance: ICycleFinanceClass,
                                           CycleRh: ICycleRhClass,
                                           DialogFormulaire: IDialog,
                                           $q: IQService,
                                           Formulaire: IFormulaireClass,
                                           FormulaireItem: IFormulaireItemClass,
                                           MonoOccurrence: IMonoOccurrenceClass,
                                           exMessageFilter: IFilterMessage) {

    class MenuItemGenererPieceJustificative extends MenuItem implements IMenuItemGenererPieceJustificative {
        readonly monoOccurrence: IMonoOccurrence;
        readonly ecranContextCtrl: IEcranContextController;

        constructor(monoOccurrence: IMonoOccurrence, ecranContextCtrl: IEcranContextController, options: IMenuItemGenererPieceJustificativeOptions = {}) {
            const lblTitre = 'G_LBL_BTN_PIECE_JUSTIFICATIVE';

            super(lblTitre, (event: MouseEvent) => {
                return this.showDialogGenererPieceJustificative(event)
                    .then(({trlcleint}) => {
                        const Resource = (this.monoOccurrence.cycleCleint === 'doccleint') ?
                            CycleFinance :
                            CycleRh;

                        const params = {
                            [this.monoOccurrence.cycleCleint]: monoOccurrence.id,
                            srccod: monoOccurrence.getNomSourceDetails(),
                            ...parametresSecurite(this.monoOccurrence.stateParams)
                        };

                        const data = {trlcleint};


                        return (<any>Resource).genererPieceJustificative(params, data).$promise
                            .catch((error: IApiError) => {
                                this.afficherMessageErreur(error, event);
                                return $q.reject(error);
                            });
                    })
                    .then((result: any) => {
                        notificationHandler.succes(result.msg);
                    })
            }, Object.assign({
                iconButton: true,
                icon: 'cached',
                directionTooltip: 'bottom'
            }, options));

            this.monoOccurrence = monoOccurrence;
            this.ecranContextCtrl = ecranContextCtrl;
        }

        showDialogGenererPieceJustificative(event: MouseEvent) {
            if (this.monoOccurrence.etatActuel.trlcleint) {
                return $q.resolve({trlcleint: this.monoOccurrence.etatActuel.trlcleint});
            } else {
                const srccod = (this.monoOccurrence.cycleCleint === 'doccleint') ?
                    'mc1001' :
                    'mr1001';
                const optionsDialogs = {
                    locals: {
                        lblTitre: 'G_LBL_BTN_PIECE_JUSTIFICATIVE',
                        lblConfirm: 'G_LBL_BTN_GENERER',
                        largeurFormulaire: 100,
                        occurrence: new MonoOccurrence({
                            bloc: 'NOOP',
                            stateParams: this.monoOccurrence.stateParams,
                            ecranDetails: this.monoOccurrence.ecranDetails,
                            ecranSourceDetails: this.monoOccurrence.ecranSourceDetails,
                            srccod
                        }),
                        ecranContext: this.ecranContextCtrl,
                        formulaire: new Formulaire([
                            new FormulaireItem('trlcleint', {
                                resourceParams: {
                                    [this.monoOccurrence.cycleCleint]: this.monoOccurrence.id
                                }
                            })
                        ]),
                        icon: 'cached'
                    },
                    targetEvent: event,
                    multiple: true
                };

                return DialogFormulaire.show(optionsDialogs);
            }
        }

        afficherMessageErreur(error: IApiError, event: MouseEvent) {
            notificationHandler.erreur({
                targetEvent: event,
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: exMessageFilter(error.data && error.data.message, 'G_MSG_ERREUR_GENERIQUE'),
                error
            });
        }
    }

    return MenuItemGenererPieceJustificative;
}
