import {IComponentOptions, module} from 'angular';
import CocheContoller from './ex-coche.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-coche', []).component('exCoche', CocheFactory());

/**
 * Représente une coche (check mark), pouvant être utilisée pour indiquer un statut. Du texte peut être passé
 * en transclude pour l'accompagner.
 */
function CocheFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA,
            Binding.LIBELLES,
            Binding.COL,
            {
                hideLibelle: '<?'
            }
        ),
        require: {
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: CocheContoller,
        transclude: true,
        template: require('./ex-coche.html')
    };
}
