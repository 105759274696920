/**
 * Ce comportement permet de générer une image qui provient de la base de données
 *
 * Option: exHttpSrcDefault = image par défaut
 *
 */
import {IAttributes, IAugmentedJQuery, IHttpService, IScope, module} from 'angular';
import {IParametresSecuriteService} from '../../services/parametres-securite.service';
import {IEcranContextController} from '../ex-ecran-context/ex-ecran-context.behavior';

export interface IexHttpSrc extends IAttributes {
    exHttpSrcDefault: string;
    exHttpSrc: string;
}

export default module('core.behaviors.ex-http-src', [])
    .directive('exHttpSrc', httpSrcDirective);

/* @ngInject */
function httpSrcDirective($http: IHttpService,
                          parametresSecurite: IParametresSecuriteService) {

    return {
        restrict: 'A',
        require: '^^exEcranContext',
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: IexHttpSrc, ecranContextCtrl: IEcranContextController) {
        const imageParDefault = attrs.exHttpSrcDefault || './assets/images/logos/no-image1.png';
        const params = {};

        if (attrs.exHttpParams) {
            Object.assign(params, scope.$eval(attrs.exHttpParams));
        }

        const options = {
            params: {
                ...params,
                ...parametresSecurite(ecranContextCtrl.stateParams)
            },
            responseType: 'arraybuffer'
        };

        if (!attrs.exHttpFinalize) {
            element.hide();
        }

        $http.get(attrs.exHttpSrc, options)
            .then((response: any) => {
                const dataUint8 = new Uint8Array(response.data);
                const raw = dataUint8.reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                }, '');
                const base64 = btoa(raw);
                const src = 'data:image/png;base64,' + base64;
                attrs.$set('src', src);
            })
            .catch(() => {
                attrs.$set('src', imageParDefault);
            })
            .finally(() => {
                if (attrs.exHttpFinalize) {
                    const finalize = scope.$eval(attrs.exHttpFinalize);
                    if (typeof finalize === 'function') {
                        finalize(scope, element, attrs);
                    }
                } else {
                    element.show();
                }
            });

    }

}
