import {IHttpResponse, IQService, module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResource = angular.resource.IResource;
import IResourceArray = angular.resource.IResourceArray;
import IResourceClass = angular.resource.IResourceClass;
import IResourceService = angular.resource.IResourceService;

export interface IProchainEtatFinance extends IResource<IProchainEtatFinance> {
    doccleint: number;
    dsceta: string;
    cleintpro: number;
    vaecyeact: string;
    intnom: string;
    docflgaih: boolean;
    acces: number;
}

export interface IProchainEtatOptionsFinance {
    doccleint: number;
}

export interface IEtatActuelFinance extends IResource<IEtatActuelFinance> {
    etdcleint: number;
    nom: string;
    date: string;
    flgstafin: number;
    trlcleint: number;
    [index: string]: any;
}

export interface IActionPossibleFinance extends IResource<IActionPossibleFinance> {
    doccleint: number;
    vaedsc: string;
    vaecypact: string;
    docflgaih: boolean;
}

export interface IMessagesErreurOptionsFinance {
    doccleint: number;
    domvenref?: number;
}

export interface ICycleActionFinance {

}

interface IActionPossibleFinanceErreur {
    code: string;
    message: string;
}

interface ICycleValideEtatsOptions {
    etats: string;
}

export interface ICycleFinanceClass extends IResourceClass<IProchainEtatFinance> {
    new(data: any): IProchainEtatFinance;
    getProchainEtat(options: IProchainEtatOptionsFinance): IResource<IProchainEtatFinance>;
    getEtatActuel(options: IProchainEtatOptionsFinance): IResource<IEtatActuelFinance>;
    queryActionsPossibles(options: IProchainEtatOptionsFinance): IResourceArray<IProchainEtatOptionsFinance>;
    queryMessagesErreur(options: IMessagesErreurOptionsFinance): IResourceArray<string>;
    validateEtats(data: ICycleValideEtatsOptions): IResource<any>;
    executeAction(params: any, data: ICycleActionFinance): IResource<any>;
    genererPieceJustificative(params: any, data: ICycleActionFinance): IResource<any>;
}

export default module('core.resources.cycle-finance', [
    'core.services.api-config'
]).factory('CycleFinance', ProchainEtatFinanceFactory);

/* @ngInject */
function ProchainEtatFinanceFactory(ApiConfig: IApiConfig,
                                    $resource: IResourceService,
                                    $q: IQService): ICycleFinanceClass {
    const params = {};

    const actions = {
        getProchainEtat: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/finance/prochain-etat/:doccleint`
        },
        getEtatActuel: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/finance/etat-actuel/:doccleint`
        },
        queryActionsPossibles: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/finance/actions-possibles/:doccleint`,
            isArray: true
        },
        queryMessagesErreur: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/finance/messages-erreur/:doccleint/:domvenref`
        },
        validateEtats: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/finance/valide-etats`,
        },
        executeAction: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/cycles/finance/action/:doccleint`,
            interceptor: {
                responseError(response: IHttpResponse<IActionPossibleFinanceErreur>) {
                    if (response.data && (response.data.code === 'SOFE-20103' || response.data.code === 'ERREUR_DE_TRANSACTION')) {
                        return $q.reject({
                            lblTitre: 'G_LBL_FSA_CYCLE_EVENT',
                            lblMessage: response.data.message,
                            confirmAction: null,
                            error: response
                        });
                    } else {
                        return $q.reject({
                            lblTitre: 'G_LBL_FSA_CYCLE_EVENT',
                            lblMessage: 'G_MSG_ERREUR_ACTION',
                            error: response
                        });
                    }
                }
            }
        },
        genererPieceJustificative: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/cycles/finance/piece-justificative/:doccleint`
        }
    };

    return <ICycleFinanceClass>$resource(`${ApiConfig.ROOT}/cycles/finance`, params, actions);
}
