import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IDataTreeDataTypeClass {
    new(options?: IDataTreeDataTypeOptions): IDataTreeDataType;
}

export interface IDataTreeDataTypeOptions extends IDataTypeOptions {
}

export interface IDataTreeDataType extends IDataType {
}

export default module('core.services.data-tree-data-type', []).factory('DataTreeDataType', DataTreeDataTypeFactory);

/* @ngInject */
function DataTreeDataTypeFactory(DataType: IDataTypeClass): IDataTreeDataTypeClass {
    class DataTreeDataType extends DataType implements IDataTreeDataType {
        constructor(options: IDataTreeDataTypeOptions) {
            super(options);
        }
    }

    return DataTreeDataType;
}
