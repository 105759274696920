import {
    IAttributes,
    IAugmentedJQuery,
    ICloneAttachFunction,
    IComponentController,
    IDirective,
    IScope,
    module
} from 'angular';
import GridController, {IComposantGrid} from './ex-grid.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-grid', []).directive('exGrid', GridFactory);

interface IGridScope extends IScope {
    vm: IComposantGrid;
}

interface ITranscludeSlotFunction {
    (scope: IScope, cloneAttachFn: ICloneAttachFunction, parent?: IAugmentedJQuery, slotName?: string): JQuery;
}

function GridFactory(): IDirective {
    return {
        restrict: 'E',
        scope: Binding(
            Binding.MULTI_OCCURRENCE.OPTIONAL,
            Binding.LIMITE.OPTIONAL,
            {
                clickRowAction: '&?',
                doubleClickRowAction: '&?',
                hasClickRowAction: '<?',
                nombreColonnesAffichables : '=?nbColsAffichableOut'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: true,
        template: require('./ex-grid.html'),
        link(scope: IGridScope, element: IAugmentedJQuery, attrs: IAttributes, controller: IComponentController, transclude: ITranscludeSlotFunction) {

            // La grille utilise un transclue avancé ou le contenu passé est utilisé pour rendre le contenu de
            // l'accordéon d'une rangée. Pour cela, il faut lier le scope et insérer le contenu nous-mêmes.
            scope.vm.compileAccordeonTemplate = (index: number, rowScope: any) => {
                const newScope = scope.$parent.$new();

                // On ajoute les propriétés du scope de la rangé au scope transcludé
                const localProps = ['data', '$index', '$first', '$middle', '$last', '$event', '$odd'];
                Object.assign(newScope, localProps.reduce((props: any, prop: string) => {
                    props[prop] = rowScope[prop];
                    return props;
                }, {}));

                transclude(newScope, (clone: IAugmentedJQuery) => {
                    const parentElement = element.find(`.ex-grid-row:nth-child(${index + 1}) .ex-grid-row-more-content`);
                    parentElement.append(clone);

                    // On s'assure que le scope soit bien détruit
                    parentElement.on('$destroy', () => {
                        newScope.$destroy();
                    });
                });
            };
        },
        bindToController: true,
        controller: GridController,
        controllerAs: 'vm'
    };
}
