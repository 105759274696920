import { IDefaultsService } from '../../services/utils/defaults.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IComponentController, IPromise } from 'angular';

export interface IDialogCompteurLignes extends IComponentController {
    lblTitre: string;
    lblMessage: string;
    icon: string;
    multiOccurrence: IMultiOccurrence;
    total: number;
    erreur: boolean;
    fetching: boolean;

    fetchCompteur(): IPromise<any>;
}

/* @ngInject */
export default function DialogCompteurLignesController(defaults: IDefaultsService) {
    const vm: IDialogCompteurLignes = this;
    const ICON_INFO = 'info_outline';
    const lblFermer = 'G_LBL_BTN_FERMER';
    const lblMessage = 'G_LBL_MESSAGE_COMPTEUR_LIGNES';

    vm.$onInit = function $onInit() {
        defaults(vm, {
            icon: ICON_INFO,
            lblCancel: lblFermer,
            lblMessage: lblMessage
        });

        vm.fetchCompteur = fetchCompteur;

        fetchCompteur();
    }

    function fetchCompteur() {
        return vm.multiOccurrence.fetchNombreResultatsTrouves().then((resultat: number) => {
            vm.erreur = false;
            vm.total = resultat;
        }).catch(() => vm.erreur = true);
    }
}
