import { module } from 'angular';
import NavigationController from './navigation.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.navigation', []).component('navigation', {
    bindings: Binding({
        navigationOpened: '='
    }),
    template: require('./navigation.html'),
    controller: NavigationController,
    controllerAs: 'vm'
});
