import { module } from 'angular';
import { IInjectStyleService } from './inject-style.service';

interface IFormatColor {
    value: Array<number>;
    contrast: Array<number>;
}

export interface ICouleurThemeService {
    theme: string;
    listeTheme(): void;
    getCouleur(theme: string, paletteName?: string, hueName?: string, options?: ICouleurThemeOptions): string;
    changerCouleur(theme: string, isPortail?: boolean): void;
    changerCouleurPe(theme: string, isPortail?: boolean): void;
    formatColor(color: IFormatColor | string, options?: ICouleurThemeOptions): string;
}
interface ICouleurThemeOptions {
    contrast?: boolean;
    alpha?: number;

}
interface IAddPropertiesOptions {
    isPortail: boolean;
    objPalettes: any;
    propertiesBoutons: object;
    propertiesCard: object;
    [key: string]: any;
}

export default module('core.services.couleur-theme', []).factory('couleurTheme', CouleurThemeFactory);

/* @ngInject */
function CouleurThemeFactory($mdTheming: any, injectStyle: IInjectStyleService, Themes: any): ICouleurThemeService {

    const COLORS = ['primary', 'accent', 'warn', 'background'];

    class CouleurTheme implements ICouleurThemeService {
        theme: string;

        listeTheme() {
            const LISTE_THEMES = Object.keys($mdTheming.THEMES);
            const DEFAULT = LISTE_THEMES.indexOf('default');
            LISTE_THEMES.splice(DEFAULT, 1);

            return LISTE_THEMES;
        }

        getCouleur(theme: string, paletteName: string = 'primary', hueName: string = 'default', options: ICouleurThemeOptions = {}) {
            const COLOR = $mdTheming.THEMES[theme].colors[paletteName];
            const PALETTE = $mdTheming.PALETTES[COLOR.name];
            const HUE = PALETTE[COLOR.hues[hueName]];

            return this.formatColor(HUE, options);
        }

        changerCouleur(theme: string, isPortail: boolean = false) {
            this.theme = theme || 'default';

            if (!theme) return;
            theme = $mdTheming.THEMES[theme] ? theme : 'default';
            const COLOR = $mdTheming.THEMES[theme].colors;
            const PRIMAIRE = COLOR.primary;
            const ACCENT = COLOR.accent;
            const PALETTE_PRIMARY = $mdTheming.PALETTES[PRIMAIRE.name];
            const PALETTE_ACCENT = $mdTheming.PALETTES[ACCENT.name];
            //Palette primaire
            const PALETTE_PRIMARY_DEFAULT = PALETTE_PRIMARY[PRIMAIRE.hues['default']];
            const PALETTE_PRIMARY_HUE1 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-1']];
            const PALETTE_PRIMARY_HUE2 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-2']];
            const PALETTE_PRIMARY_HUE3 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-3']];
            //Palette accent
            const PALETTE_ACCENT_HUE1 = PALETTE_ACCENT[ACCENT.hues['hue-1']];
            const PALETTE_ACCENT_HUE2 = PALETTE_ACCENT[ACCENT.hues['hue-2']];

            COLORS.forEach(paletteName => {

                const CSS = `${Object.keys(COLOR[paletteName].hues).map((hueName: string) => {
                    const className = (hueName === 'default') ? paletteName : `${paletteName}-${hueName}`;
                    return `
                .ex-${className}-fg {
                    color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                }

                .ex-${className}-fg--hover:hover:not(:disabled):not(.ex-disabled--disabled) {
                    color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                }

                .ex-${className}-bc {
                    border-color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                }

                .ex-${className}-bc--focus:focus {
                    border-color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                }

                .ex-${className}-bg:not(:disabled):not(.ex-disabled--disabled),
                button.ex-${className}-bg:not(:disabled):not(.ex-disabled--disabled):not(:hover) md-icon {
                    background-color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                    color: white !important;
                }

                .ex-${className}-bg--hover:hover:not(:disabled):not(.ex-disabled--disabled) {
                    background-color: ${this.getCouleur(theme, paletteName, hueName)} !important;
                    color: white !important;
                }`;
                }).join('\n')}`;

                injectStyle(CSS, 'app_theme_' + paletteName);
            });

            //Styles supplémentaires non standard
            injectStyle(`.ex-bouton-nouveau.md-button:not([disabled]):not(.ex-disabled--disabled):hover md-icon,
            .ex-grid-menu-actions .md-button:not([disabled]):not(.ex-disabled--disabled):hover md-icon,
            .ex-grid-menu-actions .md-button:focus md-menu:not([disabled]):not(.ex-disabled--disabled),
            .ex-hierarchie-item-row-actions .md-button:not([disabled]):not(.ex-disabled--disabled):hover md-icon,
            .ex-hierarchie-item-row-actions .md-button:focus md-menu:not([disabled]):not(.ex-disabled--disabled),
            .md-button.md-icon-button:not([disabled]):not(.ex-disabled--disabled):hover md-icon,
            .md-button.md-icon-button:not([disabled]):not(.ex-disabled--disabled):hover i,
            .md-button.ex-button-icon-action:not([disabled]):not(.ex-disabled--disabled):hover md-icon,
            .ex-plaquettes-fonctions-transversales-active md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; }

            .ex-plaquettes-fonctions-transversales-active md-icon,
            .ex-pagination-bouton.md-icon-button:not(:disabled):hover md-icon,
            .ex-pagination-bouton.md-icon-button:not(:disabled):focus md-icon,
            { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; }

            .ex-button-action-hover:not([disabled]):hover,
            .ex-bouton-nouveau.md-button:not([disabled]).ex-bouton-nouveau-text:not(:hover) span:not(.ex-disabled--disabled),
            .ex-bouton-nouveau.md-button:not([disabled]).ex-bouton-nouveau-forage-exact:not(:hover) span:not(.ex-disabled--disabled)
            {
                color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
            }

            .md-icon-button.ex-button-icon-action-bg:not([disabled])
            {
                background-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
                color: ${this.formatColor(PALETTE_ACCENT_HUE1, {contrast: true})} !important;
            }

            .md-icon-button.ex-button-icon-action-bg[disabled]
            {
                background-color: rgba(0, 0, 0, 0.12) !important;
                color: ${this.formatColor(PALETTE_ACCENT_HUE1, {contrast: true})} !important;
            }

            .md-icon-button.ex-button-icon-action-bg:not([disabled]) md-icon,
            .md-icon-button.ex-button-icon-action-bg:not([disabled]):hover md-icon,
            .md-icon-button.ex-button-icon-action-bg[disabled] md-icon,
            .md-icon-button.ex-button-icon-action-bg[disabled]:hover md-icon
            {
                background-color: transparent;
                color: ${this.formatColor(PALETTE_ACCENT_HUE1, {contrast: true})} !important;
            }

            .md-icon-button.ex-button-icon-action-bg:not([disabled]):hover
            {
                background-color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important;
                color: ${this.formatColor(PALETTE_ACCENT_HUE2, {contrast: true})} !important;
            }

            ex-bouton-nouveau .md-button:not([disabled]):not(.ex-disabled--disabled) md-icon,
            .ex-bouton-nouveau-menu-item .ex-menu-item-bouton
            { color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            .ex-grid-row:hover:not(.ex-grid-row--opened) .ex-grid-row-content .md-icon-button:hover:not([disabled]) md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; }

            .ex-button-icon-action.md-button:not([disabled]):not(.ex-disabled--disabled) md-icon,
            .ex-grid-menu-actions .md-button:not([disabled]):not(.ex-disabled--disabled):not(.ex-menu-item-button--secondaire) md-icon,
            .ex-hierarchie-item-row-actions .md-button:not([disabled]):not(.ex-disabled--disabled) md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE1)}; }

            .ex-hierarchie-item-row-actions .md-button:not([disabled]):not(.ex-disabled--disabled) md-icon,
            .ex-pagination-bouton:not(.ex-pagination-bouton-blend).md-icon-button:not(:disabled) md-icon,
            .ex-pagination-bouton:not(.ex-pagination-bouton-blend).md-icon-button:not(:disabled) md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            .ex-button-action.md-button:not(:disabled):not(.ex-disabled--disabled),
            .ex-dialog-actions .md-button:not(:disabled):not(.ex-disabled--disabled),
            .ex-menu-item-compteur{ background-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            .ex-button-action.md-button:hover:not(:disabled):not(.ex-disabled--disabled),
            .ex-dialog-actions .md-button:hover:not(:disabled):not(.ex-disabled--disabled),
            .ex-button-action.md-button:focus:not(:disabled):not(.ex-disabled--disabled),
            .ex-dialog-actions .md-button:focus:not(:disabled):not(.ex-disabled--disabled) { background-color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; }

            .ex-card-message-header md-checkbox .md-icon { border-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important; }
            .ex-card-message-header md-checkbox:not([disabled]).md-checked .md-icon { background-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important; }
            .ex-card-message-liste md-checkbox:not([disabled]).md-checked .md-icon { background-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            .ex-suivi-modification-circle, .ex-suivi-modification-circle::before, .ex-suivi-modification-circle-avec-mod::after {
                color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            .ex-infos-complementaires-row-valeur:hover { border-bottom: solid ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} 2px; }

            a,
            ex-menu-item:not(.ex-menu--menu-item) a:not(.ex-menu-item-button--courant):not([disabled]):not(.md-icon-button):not(.md-fab):not(.ex-menu-item-button--secondaire) md-icon,
            ex-menu-item:not(.ex-menu--menu-item) a:not(.ex-menu-item-button--courant):not([disabled]):not(.md-icon-button):not(.md-fab):not(.ex-menu-item-button--secondaire) md-icon span
            { color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            a:hover md-icon:not(a.md-button) { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; }

            md-select.md-default-theme:not([disabled]):focus .md-select-value,
            md-select:not([disabled]):focus .md-select-value
            { border-bottom-color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important }

            md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label,
            md-input-container:not(.md-input-invalid).md-input-focused label
            { color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important }

            md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused .md-input,
            md-input-container:not(.md-input-invalid).md-input-focused .md-input
            { border-color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important }

            md-switch:not([disabled]).md-default-theme.md-checked .md-bar,
            md-switch:not([disabled]).md-checked .md-bar { background: rgba(${PALETTE_PRIMARY_HUE2.value.join(',')},0.5) !important; }
            md-switch:not([disabled]).md-checked .md-thumb { background: ${this.formatColor(PALETTE_PRIMARY_HUE3)} !important; }

            md-menu-item:focus:before, md-option:focus:before,
            .ex-recherche-menu-content .md-button:focus:before,
            .ex-input-lov-item--active:not(.ex-input-lov-footer):before { background: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
                background: ${this.formatColor(PALETTE_PRIMARY_HUE3)} !important;
                color: ${this.formatColor(PALETTE_PRIMARY_HUE3)} !important; }

            md-tabs .md-tab.md-active,
            md-tabs .md-tab.md-active md-icon,
            md-tabs .md-tab.md-focused,
            md-tabs .md-tab.md-focused md-icon,
            md-tabs.md-default-theme .md-tab.md-active,
            md-tabs.md-default-theme .md-tab.md-active md-icon,
            md-tabs.md-default-theme .md-tab.md-focused,
            md-tabs.md-default-theme .md-tab.md-focused md-icon,
            .ex-input-lov--focused label,
            .ex-checkbox-focused
            { color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            md-toast.md-success-toast-theme .md-toast-content { background-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important; }

            .dropzone {
                border-color: ${this.formatColor(PALETTE_PRIMARY_HUE1)} !important;
            }

            .ex-grid-head .ex-grid-checkbox:not([disabled]) .md-icon { border-color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            .ex-grid-head .ex-grid-checkbox.md-checked .md-icon,
            md-checkbox:not([disabled]).md-checked .md-icon { background: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            .ex-grid-body .ex-grid-checkbox.md-checked .md-icon {
                background: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
                border-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} }

            .ex-grid-row:hover ex-menu:not(.ex-recherche-menu-actions--menu-more) md-icon { color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }

            .ex-pastille-filtre-term-content:not(:disabled):hover { color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            .pagination > li > a, .pagination > li > span
            { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important }

            ex-menu-item md-progress-circular path {
                stroke: ${this.formatColor(PALETTE_ACCENT_HUE1)}
            }

            button.md-button.ex-favoris:hover .ex-favoris-inactif:before,
            button.md-button.ex-favoris .ex-favoris-actif:before
            { color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important; }

            .ex-introspection-item--active:not(.ex-introspection-footer):before {
                background-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            .highlight {
                background: ${this.formatColor(PALETTE_ACCENT_HUE1, {alpha: .25})}
            }

            .ex-navigation-item-active, .navigation-menu-button--active, .ex-navigation-item-active span.ex-navigation-item-text {
                border-left: 4px solid ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
            }

            .ex-navigation-item-group a.ex-navigation-item-button.ex-navigation-item-active span.ex-navigation-item-text {
                color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
            }

            .ex-navigation-menu-item--focused {
                border-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            ex-sommaire .md-tab-item md-icon {
                color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            ex-card md-card:focus-within, ex-exp-card md-card:focus-within {
                box-shadow: 0 9px 28px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
                outline: 1px solid ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            .ex-dialog-ecran .ex-bandeau-page {
                background-color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            .ex-grid-row-updated--statut-updated:not(.ex-grid-row-updated--statut-error) {
                color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }

            .accueil .accueil-zone-carte md-icon,
            .accueil .accueil-boite-alerte .accueil-boite-alerte-buttons md-icon.accueil-boite-alerte-bouton-actif {
                color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
            }

            .accueil .accueil-zone-carte .accueil-carte-info-texte .md-button {
                background-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
            }

            .ex-multi-occurrence-bouton-toggle-criteres-suggeres,
            .ex-multi-occurrence-bouton-toggle-criteres-suggeres > md-icon {
                color: ${this.formatColor(PALETTE_ACCENT_HUE1)};
            }`, 'app_theme_couleur');
        }

        changerCouleurPe(theme: string, isPortail: boolean = false) {

            this.theme = theme || 'default';

            if (!theme) return;
            theme = $mdTheming.THEMES[theme] ? theme : 'default';
            const COLOR = $mdTheming.THEMES[theme].colors;
            const PRIMAIRE = COLOR.primary;
            const ACCENT = COLOR.accent;
            const PALETTE_PRIMARY = $mdTheming.PALETTES[PRIMAIRE.name];
            const PALETTE_ACCENT = $mdTheming.PALETTES[ACCENT.name];
            //Palette primaire
            const PALETTE_PRIMARY_DEFAULT = PALETTE_PRIMARY[PRIMAIRE.hues['default']];
            const PALETTE_PRIMARY_HUE1 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-1']];
            const PALETTE_PRIMARY_HUE2 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-2']];
            const PALETTE_PRIMARY_HUE3 = PALETTE_PRIMARY[PRIMAIRE.hues['hue-3']];
            //Palette accent
            const PALETTE_ACCENT_HUE1 = (isPortail) ? PALETTE_ACCENT[ACCENT.hues['hue-1']] : PALETTE_ACCENT[ACCENT.hues['hue-1']];
            const PALETTE_ACCENT_HUE2 = PALETTE_ACCENT[ACCENT.hues['hue-2']];
            //objet pour ajouter les couleurs à la methode addProperties
            const objButtons = this.getProperties(theme, 1);
            const objCards = this.getProperties(theme, 2);
            const objIcons = this.getProperties(theme, 3);
            const objAccordeon = this.getProperties(theme, 4);
            const objInput = this.getProperties(theme, 5);
            const objModals = this.getProperties(theme, 6);
            const objGeneral = this.getProperties(theme, 7);
            const objToolbar = this.getProperties(theme, 8);
            const objDatePicker = this.getProperties(theme, 9);
            const objGrid = this.getProperties(theme, 10);
            const objPalettes = {
                PALETTE_PRIMARY: PALETTE_PRIMARY,
                PALETTE_ACCENT: PALETTE_ACCENT,
                PALETTE_PRIMARY_DEFAULT: PALETTE_PRIMARY_DEFAULT,
                PALETTE_PRIMARY_HUE1: PALETTE_PRIMARY_HUE1,
                PALETTE_PRIMARY_HUE2: PALETTE_PRIMARY_HUE2,
                PALETTE_PRIMARY_HUE3: PALETTE_PRIMARY_HUE3,
                PALETTE_ACCENT_HUE1: PALETTE_ACCENT_HUE1,
                PALETTE_ACCENT_HUE2: PALETTE_ACCENT_HUE2,
            }

            let properties: IAddPropertiesOptions = {
                isPortail: isPortail,
                objPalettes: objPalettes,
                propertiesBoutons: objButtons,
                propertiesCard: objCards,
                propertiesIcons: objIcons,
                propertiesAccordeon: objAccordeon,
                propertiesInput: objInput,
                propertiesModals: objModals,
                propertiesGeneral: objGeneral,
                propertiesToolbar: objToolbar,
                propertiesDatePicker: objDatePicker,
                propertiesGrid: objGrid
            };

            injectStyle(`
            .ex-grid-menu-actions .md-button:not([disabled]):not(.ex-disabled--disabled , .ex-icon-primary-attach_file ):hover md-icon,
            .ex-grid-menu-actions .md-button:focus md-menu:not([disabled]):not(.ex-disabled--disabled),
            .ex-grid-row:hover:not(.ex-grid-row--opened) .ex-grid-row-content .md-icon-button:hover:not([disabled]) md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE2)} !important; ${this.addProperties({ ...properties, isButton: true })}}
            .ex-grid-menu-actions .md-button:not([disabled]):not(.ex-disabled--disabled):not(.ex-menu-item-button--secondaire) md-icon,
            .ex-hierarchie-item-row-actions .md-button:not([disabled]):not(.ex-disabled--disabled) md-icon
            { color: ${this.formatColor(PALETTE_ACCENT_HUE1)}; }
            .ex-grid-head .ex-grid-checkbox:not([disabled]) .md-icon { border-color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }
            .ex-grid-head .ex-grid-checkbox.md-checked .md-icon,
            md-checkbox:not([disabled]).md-checked .md-icon { background: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }
            .ex-grid-body .ex-grid-checkbox.md-checked .md-icon {
                background: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important;
                border-color: ${this.formatColor(PALETTE_ACCENT_HUE1)} }
            .ex-grid-row:hover ex-menu:not(.ex-recherche-menu-actions--menu-more) md-icon { color: ${this.formatColor(PALETTE_ACCENT_HUE1)} !important; }
            .ex-pastille-filtre-term-content:not(:disabled):hover { color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }
            .ex-grid-row-updated--statut-updated:not(.ex-grid-row-updated--statut-error) {
                color: ${this.formatColor(PALETTE_PRIMARY_DEFAULT)} !important;
            }
            .ex-grid-head-col { 
                ${this.addProperties({ ...properties, isGrid: true, isPrimary: true, isDefault: true })}
            }

            .ex-primary-bg:not(:disabled):not(.ex-disabled--disabled, .ex-accordeon-toolbar-titre),
            .ex-bandeau-page {
                ${this.addProperties({ ...properties, isGeneral: true, isToolbar: true, isPrimary: true })}
            }
            .ex-card-title-text,
            .ex-saisie-titre {
                ${this.addProperties({ ...properties, isCard: true, isPrimary: true, isTypography: true })}
            }

            .md-datepicker-button:hover > md-icon { ${this.addProperties({ ...properties, isButton: true, isIcon: true, isHover: true })} }

            .dropzone.dz-clickable:hover > div > md-icon {${this.addProperties({ ...properties, isFileUpload: true, isFileUploadIconHover: true })} }
            .dropzone.dz-clickable:hover .dz-message * { ${this.addProperties({ ...properties, isFileUpload: true, isFileUploadIconHover: true })} }
            .dropzone.dz-clickable:hover { ${this.addProperties({ ...properties, isFileUpload: true, isFileUploadIconHover: true })}  }
            .dropzone.dz-clickable .dz-message > md-icon { ${this.addProperties({ ...properties, isFileUpload: true, isFileUploadIcon: true })}}
            .dropzone{ ${this.addProperties({ ...properties, isFileUpload: true, isFileUploadBorder: true })} }
            .dropzone.dz-clickable .dz-message *{ ${this.addProperties({ ...properties, isFileUpload: true, isFileUploadMessage: true })} }

            md-icon:not(.close-ico,.ex-titre-modal-icon, 
                .sticky-button-list-icon, 
                .ex-sticky-prochain-etat-icon, 
                .ex-etape-etat, 
                .ex-md-icon-close, 
                .ex-toggle-icon, 
                .ex-account-circle-icon, 
                .ex-pagination-bouton-navigate_next, 
                .ex-pagination-bouton-last_page, 
                .ex-pagination-bouton-navigate_before, 
                .ex-pagination-bouton-first_page, 
                .ex-grid-head-move-bouton,
                .ex-icon-primary-attach_file,
                .ex-saisie-header-icon,
                .ex-rouge-500, 
                .ex-primary-check) { ${this.addProperties({ ...properties, isButton: true, isIcon: true })}}
            .ex-icon-primary { ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isButtonIcon: true })} }

            .ex-input-imputation .md-icon-button md-icon { ${this.addProperties({ ...properties, isButton: true, isIcon: true })}}
            md-select.md-default-theme .md-select-icon, md-select .md-select-icon { ${this.addProperties({ ...properties, isButton: true, isIcon: true })}}

            md-radio-button:not([disabled]).md-primary.md-checked .md-off { ${this.addProperties({ ...properties, radioButton: true, radioBorder: true })} }
            md-radio-group:not([disabled]) .md-primary .md-on,
            md-radio-button:not([disabled]).md-primary .md-on { ${this.addProperties({ ...properties, radioButton: true, radioBackgroud: true })} }

            .ex-accordeon-titre {
                ${this.addProperties({ ...properties, isAccordeon: true, isPrimary: true, isTypography: true })}
            }

            md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
                background: ${this.formatColor(PALETTE_PRIMARY_HUE3)} !important;
                color: ${this.formatColor(PALETTE_PRIMARY_HUE3)} !important; 
            }

            md-tabs .md-tab.md-active,
            md-tabs .md-tab.md-active md-icon,
            md-tabs .md-tab.md-focused,
            md-tabs .md-tab.md-focused md-icon,
            md-tabs.md-default-theme .md-tab.md-active,
            md-tabs.md-default-theme .md-tab.md-active md-icon,
            md-tabs.md-default-theme .md-tab.md-focused,
            md-tabs.md-default-theme .md-tab.md-focused md-icon,
            .ex-input-lov--focused label,
            .ex-checkbox-focused
            { color: ${this.formatColor(PALETTE_PRIMARY_HUE2)} !important; }

            ex-card md-card:focus-within, ex-exp-card md-card:focus-within {
                ${this.addProperties({ ...properties, isCard: true, isPrimary: true, isFocus: true })}
            }            
            .ex-accordeon-style:focus-within {
                ${this.addProperties({ ...properties, isCard: true, isPrimary: true, isFocus: true, noBorderTop: true })}
            }
            md-card { ${this.addProperties({ ...properties, isCard: true, isPrimary: true })} }
            .md-whiteframe-8dp { ${this.addProperties({ ...properties, isCard: true, isPrimary: true })} }
            md-card-title
            md-dialog.md-default-theme, md-dialog { 
                ${this.addProperties({ ...properties, isModal: true, isDiv: true })} 
            }

            .ex-button-action-icon.md-button md-icon:not(:disabled):not(.ex-disabled--disabled) {  
                ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isButtonIcon: true })} 
            }
            .ex-disabled--disabled:not(.ex-button-action-plat), .ex-disabled--disabled:hover:not(.ex-button-action-plat),
            .ex-button-secondary.md-button[disabled], 
            .ex-dialog-actions-secondaires .md-button[disabled], 
            .ex-button-secondary.md-button.ex-disabled--disabled, 
            .ex-dialog-actions-secondaires .md-button.ex-disabled--disabled { background-color: rgba(238, 238, 238, 1) !important;}
            .ex-button-secondary.md-button:hover:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-tertiary), 
            .ex-dialog-actions-secondaires .md-button:hover:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-tertiary), 
            .ex-button-secondary.md-button:focus:not(:disabled):not(.ex-disabled--disabled), 
            .ex-dialog-actions-secondaires .md-button:focus:not(:disabled):not(.ex-disabled--disabled){
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isDisabled: true })}
            }
            .ex-mono-occurrence-ecran-fixed-width, .ex-mono-occurrence-ecran-content, .ex-mono-occurrence-ecran-content::before { ${this.addProperties({ ...properties, isDiv: true })}} 
            pe-ecran-container, .app-content, .pe-ecran-container-content, .ex-mono-occurrence-ecran-container-bouton-action, .sticky-cycle-messages { ${this.addProperties({ ...properties, isDiv: true })} } 
            .ex-mono-occurrence-ecran-content-wrapper { ${this.addProperties({ ...properties, isDiv: true })} }
            .ex-dialog-content, .ex-dialog-container, .ex-dialog-ecran::before { ${this.addProperties({ ...properties, isModal: true, isDiv: true })} }
            
            .sticky-cycle-list-buttons-text,
            .sticky-cycle-list-etapes-button
             {
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isTypography: true })}
            }

            .ex-selecteur-separator-action-add, 
            .ex-selecteur-separator-action-rm,
            .ex-grid-head-move-bouton {
                ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isOther: true, isButtonIcon: true })} 
            }

            .ex-selecteur-separator-action-add:hover, 
            .ex-selecteur-separator-action-rm:hover,
            .ex-grid-head-move-bouton {
                ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isOther: true, isButtonIcon: true, isHover: true })} 
            }

            .ex-accordeon button:focus md-icon { ${this.addProperties({ ...properties, isAccordeon: true, isIcon: true, isFocus: true })} }
            .ex-pieces-jointes-no-border { ${this.addProperties({ ...properties, isAccordeon: true, isBorderFileUpload: true })}   }
            .ex-accordeon-style { ${this.addProperties({ ...properties, isAccordeon: true })} }

            .ex-accordeon-toolbar-titre {
                ${this.addProperties({ ...properties, isAccordeon: true, isTitre: true })}
            }


            .md-button:not([disabled]):not(.ex-disabled--disabled, .ex-icon-no-hover):hover > md-icon:not(.sticky-cycle-icon-hover, .ex-md-icon-close, .ex-md-icon-save, .ex-icon-no-hover),
            .ex-link-button:not([disabled]):not(.ex-disabled--disabled, .ex-icon-no-hover):hover > md-icon:not(.sticky-cycle-icon-hover, .ex-md-icon-close, .ex-md-icon-save, .ex-icon-no-hover),
            .ex-bouton-nouveau:not([disabled]):not(.ex-disabled--disabled):hover > md-icon,
            md-select.md-default-theme:hover .md-select-icon, md-select:hover .md-select-icon,
            .ex-bouton-nouveau.md-button:not([disabled]):not(.ex-disabled--disabled):hover md-icon
            {
                ${this.addProperties({ ...properties, isButton: true, isIcon: true, isHover: true })}
            }
            md-menu-content md-menu-item .md-button[disabled] > md-icon,
            #saveButton.ex-disabled--disabled > md-icon,
            #saveButton.ex-disabled--disabled  {
                ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isDisabled: true, isIconDisabled: true })}
            }

            md-menu-content.md-default-theme md-menu-item .md-button[disabled], 
            md-menu-content md-menu-item .md-button[disabled], 
            md-menu-content.md-default-theme md-menu-item .md-button[disabled] md-icon, 
            md-menu-content md-menu-item .md-button[disabled] md-icon {
                ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isDisabled: true, isIconDisabled: true })}
            }
            
            ex-menu-selection-specialisee > .ex-menu-selection-specialisee-fonce.md-button[disabled] { 
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isOther: true })}
            }

            ex-button-action.md-button,
            .ex-menu--menu-item a.md-button,
            .ex-menu-item-button,
            .md-button:not(.sticky-cycle-list-etapes-button, 
                           .ex-menu-bouton-texte, .ex-navigation-item-button, 
                           .ex-toggle-box-header, .ex-pagination-bouton-navigate_next, 
                           .ex-pagination-bouton-last_page, .ex-pagination-bouton-navigate_before, 
                           .ex-pagination-bouton-first_page, .ex-pagination-bouton, .ex-grid-head-move-bouton, .pe-toolbar-button-logo-compagnie),
            .ex-button-action,
            .ex-cycle-list-etapes-info-secondaire,
            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled):hover
             { 
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isTypography: true, isSize: true })}
            }
            .ex-pagination-bouton-navigate_next, 
            .ex-pagination-bouton-last_page, 
            .ex-pagination-bouton-navigate_before, 
            .ex-pagination-bouton-first_page{
                ${this.addProperties({ ...properties, isIconFlotant: true, isPagination: true })}
            }

            .ex-pastille-filtre-remove-btn:hover:not(:disabled):not(.ex-disabled--disabled){
                ${this.addProperties({ ...properties, isButton: true, isIcon: true, isHover: true })}
            }
            
            .ex-primary-check{ ${this.addProperties({ ...properties, isOnlyIcon: true })} }
            .ex-saisie-header-icon {
                ${this.addProperties({ ...properties, isOnlyIcon: true, isOther: true })}
            }
            .ex-button-action.md-button:not(:disabled):not(.ex-disabled--disabled),
            .ex-dialog-actions .md-button:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-warm),
            .sticky-list-principal:not(:hover),
            .ex-menu-item-compteur{ ${this.addProperties({ ...properties, isButton: true, isPrimary: true })}}

            .ex-button-action.md-button:hover:not(:disabled):not(.ex-disabled--disabled),
            .ex-dialog-actions .md-button:hover:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-warm),
            .ex-button-action.md-button:focus:not(:disabled):not(.ex-disabled--disabled),
            .md-button:not([disabled]).sticky-button-list-principal-hover:hover,
            .ex-dialog-actions .md-button:focus:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-warm) 
            { 
            ${this.addProperties({ ...properties, isButton: true, isPrimary: true, isHover: true })} 
            }

            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled), .ex-dialog-actions-warm .md-button:not(:disabled):not(.ex-disabled--disabled)
            { ${this.addProperties({ ...properties, isButton: true, isPrimary: true })}}
            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled) md-icon 
            { ${this.addProperties({ ...properties, isButton: true, isSecondary: true, isButtonIcon: true })}}
            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled) { ${this.addProperties({ ...properties, isButton: true, isSecondary: true })} }
            .ex-button-secondary-typo { ${this.addProperties({ ...properties, isButton: true, isSecondary: true, isTypo: true })} }
            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled):hover { ${this.addProperties({ ...properties, isButton: true, isSecondary: true, isHover: true })} }
            .ex-button-secondary.md-button:not(:disabled):not(.ex-disabled--disabled):hover
             { 
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isTypography: true, isSize: true })}
            }

            .ex-dialog-actions-tertiary,
            #annulerButton,
            ex-card-cycle .ex-button-cycle > div > button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled) { ${this.addProperties({ ...properties, isButton: true, isTertiary: true })} }

            .ex-dialog-actions-tertiary:hover,
            #annulerButton:hover,
            ex-card-cycle .ex-button-cycle > div > button.ex-button.md-button:not(:disabled):not(.ex-disabled--disabled):hover { ${this.addProperties({ ...properties, isButton: true, isTertiary: true, isHover: true })} }
            .ex-dialog-actions-warm { ${this.addProperties({ ...properties, isButton: true, isWarm: true })} }
            .ex-dialog-actions-warm:hover { ${this.addProperties({ ...properties, isButton: true, isWarm: true, isHover: true })} }
            .ex-dialog-actions-error {  ${this.addProperties({ ...properties, isButton: true, isError: true })} }
            .ex-card-message-erreur .ex-card-title-text md-icon { ${this.addProperties({ ...properties, isIconFlotant: true, isWarm: true })} }
            .ex-card-message-erreur md-card-title-text span { ${this.addProperties({ ...properties, isIconFlotant: true, isWarm: true })} }
            .ex-button-action-plat {
                padding: 0px 8px;
                margin: 6px 0px 6px 8px;
            }
            .ex-button-action-plat.md-button md-icon { 
                margin-right: 5px;
              }

            md-icon.ex-rouge-500 {
                ${this.addProperties({ ...properties, isIconFlotant: true, isWarm: true })}
            }
            .ex-disabled--disabled:not(.ex-button-action-plat), .ex-disabled--disabled:hover:not(.ex-button-action-plat),
            .ex-button-secondary.md-button[disabled], 
            .ex-dialog-actions-secondaires .md-button[disabled], 
            .ex-button-secondary.md-button.ex-disabled--disabled, 
            .ex-dialog-actions-secondaires .md-button.ex-disabled--disabled { background-color: rgba(238, 238, 238, 1) !important;}
            .ex-dialog-actions-secondaires .md-button:hover:not(:disabled):not(.ex-disabled--disabled, .ex-dialog-actions-tertiary), 
            .ex-button-secondary.md-button:focus:not(:disabled):not(.ex-disabled--disabled), 
            .ex-dialog-actions-secondaires .md-button:focus:not(:disabled):not(.ex-disabled--disabled){
                ${this.addProperties({ ...properties, isGeneral: true, isButton: true, isDisabled: true })}
            }

            .md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, 
            .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, 
            .md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, 
            .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator
            {
                ${this.addProperties({ ...properties, isDatePicker: true, isSelected: true, isTypography: true })}
            }

            md-input-container:not(.md-input-invalid):focus-within .md-input
            { 
                ${this.addProperties({ ...properties, isInput: true, isPrimary: true, isFocus: true })} 
            }

            #ex-icon,
            #ex-saisie-header.ex-saisie-header-icon {
                ${this.addProperties({ ...properties, isGeneral: true, isIcon: true, isDefault: true, isBorder: true })} 
            }
            
            .ex-account-circle-icon {
                ${this.addProperties({ ...properties, isIconFlotant: true, isPrimary: true, isinverse: true })} 
            }

            .ex-suivi-modification-libelle-usager{
                ${this.addProperties({ ...properties, isGeneral: true, isIcon: true, isDefault: true, isTypography: true })} 
            }

            `, 'app_theme_couleur');
        }
        addProperties(properties: any) {
            let result: string = "";
            if (properties.isPortail) {

                if (properties.isIconFlotant) {
                    return this.getPropertiesIconFlotant(properties);
                }

                if (properties.isGeneral) {
                    return this.getPropertiesGeneral(properties);
                }

                if (properties.isModal) {
                    return this.getpropertiesModals(properties);
                }

                if (properties.isCard) {
                    return this.getPropertiesCard(properties);
                }

                if (properties.isDiv) {
                    return this.getStyleFromObject(properties?.propertiesGeneral?.GENERAL?.DIV);
                }

                if (properties.isFileUpload) {
                    return this.getPropertiesFileUpload(properties)
                }

                if (properties.radioButton) {
                    return this.getPropertiesRadionButton(properties)
                }

                if (properties.isButton) {
                    return this.getPropertiesButton(properties);
                }

                //style des icones sans boutons
                if (properties.isOnlyIcon) {
                    if (properties.isOther) {
                        return this.getStyleFromObject(properties?.propertiesIcons?.PRIMARY?.OTHER?.properties)
                    }
                    return this.getStyleFromObject(properties?.propertiesIcons?.PRIMARY?.DEFAULT?.ICON)
                }

                //formulaires type accordeon
                if (properties.isAccordeon) {
                    return this.getPropertiesAccordeon(properties);

                }

                //styles input
                if (properties.isInput) {
                    if (properties.isPrimary) {
                        if (properties.isFocus) {
                            return this.getStyleFromObject(properties?.propertiesInput?.PRIMARY?.FOCUS?.properties)
                        }
                        return this.getStyleFromObject(properties?.propertiesInput?.PRIMARY?.DEFAULT?.properties)
                    }
                }

                if (properties.isDatePicker) {
                    return this.getPropertiesDatePicker(properties)
                }

                if (properties.isGrid) {
                    return this.getPropertiesGrid(properties);
                }
            }
            return result;
        }

        getPropertiesIconFlotant(properties: any) {
            if (properties.isPrimary) {
                if (properties.isinverse) {
                    return this.getStyleFromObject(properties?.propertiesIcons?.PRIMARY?.INVERSE?.ICON)
                }
            }
            if (properties.isWarm) {
                return this.getStyleFromObject(properties?.propertiesIcons?.WARM?.DEFAULT?.ICON)
            }
            if (properties.isPagination) {
                return this.getStyleFromObject(properties?.propertiesIcons?.PRIMARY?.OTHER?.ICON)
            }
        }
        getPropertiesGrid(properties: any) {
            if (properties.isPrimary) {
                if (properties.isDefault) {
                    return this.getStyleFromObject(properties?.propertiesGrid?.PRIMARY?.DEFAULT?.properties)
                }
            }
            return "";
        }

        getPropertiesDatePicker(properties: any) {
            if (properties.isSelected) {
                if (properties.isTypography) {
                    return this.getStyleFromObject(properties?.propertiesDatePicker?.PRIMARY?.SELECTED?.TYPOGRAPHY)
                }
            }
        }
        getpropertiesModals(properties: any) {
            if (properties.isDiv) {
                return this.getStyleFromObject(properties?.propertiesModals.GENERAL?.DIV)
            }
            return ""
        }
        getPropertiesRadionButton(properties: any) {
            if (properties.radioBackgroud) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.RADIOBUTTON.BACKGROUND);
            }
            if (properties.radioBorder) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.RADIOBUTTON.BORDER);
            }
        }

        getPropertiesAccordeon(properties: any) {
            if (properties.isPrimary) {
                if (properties.isTypography) {
                    return this.getStyleFromObject(properties?.propertiesAccordeon?.PRIMARY?.DEFAULT?.TYPOGRAPHY)
                }
            }
            //if (properties.isGeneral) {
            if (properties.isIcon) {
                if (properties.isFocus) {
                    return this.getStyleFromObject(properties?.propertiesAccordeon?.GENERAL?.ICON?.FOCUS.ICON)
                }
            }

            if (properties.isBorderFileUpload) {
                return this.getStyleFromObject(properties?.propertiesAccordeon?.GENERAL?.FILEUPLOAD?.FILEUPLOADBORDER)
            }
            if (properties.isTitre) {
                return this.getStyleFromObject(properties?.propertiesAccordeon?.GENERAL?.TITRE?.DEFAULT?.properties)
            }
            //} else {
            return this.getStyleFromObject(properties?.propertiesAccordeon?.PRIMARY?.DEFAULT?.properties)
            //}
        }

        getPropertiesFileUpload(properties: any) {
            if (properties.isFileUploadBorder) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.FILEUPLOAD?.FILEUPLOADBORDER);
            }
            if (properties.isFileUploadMessage) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.FILEUPLOAD?.FILEUPLOADMESSAGE);

            }
            if (properties.isFileUploadIcon) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.FILEUPLOAD?.FILEUPLOADICON);

            }
            if (properties.isFileUploadIconHover) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.FILEUPLOAD?.FILEUPLOADHOVER);
            }
        }

        getPropertiesGeneral(properties: any) {
            if (properties.isToolbar) {
                if (properties.isPrimary) {
                    return this.getStyleFromObject(properties?.propertiesToolbar?.DEFAULT?.properties)
                }
            }
            if (properties.isIcon) {
                if (properties.isDefault) {
                    if (properties.isBorder) {
                        return this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.ICON?.DEFAULT?.BORDER)
                    }
                    if (properties.isTypography) {
                        return this.getStyleFromObject(properties?.propertiesModals.PRIMARY?.SELECTED?.TYPOGRAPHY)
                    }
                }
                if (properties.isMargin) {
                    return this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.ICON?.DEFAULT?.ICON)
                }
            }
            if (properties.isButton) {
                if (properties.isOther) {
                    return this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.BUTTON?.OTHER?.properties)
                }

                if (properties.isTypography && properties.isSize) {
                    const typo = this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.BUTTON?.DEFAULT?.TYPOGRAPHY)
                    const size = this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.BUTTON?.DEFAULT?.properties)
                    return typo + " " + size;
                }
                if (properties.isTypography) {
                    return this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.BUTTON?.DEFAULT?.TYPOGRAPHY)
                }

                if (properties.isDisabled) {
                    return this.getStyleFromObject(properties?.propertiesGeneral.GENERAL?.BUTTON?.DISABLED?.properties)
                }
            }
        }

        getPropertiesCard(properties: any) {
            if (properties.isPrimary) {
                if (properties.isTypography) {
                    return this.getStyleFromObject(properties?.propertiesCard.PRIMARY?.DEFAULT?.TYPOGRAPHY)
                }
                if (properties.isFocus) {
                    let result = this.getStyleFromObject(properties?.propertiesCard.PRIMARY?.FOCUS?.properties)
                    if (properties.noBorderTop) {
                        result = result + " " + this.getStyleFromObject(properties?.propertiesCard.PRIMARY?.OTHER?.properties)
                    }
                    return result
                }
                return this.getStyleFromObject(properties?.propertiesCard.PRIMARY?.DEFAULT?.properties)
            }
        }

        getPropertiesButton(properties: any) {

            //style de boutons type icons
            if (properties.isIcon) {
                if (properties.isHover) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.ICON?.HOVER?.properties)
                }
                else {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.ICON?.DEFAULT?.properties)
                }
            }
            if (properties.isPrimary) {
                if (properties.isDisabled) {
                    if (properties.isIconDisabled) {
                        return this.getStyleFromObject(properties?.propertiesBoutons?.PRIMARY?.DISABLED?.ICON)
                    }
                }
                if (properties.isOther) {
                    if (properties.isButtonIcon) {
                        if (properties.isHover) {
                            return this.getStyleFromObject(properties?.propertiesBoutons?.PRIMARY?.OTHER?.HOVER)
                        }
                        return this.getStyleFromObject(properties?.propertiesBoutons?.PRIMARY?.OTHER?.properties)
                    }
                }
                if (properties.isTypography) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.PRIMARY?.DEFAULT?.TYPOGRAPHY)
                }
                if (properties.isButtonIcon) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.PRIMARY?.DEFAULT?.ICON)
                }
                if (properties.isHover) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.BUTTON?.HOVER?.properties)
                }
                return this.getStyleFromObject(properties?.propertiesBoutons?.GENERAL?.BUTTON?.DEFAULT?.properties)
            }
            if (properties.isSecondary) {
                if (properties.isHover) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.SECONDARY?.HOVER?.properties)
                } if (properties.isTypo) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.SECONDARY?.DEFAULT?.TYPOGRAPHY)
                }
                if (properties.isButtonIcon) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.SECONDARY?.DEFAULT?.ICON)
                }
                return this.getStyleFromObject(properties?.propertiesBoutons?.SECONDARY?.DEFAULT?.properties)


            }
            if (properties.isTertiary) {
                if (properties.isHover) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.TERTIARY?.HOVER?.properties)
                }
                return this.getStyleFromObject(properties?.propertiesBoutons?.TERTIARY?.DEFAULT?.properties)
            }
            if (properties.isWarm) {
                if (properties.isHover) {
                    return this.getStyleFromObject(properties?.propertiesBoutons?.WARM?.HOVER?.properties)
                }
                return this.getStyleFromObject(properties?.propertiesBoutons?.WARM?.DEFAULT?.properties)
            }
            if (properties.isError) {
                return this.getStyleFromObject(properties?.propertiesBoutons?.ERROR?.DEFAULT?.properties)
            }
        }

        formatColor(color: IFormatColor | string, options: ICouleurThemeOptions = {}) {
            if (typeof color === 'string') {
                return color;
            } else if (options.contrast) {
                if (color.contrast.length === 3) {
                    return `rgb(${color.contrast.join(',')})`;
                } else {
                    return `rgba(${color.contrast.join(',')})`;
                }
            } else if (options.alpha) {
                return `rgba(${color.value.join(',')}, ${options.alpha})`;
            } else {
                return `rgb(${color.value.join(',')})`;
            }
        }

        getProperties(theme: string, type: number) {
            const obj = {}
            try {
                Object.keys(Themes).forEach((el: any) => {
                    if (Themes[el]?.BUTTONS?.NAME === theme) {
                        switch (type) {
                            //boutons
                            case 1: Object.assign(obj, { ...Themes[el].BUTTONS })
                                break;
                            //cards                                
                            case 2: Object.assign(obj, { ...Themes[el].CARD })
                                break;
                            //icons sans boutons
                            case 3: Object.assign(obj, { ...Themes[el].ICONS })
                                break;
                            //accordeons
                            case 4: Object.assign(obj, { ...Themes[el].ACCORDEON })
                                break;
                            //input
                            case 5: Object.assign(obj, { ...Themes[el].INPUT })
                                break;
                            //modals
                            case 6: Object.assign(obj, { ...Themes[el].MODALS })
                                break;
                            //general
                            case 7: Object.assign(obj, { ...Themes[el].GENERAL })
                                break;
                            //toolbar
                            case 8: Object.assign(obj, { ...Themes[el].TOOLBAR })
                                break;
                            //date picker
                            case 9: Object.assign(obj, { ...Themes[el].DATEPICKER })
                                break;
                            //grid
                            case 10: Object.assign(obj, { ...Themes[el].GRID })
                                break;
                            default:
                                break;
                        }
                    }
                });
            } catch (error) {
            }

            return obj
        }

        getStyleFromObject(properties: any) {
            let result = ""
            try {
                Object.values(properties).forEach((el) => {
                    result = result + el
                })
            } catch (error) {
            }
            return result;
        }

    }

    return new CouleurTheme();
}
