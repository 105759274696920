/**
 * Composant représentant un dialog de sélection multiple
 */
import { module } from 'angular';
import DialogChampSelectionMultipleController from './dialog-champ-selection-multiple.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-champ-selection-multiple', [
    'core.services.dialog'
]).factory('DialogChampSelectionMultiple', DialogChampSelectionMultipleFactory);

/* @ngInject */
function DialogChampSelectionMultipleFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogChampSelectionMultipleController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-champ-selection-multiple.html'),
        locals: {
            icon: 'history',
            lblTitre: "G_LBL_SELECTION_MULTIPLE",
            lblCancel: 'G_LBL_BTN_FERMER',
            col: '',
            source: '',
            srccod: '', 
            isLovIntervalle: false
        }
    });
}