import {module} from 'angular';
import {IClonable} from '../interfaces/clonable.interface';
import {IColonneTri, IColonneTriClass, IColonneTriOptions} from './colonne-tri.service';

export interface ITriClass {
    new(): ITri;
}

export interface ITri extends IClonable<ITri> {
    liste: Array<IColonneTri>;
    setTriColonne(nomColonne: string, typeTriColonne?: TypeTri): void;
    setTriUniqueColonne(nomColonne: string, typeTriColonne?: TypeTri): void;
    getColonneByNom(nomColonne: string): IColonneTri;
    toString(): string;
    updateTri(list: Array<IColonneTri|IColonneTriOptions>): void;
    clearTri(): void;
    get(): string;
}

export type TypeTri = '' | '/asc' | '/desc';

const TYPE_TRI = {
    INITIAL: '' as TypeTri,
    ASC: '/asc' as TypeTri,
    DESC: '/desc' as TypeTri
};

export default module('core.services.tri', []).factory('Tri', TriFactory);

/* @ngInject */
function TriFactory(ColonneTri: IColonneTriClass) {
    class Tri implements ITri {
        liste: Array<IColonneTri>;
        map: {[key: string]: IColonneTri};

        constructor() {
            this.liste = new Array<IColonneTri>();
            this.map = {};
        }

        private remove(index: number): void {
            const [triColonne] = this.liste.splice(index, 1);
            if (triColonne) {
                delete this.map[triColonne.nom];
            }
        }

        private findIndex(nomColonne: string): number {
            return this.liste.findIndex(tri => tri.nom === nomColonne);
        }

        private add(nomColonne: string, typeTriColonne?: TypeTri): void {
            if (!this.getColonneByNom(nomColonne)) {
                if (nomColonne === 'defaut' && typeTriColonne === TYPE_TRI.INITIAL) {
                    this.clearTri();
                } else if (!this.liste.length) {
                    const triColonne = new ColonneTri(nomColonne, typeTriColonne || TYPE_TRI.ASC);
                    this.liste = [triColonne];
                    this.map = {[triColonne.nom]: triColonne};
                } else {
                    const triColonne = new ColonneTri(nomColonne, typeTriColonne || TYPE_TRI.ASC);
                    this.liste.push(triColonne);
                    this.map[nomColonne] = triColonne;
                }
            }
        }

        updateTri(list: Array<IColonneTri|IColonneTriOptions>): void {
            this.map = {};
            this.liste = list.map(option => {
                const triColonne = new ColonneTri(option.nom, option.typeTri || TYPE_TRI.ASC);
                this.map[option.nom] = triColonne;
                return triColonne;
            });
        }

        clearTri(): void {
            this.liste = [];
            this.map = {};
        }

        clone(): ITri {
            const liste = this.liste.map(obj => obj.clone());
            const tri = new Tri();
            tri.liste = liste;

            liste.forEach((colonneTri: IColonneTri) => {
                tri.map[colonneTri.nom] = colonneTri;
            });

            return tri;
        }

        getColonneByNom(nomColonne: string): IColonneTri {
            return this.map[nomColonne];
        }

        setTriColonne(nomColonne: string, typeTriColonne?: TypeTri): void {
            const colonne = this.getColonneByNom(nomColonne);
            if (colonne) {
                colonne.switchTri(typeTriColonne);
                if (colonne.typeTri === TYPE_TRI.INITIAL) {
                    this.remove(this.findIndex(nomColonne));
                }
            } else {
                this.add(nomColonne, typeTriColonne);
            }
        }

        setTriUniqueColonne(nomColonne: string, typeTriColonne?: TypeTri): void {
            const colTri = new ColonneTri(nomColonne, typeTriColonne || TYPE_TRI.ASC);
            this.liste = [colTri];
            this.map = {[nomColonne]: colTri};
        }

        toString(): string {
            return this.liste.map(function (elem) {
                return elem.nom + elem.typeTri;
            }).join(',');
        }

        get(): string {
            return (this.liste.length) ?
                this.liste[0].nom :
                null;
        }

    }
    return Tri;
}
