import {ILocationService, module} from 'angular';
import {IStateService} from 'angular-ui-router';
import {IRouter} from '../providers/router.provider';
import {IRoute} from '../interfaces/route.interface';
import IUrlRouterProvider = angular.ui.IUrlRouterProvider;
import IInjectorService = angular.auto.IInjectorService;

export default module('app.configs.ecran', []).config(EcranConfig);

/* @ngInject */
function EcranConfig($urlRouterProvider: IUrlRouterProvider, $injector: IInjectorService, accueilRoute: IRoute, appName: string) {

    // On redirige s'il n'y a pas de numéro d'écran, ou le slash de fin
    /* @ngInject */
    $urlRouterProvider.when(new RegExp('^\/menu\/([0-9]+-[a-z0-9,]+)\/([a-z0-9]+)(-([0-9-]*))?(\/(.*))?$', 'i'), ($state: IStateService, $stateParams: any, $location: ILocationService, Router: IRouter) => {
        const path = $location.path();
        const splittedPath = path.split('/')
        splittedPath.shift()
        splittedPath.shift()
        let [menuId, ecrcod, id] = splittedPath;
        let ecran = (ecrcod || '').split('-').slice(1).join('-');

        if (ecran.length && !ecran.endsWith('-2')) {
            ecrcod = `${ecrcod}-2`;
            ecran = `${ecran}-2`;
        }

        const route: IRoute = Router.getRouteByCode(ecrcod);

        if (route) {
            // Si le state params contient déjà la route, c'est que l'écran est déjà résolu
            // Si le no. d'écran n'est pas valide, on redirige vers le défaut
            if (!route.ECRANS.includes(ecran)) {
                ecran = route.ECRANS[0];
            }

            if (!route.PARAMS.includes('id')) {
                id = null;
            }

            const params = Object.assign({
                menuId,
                id,
                ecran,
                error: $stateParams.error,
                pracleint: $stateParams.pracleint,
                menucleref: $stateParams.menucleref,
                ecrcleint: $stateParams.ecrcleint,
                isProfilEmploye: $stateParams.isProfilEmploye,
                verifierChangements: $stateParams.verifierChangements
            }, $location.search());

            $state.transitionTo(route.NAME, params, {
                location: 'replace'
            });
        } else {
            introuvable($state);
        }
    });

    // Quand il n'y a pas de menu, on redirige vers le premier écran que l'on trouve sur un de nos menu. Sinon, c'est une erreur d'accès à l'écran
    /* @ngInject */
    $urlRouterProvider.when(new RegExp(`^/([a-z0-9]+)(-([0-9-]*))?(\/(.*))?$`, 'i'), ($state: IStateService,$location: ILocationService, Router: IRouter) => {
        const path = $location.path().toLowerCase();
        const splittedPath = path.split('/')
        splittedPath.shift()
        let [ecrcod, id] = splittedPath;
        let ecran = (ecrcod || '').split('-').slice(1).join('-');

        if (ecran.length && !ecran.endsWith('-2')) {
            ecrcod = `${ecrcod}-2`;
            ecran = `${ecran}-2`;
        }

        const route: IRoute = Router.getRouteByCode(ecrcod);

        if (route) {
            // Si le no. d'écran n'est pas valide, on redirige vers le défaut
            if (!route.ECRANS.includes(ecran)) {
                ecran = route.ECRANS[0];
            }

            if (!route.PARAMS.includes('id')) {
                id = null;
            }

            const params = Object.assign({
                id,
                ecran
            }, $location.search());

            $state.transitionTo(route.NAME, params, {
                location: 'replace'
            });
        } else {
            introuvable($state);
        }
    });

    /* @ngInject */
    $urlRouterProvider.when(/.*/i, ($state: IStateService) => {
        introuvable($state);
    });

    function introuvable($state: IStateService) {
        if (appName === 'portail-employe') {
            console.warn('Page introuvable : ' + location.href);
            $state.transitionTo(accueilRoute.NAME);
        } else {
            $state.transitionTo(($injector.get('introuvableRoute') as IRoute).NAME);
        }
    }
}
