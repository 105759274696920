import {module} from 'angular';
import {IMenuItem, IMenuItemClass} from './menu-item.service';
import {IMenuItemActionOptions} from './menu-item-action.service';
import {IMonoOccurrence} from '../mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceClass} from '../multi-occurrence.service';
import {IApiError} from '../../interfaces/api-error.interface';
import {INotificationHandler} from '../utils/notification-handler.service';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import {IDownloadManagerService} from '../download-manager.service';

export interface IMenuItemActionTelechargementClass {
    new(lblTitre: string, fonction: string, parametresGetter?: IMenuItemActionTelechargementParametres, options?: IMenuItemActionTelechargementOptions): IMenuItemActionTelechargement;
}

export interface IMenuItemActionTelechargement extends IMenuItem {
}

interface IMenuItemActionTelechargementParametres {
    (data: object): object;
}

export interface IMenuItemActionTelechargementOptions extends IMenuItemActionOptions {

}

export default module('core.services.menu-item-action-telechargement', []).factory('MenuItemActionTelechargement', MenuItemActionTelechargementFactory);

/* @ngInject */
function MenuItemActionTelechargementFactory(downloadManager: IDownloadManagerService,
                                             MultiOccurrence: IMultiOccurrenceClass,
                                             notificationHandler: INotificationHandler,
                                             exLibelleFilter: IFilterLibelle,
                                             MenuItem: IMenuItemClass) {

    class MenuItemActionTelechargement extends MenuItem implements IMenuItemActionTelechargement {
        constructor(lblTitre: string, fonction: string, parametresGetter: IMenuItemActionTelechargementParametres = () => ({}), options: IMenuItemActionTelechargementOptions = {}) {
            // On détermine si un lblTitre a été passé.
            if (typeof arguments[1] !== 'string') {
                lblTitre = '';
                fonction = arguments[0];
                parametresGetter = arguments[1];
                options = arguments[2];
            }
            super(lblTitre,
                (event: MouseEvent, data: any, occurrence: IMonoOccurrence | IMultiOccurrence) => executeAction(event, data, parametresGetter, fonction, options, occurrence),
                Object.assign({
                    fonction,
                    lblTitreValidation: 'G_LBL_MOD_ERREUR_TITRE'
                }, options));
        }
    }
    return MenuItemActionTelechargement;

    function executeAction(event: MouseEvent,
                           data: any,
                           parametresGetter: IMenuItemActionTelechargementParametres,
                           fonction: string,
                           options: IMenuItemActionTelechargementOptions,
                           occurrence: IMonoOccurrence | IMultiOccurrence) {
        const params: any = parametresGetter(data);
        const ids = occurrence.getParentIds();
        const id = params.id || (data && data[occurrence.cleint]);

        ids.pop();

        if (id) {
            ids.push(id)
        }

        if (occurrence instanceof MultiOccurrence && occurrence.criteresSuggeres) {
            data = Object.assign({}, (<IMultiOccurrence>occurrence).etatSelected.criteresSuggeresData, data);
        }

        params.fonction = fonction;

        const srccod = occurrence.getNomSourceDetails();

        return downloadManager.download(`/bouton-action/blob/${srccod}/${params.fonction}/${ids.join(',')}`, {...data, ...params}, {...occurrence.stateParams, ecrcleint: occurrence.ecranDetails.ecrcleint})
            .then((result: any) => {
                const defaultLblMessage = (options.lblMessageSuccess) ? options.lblMessageSuccess : 'G_MSG_ACTION_SUCCES';
                const msgSucces = (result && result.msg) || exLibelleFilter(defaultLblMessage);
                notificationHandler.succes(msgSucces);

                if (options.successAction) {
                    options.successAction(result);
                }

                return result;
            })
            .catch((err: IApiError) => {
                afficherMessageErreur(err, event);
            });
    }

    function afficherMessageErreur(error: IApiError, event: MouseEvent) {
        notificationHandler.erreur({
            targetEvent: event,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: exLibelleFilter('G_MSG_ERREUR_ACTION'),
            error
        });
    }
}
