import IDialogService = angular.material.IDialogService;
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IApiError } from '../../interfaces/api-error.interface';
import { IAugmentedJQuery, IComponentController, IFormController, IPromise, IScope, IRootScopeService, ITimeoutService } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IChangementManager } from '../../services/changement-manager.service';
import { IProfil } from '../../resources/profil.resource';

export interface IComposantDialog extends IComponentController {
    icon: string;
    lblTitre: string;
    lblConfirm: any;
    lblCancel: any;
    warm: any;
    validationError: any;
    error: IApiError;
    busy: boolean;
    closeOnError: boolean;
    formDialog: IFormController;
    saveAndNextReturn: any;
    showConfirmAction: boolean;
    hideCancelAction: boolean;
    confirmDisabled: boolean;
    submitOnEnter: boolean;
    reloadData: boolean;
    appliquer(): void;
    cancel(): void;
    confirmAction(): any;
    isDisabled(): boolean;
    onKeydown(event: KeyboardEvent): void;
    confirmerQuitterPage(): IPromise<any>;
}

const lblConfirm = 'G_LBL_BTN_CONFIRMER';
const lblCancel = 'G_LBL_BTN_ANNULER';

/* @ngInject */
export default function DialogController($mdDialog: IDialogService,
    DialogStatus: IDialogAllStatus,
    defaults: IDefaultsService,
    $scope: IScope,
    $document: IAugmentedJQuery,
    $mdUtil: any,
    changementManager: IChangementManager,
    $rootScope: IRootScopeService,
    profil: IProfil,
    $timeout: ITimeoutService) {
    const vm: IComposantDialog = this;

    vm.$onInit = $onInit;
    vm.cancel = cancel;
    vm.appliquer = appliquer;
    vm.isDisabled = isDisabled;
    vm.onKeydown = onKeydown;

    function $onInit() {
        try {
            $timeout(() => {
                const objPreferences: any = profil.preferences;
                if (objPreferences.vaesysapp == "EMP2") {
                    if (vm.error) {
                        vm.icon = "dangerous"
                        vm.lblTitre = "G_MSG_ERREUR_VALIDATION"
                        vm.lblCancel = "G_LBL_BTN_FERMER"
                        vm.validationError = true

                    }
                    if (vm.lblTitre == "G_LBL_TITRE_MODIFICATION_EN_COURS") {
                        vm.warm = true
                    }
                    if (vm.lblTitre == "G_LBL_BTN_SUPP_ENR") {
                        vm.lblConfirm = "G_LBL_BTN_SUPPRIMER"
                        vm.warm = true
                        vm.lblTitre = "G_LBL_BTN_SUPPRIMER_ELEMENT"
                        vm.icon = "warning"
                    }
                    if (vm.lblCancel == "G_LBL_BTN_OK") {
                        vm.lblCancel = "G_LBL_BTN_FERMER"
                    }
                }
            });
        } catch (error) {
        }
        defaults(vm, {
            lblConfirm: lblConfirm,
            lblCancel: lblCancel,
            showConfirmAction: Boolean(vm.confirmAction),
            //hideCancelAction: false,
            confirmDisabled: false,
            hideUsingCallbackOnly: false
        });

        /**
         * Ferme le dialog lorsque l'usager change de page.
         */
        const stateChangeListener = changementManager.watchStateChange(() => cancel());

        $scope.$on('$destroy', () => {
            stateChangeListener();
            $document.off('keydown', $mdUtil.debounce(onKeydown, 250));
        });

        $document.on('keydown', $mdUtil.debounce(onKeydown, 250));
    }

    function onKeydown(event: KeyboardEvent) {
        // Prévient de fermer plusieurs niveaux de dialogue d'un seul coup
        if (event.defaultPrevented) return
        event.preventDefault()

        if (vm.submitOnEnter && event.key === "Enter") {
            appliquer();
        } else if (event.key === "Escape") {
            cancel()
        }
    }

    function cancel() {
        if (vm.busy) return

        if (vm.confirmerQuitterPage) {
            vm.confirmerQuitterPage().then(() => $mdDialog.cancel(DialogStatus.FERMER)).catch(() => {
                // Fermeture annulée, on ignore
            });
        } else {
            //si reloadOnError =1 dans l'ecran on execute le fetdata
            if (vm && vm.error && vm.error.reloadOnError) {
                $rootScope.$broadcast('exMenuItemMonoOccurrence.fetchData');
            }
            $mdDialog.cancel(DialogStatus.FERMER);
            if (vm?.reloadData) {
                $rootScope.$broadcast('exMenuItemMonoOccurrence.fetchData')
            }
        }
    }

    function appliquer(): void {
        vm.formDialog.$setSubmitted();
        vm.formDialog.$commitViewValue();
        // Si le formulaire est invalide, on arrête ici
        if (isDisabled()) return

        const actionResult = vm.confirmAction();

        if (actionResult && actionResult.then) {
            vm.busy = true;
            actionResult.then((result: any) => {
                $mdDialog.hide(result);
                $rootScope.$broadcast('vm.multiOccurrence.etat.reinitialiser');
                //déplacer le grid vers la gauche s'il existe
                $rootScope.$broadcast('exGridReajusterLargeurColonneToLeft');
                updateCalendrier(result);
            }).catch((error: IApiError) => {
                if (vm.closeOnError) {
                    return $mdDialog.cancel(error);
                }
            }).finally(() => vm.busy = false);
        } else {
            $mdDialog.hide(actionResult);
        }
    }

    function isDisabled() {
        return !vm.formDialog.$valid || vm.confirmDisabled || vm.busy
    }
    function updateCalendrier(result: any) {
        $timeout(() => {
            if (result?.colonne == "date" && result?.valeurs && Array.isArray(result.valeurs) && result.valeurs.length > 0) {
                $rootScope.$broadcast('ex-calendrier.setDate', { newDate: result.valeurs[0], oldDate: undefined });
                $rootScope.$broadcast('ex-criteres-suggeres-update-calendrier', { date: result.valeurs[0] });
            }
            else {
                $rootScope.$broadcast('ex-calendrier.setDate', { newDate: undefined, oldDate: undefined });
            }
        }, 500);
    }
}
