import * as moment from 'moment';
import { IComponentController, IFormController, IScope, ITimeoutService } from 'angular';

export interface IComposantInputDateHour extends IComponentController {
    nameElement: string;
    formCtrl: IFormController;
    valueDate: string;
    valueHour: string;
    value: string;
    titre: string;
    readonly: boolean;
    nameElementHour: string;
    nameElementDate: string;
    onDateChange(value: string): void;
    onHourChange(value: string): void;
    isValid(): boolean;
}

/* @ngInject */
export default function InputDateHourController($timeout: ITimeoutService,
    $scope: IScope) {
    const vm: IComposantInputDateHour = this;
    const HOUR_FORMAT = 'HH:mm:ss';
    const DATE_FORMAT = 'YYYY-MM-DD';
    const DATE_HOUR_FORMAT = DATE_FORMAT + 'T' + HOUR_FORMAT;

    vm.$onInit = $onInit;
    vm.onDateChange = onDateChange;
    vm.onHourChange = onHourChange;
    vm.isValid = isValid;

    function $onInit() {
        vm.nameElementHour = `${vm.nameElement}_hour`;
        vm.nameElementDate = `${vm.nameElement}_date`;

        $scope.$watch('vm.value', (value: string) => {
            if (value) {
                const m = moment(value, DATE_HOUR_FORMAT);
                vm.valueDate = m.format('YYYY-MM-DD');
                vm.valueHour = m.format('HH:mm:ss');
            } else if (vm.formCtrl[vm.nameElement] && !vm.formCtrl[vm.nameElement].$error.validateApi) {
                vm.valueDate = null;
                vm.valueHour = null;
            }
        });

        // On attend que le formulaire soit accessible
        $scope.$watch('::vm.formCtrl[vm.nameElement]', () => {
            if (vm.formCtrl[vm.nameElement]) {
                // On utilise un Watch ici, car le input du composant md-datepicker n'est pas lié au model.
                // Il est donc impossible d'utiliser un parsers ou autre afin d'avoir un onChange qui fonctionne
                $scope.$watch(() => {
                    return (
                        (vm.formCtrl[vm.nameElementHour] && vm.formCtrl[vm.nameElementHour].$invalid) ||
                        (vm.formCtrl[vm.nameElementDate] && vm.formCtrl[vm.nameElementDate].$invalid)
                    );
                }, (isInvalid: boolean) => {
                    if (isInvalid) {
                        vm.formCtrl[vm.nameElement].$setValidity('validateApi', true);
                    }
                });

                // Si un des champs est fourni, l'autre le devient
                vm.formCtrl[vm.nameElementDate].$validators.requiredPartiel = function (value: any) {
                    return Boolean(value || !vm.valueHour);
                };
                vm.formCtrl[vm.nameElementHour].$validators.requiredPartiel = function (value: any) {
                    return Boolean(value || !vm.valueDate);
                };
            }
        });
    }

    function onDateChange(value: string): void {
        if (value && vm.valueHour && isValid()) {
            vm.formCtrl[vm.nameElement].$setViewValue(value + 'T' + vm.valueHour);
            vm.formCtrl[vm.nameElement].$commitViewValue();
        } else if (!value && !vm.valueHour) {
            vm.formCtrl[vm.nameElement].$setViewValue(null);
            vm.formCtrl[vm.nameElement].$commitViewValue();
        }

        // Il y a une validation qui lie les champs
        $timeout(() => {
            vm.formCtrl[vm.nameElementHour].$validate();
            //S'il est invalide on doit supprimer la classe ng-pristine et mettre le contrôle à son état dirty
            if (vm.formCtrl[vm.nameElementHour].$invalid) {
                vm.formCtrl[vm.nameElementHour].$setTouched();
                vm.formCtrl[vm.nameElementHour].$setDirty();
            }
        });
    }

    function onHourChange(value: string): void {
        if (value && vm.valueDate && isValid()) {
            vm.formCtrl[vm.nameElement].$setViewValue(vm.valueDate + 'T' + value);
            vm.formCtrl[vm.nameElement].$commitViewValue();
        } else if (!value && !vm.valueDate) {
            vm.formCtrl[vm.nameElement].$setViewValue(null);
            vm.formCtrl[vm.nameElement].$commitViewValue();
        }

        // Il y a une validation qui lie les champs
        $timeout(() => {
            vm.formCtrl[vm.nameElementDate].$validate();
            //S'il est invalide on doit supprimer la classe ng-pristine et mettre le contrôle à son état dirty
            if (vm.formCtrl[vm.nameElementDate].$invalid) {
                vm.formCtrl[vm.nameElementDate].$setTouched();
                vm.formCtrl[vm.nameElementDate].$setDirty();
            }
        });
    }

    function isValid(): boolean {
        return vm.formCtrl[vm.nameElementHour].$valid && vm.formCtrl[vm.nameElementDate].$valid;
    }
}
