/**
 * Composant représentant une card contenant le cycle .
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CardCycleController from './ex-card-cycle.controller';

export default module('app.components.ex-card-cycle', []).component('exCardCycle', CardCycleFactory());

function CardCycleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE,
            {
                messagesCycle: '<',
                formData: '<'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: CardCycleController,
        controllerAs: 'vm',
        template: require('./ex-card-cycle.html')
    };
}
