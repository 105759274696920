import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IPromise,
    ISCEService,
    IScope
} from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IFormulaireClass } from '../../services/formulaire/formulaire.service';
import { IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IMenuClass } from '../../services/menu/menu.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IDownloadManagerService } from '../../services/download-manager.service';
import { IUploadManagerService } from '../../services/upload-manager.service';
import { IApiError } from '../../interfaces/api-error.interface';
import { IMenuItemClass } from '../../services/menu/menu-item.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import IResourceService = angular.resource.IResourceService;
import { IEcranStateParamsService } from '../../services/utils/ecran-state-params.service';
import isMobile from '../../constants/mobile.constant';

interface IPiecesJointesRowDetails {
    [col: string]: any;
}

export interface IComposantPiecesJointes extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    srccod: string,
    cleintref: number,
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    formCtrl: IFormController;
    newFormData: IPiecesJointesRowDetails;
    newPieceJointe: File | Array<File>;
    newFormCtrl: IFormController;
    newMonoOccurrence: IMonoOccurrence
    newOpened: boolean;
    ajoutRapide: boolean;
    cacherRecherche: number;
    consultationSeule: boolean;
    resourceParams: any;
    scrollToTop: boolean;
    isEcranFinance: boolean;
    colonnePieceJointe: any;
    ecranPieceJointe: string;
    erreurInit: boolean;
    initialized: boolean;
    initializing: boolean;
    saisieDescriptionCreation: boolean;
    isMobile: boolean;
    collapsed: boolean
    opened: boolean
    newMonoOccurrenceOptions: IMonoOccurrenceOptions
    preview: { data?: any, url?: any, ext?: string }
    init(colonnesVisible: Array<string>, mnemonique: string): void;
    closeNew(): void;
    saveNewAction(): IPromise<any>;
    saveNewAndNextAction(): IPromise<any>;
    changePreview(direction: number): void;
}

/* @ngInject */
export default function PiecesJointesController(defaults: IDefaultsService,
    exFocus: IFocusService, $sce: ISCEService,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    $element: IAugmentedJQuery,
    notificationHandler: INotificationHandler,
    $scope: IScope,
    ApiConfig: IApiConfig,
    downloadManager: IDownloadManagerService,
    uploadManager: IUploadManagerService,
    $resource: IResourceService,
    parametresSecurite: IParametresSecuriteService,
    ecranStateParams: IEcranStateParamsService) {

    const vm: IComposantPiecesJointes = this;

    vm.$onInit = $onInit;
    vm.init = init;
    vm.closeNew = closeNew;
    vm.saveNewAction = saveNewAction;
    vm.saveNewAndNextAction = saveNewAndNextAction;

    function $onInit() {
        vm.isMobile = isMobile;
        vm.opened = !vm.collapsed
        vm.collapsed = vm.collapsed || false
        vm.preview = {}

        const mnemonique = vm?.monoOccurrenceEcranCtrl?.mnemonique ? vm.monoOccurrenceEcranCtrl.mnemonique :
            (vm.monoOccurrence ? vm.monoOccurrence.mnemonique : '');
        vm.isEcranFinance = isEcranFinanceOuNoteFinance(`${mnemonique}.BOUPIJ_FIN`);
        vm.colonnePieceJointe = {
            dsc: vm.isEcranFinance ? 'pijdsc' : 'pjbdsc',
            ext: vm.isEcranFinance ? 'pijnomphy' : 'pjbext'
        };

        vm.ecranPieceJointe = vm.isEcranFinance ? 'gs0018' : 'gs0044';
        vm.newOpened = false
        vm.newMonoOccurrenceOptions = { autoFetch: false }

        defaults(vm, {
            saisieDescriptionCreation: true,
            cacherRecherche: false,
            scrollToTop: true,
            ajoutRapide: false
        });

        if (vm.scrollToTop) {
            $element.closest('md-content.ex-mono-occurrence-ecran-content').animate({
                scrollTop: $element.prop('offsetTop') - 8
            }, 200);
        }

        const colonnesVisibles = [
            vm.colonnePieceJointe.dsc,
            'usrcodcre',
            'usrnomcre',
            'datcre'
        ];

        if (vm.isEcranFinance) {
            colonnesVisibles.push('pijflgcou');
        }

        if (!vm.ajoutRapide) {
            colonnesVisibles.push('tpicoddsc');
        }

        vm.cleintref = vm.cleintref || vm.ecranContextCtrl.stateParams.id || vm.monoOccurrence.data[vm.monoOccurrence.cycleCleint] || vm.monoOccurrence.data[vm.monoOccurrence.cleint]
        vm.init(colonnesVisibles, mnemonique);

        $scope.$watch('::vm.multiOccurrence', () => {
            if (vm.multiOccurrence) {
                if (vm.multiOccurrence.initialized) {
                    addPieceJointeMenuItem();
                } else {
                    vm.multiOccurrence.once('ready', () => {
                        addPieceJointeMenuItem();
                    })
                }
            }
        });

        if (!vm.saisieDescriptionCreation && !vm.monoOccurrence.id && vm.ajoutRapide) {
            vm.monoOccurrence.successActionSauvegarde = async () => {
                if (vm.newPieceJointe) {
                    setTimeout(() => saveNewAction(undefined, false))
                }
            }
        }
    }

    function init(colonnesVisibles: Array<string>, mnemonique: string): void {
        vm.initializing = true;
        getParametrePijflgcou()
            .then((parametreRapport) => {
                vm.erreurInit = false;
                vm.initialized = true;
                vm.multiOccurrenceOptions = {
                    srccod: vm.ecranPieceJointe,
                    nomSourceDetails: vm.ecranPieceJointe,
                    parentId: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
                    colonnesVisibles,
                    colonnesCachees: vm.ajoutRapide ? [vm.colonnePieceJointe.ext] : [
                        'tpicleint',
                        'tpiflgmod',
                        'tpiflgsup',
                        vm.colonnePieceJointe.ext
                    ],
                    autoFetch: Boolean(vm.monoOccurrence.id || vm.monoOccurrence.data[vm.monoOccurrence.cleint]),
                    resourceParams: Object.assign({ ...(vm.resourceParams || {}) }, {
                        ecrcleint: vm.ecranContextCtrl.ecranDetails.ecrcleint,
                        srccod: getSrccod(),
                        cleintref: vm.cleintref,
                        [vm.monoOccurrence.cleint]: vm.monoOccurrence.data[vm.monoOccurrence.cleint]
                    }),
                    fonctions: {
                        nouveau: vm.ajoutRapide ? false : !vm.consultationSeule,
                        edition: (data: any) => vm.ajoutRapide ? false : (!vm.consultationSeule && (!data.tpicleint || data.tpiflgmod)),
                        supprime: (data: any) => !vm.consultationSeule && (!data.tpicleint || data.tpiflgsup),
                        recherche: !vm.cacherRecherche,
                        rechercheParColonne: !vm.cacherRecherche,
                        filtrer: !vm.cacherRecherche,
                        enregistrerUnEtat: false,
                        selectionnerUnEtat: !vm.cacherRecherche,
                        criteresSuggeresVisibles: false,
                        exportation: false,
                        pagination: !vm.ajoutRapide,
                        importation: false,
                        selectionnerDesColonnes: false,
                        selectionnerTri: false,
                        reinitialiser: false,
                        saveEtatTemporaire: false,
                        afficherMenuSettingDansEntete: true,
                        boutonDupliquer: false
                    },
                    pagination: {
                        nombreElementPage: vm.ajoutRapide ? 999999 : 10
                    },
                    formulaire: new Formulaire([
                        new FormulaireItem(vm.colonnePieceJointe.dsc, {
                            required: (data: any) => {
                                // S'assure d'être en création ou d'avoir une pièce jointe déjà créée (dans le cas de l'édition)
                                return (vm.saisieDescriptionCreation && (vm.newOpened || data.pijnomphy));
                            },
                            hidden: (data: any) => !vm.saisieDescriptionCreation && !data.$id,
                            largeur: 50
                        }),
                        new FormulaireItem('tpicleint', {
                            hidden: vm.ajoutRapide,
                            largeur: 25
                        }),
                        new FormulaireItem('pijflgcou', {
                            hidden: !vm.isEcranFinance,
                            disabled: () => (!['CR5006', 'CR5032'].includes(parametreRapport) || mnemonique !== 'FAC'),
                            largeur: 25
                        })
                    ]),
                    actionsNouveaux: new Menu([
                        new MenuItem('G_LBL_BTN_NOUVEAU', openNew, { disabled: () => vm.newOpened })
                    ]),
                    onDelete: () => {
                        vm.compteur--
                    }
                };
            })
            .catch(() => {
                vm.erreurInit = true;
            })
            .finally(() => {
                vm.initializing = false;
            });
    }

    function isEcranFinanceOuNoteFinance(nomphy: string): boolean {
        return (vm.ecranContextCtrl.ecranDetails.fonctions[nomphy] && vm.ecranContextCtrl.ecranDetails.fonctions[nomphy].flgacc === 1) || vm.monoOccurrence.mnemonique === 'NOF';
    }

    function addPieceJointeMenuItem(): void {
        vm.multiOccurrence.actionsRangeeDroite.add(new MenuItem(
            'G_LBL_VISUALISER_PJ',
            (event: MouseEvent, rowDetails: IPiecesJointesRowDetails) => {
                return downloadPieceJointe(rowDetails)
                    .catch((error: IApiError) => {
                        afficherDownloadErreur(error, rowDetails);
                    });
            }, {
            icon: 'attach_file',
            iconButton: true,
            directionTooltip: 'bottom'
        }));

        vm.multiOccurrence.actionsRangeeDroite.add(new MenuItem(
            'G_LBL_VISUALISER',
            (event: Event, data: any) => {
                if (!vm.preview.url || vm.preview.data === data) {
                    setPreview(data)
                } else {
                    vm.preview = {}
                }
            }, {
            icon: 'search',
            iconButton: true,
            directionTooltip: 'bottom',
            hidden: (data: any) => !["pdf", "jpeg", "jpg", "gif", "png"].includes((vm.isEcranFinance ? data[vm.colonnePieceJointe.ext].substring(data[vm.colonnePieceJointe.ext].lastIndexOf(".") + 1) : data[vm.colonnePieceJointe.ext]).toLowerCase())
        }));
    }

    vm.changePreview = function changePreview(direction: number) {
        let newIndex = vm.preview.data.rn + direction
        if (newIndex === 0) {
            newIndex = vm.multiOccurrence.dataList.length
        } else if (newIndex > vm.multiOccurrence.dataList.length) {
            newIndex = 1
        }
        setPreview(vm.multiOccurrence.dataList[newIndex - 1])
    }

    function setPreview(data: any) {
        vm.multiOccurrence.activeRowCleint = data.pjbcleint
        vm.preview.data = data
        vm.preview.ext = (vm.isEcranFinance ? data[vm.colonnePieceJointe.ext].substring(data[vm.colonnePieceJointe.ext].lastIndexOf(".") + 1) : data[vm.colonnePieceJointe.ext]).toLowerCase()
        vm.preview.url = $sce.trustAsResourceUrl(`${ApiConfig.ROOT}${vm.isEcranFinance ? `/gs0018/edition/${data.pijcleint}/pijdat` : `/gs0044/edition/${data.pjbcleint}/pjbdat`}?${$.param({ ...getPieceJointeParams(), ...parametresSecurite(vm.ecranContextCtrl.stateParams) })}&screen=true&a#toolbar=0&navpanes=0`)
    }

    function getSrccod() {
        if (vm.srccod) {
            return vm.srccod;
        } else {
            const srccod = (vm.monoOccurrence.bloc) ? `${vm.monoOccurrence.srccod}-${vm.monoOccurrence.bloc}` : vm.monoOccurrence.srccod;

            return vm.monoOccurrence.isEdition ?
                `${srccod}-edition` :
                srccod;
        }
    }

    function downloadPieceJointe(rowDetails: IPiecesJointesRowDetails) {
        const params = getPieceJointeParams();
        const target = vm.isEcranFinance ? `/gs0018/edition/${rowDetails.pijcleint}/pijdat` : `/gs0044/edition/${rowDetails.pjbcleint}/pjbdat`;

        return downloadManager.download(target, params, vm.ecranContextCtrl.stateParams);
    }

    function afficherDownloadErreur(error: IApiError, rowDetails: IPiecesJointesRowDetails) {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_PIECES_JOINTES',
            lblMessage: 'G_MSG_CHARG_DONNEES',
            confirmAction() {
                return downloadPieceJointe(rowDetails);
            }
        });
    }

    function openNew(): void {
        vm.newPieceJointe = null;

        vm.newOpened = true;
    }

    function closeNew(): void {
        vm.newOpened = false;
    }

    function afficherErreurSauvegarde(error: IApiError): void {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_PIECES_JOINTES',
            lblMessage: error.data && error.data.message || 'G_MSG_SAUV_ENR'
        });
    }

    function saveRow(rowData: IPiecesJointesRowDetails, piecesJointes: File | Array<File>, fetchDataList: boolean = true): IPromise<any> {
        rowData.$saving = true;

        const params = getPieceJointeParams();
        const uploadPromises: IPromise<any>[] = [];

        if (piecesJointes instanceof Array) {
            for (const piece of piecesJointes) {
                uploadPromises.push(uploadPieceJointe({
                    ...rowData,
                    piece,
                    [vm.colonnePieceJointe.dsc]: (rowData[vm.colonnePieceJointe.dsc] ? rowData[vm.colonnePieceJointe.dsc] + ' - ' : '') + piece.name.substr(0, piece.name.lastIndexOf('.'))
                }, params));
            }
            piecesJointes.splice(0);
        } else {
            const data = Object.assign({ piecesJointes }, rowData);
            uploadPromises.push(uploadPieceJointe(data, params));
        }

        return Promise.all(uploadPromises)
            .then(() => {
                if (fetchDataList) {
                    notificationHandler.succes();

                    // La liste utilise un service différent, alors il faut toujours rafraichir meme lors des updates
                    vm.multiOccurrence.fetchDataList();
                }
            })
            .finally(() => {
                rowData.$saving = false;
            });
    }

    function uploadPieceJointe(data: any, params: any) {
        return uploadManager.upload(`/${vm.ecranPieceJointe}/edition`, data, params, vm.ecranContextCtrl.stateParams);
    }

    function getPieceJointeParams() {
        return {
            cleintref: vm.cleintref,
            ...vm.resourceParams,
            ...ecranStateParams(vm.ecranContextCtrl.stateParams),
            srccod: getSrccod()
        };
    }

    function saveNewAction(newPiecesJointes?: Array<File>, fetchDataList?: boolean): IPromise<any> {
        if (vm.newFormCtrl.$valid) {
            return saveRow(vm.newFormData, newPiecesJointes || vm.newPieceJointe, fetchDataList).then(() => {
                vm.closeNew();
                vm.compteur++
            }).catch(afficherErreurSauvegarde);
        }
    }

    function saveNewAndNextAction(): IPromise<any> {
        if (vm.newFormCtrl.$valid) {
            return saveRow(vm.newFormData, vm.newPieceJointe)
                .then(() => {
                    vm.newPieceJointe = null;
                    vm.newMonoOccurrence.resetData()
                    vm.newFormCtrl.$setPristine();
                    vm.newFormCtrl.$setUntouched();
                    exFocus($element.find('.ex-pieces-jointes-form-ajout'));
                }).catch(afficherErreurSauvegarde);
        }
    }

    function getParametrePijflgcou(): IPromise<string> {
        const srccod = vm.ecranContextCtrl.stateParams.srccod;
        const requete = $resource(`${ApiConfig.ROOT}/gs0018/parametre-rapport`, {
            srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails)
        });

        return requete.get().$promise
            .then((result: any) => {
                return result.parametreRapport;
            });
    }
}
