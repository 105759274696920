import {IFilterDate, module} from 'angular';

export default module('core.filters.ex-heure', []).filter('exHeure', heureFilter);

export interface IFilterExHeure {
    (value: string, minutes: boolean, secondes: boolean): any;
}

/* @ngInject */
function heureFilter(dateFilter: IFilterDate): IFilterExHeure {
    const format = 'HH';
    const formatMinutes = 'HH:mm';
    const formatSecondes = 'HH:mm:ss';

    return (value: string, minutes: boolean, secondes: boolean): any => {
        if (minutes === true && secondes === false) {
            return dateFilter(value, formatMinutes);
        } else if (minutes === true && secondes === true) {
            return dateFilter(value, formatSecondes)
        } else {
            return dateFilter(value, format)
        }
    }
}
