import * as angular from 'angular';
import {ICompileService, IRootScopeService, module} from 'angular';
import {IInjectStyleService} from './inject-style.service';
import {IDocumentService} from "angular";

/**
 * Ce service permet de contrôler l'activation ou la désactivation d'une section de l'application
 */
export default module('core.services.activity', []).factory('Activity', ActivityFactory);

export interface IActivityService {
    setActive(element: JQuery): void;
    setInactive(element: JQuery): void;
}

/* @ngInject */
function ActivityFactory($compile: ICompileService,
                         $document: IDocumentService,
                         injectStyle: IInjectStyleService,
                         $rootScope: IRootScopeService) {
    injectStyle(`
        .ex-activity-container {
            position: absolute;
            z-index: 1;
            background-color: rgba(33, 33, 33, 0.48);
            opacity: 0;
            outline: none;
            transition: opacity ease-in-out .25s .25s;
        }
        
        .ex-activity-container--in {
            opacity: 1;
        }
        
        .ex-activity-loader {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: 8px;
            margin-left: 8px;
        }
        
        .ex-activity-focus-trap, .ex-activity-focus-target {
            position: absolute;
            left: -999999px;
        }
    `);

    return {
        setActive, setInactive
    };

    function setActive(element: JQuery) {
        const data = element.data('exActiviy');
        if (data) {
            data.container.remove();
            data.focusTargetStart.remove();
            data.focusTargetEnd.remove();
            data.focusTrapStart.remove();
            data.focusTrapEnd.remove();
            element.removeData('exActiviy');
        }
    }

    function setInactive(element: JQuery) {
        if (!element.data('exActiviy')) {
            const container = $compile(`
                <div class="ex-activity-container">
                    <md-progress-circular md-diameter="32" class="m-r-8 ex-activity-loader"></md-progress-circular>         
                </div>
            `)($rootScope);

            // On positionne le masque par dessus l'élément à désactiver
            container.css(element.css(['height', 'width']));
            container.css(<any>element.position());

            // On ajoute des éléments au début et à la fin de l'élément à rendre inaccessible
            const focusTargetStart = angular.element(`<div class="ex-activity-focus-target" tabindex="0"></div>`);
            const focusTargetEnd = focusTargetStart.clone();
            const focusTrapStart = angular.element(`<div class="ex-activity-focus-trap" tabindex="0"></div>`);
            const focusTrapEnd = focusTrapStart.clone();

            // On déplace le focus d'un bout à l'autre de l'élément
            focusTrapStart.on('focus', () => focusTargetEnd.focus());
            focusTrapEnd.on('focus', () => focusTargetStart.focus());

            element.before(focusTargetStart);
            element.before(focusTrapStart);
            element.after(focusTargetEnd);
            element.after(focusTrapEnd);
            element.after(container);

            container.addClass('ex-activity-container--in');

            element.data('exActiviy', {
                container,
                focusTargetStart,
                focusTargetEnd,
                focusTrapStart,
                focusTrapEnd
            });

            if ($document[0].activeElement.tagName !== 'INPUT') {
                focusTargetStart.focus();
            }
        }
    }
}
