import * as angular from 'angular';
import { IQService, module, IPromise } from 'angular';
import { IMenuItem, IMenuItemClass, IMenuItemOptions } from './menu-item.service';
import { IMultiOccurrence, IMultiOccurrenceClass } from '../multi-occurrence.service';
import IDialogOptions = angular.material.IDialogOptions;
import { IApiError } from '../../interfaces/api-error.interface';
import { IUploadManagerService } from '../upload-manager.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IParametresSecuriteService } from '../parametres-securite.service';
import { IActivityService } from '../activity.service';
import { IMonoOccurrence } from "../mono-occurrence.service";
import { IEcranContextController } from "../../behaviors/ex-ecran-context/ex-ecran-context.behavior";
import { IProfil } from "../../resources/profil.resource";
import { IEcranStateParamsService } from "../utils/ecran-state-params.service";
import { IDialog } from '../dialog.service';

export interface IMenuItemImportationExcelClass {
    new(ecranContext: IEcranContextController, options?: IMenuItemOptions, occurrence?: any): IMenuItemImportationExcel;
}

export interface IMenuItemImportationExcel extends IMenuItem {
    ecranContext: IEcranContextController;
    occurrence: any;
}

export default module('core.services.menu-item-action-importation-excel', []).factory('MenuItemImportationExcel', MenuItemImportationExcelFactory);

/* @ngInject */
function MenuItemImportationExcelFactory(uploadManager: IUploadManagerService,
    MultiOccurrence: IMultiOccurrenceClass,
    parametresSecurite: IParametresSecuriteService,
    ecranStateParams: IEcranStateParamsService,
    DialogConfirm: IDialog,
    profil: IProfil,
    exLibelleFilter: IFilterLibelle,
    MenuItem: IMenuItemClass,
    $q: IQService,
    Activity: IActivityService) {

    class MenuItemImportationExcel extends MenuItem implements IMenuItemImportationExcel {
        readonly ecranContext: IEcranContextController;
        readonly occurrence: any;
        constructor(ecranContext: IEcranContextController, options: IMenuItemOptions, occurrence?: any) {
            super('G_LBL_BAR_IMPORT_EXCEL', executeImportation, {
                icon: 'file_upload',
                blocImportation: (occurrence && occurrence.bloc) ? occurrence.bloc : "",
                importation: {
                    nom: 'fichierExcel',
                    inputPattern: '.xls,.csv,.xlsx',
                    maxSize: '1mb'
                },
                ...options
            });

            this.ecranContext = ecranContext;
            this.occurrence = occurrence;
        }
    }

    return MenuItemImportationExcel;

    function executeImportation(event: MouseEvent,
        data: any,
        occurrence: IMultiOccurrence | IMonoOccurrence) {
        let pathSuffix = '';
        const ids = occurrence.getParentIds();
        const isMultiOccurrence = (occurrence instanceof MultiOccurrence);
        const params: any = {
            ...ecranStateParams(occurrence.stateParams),
            ids: ids.join(','),
            srccod: occurrence.getNomSourceDetails(),
            ...parametresSecurite(occurrence.stateParams)
        };

        // Indiquer les champs associé aux libellés dynamiques
        if (occurrence instanceof MultiOccurrence) {
            params['replacedValues'] = `${occurrence.getEntetesReplacedValuesQueryParam()};${occurrence.getDataListReplacedValuesQueryParam()}`
        }

        if (occurrence.ecranSourceDetails.sourceDeveloppement) {
            if (!profil.preferences.itvcleint) {
                return $q.reject({ interventionRequise: true });
            }
            params.itvcleint = profil.preferences.itvcleint;
        }

        if (isMultiOccurrence) {
            Object.assign(params, (<IMultiOccurrence>occurrence).etatSelected.criteresSuggeresData);
            pathSuffix = (<IMultiOccurrence>occurrence).isView ? '/edition' : '';
        }

        Activity.setInactive(angular.element('.app-content'));

        let path = (isMultiOccurrence) ?
            (<IMultiOccurrence>occurrence).getUrlPath() :
            (<IMonoOccurrence>occurrence).getDataResourceUrl();

        if (isMultiOccurrence && (occurrence.multiOccurrenceParent || (<IMultiOccurrence>occurrence).parentId)) {
            const parentQueryParams: any = (<IMultiOccurrence>occurrence).getParentQueryParams();
            Object.keys(parentQueryParams).forEach((col: string) => {
                path = path.replace(`:${col}`, String(parentQueryParams[col]));
            });
        }
        if (occurrence?.formulaire && Array.isArray(occurrence?.formulaire?.liste)) {
            let formulaire: any = []
            occurrence.formulaire.liste.forEach((el: any) => {
                const tmp = { col: el.col, required: (el.required) ? true : false }
                formulaire.push(tmp)
            })
            Object.assign(params, { ...params, formulaire: JSON.stringify(formulaire) })
        }
        return uploadManager.upload(`${path}${pathSuffix}`,
            { fichierExcel: data.fichierExcel },
            params,
            occurrence.stateParams
        )
            .catch((err: IApiError) => {
                //On prend l'erreur en charge ici pour éviter la fermetture du DialogFormulaire
                afficherMessageErreur(err, occurrence);
                Activity.setActive(angular.element('.app-content'));
                return $q.reject(err);
            })
            .then(() => {
                if (isMultiOccurrence) {
                    (<IMultiOccurrence>occurrence).fetchDataList();
                }
                afficherConfirmDialog();
                Activity.setActive(angular.element('.app-content'));
            });
    }

    function afficherConfirmDialog(): IPromise<any> {
        const dialogOptions: IDialogOptions = {
            locals: {
                lblTitre: 'G_MSG_ACTION_SUCCES',
                lblDescription: exLibelleFilter('G_MSG_IMPORT_ENR_SUCCES'),
                lblConfirm: 'G_LBL_BTN_OK',
                icon: 'info',
                hideCancelAction: true
            }
        };
        return DialogConfirm.show(dialogOptions);
    }

    function afficherMessageErreur(error: IApiError, occurrence: IMultiOccurrence | IMonoOccurrence) {
        const message = getErreurMessage(error, occurrence);

        const dialogOptions: IDialogOptions = {
            locals: {
                lblTitre: 'G_MSG_ERREUR_ACTION',
                lblDescription: exLibelleFilter(message),
                lblConfirm: 'G_LBL_BTN_OK',
                icon: 'warning',
                hideCancelAction: true
            }
        };
        return DialogConfirm.show(dialogOptions);
    }

    function getErreurMessage(error: IApiError, occurrence: IMultiOccurrence | IMonoOccurrence) {
        const status = error.request && error.request.status;

        if (status >= 500) {
            return 'G_MSG_ERREUR_GENERIQUE';
        } else if (error.data && error.data.code === 'CHAMP_REQUIS_MANQUANT') {
            return exLibelleFilter('G_MSG_CHAMP_OBLIGATOIRE_IMPORT') + ' : ' + exLibelleFilter(error.data.details.col, occurrence.libelles);
        } else if (error.data && error.data.code === 'VALEUR_INVALIDE') {
            return exLibelleFilter('G_MSG_CHAMP_INVALIDE') + ' : ' + exLibelleFilter(error.data.details.col, occurrence.libelles);
        } else if (error.data && error.data.code === 'AFFICHER_MESSAGE_IMPORTATION') {
            try {
                //s'il y a plus d'un message dans oracle on utilise le premier message
                let message = error.data.message.toString().trim().split("\n", 1);
                //si on a reussie à obtenir le message on fait l'affichage
                if (Array.isArray(message) && message.length > 0) {
                    return exLibelleFilter(message[0])
                } else {
                    return 'G_MSG_ERREUR_IMPORT';
                }

            } catch (error) {
                //s'il y a une erreur on utilise l'affichage generique
                return 'G_MSG_ERREUR_IMPORT';
            }
            //les erreurs de validation n'ont pas été ajoutées
        } else if (error.data && error.data.code === "ERREUR_DE_VALIDATION") {
            if (error.data.message)
                return exLibelleFilter(error.data.message)
            else
                return 'G_MSG_ERREUR_IMPORT';
        } else {
            return 'G_MSG_ERREUR_IMPORT';
        }
    }
}
