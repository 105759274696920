import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogSelectionEmployeController from './dialog-selection-employe.controller';

export default module('app.dialogs.dialog-selection-employe', [
    'core.services.dialog'
]).factory('DialogSelectionEmploye', DialogSelectionEmployeFactory);

/* @ngInject */
function DialogSelectionEmployeFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogSelectionEmployeController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-selection-employe.html'),
        focusOnOpen: false
    });
}
