import { module } from 'angular';

export interface IMenuItemFactory {
    (action: Function, options?: IMenuItemOptions): IMenuItem;
}

export interface IMenuItemClass {
    new(lblTitre: string, action: Function, option?: IMenuItemOptions): IMenuItem;
    new(action: Function, option?: IMenuItemOptions): IMenuItem;
    SuiviModificationMenuItem: IMenuItemFactory;
    AjoutMenuItem: IMenuItemFactory;
    SuppressionMenuItem: IMenuItemFactory;
    EditionMenuItem: IMenuItemFactory;
    lblSuiviModification: string;
}

export interface IMenuItem {
    readonly action: Function;
    readonly icon: string | ((data?: any) => string);
    readonly iconColor: string | ((data?: any) => string);
    readonly lblTitreValidation: string;
    readonly listeMenuItem: Array<IMenuItem>;
    readonly validation: (data: any) => string;
    lblTitre: string;
    busy: boolean;
    compteur: number | ((data: any) => number);
    disabled: boolean | Function;
    fonction: string;
    hidden: boolean | Function;
    visible: boolean | Function;
    courant: boolean | Function;
    iconButton: boolean;
    link: boolean;
    linkExterne: boolean;
    noAction: boolean;
    saveFirst: boolean;
    mnemonique: string;
    secondaire: boolean;
    tabindex: number;
    directionTooltip: string;
    tooltip: string;
    importation: MenuItemImportation;
    class?: string | Function;
    overrideStyle: boolean;
    blocImportation?: string;
}

export interface IMenuItemOptions {
    icon?: string | ((data: any) => string);
    iconColor?: string | ((data: any) => string);
    isFabButton?: boolean;
    listeMenuItem?: Array<IMenuItem>;
    link?: boolean;
    linkExterne?: boolean;
    noAction?: boolean;
    disabled?: boolean | Function;
    hidden?: boolean | Function;
    visible?: boolean | Function;
    courant?: boolean | Function;
    class?: string | Function;
    emphase?: boolean;
    validation?: (data: any) => string;
    lblTitreValidation?: string;
    iconButton?: boolean;
    compteur?: number | ((data: any) => number);
    fonction?: string;
    saveFirst?: boolean
    mnemonique?: string;
    secondaire?: boolean;
    directionTooltip?: string;
    tooltip?: string;
    tabindex?: number;
    importation?: MenuItemImportation;
    overrideStyle?: boolean;
    blocImportation?: string;
}

export interface MenuItemImportation {
    nom: string;
    inputPattern?: string;
    maxSize?: string;
}

export default module('core.services.menu-item', []).factory('MenuItem', MenuItemFactory);

/* @ngInject */
function MenuItemFactory() {

    class MenuItem implements IMenuItem {
        readonly icon: string = '';
        readonly iconColor: string = '';
        readonly listeMenuItem: Array<IMenuItem>;
        readonly action: Function;
        readonly link: boolean = false;
        readonly linkExterne: boolean = false;
        readonly class: string = '';
        readonly noAction: boolean = false;
        readonly isFabButton: boolean = false;
        readonly validation: (data: any) => string;
        readonly lblTitreValidation: string;
        readonly secondaire: boolean;
        readonly tabindex: number;
        readonly importation: MenuItemImportation;
        blocImportation?: string;
        iconButton: boolean = true;

        lblTitre: string = '';
        compteur: number | ((data: any) => number);
        disabled: boolean | Function = false;
        courant: boolean | Function = false;
        hidden: boolean | Function = false;
        visible: boolean | Function = false;
        busy: boolean = false;
        emphase: boolean = false;
        saveFirst: boolean = false;
        fonction: string;
        mnemonique: string;
        directionTooltip: string;
        tooltip: string;
        overrideStyle: boolean;

        constructor(lblTitre: string, action: Function, options: IMenuItemOptions = {}) {
            if (typeof arguments[0] === 'string') {
                this.lblTitre = arguments[0];
                this.action = arguments[1];

                if (arguments[2]) {
                    this.icon = arguments[2].icon || this.icon;
                    this.iconColor = arguments[2].iconColor || this.iconColor;
                    this.isFabButton = arguments[2].isFabButton || this.isFabButton;
                    this.disabled = arguments[2].disabled || this.disabled;
                    this.courant = arguments[2].courant || this.courant;
                    this.hidden = arguments[2].hidden || this.hidden;
                    this.visible = arguments[2].visible || this.visible;
                    this.link = arguments[2].link || this.link;
                    this.linkExterne = arguments[2].linkExterne || this.linkExterne;
                    this.noAction = arguments[2].noAction || this.noAction;
                    this.class = arguments[2].class;
                    this.emphase = arguments[2].emphase || this.emphase;
                    this.validation = arguments[2].validation || this.validation;
                    this.lblTitreValidation = arguments[2].lblTitreValidation || 'G_LBL_ACTION_IMPOSSIBLE';
                    this.iconButton = (typeof arguments[2].iconButton !== 'undefined') ? arguments[2].iconButton : this.iconButton;
                    this.compteur = (typeof arguments[2].compteur !== 'undefined') ? arguments[2].compteur : this.compteur;
                    this.saveFirst = arguments[2].saveFirst || this.saveFirst;
                    this.fonction = arguments[2].fonction || this.fonction;
                    this.listeMenuItem = arguments[2].listeMenuItem || this.listeMenuItem;
                    this.mnemonique = arguments[2].mnemonique || this.mnemonique;
                    this.secondaire = arguments[2].secondaire || this.secondaire;
                    this.directionTooltip = arguments[2].directionTooltip || this.directionTooltip;
                    this.tabindex = arguments[2].tabindex || this.tabindex;
                    this.importation = arguments[2].importation || this.importation;
                    this.blocImportation= arguments[2].blocImportation || this.blocImportation;
                    this.overrideStyle = arguments[2].overrideStyle || false;
                    this.tooltip = arguments[2].tooltip;
                }
            } else {
                this.action = arguments[0];

                if (arguments[1]) {
                    this.icon = arguments[1].icon || this.icon;
                    this.iconColor = arguments[1].iconColor || this.iconColor;
                    this.isFabButton = arguments[1].isFabButton || this.isFabButton;
                    this.disabled = arguments[1].disabled || this.disabled;
                    this.courant = arguments[1].courant || this.courant;
                    this.hidden = arguments[1].hidden || this.hidden;
                    this.visible = arguments[1].visible || this.visible;
                    this.link = arguments[1].link || this.link;
                    this.linkExterne = arguments[1].linkExterne || this.linkExterne;
                    this.noAction = arguments[1].noAction || this.noAction;
                    this.class = arguments[1].class;
                    this.emphase = arguments[1].emphase || this.emphase;
                    this.validation = arguments[1].validation || this.validation;
                    this.lblTitreValidation = arguments[1].lblTitreValidation || 'G_LBL_ACTION_IMPOSSIBLE';
                    this.iconButton = (typeof arguments[1].iconButton !== 'undefined') ? arguments[1].iconButton : this.iconButton;
                    this.compteur = (typeof arguments[1].compteur !== 'undefined') ? arguments[1].compteur : this.compteur;
                    this.saveFirst = arguments[1].saveFirst || this.saveFirst;
                    this.fonction = arguments[1].fonction || this.fonction;
                    this.listeMenuItem = arguments[1].listeMenuItem || this.listeMenuItem;
                    this.mnemonique = arguments[1].mnemonique || this.mnemonique;
                    this.secondaire = arguments[1].secondaire || this.secondaire;
                    this.directionTooltip = arguments[1].directionTooltip || this.directionTooltip;
                    this.tabindex = arguments[1].tabindex || this.tabindex;
                    this.importation = arguments[1].importation || this.importation;
                    this.blocImportation = arguments[1].blocImportation || this.blocImportation;
                    this.tooltip = arguments[1].tooltip;
                }
            }
        }

        public static SuiviModificationMenuItem(action: Function, options: IMenuItemOptions = {}): IMenuItem {
            return new MenuItem('G_LBL_SUIVI_MOD_TITRE', action, Object.assign({ icon: 'history' }, options));
        }

        public static AjoutMenuItem(action: Function, options: IMenuItemOptions = {}): IMenuItem {
            return new MenuItem('G_LBL_BTN_AJOUTER', action, Object.assign({ icon: 'add_box' }, options));
        }

        public static SuppressionMenuItem(action: Function, options: IMenuItemOptions = {}): IMenuItem {
            return new MenuItem('G_LBL_BTN_SUPPRIMER', action, Object.assign({ icon: 'delete' }, options));
        }

        public static EditionMenuItem(action: Function, options: IMenuItemOptions = {}): IMenuItem {
            return new MenuItem('G_LBL_BTN_MODIFIER', action, Object.assign({ icon: 'mode_edit' }, options));
        }
    }

    return MenuItem;
}
