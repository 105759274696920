/**
 * Composant représentant une erreur de chargement
 *
 */
import {IComponentOptions, module} from 'angular';
import ErreurChargementController from './ex-erreur-chargement.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-erreur-chargement', []).component('exErreurChargement', ErreurChargementFactory());

function ErreurChargementFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.LAYOUT.OPTIONAL,
            {
                lblMessage: '@?',
                retryAction: '&?',
                retrying: '<?',
                lblRetry: '@?'
            }
        ),
        controller: ErreurChargementController,
        controllerAs: 'vm',
        template: require('./ex-erreur-chargement.html')
    };
}

