import {IAttributes, IDirective, INgModelController, IScope, module} from 'angular';

export default module('core.behaviors.ex-longueur-fixe', [])
    .directive('exLongueurFixe', LongueurFixeDirective);

interface ILongueurFixeAttributes extends IAttributes {
    exLongueurFixe: string;
}
/* @ngInject */
function LongueurFixeDirective(): IDirective {

    return {
        restrict: 'A',
        bindToController: true,
        controller: () => {},
        require: 'ngModel',
        link
    };

    function link(scope: IScope, element: JQuery, attrs: ILongueurFixeAttributes, ngModelCtrl: INgModelController) {
        if (attrs.exLongueurFixe) {
            const longueurFixe = parseInt(attrs.exLongueurFixe, 10);

            ngModelCtrl.$validators['longueurfixe'] = ($modelValue, $viewValue) => {
                const value = $modelValue || $viewValue;

                if (!value) {
                    return true;
                } else {
                    return value.length === longueurFixe;
                }
            };
        }
    }
}
