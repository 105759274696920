import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogSelectionIntervenantController from './dialog-selection-intervenant.controller';

export default module('app.dialogs.dialog-selection-intervenant', [
    'core.services.dialog'
]).factory('DialogSelectionIntervenant', DialogSelectionIntervenantFactory);

/* @ngInject */
function DialogSelectionIntervenantFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogSelectionIntervenantController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-selection-intervenant.html'),
        focusOnOpen: false
    });
}
