import { IComponentController, IFormController, INgModelController } from 'angular';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IBoutonRadioDataTypeClass } from '../../services/data-types/bouton-radio-data-type.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IDateDataTypeClass } from '../../services/data-types/date-data-type.service';
import { IImputationDataTypeClass } from '../../services/data-types/imputation-data-type.service';
import { ILovDataTypeClass } from '../../services/data-types/lov-data-type.service';
import { ILovEntiteExterneDataTypeClass } from '../../services/data-types/lov-entite-externe-data-type.service';
import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IOperateur, IOperateurService } from '../../services/operateur.service';
import { ISelecteur } from '../../services/selecteur.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { INumberDataTypeClass } from '../../services/data-types/number-data-type.service';
import { IHeureDataTypeClass } from '../../services/data-types/heure-data-type.service';
import { IDateHeureDataTypeClass } from '../../services/data-types/date-heure-data-type.service';
import { ILovBoutonRadioDataTypeClass } from '../../services/data-types/lov-bouton-radio-data-type.service';
import { IFormulaireItem } from "../../services/formulaire/formulaire-item.service";
import { FormulaireElement } from "../../services/formulaire/formulaire.service";

interface IComposantFiltre extends IComponentController {
    colonne: string;
    operateur: IOperateur;
    previousOperateur: IOperateur;
    data: any;
    valeurs: Array<any>;
    operateurs: Array<IOperateur>;
    selecteur: ISelecteur;
    multiOccurrence: IMultiOccurrence,
    formCtrl: formulaire;
    getInputType(): string;
    isLovInput(): boolean;
    isBooleanInput(): boolean;
    isRadioButtonInput(): boolean;
    isStringInput(): boolean;
    isNumberInput(): boolean;
    isDateInput(): boolean;
    getSchemaItem(): ISchemaItem;
    getFiltre(): IFiltre;
    getDataType(): IDataType;
    getResourceParams(): any;
    onColonneChange(): void;
    onOperateurChange(): void;
    getPrecision(): number;
    getDecimals(): number;
    getOperateurTexte(operateur: IOperateur): string;
}

interface formulaire extends IFormController {
    inputOneValueString: INgModelController;
    inputValueMinString: INgModelController;
    inputValueMaxString: INgModelController;
    inputOneValueNumber: INgModelController;
    inputValueMinNumber: INgModelController;
    inputValueMaxNumber: INgModelController;
    dateFieldOneValue: INgModelController;
    dateFieldValueFirst: INgModelController;
    dateFieldValueLast: INgModelController;
}

/* @ngInject */
export default function FiltreController(Operateur: IOperateurService,
    defaults: IDefaultsService,
    Filtre: IFiltreClass,
    LovDataType: ILovDataTypeClass,
    LovEntiteExterneDataType: ILovEntiteExterneDataTypeClass,
    LovBoutonRadioDataType: ILovBoutonRadioDataTypeClass,
    ImputationDataType: IImputationDataTypeClass,
    DateDataType: IDateDataTypeClass,
    HeureDataType: IHeureDataTypeClass,
    DateHeureDataType: IDateHeureDataTypeClass,
    NumberDataType: INumberDataTypeClass,
    BoutonRadioDataType: IBoutonRadioDataTypeClass) {
    const vm: IComposantFiltre = this;

    const DEFAULT_DECIMALS = 2;

    const FILTRE_INPUT_TYPES = {
        BOOLEAN: 'boolean',
        BOUTON_RADIO: 'boutonRadio',
        STRING: 'string',
        DATE: 'date',
        HEURE: 'heure',
        DATE_HEURE: 'date-heure',
        NUMBER: 'number',
        LOV: 'lov',
        LOV_ENTITE_EXTERNE: 'lovEntiteExterne',
        LOV_BOUTON_EXTERNE: 'lovBoutonRadio',
        IMPUTATION: 'imputation',
        NUll_PASNULL: 'nullPasnull'
    };

    vm.$onInit = $onInit;
    vm.isBooleanInput = isBooleanInput;
    vm.isRadioButtonInput = isRadioButtonInput;
    vm.getInputType = getInputType;
    vm.getSchemaItem = getSchemaItem;
    vm.onColonneChange = onColonneChange;
    vm.onOperateurChange = onOperateurChange;
    vm.getDataType = getDataType;
    vm.getResourceParams = getResourceParams;
    vm.getPrecision = getPrecision;
    vm.getDecimals = getDecimals;

    function $onInit(): void {
        defaults(vm, {
            radioValeur: Filtre.BOOLEAN_VALUES.TOUS,
            operateurModifiable: true,
            colonneModifiable: true,
            valeurs: [],
            srccodref: vm.multiOccurrence.getSrccodref()
        });

        vm.previousOperateur = vm.operateur;

        if (vm.operateur) {
            defineOperateurs();
        }
    }

    /**
     * Retourne le type de champ à utiliser selon l'opérateur et la colonne
     */
    function getInputType(): string {
        const schemaItem = getSchemaItem();
        const dataType = getDataType();

        if (vm.multiOccurrence.isBooleanDataType(vm.colonne)) {
            return FILTRE_INPUT_TYPES.BOOLEAN;
        } else if (dataType instanceof BoutonRadioDataType) {
            return FILTRE_INPUT_TYPES.BOUTON_RADIO;
        } else if (vm.operateur && (vm.operateur === Operateur.NULL || vm.operateur === Operateur.PAS_NULL)) {
            return FILTRE_INPUT_TYPES.NUll_PASNULL;
        } else if (vm.operateur && vm.operateur.isRecherchePartielleOperateur()) {
            return FILTRE_INPUT_TYPES.STRING;
        } else if (dataType instanceof LovDataType) {
            return FILTRE_INPUT_TYPES.LOV;
        } else if (dataType instanceof LovEntiteExterneDataType) {
            return FILTRE_INPUT_TYPES.LOV_ENTITE_EXTERNE;
        } else if (dataType instanceof LovBoutonRadioDataType) {
            return FILTRE_INPUT_TYPES.LOV_BOUTON_EXTERNE;
        } else if (dataType instanceof ImputationDataType) {
            return FILTRE_INPUT_TYPES.IMPUTATION;
        } else if (dataType instanceof HeureDataType) {
            return FILTRE_INPUT_TYPES.HEURE;
        } else if (dataType instanceof DateHeureDataType) {
            return FILTRE_INPUT_TYPES.DATE_HEURE;
        } else if ((schemaItem && schemaItem.type === 'number') || dataType instanceof NumberDataType) {
            return FILTRE_INPUT_TYPES.NUMBER;
        } else if ((schemaItem && schemaItem.type === 'date') || dataType instanceof DateDataType || vm.operateur === Operateur.CREE_DEPUIS || vm.operateur === Operateur.MODIF_DEPUIS || vm.operateur === Operateur.CREE_MODIF_DEPUIS) {
            return FILTRE_INPUT_TYPES.DATE;
        } else {
            return FILTRE_INPUT_TYPES.STRING;
        }
    }

    /**
     * Permet de savoir si le champ est de type boolean
     */
    function isBooleanInput() {
        return getInputType() === FILTRE_INPUT_TYPES.BOOLEAN;
    }

    /**
     * Permet de savoir si le champ est de type radiobutton
     */
    function isRadioButtonInput() {
        return getInputType() === FILTRE_INPUT_TYPES.BOUTON_RADIO;
    }

    /**
     * Permet d'obtenir le schemas de la colonne courante
     */
    function getSchemaItem(): ISchemaItem {
        return vm.multiOccurrence.schema[vm.colonne];
    }

    /**
     * Permet d'obtenir le dataType de la colonne courante
     */
    function getDataType(): IDataType {
        return vm.multiOccurrence.dataTypes[vm.colonne];
    }

    /**
     * Permet d'obtenir les resourceParams de la colonne courante
     */
    function getResourceParams(): any {
        if (vm.multiOccurrence.criteresSuggeres) {
            const formulaireItem: FormulaireElement = vm.multiOccurrence.criteresSuggeres.liste
                .find((formulaireItem: FormulaireElement) => (<IFormulaireItem>formulaireItem).col === vm.colonne);
            return formulaireItem && (<IFormulaireItem>formulaireItem).resourceParams;
        }
    }

    function onColonneChange() {
        // Les données contiennent les informations du LOV, alors si on change de colonne est ne sont plus pertinentes
        vm.data = {};

        // Les valeurs de LOV ne sont pas compatibles entre les types de lovs, alors in faut vider la valeur
        if (getInputType() === FILTRE_INPUT_TYPES.LOV) {
            vm.valeurs[0] = null;
        }

        if (getInputType() === FILTRE_INPUT_TYPES.BOUTON_RADIO) {
            vm.valeurs[0] = "";
        }

        defineOperateurs();

        if (vm.valeurs[0] && vm.formCtrl.valeur1) {
            vm.formCtrl.valeur1.$setTouched();
            vm.formCtrl.valeur1.$setDirty();
        }

        if (vm.valeurs[1] && vm.formCtrl.valeur2) {
            vm.formCtrl.valeur2.$setTouched();
            vm.formCtrl.valeur2.$setDirty();
        }
    }

    function onOperateurChange() {

        // Quand on change de "contient" à "equal", les données ne font plus de sens pour la lov
        const doitViderValeur = (
            getInputType() === FILTRE_INPUT_TYPES.LOV &&
            (vm.operateur && vm.operateur.isRechercheExacteOperateur()) &&
            (vm.previousOperateur && !vm.previousOperateur.isRechercheExacteOperateur())
        );

        if (doitViderValeur) {
            vm.data = {};
            vm.valeurs[0] = null;
        }

        vm.previousOperateur = vm.operateur;
    }

    /**
     * Modifie la liste des opérateurs selon le type de colonne sélectionnée.
     * Les colonnes de type 'Numéro' et 'Date' détienne la totalité des opérateurs.
     * Seul les colonnes de type 'String' on des restriction sur la liste des opérateurs.
     */
    function defineOperateurs(): void {
        const dataType = getDataType();

        if (dataType) {
            vm.operateurs = dataType.operateurs;

            //Force l'opérateur a être égale dans le cas ou on a des boutons radio
            if (vm.isBooleanInput() || vm.isRadioButtonInput()) {
                vm.operateur = Operateur.EGALE;
            } else if (dataType instanceof ImputationDataType) {
                vm.operateur = Operateur.CONTIENT;
            }
        } else {
            vm.operateurs = Operateur.ALL_OPERATEURS;

            if (!vm.multiOccurrence.schema.datcre || !vm.multiOccurrence.schema.datmod) {
                vm.operateurs = vm.operateurs.filter(o => !o.nom.endsWith("DEPUIS"))
            }
        }

        if (!vm.operateurs.includes(vm.operateur)) {
            vm.operateur = Operateur.EGALE;
        }
    }

    function getPrecision() {
        const schemaItem = getSchemaItem();
        if (schemaItem) {
            return schemaItem.precision;
        } else {
            const dataType = getDataType();
            return dataType.params.precision;
        }
    }

    function getDecimals() {
        const schemaItem = getSchemaItem();

        if (schemaItem) {
            return (schemaItem.decimals !== null) ?
                schemaItem.decimals :
                DEFAULT_DECIMALS;
        } else {
            const dataType = getDataType();
            return dataType.params.decimals;
        }
    }

    vm.getOperateurTexte = function getOperateurTexte(operateur: IOperateur) {
        if (!getDataType() && operateur.textePluriel) {
            return operateur.textePluriel;
        }
        return operateur.texte;
    }
}
