import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface ISelectionEmployeOptions {}

export interface ISelectionEmploye extends IResource<ISelectionEmploye>  {}

export interface ISelectionEmployeResource extends IResourceClass<any> {
    new(data: ISelectionEmployeOptions): IResource<ISelectionEmploye>;
    create(data: any, params: any): IResource<ISelectionEmploye>;
}

export default module('core.resources.selection-employe', [
    'core.services.api-config'
]).factory('SelectionEmployeResource', SelectionEmployeResourceFactory);

/* @ngInject */
function SelectionEmployeResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): ISelectionEmployeResource {
    const params = {};
    const options = {};

    const actions = {
        create: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/employe/`
        },
        delete: {
            method: 'DELETE',
            url: `${ApiConfig.ROOT}/employe/:id`
        }
    };

    return <ISelectionEmployeResource>$resource(`${ApiConfig.ROOT}/employe/`, params, actions, options);
}
