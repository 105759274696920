import {IDocumentService, module} from 'angular';

export interface IInjectStyleService {
    (css: string, id?: string): void;
}

export default module('core.services.inject-style', []).factory('injectStyle', InjectStyleFactory);

/* @ngInject */
function InjectStyleFactory($document: IDocumentService): IInjectStyleService {
    return function injectStyle(css: string, id?: string): void {
        if (id) {
            $document.find('#' + id).remove();
        }
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(css));
        style.id = id;
        $document.find('head')[0].appendChild(style);
    };
}
