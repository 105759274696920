import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IComposantMonoOccurrence} from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import {IComponentController} from 'angular';

interface ILibelleComposant extends IComponentController {
    libelles: any;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    dataSourceCtrl: IDataSourceController;
}

/* @ngInject */
export default function LibelleController() {
    const vm: ILibelleComposant = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);
    }
}
