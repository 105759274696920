import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IComponentController, IScope} from 'angular';

interface IComposantCaseUser extends IComponentController {
    dataSourceCtrl: IDataSourceController;
    colcod: string;
    colnom: string;
    colpos: string;
    data: ICaseUserData;
}
interface ICaseUserData {
    code: string;
    nom: string;
    poste: string;
}

/* @ngInject */
export default function CaseUserController($scope: IScope, defaults: IDefaultsService) {
    const vm: IComposantCaseUser = this;
    vm.$onInit = $onInit;

    function $onInit() {
        defaults(vm, {
            colcod: 'usrcod',
            colnom: 'usrnom',
            colpos: 'numtelpos'
        });

        $scope.$watch('vm.dataSourceCtrl', (newValue: IDataSourceController) => {
            if (newValue) {
                vm.data = {
                    code: newValue.data[vm.colcod],
                    nom: newValue.data[vm.colnom],
                    poste: newValue.data[vm.colpos]
                };
            }
        });
    }
}
