/**
 * Composant représentant une card contenant le cycle .
 */
import {IComponentOptions, module} from 'angular';
import CardCycleMessageController from './ex-card-message.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-card-message', []).component('exCardMessage', CardCycleFactory());

function CardCycleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                messagesCycle: '<',
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: CardCycleMessageController,
        controllerAs: 'vm',
        template: require('./ex-card-message.html')
    };
}
