import { IAugmentedJQuery, IComponentController, IRootScopeService, IScope, ITimeoutService, ITranscludeFunction } from 'angular';
import { IProfil, IProfilAcces } from '../../resources/profil.resource';
import { IMenuNavigationItem } from '../../interfaces/menu-navigation-item.interface';
import { IRoute } from "../../interfaces/route.interface";
import { IStateService } from "angular-ui-router";
import { IEcranDetailsResourcesEntite } from "../../resources/ecran-details.resource";
import isMobile from "../../constants/mobile.constant";
import { IFavorisMenu } from "../../interfaces/favoris-menu.interface";

interface NavigationMenuComponent extends IComponentController {
    profilsAccesBase: Array<IProfilAcces>;
    profilsAcces: Array<IProfilAcces>;
    navigationOpened: boolean;
    focusedElement: IAugmentedJQuery;
    focusedElementIndex: number;
    visibleLinks: IAugmentedJQuery;
    scrollContainer: IAugmentedJQuery;
    focusables: IAugmentedJQuery;
    afficheAccueil: boolean;
    cleFavoris: string;
    onRechercheKeydown(event: KeyboardEvent): void;
    onRechercheBlur(): void;
}

const FOCUSED_CLASS = 'ex-navigation-menu-item--focused';
const ACTIVE_SELECTOR = '.ex-navigation-item-active';
const BUTTON_SELECTOR = 'a.ex-navigation-item-button';
const SECTION_HEADER_SELECTOR = '.ex-toggle-box-header';

const TRAITEMENTS = {
    LOT: 'TRL',
    EXCEL: 'TRX',
    QLIK: 'QCS'
};

/* @ngInject */
export default function NavigationMenuController($scope: IScope,
    $timeout: ITimeoutService,
    $element: IAugmentedJQuery,
    $transclude: ITranscludeFunction,
    $state: IStateService,
    accueilRoute: IRoute,
    $rootScope: IRootScopeService,
    appName: string,
    ecranDetails: IEcranDetailsResourcesEntite,
    profil: IProfil) {
    const vm: NavigationMenuComponent = this;

    vm.isCentralise = appName === "sofe";
    vm.cleFavoris = "ex-navigation-favoris"
    vm.accueilRoute = accueilRoute;
    vm.hyperliens = triHyperliens()
    vm.$onInit = function $onInit() {
        vm.accueilActif = isAccueilActif();
        $rootScope.$on('$stateChangeSuccess', () => {
            vm.hyperliens = triHyperliens()
            vm.accueilActif = isAccueilActif()

            if (isMobile) {
                vm.navigationOpened = false
            }
        });

        vm.focusedElementIndex = -1;
        vm.scrollContainer = $element.find('md-content');
        $scope.$watch("vm.value", (newValue: string, oldValue: string) => {
            if (!newValue || newValue.length <= 2) {
                vm.profilsAcces = vm.profilsAccesBase;
            } else if (newValue !== oldValue) {
                const regex = new RegExp(`(${newValue.replace(/[aãáàâ]/gi, '[aãáàâ]').replace(/[eẽéèê]/gi, '[eẽéèê]').replace(/[iĩíìî]/gi, '[iĩíìî]').replace(/[oõóòô]/gi, '[oõóòô]').replace(/[uũúùû]/gi, '[uũúùû]').replace(/[cç]/gi, '[cç]')})`, 'gi');
                vm.profilsAcces = vm.profilsAccesBase.reduce((filtered, profilAcces) => {
                    if (regex.test(profilAcces.dscmnu)) {
                        filtered.push({
                            ...profilAcces,
                            dscmnu: profilAcces.dscmnu.replace(regex, '<span class="highlight">$1</span>')
                        });
                    } else {
                        const matchedMenus = searchMenus(profilAcces.menus, regex);

                        if (matchedMenus.length) {
                            filtered.push({ ...profilAcces, menus: matchedMenus });
                        }
                    }
                    return filtered;
                }, []);
                $timeout(() => {
                    focusElement($transclude.isSlotFilled("content") ? 0 : 1, undefined, true, false)
                })
            }
        });

        for (const profil of vm.profilsAccesBase) {
            for (const menu of profil.menus) {
                prepareItem(menu)
            }
        }

        if (vm.isCentralise) {
            $scope.$watch("vm.profilsAcces", () => {
                if (vm.profilsAcces) {
                    vm.favoris = localStorage.getItem(vm.cleFavoris) ? prepareFavoris(JSON.parse(localStorage.getItem(vm.cleFavoris))) : [];
                }
            })

            $rootScope.$on(vm.cleFavoris, (event, favoris) => {
                vm.favoris = prepareFavoris(favoris);
            })
        }
    }

    function prepareItem(item: IMenuNavigationItem) {
        switch (item.vaesysenttrt) {
            case TRAITEMENTS.LOT:
                item.isRapport = true;
                item.icon = 'print';
                item.isFontAwesomeIcon = false;
                break;
            case TRAITEMENTS.EXCEL:
                item.isRapport = true;
                item.icon = 'fas fa-file-excel';
                item.isFontAwesomeIcon = true;
                break;
            case TRAITEMENTS.QLIK:
                item.isQlik = true;
                item.icon = ' insert_chart';
                item.isFontAwesomeIcon = false;
                break;
        }
        if (item.items) {
            for (const child of item.items) {
                prepareItem(child)
            }
        }
    }

    function searchMenus(menus: Array<IMenuNavigationItem>, regex: RegExp) {
        return menus.reduce((matchedMenus, menu) => {
            if (menu.items) {
                const matchedSousMenus = searchMenus(menu.items, regex);

                if (matchedSousMenus.length) {
                    matchedMenus.push({ ...menu, items: matchedSousMenus });
                }
            } else if (regex.test(menu.codref)) {
                matchedMenus.push({
                    ...menu,
                    meddscmen: menu.meddscmen.replace(regex, '<span class="highlight">$1</span>'),
                    codreftxt: menu.codref.replace(regex, '<span class="highlight">$1</span>'),
                    highlightedCode: true
                });
            } else if (regex.test(menu.meddscmen)) {
                matchedMenus.push({
                    ...menu,
                    meddscmen: menu.meddscmen.replace(regex, '<span class="highlight">$1</span>'),
                    codreftxt: menu.codref,
                    highlightedCode: false
                });
            }

            return matchedMenus;
        }, []);
    }

    vm.onRechercheKeydown = function onRechercheKeydown(event: KeyboardEvent) {
        if (event.key === "ArrowUp") {
            event.preventDefault();
            focusElement(vm.focusedElementIndex, -1);
        } else if (event.key === "ArrowDown") {
            event.preventDefault();
            focusElement(vm.focusedElementIndex, 1);
        } else if (event.key === "Enter") {
            if (vm.focusedElement) {
                $scope.$applyAsync(() => {
                    $timeout(() => {
                        vm.focusedElement.trigger('click');
                    })
                })
            }
        } else if (event.key === "Escape") {
            $timeout(() => {
                focusElement(-1);
            });
        }
    }

    vm.onRechercheBlur = function onRechercheBlur() {
        focusElement(-1, null, false);
    }

    function focusElement(index: number, direction?: number, scrollToElementPosition = true, focusMenuElements = true) {
        if (vm.focusedElement) {
            vm.focusedElement.removeClass(FOCUSED_CLASS);
        }

        if (direction) {
            vm.focusedElementIndex = index + direction;
            vm.visibleLinks = $element.find(`${BUTTON_SELECTOR},${SECTION_HEADER_SELECTOR}`);
            vm.focusedElement = vm.visibleLinks.eq(vm.focusedElementIndex);
        } else if (index === -1) {
            vm.focusedElement = null;
            vm.focusedElementIndex = index;
        } else {
            vm.visibleLinks = focusMenuElements ? $element.find(`${BUTTON_SELECTOR},${SECTION_HEADER_SELECTOR}`) : $element.find(BUTTON_SELECTOR);
            const element = vm.visibleLinks.eq(index);
            vm.focusedElement = element.length ? element : null;
            vm.focusedElementIndex = vm.focusedElement ? focusMenuElements ? index : $element.find(`${BUTTON_SELECTOR},${SECTION_HEADER_SELECTOR}`).index(vm.focusedElement) : -1;
        }

        if (scrollToElementPosition) {
            if (vm.focusedElement) {
                vm.focusedElement.addClass(FOCUSED_CLASS);
                scrollToElement(vm.focusedElement);
            } else {
                scrollToElement($element.find(ACTIVE_SELECTOR));
            }
        }
    }

    function scrollToElement(element: IAugmentedJQuery) {
        const position = element.position();
        if (position) {
            vm.scrollContainer.scrollTop(position.top + vm.scrollContainer.scrollTop() - (vm.scrollContainer.height() / 2) + 10);
        }
    }

    function isAccueilActif() {
        return $state.current.name === accueilRoute.NAME;
    }

    function triHyperliens() {
        return (profil.hyperliens || []).concat(ecranDetails.hyperliens || []).sort((a, b) => a.hypseq - b.hypseq)
    }

    function prepareFavoris(favoris: IFavorisMenu[]) {
        return favoris.map(favori => {
            const profil = vm.profilsAcces.find(p => p.pracleint === favori.pracleint)
            if (profil) {
                return findItemInProfil(favori, profil.menus)
            }
        }).filter(favori => favori)
    }

    function findItemInProfil(favori: IFavorisMenu, navigationItems: IMenuNavigationItem[]): any {
        for (const navigationItem of navigationItems) {
            if (navigationItem.medcleintref === favori.medcleintref && navigationItem.mencleint === favori.mencleint && navigationItem.vaesysenttrt === favori.vaesysenttrt) return { ...favori, navigationItem }
            if (navigationItem.items) {
                const foundItem = findItemInProfil(favori, navigationItem.items)
                if (foundItem) return foundItem
            }
        }
    }
}
