import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-texte', []).component('exTexte', TexteFactory());

function TexteFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                lblTitre: '@',
                hasCustomClass: '<?'
            }
        ),
        controllerAs: 'vm',
        template: require('./ex-texte.html')
    };
}
