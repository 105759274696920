import { IComponentController } from 'angular';
import { IMonoOccurrence, IMonoOccurrenceOptions } from '../../services/mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IDialogCycleTransactionnelHistoriqueController extends IComponentController {
    ecranContext: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    historiqueEtatMultiOccurrenceOptions: IMultiOccurrenceOptions;
    historiqueTachesMultiOccurrenceOptions: IMultiOccurrenceOptions;
    historiqueEtatMultiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function DialogCycleTransactionnelHistoriqueController() {
    const vm: IDialogCycleTransactionnelHistoriqueController = this;

    vm.$onInit = function $onInit() {
        const resourceParams = {
            trncleint: vm.monoOccurrence.id || vm.monoOccurrence.data.trncleint,
            srccod: vm.monoOccurrence.stateParams.srccod
        };

        vm.historiqueEtatMultiOccurrenceOptions = {
            srccod: 'mr1005',
            resourceParams,
            colonnesVisibles: [
                { nom: 'ecldsceta', largeur: 150 },
                { nom: 'usrcod', largeur: 150 },
                { nom: 'usrnom', largeur: 150 },
                { nom: 'numtelpos', largeur: 150 },
                { nom: 'datcre', largeur: 150 },
                { nom: 'theflgstaref', largeur: 150 },
                { nom: 'theflgstafin', largeur: 150 },
                { nom: 'theflgstafer', largeur: 150 },
                { nom: 'theflgstaann', largeur: 150 },
                { nom: 'thecom', largeur: 150 },
                { nom: 'ecldscetapro', largeur: 150 },
                { nom: 'theflgcen', largeur: 150 },
                { nom: 'theflggen', largeur: 150 }
            ],
            fonctions: {
                edition: false,
                nouveau: false,
                supprime: false,
                suiviModification: false,
                enregistrerUnEtat: false,
                selectionCycle:false
            }
        };

        vm.historiqueTachesMultiOccurrenceOptions = {
            srccod: 'mr1005',
            bloc: 'tht',
            resourceParams,
            colonnesVisibles: [
                'datcre',
                'ctadsc',
                'thtflgeff',
                'usrcod',
                'usrnom',
                'numtelpos',
                'thtcom'
            ],
            fonctions: {
                edition: false,
                nouveau: false,
                supprime: false,
                suiviModification: false
            }
        };
    }
}
