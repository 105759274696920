import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    IPromise,
    IWindowService
} from 'angular';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IMenu, IMenuClass } from '../../services/menu/menu.service';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../services/menu/menu-more.service';
import { IDialog } from '../../services/dialog.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IApiError } from '../../interfaces/api-error.interface';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import IDialogOptions = angular.material.IDialogOptions;
import IDialogService = angular.material.IDialogService;
import IStateService = angular.ui.IStateService;
import { IMenuItemImportationExcelClass } from '../../services/menu/menu-item-importation-excel.service';
import { IMenuDividerClass } from '../../services/menu/menu-divider.service';
import { IMenuItemClass } from '../../services/menu/menu-item.service';
import { IRoute } from '../../interfaces/route.interface';
import IInjectorService = angular.auto.IInjectorService;
import { IMenuItemEcranClass } from '../../services/menu/menu-item-ecran.service';
import { IAvisEmployesResource } from '../../resources/avis-employes.resource';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IChangementManager } from '../../services/changement-manager.service';
import { IEcranStateParamsService } from '../../services/utils/ecran-state-params.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IMenuItemForageClass } from '../../services/menu/menu-item-forage.service';
import { IApiConfig } from "../../interfaces/api-config.interface";
import { IMenuItemForageExterneClass } from "../../services/menu/menu-item-forage-externe.service";

export interface IComposantCardFormulaireMaitre extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    formulaire: IFormulaire;
    menuPlaquette: IMenu;
    formCtrl: IFormController;
    formData: any;
    menuMoreBas: IMenu;
    collapsable: boolean;

    downloadExcel(): void;
    hasParametres(): boolean;
}

/* @ngInject */
export default function CardFormulaireMaitreController(AvisEmployesResource: IAvisEmployesResource,
    Menu: IMenuClass, ApiConfig: IApiConfig, MenuItemForageExterne: IMenuItemForageExterneClass,
    MenuMore: IMenuMoreClass,
    MenuItem: IMenuItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuDivider: IMenuDividerClass,
    MenuItemImportationExcel: IMenuItemImportationExcelClass,
    DialogStatus: IDialogAllStatus,
    DialogSuiviModification: IDialog,
    $injector: IInjectorService,
    MenuItemForage: IMenuItemForageClass,
    changementManager: IChangementManager,
    notificationHandler: INotificationHandler,
    parametresSecurite: IParametresSecuriteService,
    ecranDetails: IEcranDetailsResourcesEntite,
    $mdDialog: IDialogService,
    $state: IStateService,
    $window: IWindowService,
    $element: IAugmentedJQuery,
    defaults: IDefaultsService,
    ecranStateParams: IEcranStateParamsService,
    appName: string) {
    const vm: IComposantCardFormulaireMaitre = this;

    vm.$onInit = $onInit;
    vm.downloadExcel = downloadExcel;

    function $onInit() {
        defaults(vm, {
            collapsable: true
        });

        const menuMoreTypes: IMenuMoreTypes = {};
        const menuMoreTypesOptions: IMenuMoreTypesOptions = {};

        if (vm.monoOccurrence.fonctions.supprime) {
            if (vm.monoOccurrence.fonctions.supprime instanceof Function) {
                menuMoreTypesOptions.supprime = {
                    visible: vm.monoOccurrence.fonctions.supprime
                };
            }

            menuMoreTypes.supprime = () => {
                const retourParams = vm.monoOccurrence.paramsRetourSuppression instanceof Function ?
                    vm.monoOccurrence.paramsRetourSuppression(vm.monoOccurrence.data) : vm.monoOccurrence.paramsRetourSuppression;
                vm.monoOccurrence.delete(vm.monoOccurrence.id, ecranStateParams(vm.ecranContextCtrl.stateParams))
                    .then(() => {
                        notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');

                        if (vm.ecranContextCtrl.ecranDetails.ecrcleint === ecranDetails.ecrcleint) {
                            const routeName = vm.monoOccurrence.pageRetourSuppression || vm.ecranContextCtrl.stateParams.route.NAME;
                            const stateParams = { ...vm.ecranContextCtrl.stateParams };

                            //On ne conserve pas la route et le srccod de l'écran actuel dans les paramètres.
                            delete stateParams.srccod;
                            delete stateParams.route;

                            const params = {
                                ...stateParams,
                                id: null,
                                verifierChangements: false,
                                ...(retourParams || {})
                            };

                            if (changementManager.getPreviousURL().endsWith(decodeURIComponent($state.href(routeName, params)))) {
                                //Si la page de retour est la page précédente on passe par l'historique
                                $window.history.back();
                            } else {
                                // On remplace l'état pour éviter une redirection sur l'enregistrement supprimé
                                $state.transitionTo(routeName, params, {
                                    location: 'replace',
                                    reload: vm.monoOccurrence.id && !vm.monoOccurrence.stateParams.id
                                });
                            }
                        } else {
                            $mdDialog.hide();
                        }
                    })
                    .catch((err) => {
                        if (err !== DialogStatus.FERMER) {
                            afficherValidationErreur(err);
                        }
                    });
            };
        }

        if (vm.monoOccurrence.fonctions.suiviModification) {
            menuMoreTypes.suiviModification = (event: any) => {
                afficherSuiviModification(event);
            };
        }

        if (vm.monoOccurrence.fonctions.exportation || vm.monoOccurrence.fonctions.importation || $state.current.name.includes('rr0149')) {
            // On bati un menu pour les items qui se retrouveront dans le bas du menuMore.
            const menuItemsBas = [];

            if (!vm.monoOccurrence.fonctions.suiviModification) {
                menuItemsBas.push(new MenuDivider());
            }

            if (vm.monoOccurrence.schema.demcleint && !vm.monoOccurrence.schema.empcleint && appName === "sofe" && !$state.current.name.includes('mr1010')) {
                menuItemsBas.push(new MenuItem(
                    'G_LBL_FCT_TRANS_AVIS_COMM_EMP',
                    (event: MouseEvent) => openEcranAvis(event),
                    {
                        icon: 'contact_mail'
                    }
                ));
            }

            if (vm.monoOccurrence.schema.demcleint && !vm.monoOccurrence.schema.empcleint && appName === "sofe" && !$state.current.name.includes('mr1010')) {
                menuItemsBas.push(new MenuItemForage('Avis manuel', ($injector.get('mr1009Route') as IRoute).NAME, (data) => ({
                    id: data.demcleint || 9999999,
                    demcleint: data.demcleint,
                    stamnetabref: vm.monoOccurrence.mnemonique,
                    avicleintref: vm.monoOccurrence.data[vm.monoOccurrence.cleint]
                }), { icon: 'alarm' }));
            }

            if (vm.monoOccurrence.fonctions.exportation) {
                menuItemsBas.push(
                    new MenuItem(
                        'G_LBL_BAR_EXPORT_EXCEL',
                        downloadExcel,
                        {
                            icon: 'file_download'
                        }
                    )
                );
            }

            if (vm.monoOccurrence.fonctions.importation) {
                menuItemsBas.push(new MenuItemImportationExcel(vm.ecranContextCtrl, { iconButton: !vm.ecranContextCtrl.stateParams.id }));
            }

            if (ecranDetails.urlclient.length || ecranDetails.documclient.length) {
                for (const url of ecranDetails.urlclient) {
                    menuItemsBas.push(new MenuItemForageExterne(url.eucdsc, url.eucurl, { iconButton: false }))
                }
                for (const docum of ecranDetails.documclient) {
                    menuItemsBas.push(new MenuItemForageExterne(docum.edcdsc, `${ApiConfig.ROOT}/gsecr-docum-client/${docum.edccleint}`, { iconButton: false }))
                }
            }

            vm.menuMoreBas = new Menu(menuItemsBas);
        }

        vm.menuPlaquette = new MenuMore(menuMoreTypes, vm.monoOccurrence.actionsMore, menuMoreTypesOptions);
    }

    function openEcranAvis(event: MouseEvent) {
        const gs0069Route: IRoute = $injector.get('gs0069Route');
        const menuItemEcran = new MenuItemEcran(
            'G_LBL_FCT_TRANS_AVIS_COMM_EMP',
            gs0069Route.NAME,
            (data: any) => ({
                xyzcleint: data.xyzcleint
            })
        );

        getXyzcleintStep()
            .then((result: any) => {
                menuItemEcran.action(event, result, vm.monoOccurrence);
            })
            .catch((error: any) => {
                if (error !== DialogStatus.FERMER) {
                    notificationHandler.erreur({
                        error,
                        lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                        lblMessage: 'G_MSG_CHARG_DONNEES',
                        confirmAction: () => openEcranAvis(event)
                    });
                }
            });
    }

    function getXyzcleintStep(): IPromise<any> {
        const params: any = {
            srccod: vm.monoOccurrence.srccod,
            ...parametresSecurite(vm.monoOccurrence.stateParams)
        };

        if (params.limit !== 'undefined') {
            delete params.limit;
        }

        if (vm.monoOccurrence.id) {
            params.ids = vm.monoOccurrence.id;

            return AvisEmployesResource.get(params).$promise;
        } else if (vm.monoOccurrence.stateParams.employe) {
            params.demcleint = vm.monoOccurrence.stateParams.employe;

            return AvisEmployesResource.getByEmploye(params).$promise;
        }
    }

    function afficherValidationErreur(error: IApiError) {
        const message = (error.data && error.data.code && error.data.code.startsWith('SOFE-')) ? error.data.message : 'G_MSG_SAUV_ENR';
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: message,
            confirmAction: () => {
                return vm.monoOccurrence.delete(vm.monoOccurrence.id);
            }
        });
    }

    /**
     * Permet de faire la sauvegarde d'un fichier
     *
     */
    function downloadExcel(): void {
        // Afficher le curseur de chargement
        document.body.style.cursor = 'wait';

        vm.monoOccurrence.downloadData($element.closest('ex-mono-occurrence-ecran'))
            .catch(afficherDownloadErreur)
            .finally(() => {
                // Rétablir le curseur par défaut une fois le chargement terminé
                document.body.style.cursor = 'auto';
            }
        );
    }

    function afficherDownloadErreur(error: IApiError): void {
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_BAR_EXPORT_EXCEL',
            lblMessage: 'G_MSG_CHARG_DONNEES',
            confirmAction() {
                return vm.monoOccurrence.downloadData($element.closest('ex-mono-occurrence-ecran'));
            }
        });
    }

    function afficherSuiviModification(ev: any): void {
        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            locals: {
                data: vm.monoOccurrence.data,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSuiviModification.show(dialogOptions);
    }
}
