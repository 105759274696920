/**
 * Composant représentant la fonction transversale du cycle evenement
 */
import {module} from 'angular';
import DialogCycleEvenementInformationsController from './dialog-cycle-evenement-informations.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-cycle-evenement-informations', [
    'core.services.dialog'
]).factory('DialogCycleEvenementInformations', DialogCycleEvenementInformationsFactory);

/* @ngInject */
function DialogCycleEvenementInformationsFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCycleEvenementInformationsController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-cycle-evenement-informations.html'),
        locals: {
            icon: 'info_outline',
            lblTitre: "G_LBL_INFOS_CYCLE_EVENEMENT",
            lblCancel: 'G_LBL_BTN_FERMER'
        }
    });
}
