import {IComponentController} from 'angular';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import {IErrorTypes} from '../../constants/error-types.constant';

interface IErrorInput {
    [key: string]: any;
    $error: object;
    $$SofeValidationType: string
    $$SofeValidationMessage: string;
}

interface IMessage {
    key:string;
    message: string;
}

export interface IComposantInputValidation extends IComponentController {
    input: IErrorInput;
    messages: Array<IMessage>

    registerMessage(key: string, value: string): void;
    shouldShowError() : boolean;
    displayOrTranslate(message: string) : string
    getErrorClass() : string;
}

/* @ngInject */
export default function InputValidationController(defaults: IDefaultsService,
                                                  ErrorTypes: IErrorTypes,
                                                  exLibelleFilter: IFilterLibelle) {
    const vm: IComposantInputValidation = this;

    vm.$onInit = $onInit;
    vm.registerMessage = registerMessage;
    vm.shouldShowError = shouldShowError;
    vm.displayOrTranslate = displayOrTranslate;
    vm.getErrorClass = getErrorClass;

    function $onInit() {
        vm.messages = [];
        defaults(vm.input, {
            type: ErrorTypes.ERREUR
        });
    }

    function registerMessage (key: string, value: string) : void {
        vm.messages.push({
            key,
            message: value
        });
    }

    function shouldShowError() : boolean {
        return Boolean(Object.keys(vm.input.$error).length || (vm.input.$$SofeValidationType && vm.input.$$SofeValidationMessage));
    }

    /**
     * On évite de retraduire le message si il est déjà traduit.
     * @param message
     */
    function displayOrTranslate(message: string) : string {
        return message.match(/(G_)?(LBL|MSG)_([A-Z0-9]+_?)+/) ? exLibelleFilter(message) : message;
    }

    function getErrorClass() : string {
        if (vm.input.$$SofeValidationType === ErrorTypes.ERREUR
        || ![ErrorTypes.AVERTISSEMENT, ErrorTypes.ERREUR, ErrorTypes.INFORMATION].includes(vm.input.$$SofeValidationType)) {
            return 'ex-input-validation-error';
        } else if (vm.input.$$SofeValidationType === ErrorTypes.AVERTISSEMENT)  {
            return 'ex-input-validation-warning';
        } else if (vm.input.$$SofeValidationType === ErrorTypes.INFORMATION) {
            return 'ex-input-validation-info';
        }
    }
}

