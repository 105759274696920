/**
 * Composant représentant un mono-occurrence
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MonoOccurrenceController from './ex-mono-occurrence.controller';

export default module('core.components.ex-mono-occurrence', []).component('exMonoOccurrence', MonoOccurrenceFactory());

function MonoOccurrenceFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.FORMULAIRE,
            Binding.MONO_OCCURRENCE_OPTIONS.OPTIONAL,
            {
                monoOccurrence: '=?monoOccurrenceOut',
                data: '<?'
            }
        ),
        require: {
            multiOccurrenceCtrl: '^^?exMultiOccurrence',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: true,
        controller: MonoOccurrenceController,
        controllerAs: 'vm',
        template: require('./ex-mono-occurrence.html')
    };
}
