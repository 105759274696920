import {IComponentController} from 'angular';
import IDialogOptions = angular.material.IDialogOptions;
import IResourceService = angular.resource.IResourceService;

import {IApiConfig} from '../../../core/interfaces/api-config.interface';
import {IDialog} from '../../services/dialog.service';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IDataType} from '../../services/data-types/data-type.service';
import {IComposantMultiOccurrence} from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IParametresSecuriteService} from '../../services/parametres-securite.service';
import {IComposantMonoOccurrence} from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import {IOccurrence} from '../../services/occurrence.service';
import {IMonoOccurrenceClass} from '../../services/mono-occurrence.service';
import {IMultiOccurrence} from '../../services/multi-occurrence.service';

interface IComposantFavoris extends IComponentController {
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    monoOccurrenceCtrl: IComposantMonoOccurrence
    occurrence: IOccurrence
    ecranContextCtrl: IEcranContextController;
    favorisActif: boolean;
    iconActif: string;
    iconInactif: string;
    data: any;
    dataType: IDataType;
    resource: any;
    onClick(): void;
    nomSourceDetails: string;
}

/* @ngInject */
export default function FavorisController(ApiConfig: IApiConfig,
                                          defaults: IDefaultsService,
                                          $resource: IResourceService,
                                          DialogConfirm: IDialog,
                                          MonoOccurrence: IMonoOccurrenceClass,
                                          parametresSecurite: IParametresSecuriteService) {
    const vm: IComposantFavoris = this;

    vm.$onInit = $onInit;
    vm.onClick = onClick;

    function $onInit() {
        defaults(vm, {
            favorisActif: Boolean(vm.data.fvicleint),
            occurrence: ((vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence) || (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence)),
            Resource: $resource(ApiConfig.ROOT + '/gsfavoris/:id', {
                id: '@fvicleint',
                ...getSecurityResourceParams()
            })
        });

        if (vm.dataType) {
            vm.iconActif = vm.dataType.params.iconActif;
            vm.iconInactif = vm.dataType.params.iconInactif;
        } else {
            vm.iconActif = 'favorite';
            vm.iconInactif = 'favorite_border';
        }
    
        vm.resource = new vm.Resource({
            fvicleintref: vm.data.fvicleintref || vm.data[vm.dataType.params.id],
            fvicleint: vm.data.fvicleint
        });
    }

    function onClick(): void {
        executeAction()
            .catch((error: any) => {
                getDialogErreur(error);
            });
    }

    function executeAction(): Promise<any> {
        if (vm.favorisActif) {
            return vm.resource.$delete()
                .then(() => {
                    if (vm.dataType && vm.dataType.params.fetchOnChange) {
                        fetchData();
                    } else {
                        delete vm.resource.fvicleint;
                        vm.data.fvicleint = undefined;
                        vm.favorisActif = false;
                    }
                });
        } else {
            return vm.resource.$save()
                .then((result: any) => {
                    if (vm.dataType && vm.dataType.params.fetchOnChange) {
                        fetchData();
                    } else {
                        vm.data.fvicleint = result.fvicleint;
                        vm.favorisActif = true;
                    }
                });
        }
    }

    function getDialogErreur(error: any): void {
        const dialogOptions: IDialogOptions = {
            locals: {
                error,
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: 'G_MSG_ERREUR_ACTION',
                ecranContext: vm.ecranContextCtrl,
                confirmAction: () => {
                    return executeAction();
                }
            }
        };
        DialogConfirm.show(dialogOptions);
    }

    function getSecurityResourceParams() {
        return {
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            srccodref: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };
    }

    function fetchData() {
        if (vm.occurrence instanceof MonoOccurrence) {
            vm.occurrence.fetchData();
        } else {
            (<IMultiOccurrence>vm.occurrence).fetchDataList();
        }
    }
}
