import {IComponentController, IPromise, ITimeoutService} from 'angular';

import {ISelecteur} from '../../services/selecteur.service';
import {IOperateur, IOperateurService} from '../../services/operateur.service';
import {IMultiOccurrence} from '../../services/multi-occurrence.service';
import {IFiltreClass} from '../../services/filtre.service';
import {ILovDataTypeClass} from '../../services/data-types/lov-data-type.service';
import {IImputationDataTypeClass} from '../../services/data-types/imputation-data-type.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {ILovBoutonRadioDataTypeClass} from '../../services/data-types/lov-bouton-radio-data-type.service';
import {ILovEntiteExterneDataTypeClass} from "../../services/data-types/lov-entite-externe-data-type.service";
import { formatStringDateTime } from '../../utils/data-time.utils';

interface IDialogFiltre extends IComponentController {
    colonne: string;
    operateur: IOperateur;
    data: any;
    valeurs: Array<any>;
    autresValeurs: any;
    radioValeur: any;
    selecteur: ISelecteur;
    multiOccurrence: IMultiOccurrence;
    isCritereSuggere: boolean;
    lblTitre: string;
    confirm(): IPromise<IDialogFiltreResultats>;
}

export interface IDialogFiltreOptions {
    colonne: string;
    required?: boolean;
    selecteur?: ISelecteur,
    operateur?: IOperateur;
    data?: any;
    valeurs?: Array<any>;
    radioValeur?: any;
    colonneModifiable?: boolean;
    operateurModifiable?: boolean;
    isCritereSuggere?: boolean;
    lblTitre?: string;
    multiOccurrence: IMultiOccurrence;
    ecranContext: IEcranContextController;
}

export interface IDialogFiltreResultats {
    colonne: string;
    data: any;
    operateur?: IOperateur;
    valeurs?: Array<any>;
    autresValeurs: any;
}

/* @ngInject */
export default function DialogFiltreController(LovDataType: ILovDataTypeClass,
                                               LovEntiteExterneDataType: ILovEntiteExterneDataTypeClass,
                                               LovBoutonRadioDataType: ILovBoutonRadioDataTypeClass,
                                               ImputationDataType: IImputationDataTypeClass,
                                               Filtre: IFiltreClass,
                                               Operateur: IOperateurService,
                                               $timeout: ITimeoutService) {
    const vm: IDialogFiltre = this;

    vm.$onInit = function $onInit() {
        vm.data = Object.assign({}, vm.data || {});
        vm.lblTitre = vm.lblTitre || (vm.isCritereSuggere ? 'G_LBL_CRITERE_SUGG' : 'G_LBL_MSL_MOD_FILTRE_TITRE');
        vm.confirm = confirm;

        initFiltreValeurs();
    }

    function initFiltreValeurs() {
        if (vm.multiOccurrence.isBooleanDataType(vm.colonne)) {
            // S'il y a un opérateur, c'est un filtre
            if (vm.operateur) {
                if (vm.valeurs[0] === '') {
                    vm.radioValeur = Filtre.BOOLEAN_VALUES.TOUS;
                } else if (vm.operateur === Operateur.EGALE) {
                    vm.radioValeur = Filtre.BOOLEAN_VALUES.OUI;
                } else {
                    vm.radioValeur = Filtre.BOOLEAN_VALUES.NON;
                }
            } else {
                const valeur = (vm.valeurs[0] === 1) ?
                    Filtre.BOOLEAN_VALUES.OUI :
                    Filtre.BOOLEAN_VALUES.NON;
                vm.radioValeur = valeur;
            }
        }
    }

    function confirm(): IPromise<IDialogFiltreResultats> {
        const schemaItem = vm.multiOccurrence.getSchemaItem(vm.colonne);

        // Certains champs doivent avoir le temps de se mettre à jour avant de fermer la modale.
        return $timeout(() => {
            let operateur = vm.operateur;
            let [valeur, valeur2] = vm.valeurs || [null, null];

            if (vm.multiOccurrence.isBooleanDataType(vm.colonne)) {
                // S'il y a un opérateur, c'est un filtre
                if (operateur) {
                    switch (vm.radioValeur) {
                        case Filtre.BOOLEAN_VALUES.TOUS:
                            valeur = '';
                            operateur = Operateur.EGALE;
                            break;
                        case Filtre.BOOLEAN_VALUES.OUI:
                            valeur = '1';
                            operateur = Operateur.EGALE;
                            break;
                        case Filtre.BOOLEAN_VALUES.NON:
                            valeur = '1';
                            operateur = Operateur.EGALE_PAS;
                            break;
                    }
                } else {
                    switch (vm.radioValeur) {
                        case Filtre.BOOLEAN_VALUES.OUI:
                            valeur = 1;
                            break;
                        case Filtre.BOOLEAN_VALUES.NON:
                            valeur = 0;
                            break;
                    }
                }
            } else if (vm.multiOccurrence.dataTypes[vm.colonne] instanceof LovBoutonRadioDataType) {
                valeur = vm.data[vm.colonne];
                valeur2 = null;
            }  else if (vm.operateur && vm.operateur.isAucuneValeurOperateur()) {
                valeur = null;
                valeur2 = null;
            } else if (vm.operateur && !vm.operateur.isValeurDoubleOperateur()) {
                valeur2 = null;
            }

            if (schemaItem && schemaItem.type === 'date' && operateur && operateur.nom === 'CONTIENT') {
                valeur = formatStringDateTime(valeur);
            }

            const data = hasData()
                ? vm.data
                : {};

            return {
                colonne: vm.colonne,
                data,
                operateur,
                valeurs: [valeur, valeur2],
                autresValeurs: vm.autresValeurs
            };
        }, 100);
    }

    function hasData() {
        return (
            (
                vm.multiOccurrence.dataTypes[vm.colonne] instanceof LovDataType ||
                vm.multiOccurrence.dataTypes[vm.colonne] instanceof LovEntiteExterneDataType ||
                vm.multiOccurrence.dataTypes[vm.colonne] instanceof LovBoutonRadioDataType ||
                vm.multiOccurrence.dataTypes[vm.colonne] instanceof ImputationDataType
            ) &&
            (!vm.operateur || vm.operateur.isRechercheExacteOperateur())
        );
    }
}
