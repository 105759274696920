import {IFormulaireItem, IFormulaireItemClass} from "../../services/formulaire/formulaire-item.service";
import {IProfil} from "../../resources/profil.resource";
import {IParametresSecuriteService} from "../../services/parametres-securite.service";
import {IEcranDetailsResourcesEntite} from "../../resources/ecran-details.resource";
import { ITexteDataTypeClass } from "../../services/data-types/texte-data-type.service";
import { IHttpService, IHttpResponse } from "angular";
import { IApiConfig } from "../../interfaces/api-config.interface";

interface IDialogSelectionIntervention {
    data: any;
    lblTitre: string;

    /** 
     * Erreur si nécessaire
     */
    lblMessageErreur: string;

    /** 
     * Erreur si numéro d'intervention non valide après confirmation
     */
    messageErreurInterventionNonValide: string;
    champ: IFormulaireItem;
    confirm(): any;
}
/* @ngInject */
export default function DialogSelectionInterventionController(FormulaireItem: IFormulaireItemClass,
                                                              TexteDataType: ITexteDataTypeClass,
                                                              $stateParams: any,
                                                              ecranDetails: IEcranDetailsResourcesEntite,
                                                              parametresSecurite: IParametresSecuriteService,
                                                              profil: IProfil,
                                                              $http: IHttpService,
                                                              ApiConfig: IApiConfig) {
    const vm: IDialogSelectionIntervention = this;
    
    const OPTIONS_CHAMPS = {
        required: true,
        titre: 'G_LBL_SELECTION_INTERVENTION',
        dataType: new TexteDataType(),
        resourceParams: {
            usrcleint: profil.preferences.usrcleint,
            srccodref: $stateParams.srccod,
            ...parametresSecurite($stateParams, ecranDetails)
        }
    };

    //init
    vm.confirm = confirm;

    vm.champ = new FormulaireItem('itvnum', OPTIONS_CHAMPS);

    function confirm() {
        return $http.get(`${ApiConfig.ROOT}/gsintervention?itvnum=${vm.data.itvnum}`).then((result: IHttpResponse<{ itvcleint: number }>) => {
            profil.preferences.itvcleint = result.data.itvcleint;
            profil.preferences.$update().catch(() => {
                // On ignore en cas d'erreur
            });
            return Object.keys(vm.data).reduce((data: any, col: string) => {
                if (col.includes('__')) {
                    const colDesc = col.split('__').pop();
                    data[colDesc] = vm.data[col];
                } else {
                    data[col] = vm.data[col];
                }
    
                return data;
            }, {});
        }).catch((err) => {
            vm.messageErreurInterventionNonValide = err.data.message;

            //Permet de garder la modale ouverte en cas d'erreur
            return Promise.reject(err);
        });
    }
}
