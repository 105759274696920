import {IExAugmentedEvent} from '../../interfaces/event.interface';
import {IComponentController} from 'angular';
import {IComposantMonoOccurrenceEcran} from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';

interface IComposantAccordeon extends IComponentController {
    libelles: any;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    preventExpandableAction(event: IExAugmentedEvent): void;
}

/* @ngInject */
export default function AccordeonController() {
    const vm: IComposantAccordeon = this;

    vm.$onInit = $onInit;
    vm.preventExpandableAction = preventExpandableAction;

    function $onInit() {
        vm.libelles = vm.libelles || (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles);
    }

    function preventExpandableAction(event: IExAugmentedEvent): void {
        event.originalEvent.exExpandablePreventAction = true;
    }
    // function blockExpandableIfNeeded(event)
}
