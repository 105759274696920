import {module} from 'angular';


export interface IFormulaireItemDividerClass {
    new(titre?: string, options?: IFormulaireItemDividerOptions): IFormulaireItemDivider;
    new(options: IFormulaireItemDividerOptions): IFormulaireItemDivider;
}

export interface IFormulaireItemDivider {
    readonly titre: string;
    readonly hidden?: boolean | IFormulaireItemDividerHiddenFonction;
}

export interface IFormulaireItemDividerOptions {
    hidden?: boolean | IFormulaireItemDividerHiddenFonction;
}

interface IFormulaireItemDividerHiddenFonction {
    (data: any): boolean;
}

export default module('core.services.formulaire-divider', []).factory('FormulaireItemDivider', FormulaireItemDividerFactory);

/* @ngInject */
function FormulaireItemDividerFactory() {


    class FormulaireItemDivider implements IFormulaireItemDivider {
        readonly titre: string;
        readonly hidden: boolean | IFormulaireItemDividerHiddenFonction;

        constructor(titre?: string, options?: IFormulaireItemDividerOptions) {
            titre = (typeof arguments[0] === 'string') ? arguments[0] : null;
            options = ((typeof arguments[0] === 'object') ? arguments[0] : arguments[1]) || {};

            this.titre = titre;
            this.hidden = options.hidden;
        }
    }
    return FormulaireItemDivider;
}
