import {module} from 'angular';
import NavigationMenuController from './ex-navigation-menu.controller';

export default module('app.components.ex-navigation-menu', []).component('exNavigationMenu', {
    bindings: {
        profilsAccesBase: '<',
        profilsAcces: '=?profilsAffiches',
        navFilter: '=?rechercheRapide',
        afficheAccueil: '<?',
        value: '=?rechercheRapide',
        navigationOpened: "=?"
    },
    transclude: {
        content: '?exNavigationMenuContent'
    },
    template: require('./ex-navigation-menu.html'),
    controller: NavigationMenuController,
    controllerAs: 'vm'
});
