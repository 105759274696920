import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import BoutonImputationController from './ex-bouton-imputation.controller';

export default module('app.components.ex-bouton-imputation', []).component('exBoutonImputation', BoutonImputationFactory());

function BoutonImputationFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DISABLED,
            Binding.DATA,
            Binding.ICON,
            Binding.DATA_TYPE,
            {
                srccodref: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: BoutonImputationController,
        template: require('./ex-bouton-imputation.html')
    };
}
