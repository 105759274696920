import {module} from 'angular';
import {IOperateur, IOperateurService} from '../services/operateur.service';
import {IFilterLibelle} from './ex-libelle.filter';

export default module('core.filters.ex-oui-non-tous', ['core.filters.ex-libelle']).filter('exOuiNonTous', ouiNonTousFilter);

export interface IFilterExOuiNontous {
    (value: string, operateur: IOperateur): any;
}

/* @ngInject */
function ouiNonTousFilter(exLibelleFilter: IFilterLibelle, Operateur: IOperateurService): IFilterExOuiNontous {
    const oui = 'G_LBL_OUI';
    const non = 'G_LBL_NON';
    const tous = 'G_LBL_TOUS';

    return (value: string, operateur: IOperateur): any => exLibelleFilter(getLibelle(value, operateur));

    function getLibelle(value: string, operateur: IOperateur): string {
        if (value === '1') {
            if (operateur === Operateur.EGALE_PAS) {
                return non;
            } else {
                return oui;
            }
        }
        return tous;
    }
}
