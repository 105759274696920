import { IAugmentedJQuery, IComponentController, IFormController, IPromise, IQService, IScope, ITimeoutService } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { ILovDataTypeClass } from '../../services/data-types/lov-data-type.service';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IDataTypeMap } from '../../services/data-types/data-type.service';
import { ILovParamsImputation } from '../../components/ex-input-imputation/ex-input-imputation.controller';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IErrorTypes } from '../../constants/error-types.constant';

export interface IDialogImputation extends IComponentController {
    stateParams: any;
    lblTitre: string;
    icon: string;
    data: IDataImputation;
    formCtrl: IFormController;
    lovsParams: Array<ILovParamsImputation>;
    messageErreur: string;
    dataTypes: IDataTypeMap;
    resourceParams: any;
    isCritereRecherche: boolean;
    loading: boolean;
    loadingError: boolean;
    srccodref: string;
    initialized: boolean;
    lovsInitialized: boolean;
    indsai: number;
    col: string;
    restrictions: { [code: string]: IImputationRestrictions };
    isFormReady(): boolean;
    isLovDisabled(col: string): boolean;
    isLovObligatoire(col: string): boolean;
    init(): void;
    confirm(): any;
    getNombreLibellesDetailCachePourChamp(champs: ILovParamsImputation): number | Array<number> | null;
}

interface IImputationRestrictions {
    flgsai: boolean;
    flgobl: boolean;
}

export interface IDataImputation {
    [index: string]: number | string;
}

interface IDetailsImputation {
    [col: string]: Array<string>;
}

interface INombreLibelleCache {
    [col: string]: number;
}

interface IValidationImputationOptions {
    cle?: string;
}

/* @ngInject */
export default function DialogImputationController(defaults: IDefaultsService,
    LovDataType: ILovDataTypeClass,
    exFocus: IFocusService,
    $scope: IScope,
    $element: IAugmentedJQuery,
    $timeout: ITimeoutService,
    $resource: ng.resource.IResourceService,
    ApiConfig: IApiConfig,
    ErrorTypes: IErrorTypes,
    $q: IQService,
    parametresSecurite: IParametresSecuriteService) {
    const vm: IDialogImputation = this;
    const LBL_TITRE = 'G_LBL_IMPUTATION';
    const lblConfirm = 'G_LBL_BTN_APPLIQUER';
    const lblCancel = 'G_LBL_BTN_ANNULER';
    const restrictionPrs = { prucleint: 'prucleint' };
    const CHAMPS_RESTRICTION_SAISIE = ['prs', 'cpt', 'una', 'el1', 'el2', 'el3'];
    const DETAILS: IDetailsImputation = {
        prucleint: ['prudatfin', 'flgreq', 'intcod', 'vecprucatpro', 'cifcod', 'unacod'],
        prscleint: ['intcod'],
        cptcleint: ['nacson', 'cptdatina'],
        unacleint: ['grpcod', 'intcod', 'foncod', 'flgreq'],
        el1cleint: ['el1dscabr', 'el1datdeb', 'el1datfin'],
        el2cleint: ['el2dscabr', 'el2datdeb', 'el2datfin'],
        el3cleint: ['el3dscabr', 'el3datdeb', 'el3datfin']
    };

    vm.$onInit = function $onInit() {
        const NOMBRE_LIBELLE_CACHE_PER_CHAMPS: INombreLibelleCache = {
            cptcleint: 1
        };
        const RESTRICTIONS_DEFAUT = {
            flgsai: 1,
            flgobl: !vm.isCritereRecherche
        };

        vm.isFormReady = isFormReady;
        vm.isLovDisabled = isLovDisabled;
        vm.isLovObligatoire = isLovObligatoire;
        vm.init = init;
        vm.confirm = confirm;
        vm.getNombreLibellesDetailCachePourChamp = getNombreLibellesDetailCachePourChamp;

        defaults(vm, {
            lblTitre: LBL_TITRE,
            lblConfirm: lblConfirm,
            lblCancel: lblCancel,
            loadingError: false,
            loading: true,
            data: {},
            initialized: false,
            lovsInitialized: false
        });

        init();

        vm.dataTypes = vm.lovsParams.reduce((dataTypes: IDataTypeMap, lovParams) => {
            dataTypes[lovParams.col] = new LovDataType({
                schemaItem: {},
                params: {
                    source: `imp${lovParams.code}`,
                    description: [`${lovParams.code}cod`, `${lovParams.code}dsc`],
                    details: DETAILS[lovParams.col],
                    restrictions: (lovParams.code === 'prs') ? restrictionPrs : {}
                }
            });
            return dataTypes;
        }, {});

        $scope.$watch('vm.data.prucleint', (newPrucleint, oldPrucleint) => {
            if (!newPrucleint) {
                delete vm.resourceParams.prucleint;
            } else if (newPrucleint !== oldPrucleint) {
                /*
                On vide nous-mêmes les LOVS qui ont une restriction de saisie puisque leur valeur ne fait peut-être pas partie des données.
                */
                CHAMPS_RESTRICTION_SAISIE.forEach((champ) => {
                    delete vm.data[`${champ}cleint`];
                    delete vm.data[`${champ}cleint__${champ}cod`];
                    delete vm.data[`${champ}cleint__${champ}dsc`];
                });
                vm.resourceParams.prucleint = newPrucleint;
            }
            else {
                vm.resourceParams.prucleint = newPrucleint;
            }
        });

        $scope.$watch('vm.data.prscleint', (newPrscleint, oldPrscleint) => {
            if (!newPrscleint) {
                delete vm.resourceParams.prscleint;
            } else if (newPrscleint !== oldPrscleint) {
                vm.resourceParams.prscleint = newPrscleint;
            }
            else {
                vm.resourceParams.prscleint = newPrscleint;
            }
        });

        $scope.$watch('vm.data.cptcleint', (newCptcleint, oldCptcleint) => {
            if (!newCptcleint) {
                delete vm.resourceParams.cptcleint;
            } else if (newCptcleint !== oldCptcleint) {
                vm.resourceParams.cptcleint = newCptcleint;
            }
            else {
                vm.resourceParams.cptcleint = newCptcleint;
            }
        });

        $scope.$watchCollection(
            () => vm.lovsParams.map((lov: ILovParamsImputation) => vm.data[lov.col]),
            (newValues, oldValues) => {
                const modifiedLovs = newValues
                    .map((newValue: number, index: number) => {
                        if (newValue !== oldValues[index] && (typeof newValue !== 'undefined' || oldValues[index] !== null)) {
                            return vm.lovsParams[index];
                        }
                    })
                    //On retire les valeurs qui n'ont pas changé (qui seront à undefined après le map)
                    .filter((hasChanged: ILovParamsImputation) => !!hasChanged);

                modifiedLovs.forEach((lov: ILovParamsImputation) => {
                    return validateData({ cle: lov.code })
                        .then(() => {
                            vm.formCtrl['lovsImputations'][lov.col].$setValidity('validateApi', true);
                        })
                        .catch((err) => {
                            if (err.data && err.data.code === 'SOFE-20004') {
                                vm.formCtrl['lovsImputations'][lov.col].$setValidity('validateApi', false);
                                vm.formCtrl['lovsImputations'][lov.col]['validateApi'] = err.data.message;
                                vm.formCtrl['lovsImputations'][lov.col]['$$SofeValidationMessage'] = err.data.message;
                                vm.formCtrl['lovsImputations'][lov.col]['$$SofeValidationType'] = ErrorTypes.ERREUR;
                            }
                        });
                })
            }
        );

        const removeListener = $scope.$watch('vm.isFormReady()', (isReady) => {
            if (isReady) {
                exFocus($element);
                // Dans la modale d'imputation, pour s'assurer de voir la description de la première LOV,
                // on positionne le focus sur le bouton pour fermer la modale lorsqu'on a des données au chargement.
                if (vm.lovsParams.some(lov => typeof vm.data[lov.col] !== 'undefined' && vm.data[lov.col] !== null)) {
                    $timeout(() => {
                        exFocus($element, { types: ['button.ex-dialog-md-toolbar-tools-close'] });
                    });
                }
                removeListener();
            }
        });

        function isFormReady(): boolean {
            if (vm.loading) {
                return false;
            }

            //On s'assure que toutes les données sont chargées dans les lovs où on a la cleint.
            return vm.lovsParams.every((lov) => {
                return Boolean(!vm.data[lov.col] ||
                    vm.data[lov.col] && vm.data[`${lov.col}__${lov.code}cod`]);
            });
        }

        function isLovDisabled(col: string) {
            const code = col.split('cleint')[0];
            return Boolean(vm.restrictions && vm.restrictions[code] && !vm.restrictions[code].flgsai);
        }

        function isLovObligatoire(col: string) {
            const code = col.split('cleint')[0];
            return Boolean(vm.restrictions && vm.restrictions[code] && vm.restrictions[code].flgobl);
        }

        function confirm(): IPromise<any> {
            return validateData()
                .catch((erreur: any) => {
                    if (erreur && erreur.data && erreur.data.code === 'SOFE-20004') {
                        vm.messageErreur = erreur.data.message;
                    }

                    exFocus($element);

                    //Pour empêcher la modale de se refermer, on laisse continuer l'erreur
                    throw erreur;
                });
        }

        function init() {
            // Si on est dans des critères de recherche, l'indicateur de saisie doit absolument être à 2
            vm.indsai = (vm.isCritereRecherche) ? 2 : vm.indsai;
            vm.loading = true;

            // Dans le cas où on utilise l'imputation dans un dialog-ecran, il faut ajouter
            // nous-même les paramètres de sécurité.
            Object.assign(vm.resourceParams, parametresSecurite(vm.stateParams));

            prepareLovs()
                .then(() => {
                    vm.initialized = true;
                    vm.loadingError = false;
                })
                .catch(() => {
                    vm.loadingError = true;
                })
                .finally(() => {
                    vm.loading = false;
                });
        }

        function prepareLovs(): IPromise<void> {
            const params = Object.keys(vm.data).reduce((result: any, key: string) => {
                if (!key.startsWith('$')) {
                    result[key] = vm.data[key];
                }
                return result;
            }, {});

            params.col = vm.col;
            params.srccod = vm.srccodref;

            const requete = $resource(`${ApiConfig.ROOT}/actions-imputation/prepare-lovs/:col`, {
                ...vm.resourceParams,
                ...params,
                ...parametresSecurite(vm.stateParams)
            });

            if (vm.lovsInitialized) {
                return $q.resolve();
            } else {
                return requete.get().$promise
                    .then((data) => {
                        if (data.restrictions) {
                            vm.restrictions = Object.keys(data.restrictions).reduce((restrictions: any, code: string) => {
                                let restrictionCpt: any = {};
                                if (code === 'cpt' && vm.indsai === 5) {
                                    //l'indicateur de saisie 5 désactive la saisie du compte.
                                    restrictionCpt = { flgobl: 0, flgsai: 0 };
                                }

                                if (vm.isCritereRecherche) {
                                    //En mode critères suggérés, on permet la saisie de tous les champs et aucun n'est "obligatoire"
                                    restrictions[code] = { flgsai: 1, flgobl: 0 };
                                } else {
                                    restrictions[code] = { ...RESTRICTIONS_DEFAUT, ...data.restrictions[code], ...restrictionCpt };
                                }
                                return restrictions;
                            }, {});

                            delete data.restrictions;
                        }

                        Object.assign(vm.data, data);
                        vm.lovsInitialized = true;
                    });
            }
        }

        function validateData(options: IValidationImputationOptions = {}): IPromise<any> {
            const data = Object.keys(vm.data).reduce((data: IDataImputation, col: string) => {
                if (vm.dataTypes[col] && !vm.isLovDisabled(col)) {
                    data[col] = vm.data[col];
                }
                return data;
            }, {});

            Object.assign(data, { indsai: (vm.isCritereRecherche) ? 2 : vm.indsai, usicleint: vm.data.usicleint }, options);

            const validate = $resource(`${ApiConfig.ROOT}/actions-imputation/validate/:col/:cle?`, {
                ...vm.resourceParams,
                ...data,
                col: vm.col,
                srccod: vm.srccodref,
                ...parametresSecurite(vm.stateParams)
            });

            vm.messageErreur = '';

            return validate.get().$promise;
        }

        function getNombreLibellesDetailCachePourChamp(champ: ILovParamsImputation): number | Array<number> | null {
            return typeof NOMBRE_LIBELLE_CACHE_PER_CHAMPS[champ.col] !== 'undefined' ? NOMBRE_LIBELLE_CACHE_PER_CHAMPS[champ.col] : null;
        }
    }
}
