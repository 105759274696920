/**
 * Composant représentant un formulaire
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import FormulaireController from './ex-formulaire.controller';

export default module('core.components.ex-formulaire', []).component('exFormulaire', FormulaireFactory());

function FormulaireFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.FORMULAIRE.OPTIONAL,
            Binding.OCCURRENCE.OPTIONAL,
            Binding.DATA.OPTIONAL,
            {
                messagesErreur: '<?',
                name: '@?',
                formCtrl: '=?',
                // Permet d'exposer les données clonées
                formData: '=?',
                enregistrable: '<?',
                typesFichiers: '<?',
                fichier: '=?',
                customFormulaire: '<?',
                indexFormulaire: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext',
            criteresSuggeresCtrl: '^^?exCriteresSuggeres',
            plaquettesFonctionsTransversalesCtrl: '^^?exPlaquettesFonctionsTransversales',
            fonctionsTransversalesCtrl: '^^?exFonctionsTransversales'
        },
        transclude: true,
        controller: FormulaireController,
        controllerAs: 'vm',
        template: require('./ex-formulaire.html')
    };
}
