import {module} from 'angular';
import {IFormulaire} from './formulaire.service';

export interface IFormulaireGroupeAccordeonClass {
    new(lblTitre: string, formulaire: IFormulaire, options?: IFormulaireGroupeAccordeonOptions): IFormulaireGroupeAccordeon;
}

export interface IFormulaireGroupeAccordeonOpenedFonction {
    (data: any): boolean;
}

export interface IFormulaireGroupeAccordeonHiddenFonction {
    (data: any): boolean;
}

export interface IFormulaireGroupeAccordeonDisabledFonction {
    (data: any): boolean;
}

export interface IFormulaireGroupeAccordeon {
    readonly lblTitre: string;
    readonly formulaire: IFormulaire;
    readonly largeur: number;
    readonly opened: boolean | IFormulaireGroupeAccordeonOpenedFonction;
    readonly hidden: boolean | IFormulaireGroupeAccordeonHiddenFonction;
    readonly disabled: boolean | IFormulaireGroupeAccordeonDisabledFonction;
}

export interface IFormulaireGroupeAccordeonOptions {
    readonly opened?: boolean | IFormulaireGroupeAccordeonOpenedFonction;
    readonly hidden?: boolean | IFormulaireGroupeAccordeonHiddenFonction;
    readonly disabled?: boolean | IFormulaireGroupeAccordeonDisabledFonction;
    readonly largeur?: number;
}

export default module('core.services.formulaire-groupe-accordeon', []).factory('FormulaireGroupeAccordeon', FormulaireGroupeAccordeonFactory);

/* @ngInject */
function FormulaireGroupeAccordeonFactory() {

    class FormulaireGroupeAccordeon implements IFormulaireGroupeAccordeon {

        readonly lblTitre: string;
        readonly formulaire: IFormulaire;
        readonly largeur: number;
        readonly opened: boolean | IFormulaireGroupeAccordeonOpenedFonction;
        readonly hidden: boolean | IFormulaireGroupeAccordeonHiddenFonction = false;
        readonly disabled: boolean | IFormulaireGroupeAccordeonDisabledFonction = false;

        constructor(lblTitre: string, formulaire: IFormulaire, options: IFormulaireGroupeAccordeonOptions = {}) {
            this.lblTitre = lblTitre;
            this.formulaire = formulaire;
            this.largeur = 100;

            if (typeof options.opened !== 'undefined') {
                this.opened = options.opened;
            }

            if (typeof options.hidden !== 'undefined') {
                this.hidden = options.hidden;
            }

            if (typeof options.disabled !== 'undefined') {
                this.disabled = options.disabled;
            }
            if (typeof options.largeur !== 'undefined') {
                this.largeur = options.largeur || 100;
            }
        }
    }
    return FormulaireGroupeAccordeon;
}
