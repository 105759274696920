

import {IAttributes, IAugmentedJQuery, INgModelController, IScope, module} from 'angular';

export default module('core.behaviors.ex-masque', [])
    .directive('exMasque', MasqueDirective)
    .filter('exMasque', MasqueFilter);

export interface IFilterMasque {
    (value: string, masque: string, demasque?: boolean): any;
}

interface IMasqueAttributes extends IAttributes {
    exMasque: string; 
}

function MasqueFilter(): IFilterMasque {
    return (value: string, masque: string, demasque: boolean = false) => {
        if (!masque) {
            return value;
        }
        if (demasque) {
            return demasquerValeur(value || "");
        } else {
            return masquerValeur(demasquerValeur(value || ""), masque);
        }
    };
}

function MasqueDirective() {
    return {
        restrict: 'A',
        link,
        require: 'ngModel'
    };

    function link($scope: IScope, tElement: IAugmentedJQuery, attrs: IMasqueAttributes, controller: INgModelController) {
        let currentMasque: string;

        attrs.$observe('exMasque', (masque: string) => {
            if (masque) { 
                controller.$parsers.unshift(demasquerValeur);
                controller.$formatters.unshift((modelValue: string) => masquerValeur(demasquerValeur(modelValue || ''), masque));
            } else if (currentMasque) {
                const parserIndex = controller.$parsers.indexOf(demasquerValeur)
                if (parserIndex > -1) {
                    controller.$parsers.splice(parserIndex, 1);
                }

                const formatterIndex = controller.$formatters.indexOf(demasquerValeur)
                if (formatterIndex > -1) {
                    controller.$formatters.splice(formatterIndex, 1);
                }
            }
            currentMasque = masque;
        });
    }
}

function masquerValeur(value: string, masque: string): string {
    if (!value) return value;

    let valeurMasquer = '';
    for (const char of masque) {
        if (value.length && char === 'X' || char === '9') {
            valeurMasquer += value.charAt(0) || '';
            value = value.substr(1);
        } else {
            valeurMasquer += char;
        }
    }
    
    return valeurMasquer;
}

export function demasquerValeur(value: string): string {
    return value.replace(/ /g, '');
}
