import {IAttributes, IAugmentedJQuery, IQService, IScope, module} from 'angular';
import {IComposantTabs} from '../ex-tabs/ex-tabs.controller';
import TabController, {IComposantTab} from './ex-tab.controller';

interface scopeComposant extends IScope {
    vm: IComposantTab;
}

export default module('core.components.ex-tab', []).directive('exTab', TabFactory);

/* @ngInject */
function TabFactory($q: IQService) {
    return {
        scope: {
            lblTitre: '@',
            icon: '@',
            iconTitre: '<?',
            initAction: '&?',
            show: '<ngShow',
            index: '=?',
            bloc: '@?'
        },
        bindToController: true,
        restrict: 'EA',
        require: '^^exTabs',
        transclude: true,
        link,
        controllerAs: 'vm',
        controller: TabController,
        template: require('./ex-tab.html')
    };


    function link(scope: scopeComposant, element: IAugmentedJQuery, attrs: IAttributes, exTabsCtrl: IComposantTabs) {
        scope.vm.exTabsCtrl = exTabsCtrl;
        // Les tabs material ne peuvent être modifié une fois affiché. Il faut qu'on attende que tous les tabs
        // soient prêt
        scope.vm.index = exTabsCtrl.register($q((resolve) => {
            if (attrs.ngShow) {
                scope.$watch('::vm.show', (show: boolean) => {
                    resolveTab(show);
                });
            } else {
                resolve(scope.vm);
            }

            function resolveTab(show: boolean) {
                // Si c'est undefined, on attend
                if (Boolean(show) === true) {
                    resolve(scope.vm);
                } else if (show === false) {
                    resolve(null);
                }
            }
        }));
    }
}
