import {IComponentController, IScope} from 'angular';
import {IDataType} from '../../services/data-types/data-type.service';
import {IDataTypeManager} from '../../services/data-types/data-type-manager.service';
import {ISchemas} from '../../interfaces/schemas.interface';
import {IComposantMultiOccurrence} from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import {IComposantFormulaire} from '../ex-formulaire/ex-formulaire.controller';
import {IFormulaireItemClass} from '../../services/formulaire/formulaire-item.service';
import {IData} from '../../services/data-linker.service';

interface IComposantCaseEditionAssistee extends IComponentController {
    col: string;
    data: IData;
    dataType: IDataType;
    dataTypeEdition: IDataType;
    schemas: ISchemas;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    formulaireCtrl: IComposantFormulaire;
}

/* @ngInject */
export default function CaseEditionAssisteeController(dataTypeManager: IDataTypeManager,
                                                      FormulaireItem: IFormulaireItemClass,
                                                      $scope: IScope) {
    const vm: IComposantCaseEditionAssistee = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    function $onInit() {
        vm.dataTypeEdition = dataTypeManager.fromJson({[vm.col]: vm.dataType.params.dataTypeEdition}, vm.schemas)[vm.col];

        $scope.$watch('vm.data[vm.col]', (newValue: any, oldValue: any) => {
            if (newValue !== oldValue) {
                //On doit indiquer qu'une valeur a changé.
                vm.multiOccurrenceCtrl.multiOccurrence.emit('rowSelectionChange', vm.data[vm.formulaireCtrl.occurrence.cleint]);
            }
        });

        $scope.$watch('::vm.formulaireCtrl.formCtrl[vm.col]', () => {
            if (vm.formulaireCtrl.formCtrl[vm.col]) {
                vm.formulaireCtrl.formCtrl[vm.col].$validators.required = function (modelValue: any, viewValue: any) {
                    return Boolean(
                        //On ne valide que dans les rangées sélectionnées.
                        !vm.data[vm.multiOccurrenceCtrl.selectionColonne] ||
                        !vm.formulaireCtrl.occurrence.isChampRequired(new FormulaireItem(vm.col), vm.data) ||
                        !vm.formulaireCtrl.formCtrl[vm.col].$isEmpty(viewValue)

                    );
                };
                vm.formulaireCtrl.formCtrl[vm.col].$validate();

                vm.multiOccurrenceCtrl.multiOccurrence.on('rowSelectionChange', onRowSelectionChange)
            }
        });
    }

    function $onDestroy() {
        vm.multiOccurrenceCtrl.multiOccurrence.removeListener('rowSelectionChange', onRowSelectionChange)
    }

    function onRowSelectionChange(id: number) {
        if (vm.data[vm.multiOccurrenceCtrl.multiOccurrence.cleint] === id) {
            if (vm.data[vm.col] === null && typeof vm.dataType.params.defaultOnSelection !== 'undefined') {
                if (vm.data[vm.multiOccurrenceCtrl.selectionColonne]) {
                    vm.formulaireCtrl.formCtrl[vm.col].$setViewValue(vm.dataType.params.defaultOnSelection);
                    vm.formulaireCtrl.formCtrl[vm.col].$commitViewValue();
                    vm.formulaireCtrl.formCtrl[vm.col].$render();
                }
            }

            vm.formulaireCtrl.formCtrl[vm.col].$validate();
        }
    }
}
