import {IDefaultsService} from '../../services/utils/defaults.service';
import {IApiError} from '../../interfaces/api-error.interface';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComponentController } from 'angular';

interface IDialogAlert extends IComponentController {
    backgroundColor: string;
    data?: any;
    ecranContext: IEcranContextController;
    error: IApiError;
    html?: string;
    icon?: string;
    lblTitre?: string;
    lblMessage: string;
    rowError: boolean;
}

/* @ngInject */
export default function DialogAlertController(defaults: IDefaultsService) {
    const vm: IDialogAlert = this;
    const ICON_ERROR = 'warning';
    const lblOk = 'G_LBL_BTN_OK';

    vm.$onInit = function $onInit() {
        defaults(vm, {
            icon: ICON_ERROR,
            lblCancel: lblOk,
            rowError: hasRowError()
        });
    }

    function hasRowError() {
        return Boolean(
            vm.error &&
            vm.error.data &&
            vm.error.data.details &&
            typeof vm.error.data.details.row !== 'undefined'
        );
    }
}
