import { IComponentController, IScope, IFormController } from 'angular';
import { IFormulaireItem } from '../../services/formulaire/formulaire-item.service';
import { FormulaireElement, IFormulaire } from '../../services/formulaire/formulaire.service';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComportement } from '../../services/comportements.service';
import isMobile from '../../constants/mobile.constant';
import IResourceService = angular.resource.IResourceService;
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IComposantFormulaireInputList extends IComponentController {
    formulaireInputListCtrl: IComposantFormulaireInputList;
    occurrence: IMonoOccurrence | IMultiOccurrence;
    currentScope: number;
    formulaire: IFormulaire;
    data: any;
    formCtrl: IFormController;
    // Détermine si le formulaire est enregistrable, ou plutôt un formulaire temporaire comme les critères suggérés
    enregistrable: boolean;
    ecranContextCtrl: IEcranContextController;
    getChampWidth(champ?: FormulaireElement): number | string;
    isReadonly(champ: IFormulaireItem): boolean;
    isHidden(champ: IFormulaireItem): boolean;
    hasHistoriqueColonne(champ: IFormulaireItem): boolean;
}

/* @ngInject */
export default function FormulaireInputListController(defaults: IDefaultsService, $scope: IScope,
    $resource: IResourceService,
    parametresSecurite: IParametresSecuriteService,
    ApiConfig: IApiConfig) {
    const vm: IComposantFormulaireInputList = this;
    const resource = $resource(`${ApiConfig.ROOT}/champs-decoupage/getOrdre`);
    vm.$onInit = $onInit;
    vm.getChampWidth = getChampWidth;
    vm.isReadonly = isReadonly;
    vm.isHidden = isHidden;
    vm.hasHistoriqueColonne = hasHistoriqueColonne;

    function $onInit() {
        vm.currentScope = $scope.$id;

        defaults(vm, {
            enregistrable: true
        });

        if (vm.formulaireInputListCtrl && vm.formulaireInputListCtrl.formulaire) {
            //Dans un sous-groupe, on s'assure que les valeurs par défauts ne sont pas perdues.
            vm.formulaireInputListCtrl.formulaire.initFormData(vm.data, vm.occurrence.dataTypes);
            vm.formCtrl.$setPristine();
            vm.formCtrl.$setUntouched();
        }
        //si le flag a été activé on modifie l'ordre des champs du formulaire
        if (vm?.formulaire && vm?.formulaire?.modifierOrdre) {
            changeOrder()
        }

    }
    
    function changeOrder() {
        //quand il y a champs qui sont hidden ou static dans le débbut, on definie un index initial et on les laisse au début
        const initialPosition = vm.formulaire.modifierOrdre.initialPosition
        //on envoie la requete pour savoir l'ordre
        resource.query({
            filter: `vaesegdec{:}${vm.data.vaesegdec};sutcleint{:}${vm.data.sutcleint}`,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams),
        }).$promise.then((rows: any[]) => {

            if (rows && Array.isArray(rows) && vm.formulaire.liste) {

                rows.forEach((el, frmindex) => {
                    //on obtiene l'ordre en cherchant par nemonique
                    const flag = vm.formulaire.liste.filter((form: any) => form?.col?.substring(0, 3)?.toLowerCase() === el?.cdenemonique?.toLowerCase())
                    if (flag) {
                        const index = vm.formulaire.liste.indexOf(flag[0])
                        if (index) {
                            const form: any = vm.formulaire.liste[index]
                            //on ajoute la nouvelle position avec la position initial et l'index de l'array car il y a champ static à la fin du formulaire
                            form.setOrdre = initialPosition + frmindex
                            //on re-copie l'object modifié
                            Object.assign(vm.formulaire.liste[index], { ...form })
                        }
                    }
                })
                // à la fin on modifier l'ordre du parametre liste du formulaire en utilisant la fonctionne sort
                vm.formulaire.liste.sort((a: IFormulaireItem, b: IFormulaireItem) => a.setOrdre - b.setOrdre)
            }
        });
    }

    function getChampWidth(champ: FormulaireElement): number | string {
        if (isMobile || vm.occurrence.isDividerInput(champ) || (vm.occurrence.isChampTexte(champ) && !(<IFormulaireItem>champ).largeur)) {
            return '100%';
        } else {
            const flex = (<IFormulaireItem>champ).largeur || vm.formulaire.largeurDefaut;

            if (flex === 'flexible') {
                const largeur = vm.occurrence.schema[(<IFormulaireItem>champ).col].length;

                // En mode flexible, les champs courts s'affichent plus petit
                if (largeur < 30) {
                    return `${vm.formulaire.largeurDefaut}%`;
                } else {
                    return '100%';
                }
            } else {
                return `${flex}%`;
            }
        }
    }

    function isReadonly(champ: IFormulaireItem): boolean {
        const isMiseAJour = Boolean(vm.data[vm.occurrence.cleint]);
        return vm.occurrence.isChampReadonly(champ, isMiseAJour, vm.data, vm.formulaire.securityCycle, vm.enregistrable);
    }

    function isHidden(champ: IFormulaireItem) {
        return Boolean(
            getHiddenValue(champ, vm.data) ||
            getHiddenValue(vm.occurrence.comportements[champ.col], vm.data)
        );
    }

    function getHiddenValue(champ: IFormulaireItem | IComportement, data: any) {
        if (!champ) {
            return false;
        } else if (champ.hidden instanceof Function) {
            return champ.hidden(data);
        } else {
            return champ.hidden;
        }
    }

    function hasHistoriqueColonne(champ: IFormulaireItem) {
        return Boolean(champ.historiqueColonne && (vm.occurrence as IMonoOccurrence).data[vm.occurrence.cleint]);
    }
}
