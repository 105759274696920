import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;

export interface IProchainEtatRh extends IResource<IProchainEtatRh> {
    trncleint: number;
    cleintpro: number;
    dsceta?: string;
    dscact?: string;
    com?: string;
    acces: number;
    approbation: number;
    err?: number;
    trnflgstaann?: number;
    trnflgstafer?: number;
    trnflgstafin?: number;
    trnflgstaref?: number;
    cepflgaccepl?: number;
    cepflgstaref?: number,
    cepflgstafin?: number,
    cepflgstafer?: number,
    cepflgstaann?: number
}
export interface IEtatActuelRH extends IResource<IEtatActuelRH> {
    eclcleint: number;
    nom: string;
    date: string;
    flgstafin: number;
    trlcleint: number;
    [index: string]: any;
}

export interface IProchainEtatOptionsRh {
    trncleint: number;
}

export interface IActionsPossiblesOptionsRh {
    trncleint: number;
}

export interface ITachesOptionsRh {
    trncleint: number;
}

export interface IMessagesErreurOptionsRh {
    trncleint: number;
}

export interface IMessagesAvertissementOptionsRh {
    trncleint: number;
}

export interface IActionPossibleRh extends IResource<any> {
    trncleint: number;
    eclcleintcib: number;
    ecldscact: string;
    eclcom: string;
    trnflgstaann?: number;
    trnflgstafer?: number;
    trnflgstafin?: number;
    trnflgstaref?: number;
    cepflgaccepl?: number;
    cepflgstaref?: number,
    cepflgstafin?: number,
    cepflgstafer?: number,
    cepflgstaann?: number    
}

export interface IActionRh {
    trncleint: number | Array<number>;
    eclcleint: number;
}

export interface ITacheRh {
    trncleint: number | Array<number>;
    ctacleint: number;
    ctadsc?: string;
    ctacom?: string;
    thtflgeff?: number;
}

export interface IMessageAvertissementRh extends IResource<any> {
    trvcleint: number;
    trncleint: number | Array<number>;
    trvflgaut: number;
    thecleint: number;
    mtrmsg: string;
    $selected: boolean;
}

export interface IMessageAvertissementUpdateDataRh {
    trvflgaut: number;
}

export interface IMessageAvertissementUpdateParamsRh {
    ids: string;
}

interface ICycleValideEtatsOptions {
    selections: string;
    ids?: string;
}

export interface ICycleRhClass extends IResourceClass<any> {
    new(data: any): IResource<any>;
    getProchainEtat(options: IProchainEtatOptionsRh): IResource<IProchainEtatRh>;
    getEtatActuel(options: IProchainEtatOptionsRh): IResource<IEtatActuelRH>;
    queryActionsPossibles(options: IActionsPossiblesOptionsRh): IResourceArray<IActionPossibleRh>;
    queryTaches(options: ITachesOptionsRh): IResourceArray<ITacheRh>;
    queryMessagesErreur(options: IMessagesErreurOptionsRh): IResourceArray<string>;
    queryMessagesAvertissement(options: IMessagesAvertissementOptionsRh): IResourceArray<IMessageAvertissementRh>;
    updateMessagesAvertissement(params: IMessageAvertissementUpdateParamsRh, data: IMessageAvertissementUpdateDataRh): IResourceArray<IMessageAvertissementRh>;
    validateEtats(data: ICycleValideEtatsOptions): IResource<any>;
    executeTache(params: any, data: ITacheRh): IResource<any>;
    genererPieceJustificative(params: any, data: ITacheRh): IResource<any>;
    verifierForageId(params: any): IResource<any>;
}

export default module('core.resources.cycle-rh', [
    'core.services.api-config'
]).factory('CycleRh', CycleRhFactory);

/* @ngInject */
function CycleRhFactory(ApiConfig: IApiConfig,
                        $resource: IResourceService): ICycleRhClass {
    const params = {};

    const actions = {
        getProchainEtat: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/prochain-etat/:trncleint`
        },
        getEtatActuel: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/etat-actuel/:trncleint`
        },
        queryActionsPossibles: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/actions-possibles/:trncleint`,
            isArray: true
        },
        queryTaches: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/taches/:trncleint`,
            isArray: true
        },
        queryMessagesErreur: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/messages-erreur/:trncleint`,
            isArray: true
        },
        queryMessagesAvertissement: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/messages-avertissement/:trncleint`,
            isArray: true
        },
        updateMessagesAvertissement: {
            method: 'PUT',
            url: `${ApiConfig.ROOT}/cycles/rh/messages-avertissement/:trncleint/:ids`,
            isArray: true
        },
        validateEtats: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/valide-etats`,
        },
        executeTache: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/cycles/rh/taches`
        },
        genererPieceJustificative: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/cycles/rh/piece-justificative/:trncleint`
        },
        verifierForageId: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/cycles/rh/verifier-forage-id/:id`
        }
    };

    return <ICycleRhClass>$resource(`${ApiConfig.ROOT}/cycles/rh`, params, actions);
}
