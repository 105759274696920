import { IComponentController } from 'angular';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';

export interface IDialogCycleEvenementActionsController extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
}


/* @ngInject */
export default function DialogCycleTransactionnelActionsController() {
    const vm: IDialogCycleEvenementActionsController = this;
    vm.$onInit = $onInit;
    function $onInit() { 
        vm.monoOccurrence.fonctions.codeEcranBandeau=false;
    }

}
