/**
 * Composant représentant la fonction transversale du cycle transactionnel
 */
import {module} from 'angular';
import DialogCycleTransactionnelHistoriqueController from './dialog-cycle-transactionnel-historique.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-cycle-transactionnel-historique', [
    'core.services.dialog'
]).factory('DialogCycleTransactionnelHistorique', DialogCycleTransactionnelHistoriqueFactory);

/* @ngInject */
function DialogCycleTransactionnelHistoriqueFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCycleTransactionnelHistoriqueController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-cycle-transactionnel-historique.html'),
        locals: {
            icon: 'history',
            lblTitre: "G_LBL_HISTORIQUE_CYCLE_TRANSACTIONNEL",
            lblCancel: 'G_LBL_BTN_FERMER'
        }
    });
}
