import { IComponentController } from 'angular';

import { IDataSourceController } from '../../components/ex-data-source/ex-data-source.component';
import { IComposantMonoOccurrence } from '../../components/ex-mono-occurrence/ex-mono-occurrence.controller';
import { IMenu } from '../../services/menu/menu.service';
import { IDefaultsService } from '../../services/utils/defaults.service';

interface ICardOptions {
    contentClass?: string;
    hideHeader?: boolean;
    iconTitre?: string;
    lblTitre?: string;
    libelles?: { [key: string]: string | number };
    menu?: IMenu;
    themeColor?: string;
}

export interface IComposantCardExperimental extends ICardOptions, IComponentController {
    dataSourceCtrl?: IDataSourceController;
    monoOccurrenceCtrl?: IComposantMonoOccurrence;
}

/* @ngInject */
export default function CardController(defaults: IDefaultsService) {
    const vm: IComposantCardExperimental = this;

    vm.$onInit = $onInit;

    function $onInit(): void {
        defaults(vm, {
            color: 'grey-A100' // Représente le blanc de Material Color
        });

        vm.libelles = getLibelles();
    }

    function getLibelles(): { [key: string]: string | number } {
        return vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);
    }

}
