import { module } from 'angular';
import { EventEmitter } from 'events';
import { IReglesValidation } from '../interfaces/regles-validation.interface';
import { IDataTypeMap } from './data-types/data-type.service';
import { ISchemas } from '../interfaces/schemas.interface';
import { IFormulaireItem, IFormulaireItemClass } from './formulaire/formulaire-item.service';
import { FormulaireElement, IFormulaire } from './formulaire/formulaire.service';
import { IChampsTransactionnels } from '../resources/champs-transactionnels.resource';
import { ITexteDataTypeClass } from './data-types/texte-data-type.service';
import { IHeureDataTypeClass } from './data-types/heure-data-type.service';
import { IDateHeureDataTypeClass } from './data-types/date-heure-data-type.service';
import { ILovDataTypeClass } from './data-types/lov-data-type.service';
import { IDateDataTypeClass } from './data-types/date-data-type.service';
import { INumberDataTypeClass } from './data-types/number-data-type.service';
import { IBoutonRadioDataTypeClass } from './data-types/bouton-radio-data-type.service';
import { IFormulaireItemSautDeLigneClass } from './formulaire/formulaire-item-saut-de-ligne.service';
import { IFormulaireItemDividerClass } from './formulaire/formulaire-item-divider.service';
import { IBooleanDataTypeClass } from './data-types/boolean-data-type.service';
import { IImputationDataTypeClass } from './data-types/imputation-data-type.service';
import { ILovRequerantDataTypeClass } from './data-types/lov-requerant-data-type.service';
import { ILovEntiteExterneDataTypeClass } from './data-types/lov-entite-externe-data-type.service';
import { IEcranDetailsResourcesEntite } from '../resources/ecran-details.resource';
import { IFormulaireGroupeConditionnelClass } from './formulaire/formulaire-groupe-conditionnel.service';
import { IFormulaireItemTexteClass } from './formulaire/formulaire-item-texte.service';
import { IFormulaireItemMenuItemClass } from './formulaire/formulaire-item-menu-item.service';
import { ISelectionMultipleDataTypeClass } from './data-types/selection-multiple-data-type.service';
import { ILovBoutonRadioDataTypeClass } from './data-types/lov-bouton-radio-data-type.service';
import { IFormulaireGroupeAccordeonClass } from './formulaire/formulaire-groupe-accordeon.service';
import { IEditionAvanceeDataTypeClass } from './data-types/edition-avancee-data-type.service';
import { IFormulaireGroupe, IFormulaireGroupeClass } from './formulaire/formulaire-groupe.service';
import { IComportement, IComportementMap } from './comportements.service';
import { IFormulaireTemplateSlotClass } from './formulaire/formulaire-template-slot.service';
import { ILovIntervenantDataTypeClass } from './data-types/lov-intervenant-data-type.service';
import { IMessages, ISourceDetails } from '../resources/source-details.resource';
import { ISelectionMultipleAvanceeDataTypeClass } from './data-types/selection-multiple-avancee-data-type.service';
import { IIntervalleDataTypeClass } from './data-types/intervalle-data-type.service';
import { IParametreMultipleDataTypeClass } from './data-types/parametre-multiple-data-type.service';
import { IFormulaireTabSectionsClass } from './formulaire/formulaire-tab-sections.service';
import { IFormulaireLovClass } from './formulaire/formulaire-lov.service';

export interface IOccurrenceClass {
    new(options: IOccurrenceOptions): IOccurrence;
}

export interface IOccurrenceOptions { }

export interface IOccurrence extends EventEmitter {
    reglesValidation: IReglesValidation;
    mnemonique: string;
    dataTypes: IDataTypeMap;
    comportements: IComportementMap;
    messages: IMessages;
    libelles: any;
    stateParams: any;
    ecranDetails: IEcranDetailsResourcesEntite;
    ecranSourceDetails: ISourceDetails;
    resetEtatDialog?: boolean;

    isFormulaireItem(champ: FormulaireElement): boolean;
    isDividerInput(champ: FormulaireElement): boolean;
    isSautDeLigne(champ: FormulaireElement): boolean;

    isChampDefault(champ: IFormulaireItem): boolean;
    isConditionnelInput(champ: IFormulaireItem): boolean;
    isGroupeAccordeon(champ: IFormulaireItem): boolean;
    isFormulaireGroupe(champ: IFormulaireItem): boolean;
    isFormulaireLov(champ: IFormulaireItem): boolean;
    isFormulaireTabs(champ: IFormulaireItem): boolean;
    isFormulaireGroupeConditionnel(champ: IFormulaireItem): boolean;
    isFormulaireTemplateSlot(champ: IFormulaireItem): boolean;
    isChampBoolean(champ: IFormulaireItem): boolean;
    isChampRadioButton(champ: IFormulaireItem): boolean;
    isChampDropdown(champ: IFormulaireItem): boolean;
    isChampLov(champ: IFormulaireItem): boolean;
    isChampLovIntervenant(champ: IFormulaireItem): boolean;
    isChampLovRequerant(champ: IFormulaireItem): boolean;
    isChampLovEntiteExterne(champ: IFormulaireItem): boolean;
    isChampLovBoutonRadio(champ: IFormulaireItem): boolean;
    isChampSelectionMultiple(champ: IFormulaireItem): boolean;
    isChampSelectionMultipleAdvanced(champ: IFormulaireItem): boolean;
    isChampNumber(champ: IFormulaireItem): boolean;
    isChampDescription(champ: IFormulaireItem): boolean;
    isChampEditionAvancee(champ: IFormulaireItem): boolean;
    isChampDate(champ: IFormulaireItem): boolean;
    isChampDateHour(champ: IFormulaireItem): boolean;
    isChampHour(champ: IFormulaireItem): boolean;
    isChampImputation(champ: IFormulaireItem): boolean;
    isChampIntervalle(champ: IFormulaireItem): boolean;
    isChampParametreMultiple(champ: IFormulaireItem): boolean;

    hasChampsModifiables(isMiseAJour: boolean, data: any): boolean;
    hasChampsRequisModifiables(isMiseAJour: boolean, data: any): boolean;
    isEveryChampsRequisModifiables(isMiseAJour: boolean, data?: any): boolean;
    isChampRequired(champ: IFormulaireItem, data: any): boolean;
    isChampDisabled(champ: IFormulaireItem, data: any): boolean;
    isChampModifiable(col: string, securityCycle?: boolean): boolean;
    isChampReadonly(champ: IFormulaireItem, isMiseAjour: boolean, data: any, securityCycle?: boolean, isEnregistrable?: boolean): boolean;
    isChampTexte(champ: FormulaireElement): boolean;
    isMenuItem(champ: FormulaireElement): boolean;
    isModifiablePartiel(): boolean;
    isModifiableTous(): boolean;
}

export default module('core.services.occurrence', []).factory('Occurrence', OccurrenceFactory);

/* @ngInject */
function OccurrenceFactory(FormulaireItem: IFormulaireItemClass,
    FormulaireItemDivider: IFormulaireItemDividerClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireLov: IFormulaireLovClass,
    FormulaireTabSections: IFormulaireTabSectionsClass,
    FormulaireTemplateSlot: IFormulaireTemplateSlotClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    TexteDataType: ITexteDataTypeClass,
    EditionAvanceeDataType: IEditionAvanceeDataTypeClass,
    HeureDataType: IHeureDataTypeClass,
    DateHeureDataType: IDateHeureDataTypeClass,
    LovDataType: ILovDataTypeClass,
    LovIntervenantDataType: ILovIntervenantDataTypeClass,
    LovRequerantDataType: ILovRequerantDataTypeClass,
    LovEntiteExterneDataType: ILovEntiteExterneDataTypeClass,
    LovBoutonRadioDataType: ILovBoutonRadioDataTypeClass,
    SelectionMultipleDataType: ISelectionMultipleDataTypeClass,
    SelectionMultipleAvanceeDataType: ISelectionMultipleAvanceeDataTypeClass,
    BooleanDataType: IBooleanDataTypeClass,
    DateDataType: IDateDataTypeClass,
    NumberDataType: INumberDataTypeClass,
    BoutonRadioDataType: IBoutonRadioDataTypeClass,
    ImputationDataType: IImputationDataTypeClass,
    IntervalleDataType: IIntervalleDataTypeClass,
    ParametreMultipleDataType: IParametreMultipleDataTypeClass,
    FormulaireItemTexte: IFormulaireItemTexteClass,
    FormulaireItemMenuItem: IFormulaireItemMenuItemClass) {
    class Occurrence extends EventEmitter implements IOccurrence {
        schema: ISchemas;
        dataTypes: IDataTypeMap;
        comportements: IComportementMap;
        libelles: any;
        validatedFields: Array<string>;
        reglesValidation: IReglesValidation;
        formulaire: IFormulaire;
        champsTransactionnels: IChampsTransactionnels;
        bloc: string;
        mnemonique: string;
        messages: IMessages;
        stateParams: any;
        ecranDetails: IEcranDetailsResourcesEntite;
        ecranSourceDetails: ISourceDetails;

        isModifiableTous(): boolean {
            return Boolean(
                !this.champsTransactionnels ||
                !this.champsTransactionnels.vaeceptypmod ||
                this.champsTransactionnels.vaeceptypmod === 'TOUS'
            );
        }

        isModifiablePartiel(): boolean {
            return Boolean(
                this.champsTransactionnels &&
                this.champsTransactionnels.vaeceptypmod === 'PART' &&
                this.champsTransactionnels.champsModifiables.length
            );
        }

        hasChampsModifiables(isMiseAJour: boolean, data: any): boolean {
            if (this.isModifiableTous()) {
                return true;
            }

            if (this.isModifiablePartiel()) {
                // On vérifie s'il y a au moins un champs modifiable
                return this.getListeChampsModifiablesValue(this.formulaire.liste, isMiseAJour, data);
            }

            return false;
        }

        private getListeChampsModifiablesValue(liste: Array<FormulaireElement>, isMiseAJour: boolean, data: any): boolean {
            return liste
                .filter((formulaireElement: FormulaireElement) => {
                    return (formulaireElement instanceof FormulaireItem || formulaireElement instanceof FormulaireGroupe);
                })
                .some((champ: IFormulaireItem | IFormulaireGroupe) => {
                    if (champ instanceof FormulaireGroupe) {
                        return this.getListeChampsModifiablesValue((<IFormulaireGroupe>champ).formulaire.liste, isMiseAJour, data);
                    } else {
                        return (!this.isChampDisabled(champ, data) && !this.isChampReadonly(champ, isMiseAJour, data));
                    }
                });
        }

        isChampModifiable(col: string, securityCycle: boolean = true) {
            if (!securityCycle) {
                return true;
            }

            return this.isModifiableTous() || (this.isModifiablePartiel() && this.champsTransactionnels.champsModifiables.includes(col));
        }

        hasChampsRequisModifiables(isMiseAJour: boolean, data: any = {}) {
            const formulaireItems = this.formulaire.liste.filter(formulaireElement => formulaireElement instanceof FormulaireItem)
            if (!isMiseAJour) {
                return formulaireItems.every((champ: IFormulaireItem) => !this.isChampRequired(champ, data) || !this.champEmpecheSauvegarde(champ, data, isMiseAJour));
            } else {
                return formulaireItems.some((champ: IFormulaireItem) => !this.champEmpecheSauvegarde(champ, data, isMiseAJour));
            }
        }

        private champEmpecheSauvegarde(champ: IFormulaireItem, data: any, isMiseAJour: boolean) {
            return this.isChampDisabled(champ, data) || (champ.readonly instanceof Function && this.isChampReadonly(champ, isMiseAJour, data, true));
        }

        isEveryChampsRequisModifiables(isMiseAJour: boolean, data: any): boolean {
            //Si on ne passe pas par un cycle transactionnel
            if (this.champsTransactionnels && !Object.keys(this.champsTransactionnels).length && this.formulaire) {
                return this.hasChampsRequisModifiables(isMiseAJour, data);
            } else {
                if (this.isModifiableTous()) {
                    return true;
                }

                if (this.isModifiablePartiel()) {
                    // On vérifie qu'aucun champs requis ne soit désactivé
                    return this.hasChampsRequisModifiables(isMiseAJour, data);
                }
            }
            return false;
        }

        isChampRequired(champ: IFormulaireItem, data: any): boolean {
            if (typeof champ.required !== 'undefined') {
                return this.getRequiredValue(champ, data);
            } else if (this.comportements[champ.col] && typeof this.comportements[champ.col].required !== 'undefined') {
                return this.getRequiredValue(this.comportements[champ.col], data);
            }

            // Les séquences ne sont jamais requises, puisqu'elle sont générées côté serveur
            if (this.reglesValidation[champ.col] && Boolean(this.reglesValidation[champ.col].flgseqaut)) {
                return false;
            }

            return (
                // Champ requis par règle de validation
                (this.reglesValidation && this.reglesValidation[champ.col] && this.reglesValidation[champ.col].forolb === 1) ||
                // Champ requis par le schéma
                (this.schema[champ.col] && this.schema[champ.col].required)
            );
        }

        private getRequiredValue(champ: IFormulaireItem | IComportement, data: any) {
            if (champ.required instanceof Function) {
                return champ.required(data);
            } else {
                return champ.required;
            }
        }

        isChampDisabled(champ: IFormulaireItem, data: any): boolean {
            return this.getDisabledValue(champ, data) || this.getDisabledValue(this.comportements[champ.col], data)
        }

        private getDisabledValue(champ: IFormulaireItem | IComportement, data: any) {
            if (!champ) {
                return false;
            } else if (champ.disabled instanceof Function) {
                return champ.disabled(data);
            } else {
                return champ.disabled;
            }
        }

        isChampReadonly(champ: IFormulaireItem, isMiseAjour: boolean, data: any, securityCycle: boolean = false, isEnregistrable: boolean = true) {
            return Boolean(
                (isEnregistrable && ((!(this as any).fonctions.edition && isMiseAjour) || (!(this as any).fonctions.nouveau && !isMiseAjour))) ||
                this.getReadonlyValue(champ, data) ||
                this.getReadonlyValue(this.comportements[champ.col], data) ||
                (!champ.modifAllow && isMiseAjour) ||
                !this.isChampModifiable(champ.col, securityCycle && champ.securityCycle) ||
                // On applique les règles de validations uniquement aux champs textuels
                (
                    (this.isChampDefault(champ) || this.isChampDescription(champ) || this.isChampEditionAvancee(champ)) &&
                    this.reglesValidation[champ.col] && this.reglesValidation[champ.col].flgseqaut === 1
                )
            );
        }

        private getReadonlyValue(champ: IFormulaireItem | IComportement, data: any) {
            if (!champ) {
                return false;
            } else if (champ.readonly instanceof Function) {
                return champ.readonly(data);
            } else {
                return champ.readonly;
            }
        }

        isChampDefault(champ: IFormulaireItem): boolean {
            return (
                this.isFormulaireItem(champ) &&
                !this.isConditionnelInput(champ) &&
                !this.isGroupeAccordeon(champ) &&
                !this.isChampDescription(champ) &&
                !this.isChampEditionAvancee(champ) &&
                !this.isChampNumber(champ) &&
                !this.isChampDate(champ) &&
                !this.isChampDateHour(champ) &&
                !this.isChampHour(champ) &&
                !this.isChampDropdown(champ) &&
                !this.isChampLovBoutonRadio(champ) &&
                !this.isChampSelectionMultiple(champ) &&
                !this.isChampSelectionMultipleAdvanced(champ) &&
                !this.isChampBoolean(champ) &&
                !this.isChampRadioButton(champ) &&
                !this.isChampImputation(champ) &&
                !this.isChampIntervalle(champ) &&
                !this.isChampParametreMultiple(champ) &&
                !this.isChampTexte(champ) &&
                !this.isMenuItem(champ) &&
                !this.isFormulaireTemplateSlot(champ)
            );
        }

        isFormulaireItem(champ: FormulaireElement): boolean {
            return champ instanceof FormulaireItem;
        }

        isDividerInput(champ: FormulaireElement): boolean {
            return champ.constructor === FormulaireItemDivider;
        }

        isSautDeLigne(champ: FormulaireElement): boolean {
            return champ.constructor === FormulaireItemSautDeLigne;
        }

        isConditionnelInput(champ: IFormulaireItem): boolean {
            return champ.constructor === FormulaireGroupeConditionnel;
        }

        isGroupeAccordeon(champ: IFormulaireItem) {
            return champ.constructor === FormulaireGroupeAccordeon;
        }

        isFormulaireGroupe(champ: IFormulaireItem) {
            return champ.constructor === FormulaireGroupe;
        }

        isFormulaireLov(champ: IFormulaireItem) {
            return champ.constructor === FormulaireLov;
        }

        isFormulaireTabs(champ: IFormulaireItem) {
            return champ.constructor === FormulaireTabSections;
        }

        isFormulaireGroupeConditionnel(champ: IFormulaireItem) {
            return champ.constructor === FormulaireGroupeConditionnel;
        }

        isFormulaireTemplateSlot(champ: IFormulaireItem) {
            return champ.constructor === FormulaireTemplateSlot;
        }

        isChampBoolean(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof BooleanDataType ||
                this.dataTypes[champ.col] instanceof BooleanDataType;
        }

        isChampImputation(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof ImputationDataType ||
                this.dataTypes[champ.col] instanceof ImputationDataType;
        }

        isChampIntervalle(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof IntervalleDataType ||
                this.dataTypes[champ.col] instanceof IntervalleDataType;
        }

        isChampParametreMultiple(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof ParametreMultipleDataType ||
                this.dataTypes[champ.col] instanceof ParametreMultipleDataType;
        }

        isChampRadioButton(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof BoutonRadioDataType ||
                this.dataTypes[champ.col] instanceof BoutonRadioDataType;
        }

        isChampDropdown(champ: IFormulaireItem): boolean {
            return this.isChampLov(champ) ||
                this.isChampLovIntervenant(champ) ||
                this.isChampLovRequerant(champ) ||
                this.isChampLovEntiteExterne(champ) ||
                this.isChampSelectionMultiple(champ) ||
                this.isChampSelectionMultipleAdvanced(champ);
        }

        isChampLov(champ: IFormulaireItem): boolean {
            return (champ.dataType instanceof LovDataType || this.dataTypes[champ.col] instanceof LovDataType);
        }

        isChampLovIntervenant(champ: IFormulaireItem): boolean {
            return (champ.dataType instanceof LovIntervenantDataType || this.dataTypes[champ.col] instanceof LovIntervenantDataType);
        }

        isChampLovRequerant(champ: IFormulaireItem): boolean {
            return (champ.dataType instanceof LovRequerantDataType || this.dataTypes[champ.col] instanceof LovRequerantDataType);
        }

        isChampSelectionMultiple(champ: IFormulaireItem): boolean {
            return (champ.dataType instanceof SelectionMultipleDataType || this.dataTypes[champ.col] instanceof SelectionMultipleDataType);
        }

        isChampSelectionMultipleAdvanced(champ: IFormulaireItem): boolean {
            return (champ.dataType instanceof SelectionMultipleAvanceeDataType || this.dataTypes[champ.col] instanceof SelectionMultipleAvanceeDataType);
        }

        isChampLovEntiteExterne(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof LovEntiteExterneDataType ||
                this.dataTypes[champ.col] instanceof LovEntiteExterneDataType;
        }

        isChampLovBoutonRadio(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof LovBoutonRadioDataType ||
                this.dataTypes[champ.col] instanceof LovBoutonRadioDataType;
        }

        isChampNumber(champ: IFormulaireItem): boolean {
            return (
                (champ.dataType instanceof NumberDataType ||
                    this.dataTypes[champ.col] instanceof NumberDataType) ||
                (
                    !this.isChampDropdown(champ) &&
                    !this.isChampRadioButton(champ) &&
                    !this.isChampLovBoutonRadio(champ) &&
                    !this.isChampBoolean(champ) &&
                    (this.schema[champ.col] && this.schema[champ.col].type === 'number')
                )
            );
        }

        isChampDescription(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof TexteDataType ||
                this.dataTypes[champ.col] instanceof TexteDataType;
        }

        isChampEditionAvancee(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof EditionAvanceeDataType ||
                this.dataTypes[champ.col] instanceof EditionAvanceeDataType;
        }

        isChampDate(champ: IFormulaireItem): boolean {
            return (
                !this.isChampDateHour(champ) &&
                !this.isChampLov(champ) &&
                !this.isChampHour(champ) &&
                (champ.dataType instanceof DateDataType ||
                    (
                        ((this.schema[champ.col] && this.schema[champ.col].type === 'date')) ||
                        this.dataTypes[champ.col] instanceof DateDataType
                    ))
            );
        }

        isChampDateHour(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof DateHeureDataType ||
                this.dataTypes[champ.col] instanceof DateHeureDataType;
        }

        isChampHour(champ: IFormulaireItem): boolean {
            return champ.dataType instanceof HeureDataType ||
                this.dataTypes[champ.col] instanceof HeureDataType;
        }

        isChampTexte(champ: FormulaireElement): boolean {
            return (champ instanceof FormulaireItemTexte);
        }

        isMenuItem(champ: FormulaireElement): boolean {
            return (champ instanceof FormulaireItemMenuItem)
        }
    }

    return Occurrence;
}
