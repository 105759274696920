import { IAugmentedJQuery, IFormController, IRootScopeService } from 'angular';
import { IComposantDialog } from '../../components/ex-dialog/ex-dialog.controller';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMenuClass } from '../../services/menu/menu.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IOperateurService } from '../../services/operateur.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import IDialogService = angular.material.IDialogService;

export interface IDialogLovEntiteExterne extends IComposantDialog {
    srccodref: string;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    formDialog: IFormController;
    axeVisibiliteActifs: Array<string>;
    forcerEtatPredefinisDepart: string;
    id: string;
    description: Array<any>;
    confirm(event: any, data: { refcleint: number; refcod: string; refnom: string; }): void;
    confirmSelectedRow(event: any): void;
    disableConfirmAction(): boolean;
}

/* @ngInject */
export default function DialogLovEntiteExterneController(defaults: IDefaultsService,
    Operateur: IOperateurService,
    ApiConfig: IApiConfig,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    $mdDialog: IDialogService,
    $element: IAugmentedJQuery,
    ecranDetails: IEcranDetailsResourcesEntite,
    $rootScope: IRootScopeService) {
    const vm: IDialogLovEntiteExterne = this;
    vm.confirm = confirm;
    vm.disableConfirmAction = disableConfirmAction;
    vm.confirmSelectedRow = confirmSelectedRow;
    const LBL_TITRE = 'G_LBL_ENTITE_EXTERNE';
    const lblConfirm = 'G_LBL_BTN_CONFIRMER';
    vm.$onInit = function $onInit() {
        defaults(vm, {
            lblTitre: LBL_TITRE,
            lblConfirm: lblConfirm,
            loading: true
        });

        $element.find('md-dialog').addClass('dialog-lov-entite-externe-md-dialog');
        vm.multiOccurrenceOptions = {
            actionsRangeeDroite: new Menu([new MenuItem(confirm, {
                icon: 'check_circle',
                iconButton: true
            })]),
            fonctions: {
                supprime: false,
                nouveau: false,
                edition: false,
                suiviModification: false,
                exportation: false,
                importation: false,
                enregistrerUnEtat: false,
                saveEtatTemporaire: false,
                selectionnerDesColonnes: false,
                reinitialiser: false,
                skipDoubleRequetes: true
            },
            srccod: `lov-ref-ext`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/ref-ext`,
            resourceParams: {
                srccod: vm.srccodref
            },
            forcerEtatPredefinisDepart: vm.forcerEtatPredefinisDepart,
            etatsPredefinis: [
                {
                    nom: 'FOU',
                    axeVisibiliteActif: Boolean(vm.axeVisibiliteActifs.find((element: any) => element.toUpperCase() === 'FOU')),
                    filtres: [
                        {
                            colonne: 'type',
                            operateur: Operateur.EGALE,
                            valeur: 'FOU'
                        }
                    ],
                    colonnesVisibles: [
                        'vaesysdevcod',
                        'pflcod',
                        'pfldsc',
                        'radadr',
                        'radcodpos',
                        'foudatfin',
                        'radnom',
                        'founumtvq',
                        'founumtps',
                        "refnomabr"
                    ]
                }, {
                    nom: 'EMP',
                    axeVisibiliteActif: Boolean(vm.axeVisibiliteActifs.find((element: any) => element.toUpperCase() === 'EMP')),
                    filtres: [
                        {
                            colonne: 'type',
                            operateur: Operateur.EGALE,
                            valeur: 'EMP'
                        }
                    ],
                    colonnesVisibles: [
                        'unadsc',
                        'radnom',
                        "refnomabr"
                    ]
                }, {
                    nom: 'CLI',
                    axeVisibiliteActif: Boolean(vm.axeVisibiliteActifs.find((element: any) => element.toUpperCase() === 'CLI')),
                    filtres: [
                        {
                            colonne: 'type',
                            operateur: Operateur.EGALE,
                            valeur: 'CLI'
                        }
                    ],
                    colonnesVisibles: [
                        'vaesysdevcod',
                        'crpcod',
                        'crpdsc',
                        'clycod',
                        'radadr',
                        'radcodpos',
                        'radnom',
                        "refnomabr"
                    ]
                }
            ],
            colonnesFixesGauche: [
                { nom: 'refnom', largeur: 262 },
                { nom: 'refcod', largeur: 89 }
            ],
            colonnesVisibles: [
                { nom: 'foudatfin', largeur: 102 },
                { nom: 'unadsc', largeur: 262 },
                { nom: 'vaesysdevcod', largeur: 76 },
                { nom: 'pflcod', largeur: 100 },
                { nom: 'pfldsc', largeur: 189 },
                { nom: 'crpcod', largeur: 95 },
                { nom: 'crpdsc', largeur: 189 },
                { nom: 'clycod', largeur: 91 },
                { nom: 'radnom', largeur: 188 },
                { nom: 'radadr', largeur: 262, hidden: (ecranDetails.flgcen === 0) ? true : false },
                { nom: 'radcodpos', largeur: 110, hidden: (ecranDetails.flgcen === 0) ? true : false },
                { nom: 'founumtvq', largeur: 142 },
                { nom: 'founumtps', largeur: 142 },
                { nom: 'refnomabr', largeur: 142 }
            ]
        };
    }

    function disableConfirmAction() {
        return !vm.multiOccurrence || !vm.multiOccurrence.activeRowCleint;
    }

    function confirmSelectedRow(event: any) {
        const activeRow = vm.multiOccurrence.dataList.find(data => data[vm.multiOccurrence.cleint] === vm.multiOccurrence.activeRowCleint);
        confirm(event, { refcleint: activeRow.refcleint, refcod: activeRow.refcod, refnom: activeRow.refnom });
    }

    function confirm(event: any, data: { refcleint: number; refcod: string; refnom: string; }) {
        const champsDescription = getChampsDescription(data);
        $element.find('md-dialog').removeClass('dialog-lov-entite-externe-md-dialog');
        $mdDialog.hide({
            ...champsDescription,
            [vm.id]: data.refcleint
        });
        //si on change d'entite externe on doit vider les champs du formulaire qui ont la propriete viderchamp=true
        $rootScope.$broadcast("exEntiteExterne.initForm")
    }
    function getChampsDescription(data: any) {
        const objResult = {}
        try {
            //on fais l'assignation s'il y a des données
            if (data && vm.description && Array.isArray(vm.description) && vm.description.length > 0) {
                //on va créer les champs de description de façon dynamique en considerant le format cleint__nomDuChamp
                vm.description.forEach((el: string) => {
                    //on fais le loop sur l'objet data afin de trouver le bon champ, normalement c'est refcod et refnom
                    Object.keys(data).forEach((donnee) => {
                        if (el.includes(donnee)) {
                            //format du champ description cleint__nomDuChamp
                            let col = [vm.id] + "__" + [donnee]
                            Object.assign(objResult, {
                                ...objResult,
                                [col]: data[donnee]
                            })
                        }
                    })
                })
            }
        } catch (error) {
        }
        return objResult;
    }
}
