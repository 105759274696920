import { IComponentController, IRootScopeService, IScope } from 'angular';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import IResourceService = angular.resource.IResourceService;

interface IComposantCardInfosMonetaires extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    monoOccurrence: IMonoOccurrence;
    infosMonetaires: IInfosMonetaires;
    data: IInfosMonetairesData;
    fetchingData: boolean;
    dataError: boolean;
    initialized: boolean;
    isValidValue(champ: IInfoMonetairesOptions): boolean;
    fetchData(): void;
    validationChamp(champ: IInfoMonetairesOptions, flag: boolean): boolean;

}

export interface IInfosMonetaires {
    donnees: Array<IInfoMonetairesOptions>
    largeurTexte?: number;
    blocs?: Array<string>;
    noIcons?: boolean;
    lblTitre?: string;
    paddingActions?: boolean;
    afficherAuBas?:any;
}

interface IInfosMonetairesData {
    [col: string]: number;
}

interface IInfoMonetairesOptions {
    nom: string;
    libelle: string;
    lastOfGroup?: boolean;
    skipLine?: boolean;
    important?: boolean;
    expectedValue?: any;
    flagShowField?: any;
}

/* @ngInject */
export default function CardInfosMonetairesController($resource: IResourceService,
    $scope: IScope,
    ApiConfig: IApiConfig,
    parametresSecurite: IParametresSecuriteService,
    $rootScope: IRootScopeService) {
    const vm: IComposantCardInfosMonetaires = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.fetchData = fetchData;
    vm.isValidValue = isValidValue;
    vm.validationChamp = validationChamp;
    function $onInit() {
        vm.fetchData();

        if (vm.infosMonetaires.blocs) {
            vm.infosMonetaires.blocs.map((bloc) => bloc.toUpperCase()).forEach((bloc: string) => {
                $scope.$watch(`::vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants.${bloc}`, addBlocListener);
            });
        }
    }

    function $onDestroy() {
        if (vm.infosMonetaires.blocs && vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants.length) {
            vm.infosMonetaires.blocs.map((bloc) => bloc.toUpperCase()).forEach((bloc: string) => {
                vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc].removeListener('dataListUpdate', onMultiOccurrenceDataListUpdate);
            });
        }
    }

    function addBlocListener(multiOccurrence: IMultiOccurrence) {
        if (multiOccurrence) {
            multiOccurrence.on('dataListUpdate', onMultiOccurrenceDataListUpdate);
        }
    }
    
    //être à l'ecoute du message pour miser à jour le datalist
    $rootScope.$on('monoOccurrence.infosMonetaires.updateDataList', () => {
        onMultiOccurrenceDataListUpdate();
    });

    function onMultiOccurrenceDataListUpdate() {
        if (!vm.initialized) {
            vm.initialized = true;           
        }
        vm.fetchData();
    }

    function fetchData(): void {
        vm.fetchingData = true;
        const cycleId = vm.monoOccurrence.id;
        const srccod = vm.ecranContextCtrl.stateParams.srccod;

        const requete = $resource(`${ApiConfig.ROOT}/infos-monetaires/:cycleId`, {
            cycleId,
            srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        });

        requete.get().$promise
            .then((infosMonetaires: IInfosMonetairesData) => {
                vm.dataError = false;
                vm.data = infosMonetaires;
            })
            .catch(() => {
                vm.dataError = true;
            })
            .finally(() => {
                vm.fetchingData = false;
            });
    }

    function isValidValue(champ: IInfoMonetairesOptions): boolean {
        return Boolean(
            typeof champ.expectedValue === 'undefined' ||
            champ.expectedValue === vm.data[champ.nom] ||
            (!isNaN(Number(vm.data[champ.nom])) && champ.expectedValue === Number(vm.data[champ.nom]))
        );
    }

    //le paramètre flag permet de retourner la valeur pour le fonctionnement normal, ajouté dans le html
    function validationChamp(champ: IInfoMonetairesOptions, flag: boolean): boolean {
        //fonctionnement normal
        if (typeof (champ.flagShowField) === "undefined") {
            return flag;
        }
        //validation, si le champ est un array
        if (!Array.isArray(champ.flagShowField)) {
            return flag;
        }

        var result = true;
        //mettre la condition des champs de l'array du genre if(champ && champ)
        champ.flagShowField.map((el: any) => {
            if(!isNaN(vm.data[el])){
                result = result && (vm.data[el].toString() !== "0.00") ? true : false;
            }else{
                result=false;
            }
        });
        return result;
    }
}
