import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MenuListController from './ex-card-menu-list.controller';

export default module('core.components.ex-card-menu-list', []).component('exCardMenuList', CardMenuListFactory());

function CardMenuListFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.LIBELLES.OPTIONAL,
            Binding.COLLAPSABLE.OPTIONAL,
            Binding.NO_HEADER.OPTIONAL,
            {
                menu: '<?',
                menuItems: '<?',
                lblTitre: '@?',
                layoutAlign: '@?contentLayoutAlign',
                layout: '@?contentLayout'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            dataSourceCtrl: '?^exDataSource',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            content: '?exCardMenuListContent'
        },
        controller: MenuListController,
        controllerAs: 'vm',
        template: require('./ex-card-menu-list.html')
    };
}
