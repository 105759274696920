import {module} from 'angular';

export default module('core.filters.ex-code-postal', []).filter('exCodePostal', codePostalFilter);

export interface IFilterCodePostal {
    (value: string): any;
}

/* @ngInject */
function codePostalFilter(): IFilterCodePostal {
    return (value: string): any => {
        if (!value) {
            return value;
        } else {
            const valueUpper = value.toUpperCase().replace(/\ /g, "");

            return valueUpper.substr(0, 3) + ' ' + valueUpper.substring(3);
        }
    }
}
