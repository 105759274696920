import {module} from 'angular';
import {IEcranDetailsResourcesClass, IEcranDetailsResourcesEntite} from '../resources/ecran-details.resource';

export default module('core.services.ecran-details', [
]).factory('ecranDetails', EcranDetailsServiceFactory);

/* @ngInject */
function EcranDetailsServiceFactory(EcranDetailsResource: IEcranDetailsResourcesClass): IEcranDetailsResourcesEntite {
    return new EcranDetailsResource();
}
