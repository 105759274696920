import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IOperateur } from '../../services/operateur.service';
import { IComponentController } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataType } from '../../services/data-types/data-type.service';

interface IComposantPastilleFiltre extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    operateurEgale: IOperateur;
    filtre: IFiltre;
    col: string;
    modifiable: boolean;
    lblRechercheGlobale: string;
    dataType: IDataType;

    isRechercheGlobale(colonne: string): boolean;
    handleEditFiltre(event: any): any;
    editAction(event: any): any;
    getEnteteLibelle(colonne: string): string;
}
/* @ngInject */
export default function PastilleFiltreController(defaults: IDefaultsService, Filtre: IFiltreClass) {
    const vm: IComposantPastilleFiltre = this;

    vm.$onInit = $onInit;
    vm.isRechercheGlobale = isRechercheGlobale;
    vm.handleEditFiltre = handleEditFiltre;
    vm.getEnteteLibelle = getEnteteLibelle;

    function $onInit() {
        defaults(vm, {
            lblRechercheGlobale: 'G_LBL_BAR_FILTRE_TOUTES_COL',
            lblSuffixValeur: ''
        });

        if ((!vm.valeur || !vm.valeur.length) && vm.multiOccurrence.criteresSuggeres && vm.col !== "*") {
            vm.valeur = vm.multiOccurrence.getCritereSuggereDefaultValue(vm.col);
        }

        vm.dataType = vm.multiOccurrence.dataTypes[vm.col]
    }

    function getEnteteLibelle(col: string) {
        return vm.multiOccurrence.getEnteteLibelle(col, vm.multiOccurrence.colonnesEnteteTitre, true);
    }

    function isRechercheGlobale(colonne: string) {
        return colonne === Filtre.CLE_RECHERCHE_GLOBALE;
    }

    function handleEditFiltre(event: any) {
        if (vm.modifiable) {
            vm.editAction(event);
        }
    }
}
