import {module} from 'angular';
import {IFormulaireItemHiddenFonction} from './formulaire-item.service';
import {IFormulaireItemLibellesContour} from './formulaire-item.service';


export interface IFormulaireItemSautDeColonneClass {
    new(options?: IFormulaireItemSautDeColonneOptions): IFormulaireItemSautDeColonne;
}

export interface IFormulaireItemSautDeColonne {
    readonly largeur: number|string;
}

export interface IFormulaireItemSautDeColonneOptions {
    largeur?: number|string;
    minWidth?: number;
    hidden?: boolean | IFormulaireItemHiddenFonction;
    libellesContour?: IFormulaireItemLibellesContour;
}

export default module('core.services.formulaire-saut-de-colonne', []).factory('FormulaireItemSautDeColonne', FormulaireItemSautDeColonneFactory);

/* @ngInject */
function FormulaireItemSautDeColonneFactory() {


    class IFormulaireItemSautDeColonne implements IFormulaireItemSautDeColonne {

        readonly largeur: number|string = null;
        readonly minWidth: number;
        readonly hidden: boolean | IFormulaireItemHiddenFonction;
        readonly libellesContour: IFormulaireItemLibellesContour;

        constructor(options: IFormulaireItemSautDeColonneOptions = {}) {
            if (typeof options.largeur !== 'undefined') {
                this.largeur = options.largeur;
            }

            if (typeof options.hidden !== 'undefined') {
                this.hidden = options.hidden;
            }

            if (typeof options.minWidth !== 'undefined') {
                this.minWidth = options.minWidth;
            }

            if (typeof options.libellesContour !== 'undefined') {
                this.libellesContour = options.libellesContour;
            }
        }
    }
    return IFormulaireItemSautDeColonne;
}
