import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IHyperlienDataTypeClass {
    new(options?: IHyperlienDataTypeOptions): IHyperlienDataType;
}

export interface IHyperlienDataTypeOptions extends IDataTypeOptions {
}

export interface IHyperlienDataType extends IDataType {
}

export default module('core.services.hyperlien-data-type', []).factory('HyperlienDataType', HyperlienDataTypeFactory);

/* @ngInject */
function HyperlienDataTypeFactory(DataType: IDataTypeClass): IHyperlienDataTypeClass {
    class HyperlienDataType extends DataType implements IHyperlienDataType {
        readonly template: string = `<a ng-if="vm.data[vm.col]" href="{{vm.data[vm.col]}}" target="_blank">{{vm.data[vm.col]}}</a>`;
    }

    return HyperlienDataType;
}
