import { module } from 'angular';
import DialogCycleTransactionnelActionsController from './dialog-cycle-transactionnel-actions.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-cycle-transactionnel-actions', [
    'core.services.dialog'
]).factory('DialogCycleTransactionnelActions', DialogCycleTransactionnelActionsFactory);

/* @ngInject */
function DialogCycleTransactionnelActionsFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCycleTransactionnelActionsController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-cycle-transactionnel-actions.html'),
        locals: {
            icon: 'info_outline',
            lblTitre: 'G_LBL_INFOS_CYCLE_TRANSACTIONNNEL',
            lblCancel: 'G_LBL_BTN_FERMER',
            lblChoisirAction: 'G_LBL_CHOISIR_ACTION',
            hideCancelAction: true
        }
    });
}
