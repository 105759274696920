import {module} from 'angular';


export interface IFormulaireItemSautDeLigneClass {
    new(): IFormulaireItemSautDeLigne;
}

export interface IFormulaireItemSautDeLigne {
}

export default module('core.services.formulaire-saut-de-ligne', []).factory('FormulaireItemSautDeLigne', FormulaireItemSautDeLigneFactory);

/* @ngInject */
function FormulaireItemSautDeLigneFactory() {


    class FormulaireItemSautDeLigne implements IFormulaireItemSautDeLigne {
        constructor() {}
    }
    return FormulaireItemSautDeLigne;
}
