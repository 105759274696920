import { IComponentController } from 'angular';

interface IComposantSelecteurTitre extends IComponentController {
    lblColonneLeft: string;
    lblColonneRight: string;
    champsDisponibles: { champ: string, titre: string }[]
    champsChoisis: { champ: string, titre: string }[]
    transferAllToRight: () => void;
    transferAllToLeft: () => void;
}

/* @ngInject */
export default function SelecteurTitreController() {
    const vm: IComposantSelecteurTitre = this;

    vm.$onInit = function $onInit() {
        vm.lblColonneLeft = vm.lblColonneLeft || 'G_LBL_CHAMP_GAUCHE';
        vm.lblColonneRight = vm.lblColonneRight || 'G_LBL_CHAMP_DROITE';
    }

    vm.transferAllToRight = function transferAllToRight() {
        vm.champsChoisis.push(...vm.champsDisponibles)
        vm.champsDisponibles.splice(0, vm.champsDisponibles.length)
    }

    vm.transferAllToLeft = function transferAllToLeft() {
        vm.champsDisponibles.push(...vm.champsChoisis)
        vm.champsChoisis.splice(0, vm.champsChoisis.length)
    }
}
