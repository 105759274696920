import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogCompteurLignesController from './dialog-compteur-lignes.controller';

export default module('core.dialogs.dialog-compteur-lignes', [
    'core.services.dialog'
]).factory('DialogCompteurLignes', DialogCompteurLignesFactory);

/* @ngInject */
function DialogCompteurLignesFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCompteurLignesController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-compteur-lignes.html')
    });
}
