import {IFullscreen} from '../../services/fullscreen.service';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IComponentController} from 'angular';

interface IComposantDashboardCard extends IComponentController {
    fullscreen: IFullscreen;
    left: number;
    right: number;
}

/* @ngInject */
export default function DashboardCardController(defaults: IDefaultsService, fullscreen: IFullscreen) {

    const vm: IComposantDashboardCard = this;

    vm.$onInit = $onInit;
    vm.fullscreen = fullscreen;


    function $onInit(): void {
        defaults(vm, {
            left: 50,
            right: 50
        });
    }
}
