/**
 * Composant représentant un bouton Nouveau
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import BoutonNouveauController from './ex-bouton-nouveau.controller';

export default module('core.components.ex-bouton-nouveau', []).component('exBoutonNouveau', BoutonNouveauFactory());

function BoutonNouveauFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MENU,
            Binding.LIBELLES.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            {
                occurrence: '<?',
                options: '<?',
                text: '<?'
            }
        ),
        require: {
            dataSourceCtrl: '?^exDataSource',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: BoutonNouveauController,
        controllerAs: 'vm',
        template: require('./ex-bouton-nouveau.html')
    };
}
