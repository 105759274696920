import {IAttributes} from 'angular';

export interface IBlocBehavior {
    bloc: string;
}

interface IBlocAttributes extends IAttributes {
    exBloc: string;
}

/* @ngInject */
export default function BlocController($attrs: IBlocAttributes) {
    const vm: IBlocBehavior = this;

    const bloc = $attrs.exBloc;
    vm.bloc = bloc.toUpperCase();

    $attrs.$observe('exBloc', (bloc: string) => {
        vm.bloc = bloc.toUpperCase();
    });
}
