import {IAugmentedJQuery, IScope, module} from 'angular';

export default module('core.components.ex-checkbox-container', [])
    .directive('exCheckboxContainer', CheckboxContainerDirective);

/* @ngInject */
function CheckboxContainerDirective() {
    return {
        transclude: true,
        template: require('./ex-checkbox-container.html'),
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery) {
        const labelElement = element.find('label');

        element.on('focusin', 'md-radio-group', (event: JQueryEventObject) => {
            if (!element.find(event.target).attr('disabled')) {
                labelElement.addClass('ex-checkbox-focused');
            }
        });

        element.on('focusout', 'md-radio-group', (event: JQueryEventObject) => {
            if (!element.find(event.target).attr('disabled')) {
                labelElement.removeClass('ex-checkbox-focused');
            }
        });

        element.on('$destroy', () => {
            //On s'assure de retirer les listeners
            element.off();
        })
    }
}
