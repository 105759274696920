import { IPagination } from '../../services/pagination.service';
import { IComponentController } from 'angular';
import { IMultiOccurrence } from "../../services/multi-occurrence.service";

interface IComposantPagination extends IComponentController {
    pagination: IPagination;
    multiOccurrence: IMultiOccurrence
    getLibelleNombreElement(): string;
}

export default function PaginationController() {
    const vm: IComposantPagination = this;

    vm.getLibelleNombreElement = function getLibelleNombreElement() {
        const start = (vm.pagination.pageCourante - 1) * vm.pagination.nombreElementPage + 1;
        if (vm.pagination.total < vm.pagination.nombreElementPage + 1) {
            return `${start} - ${start + vm.pagination.total - 1}`;
        } else {
            return `${start} - ${start + vm.pagination.total - 2}`;
        }
    }

    vm.getLastPage = function getLastPage() {
        vm.multiOccurrence.fetchNombreResultatsTrouves().then((resultat: number) => {
            vm.pagination.pageCourante = Math.ceil(resultat / vm.pagination.nombreElementPage)
        })
    }
}
