import {IComponentOptions, module} from 'angular';
import TabsController from './ex-tabs.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-tabs', [])
    .component('exTabs', TabsFactory());

function TabsFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                selected: '=?exSelectedTab',
                sticky: '<?',
                stickyOptions: '<?',
                etatTemporaire: '<?'
            }
        ),
        require: {
            formulaireInputCtrl: '^?exFormulaireInput',
             ecranContextCtrl: '^^exEcranContext'
        },
        transclude: true,
        controllerAs: 'vm',
        controller: TabsController,
        template: require('./ex-tabs.html')
    };
}
