import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import {ILovEntiteExterneDataTypeOptions} from './lov-entite-externe-data-type.service';
import * as angular from 'angular';

export interface ILovDataTypeClass {
    new(options?: ILovDataTypeOptions): ILovDataType;
}

export interface ILovDataTypeOptions extends IDataTypeOptions {
}

export interface ILovDataType extends IDataType {
}

export default module('core.services.lov-data-type', []).factory('LovDataType', LovDataTypeFactory);

/* @ngInject */
function LovDataTypeFactory(DataType: IDataTypeClass): ILovDataTypeClass {
    class LovDataType extends DataType implements ILovDataType {
        readonly template: string = `{{vm.col | exLov : vm.data : vm.dataType}}`;

        constructor(options: ILovEntiteExterneDataTypeOptions) {
            super(angular.extend({
                alignment: 'left'
            }, options));
        }
    }

    return LovDataType;
}
