import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IValidateItemOptions {}

export interface IValidateItem {}

export interface IValidateItemResource extends IResourceClass<any> {
    new(data: IValidateItemOptions): IResource<IValidateItem>;
}

export default module('core.resources.validate-item', [
    'core.services.api-config'
]).factory('ValidateItemResource', ValidateItemResourceFactory);

/* @ngInject */
function ValidateItemResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IValidateItemResource {
    return <IValidateItemResource>$resource(`${ApiConfig.ROOT}/validate-item/:srccod/:champ/:ids?`);
}
