import * as angular from 'angular';
import {module} from 'angular';
import {IMenuItemClass, IMenuItemFactory, IMenuItemOptions} from './menu-item.service';
import {IMenu, IMenuClass, MenuElement} from './menu.service';
import {IMenuDividerClass} from './menu-divider.service';
import {IMenuItemForageClass} from "./menu-item-forage.service";
import {IData} from "../data-linker.service";
import {IRoute} from "../../interfaces/route.interface";
import IInjectorService = angular.auto.IInjectorService;
import {IProfil, IProfilAcces} from "../../resources/profil.resource";
import {IParametresSecuriteService} from "../parametres-securite.service";
import {IMenuNavigationItem} from "../../interfaces/menu-navigation-item.interface";


export interface IMenuMoreClass {
    new(menuMoreTypes?: IMenuMoreTypes, autresActions?: IMenu, menuMoreTypesOptions?:IMenuMoreTypesOptions): IMenu;
}

export interface IMenuMore extends IMenu {}

export interface IMenuMoreTypes {
    ajout?: Function;
    supprime?: Function;
    suiviModification?: Function;
    edition?: Function;
    dossierEmploye?: boolean;
    [key: string]: boolean|Function;
}

export interface IMenuMoreTypesOptions {
    ajout?: IMenuItemOptions;
    supprime?: IMenuItemOptions;
    edition?: IMenuItemOptions;
    [key: string]: IMenuItemOptions;
}

interface IMenuMoreMap {
    ajout: IMenuItemFactory;
    supprime: IMenuItemFactory;
    suiviModification: IMenuItemFactory;
    edition: IMenuItemFactory;
    [key: string]: IMenuItemFactory;
}

export default module('core.services.menu-more', []).factory('MenuMore', MenuMoreFactory);

/* @ngInject */
function MenuMoreFactory(MenuItem: IMenuItemClass,
                         MenuItemForage: IMenuItemForageClass,
                         Menu: IMenuClass,
                         $injector: IInjectorService,
                         MenuDivider: IMenuDividerClass,
                         parametresSecurite: IParametresSecuriteService,
                         profil: IProfil) {

    const hasDossierEmploye: boolean = $injector.has('rr0003Route');

    function getMenuMore(menuMoreTypes: IMenuMoreTypes = {}, autresActions?: IMenu, menuMoreTypesOptions?:IMenuMoreTypesOptions) {

        const typeMenuItem = [
            'ajout',
            'edition',
            'supprime'
        ];

        if (typeof menuMoreTypesOptions === 'undefined') {
            menuMoreTypesOptions = {};
        }

        const typesDisponibles: IMenuMoreMap = {
            suiviModification: MenuItem.SuiviModificationMenuItem,
            ajout: MenuItem.AjoutMenuItem,
            supprime: MenuItem.SuppressionMenuItem,
            edition: MenuItem.EditionMenuItem
        };

        const menuMore: Array<MenuElement> = [];
        typeMenuItem
            .forEach((type: string) => {
                if (menuMoreTypes[type] && menuMoreTypes[type] instanceof Function) {
                    menuMore.push(typesDisponibles[type]((<Function>menuMoreTypes[type]),menuMoreTypesOptions[type]));
                }
            });

        if (autresActions) {
            if (autresActions.positionFirst) {
                autresActions.listeMenuItem.push(new MenuDivider());
                menuMore.unshift(...autresActions.listeMenuItem);
            } else {
                if (menuMore.length) {
                    menuMore.push(new MenuDivider());
                }
                menuMore.push(...autresActions.listeMenuItem);
            }
        }

        if (menuMoreTypes.suiviModification || menuMoreTypes.dossierEmploye) {
            if (menuMore.length && !(menuMore[menuMore.length - 1] instanceof MenuDivider)) {
                menuMore.push(new MenuDivider());
            }

            if (menuMoreTypes.dossierEmploye && hasDossierEmploye) {
                menuMore.push(new MenuItemForage(
                    'G_LBL_DOSSIER_EMPLOYE',
                    getForageDossierEmploye,
                    getParamsDossierEmploye,
                    {
                        fonction: 'BTN_EMP',
                        icon: 'recent_actors'
                    }
                ));
            }

            if (menuMoreTypes.suiviModification) {
                menuMore.push(typesDisponibles.suiviModification(menuMoreTypes.suiviModification, menuMoreTypesOptions.suiviModification));
            }
        }

        return new Menu(menuMore, {
            lblTooltip: 'G_LBL_BTN_ACTIONS_COMPLEMENTAIRES',
            tooltipDirection: 'bottom'
        });
    }

    function getForageDossierEmploye(data: IData) {
        if (data.$flgcen) {
            return ($injector.get('rr0003Route') as IRoute).NAME;
        } else {
            return ($injector.get('rr0093Route') as IRoute).NAME;
        }
    }

    function getParamsDossierEmploye (data: IData) {
        let menuId;
        const routeName = getForageDossierEmploye(data);
        const {pracleint} = parametresSecurite(data.$params);
        const profilAcces = profil.profilsAcces
            .find((profilAcces: IProfilAcces) => {
                return (profilAcces.pracleint === pracleint);
            });

        if (profilAcces && profilAcces.menusEmploye) {
            const menuDestination = profilAcces.menusEmploye.find((menu: IMenuNavigationItem) => menu.stateName === routeName);

            if (menuDestination) {
                menuId = menuDestination.menucleref
            }
        }
        return {
            employe: data.demcleint || data.employe_demcleint,
            menuId
        };
    }

    return getMenuMore;
}
