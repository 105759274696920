import * as angular from 'angular';
import {module} from 'angular';
import {IStringDataType, IStringDataTypeClass, IStringDataTypeOptions} from './string-data-type.service';
import {IOperateurService} from '../operateur.service';

export interface IPeriodeDataTypeClass {
    new(options?: IPeriodeDataTypeOptions): IPeriodeDataType;
}

export interface IPeriodeDataTypeOptions extends IStringDataTypeOptions {}
export interface IPeriodeDataType extends IStringDataType {}

export default module('core.services.periode-data-type', []).factory('PeriodeDataType', PeriodeDataTypeFactory);

/* @ngInject */
function PeriodeDataTypeFactory(StringDataType: IStringDataTypeClass, Operateur: IOperateurService): IPeriodeDataTypeClass {
    class PeriodeDataType extends StringDataType {
        constructor(options: IPeriodeDataTypeOptions = {}) {
            super(angular.extend({}, options, {
                operateurs: Operateur.BASIC_OPERATEURS
            }));
        }
    }

    return PeriodeDataType;
}
