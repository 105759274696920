import { IAugmentedJQuery, IComponentController } from 'angular';
import { applyAutoScroll } from '../../utils/apply-auto-scroll.utils';
import { IDefaultsService } from '../../services/utils/defaults.service';

interface IDialogSelecteurTitre extends IComponentController {
    lblTitre: string;
    icon: string;
    lblDescription: string;
    lblSelecteurLeft: string;
    lblSelecteurRight: string;
    confirm(): void;
}
/* @ngInject */
export default function DialogSelecteurTitreController($element: IAugmentedJQuery, defaults: IDefaultsService) {
    const vm: IDialogSelecteurTitre = this;

    vm.$onInit = function $onInit() {
        applyAutoScroll([$element.find('.ex-dialog-content').get(0)]);

        defaults(vm, {
            icon: 'view_column',
            lblTitre: 'G_LBL_SELECTEUR_TITRE',
            lblDescription: 'G_LBL_SELECTEUR_TITRE_DESC',
            lblSelecteurLeft: 'G_LBL_MSL_CHAMP_DISPONIBLE',
            lblSelecteurRight: 'G_LBL_MSL_CHAMP_AFFICHE'
        });
    }
}
