import {IComponentOptions, module} from 'angular';
import InputValidationController from './ex-input-validation.controller';

export default module('core.components.ex-input-validation', [])
    .component('exInputValidation', InputValidationFactory());

function InputValidationFactory(): IComponentOptions {
    return {
        bindings: {
          input: '<'
        },
        controller: InputValidationController,
        transclude: true,
        controllerAs: 'vm',
        template: require('./ex-input-validation.html')
    };
}
