import {IAugmentedJQuery, module} from 'angular';
import {IEcranDetailsResourcesEntite} from '../resources/ecran-details.resource';

export interface IDataLinker {
    link(element: IAugmentedJQuery, data: any, stateParams: any, ecranDetails: IEcranDetailsResourcesEntite): void;
}

export interface IData {
    [index: string]: any;
    $id: number;
    $flgcen: number;
    $linked: boolean;
    $valeurs: any;
    $paramsEcran: any;
    $params: any;
    $libelles: any;
    $parent: IData;
    $ancetre: IData;
    $bloc(bloc: string): IData|Object;
    $closest(col: string): IData;
}

interface IDataParent {
    element: IAugmentedJQuery;
    cleint: string;
    data: IData;
    bloc: string
}

interface IDataBlocs {
    [bloc: string]: IData|Object;
}

export default module('core.services.data-linker', []).factory('dataLinker', FormulaireFactory);

function FormulaireFactory() {
    // On utilise un symbol pour prévenir les usages extérieurs
    const $parent$ = Symbol();
    let resetParents: boolean = false;

    class DataLinkerFactory implements IDataLinker {
        link(element: IAugmentedJQuery, data: any, stateParams: any, ecranDetails: IEcranDetailsResourcesEntite) {
            if (!data.$linked) {
                Object.defineProperties(data, {
                    $linked: {
                        value: true,
                        enumerable: false
                    },
                    $id: {
                        get: () => {
                            const parent = data[$parent$][data[$parent$].length - 1];
                            if (parent) {
                                return data[parent.cleint];
                            } else {
                                return null;
                            }
                        },
                        enumerable: false
                    },
                    $flgcen: {
                        get: () => ecranDetails.flgcen,
                        enumerable: false
                    },
                    $valeurs: {
                        get: () => ecranDetails.valeurs,
                        enumerable: false
                    },
                    $paramsEcran: {
                        get: () => ecranDetails.params,
                        enumerable: false
                    },
                    $params: {
                        get: () => stateParams,
                        enumerable: false
                    },
                    $parent: {
                        get: () => (data[$parent$][data[$parent$].length -1] && data[$parent$][data[$parent$].length -1].data) || {},
                        enumerable: false
                    },
                    $ancetre: {
                        get: () => (data[$parent$][0] && data[$parent$][0].data) || data,
                        enumerable: false
                    },
                    $blocs: {
                        // On utilise une closure pour lazy loader les blocs. On les loads au premier usage, mais pas avant
                        get: (() => {
                            let blocs: IDataBlocs;
                            return () => {
                                if (!blocs) {
                                    if (!data[$parent$].length && element.attr('id')) {
                                        element = $(`#${element.attr('id')}`);
                                        resetParents = true;
                                    }

                                    blocs = data[$parent$].reduce((result: IDataBlocs, parent: IDataParent) => {
                                        result[parent.bloc.toLowerCase()] = parent.data || {};
                                        return result;
                                    }, {});
                                }
                                return blocs;
                            };
                        })(),
                        enumerable: false
                    },
                    [$parent$]: {
                        // On utilise une closure pour lazy loader les parents. On les loads au premier usage, mais pas avant
                        get: (() => {
                            let parents: Array<IDataParent>;
                            return () => {
                                if (!parents || resetParents) {
                                    resetParents = false;
                                    parents = this.getParents(element);
                                }
                                return parents;
                            };
                        })(),
                        enumerable: false
                    }
                });
            }
        }

        private getParents(element: IAugmentedJQuery, parents: Array<IDataParent> = []): Array<IDataParent> {
            const parentComposantElement = element.closest('ex-data-source,ex-mono-occurrence,ex-multi-occurrence,ex-multi-occurrence-maitre-details');

            if (!parentComposantElement.length) {
                return parents;
            } else {
                return this.addParent(parentComposantElement, parents);
            }
        }

        private addParent(parentComposantElement: IAugmentedJQuery, parents: Array<IDataParent>): Array<IDataParent> {
            const parentSource = this.getParentSource(parentComposantElement);

            if (parentSource) {
                parents.unshift({
                    element: parentComposantElement,
                    cleint: parentSource.cleint,
                    // Le getter permet de toujours rammener la dernière version
                    get data() {
                        return parentSource.data
                    },
                    bloc: parentSource.bloc || parentSource.mnemonique
                });
            }

            return this.getParents(parentComposantElement.parent(), parents);
        }

        private getParentSource(parentComposantElement: IAugmentedJQuery) {
            const tagName = parentComposantElement.prop('tagName');

            switch (tagName) {
                case 'EX-DATA-SOURCE':
                    return parentComposantElement.controller('exDataSource');
                case 'EX-MULTI-OCCURRENCE':
                case 'EX-MULTI-OCCURRENCE-MAITRE-DETAILS':
                    const controllerName = (tagName === 'EX-MULTI-OCCURRENCE') ? 'exMultiOccurrence' : 'exMultiOccurrenceMaitreDetails';
                    const multiOccurrenceCtrl = parentComposantElement.controller(controllerName);
                    const cleint = multiOccurrenceCtrl.multiOccurrence.cleint;
                    let rowData = {};
                    if (multiOccurrenceCtrl.multiOccurrence.dataList && multiOccurrenceCtrl.multiOccurrence.dataList.length) {
                        //Dans le cas d'un multiOccurrence, on utilise les données de la ligne courante comme source de données.
                        rowData = multiOccurrenceCtrl.multiOccurrence.dataList.find((row: any) => row[cleint] === multiOccurrenceCtrl.multiOccurrence.activeRowCleint);
                    }
                    return {
                        cleint,
                        data: rowData,
                        bloc: multiOccurrenceCtrl.multiOccurrence.bloc,
                        mnemonique: multiOccurrenceCtrl.multiOccurrence.mnemonique
                    };
                case 'EX-MONO-OCCURRENCE':
                    const monoOccurrenceCtrl = parentComposantElement.controller('exMonoOccurrence');
                    return monoOccurrenceCtrl.monoOccurrence;
            }
        }
    }

    return new DataLinkerFactory();
}
