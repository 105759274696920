import * as angular from 'angular';
import {ICompileService, IDocumentService, ILogService, IScope, module} from 'angular';

export default module('core.services.fullscreen', []).factory('fullscreen', FullscreenFactory);


export interface IFullscreen {
    name: string;
    isFullscreen: boolean;
    open(name: string, scope?: IScope): void;
    close(): void;
    register(name: string, template: string): void;
    deregister(name: string): void;
}
/* @ngInject */
function FullscreenFactory($document: IDocumentService, $compile: ICompileService, $log: ILogService) {

    class Fullscreen implements IFullscreen {
        name: string;
        isFullscreen: boolean = false;
        elementParentInitial: JQuery;
        elementIndexInitial: number;
        elementMap: any = {};

        open(name: string, scope?: IScope): void {
            this.isFullscreen = true;
            this.name = name;
            this.elementParentInitial = $document.find(`[ex-fullscreen="${name}"]`).parent();
            const element = $document.find(`[ex-fullscreen="${name}"]`);
            $log.log('La fonctionnalité fullscreen est obsolète en production puique les debug data sont désactivés.');
            const viewScope = scope || angular.element('[ui-view="content"] > [ui-view]').scope();

            viewScope.$on('$destroy', () => this.deregister(name));
            viewScope.$on('$stateChangeStart', () =>  this.close());

            const modale = $compile(this.elementMap[name])(viewScope);

            this.elementIndexInitial = element.index();

            modale.css({
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0px',
                zIndex: '5'
            });
            modale.children().css({
                margin: '0px'
            });
            modale.children().addClass('fullscreen-actif');
            $document.find(`[ui-view="content"]`).append(modale);
        }

        close(): void {
            const element = $document.find(`[ex-fullscreen="${this.name}"]`);
            const elementApres = this.elementParentInitial[0].children[this.elementIndexInitial];

            element.removeAttr("style");
            element.children().removeAttr("style");
            element.children().removeClass('fullscreen-actif');
            element.insertBefore(elementApres);

            this.isFullscreen = false;
        }

        register(name: string, template: string): void {
            this.elementMap[name] = template;
        }

        deregister(name: string): void {
            delete this.elementMap[name];
        }
    }
    return new Fullscreen();
}
