/**
 * Composante permettant d'afficher des messages d'erreurs
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-erreur-messages', []).component('exErreurMessages', ErreurMessagesFactory());

function ErreurMessagesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA.OPTIONAL,
            {
                messagesErreur: '<'
            }
        ),
        controllerAs: 'vm',
        template: require('./ex-erreur-messages.html')
    };
}

