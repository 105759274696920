import * as angular from 'angular';
import { IAugmentedJQuery, IPromise, IQService, module } from 'angular';
import { IInfosFinancieresOptions } from '../../apps/sofe/components/ex-card-infos-financieres/ex-card-infos-financieres.controller';
import { IInfosMonetaires } from '../components/ex-card-infos-monetaires/ex-card-infos-monetaires.controller';
import { IException, IExceptions } from '../constants/exceptions.constant';
import { IApiConfig } from '../interfaces/api-config.interface';
import { IReglesValidation } from '../interfaces/regles-validation.interface';
import { ISchemas } from '../interfaces/schemas.interface';
import { IUpdatableResource } from '../interfaces/updatable-resource.interface';
import { IChampsEvenementsResource } from '../resources/champs-evenements.resource';
import { IChampsTransactionnels, IChampsTransactionnelsResource } from '../resources/champs-transactionnels.resource';
import { ICycleFinanceClass, IEtatActuelFinance, IProchainEtatFinance } from '../resources/cycle-finance.resource';
import { ICycleRhClass, IEtatActuelRH, IProchainEtatRh } from '../resources/cycle-rh.resource';
import { IEcranDetailsResourcesEntite } from '../resources/ecran-details.resource';
import { IMessages, ISourceDetails } from '../resources/source-details.resource';
import { IValeursDefautResource } from '../resources/valeurs-defaut.resource';
import { IDataTypeClass, IDataTypeMap } from './data-types/data-type.service';
import { IDownloadManagerService } from './download-manager.service';
import { IFormulaireItem, IFormulaireItemClass } from './formulaire/formulaire-item.service';
import { FormulaireElement, IFormulaire } from './formulaire/formulaire.service';
import { ILibelles } from './libelles.service';
import { IMenuItemForage } from './menu/menu-item-forage.service';
import { IMenu, IMenuClass } from './menu/menu.service';
import { IMultiOccurrence } from './multi-occurrence.service';
import { IOccurrence, IOccurrenceClass } from './occurrence.service';
import { ISourceDetailsManager } from './source-details-manager.service';
import { INotificationHandler } from './utils/notification-handler.service';
import { IComportementClass, IComportementMap } from './comportements.service';
import { IFormulaireGroupeAccordeonClass } from './formulaire/formulaire-groupe-accordeon.service';
import { IParametresSecuriteService } from './parametres-securite.service';
import { IFormulaireGroupe, IFormulaireGroupeClass } from './formulaire/formulaire-groupe.service';
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;
import IResourceService = angular.resource.IResourceService;
import IResourceInterceptor = angular.resource.IResourceInterceptor;
import { IChangementManager } from './changement-manager.service';
import {
    IFormulaireGroupeConditionnel,
    IFormulaireGroupeConditionnelClass
} from './formulaire/formulaire-groupe-conditionnel.service';
import { IProfil } from '../resources/profil.resource';
import { IFonctionsEdition, IFonctionsEditionClass } from './fonctions-edition.service';
import { IDataLinker } from './data-linker.service';
import { IFilterLibelle } from '../filters/ex-libelle.filter';
import { IUploadManagerService } from "./upload-manager.service";
export interface IMonoOccurrenceClass {
    new(options?: IMonoOccurrenceOptions): IMonoOccurrence;
}

export interface IMonoOccurrenceResourceClass extends IResourceClass<any> {
    new(data: any): IMonoOccurrenceResource;

    update(data: any, params: any): IResource<any>;
    download(params: any, data: any): IResource<any>;
}

export interface IMonoOccurrenceResource extends IResource<any>, IUpdatableResource<any> {
    [col: string]: any;
}

export interface IMonoOccurrence extends IOccurrence {
    readonly formulaire: IFormulaire;
    readonly srccod: string;
    readonly srccodref: string;
    readonly bloc: string;
    paramDefaults: IMonoOccurrenceParamDefaults;
    readonly DataResource: IMonoOccurrenceResourceClass;
    readonly ready: IPromise<any>;
    readonly nomSourceDetails: string;
    readonly actionsMore: IMenu;
    readonly lblBoutonEnregistrer: string;
    readonly stateParams: any;
    readonly ecranDetails: IEcranDetailsResourcesEntite;
    readonly ecranSourceDetails: ISourceDetails;
    readonly pageRetourSuppression: string;
    readonly paramsRetourSuppression: any | Function;
    readonly updateDataWith: Array<string>;
    readonly resourceParams: any;

    id: string | number;
    noId: boolean;
    multiOccurrenceParent: IMultiOccurrence;
    isEdition: boolean;
    initialized: boolean;
    initializing: boolean;
    initError: boolean;
    data: IMonoOccurrenceResource;
    fetchingData: boolean;
    dataError: boolean;
    dataReady: boolean;
    dataErrorType: IException;
    cycleErrorReloadBlocs: Array<string>;
    saving: boolean;
    successActionSauvegarde: Function;
    autoFetch: boolean;

    // Valeurs par défaut
    procedureValeursDefautDefinie: boolean;
    valeursDefaut: any;
    fetchingValeursDefaut: boolean;
    valeursDefautError: boolean;

    // Source details
    cleint: string;
    cycleCleint: string;
    cycleCleintParent: string;
    cycleParent: string;
    schema: ISchemas;
    dataTypes: IDataTypeMap;
    comportements: IComportementMap;
    fonctionsEdition: IFonctionsEdition;
    validatedFields: Array<string>;
    listenedFields: Array<string>;
    reglesValidation: IReglesValidation;
    libelles: ILibelles;
    mnemonique: string;
    messages: IMessages;
    champsTransactionnelsError: boolean;
    fetchingChampsTransactionnels: boolean;
    champsTransactionnels: IChampsTransactionnels;
    etatActuel: IEtatActuelRH | IEtatActuelFinance;
    colonnesEnteteTitre: any;
    prochainEtat: IProchainEtatRh | IProchainEtatFinance;
    fetchingEtatsCycle: boolean;
    etatsCycleError: boolean;
    downloadingData: boolean;
    fonctions: IMonoOccurrenceFonctions;
    icon: string;
    entetesReplacedValues?: any;
    element: angular.IAugmentedJQuery;
    isPortailEmploye?: boolean
    // Titre fonction transversale
    fonctionsTransversalesTitreErreur: boolean;
    titreBlocFonctionTransversale: string;
    promiseTitreFonctionTransversale: IPromise<void>;

    fetchTitreBlocFonctionTransversale(): IPromise<any>;
    init(): void;
    save(data: any, params?: any): IPromise<any>;
    isSaveable(data?: any): boolean;
    delete(id: number | string, additionnalParams?: any): IPromise<any>;
    fetchData(): IPromise<any>;
    softUpdateData(params?: any): void;
    resetData(params?: any): Promise<any>;
    fetchEtatsCycle(): IPromise<void>;
    refreshChampsTransactionnels(): IPromise<IChampsTransactionnels>;
    fetchValeursDefaut(params?: any): IPromise<any>;
    downloadData(parentElement: IAugmentedJQuery): IPromise<void>;
    getNomSourceDetails(): string;
    getDataResourceUrl(): string;
    getSrccodref(): string;
    getParentIds(): Array<number | string>;
    hasSuppression(data: any): boolean;
    hasNouveau(data: any): boolean;
    hasEdition(data: any): boolean;
    shouldDisplaySaveButton(): boolean;
    hasChangementsNonSauvegardes(formData: any): boolean;
    verifierChangementsNonSauvegardes(formData: any, event?: MouseEvent): boolean;
}

interface IMonoOccurrenceParamDefaults {
    id: string | number;
}

export interface IMonoOccurrenceFonctions {
    [index: string]: boolean | Function;

    exportation?: boolean;
    importation?: boolean;
    supprime?: boolean | Function;
    edition?: boolean | Function;
    nouveau?: boolean | Function;
    suiviModification?: boolean;
    boutonRetour?: boolean;
    codeEcranBandeau?: boolean;
    boutonEnregistrerHaut?: boolean;
    afficherMenusCreation?: boolean;
    afficherMenusDansEntete?: boolean;
    zoneDroiteVisibleCreation?: boolean;
    hideActionsCycle?: boolean;
    skipChangementsNonSauvegardes?: boolean;
    refreshForageIdWithCycle?: boolean;
    boutonDupliquer?: boolean | ((data: any) => boolean)
}

export interface IMonoOccurrenceOptions {
    srccod?: string;
    srccodref?: string;
    resourceUrl?: string;
    nomSourceDetails?: string;
    lblBoutonEnregistrer?: string;
    multiOccurrenceParent?: IMultiOccurrence;
    monoOccurrenceParentId?: number | string;
    resourceParams?: {};
    isEdition?: boolean;
    bloc?: string;
    id?: string | number;
    noId?: boolean;
    formulaire?: IFormulaire;
    cycleErrorReloadBlocs?: Array<string>;
    champsTransactionnels?: IChampsTransactionnels;
    infosMonetaires?: IInfosMonetaires;
    infosFinancieres?: IInfosFinancieresOptions;
    menus?: Array<IMenuItemForage>;
    successActionSauvegarde?: Function;
    actionsMore?: IMenu;
    stateParams?: any;
    ecranDetails?: IEcranDetailsResourcesEntite;
    ecranSourceDetails?: ISourceDetails;
    pageRetourSuppression?: string;
    paramsRetourSuppression?: any | Function;
    updateDataWith?: Array<string>;
    forageCreation?: string;
    element?: angular.IAugmentedJQuery;

    confirmationSauvegarde?: IMonoOccurrenceFonctionConfirmation;
    fonctions?: IMonoOccurrenceFonctions;
    icon?: string;
    autoFetch?: boolean;
    ecranEnteteSticky?:boolean
    isPortailEmploye?: boolean
    //cacher le titre au besoin
    hideTitreBlocFonctionTransversale?: boolean;
    //cacher le shadowbox du formulaire card
    hideShadowBox?:boolean;
    reloadPage?:boolean
    resetEtatDialog?: boolean;
}

interface IMonoOccurrenceFonctionConfirmation {
    lblTitre: string;
    formulaire: IFormulaire;
    lblMessageInfo?: string;
    icon?: string;
    largeur?: number;
    saveOnCancel?: boolean;
    conditionAffichage?: boolean | IFonctionConditionAffichage
}

export interface IFonctionConditionAffichage {
    (monoOccurrence: IMonoOccurrence, data?: any): boolean;
}

export default module('core.services.monoOccurrence', []).factory('MonoOccurrence', MonoOccurrenceFactory);

/* @ngInject */
function MonoOccurrenceFactory(Occurrence: IOccurrenceClass,
    ApiConfig: IApiConfig, Menu: IMenuClass,
    $resource: IResourceService,
    $q: IQService,
    DataType: IDataTypeClass,
    Exceptions: IExceptions,
    ChampsTransactionnelsResource: IChampsTransactionnelsResource,
    ChampsEvenementsResource: IChampsEvenementsResource,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    CycleRh: ICycleRhClass,
    sourceDetailsManager: ISourceDetailsManager,
    CycleFinance: ICycleFinanceClass,
    ValeursDefautResource: IValeursDefautResource,
    downloadManager: IDownloadManagerService,
    notificationHandler: INotificationHandler,
    Comportement: IComportementClass,
    FonctionsEdition: IFonctionsEditionClass,
    parametresSecurite: IParametresSecuriteService,
    profil: IProfil,
    messageHandlingInterceptor: IResourceInterceptor,
    changementManager: IChangementManager,
    dataLinker: IDataLinker,
    exLibelleFilter: IFilterLibelle,
    uploadManager: IUploadManagerService) {

    class MonoOccurrence extends Occurrence implements IMonoOccurrence {
        readonly formulaire: IFormulaire;
        paramDefaults: IMonoOccurrenceParamDefaults;
        readonly srccod: string;
        readonly srccodref: string;
        readonly bloc: string;
        readonly DataResource: IMonoOccurrenceResourceClass;
        readonly nomSourceDetails: string;
        readonly actionsMore: IMenu;
        readonly lblBoutonEnregistrer: string;
        readonly stateParams: any;
        readonly ecranDetails: IEcranDetailsResourcesEntite;
        readonly ecranSourceDetails: ISourceDetails;
        readonly pageRetourSuppression: string;
        readonly paramsRetourSuppression: any | Function;
        readonly updateDataWith: Array<string>;
        readonly resourceParams: any;
        private clonedData: any;

        cleint: string;
        cycleCleint: string;
        cycleCleintParent: string;
        cycleParent: string;
        cycleErrorReloadBlocs: Array<string> = [];
        schema: ISchemas;
        dataTypes: IDataTypeMap;
        comportements: IComportementMap;
        fonctionsEdition: IFonctionsEdition;
        validatedFields: Array<string>;
        listenedFields: Array<string>;
        reglesValidation: IReglesValidation;
        libelles: ILibelles;
        mnemonique: string;
        messages: IMessages;
        ready: IPromise<any>;

        id: string | number;
        noId: boolean;
        multiOccurrenceParent: IMultiOccurrence;
        monoOccurrenceParentId: number | string;
        isEdition: boolean;
        initialized: boolean;
        initializing: boolean;
        initError: boolean;
        data: IMonoOccurrenceResource;
        fetchingData: boolean = false;
        dataError: boolean = false;
        dataReady: boolean = false;
        dataErrorType: IException = null;
        saving: boolean = false;
        champsTransactionnelsError: boolean = false;
        fetchingChampsTransactionnels: boolean = false;
        champsTransactionnels: IChampsTransactionnels;
        etatActuel: IEtatActuelRH | IEtatActuelFinance;
        prochainEtat: IProchainEtatRh | IProchainEtatFinance;
        fetchingEtatsCycle: boolean = false;
        etatsCycleError: boolean = false;
        colonnesEnteteTitre: any;
        // Valeurs par défaut
        procedureValeursDefautDefinie: boolean;
        valeursDefaut: any;
        fetchingValeursDefaut: boolean = false;
        valeursDefautError: boolean = false;
        downloadingData: boolean = false;
        successActionSauvegarde: Function;
        confirmationSauvegarde: IMonoOccurrenceFonctionConfirmation;
        fonctions: IMonoOccurrenceFonctions;
        icon: string;
        entetesReplacedValues: any;
        autoFetch: boolean;
        ecranEnteteSticky?:boolean;
        isPortailEmploye?: boolean;
        hideTitreBlocFonctionTransversale?: boolean;
        hideShadowBox?:boolean;
        reloadPage?:boolean;
        resetEtatDialog?: boolean;

        // Titre fonction transversale
        fonctionsTransversalesTitreErreur: boolean = false;
        titreBlocFonctionTransversale: string;
        promiseTitreFonctionTransversale: IPromise<void>;
        fetchTitreBlocFonctionTransversale: () => IPromise<any>;
        element: angular.IAugmentedJQuery;
        resourceUrl: string;

        constructor(options: IMonoOccurrenceOptions) {
            super(options);

            this.formulaire = options.formulaire;
            this.stateParams = options.stateParams;
            this.ecranDetails = options.ecranDetails;
            this.ecranSourceDetails = options.ecranSourceDetails;
            this.pageRetourSuppression = options.pageRetourSuppression;
            this.paramsRetourSuppression = options.paramsRetourSuppression;
            this.updateDataWith = options.updateDataWith;
            this.srccod = options.srccod || this.stateParams.srccod;
            this.srccodref = options.srccodref;
            this.multiOccurrenceParent = options.multiOccurrenceParent;
            this.resourceParams = options.resourceParams || null;
            this.isEdition = options.isEdition;
            this.nomSourceDetails = options.nomSourceDetails;
            this.actionsMore = options.actionsMore || new Menu([]);
            this.noId = options.noId || false;
            this.lblBoutonEnregistrer = options.lblBoutonEnregistrer ||
                (this.stateParams.appliquerEnregistrement ? 'G_LBL_BTN_APPLIQUER' : 'G_LBL_BTN_ENREGISTRER');
            this.successActionSauvegarde = options.successActionSauvegarde || null;
            this.confirmationSauvegarde = options.confirmationSauvegarde;
            this.bloc = options.bloc || null;
            this.icon = options.icon;
            this.element = options.element || null;
            this.monoOccurrenceParentId = options.monoOccurrenceParentId;
            try{
                if(options.ecranEnteteSticky){
                    this.ecranEnteteSticky=options.ecranEnteteSticky
                }
                if(options.hideTitreBlocFonctionTransversale){
                    this.hideTitreBlocFonctionTransversale=options.hideTitreBlocFonctionTransversale;
                }
                if(options.hideShadowBox){
                    this.hideShadowBox=options.hideShadowBox;
                }
                if(options.reloadPage){
                    this.reloadPage=options.reloadPage;
                }
                if(options.resetEtatDialog){
                    this.resetEtatDialog=options.resetEtatDialog
                }
                if(options.isPortailEmploye){
                    this.isPortailEmploye=options.isPortailEmploye;
                }
            }catch(Exception){};
            this.fonctions = {
                // Défauts
                codeEcranBandeau: true,
                boutonRetour: true,
                boutonEnregistrerHaut: true,
                afficherMenusDansEntete: false,
                zoneDroiteVisibleCreation: false,
                refreshForageIdWithCycle: false,
                importation: false,
                skipChangementsNonSauvegardes: false,
                boutonDupliquer: true,
                // Options passées
                ...options.fonctions
            };

            if (options.cycleErrorReloadBlocs) {
                this.cycleErrorReloadBlocs = options.cycleErrorReloadBlocs.reduce((acc: Array<string>, ele: string) => {
                    acc.push(ele.toUpperCase());
                    return acc;
                }, []);
            }

            if (options.champsTransactionnels) {
                this.champsTransactionnels = options.champsTransactionnels;
            }

            if (this.multiOccurrenceParent) {
                this.entetesReplacedValues = this.multiOccurrenceParent.entetesReplacedValues;
            }

            if (this.noId) {
                this.fetchingData = true;
            } else {
                if (this.multiOccurrenceParent) {
                    this.id = options.id || this.multiOccurrenceParent.activeRowCleint;
                    this.bloc = this.multiOccurrenceParent.bloc;
                } else if (this.isEdition || options.id) {
                    this.id = options.id;
                } else if (!this.bloc) {
                    this.id = this.stateParams.id;
                }

                if (this.id || options.noId) {
                    this.fetchingData = true;
                }

                this.paramDefaults = {
                    id: this.id
                };
            }

            if (options.autoFetch !== false) {
                this.autoFetch = true;
            }

            this.on("dataUpdate", () => this.clonedData = { ...this.data })
            this.on("softDataUpdate", () => this.clonedData = { ...this.data })
            this.DataResource = this.getDataResource(options.resourceUrl);
            this.resourceUrl = options.resourceUrl
            this.init();
        }

        init(): void {
            this.initializing = true;

            this.ready = this.fetchSourceDetails()
                .then(() => {
                    return this.refreshChampsTransactionnels();
                }).then(async () => {
                    this.initialized = true;
                    this.initError = false;
                    if (this.id || this.noId) {
                        if (this.autoFetch) {
                            await this.fetchData();
                        }

                        if (!this.isEdition) {
                            if (this.noId) {
                                return this.data.$promise.then(() => {
                                    return this.fetchEtatsCycle();
                                });
                            } else {
                                return this.fetchEtatsCycle();
                            }
                        }
                    } else {
                        return this.resetData();
                    }
                })
                .then(() => this.initFonctionsEdition())
                .finally(() => {
                    this.initializing = false;
                });

            this.ready.catch(() => {
                this.initError = true;
            });
        }

        resetData(params?: any) {
            const copyData = localStorage.getItem("monoOccurrenceCopy")
            if (copyData) {
                this.data = JSON.parse(copyData)
                localStorage.removeItem("monoOccurrenceCopy")
                return Promise.resolve()
            } else {
                return this.fetchValeursDefaut(params).then((data: any) => {
                    if (this.formulaire) {
                        this.formulaire.initFormData(data, this.dataTypes || {});
                    }
                    this.id = undefined;
                    this.data = new this.DataResource(data);
                    this.emit('dataUpdate');
                    return this.data;
                });
            }
        }

        fetchValeursDefaut(params?: any) {
            if (this.procedureValeursDefautDefinie) {
                const ids = this.getParentIds();
                if (ids.length > 1 && !ids[ids.length - 1]) {
                    ids.pop();
                }

                const paramsValeursDefaut: any = {
                    srccod: this.getSrccodref(),
                    ids: ids.join(','),
                    ...this.resourceParams,
                    ...params,
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                };

                this.stateParams.route.QUERY_PARAMS.forEach((param: string) => {
                    const nomParam = param.replace(/^\?/, '');
                    paramsValeursDefaut[nomParam] = this.stateParams[nomParam];
                });

                this.fetchingValeursDefaut = true;
                return ValeursDefautResource.get(paramsValeursDefaut).$promise
                    .then((data: any) => {
                        this.valeursDefaut = data;
                        this.valeursDefautError = false;
                        return { ...data };
                    })
                    .catch(() => {
                        this.valeursDefautError = true;
                    })
                    .finally(() => {
                        this.fetchingValeursDefaut = false;
                    });
            } else {
                return $q.resolve({});
            }
        }

        private fetchSourceDetails(): IPromise<any> {
            const params: any = {
                ...this.stateParams,
                ...this.resourceParams,
                srccod: this.getNomSourceDetails().toLowerCase(),
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            if (this.stateParams.id) {
                params.cleint = this.stateParams.id;
            }

            return sourceDetailsManager.fetch(this.getNomSourceDetails(), null, params)
                .then((sourceDetails: ISourceDetails) => {
                    this.setSourceDetails(sourceDetails);
                });
        }

        getNomSourceDetails() {
            if (this.nomSourceDetails) {
                return this.nomSourceDetails;
            } else if (this.multiOccurrenceParent && this.multiOccurrenceParent.isView) {
                return `${this.multiOccurrenceParent.getNomSourceDetails()}-edition`;
            } else if (this.multiOccurrenceParent && !this.multiOccurrenceParent.isView) {
                return this.multiOccurrenceParent.getNomSourceDetails();
            } else if (this.monoOccurrenceParentId && this.isEdition) {
                return `${this.srccod}-${this.bloc}-edition`;
            } else if (this.monoOccurrenceParentId) {
                return `${this.srccod}-${this.bloc}`;
            } else if (this.isEdition) {
                return `${this.srccod}-edition`;
            } else {
                return this.srccod;
            }
        }

        getSrccodref() {
            return this.srccodref || this.getNomSourceDetails();
        }

        private setSourceDetails(sourceDetails: ISourceDetails): void {
            this.libelles = sourceDetails.libelles;
            this.schema = sourceDetails.schema;
            this.dataTypes = sourceDetails.dataTypes;
            this.comportements = Comportement.init(sourceDetails.comportements);
            this.fonctionsEdition = FonctionsEdition.init(sourceDetails.fonctionsEdition);
            this.validatedFields = sourceDetails.validatedFields;
            this.listenedFields = sourceDetails.listenedFields;
            this.reglesValidation = sourceDetails.validationRules;
            this.mnemonique = sourceDetails.mnemonique.toUpperCase();
            this.messages = sourceDetails.messages;
            this.cleint = sourceDetails.cleint;
            this.cycleCleint = sourceDetails.cycleCleint;
            this.cycleCleintParent = sourceDetails.cycleCleintParent;
            this.cycleParent = sourceDetails.cycleParent;
            this.colonnesEnteteTitre = sourceDetails.colonnesEnteteTitre;
            this.procedureValeursDefautDefinie = sourceDetails.procedureValeursDefautDefinie;

            this.initDataTypes();
        }

        fetchData() {
            this.fetchingData = true;
            const params = this.getQueryParams();

            this.data = this.DataResource.get(params);

            return this.data.$promise.then(() => {
                this.emit('dataUpdate');
                this.dataError = false;
                this.dataErrorType = null;
                this.dataReady = true;
            }).catch(response => {
                this.dataError = true;
                this.dataErrorType = response.status === Exceptions.INTROUVABLE.STATUS ? Exceptions.INTROUVABLE : { ...Exceptions.ERREUR_SERVEUR, message: response.data ? response.data.message : '' };
            }).finally(() => this.fetchingData = false);
        }

        softUpdateData(additionalParams: any = {}) {
            const dataListQuery = this.DataResource.get({ ...this.getQueryParams(), ...additionalParams });

            dataListQuery.$promise.then((result: any) => {
                this.data = new this.DataResource({ ...this.data, ...result });
                this.emit('softDataUpdate', this.data);
            });
        }

        refreshChampsTransactionnels() {
            // Les champs peuvent passés par le multi parent
            if (this.champsTransactionnels) {
                return $q.resolve(this.champsTransactionnels);
            }

            const nomFonction = (this.bloc || this.mnemonique).toUpperCase();
            const fonction = this.ecranDetails.fonctions[nomFonction];
            const isEditable = Boolean(!fonction || (this.id && fonction.flgmod) || (!this.id && fonction.flgins));

            // Seul le mono ancêtre est responsable de charger ses champs transactionnels
            if (isEditable && ((!this.bloc && (this.id || this.noId) && this.cycleCleint) || this.cycleParent)) {
                this.fetchingChampsTransactionnels = true;

                return this.fetchChampsTransactionnels()
                    .then((champsTransactionnels: IChampsTransactionnels) => {
                        this.champsTransactionnelsError = false;
                        this.champsTransactionnels = champsTransactionnels;
                    })
                    .catch((erreur: any) => {
                        this.champsTransactionnelsError = true;
                        return $q.reject(erreur);
                    })
                    .finally(() => {
                        this.fetchingChampsTransactionnels = false;
                    });
            } else {
                this.champsTransactionnels = {};
                return $q.resolve(this.champsTransactionnels);
            }
        }

        private fetchChampsTransactionnels() {
            const employe = this.stateParams.employe;
            const params: any = {
                srccod: (this.bloc) ? `${this.srccod}-${this.bloc}` : this.srccod,
                srccodref: this.stateParams.srccod,
                cleint: this.stateParams.id,
                employe,
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            if (this.cycleParent && !this.id) {
                // Dans le cas d'un écran enfant utilisé en création, on ajoute les paramètres de query pour obtenir
                // les champs transactionnels.
                this.stateParams.route.QUERY_PARAMS.forEach((param: string) => {
                    const nomParam = param.replace(/^\?/, '');
                    params[nomParam] = this.stateParams[nomParam];
                });
            }

            if (this.ecranSourceDetails.cycleCleint === 'doccleint' || this.ecranSourceDetails.cycleCleintParent === 'doccleint') {
                return ChampsEvenementsResource.get(params).$promise;
            } else {
                return ChampsTransactionnelsResource.get(params).$promise;
            }
        }

        private initDataTypes(): void {
            const dataTypes = this.dataTypes;
            Object.keys(this.schema).forEach((column: string) => {
                dataTypes[column] = dataTypes[column] || new DataType({ schemaItem: this.schema[column] });
            });
        }

        private getDataResource(url: string): IMonoOccurrenceResourceClass {
            const rootUrl = url || this.getDataResourceUrl();

            const params = {
                bloc: '@bloc',
                id: '@id',
                ...this.resourceParams,
                ...parametresSecurite(this.stateParams, this.ecranDetails)
            };

            if (this.multiOccurrenceParent) {
                Object.assign(params, this.multiOccurrenceParent.getParentParams())
            }

            // On passe les paramètres de la route aux requêtes des données
            this.stateParams.route.QUERY_PARAMS.forEach((param: string) => {
                const nomParam = param.replace(/^\?/, '');
                params[nomParam] = this.stateParams[nomParam];
            });

            const actions = {
                update: {
                    method: 'PUT',
                    interceptor: messageHandlingInterceptor
                },
                save: {
                    method: 'POST',
                    interceptor: messageHandlingInterceptor
                },
                delete: {
                    method: 'DELETE',
                    interceptor: messageHandlingInterceptor
                }
            };

            return <IMonoOccurrenceResourceClass>$resource(`${rootUrl}/${(this.noId && !this.stateParams.employe) || rootUrl.includes(':id') ? '' : ':id'}`, params, actions);
        }

        getDataResourceUrl(): string {
            if (this.multiOccurrenceParent && (this.multiOccurrenceParent.isView || this.multiOccurrenceParent.fonctions.editionRapide)) {
                return `${this.multiOccurrenceParent.getUrlPath()}/edition`;
            } else if (this.multiOccurrenceParent && !this.multiOccurrenceParent.isView) {
                return this.multiOccurrenceParent.getUrlPath();
            } else if (this.bloc && this.isEdition) {
                return `${ApiConfig.ROOT}/${this.srccod}/${this.monoOccurrenceParentId}/${this.bloc}/edition`
            } else if (this.bloc) {
                return `${ApiConfig.ROOT}/${this.srccod}/${this.monoOccurrenceParentId}/${this.bloc}`
            } else if (this.isEdition) {
                return `${ApiConfig.ROOT}/${this.srccod}/edition`;
            } else {
                return `${ApiConfig.ROOT}/${this.srccod}`;
            }
        }

        downloadData(parentElement: IAugmentedJQuery) {
            const queryParams = {
                id: this.id,
                srccod: this.srccod,
                titres: this.formulaire && `${this.getTitresQueryParam()}`,
                cols: this.getDownloadCols(parentElement).join(','),
                cleint: this.id,
                isDownload: true,
                bloc: this.bloc,
                ...this.resourceParams,
                ...this.stateParams
            };

            if (this.multiOccurrenceParent) {
                Object.assign(queryParams, this.multiOccurrenceParent.getParentQueryParams());
            }

            this.downloadingData = true;

            return downloadManager.download(`${(this.resourceUrl || this.getDataResourceUrl()).replace(ApiConfig.ROOT, '')}/${this.noId ? '' : this.id}`, queryParams, this.stateParams)
                .finally(() => this.downloadingData = false);
        }

        private getTitresQueryParam() {
            return this.formulaire.liste
                .filter((champ: IFormulaireItem) => champ instanceof FormulaireItem && champ.titre)
                .map((champ: IFormulaireItem) => `${champ.col}:${window.encodeURIComponent(champ.titre instanceof Function ? champ.titre(this.data) : champ.titre)}`)
                .join(';');
        }

        private getDownloadCols(parentElement: IAugmentedJQuery) {
            const cols: Array<string> = [];

            this.formulaire.liste.forEach(formulaireItem => {
                this.pushVisibleField(cols, formulaireItem);

                if (this.isFormulaireGroupe(formulaireItem)) {
                    (<IFormulaireGroupe>formulaireItem).formulaire.liste.forEach(formulaireItemGroupeAccordeon => {
                        this.pushVisibleField(cols, formulaireItemGroupeAccordeon);
                    })
                }
            });

            parentElement.find('ex-card ex-data-list').each((index, dataListElement) => {
                cols.push($(dataListElement).controller('exDataList').cols)
            });

            parentElement.find('ex-card-saisie').each((index, cardSaisieElement) => {
                const colCardSaisie: Array<FormulaireElement> = $(cardSaisieElement).controller('exCardSaisie').formulaire.liste;

                colCardSaisie.forEach(cardSaisieFormulaireItem => {
                    this.pushVisibleField(cols, cardSaisieFormulaireItem);

                    if (this.isFormulaireGroupe(cardSaisieFormulaireItem)) {
                        if ((<IFormulaireGroupeConditionnel>cardSaisieFormulaireItem).col) {
                            cols.push((<IFormulaireGroupeConditionnel>cardSaisieFormulaireItem).col);
                        }

                        (<IFormulaireGroupe>cardSaisieFormulaireItem).formulaire.liste.forEach(formulaireGroupe => {
                            this.pushVisibleField(cols, formulaireGroupe);
                        })
                    }
                })
            });

            return cols;
        }

        private pushVisibleField(cols: Array<string>, formulaireItem: FormulaireElement) {
            if (formulaireItem instanceof FormulaireItem && this.schema[formulaireItem.col]) {
                const isAlwaysHidden = Boolean(
                    this.comportements[formulaireItem.col] &&
                    this.comportements[formulaireItem.col].hidden &&
                    typeof this.comportements[formulaireItem.col].hidden === 'boolean'
                );

                if (!isAlwaysHidden) {
                    cols.push(formulaireItem.col);
                }
            }
        }

        isFormulaireGroupe(formulaireItem: any): boolean {
            return formulaireItem instanceof FormulaireGroupe || formulaireItem instanceof FormulaireGroupeConditionnel || formulaireItem instanceof FormulaireGroupeAccordeon;
        }

        save(data: any, params?: any): IPromise<any> {
            this.saving = true;

            if (this.id || this.noId) {
                return this.update(data, params)
                    .then(() => {
                        if (this.successActionSauvegarde) {
                            return this.successActionSauvegarde();
                        }
                    })
                    .finally(() => this.saving = false);
            } else {
                return this.create(data, params)
                    .then((data: any) => {
                        this.id = data[this.cycleCleint || this.cleint];
                    })
                    .then(() => {
                        if (this.successActionSauvegarde) {
                            return this.successActionSauvegarde();
                        }
                    })
                    .finally(() => this.saving = false);
            }
        }

        isSaveable(data: any = this.data): boolean {
            const isMiseAjour = Boolean(this.id || (this.noId && this.stateParams.employe));

            return (
                !isMiseAjour ||
                // Si aucun champ n'est modifiable, on désactive le bouton enregistrer
                data && data.$resolved && this.hasChampsModifiables(isMiseAjour, data)
            )
        }

        private update(data?: any, additionalParams?: any) {
            const params = {
                ...this.getQueryParams(),
                ...additionalParams
            };

            if (this.noId && this.stateParams.employe && !params.id) {
                params.id = this.data[this.cycleCleint || this.cleint]
            }

            if (this.ecranSourceDetails.sourceDeveloppement) {
                if (!profil.preferences.itvcleint) {
                    return $q.reject({ interventionRequise: true });
                }

                params.itvcleint = profil.preferences.itvcleint
            }

            if (data) {
                return this.DataResource.update(params, data).$promise
                    .then((savedData: IMonoOccurrenceResource) => {
                        this.data = new this.DataResource({ ...this.data, ...data, ...savedData });
                        this.emit('dataUpdate');
                        return this.data;
                    });
            } else {
                return this.data.$update(params);
            }
        }

        private create(data?: any, additionalParams?: any) {
            const params = {
                ...this.getQueryParams(true),
                ...additionalParams
            };

            if (this.ecranSourceDetails.sourceDeveloppement) {
                if (!profil.preferences.itvcleint) {
                    return $q.reject({ interventionRequise: true });
                }

                params.itvcleint = profil.preferences.itvcleint
            }

            if (data) {
                data.id = undefined
                let promise
                if (this.formulaire.uploadFichier instanceof Function ? this.formulaire.uploadFichier(this.data) : this.formulaire.uploadFichier) {
                    promise = uploadManager.upload(`${(this.resourceUrl || this.getDataResourceUrl()).replace(ApiConfig.ROOT, '').replace(":id", this.id ? this.id.toString() : "").replace(":parentId", this.getParentIds()[0].toString())}/${this.noId ? '' : this.id || ""}`, data, params, this.stateParams)
                } else {
                    promise = this.DataResource.save(params, data).$promise
                }

                return promise.then((savedData: IMonoOccurrenceResource) => {
                    this.data = new this.DataResource({ ...data, ...savedData });
                    this.data.$resolved = true;
                    this.paramDefaults.id = this.data[this.cycleCleint || this.cleint];
                    this.emit('dataUpdate');
                    return this.data;
                });
            } else {
                return this.data.$save(params);
            }
        }

        delete(id: number, additionnalParams: any = {}) {
            const params = { ...additionnalParams, ...this.getQueryParams() };

            if (this.noId && this.stateParams.employe && !params.id) {
                params.id = this.data[this.cycleCleint || this.cleint]
            }

            if (this.ecranSourceDetails.sourceDeveloppement) {
                if (!profil.preferences.itvcleint) {
                    return Promise.reject({ interventionRequise: true });
                }

                params.itvcleint = profil.preferences.itvcleint
            }

            if (this.data && this.data.$delete && (this.id || this.noId)) {
                return this.data.$delete(params);
            } else {
                return this.DataResource.delete({
                    ...params,
                    id
                }).$promise;
            }
        }

        fetchEtatsCycle() {
            if (!this.cycleCleint) {
                return $q.resolve();
            } else if (this.cycleCleint === 'trncleint') {
                const params = {
                    trncleint: this.noId ? this.data.trncleint : this.id,
                    srccod: this.stateParams.srccod,
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                };

                const requetes: Array<IPromise<any>> = [
                    CycleRh.getEtatActuel(params).$promise,
                    CycleRh.getProchainEtat(params).$promise
                ];

                this.fetchingEtatsCycle = true;

                return $q.all(requetes)
                    .then(([etatActuel, prochainEtat]) => {
                        this.etatsCycleError = false;
                        this.etatActuel = etatActuel;
                        this.prochainEtat = prochainEtat;
                    })
                    .catch(() => {
                        this.etatsCycleError = true;
                    })
                    .finally(() => {
                        this.fetchingEtatsCycle = false;
                    });

            } else if (this.cycleCleint === 'doccleint') {
                const params = {
                    doccleint: this.noId ? this.data.doccleint : this.id,
                    srccod: this.stateParams.srccod,
                    ...parametresSecurite(this.stateParams, this.ecranDetails)
                };

                const requetes: Array<IPromise<any>> = [
                    CycleFinance.getEtatActuel(params).$promise,
                    CycleFinance.getProchainEtat(params).$promise
                ];

                this.fetchingEtatsCycle = true;

                return $q.all(requetes)
                    .then(([etatActuel, prochainEtat]) => {
                        this.etatsCycleError = false;
                        this.etatActuel = etatActuel;
                        this.prochainEtat = prochainEtat;
                    })
                    .catch(() => {
                        this.etatsCycleError = true;
                    })
                    .finally(() => {
                        this.fetchingEtatsCycle = false;
                    });
            }
        }

        getQueryParams(isCreation: boolean = false) {
            let params = {};

            if (!isCreation) {
                params = { ...this.paramDefaults };
            }

            if (this.multiOccurrenceParent) {
                params = {
                    ...params,
                    ...this.resourceParams,
                    ...this.multiOccurrenceParent.etatSelected.criteresSuggeresData,
                    ...this.multiOccurrenceParent.getParentQueryParams()
                };
            }

            return params;
        }

        getParentIds() {
            return (this.multiOccurrenceParent) ?
                this.multiOccurrenceParent.getParentIds() :
                this.monoOccurrenceParentId ? [this.monoOccurrenceParentId] : [];
        }

        hasChangementsNonSauvegardes(formData: any) {
            if (formData) {
                return changementManager.compareChangements(formData, this.clonedData, this.schema, this.dataTypes);
            }
        }

        verifierChangementsNonSauvegardes(formData: any, event?: MouseEvent) {
            if (this.hasChangementsNonSauvegardes(formData)) {
                notificationHandler.erreur({
                    targetEvent: event,
                    lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                    lblMessage: exLibelleFilter('G_MSG_CHANGEMENTS_ACTION', this.libelles, false, exLibelleFilter(this.formulaire.lblTitre, this.libelles))
                });

                return false;
            } else {
                return true;
            }
        }

        hasEdition(data: any = {}) {
            if (this.element && !data.$linked) {
                dataLinker.link(this.element, data, this.stateParams, this.ecranDetails);
            }
            if (this.fonctions.edition instanceof Function) {
                return Boolean(this.fonctions.edition(data));
            } else {
                return this.fonctions.edition;
            }
        }

        hasNouveau(data: any = {}) {
            if (this.element && !data.$linked) {
                dataLinker.link(this.element, data, this.stateParams, this.ecranDetails);
            }
            if (this.fonctions.nouveau instanceof Function) {
                return Boolean(this.fonctions.nouveau(data));
            } else {
                return this.fonctions.nouveau;
            }
        }

        hasSuppression(data: any = {}) {
            if (this.element && !data.$linked) {
                dataLinker.link(this.element, data, this.stateParams, this.ecranDetails);
            }
            if (this.fonctions.supprime instanceof Function) {
                return Boolean(this.fonctions.supprime(data));
            } else {
                return this.fonctions.supprime;
            }
        }

        shouldDisplaySaveButton(): boolean {
            return this.id || this.noId ? this.hasEdition(this.data) : this.hasNouveau(this.data);
        }

        private initFonctionsEdition() {
            const nomFonction = (this.bloc || this.mnemonique).toUpperCase();
            const fonction = this.ecranDetails.fonctions[nomFonction];

            const isFlagSup = Boolean(!fonction || fonction.flgsup);
            if (typeof this.fonctions.supprime === 'undefined') {
                if (!this.fonctionsEdition.supprime) {
                    this.fonctions.supprime = isFlagSup;
                } else {
                    this.fonctions.supprime = isFlagSup ? this.fonctionsEdition.supprime : false;
                }
            }

            const isFlagMod = Boolean(!fonction || fonction.flgmod);
            if (typeof this.fonctions.edition === 'undefined') {
                if (!this.fonctionsEdition.edition) {
                    this.fonctions.edition = isFlagMod;
                } else {
                    this.fonctions.edition = isFlagMod ? this.fonctionsEdition.edition : false;
                }
            }

            const isFlagIns = Boolean(!fonction || fonction.flgins);
            if (typeof this.fonctions.nouveau === 'undefined') {
                if (!this.fonctionsEdition.nouveau) {
                    this.fonctions.nouveau = isFlagIns;
                } else {
                    this.fonctions.nouveau = isFlagIns ? this.fonctionsEdition.nouveau : false;
                }
            }
        }
    }

    return MonoOccurrence;
}
