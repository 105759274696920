import * as angular from 'angular';
import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface ICaseEditionAssisteeDataTypeClass {
    new(options?: ICaseEditionAssisteeDataTypeOptions): ICaseEditionAssisteeDataType;
}

export interface ICaseEditionAssisteeDataTypeOptions extends IDataTypeOptions {
}

export interface ICaseEditionAssisteeDataType extends IDataType {
}

export default module('core.services.case-edition-assistee-data-type', []).factory('CaseEditionAssisteeDataType', CaseEditionAssisteeDataTypeFactory);

/* @ngInject */
function CaseEditionAssisteeDataTypeFactory(DataType: IDataTypeClass): ICaseEditionAssisteeDataTypeClass {
    return class CaseEditionAssisteeDataType extends DataType implements ICaseEditionAssisteeDataType {
        readonly template: string = `<ex-case-edition-assistee data="vm.data" col="{{vm.col}}" data-type="vm.dataType" ex-readonly="vm.readonly" schemas="vm.schemas"></ex-case-edition-assistee>`;
        constructor(options: ICaseEditionAssisteeDataTypeOptions = {}) {
            super(angular.extend({alignment: 'center'}, options));
        }
    }
}
