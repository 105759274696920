import { IComponentController } from "angular";
import {IDefaultsService} from "../../services/utils/defaults.service";

export interface IDialogPiecesJointes extends IComponentController {
}

/* @ngInject */
export default function DialogPiecesJointesController(defaults: IDefaultsService) {
    const vm: IDialogPiecesJointes = this;
    vm.$onInit = function $onInit() {
        defaults(vm, {
            lblTitre: 'G_LBL_PIECES_JOINTES',
            lblCancel: 'G_LBL_BTN_FERMER',
            icon: 'attach_file'
        })
    }
}
