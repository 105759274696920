/**
 * Ce service sert à sauvegarder l'état de recherche courant localement dans le navigateur. Lorsqu'on retourne à un
 * état précédemment consulté, on retrouvera les derniers critères de recherches utilisés (même s'ils n'ont pas été
 * sauvegardés) de même que la position de la pagination.
 */
// import * as angular from 'angular';
import { module } from 'angular';
import { IEtatResourceSerializedEntite, IEtatsResourcesClass, IEtatsResourcesEntite } from '../resources/etat.resource';
import { IMultiOccurrence } from './multi-occurrence.service';
import { IFiltre } from './filtre.service';

export interface IEtatStore {
    save(multiOccurrence: IMultiOccurrence): void;
    fetch(multiOccurrence: IMultiOccurrence): IEtatStoreResult;
}

export interface IEtatStoreSerialized {
    etat?: IEtatResourceSerializedEntite,
    pageCourante: number;
}

export interface IEtatStoreResult {
    etat?: IEtatsResourcesEntite,
    pageCourante?: number;
}

export default module('core.services.etat-store', []).factory('etatStore', EtatStoreFactory);

/* @ngInject */
function EtatStoreFactory(EtatsResource: IEtatsResourcesClass): IEtatStore {
    class EtatStore implements IEtatStore {
        save(multiOccurrence: IMultiOccurrence) {
            if (this.isNotSupported(multiOccurrence)) {
                return;
            }

            localStorage.setItem(this.getMultiOccurrenceStoreKey(multiOccurrence), JSON.stringify({
                etat: {...JSON.parse(EtatsResource.toJson(multiOccurrence.etatSelected)), gteedtrpd: multiOccurrence.editionRapideActive},
                pageCourante: multiOccurrence.etatSelected.pagination.pageCourante
            }));

            this.saveBlocIndex(multiOccurrence);
        }

        fetch(multiOccurrence: IMultiOccurrence): IEtatStoreResult {
            if (this.isNotSupported(multiOccurrence)) return

            const serializedEtat: IEtatStoreSerialized = JSON.parse(localStorage.getItem(this.getMultiOccurrenceStoreKey(multiOccurrence)));

            if (serializedEtat) {
                const result: IEtatStoreResult = {
                    pageCourante: serializedEtat.pageCourante
                };

                if (serializedEtat.etat) {
                    result.etat = new EtatsResource(EtatsResource.parseEtat(serializedEtat.etat));
                    if (result.etat.isEditionRapide !== multiOccurrence.editionRapideActive) {
                        result.etat.colonnesVisibles = multiOccurrence.etatDefault.colonnesVisibles
                    }

                    // On doit restaurer les filtres prédéfinis pour bien prendre en compte les paramètres
                    multiOccurrence.etatSelectedOriginal.filtres.filter((filtre: IFiltre) => !filtre.parametre).forEach((filtre: IFiltre, index: number) => {
                        if (filtre.predefini && !result.etat.isEditionRapide) {
                            result.etat.filtres[index] = filtre.clone();
                        }
                    });
                    result.etat.id = '$TEMPORAIRE$';
                }

                return result;
            }
        }

        private isNotSupported(multiOccurrence: IMultiOccurrence) {
            return !multiOccurrence.fonctions.saveEtatTemporaire || multiOccurrence.srccod.startsWith('lov-');
        }

        private getMultiOccurrenceStoreKey(multiOccurrence: IMultiOccurrence) {
            return this.getStoreKey(multiOccurrence.srccod, multiOccurrence.bloc, multiOccurrence.stateParams.ecran);
        }

        private getMultiOccurrenceBlocsStoreKey(multiOccurrence: IMultiOccurrence) {
            return this.getBlocsStoreKey(multiOccurrence.srccod, multiOccurrence.stateParams.ecran);
        }

        private getBlocsStoreKey(srccod: string, ecran: string): string {
            return this.getStoreKey(srccod, undefined, ecran) + ':blocs';
        }

        private getStoreKey(srccod: string, bloc: string, ecran: string) {
            return JSON.stringify({
                srccod: srccod.toLowerCase(),
                bloc,
                ecran
            });
        }

        /**
         * Si le multiOccurrence est un bloc, on l'ajoute à un index qui nous permettra de savoir les blocs qui ont été
         * sauvegardés pour un écran, ce qui permettra de les supprimer au besoin
         */
        private saveBlocIndex(multiOccurrence: IMultiOccurrence) {
            // On doit garder une liste des blocs pour qu'on puisse facilement les supprimer lors du forage
            if (multiOccurrence.bloc) {
                const blocs = this.fetchBlocs(multiOccurrence.srccod, multiOccurrence.stateParams.ecran)
                if (!blocs.includes(multiOccurrence.bloc)) {
                    blocs.push(multiOccurrence.bloc);
                    const blocIndexKey = this.getMultiOccurrenceBlocsStoreKey(multiOccurrence);
                    localStorage.setItem(blocIndexKey, JSON.stringify(blocs))
                }
            }
        }

        private fetchBlocs(srccod: string, ecran: string) {
            const blocsExistant = localStorage.getItem(this.getBlocsStoreKey(srccod, ecran));
            return blocsExistant ? JSON.parse(blocsExistant) : [];
        }
    }

    return new EtatStore();
}

