import {
    IHttpPromise,
    IHttpService,
    IRootScopeService,
    module,
    ILogService,
    IAngularEvent
} from 'angular';
import { IRoute } from '../interfaces/route.interface';
import { IApiConfig } from '../interfaces/api-config.interface';
import { IExceptions } from '../constants/exceptions.constant';
import IStateService = angular.ui.IStateService;
import IInjectorService = angular.auto.IInjectorService;
import { erreurChargementAppRoute } from "../components/ex-erreur-chargement-app/ex-erreur-chargement-app.component";

export default module('core.providers.acces-manager', []).service('accesManager', AccesManagerFactory);

export interface IReponseAuth {
    message?: string;
}

export interface IAccesManager {
    start(): void;
    logout(): void;
    verifierAcces(): IHttpPromise<any>;
    login(user: IUser, provenance?: string): IHttpPromise<IReponseAuth>;
}

export interface IUser {
    username: string;
    password: string;
}

/* @ngInject */
function AccesManagerFactory($rootScope: IRootScopeService,
    $state: IStateService,
    Exceptions: IExceptions,
    $http: IHttpService,
    ApiConfig: IApiConfig,
    erreurAccesEcranRoute: IRoute,
    erreurChargementEcranRoute: IRoute,
    $injector: IInjectorService,
    $log: ILogService,
    appName: string) {
     return new class AccesManager implements IAccesManager {
        public start() {
            //Lorsqu’il a un changement de state et qu'il est en erreur.
            $rootScope.$on('$stateChangeError', (event: IAngularEvent, toState: any, toParams: any, fromState: any, fromParams: any, error: any) => {
                event.preventDefault()
                // On vérifie si l'erreur provient d’un échec d'authentification
                if (error === Exceptions.ERREUR_ACCESS_ECRAN) {
                    this.handleErreurChargement(erreurAccesEcranRoute.NAME, toParams)
                } else if (error === Exceptions.ERREUR_CHARGEMENT_ECRAN) {
                    this.handleErreurChargement(erreurChargementEcranRoute.NAME, toParams)
                } else if (error.data && error.data.code && error.data.code.startsWith('SOFE-')) {
                    toParams.error = error.data;
                    $state.transitionTo(erreurAccesEcranRoute.NAME, toParams);
                } else if (error === Exceptions.INTROUVABLE) {
                        this.handleErreurChargement(($injector.get('introuvableRoute') as IRoute).NAME, toParams);
                } else {
                    this.handleErreurChargement(erreurChargementAppRoute.NAME, toParams)
                }
            });
        }

        private handleErreurChargement(viewName: string, toParams: any) {
            $state.go(viewName, { params: toParams }, { location: false });
        }

        public logout() {
            $http.post((window as any).sofeLogoutUrl || (ApiConfig.URL + '/logout'), {}).then((casLogoutUrl: any) => {
                location.href = (casLogoutUrl.data as { logoutUrl: string }).logoutUrl;
            }).catch((err) => $log.error(JSON.stringify(err)));
        }

        public login(user: IUser, provenance?: string): IHttpPromise<IReponseAuth> {
            return $http.post(`${ApiConfig.URL}/login${provenance ? `?prv=${provenance}` : ''}`, user);
        }

        public verifierAcces(): IHttpPromise<any> {
            return $http({
                method: 'POST',
                url: `${ApiConfig.URL}/login/verifier-acces`,
                data: {}
            });
        }
    }()
}
