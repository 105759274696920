import { module } from 'angular';
import { IDataType, IDataTypeClass, IDataTypeOptions } from './data-type.service';

export interface ICompteurPiecesJointesDataTypeClass {
    new(options?: ICompteurPiecesJointesDataTypeOptions): ICompteurPiecesJointesDataType;
}

export interface ICompteurPiecesJointesDataTypeOptions extends IDataTypeOptions {
}

export interface ICompteurPiecesJointesDataType extends IDataType { }

export default module('core.services.compteur-pieces-jointes-data-type', []).factory('CompteurPiecesJointesDataType', CompteurPiecesJointesDataTypeFactory);

/* @ngInject */
function CompteurPiecesJointesDataTypeFactory(DataType: IDataTypeClass): ICompteurPiecesJointesDataTypeClass {
    class CompteurPiecesJointesDataType extends DataType implements ICompteurPiecesJointesDataType {
        readonly template: string = `<div layout="row" style="text-align: right;">
        <span flex>{{vm.data[vm.col] | exData : vm.dataType.schemaItem : vm.dataType | number: 0}}</span>
        <ex-bouton-compteur-pieces-jointes multi-occurrence="vm.multiOccurrenceCtrl.multiOccurrence"
        col="{{vm.col}}"
        data-type="vm.dataType"
        data="vm.data"></ex-bouton-compteur-pieces-jointes></div>`;

        constructor(options: ICompteurPiecesJointesDataTypeOptions = {}) {
            super({ alignment: 'right', ...options });
        }
    }

    return CompteurPiecesJointesDataType;
}
