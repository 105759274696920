import { IHttpHeadersGetter, module } from 'angular';
import { IApiConfig } from '../interfaces/api-config.interface';
import IResource = ng.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IEtatsMultipleResourcesEntite extends IResource<IEtatsMultipleResourcesEntite> {
    id: number | string;
    pmtcleint?: any;
    gtecleint?: number;
}

export interface IEtatsMultipleResourcesClass extends IResourceClass<IEtatsMultipleResourcesEntite> {
    new(data: any): any;
    toJson(etat: IEtatsMultipleResourcesEntite): string;
}

export default module('core.resources.etats.multiple', []).factory('EtatsMultipleResource', EtatsMultipleResourceFactory);
/* @ngInject */
function EtatsMultipleResourceFactory(ApiConfig: IApiConfig,
    $resource: ng.resource.IResourceService,
) {

    const actions = {
        query: {
            method: 'get',
            isArray: true,
            transformResponse
        },
        save: {
            method: 'POST',
            transformResponse,
            transformRequest(data: any) {
                return toJson(data);
            }
        },
        update: {
            method: 'PUT',
            transformResponse,
            transformRequest(data: any) {
                return toJson(data);
            }
        }
    };

    const EtatsResource = <IEtatsMultipleResourcesClass>$resource(`${ApiConfig.ROOT}/etatsMultiple/:id`, { id: '@id' }, actions);

    // On ajoute des méthodes à notre classes
    Object.assign(EtatsResource.prototype, {
    });

    EtatsResource.toJson = toJson;

    return EtatsResource;

    function transformResponse(json: string, headerGetters: IHttpHeadersGetter, status: number) {
        let data = JSON.parse(json);
        return data;
    }

    function toJson(etat: IEtatsMultipleResourcesEntite) {
        //on laisse vide dans le cas d'ajouter proprietes custom
        const formattedData: any = {
        };
        return JSON.stringify(formattedData);
    }
}