import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IValeursDefautOptions {}

export interface IValeursDefaut {}

export interface IValeursDefautResource extends IResourceClass<any> {
    new(data: IValeursDefautOptions): IResource<IValeursDefaut>;
}

export default module('core.resources.valeurs-defaut', [
    'core.services.api-config'
]).factory('ValeursDefautResource', ValeursDefautResourceFactory);

/* @ngInject */
function ValeursDefautResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IValeursDefautResource {
    return <IValeursDefautResource>$resource(`${ApiConfig.ROOT}/valeurs-defaut/:srccod`);
}
