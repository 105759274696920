import {IPromise, IRootScopeService, module} from 'angular';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import {IApiError} from '../../interfaces/api-error.interface';
import {IDialogConfirmOptions} from '../../dialogs/dialog-confirm/dialog-confirm.controller';
import IToastService = angular.material.IToastService;
import IDialogService = angular.material.IDialogService;
import IDialogOptions = angular.material.IDialogOptions;

export interface INotificationHandler {
    succes(message?: string, libelles?: any): void;
    erreur(options?: INotificationErreurOptions): IPromise<any>;
    info(options?: INotificationInfoOptions): IPromise<any>;
    avertissement(options?: INotificationAvertissementOptions): IPromise<any>;
}

export interface INotificationErreurOptions {
    icon?: string;
    targetEvent?: MouseEvent;
    lblTitre?: string;
    lblMessage?: string;
    lblConfirm?: string;
    lblDescription?: string;
    confirmAction?: () => any;
    error?: IApiError|boolean;
}

export interface INotificationInfoOptions {
    data?: any;
    html?: string;
    icon?: string;
    lblTitre?: string;
    lblMessage?: string;
    targetEvent?: MouseEvent;
}

export interface INotificationAvertissementOptions {
    icon?: string;
    targetEvent?: MouseEvent;
    lblTitre?: string;
    lblMessage?: string;
}

export default module('core.services.utils.notification-handler', []).factory('notificationHandler', NotificationHandlerFactory);

/* @ngInject */
function NotificationHandlerFactory($mdToast: IToastService,
                                    exLibelleFilter: IFilterLibelle,
                                    DialogConfirm: IDialogService,
                                    DialogAlert: IDialogService,
                                    DialogSelectionIntervention: IDialogService,
                                    $rootScope: IRootScopeService) {
    class NotificationHandler implements INotificationHandler {
        succes(message: string = 'G_MSG_SAUV_ENR_SUCCES', libelles?: any): void {
            const toast = $mdToast.simple()
                .textContent(exLibelleFilter(message, libelles))
                .theme('success-toast');

            $mdToast.show(toast);
            //mise à jour du grid s'il existe dans l'écran 
            $rootScope.$broadcast('multiOccurrence.fetchDataList.updateGrid');
        }

        erreur(options?: INotificationErreurOptions) {
            const dialogLocals: IDialogConfirmOptions = {
                error: options.error || true,
                icon: options.icon
            };

            const dialogOptions: IDialogOptions = {
                targetEvent: options.targetEvent,
                locals: dialogLocals,
                multiple: true
            };

            const error: IApiError = <IApiError>options.error;

            if (error && (<any>error).interventionRequise) {
                (<any>dialogLocals).lblMessageErreur = 'G_LBL_ERROR_INTERVENTION';
                (<any>dialogLocals).data = {};

                return DialogSelectionIntervention.show(dialogOptions)
                    .then(() => {
                        if (options.confirmAction) {
                            options.confirmAction();
                        }
                    });
            } else if (this.isErreurValidation(error)) {
                dialogLocals.lblMessage = error.data.message;
                dialogLocals.lblTitre = (this.isErreurDonneesVerrouillees(error)) ? 'G_LBL_ERREUR' : 'G_MSG_ERREUR_VALIDATION';
                return DialogAlert.show(dialogOptions);
            } else if (options.confirmAction) {
                dialogLocals.lblTitre = options.lblTitre;
                dialogLocals.lblMessage = options.lblMessage;
                dialogLocals.lblConfirm = options.lblConfirm;
                dialogLocals.lblDescription = options.lblDescription;
                dialogLocals.confirmAction = options.confirmAction;

                return DialogConfirm.show(dialogOptions);
            } else {
                dialogLocals.lblTitre = options.lblTitre;
                dialogLocals.lblMessage = options.lblMessage;

                return DialogAlert.show(dialogOptions);
            }
        }

        info(options: INotificationInfoOptions = {}) {
            const dialogOptions: IDialogOptions = {
                targetEvent: options.targetEvent,
                locals: {
                    lblTitre: 'G_LBL_MOD_INFORMATION_TITRE',
                    icon: 'info',
                    ...options
                },
                multiple: true
            };

            return DialogAlert.show(dialogOptions);
        }

        avertissement(options: INotificationAvertissementOptions = {}) {
            const dialogOptions: IDialogOptions = {
                targetEvent: options.targetEvent,
                locals: {
                    avertissement: true,
                    lblTitre: 'G_LBL_MOD_AVERTISSEMENT_TITRE',
                    ...options
                },
                multiple: true
            };

            return DialogAlert.show(dialogOptions);
        }

        private isErreurValidation(error: IApiError) {
            return (
                error &&
                error.data &&
                (
                    error.data.code && error.data.code.startsWith('SOFE-') ||
                    error.data.code === 'ERREUR_DE_VALIDATION' ||
                    // APP-00114 = Fichier trop gros
                    error.data.message && error.data.message.startsWith('APP-00114')
                )
            );
        }

        private isErreurDonneesVerrouillees(error: IApiError) {
            return (
                error &&
                error.status === 423 // Code d'erreur pour données vérouillées
            );
        }
    }
    return new NotificationHandler();
}
