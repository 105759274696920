import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IDataType } from '../../services/data-types/data-type.service';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IComponentController, IScope, IAugmentedJQuery } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IComposantGrid } from '../ex-grid/ex-grid.controller';
import { IColonneTri } from '../../services/colonne-tri.service';
import { ITri } from "../../services/tri.service";

interface IComposantButtonTri extends IComponentController {
    titre: string;
    replacedValue: string;
    colonneTri: IColonneTri;
    dataType: IDataType;
    schemaItem: ISchemaItem;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    gridCtrl?: IComposantGrid;
    inactive: boolean;
    ecranContextCtrl: IEcranContextController;
    isRequired(): boolean;
    disabled: boolean;
    libelles: any;
    col: any;
    tri: ITri;

    toggleTri(): void;
    getTitre(): string;
}

/* @ngInject */
export default function ButtonTriController($element: IAugmentedJQuery,
    $scope: IScope,
    dataLinker: IDataLinker,
    defaults: IDefaultsService,
    exLibelleFilter: IFilterLibelle,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IComposantButtonTri = this;
    const multiOccurrenceElement = $element.closest('ex-multi-occurrence');
    const data = {};

    vm.$onInit = $onInit;
    vm.toggleTri = toggleTri;
    vm.getTitre = getTitre;

    function $onInit() {
        dataLinker.link(multiOccurrenceElement, data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        defaults(vm, {
            layoutAlignment: getLayoutAlignment(),
            inactive: vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence.modeEditionRapide
        });

        $scope.$watch(() => vm.tri.getColonneByNom(vm.col), (colonneTri: IColonneTri) => {
            vm.colonneTri = colonneTri;
        });
    }

    function getTitre() {
        return exLibelleFilter(vm.titre || vm.col, vm.libelles, true, vm.replacedValue);
    }

    function toggleTri() {
        if (!vm.inactive) {
            vm.tri.setTriColonne(vm.col);
        }
    }

    vm.isRequired = function isRequired() {
        return !vm.gridCtrl || !vm.multiOccurrenceCtrl || !vm.multiOccurrenceCtrl.multiOccurrence.modeEditionRapide ? false : vm.gridCtrl.multiOccurrence.isChampRequired(new FormulaireItem(vm.col), data);
    }

    function getLayoutAlignment() {
        if (vm.dataType && vm.dataType.alignment === 'center') {
            return 'center';
        } else if (vm.dataType && vm.dataType.alignment === 'right') {
            return 'end';
        } else {
            return 'start';
        }
    }
}
