import {module} from 'angular';

export interface IExceptions {
    UNAUTHORIZED: IException;
    ERREUR_CHARGEMENT_APP: IException;
    ERREUR_CHARGEMENT_ECRAN: IException;
    ERREUR_ACCESS_ECRAN: IException;
    INTROUVABLE: IException;
    ERREUR_SERVEUR: IException;
}
export interface IException {
    NOM: string,
    STATUS: number;
    message?: string;
}

const EXCEPTIONS: IExceptions = {
    UNAUTHORIZED: {
        NOM: 'UNAUTHORIZED',
        STATUS: 401
    },
    ERREUR_CHARGEMENT_APP: {
        NOM: 'ERREUR_CHARGEMENT_APP',
        STATUS: 500
    },
    ERREUR_CHARGEMENT_ECRAN: {
        NOM: 'ERREUR_CHARGEMENT_ECRAN',
        STATUS: 500
    },
    ERREUR_ACCESS_ECRAN: {
        NOM: 'ERREUR_ACCESS_ECRAN',
        STATUS: 403
    },
    INTROUVABLE: {
        NOM: 'INTROUVABLE',
        STATUS: 404
    },
    ERREUR_SERVEUR: {
        NOM: 'ERREUR_SERVEUR',
        STATUS: 500
    }
};

export default module('core.constants.exceptions', []).constant('Exceptions', EXCEPTIONS);
