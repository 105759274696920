import {IAugmentedJQuery, module} from 'angular';
import {IFullscreen} from '../../services/fullscreen.service';

export default module('core.behaviors.ex-fullscreen', [])
    .directive('exFullscreen', fullscreenDirective);

/* @ngInject */

function fullscreenDirective(fullscreen: IFullscreen) {
    return {
        restrict: 'A',
        compile,
        priority: 9999
    };

    function compile(tElement: IAugmentedJQuery) {
        const name = tElement.attr('ex-fullscreen');
        //On vérifie si l’élément est présent dans le DOM
        if (tElement.parent().length) {
            fullscreen.register(name, tElement[0].outerHTML);
        }
    }
}
