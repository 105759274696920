import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import ImageController from './ex-image.controller';

export default module('core.components.ex-image', []).component('exImage', ImageFactory());

function ImageFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            Binding.MONO_OCCURRENCE.OPTIONAL,
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.COL
        ),
        controllerAs: 'vm',
        controller: ImageController,
        template: require('./ex-image.html')
    };
}
