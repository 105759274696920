import {IComponentOptions, module} from 'angular';
import ListePastilleFiltreController from './ex-liste-pastille-filtre.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-liste-pastille-filtre', [
    'core.services.operateur'
]).component('exListePastilleFiltre', ListePastilleFiltreFactory());

function ListePastilleFiltreFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                isCritereSuggere: '<',
                lblRechercheGlobale: '<?',
                lblTitrePastille: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            criteresSuggeresCtrl: '^^?exCriteresSuggeres'
        },
        controller: ListePastilleFiltreController,
        controllerAs: 'vm',
        template: require('./ex-liste-pastille-filtre.html')
    };
}
