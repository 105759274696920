import {IComponentOptions, module} from 'angular';
import FavorisController from '../../components/ex-favoris/ex-favoris.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-favoris', []).component('exFavoris', FavorisFactory());

function FavorisFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA,
            Binding.DATA_TYPE.OPTIONAL
        ),
        require: {
            multiOccurrenceCtrl: '?^^exMultiOccurrence',
            monoOccurrenceCtrl: '?^^exMonoOccurrence',
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: FavorisController,
        template: require('./ex-favoris.html')
    };
}
