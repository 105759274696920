/**
 * Permet de sélectionner plusieurs éléments
 */
import {IComponentOptions, module} from 'angular';
import SelectionMultipleController from './ex-selection-multiple.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-selection-multiple', []).component('exSelectionMultiple', SelectionMultipleFactory());

function SelectionMultipleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.DATA,
            Binding.VALUE,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                resourceParams: '<?',
                srccodref: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: SelectionMultipleController,
        template: require('./ex-selection-multiple.html')
    };
}
