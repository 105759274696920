import { IDefaultsService } from '../../services/utils/defaults.service';
import { IAugmentedJQuery, IComponentController, IScope } from 'angular';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IexHttpSrc } from '../../behaviors/ex-http-src/ex-http-src.behavior';
import { IMonoOccurrence } from "../../services/mono-occurrence.service";

interface IComposantImage extends IComponentController {
    multiOccurrence: IMultiOccurrence;
    monoOccurrence: IMonoOccurrence;
    data: any;
    col: string;
    dataType: IDataType;
    finalizeLoading(scope: IScope, element: IAugmentedJQuery, attrs: IexHttpSrc): void;
    getResourceUrl(): string;
}

/* @ngInject */
export default function ImageController(defaults: IDefaultsService, ApiConfig: IApiConfig) {
    const vm: IComposantImage = this;
    vm.$onInit = $onInit;
    vm.getResourceUrl = getResourceUrl;
    vm.finalizeLoading = finalizeLoading;

    function $onInit() {
        defaults(vm, {
            httpParams: { srccodref: getSrccodref() },
            style: { width: (vm.dataType.params.width || '100%') }
        });
    }

    function finalizeLoading(scope: IScope, element: IAugmentedJQuery, attrs: IexHttpSrc): void {
        element.closest('ex-image').find('md-progress-circular').toggleClass('ex-image-hide-busy');
        element.addClass('ex-image-show');
    }

    function getResourceUrl(): string {
        return `${ApiConfig.ROOT}/image/${vm.dataType.params.source}/${vm.data[vm.col]}/${vm.dataType.params.blob}/${vm.dataType.params.ext}`
    }

    function getSrccodref(): any {
        const occurrenceSourceDetails = getNomSourceDetails();

        if (vm.col && vm.col.includes('__')) {
            return `${occurrenceSourceDetails}-${vm.col.split('__')[0]}`
        } else {
            return occurrenceSourceDetails;
        }
    }

    function getNomSourceDetails() {
        if (!vm.multiOccurrence || (vm.monoOccurrence && vm.monoOccurrence.isEdition && vm.monoOccurrence.multiOccurrenceParent === vm.multiOccurrence)) {
            return vm.monoOccurrence.getNomSourceDetails()
        } else {
            return vm.multiOccurrence.getNomSourceDetails()
        }
    }
}
