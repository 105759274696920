import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogPiecesJointesController from "./dialog-pieces-jointes.controller";

export default module('core.dialogs.dialog-pieces-jointes', [
    'core.services.dialog'
]).factory('DialogPiecesJointes', DialogPiecesJointesFactory);

/* @ngInject */
function DialogPiecesJointesFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogPiecesJointesController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-pieces-jointes.html')
    });
}
