/**
 * Composant permettant de faire un tri sur 2 colonnes.
 *
 * @param {ISelecteurClass}  : Selecteur un obj qui contient 2 list<any> qui permet de faire la gestion du composant.
 * @param {string} nameLeft  : Titre de la colonne de gauche (si null 'Champ gauche')
 * @param {string} nameRight : Titre de la colonne de droite (si null 'Champ droite')
 */
import {IComponentOptions, module} from 'angular';
import SelecteurController from './ex-selecteur.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-selecteur', []).component('exSelecteur', SelecteurFactory());

function SelecteurFactory():IComponentOptions {
    return {
        bindings: Binding(
            Binding.SELECTEUR,
            Binding.LIBELLES,
            Binding.MULTI_OCCURRENCE,
            {
                lblColonneLeft: '@?',
                lblColonneRight: '@?',
                menusColonneDroite: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: SelecteurController,
        controllerAs: 'vm',
        template: require('./ex-selecteur.html')
    };
}
