import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import {ISelectionSpecialiseeDataTypeOptions} from './selection-specialisee-data-type.service';
import {IImageBlob} from '../../interfaces/image-blob.interface';
import * as angular from 'angular';

export interface ILovBoutonRadioDataTypeClass {
    new(options?: ILovBoutonRadioDataTypeOptions): ILovBoutonRadioDataType;
}

export interface ILovBoutonRadioDataTypeOptions extends IDataTypeOptions {
}

export interface ILovBoutonRadioDataType extends IDataType {
    image: IImageBlob;
}

export default module('core.services.lov-bouton-radio-data-type', []).factory('LovBoutonRadioDataType', LovBoutonRadioDataTypeFactory);

/* @ngInject */
function LovBoutonRadioDataTypeFactory(DataType: IDataTypeClass): ILovBoutonRadioDataTypeClass {
    class LovBoutonRadioDataType extends DataType implements ILovBoutonRadioDataType {
        readonly image: IImageBlob;
        readonly template: string = `{{vm.col | exLov : vm.data : vm.dataType}}`;

        constructor(options: ISelectionSpecialiseeDataTypeOptions = {}) {
            super(angular.extend({
                alignment: 'left'
            }, options));

            this.image = options.params.image;
        }
    }

    return LovBoutonRadioDataType;
}
