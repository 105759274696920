import {IComponentOptions, module} from 'angular';
import CaseController from './ex-case.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-case', [
]).component('exCase', CaseFactory());

/**
 * Représente une case pour bâtir un gabarit personnalisé. Une largeur doit être passée.
 */
function CaseFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL.OPTIONAL,
            Binding.COLS.OPTIONAL,
            {
                largeur: '@?',
                alignment: '@?',
                secondaire: '<?',
                clear: '<?',
                hideVide: '<?',
                titre: '<?',
                separator: '@?',
                prefix: '<?',
                suffix: '<?',
                cssprefix: '<?'
            }
        ),
        transclude: true,
        require: {
            dataSourceCtrl: '?^exDataSource'
        },
        controller: CaseController,
        controllerAs: 'vm',
        template: require('./ex-case.html')
    };
}
