import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IHeureDataTypeClass {
    new(options?: IHeureDataTypeOptions): IHeureDataType;
}

export interface IHeureDataTypeOptions extends IDataTypeOptions{
}

export interface IHeureDataType extends IDataType {
}

export default module('core.services.heure-data-type', []).factory('HeureDataType', HeureDataTypeFactory);

/* @ngInject */
function HeureDataTypeFactory(DataType: IDataTypeClass): IHeureDataTypeClass {
    class HeureDataType extends DataType implements IHeureDataType {
        readonly template: string = `{{vm.data[vm.col] | exHeure : vm.dataType.params.minutes : vm.dataType.params.secondes}}`;
    }

    return HeureDataType;
}
