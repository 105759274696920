import { IComponentController, IScope } from 'angular';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IMenuClass } from '../../services/menu/menu.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IData } from '../../services/data-linker.service';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { ISelectionEmployeResource } from '../../resources/selection-employe.resource';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import IDialogService = angular.material.IDialogService;
import { IOperateurService } from "../../services/operateur.service";

export interface IDialogSelectionEmploye extends IComponentController {
    col: string;
    colEmploye: string;
    colsDescription: Array<string>;
    dataType: IDataType;
    srccodref: string;
    menuItemAjouter: IMenuItem;
    menuItemRetirer: IMenuItem;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    ecranContextCtrl: IEcranContextController;
    confirm(event: any, data: any): void;
}

/* @ngInject */
export default function DialogSelectionEmployeController(ApiConfig: IApiConfig,
    Menu: IMenuClass, Operateur: IOperateurService,
    MenuItem: IMenuItemClass,
    notificationHandler: INotificationHandler,
    parametresSecurite: IParametresSecuriteService,
    SelectionEmployeResource: ISelectionEmployeResource,
    $scope: IScope,
    $mdDialog: IDialogService) {
    const vm: IDialogSelectionEmploye = this;
    vm.$onInit = function $onInit() {
        vm.confirm = confirm;

        vm.colsDescription = [...vm.dataType.params.description];
        vm.colEmploye = vm.colsDescription.shift();

        vm.menuItemAjouter = new MenuItem(
            (event: MouseEvent, rowDetails: IData) => creerEmployeFrequent(rowDetails.refcleint),
            {
                iconButton: true,
                class: 'dialog-selection-employe--boutons-actions',
                icon: 'add_circle'
            }
        );

        vm.menuItemRetirer = new MenuItem(
            (event: MouseEvent, rowDetails: IData) => deleteEmploye(rowDetails.hemcleint),
            {
                iconButton: true,
                class: 'dialog-selection-employe--boutons-actions',
                icon: 'remove_circle'
            }
        );

        const colonnesVisibles = [
            'lbl_sec',
            'lbl_ico',
            ...vm.dataType.params.description,
            ...vm.dataType.params.details
        ]

        vm.multiOccurrenceOptions = {
            bris: 'lbl_sec',
            brisIcon: 'lbl_ico',
            colonnesCachees: ['hemcleint'],
            colonnesVisibles,
            etatsPredefinis: [
                {
                    nom: "Employé actif",
                    filtres: [
                        {
                            colonne: "empflgact",
                            operateur: Operateur.EGALE,
                            valeur: "1",
                            visible: false
                        }
                    ],
                    colonnesVisibles
                },
                {
                    nom: "Tous",
                    filtres: [],
                    colonnesVisibles
                }
            ],
            actionsRangeeDroite: new Menu([]),
            fonctions: {
                recherche: true,
                placeholderRecherche: 'G_LBL_PLACEHOLDER_RECHERCHE_SELECTION_EMPLOYE',
                activeRowOnClick: true,
                supprime: false,
                nouveau: false,
                edition: false,
                suiviModification: false,
                exportation: false,
                importation: false,
                rechercheParColonne: false,
                enregistrerUnEtat: false,
                saveEtatTemporaire: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                reinitialiser: false,
                selectionnerUnEtat: true,
                skipDoubleRequetes: true
            },
            srccod: `lov-${vm.dataType.params.source}`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            resourceParams: {
                srccod: vm.srccodref
            },
            pagination: {
                pageCourante: 1,
                nombreElementPage: 5,
                nbElementsPossibles: [5, 10, 15]
            }
        };
    }

    $scope.$watch('::vm.multiOccurrence', (multiOccurrence: IMultiOccurrence) => {
        if (multiOccurrence && !multiOccurrence.actionsRangeeDroite.get(0)) {
            multiOccurrence.actionsRangeeDroite.add(new MenuItem((event: MouseEvent, data: any) => confirm(event, data), {
                icon: 'check_circle',
                iconButton: true
            }))
        }
    });

    function confirm(event: MouseEvent, data: any): void {
        if (!data.hemcleint) {
            creerEmployeFrequent(data.refcleint)
        }

        const descriptionValues = vm.dataType.params.description.reduce((result: any, col: string) => {
            result[`${vm.col}__${col}`] = data[col];
            return result;
        }, {});

        $mdDialog.hide(
            Object.assign(
                { [vm.col]: data.$id },
                descriptionValues
            )
        );
    }

    function creerEmployeFrequent(refcleint: number) {
        const data = { refcleint };
        const params = { ...getSecurityResourceParams() };

        SelectionEmployeResource.create(params, data).$promise
            .then(() => {
                vm.multiOccurrence.fetchDataList();
            })
            .catch((error: any) => {
                notificationHandler.erreur({
                    error,
                    lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                    lblMessage: 'G_MSG_CHARG_DONNEES',
                    confirmAction() {
                        return creerEmployeFrequent(refcleint);
                    }
                });
            })
    }

    function deleteEmploye(hemcleint: number) {
        SelectionEmployeResource.delete({ id: hemcleint, ...getSecurityResourceParams() }).$promise
            .then(() => {
                vm.multiOccurrence.fetchDataList();
            })
            .catch((error: any) => {
                notificationHandler.erreur({
                    error,
                    lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                    lblMessage: 'G_MSG_CHARG_DONNEES',
                    confirmAction() {
                        return deleteEmploye(hemcleint);
                    }
                });
            })
    }

    function getSecurityResourceParams() {
        return {
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            srccodref: vm.srccodref,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        }
    }
}
