import {module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';

export interface IMenuItemForageExterneClass {
    new(lblTitre: string, pageNom: string|IMenuItemForageExterneDestinationFonction, option?: IMenuItemForageExterneOptions): IMenuItemForageExterne;
    new(destination: string|IMenuItemForageExterneDestinationFonction, option?: IMenuItemForageExterneOptions): IMenuItemForageExterne;
}

interface IMenuItemForageExterneDestinationFonction {
    (data?: any): string;
}

export interface IMenuItemForageExterne extends IMenuItem {
}

export interface IMenuItemForageExterneOptions extends IMenuItemOptions{
}

export default module('core.services.menu-item-forage-externe', []).factory('MenuItemForageExterne', MenuItemForageExterneFactory);

/* @ngInject */
function MenuItemForageExterneFactory(MenuItem: IMenuItemClass) {

    class MenuItemForageExterne extends MenuItem implements IMenuItemForageExterne {
        constructor() {
            let lblTitre = arguments[0];
            let destination = arguments[1];
            let options = arguments[2] || {};

            // On détermine si un lblTitre a été passé
            if (typeof arguments[2] === 'undefined') {
                lblTitre = '';
                destination = arguments[0];
                options = arguments[1];
            }
            super(lblTitre,
                (data: any) => {
                    if (destination instanceof Function) {
                        return destination(data || {});
                    } else {
                        return destination;
                    }
                },
                Object.assign({
                    link: true,
                    linkExterne: true,
                    icon: 'open_in_browser',
                    iconButton: true,
                    saveFirst: true,
                    lblTitreValidation: 'G_LBL_FORAGE_IMPOSSIBLE'
                }, options));

        }
    }

    return MenuItemForageExterne;
}
