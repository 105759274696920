import { IComponentController } from 'angular';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IResourceService = angular.resource.IResourceService;

export interface IComposantCycleEvenement extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    etapesCycle: Array<IEtapeCycleEvenement>;
    erreurChargementEtapes: boolean;
    initializingEtapes: boolean;
    initEtapesCycle(): void
}

interface IEtapeCycleEvenement {
    [index: string]: any;
    isEtatActuel: boolean;
    isProchainEtat: boolean;
}

/* @ngInject */
export default function CycleListEtapesController(ApiConfig: IApiConfig,
    $resource: IResourceService,
    parametresSecurite: IParametresSecuriteService) {
    const vm: IComposantCycleEvenement = this;

    vm.$onInit = $onInit;
    vm.initEtapesCycle = initEtapesCycle;

    function $onInit() {
        vm.initEtapesCycle();
    }

    function initEtapesCycle(): void {
        let tmpEtapes: Array<IEtapeCycleEvenement> = [];
        let cleEtatActuel = '';
        let requete;
        let cleDsc = '';

        if (vm.monoOccurrence.cycleCleint === 'doccleint') {
            cleEtatActuel = 'etdcleint';
            cleDsc = 'etddsc';

            requete = $resource(`${ApiConfig.ROOT}/cycles/finance/etapes/:doccleint`, {
                doccleint: vm.monoOccurrence.data.doccleint,
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            });
        } else {
            cleEtatActuel = 'eclcleint';
            cleDsc = 'ecldsceta';

            requete = $resource(`${ApiConfig.ROOT}/cycles/rh/etapes/:trncleint`, {
                trncleint: vm.monoOccurrence.data.trncleint,
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            });
        }

        vm.initializingEtapes = true;

        requete.query().$promise.then((etapes: Array<any>) => {
            for (const etape of etapes) {
                if (etape.cepflgcycbas === undefined ? true : etape.cepflgcycbas || etape[cleEtatActuel] === vm.monoOccurrence.etatActuel[cleEtatActuel]) {
                    tmpEtapes.push(Object.assign({
                        dsc: etape[cleDsc],
                        isEtatActuel: etape[cleEtatActuel] === vm.monoOccurrence.etatActuel[cleEtatActuel],
                        isProchainEtat: etape[cleEtatActuel] === vm.monoOccurrence.prochainEtat.cleintpro
                    }, etape));
                }
            }

            vm.etapesCycle = tmpEtapes;
            vm.erreurChargementEtapes = false;
        }).catch(() => vm.erreurChargementEtapes = true).finally(() => vm.initializingEtapes = false);
    }
}
