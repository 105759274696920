import { module } from 'angular';
import { IMenuItem, IMenuItemClass, IMenuItemOptions } from './menu-item.service';
import { IDialogEcranOptions, IDialogOptions } from '../../dialogs/dialog-ecran/dialog-ecran.controller';
import { IDialog } from '../dialog.service';
import { IState, IStateService } from 'angular-ui-router';
import { IRoute } from '../../interfaces/route.interface';
import { IDialogAllStatus } from '../../constants/dialog.constant';

export interface IMenuItemEcranClass {
    new(lblTitre: string, pageNom: string | IMenuItemEcranPageNomFonction, parametresGetter?: IMenuItemEcranParametresGetter, options?: IMenuItemEcranOptions): IMenuItemEcran;
}

export interface IMenuItemEcran extends IMenuItem { }

interface IMenuItemEcranParametresGetter {
    (data: object): object;
}

interface IMenuItemEcranPageNomFonction {
    (data?: any): string;
}

export interface IMenuItemEcranOptions extends IMenuItemOptions {
    ecran?: string;
    optionsDialog?: IDialogOptions;
    contextAdditionnel?: Function;
    onClose?: (data?: any, response?: any, cancelled?: boolean) => void;
    appliquer?: boolean;
    showActionsInDialog?: boolean;
    customLblFermer?: boolean;
}

export default module('core.services.menu-item-ecran', []).factory('MenuItemEcran', MenuItemEcranFactory);

/* @ngInject */
function MenuItemEcranFactory(MenuItem: IMenuItemClass,
    $state: IStateService,
    DialogStatus: IDialogAllStatus,
    DialogEcran: IDialog) {

    class MenuItemEcran extends MenuItem implements IMenuItemEcran {
        constructor(lblTitre: string, pageNom: string | IMenuItemEcranPageNomFonction, parametresGetter?: IMenuItemEcranParametresGetter, options: IMenuItemEcranOptions = {}) {
            if (!options.directionTooltip && options.iconButton) {
                options.directionTooltip = 'bottom';

                const icon = options.icon instanceof Function ? options.icon({}) : options.icon;
                if (icon === 'create' || icon === 'edit') {
                    lblTitre = 'G_LBL_BTN_EDIT';
                } else if (icon === 'add_circle') {
                    lblTitre = 'G_LBL_BTN_NOUVEAU';
                } else {
                    lblTitre = 'G_LBL_BTN_OUVRIR_MODALE';
                }
            }

            super(lblTitre, ouvrirEcran, options);

            function ouvrirEcran(event: JQueryEventObject, data: any) {
                const stringPageNom = getPageNom(data);
                const state: IState = $state.get(stringPageNom);
                const basePageNom = stringPageNom.split('.').slice(0, -1).join('.');
                const baseState: IState = $state.get(basePageNom);
                const route: IRoute = baseState.params.route;

                const params = (parametresGetter && parametresGetter(data)) || {};

                const titre: string = options.optionsDialog && options.optionsDialog.titre && options.optionsDialog.titre instanceof Function ?
                    options.optionsDialog.titre(data) : options.optionsDialog && options.optionsDialog.titre;

                const dialogOptions: IDialogEcranOptions = {
                    source: route.SOURCE,
                    ecran: options.ecran || route.ECRAN_DEFAUT,
                    titre,
                    state,
                    route,
                    params,
                    contextAdditionnel: options.contextAdditionnel && options.contextAdditionnel(),
                    appliquerEnregistrement: options.appliquer,
                    showActionsInDialog: options.showActionsInDialog,
                    customLblFermer: (options.customLblFermer) ? options.customLblFermer : null,
                    optionsDialog: Object.assign({
                        hideToolbar: true
                    }, options.optionsDialog)
                };

                let resData: any = {};
                let cancelled: boolean = true;
                return DialogEcran.show({locals: dialogOptions})
                    .then((response: any) => {
                        resData = response;
                        cancelled = false;
                        return response;
                    })
                    .catch((err) => {
                        if (err !== DialogStatus.FERMER) {
                            cancelled = false;
                        }

                        throw err;
                    })
                    .finally(() => {
                        if (options.onClose) {
                            options.onClose(data, resData, cancelled);
                        }
                    });
            }

            function getPageNom(data: any): string {
                if (typeof pageNom === 'function') {
                    return pageNom(data);
                } else {
                    return <string>pageNom;
                }
            }
        }
    }

    return MenuItemEcran;
}
