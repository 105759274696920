import {module} from 'angular';
import {IMenuNavigationItem} from '../interfaces/menu-navigation-item.interface';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;
import IResourceService = angular.resource.IResourceService;
import IActionHash = angular.resource.IActionHash;
import { IHyperlien } from "../interfaces/hyperliens.interface";

export interface IEcranDetailsResourcesEntite extends IResource<IEcranDetailsResourcesEntite>{
    ecrcleint: number;
    flgcen: number;
    titre: string;
    valeurs: any;
    params: any;
    compteurs: ICompteursBadge;
    accessRefused: boolean;
    fonctions: {[key: string]: IEcranDetailsFonctions}
    menucleref: string;
    employe: IEcranEmploye;
    hyperliens: IHyperlien[];
    urlclient: { eucdsc: string, eucurl: string }[]
    documclient: { edcdsc: string, edccleint: number}[]
}

interface IEcranEmploye {
    eplnummat: string;
    eplnomprn: string;
    eplnomprnusu: string;
}

export interface IEcranDetailsFonctions {
    srfdsc: string;
    srfnomphy: string;
    flgcon: number;
    flgacc: number;
    flgins: number;
    flgsup: number;
    flgmod: number;
    isChampTransactionnel?: boolean;
}

export interface ICompteursBadge {
    notes: number;
    notesFinance: number;
    piecesJointes: number;
    infosComplementaires: number;
    infosComplementairesFinance: number;
}

interface IMenuNavigationItemLocator extends IMenuNavigationItem {
    pracleint: number;
}

export interface IEcranDetailsResourcesClass extends IResourceClass<IEcranDetailsResourcesEntite> {
    new(data: any): IEcranDetailsResourcesEntite;
    getMenuItem(params: any): IResource<IMenuNavigationItemLocator>;
}

export default module('core.resources.ecran-details', [
    'core.services.api-config'
]).factory('EcranDetailsResource', EcranDetailsResourceFactory);

/* @ngInject */
function EcranDetailsResourceFactory(ApiConfig: IApiConfig,
                                     $resource: IResourceService): IEcranDetailsResourcesClass {

    const params: any = {};

    const actions: IActionHash = {
        getMenuItem: {
            url: `${ApiConfig.ROOT}/ecran-details/:ecrcod/menu-item`,
            method: 'get'
        }
    };

    return <IEcranDetailsResourcesClass> $resource(`${ApiConfig.ROOT}/ecran-details/:ecrcod`, params, actions);
}
