import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogFormulaireController from './dialog-formulaire.controller';

export default module('core.dialogs.dialog-formulaire', [
    'core.services.dialog'
]).factory('DialogFormulaire', DialogFormulaireFactory);

/* @ngInject */
function DialogFormulaireFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogFormulaireController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-formulaire.html'),
       focusOnOpen: false
   });
}
