/**
 * Ce comportement est un remplacement pour le comportement natif "ngReadonly" mais avec un plus haute priorité. La
 * basse priorité de ngReadonly cause des problèmes avec mdInputContainer.
 *
 * Ce composant peut aussi être appliqué sur d'autres composants qu'un input, ce qui est pratique pour les composants
 * intermédiaires comme ex-input-string
 */
import {IAttributes, IAugmentedJQuery, IScope, module} from 'angular';

interface IExReadonlyBehavior extends IScope {
    readonly: boolean;
}

interface IExReadonlyAttributes extends IAttributes {
    exReadonly: string;
}

export default module('core.behaviors.ex-readonly', [])
    .directive('exReadonly', ReadOnlyDirective);

/* @ngInject */
function ReadOnlyDirective() {
    return {
        // Juste après ng-if
        priority: 599,
        compile() {
            return {
                pre: preLink
            };
        }
    };

    function preLink(scope: IExReadonlyBehavior, element: IAugmentedJQuery, attrs: IExReadonlyAttributes) {
        const readonly = scope.$eval(attrs.exReadonly);
        updateAttr(readonly);

        scope.$watch(attrs.exReadonly, (readonly: boolean) => {
            updateAttr(readonly);
        });

        function updateAttr(readonly: boolean) {
            attrs.$set('readonly', readonly || undefined);
            attrs.$set('tabindex', readonly ? -1 : undefined);
        }
    }
}
