import { module } from 'angular';
import { IMenuItem, IMenuItemClass, IMenuItemOptions } from './menu-item.service';
import { IDialog } from '../dialog.service';
import { IMultiOccurrenceClass, IMultiOccurrenceOptions } from '../multi-occurrence.service';
import { IMonoOccurrence } from '../mono-occurrence.service';
import { IOccurrence } from '../occurrence.service';
import { IDialogMultiOccurrenceOptions } from '../../../apps/sofe/dialogs/dialog-multi-occurrence/dialog-multi-occurrence.controller';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';

export interface IMenuItemMultiOccurrenceClass {
    new(lblTitre: string, multiOccurrenceOptions: IMultiOccurrenceOptions | IFonctionMenuItemMultiOccurrenceOptions, options: IMenuItemMultiOccurrenceOptions): IMenuItemMultiOccurrence;
}

export interface IMenuItemMultiOccurrence extends IMenuItem {
}

export interface IMenuItemMultiOccurrenceOptions extends IMenuItemOptions {
    icon?: string;
    largeur?: number;
    useLocalData?: boolean;
    datalist?: Array<any> | ILocalDataListFunction;
    lblCancel?: string;
    titreDialog?: (data: any, occurrence: IOccurrence) => string
    onClose?: () => void;
}

export interface ILocalDataListFunction {
    (): Array<any>;
}

interface IFonctionMenuItemMultiOccurrenceOptions {
    (data: any, occurrence: IOccurrence): IMultiOccurrenceOptions;
}

export default module('core.services.menu-item-multi-occurrence', []).factory('MenuItemMultiOccurrence', MenuItemMultiOccurrenceFactory);

/* @ngInject */
function MenuItemMultiOccurrenceFactory(MenuItem: IMenuItemClass,
    DialogMultiOccurrence: IDialog,
    exLibelleFilter: IFilterLibelle,
    MultiOccurrence: IMultiOccurrenceClass): IMenuItemMultiOccurrenceClass {

    class MenuItemMultiOccurrence extends MenuItem implements IMenuItemMultiOccurrence {

        constructor(lblTitre: string, multiOccurrenceOptions: IMultiOccurrenceOptions | IFonctionMenuItemMultiOccurrenceOptions, options: IMenuItemMultiOccurrenceOptions) {
            super(lblTitre, lancerRecuperation, Object.assign({
                link: false,
                iconButton: false,
                noAction: false,
                icon: 'exit_to_app',
                saveFirst: false
            }, options));

            function lancerRecuperation(event: JQueryEventObject, data: any, occurrence: IOccurrence) {
                if (occurrence instanceof MultiOccurrence) {
                    occurrence.activeRowCleint = data[occurrence.cleint];
                }

                ouvrirDialogue(occurrence, data);
            }

            function ouvrirDialogue(occurrence: IOccurrence, data: any) {

                const dialogOptions: IDialogMultiOccurrenceOptions = {
                    largeur: options.largeur,
                    icon: options.icon,
                    useLocalData: options.useLocalData,
                    datalist: options.datalist,
                    lblTitre: options.titreDialog ? options.titreDialog(data, occurrence) : exLibelleFilter(lblTitre, occurrence.libelles),
                    lblCancel: exLibelleFilter((options.lblCancel || 'G_LBL_BTN_ANNULER'), occurrence.libelles),
                    multiOccurrenceOptions: getMultiOccurrenceOptions(occurrence, data),
                    ecranContext: {
                        stateParams: occurrence.stateParams,
                        ecranDetails: occurrence.ecranDetails,
                        ecranSourceDetails: occurrence.ecranSourceDetails
                    },
                    onClose: options.onClose
                };

                return DialogMultiOccurrence.show({ locals: dialogOptions })
                    .then(function () { }, function (res: any) {
                    }).finally(() => {
                        if (occurrence instanceof MultiOccurrence) {
                            occurrence.fetchDataList({});
                        }
                    });
            }

            function getMultiOccurrenceOptions(occurrence: IOccurrence, data: any): IMultiOccurrenceOptions {
                let newMultiOccurrenceOptions;
                if (multiOccurrenceOptions instanceof Function) {
                    newMultiOccurrenceOptions = multiOccurrenceOptions(data, occurrence)
                } else {
                    newMultiOccurrenceOptions = multiOccurrenceOptions;
                }

                if (occurrence instanceof MultiOccurrence) {
                    newMultiOccurrenceOptions.multiOccurrenceParent = occurrence;
                } else {
                    const monoOccurrence = <IMonoOccurrence>occurrence;
                    newMultiOccurrenceOptions.parentId = newMultiOccurrenceOptions.parentId || monoOccurrence.data[monoOccurrence.cleint];
                }
                //on remetre l'état original dans le cas d'avoir diferente type de champs dans une formulaire dynamique gs0090
                if (newMultiOccurrenceOptions && typeof occurrence?.resetEtatDialog !== "undefined") {
                    newMultiOccurrenceOptions.resetEtatDialog = occurrence.resetEtatDialog
                }
                return newMultiOccurrenceOptions;
            }
        }
    }

    return MenuItemMultiOccurrence;
}
