/**
 * Composant représentant un icône chevron qui s'ouvre et se ferme avec une animation.
 *
 * @param {boolean} opened Contrôle l'ouverture et la fermeture du chevron.
 */
import { IComponentOptions, module } from 'angular';
import ToggleIconController from './ex-toggle-icon.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-toggle-icon', []).component('exToggleIcon', ToggleIconFactory());

function ToggleIconFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            {
                expanded: "<?"
            }
        ),
        require: {
            expandableCtrl: '^^exExpandable'
        },
        controller: ToggleIconController,
        controllerAs: 'vm',
        template: require('./ex-toggle-icon.html')
    };
}
