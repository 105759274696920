/**
 * Composant servant à afficher une liste de valeur séparé par un charactère
 */
import {IComponentOptions, module} from 'angular';
import DataValueListController from './ex-data-value-list.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-value-list', []).component('exDataValueList', DataValueListFactory());

function DataValueListFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COLS,
            Binding.DATA.OPTIONAL,
            Binding.DATA_TYPES.OPTIONAL,
            Binding.SCHEMAS.OPTIONAL,
            Binding.MENUS.OPTIONAL,
            {
                separateur: '@?',
                // Manière de passer un context additionel au template
                context: '<?',
                hideVide: '<?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            multiOccurrenceTemplateCtrl: '?^exMultiOccurrenceTemplate',
            dataSourceCtrl: '?^exDataSource'
        },
        controller: DataValueListController,
        controllerAs: 'vm',
        template: require('./ex-data-value-list.html')
    };
}
