import { IHttpHeadersGetter, IPromise, module } from 'angular';
import { ITri, ITriClass, TypeTri } from '../services/tri.service';
import { IFiltre, IFiltreClass } from '../services/filtre.service';
import { IOperateurService } from '../services/operateur.service';
import { IIsSuccessResponseService } from '../services/utils/is-success-response.service';
import { IPagination, IPaginationClass } from '../services/pagination.service';
import { IApiConfig } from '../interfaces/api-config.interface';
import IResource = ng.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IEtatsResourcesEntite extends IResource<IEtatsResourcesEntite> {
    id: number | string;
    gtecleref: string;
    description: string;
    nom: string;
    pagination: IPagination;
    actif: boolean;
    predefini: boolean;
    axeAffichageActif: string;
    colonnesVisibles: Array<string>;
    colonnesDisponibles: Array<string>;
    colonnesFiltrables: Array<string>;
    filtres: Array<IFiltre>;
    criteresSuggeresData: any;
    tri: ITri;
    isEditionRapide: boolean;
    criteresMultiplesListe?: Array<any>;
    pmtcleint?: any
    equal(etat: IEtatsResourcesEntite): boolean;
    getAllColonnes(): Array<string>;
    getColonnesVisibles(): Array<string>;
    clone(): IEtatsResourcesEntite;
    addFiltre(filtre: IFiltre): void;
    removeFiltre(index: number): void;
    replaceFiltre(filtre: IFiltre, index: number): void;
    $update(data?: any): IPromise<any>;
    emptyFiltreAffichage(): void;
}

export interface IEtatResourceSerializedEntite {
    gtecleint: number;
    gtecleref: string;
    gtenom: string;
    gtedsc: string;
    gtenbrengpag: number;
    gtenbrelepos: string;
    gteflgaxe: number;
    gteaxeaff: string;
    gtecol: string;
    gtefil: string;
    gtetri: string;
    gteedtrpd: number;
    pmtcleint?: any;
}

export interface IEtatsResourcesClass extends IResourceClass<IEtatsResourcesEntite> {
    new(data: any): IEtatsResourcesEntite;
    update: any;
    parseEtat(data: IEtatResourceSerializedEntite): any;
    toJson(etat: IEtatsResourcesEntite): string;
}

export default module('core.resources.etats', []).factory('EtatsResource', EtatsResourceFactory);
const CRITERE_SUGGERE_OPERATEUR = "CRITERE_SUGGERE";
/* @ngInject */
function EtatsResourceFactory(ApiConfig: IApiConfig,
    $resource: ng.resource.IResourceService,
    Filtre: IFiltreClass,
    Operateur: IOperateurService,
    Tri: ITriClass,
    isSuccessResponse: IIsSuccessResponseService,
    Pagination: IPaginationClass): IEtatsResourcesClass {

    function encode(value: any) {
        return encodeURIComponent(JSON.stringify(value));
    }

    function decode(value: any) {
        let result: any;
        //on ajoute un try pour les valeurs qui ne sont pas un URI
        try {
            result = JSON.parse(decodeURIComponent(value));
        } catch (error) {
        }
        return result;
    }

    const actions = {
        query: {
            method: 'get',
            isArray: true,
            transformResponse
        },
        save: {
            method: 'POST',
            transformResponse,
            transformRequest(data: any) {
                return toJson(data);
            }
        },
        update: {
            method: 'PUT',
            transformResponse,
            transformRequest(data: any) {
                return toJson(data);
            }
        }
    };

    const EtatsResource = <IEtatsResourcesClass>$resource(`${ApiConfig.ROOT}/etats/:id`, { id: '@id' }, actions);

    // On ajoute des méthodes à notre classes
    Object.assign(EtatsResource.prototype, {
        equal,
        getAllColonnes,
        clone,
        addFiltre,
        removeFiltre,
        replaceFiltre,
        emptyFiltreAffichage
    });

    EtatsResource.toJson = toJson;
    EtatsResource.parseEtat = parseEtat;

    return EtatsResource;

    function equal(etat: IEtatsResourcesEntite) {
        return this.id === etat.id;
    }

    function getAllColonnes() {
        return this.colonnesDisponibles.concat(this.colonnesVisibles);
    }

    function addFiltre(filtre: IFiltre) {
        this.filtres.push(filtre);
    }

    function removeFiltre(index: number) {
        this.filtres.splice(index, 1);
    }

    function replaceFiltre(filtre: IFiltre, index: number) {
        this.filtres.splice(index, 1, filtre);
    }

    function emptyFiltreAffichage() {
        this.filtres = this.filtres.filter((filtre: IFiltre) => !filtre.affichage);
    }

    function clone() {
        return new EtatsResource({
            id: this.id,
            gtecleref: this.gtecleref,
            nom: this.nom,
            description: this.description,
            predefini: this.predefini,
            colonnesVisibles: (this.colonnesVisibles || []).concat(),
            colonnesDisponibles: (this.colonnesDisponibles || []).concat(),
            colonnesFiltrables: (this.colonnesFiltrables || []).concat(),
            criteresSuggeresData: Object.assign({}, this.criteresSuggeresData),
            filtres: this.filtres.map((filtre: IFiltre) => filtre.clone()),
            axeAffichageActif: this.axeAffichageActif,
            pagination: this.pagination.clone(),
            tri: this.tri.clone(),
            isEditionRapide: this.isEditionRapide,
            criteresMultiplesListe: this.criteresMultiplesListe,
            pmtcleint: this.pmtcleint
        });
    }

    function transformResponse(json: string, headerGetters: IHttpHeadersGetter, status: number) {
        let data = JSON.parse(json);
        if (isSuccessResponse(status)) {
            data = transformData(data);
        }
        return data;
    }

    function transformData(data: any) {
        if (Array.isArray(data)) {
            return data.map((dataItem: any) => parseEtat(dataItem));
        } else {
            return parseEtat(data);
        }
    }

    function toJson(etat: IEtatsResourcesEntite) {
        const filtres = etat.filtres
            // Les filtres paramétrés ne sont pas sauvegardés, mais doivent toujours provenir du URL
            .filter((filtre: IFiltre) => !filtre.parametre && !filtre.affichage)
            .map(filtre => {
                let encodedFiltre = `${filtre.colonne.replace(',', '|')}:${filtre.operateur.nom}:${encode(filtre.getValeur())}:${encode(filtre.getValeur2())}:${encode(filtre.data)}:${encode(filtre.readOnly)}:${encode(filtre.visible)}:${encode(filtre.nonSupprimable)}`;
                if (filtre.colonne.includes(',')) {
                    // Si le filtre est sur plusieurs colonnes, on s'assure que la clé n'est pas encodée.
                    const regex = new RegExp(encode(filtre.colonne), 'g');
                    //On utilise pas la virgule pour éviter de mélanger avec les valeurs.
                    encodedFiltre = encodedFiltre.replace(regex, `${filtre.colonne.replace(',', '|')}`);
                }

                return encodedFiltre
            });

        if (etat.criteresSuggeresData) {
            Object.keys(etat.criteresSuggeresData).filter((col: string) => etat.criteresSuggeresData[col] !== undefined).forEach((critere: string) => {
                filtres.push(`${critere}:${CRITERE_SUGGERE_OPERATEUR}:${encode(etat.criteresSuggeresData[critere])}`);
            });
        }

        const formattedData: any = {
            gtecleint: etat.id,
            gtecleref: etat.gtecleref,
            gtenom: etat.nom,
            gtedsc: etat.description,
            gtenbrengpag: etat.pagination.nombreElementPage,
            gtenbrelepos: etat.pagination.nbElementsPossibles.toString(),
            gteflgaxe: etat.predefini ? 1 : 0,
            gtecol: etat.colonnesVisibles.join(','),
            gteaxeaff: etat.axeAffichageActif,
            gtefil: filtres.join(','),
            gtetri: etat.tri.toString(),
            gteedtrpd: etat.isEditionRapide ? 1 : 0
        };

        return JSON.stringify(formattedData);
    }

    function parseEtat(data: IEtatResourceSerializedEntite): any {
        const filtresJson = (data.gtefil && data.gtefil.split(',')) || [];
        const colonnesJson = (data.gtecol && data.gtecol.split(',')) || [];
        const triJson = (data.gtetri && data.gtetri.split(',')) || [];

        const filtres: Array<IFiltre> = [];
        const criteresSuggeresData: any = {};

        filtresJson.forEach((filtre: any) => {
            const [colonne, operateur, valeur, valeur2, data, readOnly, visible, nonSupprimable] = filtre.split(':');

            if (operateur === CRITERE_SUGGERE_OPERATEUR) {
                criteresSuggeresData[colonne] = decode(valeur);
            } else {
                filtres.push(new Filtre({
                    colonne: colonne.replace('|', ','),
                    operateur: Operateur[operateur],
                    valeur: decode(valeur),
                    valeur2: decode(valeur2),
                    data: decode(data),
                    readOnly: readOnly ? decode(readOnly) : false,
                    visible: visible ? decode(visible) : true,
                    nonSupprimable: nonSupprimable ? decode(nonSupprimable) : false,
                }));
            }
        });

        return {
            id: data.gtecleint,
            gtecleref: data.gtecleref,
            nom: data.gtenom,
            pagination: new Pagination({
                nombreElementPage: data.gtenbrengpag,
                nbElementsPossibles: data.gtenbrelepos.split(',').map(n => Number(n))
            }),
            description: data.gtedsc,
            predefini: Boolean(data.gteflgaxe),
            colonnesVisibles: colonnesJson,
            colonnesDisponibles: [],
            colonnesFiltrables: [],
            filtres,
            criteresSuggeresData,
            axeAffichageActif: data.gteaxeaff,
            tri: triJson.reduce((tri: ITri, colonneTri: string) => {
                const [nom, typeTri] = colonneTri.split('/');
                tri.setTriColonne(nom);
                tri.liste[tri.liste.length - 1].typeTri = <TypeTri>`/${typeTri}`;
                return tri;
            }, new Tri()),
            isEditionRapide: Boolean(data.gteedtrpd)
        };
    }
}
