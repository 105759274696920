/**
 * Détermine si un message du serveur doit être affiché ou sinon un message par défaut
 */
import {module} from 'angular';

export default module('core.filters.ex-message', []).filter('exMessage', messageFilter);

export interface IFilterMessage {
    (message: string, defaultMessage: string): string;
}

/* @ngInject */
function messageFilter(): IFilterMessage {
    return (message: string, defaultMessage: string, code: string = ''): string => {
        if (message && (message.startsWith('APP-') || message.startsWith('ORA-2010') || code.startsWith('SOFE-2010'))) {
            return message;
        } else {
            return defaultMessage;
        }
    }
}
