import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IEditionAvanceeDataTypeClass {
    new(options?: IEditionAvanceeDataTypeOptions): IEditionAvanceeDataType;
}

export interface IEditionAvanceeDataTypeOptions extends IDataTypeOptions {
}

export interface IEditionAvanceeDataType extends IDataType {
}

export default module('core.services.edition-avancee-data-type', []).factory('EditionAvanceeDataType', EditionAvanceeDataTypeFactory);

/* @ngInject */
function EditionAvanceeDataTypeFactory(DataType: IDataTypeClass): IEditionAvanceeDataTypeClass {
    class EditionAvanceeDataType extends DataType implements IEditionAvanceeDataType {}

    return EditionAvanceeDataType;
}
