/**
 * Comportement permettant de contrôler l'ouverture et la fermeture d'un élément.
 *
 * Ce comportement requère 2 éléments: le "toggle" et le "target". Le "toggle" est l'élément qui, lorsque cliqué,
 * ouvrira le "target".
 *
 * Lorsqu'appliqué à un élément, par défaut le premier descendant est le "toggle" et le second le "target". Il est
 * aussi possible de spécifier un ou plusieurs "toggle" en applicant l'attribut `ex-expandable-toggle`, et un ou
 * plusieurs "target" en attribute l'attribut `ex-expandable-target` à des éléments enfants.
 *
 */
import * as angular from 'angular';
import {
    IAngularEvent,
    IAugmentedJQuery,
    IComponentController,
    IDirective,
    IRootScopeService,
    IScope,
    ITimeoutService,
    module
} from 'angular';
import { IExpandableService } from '../../services/expandable.service';
import { IExAugmentedEvent } from '../../interfaces/event.interface';

export default module('core.behaviors.ex-expandable', [
    'core.services.expandable'
]).directive('exExpandable', ExpandableDirective);

export interface IExpandableBehaviorController extends IComponentController {
    // If is doing its first render
    initialRender: boolean;
    isWatching: boolean;
    expanded: boolean;
    disabled: boolean;
    disabledExpandable: boolean; //Permets de désactiver l'expandable sans désactiver le contenu
    name?: string;
    initOn: { expression: any, value: any }
    watcher: () => void;


    toggle(expanded?: boolean): void;
    onExpandableChange(callback: (event: IAngularEvent, expanded: boolean) => void): () => void;
}

/* @ngInject */
function ExpandableDirective(expandable: IExpandableService): IDirective {
    const NOT_APPLY_TOGGLE_SELECTOR = '[ex-expandable-not-apply-toggle]';
    const TOGGLE_SELECTOR = '[ex-expandable-toggle]';
    const TARGET_SELECTOR = '[ex-expandable-target]';
    const EXPANDED_CLASS = 'ex-expandable--expanded';
    const COLLAPSED_CLASS = 'ex-expandable--collapsed';
    const TARGET_CLASS = 'ex-expandable-target';
    const TARGET_EXPANDED_CLASS = 'ex-expandable-target--expanded';
    const TARGET_COLLAPSED_CLASS = 'ex-expandable-target--collapsed';

    return {
        scope: {
            expanded: '=?',
            disabled: '<?ngDisabled',
            disabledExpandable: '<?',
            initOn: '<?exExpandableInitOn',
            name: '@exExpandable',
            occurrenceExpandable: '=?'
        },
        restrict: 'A',
        bindToController: true,
        controllerAs: 'vm',
        /* @ngInject */
        controller: function ExpandableModel($scope: IScope, $element: IAugmentedJQuery, $timeout: ITimeoutService, $rootScope: IRootScopeService) {
            const vm: IExpandableBehaviorController = this;

            let targetElements: JQuery;

            vm.toggle = toggle;
            vm.onExpandableChange = onExpandableChange;

            vm.$onInit = $onInit;

            function $onInit() {
                if (typeof vm.initOn !== 'undefined'
                    && typeof vm.initOn.expression !== 'undefined'
                    && typeof vm.initOn.value !== 'undefined') {
                    vm.watcher = $scope.$watch('vm.initOn.expression', (newValue: any) => {
                        if (newValue === vm.initOn.value) {
                            vm.watcher();
                            initExpandable();
                        }
                    });
                } else {
                    initExpandable();
                }
            }

            function initExpandable() {
                if (typeof vm.disabled === 'undefined' && typeof vm.disabledExpandable === 'undefined' ||
                    typeof vm.disabledExpandable !== 'undefined' && typeof vm.disabled !== 'undefined') {
                    vm.disabled = false;
                }

                if (typeof vm.disabledExpandable !== 'undefined' && typeof vm.disabled === 'undefined') {
                    vm.disabled = vm.disabledExpandable;
                }

                // On ne watch que si l'attribute "expanded" est défini
                vm.isWatching = typeof vm.expanded !== 'undefined' && vm.expanded === true || vm.expanded === false;

                let stopExpandableWatcher: () => void;
                // Si le nom est défini, nous observons le service `expandable` qui permet d'ouvrir un expandable à partir d'un service
                if (vm.name) {
                    stopExpandableWatcher = expandable.watch(vm.name, (expanded: boolean) => {
                        if (vm.expanded !== expanded && vm.disabled === false && !vm.disabledExpandable) {
                            vm.expanded = expanded;

                            if (!vm.isWatching) {
                                toggleExpansion();
                            }
                        }
                    });
                }

                vm.initialRender = true;
                vm.expanded = vm.expanded || false;

                let toggleElements: JQuery = filtreImbriqueElements($element.find(TOGGLE_SELECTOR));
                targetElements = filtreImbriqueElements($element.find(TARGET_SELECTOR));

                // Par défaut, le premier élément est le toggle
                if (!toggleElements.length) {
                    toggleElements = $element.children().eq(0);
                }

                // Par défaut, le deuxième élément est le target
                if (!targetElements.length) {
                    targetElements = $element.children().eq(1);
                }

                targetElements.addClass(TARGET_CLASS);

                // Lorsqu'on click sur le "toggle", on ouvre ou ferme
                toggleElements.on('click', onToggleElementClick);

                $scope.$on('$destroy', () => {
                    toggleElements.off('click', onToggleElementClick);
                    if (stopExpandableWatcher) {
                        stopExpandableWatcher();
                    }
                });

                if (vm.isWatching) {
                    $scope.$watch('vm.expanded', () => {
                        toggleExpansion();
                    });
                } else {
                    toggleExpansion();
                }
            }

            // Cette fonction est utilisé dans NavigationController par JQuery
            function toggle(expanded: boolean = !vm.expanded): void {
                if (vm.expanded !== expanded && vm.disabled === false && !vm.disabledExpandable) {
                    vm.expanded = expanded;

                    if (!vm.isWatching) {
                        toggleExpansion();
                    }
                }
            }

            function onExpandableChange(callback: (event: IAngularEvent, expanded: boolean) => void): () => void {
                return $scope.$on('exExpandableChange', callback);
            }

            function getBloc(event: IExAugmentedEvent) {
                let result = ""
                let srccod = ""
                try {
                    result = event.currentTarget.parentElement.getAttribute("occurrence-expandable-bloc").trim();
                    srccod = event.currentTarget.parentElement.getAttribute("occurrence-expandable-srccod").trim();
                } catch (error) {
                }
                return [result, srccod];
            }

            function onToggleElementClick(event: IExAugmentedEvent) {
                const getDataBloc = getBloc(event)
                const bloc = getDataBloc[0];
                const srccod = getDataBloc[1]
                const isNotApplyToggle = Boolean(angular.element(event.target).closest(NOT_APPLY_TOGGLE_SELECTOR).length);
                if (event.originalEvent && event.originalEvent.exExpandablePreventAction || isNotApplyToggle) {
                    return;
                }
                if (bloc === "" || !vm.occurrenceExpandable || !vm.occurrenceExpandable?.bloc) {
                    applyTooleExpansion()
                    return;
                }
                if (bloc !== vm.occurrenceExpandable.bloc.trim()) {
                    applyTooleExpansion()
                    return;
                }
                else {
                    $timeout(() => {
                        $rootScope.$broadcast("ex-expandable.hasErrorGridToogle", { bloc, srccod })
                    }, 200)
                }
                return;
            }
            function applyTooleExpansion() {
                $scope.$applyAsync(() => {
                    if (vm.disabled === false && !vm.disabledExpandable) {
                        vm.expanded = !vm.expanded;
                        if (!vm.isWatching) {
                            toggleExpansion();
                        }
                    }
                });
            }

            let listener = $rootScope.$on('ex-expandable.setFlagRequeteToogle', function (event, data) {
                if (event.defaultPrevented) {
                    return;
                }
                if (data && vm.occurrenceExpandable && vm.occurrenceExpandable.bloc === data.bloc && vm.occurrenceExpandable.srccod === data.srccod) {
                    targetElements.each((index: number, targetDOMElement: HTMLElement) => {
                        const listClassCss = targetDOMElement.classList.value;
                        const isExpanded = String(listClassCss).includes("--expanded");
                        const classToRemove = !isExpanded ? TARGET_COLLAPSED_CLASS : TARGET_EXPANDED_CLASS;
                        targetDOMElement.classList.remove(classToRemove);

                        const classToAdd = !isExpanded ? TARGET_EXPANDED_CLASS : TARGET_COLLAPSED_CLASS;
                        targetDOMElement.classList.add(classToAdd);
                    });
                }
            });
            $rootScope.$on('$destroy', listener);

            function toggleExpansion() {
                // On s'assure que vm.expanded est en Boolean
                vm.expanded = Boolean(vm.expanded);
                $timeout(() => {
                    $element.toggleClass(EXPANDED_CLASS, vm.expanded);
                    $element.toggleClass(COLLAPSED_CLASS, !vm.expanded);

                    $scope.$emit('exExpandableChange', vm.expanded);

                    targetElements.each((index: number, targetDOMElement: HTMLElement) => {
                        const classToRemove = vm.expanded ? TARGET_COLLAPSED_CLASS : TARGET_EXPANDED_CLASS;
                        targetDOMElement.classList.remove(classToRemove);

                        const classToAdd = vm.expanded ? TARGET_EXPANDED_CLASS : TARGET_COLLAPSED_CLASS;
                        targetDOMElement.classList.add(classToAdd);
                    });
                });
            }

            function filtreImbriqueElements(elements: JQuery) {
                return elements.filter((index: number, elementCourrant: Element) => {
                    const parent = angular.element(elementCourrant).closest('[ex-expandable]');
                    return (parent.length && parent[0] === $element[0]);
                })
            }
        }
    };
}
