import { module } from 'angular';
import { IClonable } from '../interfaces/clonable.interface';

export interface IPaginationClass {
    new(options?: IPaginationOptions): IPagination;
}

export interface IPagination extends IClonable<IPagination> {
    readonly totalFixe: boolean;
    onChange: Function;
    onNext: Function;
    onPrevious: Function;
    total: number;
    nombreElementPage: number;
    nombreElementPageMin: number;
    pageCourante: number;
    nbElementsPossibles: Array<number>;
    nombreEnregistrementInMenuMore?: boolean;
    hidePagination?: boolean;

    getIndexCurrentPage(): number;
    getPageLimite(): number;
    getLimit(): number;
    getStart(): number;
    getNext(): void;
    getPrevious(): void;
    getFirstPage(): void;
    setPage(page: number): void;
    hasNext(): boolean;
    hasPrevious(): boolean;
}

export interface IPaginationOptions {
    onChange?: Function;
    nombreEnregistrementInMenuMore?: boolean;
    onNext?: Function;
    onPrevious?: Function;
    total?: number;
    pageCourante?: number;
    nombreElementPage?: number;
    nbElementsPossibles?: Array<number>;
    hidePagination?: boolean;
}

export default module('core.services.pagination', []).factory('Pagination', PaginationFactory);

function PaginationFactory() {
    const nbElementsPossiblesDefaut = [20, 50, 100];
    const nombrePagesGlobal = [250, 500]
    class Pagination implements IPagination {
        readonly totalFixe: boolean;

        public total: number = null;
        public onChange: Function;
        public onNext: Function;
        public onPrevious: Function;

        public pageCourante: number = 1;
        public nombreElementPage: number = nbElementsPossiblesDefaut[0];
        public nombreElementPageMin: number = nbElementsPossiblesDefaut[0];
        public hidePagination: boolean;
        public nbElementsPossibles: Array<number> = nbElementsPossiblesDefaut;

        public nombreEnregistrementInMenuMore: boolean = true;

        constructor(options: IPaginationOptions | IPagination = {}) {
            if (options.total !== undefined) {
                this.total = options.total;

                if (this.total !== null) {
                    this.totalFixe = true;
                }
            }

            if (options.pageCourante !== undefined) {
                this.pageCourante = options.pageCourante;
            }

            this.onChange = options.onChange;
            this.onNext = options.onNext;
            this.onPrevious = options.onPrevious;

            if (options.nbElementsPossibles !== undefined) {
                this.nbElementsPossibles = options.nbElementsPossibles;
                nombrePagesGlobal.map((el) => {
                    //on valide qu'il n'y pas de doublons
                    if (!this.nbElementsPossibles.includes(el)) {
                        this.nbElementsPossibles.push(el)
                    }
                })
                this.nombreElementPageMin = Number(this.nbElementsPossibles[0]);
            }

            if (options.nombreEnregistrementInMenuMore !== undefined) {
                this.nombreEnregistrementInMenuMore = options.nombreEnregistrementInMenuMore;
            }

            this.hidePagination = options.hidePagination || false;

            if (options.nombreElementPage !== undefined) {
                this.nombreElementPage = options.nombreElementPage;
            }
        }

        getIndexCurrentPage(): number {
            return (this.pageCourante - 1) * this.nombreElementPage;
        }

        setPage(page: number): void {
            const nbTotalPage = this.total / this.nombreElementPage;
            if (page <= nbTotalPage && page >= 1) {
                if (page < this.pageCourante && this.getPrevious) {//Previous
                    const nbStep = this.pageCourante - page;
                    Array.from(Array(nbStep).keys()).forEach(() => {
                        this.getPrevious();
                    });
                } else if (page > this.pageCourante && this.getNext) {//Next
                    const nbStep = page - this.pageCourante;
                    Array.from(Array(nbStep).keys()).forEach(() => {
                        this.getNext();
                    });
                }
                this.pageCourante = page;
            }
        }

        getNext(): void {
            if (this.hasNext()) {
                this.pageCourante++;
                if (this.onNext) {
                    this.onNext();
                }
                if (this.onChange) {
                    this.onChange();
                }
            }
        }

        getPrevious(): void {
            if (this.hasPrevious()) {
                this.pageCourante--;
                if (this.onPrevious) {
                    this.onPrevious();
                }
                if (this.onChange) {
                    this.onChange();
                }
            }
        }

        getFirstPage(): void {
            if (this.hasPrevious()) {
                this.pageCourante = 1;
                if (this.onPrevious) {
                    this.onPrevious();
                }
                if (this.onChange) {
                    this.onChange();
                }
            }
        }

        hasNext(): boolean {
            if (!this.totalFixe) {
                return this.nombreElementPage === this.total - 1;
            } else {
                return this.nombreElementPage * this.pageCourante < this.total;
            }
        }

        hasPrevious(): boolean {
            return this.pageCourante > 1;
        }

        getPageLimite(): number {
            return Math.ceil(this.total / this.nombreElementPage);
        }

        getLimit(): number {
            return this.nombreElementPage;
        }

        /**
         * Permet le retour du positionnement selon la page courante
         * @returns {number}
         */
        getStart(): number {
            return ((this.pageCourante - 1) * this.nombreElementPage) + 1;
        }

        public clone(): IPagination {
            return new Pagination(this);
        }
    }

    return Pagination;
}
