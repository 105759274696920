import {module} from 'angular';

export interface IEcranStateParamsService {
    (stateParams: any): any;
}

export default module('core.services.utils.ecran-state-params', []).factory('ecranStateParams', ecranStateParamsFactory);

/* @ngInject */
function ecranStateParamsFactory() : IEcranStateParamsService {
    return function ecranStateParams(stateParams: any): any {
        return stateParams.route.QUERY_PARAMS.reduce((params: any, param: string) => {
            const nomParam = param.replace(/^\?/, '');
            if (typeof stateParams[nomParam] !== 'undefined') {
                params[nomParam] = stateParams[nomParam];
            }
            return params;
        }, {});
    };
}
