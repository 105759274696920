import {IAugmentedJQuery, IComponentController, IFormController, ITimeoutService} from 'angular';
import {IDefaultsService} from '../../services/utils/defaults.service';

interface IComposantInputRecherche extends IComponentController {
    value: string;
    keydownAction: (context: {$event: Event}) => void;
    blurAction: (context: {$event: Event}) => void;
    nameElement: string;
    formCtrl: IFormController;
    effacerRecherche(): void;
    onKeyDown(event: KeyboardEvent): void;
    onBlur(event: KeyboardEvent): void;
}

/* @ngInject */
export default function InputRechercheController(defaults: IDefaultsService,
                                                 $element: IAugmentedJQuery,
                                                 $timeout: ITimeoutService) {
    const vm: IComposantInputRecherche = this;

    vm.$onInit = $onInit;
    vm.effacerRecherche = effacerRecherche;
    vm.onKeyDown = onKeyDown;
    vm.onBlur = onBlur;

    function $onInit() {
        defaults(vm, {
            placeholder: 'G_LBL_EX_RECHERCHE'
        });
    }

    function effacerRecherche(): void {
        resetRecherche();
        positionneFocus();
    }

    function resetRecherche(): void {
        vm.value = '';

        if (vm.formCtrl && vm.formCtrl[vm.nameElement]) {
            // Sans ça, les views change listeners ne sont pas déclanchés
            vm.formCtrl[vm.nameElement].$setViewValue('');
            vm.formCtrl[vm.nameElement].$commitViewValue();
            vm.formCtrl[vm.nameElement].$render();
        }
    }

    function positionneFocus(): void {
        $timeout(() => {
            $element.find('input').trigger('focus');
        });
    }

    function onKeyDown(event: KeyboardEvent) {
        if (event.key === "Escape") {
            effacerRecherche();
        }

        if (vm.keydownAction) {
            vm.keydownAction({$event: event});
        }
    }

    function onBlur(event: KeyboardEvent) {
        if (vm.blurAction) {
            vm.blurAction({$event: event});
        }
    }
}
