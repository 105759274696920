import {IComponentOptions, module} from 'angular';
import InputImputationController from './ex-input-imputation.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-input-imputation', []).component('exInputImputation', InputImputationFactory());

function InputImputationFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.COL,
            Binding.VALUE,
            Binding.DISABLED,
            Binding.LIBELLES.OPTIONAL,
            Binding.DATA.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                required: '<?ngRequired',
                lblTitre: '@?',
                srccodref: '@?',
                hidden: '<?isHidden',
                isValidatingAtLoad: '<?'
            }
        ),
        require: {
            formCtrl: '^^form',
            monoOccurrenceEcranCtrl: '^^?exMonoOccurrenceEcran',
            multiOccurrenceCtrl: '^^?exMultiOccurrence',
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: InputImputationController,
        template: require('./ex-input-imputation.html')
    };
}
