import {IComponentOptions, module} from 'angular';
import FormulaireInputListController from './ex-formulaire-input-list.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-formulaire-input-list', [
]).component('exFormulaireInputList', FormulaireInputListFactory());

function FormulaireInputListFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.OCCURRENCE,
            Binding.FORMULAIRE,
            Binding.DATA,
            {
                formCtrl: '=?',
                enregistrable: '<?'
            }
        ),
        require: {
            formulaireInputListCtrl: '^?exFormulaireInputList',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: FormulaireInputListController,
        controllerAs: 'vm',
        template: require('./ex-formulaire-input-list.html')
    };
}
