import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogRequerantController from '../dialog-requerant/dialog-requerant.controller';

export default module('app.dialogs.dialog-requerant', [
    'core.services.dialog'
]).factory('DialogRequerant', DialogRequerantFactory);

/* @ngInject */
function DialogRequerantFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogRequerantController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-requerant.html'),
        focusOnOpen: false
    });
}
