import {module} from 'angular';
import SuiviModificationController from './dialog-suivi-modification.controller';
import {IDialogClass} from '../../services/dialog.service';

export default module('app.dialogs.dialog-suivi-modification', [
    'core.services.dialog'
]).factory('DialogSuiviModification', DialogSuiviModificationFactory);

/* @ngInject */
function DialogSuiviModificationFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: SuiviModificationController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-suivi-modification.html'),
       multiple: true
   });
}
