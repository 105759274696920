/**
 * Composant représentant une donnée sous form text-valeur. Les valeurs peuvent être soit passés via un object, soit
 * via des transcludes, ce qui permet l'utilisation de HTML.
 *
 * @param {object} [data] Un object de données. Si présent, l'attribut `col` est requis.
 * @param {string} [col] La colonne à afficher. Si présent, l'attribute `data` est requis.
 * @param {object} [libelles] Un object de libellés pour le texte.
 * @param {boolean} [hasBottomPadding] Si l'item devrait avoir un padding au bas.
 * @param {boolean} [hasTopPadding] Si l'item devrait avoir un padding au bas.
 *
 * @param {transclude} [exDataTexte] Le texte de l'item.
 * @param {transclude} [exDataValeur] La valeur de l'item.
 *
 * @example Object
 * <ex-data data={val1: '1234 rue Foobar'} col='val1' libelles="libelles"></ex-data>
 *
 * @example Transclude
 * <ex-data>
 *     <ex-data-texte>Adresse</ex-data-texte>
 *     <ex-data-valeur>1234 rue Foobar</ex-data-valeur>
 * </ex-data>
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import DataController from './ex-data.controller';

export default module('core.components.ex-data', [
    'core.services.utils.defaults'
]).component('exData', DataFactory());

function DataFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA.OPTIONAL,
            Binding.COL.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.DATA_TYPES.OPTIONAL,
            Binding.SCHEMAS.OPTIONAL,
            {
                largeur: '<?',
                largeurTexte: '<?',
                classTexte: '<?',
                hasBottomPadding: '<?',
                hasTopPadding: '<?',
                classValue: '@?',
                menu: '<?',
                hideVide: '<?',
                secondaire: '<?',
                hideLibelle: '<?',
                libelleDroite: '<?',
                libelleValeur: '<?',
                titre: '@?',
                alignmentValeur: '<?',
                trustAsHtml: '<?',
                listeDetailsCss: '=?',
            }
        ),
        transclude: {
            texte: '?exDataTexte',
            valeur: '?exDataValeur'
        },
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource',
            dataListCtrl: '?^exDataList'
        },
        controller: DataController,
        controllerAs: 'vm',
        template: require('./ex-data.html')
    };
}
