import {IComponentOptions, module} from 'angular';
import CaseCocheController from './ex-case-coche.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-case-coche', []).component('exCaseCoche', CaseCocheFactory());

/**
 * Représente une case contenant une coche
 */
function CaseCocheFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            {
                largeur: '@',
                alignment: '@?',
                hideLibelle: '<?',
                hideVide: '<?'
            }
        ),
        require: {
            dataSourceCtrl: '?^exDataSource'
        },
        controller: CaseCocheController,
        controllerAs: 'vm',
        template: require('./ex-case-coche.html')
    };
}
