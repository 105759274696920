import {module} from 'angular';

export interface IIsSuccessResponseService {
    (status: number): any;
}

export default module('core.services.utils.is-success-response', []).factory('isSuccessResponse', isSuccessResponseFactory);

/* @ngInject */
function isSuccessResponseFactory() : IIsSuccessResponseService {
    return function isSuccessResponse(status: number) {
        return 200 <= status && status < 300;
    }
}
