import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import {ILovEntiteExterneDataTypeOptions} from './lov-entite-externe-data-type.service';
import * as angular from 'angular';

export interface ILovIntervenantDataTypeClass {
    new(options?: ILovIntervenantDataTypeOptions): ILovIntervenantDataType;
}

export interface ILovIntervenantDataTypeOptions extends IDataTypeOptions {
}

export interface ILovIntervenantDataType extends IDataType {
}

export default module('core.services.lov-intervenant-data-type', []).factory('LovIntervenantDataType', LovIntervenantDataTypeFactory);

/* @ngInject */
function LovIntervenantDataTypeFactory(DataType: IDataTypeClass): ILovIntervenantDataTypeClass {
    class LovIntervenantDataType extends DataType implements ILovIntervenantDataType {
        constructor(options: ILovEntiteExterneDataTypeOptions) {
            super(angular.extend({
                alignment: 'left'
            }, options));
        }
    }

    return LovIntervenantDataType;
}
