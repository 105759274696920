import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import {IImageBlob} from '../../interfaces/image-blob.interface';

export interface ISelectionSpecialiseeDataTypeClass {
    new(options?: ISelectionSpecialiseeDataTypeOptions): ISelectionSpecialiseeDataType;
}

export interface ISelectionSpecialiseeDataTypeOptions extends IDataTypeOptions {
}

export interface ISelectionSpecialiseeDataType extends IDataType {
    image: IImageBlob;
    valeurs: Array<string>;
}

export default module('core.services.selection-specialisee-data-type', []).factory('SelectionSpecialiseeDataType', SelectionSpecialiseeDataTypeFactory);

/* @ngInject */
function SelectionSpecialiseeDataTypeFactory(DataType: IDataTypeClass): ISelectionSpecialiseeDataTypeClass {
    class SelectionSpecialiseeDataType extends DataType implements ISelectionSpecialiseeDataType {
        readonly template: string = `{{vm.col | exLov : vm.data : vm.dataType}}`;
        readonly image: IImageBlob;
        readonly valeurs: Array<string>;

        constructor(options: ISelectionSpecialiseeDataTypeOptions = {}) {
            super(options);
            this.image = options.params.image;
            this.valeurs = options.params.valeurs;
        }
    }

    return SelectionSpecialiseeDataType;
}
