import {IComponentController, IScope, ITimeoutService} from 'angular';
import {ILibellesClass} from '../../services/libelles.service';
import {IDefaultsService} from '../../services/utils/defaults.service';

type ErreurChargementLayout = 'vertical' | 'horizontal';

interface IComposantErreurChargement extends IComponentController {
    layout: ErreurChargementLayout;
    busy: boolean;
    busyStart: number;
    lblMessage: string;
    lblRetry: string;
    getLayout(): string;
    retry(): void;
    retryAction(): void;
}

/* @ngInject */
export default function ErreurChargementController($scope: IScope,
                                                   $timeout: ITimeoutService,
                                                   defaults: IDefaultsService,
                                                   Libelles: ILibellesClass) {
    const MINIMUM_CHARGEMENT = 500;
    const vm: IComposantErreurChargement = this;

    vm.$onInit = $onInit;
    vm.getLayout = getLayout;
    vm.retry = retry;

    function $onInit() {
        defaults(vm, {
            layout: vm.layout || 'vertical',
            lblMessage: getLblMessage(),
            lblRetry: getLblRetry()
        });

        $scope.$watch('vm.retrying', (newValue: boolean, oldValue: boolean) => {
            if (newValue !== oldValue) {
                if (newValue) {
                    vm.busyStart = Date.now();
                    vm.busy = true;
                } else {
                    // Si le loader disparaît trop rapidement, on dirait que ça ne fonctionne pas. Alors on met un
                    // minimum de temps d'affichage.
                    const now = Date.now();
                    const duration = now - vm.busyStart;
                    if (duration < MINIMUM_CHARGEMENT) {
                        $timeout(() => {
                            vm.busy = false;
                        }, MINIMUM_CHARGEMENT - duration)
                    } else {
                        vm.busy = false;
                    }
                }
            }
        });
    }

    function getLblMessage() {
        if (!Libelles.libellesGlobaux.G_MSG_CHARG_DONNEES) {
            return 'Désolé, une erreur de chargement s\'est produite.';
        } else {
            return 'G_MSG_CHARG_DONNEES';
        }
    }

    function getLblRetry() {
        if (!Libelles.libellesGlobaux.G_LBL_BTN_REESSAYER) {
            return 'Réessayer';
        } else {
            return 'G_LBL_BTN_REESSAYER';
        }
    }

    function getLayout() {
        return (vm.layout === 'vertical') ? 'column' : 'row';
    }

    function retry() {
        vm.retryAction();
    }
}
