import * as angular from 'angular';
import IMenuService = angular.material.IMenuService;
import { IAugmentedJQuery, IComponentController } from 'angular';
import { IMenu } from '../../services/menu/menu.service';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMenuDivider, IMenuDividerClass } from '../../services/menu/menu-divider.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IOccurrence } from '../../services/occurrence.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IComposantMenu extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    menu: IMenu;
    data: any;
    offset: string;
    occurrence: IOccurrence;
    iconDynamique: boolean;
    titreDynamique: boolean;
    disabled: boolean;

    isMenuItem(menuItem: any): boolean;
    isMenuDivider(menuItem: any): boolean;
    isMenuDividerVisible(menuItem: IMenuDivider): boolean;
    getMenuItemHidden(menuItem: IMenuItem): boolean;
    openMenu(event: MouseEvent, menu: IMenuService): void;
    isHiddenFunction(menuitem: IMenuItem): boolean;
}

/* @ngInject */
export default function MenuController(defaults: IDefaultsService,
    MenuItem: IMenuItemClass,
    MenuDivider: IMenuDividerClass,
    $element: IAugmentedJQuery,
    dataLinker: IDataLinker) {
    const vm: IComposantMenu = this;

    vm.$onInit = $onInit;
    vm.isMenuItem = isMenuItem;
    vm.isMenuDivider = isMenuDivider;
    vm.isMenuDividerVisible = isMenuDividerVisible;
    vm.isHiddenFunction = isHiddenFunction;
    function $onInit() {
        defaults(vm, {
            offset: '-10 53',
            iconDynamique: vm.menu.icon instanceof Function,
            titreDynamique: vm.menu.lblTitre instanceof Function,
        });

        if (vm.data) {
            dataLinker.link($element, vm.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
        }
    }
    vm.getMenuItemHidden = getMenuItemHidden;

    vm.openMenu = openMenu;

    function isMenuItem(menuItem: any): boolean {
        return menuItem instanceof MenuItem;
    }

    function isMenuDivider(menuItem: any): boolean {
        return menuItem instanceof MenuDivider;
    }

    function getMenuItemHidden(menuItem: IMenuItem): boolean {
        return hasAccesFonction(menuItem) ? getHiddenValue(menuItem) : true;
    }

    function hasAccesFonction(menuItem: IMenuItem): boolean {
        const fonctionName = menuItem.fonction;
        if (fonctionName) {
            const mnemonique = menuItem.mnemonique || vm.occurrence && vm.occurrence.mnemonique;
            const fonctions = vm.ecranContextCtrl.ecranDetails.fonctions;
            const formattedFonctionName = (mnemonique) ? `${mnemonique}.${fonctionName}` : fonctionName;
            const fonction = fonctions[formattedFonctionName.toUpperCase()];
            return Boolean(!fonction || fonction.flgacc);
        } else {
            return true;
        }
    }

    function getHiddenValue(menuItem: IMenuItem) {
        if (menuItem.hidden instanceof Function) {
            return menuItem.hidden(vm.data);
        } else if (menuItem.visible instanceof Function) {
            return !menuItem.visible(vm.data);
        } else {
            return menuItem.hidden;
        }
    }

    function isMenuDividerVisible(menuItem: IMenuDivider) {
        const index = vm.menu.listeMenuItem.indexOf(menuItem);
        const prevMenuItems = vm.menu.listeMenuItem.slice(0, index);
        const hasPrevMenuItemVisible = prevMenuItems.some((item: IMenuItem) => !getMenuItemHidden(item) && !isMenuDivider(item));

        return menuItem.visible && hasPrevMenuItemVisible;
    }

    function openMenu(event: MouseEvent, menu: IMenuService): void {
        if (!vm.disabled) {
            vm.isClosed = false;
            menu.open(event)
        }
    }

    function isHiddenFunction(menuItem: IMenuItem): boolean {
        return menuItem.hidden instanceof Function;
    }
}
