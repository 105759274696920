import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import AccordeonController from './ex-accordeon.controller';

export default module('core.components.ex-accordeon', []).component('exAccordeon', AccordeonFactory());

function AccordeonFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                opened: '=?',
                lblTitre: '@?',
                disabled: '<?ngDisabled',
                sticky: '<?',
                stickyOptions: '<?',
                blockCollapsable: '<?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran'
        },
        transclude: {
            header: '?exAccordeonHeader',
            actions: '?exAccordeonActions',
            content: '?exAccordeonContent'
        },
        controllerAs: 'vm',
        controller: AccordeonController,
        template: require('./ex-accordeon.html')
    };
}
