/**
 * Composant représentant une pagination
 *
 */
import {IComponentOptions, module} from 'angular';
import PaginationController from './ex-pagination.controller';

export default module('core.components.ex-pagination', []).component('exPagination', PaginationFactory());

function PaginationFactory(): IComponentOptions {
    return {
        bindings: {
            pagination: '=',
            multiOccurrence: "<"
        },
        controller: PaginationController,
        controllerAs: 'vm',
        template: require('./ex-pagination.html')
    };
}
