import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface ISelectionMultipleDataTypeClass {
    new(options?: ISelectionMultipleDataTypeOptions): ISelectionMultipleDataType;
}

export interface ISelectionMultipleDataTypeOptions extends IDataTypeOptions {
}

export interface ISelectionMultipleDataType extends IDataType {
    readonly hideLibelle: boolean
}

export default module('core.services.selection-multiple-data-type', []).factory('SelectionMultipleDataType', SelectionMultipleDataTypeFactory);

/* @ngInject */
function SelectionMultipleDataTypeFactory(DataType: IDataTypeClass): ISelectionMultipleDataTypeClass {
    class SelectionMultipleDataType extends DataType implements ISelectionMultipleDataType {
        readonly hideLibelle: boolean = true;
        readonly template: string = `
            <ex-selection-multiple name-element="{{::vm.col}}"
                                   col="{{::vm.col}}"
                                   data="vm.data"
                                   value="::vm.data[vm.col]"
                                   schema-item="::vm.schemas[vm.col]"
                                   data-type="::vm.dataType"
                                   ex-readonly="true">         
            </ex-selection-multiple>
        `.trim();
    }

    return SelectionMultipleDataType;
}
