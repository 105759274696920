import { module } from 'angular';
import { IFilterLibelle } from '../filters/ex-libelle.filter';

/**
 * Service permettant d'interragir plus facilement avec le titre de la page (visible comme nom d'onglet)
 */
export default module('core.services.app-titre-helper', []).factory('AppTitreHelper', AppTitreHelperFactory);

export interface IAppTitreHelper {
    getNomAppTitre(appName: string): string;
    setTitrePage(currentData: ITitreData): void;
    setSelectionTitre(selectionTitre: ISelectionTitre): void
}

export interface ISelectionTitre {
    champsDisponibles: ISelectionTitreColonne[]
    champsChoisis: ISelectionTitreColonne[]
}

export interface ISelectionTitreColonne {
    champ: string
    titre: string
}

export interface ITitreData {
    titreEcran?: string
    titreContenu?: string
    codeEcran?: string
    titreApp?: string
    cienomabr?: string
    cienom?: string
    [key:string]: string
}

/* @ngInject */
function AppTitreHelperFactory(exLibelleFilter: IFilterLibelle) {
    class AppTitreHelperClass implements IAppTitreHelper {
        private currentData: ITitreData
        private selectionTitre: ISelectionTitre

        public getNomAppTitre(appName: string) {
            switch (appName) {
                case "sofe":
                    return exLibelleFilter("G_LBL_TITRE_APP_SAFIRH2");
                case "portail-employe":
                    return exLibelleFilter("G_LBL_TITRE_APP_PORTAIL_EMPLOYE");
                default:
                    return appName;
            }
        }

        public setTitrePage(currentData: ITitreData) {
            this.currentData = currentData

            if (this.selectionTitre) {
                this.setTitre()
            }
        }

        private setTitre() {
            let titre = ""
            for (const itemTitre of this.selectionTitre.champsChoisis) {
                if (this.currentData[itemTitre.champ]) {
                    if (titre.length) {
                        titre += " - "
                    }
                    titre += this.currentData[itemTitre.champ]
                }
            }

            if (!titre) {
                titre = this.currentData.titreApp
            }

            document.title = titre;
        }

        public setSelectionTitre(selectionTitre: ISelectionTitre) {
            this.selectionTitre = selectionTitre
            localStorage.setItem("uqSelectionTitreNavigateur", JSON.stringify(selectionTitre))
            if (this.currentData) {
                this.setTitre()
            }
        }
    }

    return new AppTitreHelperClass();
}
