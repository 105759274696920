import {IComponentOptions, module} from 'angular';
import BandeauPageController from './ex-bandeau-page.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-bandeau-page', []).component('exBandeauPage', BandeauPageComponent());

function BandeauPageComponent(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MENU.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.HEIGHT,
            Binding.WIDTH.OPTIONAL,
            {
                bandeauDisabled: '<?',
                panneauOpened: '=?',
                hasPanneauPage: '<?',
                activeNavBack: '<?',
                titrePage: '@',
                menu: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran'
        },
        transclude: {
            titre: '?exBandeauPageTitre',
            actionsSpecifiques: '?exBandeauPageActionsSpecifiques'
        },
        controller: BandeauPageController,
        controllerAs: 'vm',
        template: require('./ex-bandeau-page.html')
    };
}
