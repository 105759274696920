import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import * as angular from 'angular';

export interface ILovEntiteExterneDataTypeClass {
    new(options?: ILovEntiteExterneDataTypeOptions): ILovEntiteExterneDataType;
}

export interface ILovEntiteExterneDataTypeOptions extends IDataTypeOptions {
}

export interface ILovEntiteExterneDataType extends IDataType {
}

export default module('core.services.lov-entite-externe-data-type', []).factory('LovEntiteExterneDataType', LovEntiteExterneDataTypeFactory);

/* @ngInject */
function LovEntiteExterneDataTypeFactory(DataType: IDataTypeClass): ILovEntiteExterneDataTypeClass {
    class LovEntiteExterneDataType extends DataType implements ILovEntiteExterneDataType {
        readonly template: string = `{{vm.col | exLov : vm.data : vm.dataType}}`;

        constructor(options: ILovEntiteExterneDataTypeOptions) {
            super(angular.extend({
                alignment: 'left'
            }, options));
        }
    }

    return LovEntiteExterneDataType;
}
