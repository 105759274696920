import ErreurChargementAppController from './ex-erreur-chargement-app.controller';
import IStateProvider = angular.ui.IStateProvider;
import { module } from 'angular';

export const erreurChargementAppRoute = {
    NAME: 'unsecure.erreur-chargement-app',
}

export default module('static.erreur-chargement-app', []).config(config);

/* @ngInject */
function config($stateProvider: IStateProvider) {
    $stateProvider.state(erreurChargementAppRoute.NAME, {
        params: {
            route: erreurChargementAppRoute
        },
        views: {
            content: {
                template: require('./ex-erreur-chargement-app.html'),
                controllerAs: 'vm',
                controller: ErreurChargementAppController
            }
        }
    });
}

