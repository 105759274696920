import IDialogOptions = angular.material.IDialogOptions;
import IInjectorService = angular.auto.IInjectorService;
import { equals, IAugmentedJQuery, IComponentController, IFormController, IRootScopeService, IScope } from 'angular';
import { IMenuMoreClass } from '../../services/menu/menu-more.service';
import AxesAffichage, { IAxeAffichage } from '../../classes/axe-affichage.class';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMenuItemForageClass } from '../../services/menu/menu-item-forage.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IMenu, IMenuClass } from '../../services/menu/menu.service';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { ISelecteur, ISelecteurClass } from '../../services/selecteur.service';
import { IDialog } from '../../services/dialog.service';
import { IMenuDividerClass } from '../../services/menu/menu-divider.service';
import { IFiltreClass } from '../../services/filtre.service';
import { IOperateurService } from '../../services/operateur.service';
import { IDialogFiltreResultats } from '../../dialogs/dialog-filtre/dialog-filtre.controller';
import { IApiError } from '../../interfaces/api-error.interface';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IMenuItemImportationExcelClass } from '../../services/menu/menu-item-importation-excel.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IMenuItemEcranClass } from '../../services/menu/menu-item-ecran.service';
import { IRoute } from '../../interfaces/route.interface';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IAvisEmployesResource } from '../../resources/avis-employes.resource';
import { IStateService } from 'angular-ui-router';
import { IColonneTri, IColonneTriClass } from '../../services/colonne-tri.service';
import isMobile from "../../constants/mobile.constant";
import { IEcranDetailsResourcesEntite } from "../../resources/ecran-details.resource";
import { IMenuItemForageExterneClass } from "../../services/menu/menu-item-forage-externe.service";
import { IApiConfig } from "../../interfaces/api-config.interface";

interface IComposantRechercheMenuActions extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence;
    columnFiltreRecherche: string;
    cleLblRechercheGlobal: string;
    rechercheInput: string;
    menu: IMenu;
    menuItemEditionRapide: IMenuItem;
    menuItemExportation: IMenuItem;
    menuMore: IMenu;
    menuAxesAffichage: IMenuItem[];
    multiOccurrenceFormCtrl: IFormController;
    scopeColonnesHidden: any;
    isBoutonNouveauDisabled(): boolean;
    saveEditionRapide(event: JQueryEventObject): void;
    hasChangementsNonSauvegardes(): boolean;
}

/* @ngInject */
export default function RechercheMenuActionsController(AvisEmployesResource: IAvisEmployesResource,
    Selecteur: ISelecteurClass,
    ColonneTri: IColonneTriClass,
    DialogSelecteur: IDialog,
    DialogFiltre: IDialog,
    DialogEtatsAjout: IDialog,
    DialogConfirm: IDialog,
    DialogCompteurLignes: IDialog,
    DialogStatus: IDialogAllStatus,
    Menu: IMenuClass,
    MenuMore: IMenuMoreClass,
    MenuItem: IMenuItemClass,
    MenuItemEcran: IMenuItemEcranClass,
    MenuItemForage: IMenuItemForageClass,
    MenuItemImportationExcel: IMenuItemImportationExcelClass,
    MenuDivider: IMenuDividerClass,
    Filtre: IFiltreClass,
    Operateur: IOperateurService,
    $element: IAugmentedJQuery,
    $injector: IInjectorService,
    $scope: IScope,
    notificationHandler: INotificationHandler,
    parametresSecurite: IParametresSecuriteService,
    dataLinker: IDataLinker,
    exLibelleFilter: IFilterLibelle,
    DialogInfo: IDialog,
    appName: string,
    $state: IStateService,
    $rootScope: IRootScopeService,
    ecranDetails: IEcranDetailsResourcesEntite, ApiConfig: IApiConfig,
    MenuItemForageExterne: IMenuItemForageExterneClass) {
    const vm: IComposantRechercheMenuActions = this;

    vm.$onInit = $onInit;
    vm.saveEditionRapide = saveEditionRapide;
    vm.isBoutonNouveauDisabled = isBoutonNouveauDisabled;
    vm.hasChangementsNonSauvegardes = hasChangementsNonSauvegardes;
    function $onInit() {
        vm.columnFiltreRecherche = Filtre.CLE_RECHERCHE_GLOBALE;

        vm.scopeColonnesHidden = $scope.$new();
        vm.scopeColonnesHidden.data = {};
        dataLinker.link($element, vm.scopeColonnesHidden.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        $scope.$watch('::vm.multiOccurrence', () => {
            if (vm.multiOccurrence) {
                vm.multiOccurrence.ready.then(() => {
                    if (vm.multiOccurrence.fonctions.editionRapide) {
                        vm.menuItemEditionRapide = new MenuItem(
                            'G_LBL_BTN_EDITION_RAPIDE',
                            () => toggleEditionRapide(),
                            {
                                icon: () => vm.multiOccurrence.editionRapideActive ? 'grid_off' : 'grid_on',
                                disabled: () => !vm.multiOccurrence.isModifiableTous() && !vm.multiOccurrence.isModifiablePartiel(),
                                directionTooltip: 'bottom'
                            }
                        );
                    }

                    let menuItems = [];
                    if (vm.multiOccurrence.fonctions.selectionnerDesColonnes) {
                        menuItems.push(new MenuItem('G_LBL_SEL_DES_COLLONES', (event: MouseEvent) => showDialogColonne(event), { icon: 'view_column' }));
                    }

                    if (vm.multiOccurrence.fonctions.selectionnerTri && !isMobile) {
                        menuItems.push(new MenuItem('G_LBL_SEL_TRI', (event: MouseEvent) => showDialogTri(event), { icon: 'import_export' }));
                    }

                    if (vm.multiOccurrence.fonctions.filtrer) {
                        menuItems.push(new MenuItem(
                            'G_LBL_BAR_FILTRE',
                            (event: MouseEvent) => showDialogFiltre(event),
                            { icon: 'filter_list' }));
                    }

                    if (vm.multiOccurrence.fonctions.enregistrerUnEtat) {
                        menuItems.push(new MenuItem('G_LBL_BAR_ENREGISTRER_ETAT', (event: MouseEvent) => showDialogEtatsAjout(event), { icon: 'bookmark_border' }));
                    }

                    if (vm.multiOccurrence.fonctions.reinitialiser && !vm.multiOccurrence.fonctions.customCriteresSuggeres) {
                        menuItems.push(new MenuItem(
                            'G_LBL_BAR_REINITIALISER',
                            (ev: MouseEvent) => {
                                DialogConfirm.show({
                                    targetEvent: ev,
                                    multiple: true,
                                    locals: {
                                        lblTitre: 'G_LBL_BAR_REINITIALISER',
                                        lblDescription: 'G_LBL_MSL_MOD_INIT_DESC',
                                        lblConfirm: 'G_LBL_BTN_APPLIQUER',
                                        lblMessage: 'G_MSG_CHANG_ETAT',
                                        icon: 'settings_backup_restore',
                                        ecranContext: vm.ecranContextCtrl,
                                        confirmAction() {
                                            return vm.multiOccurrence.reinitialiser().then(() => {
                                                vm.columnFiltreRecherche = Filtre.CLE_RECHERCHE_GLOBALE;
                                                vm.rechercheInput = '';
                                            });
                                        }
                                    }
                                });
                            },
                            { icon: 'settings_backup_restore' }));
                    }
                    if (vm?.multiOccurrence?.fonctions?.reinitialiserPastillefiltre) {
                        menuItems.push(new MenuItem(
                            'G_LBL_BAR_REINITIALISER',
                            (ev: MouseEvent) => {
                                vm.multiOccurrence.reinitialiserPastillefiltre()
                            },
                            { icon: 'settings_backup_restore' }));
                    }

                    if (menuItems.length && ((vm.multiOccurrence.fonctions.pagination && vm.multiOccurrence.etatSelected.pagination.nombreEnregistrementInMenuMore && !isMobile) || vm.multiOccurrence.actionsMore || hasFonctionsTransversales())) {
                        menuItems.push(new MenuDivider());
                    }

                    if (vm.multiOccurrence.fonctions.pagination && vm.multiOccurrence.etatSelected.pagination.nombreEnregistrementInMenuMore && !isMobile) {
                        const menuItem = new MenuItem(
                            'G_LBL_NBRE_ENREG',
                            () => DialogCompteurLignes.show({
                                locals: {
                                    lblTitre: 'G_LBL_NBRE_ENREG',
                                    multiOccurrence: vm.multiOccurrence
                                }
                            }),
                            {
                                iconButton: false,
                                icon: 'info_outline'
                            }
                        );

                        menuItems.push(menuItem);
                    }


                    if (vm.multiOccurrence.actionsMore) {
                        menuItems.push(...vm.multiOccurrence.actionsMore.listeMenuItem);
                    }

                    if (hasFonctionsTransversales()) {
                        if (vm.multiOccurrence.schema && vm.multiOccurrence.schema.demcleint && appName === "sofe" && vm.multiOccurrence.fonctions.avisEmploye) {
                            menuItems.push(new MenuItem(
                                'G_LBL_FCT_TRANS_AVIS_COMM_EMP',
                                (event: MouseEvent) => openEcranAvis(event),
                                {
                                    iconButton: false,
                                    icon: 'contact_mail',
                                    disabled: () => !vm.multiOccurrence || !vm.multiOccurrence.dataList || !vm.multiOccurrence.dataList.length
                                }
                            ));
                        }

                        if (vm.multiOccurrence.fonctions.exportation) {
                            const bouton = new MenuItem(
                                'G_LBL_BAR_EXPORT_EXCEL',
                                downloadExcel,
                                {
                                    icon: 'file_download',
                                    iconButton: false
                                }
                            );

                            menuItems.push(bouton);
                        }

                        if (vm.multiOccurrence.fonctions.importation && hasAccesNouveau()) {
                            menuItems.push(new MenuItemImportationExcel(vm.ecranContextCtrl, { disabled: () => vm.isBoutonNouveauDisabled() }, vm.multiOccurrence));
                        }
                    }

                    if (ecranDetails.urlclient.length || ecranDetails.documclient.length) {
                        menuItems.push(new MenuDivider())
                        for (const url of ecranDetails.urlclient) {
                            menuItems.push(new MenuItemForageExterne(url.eucdsc, url.eucurl, { iconButton: false }))
                        }
                        for (const docum of ecranDetails.documclient) {
                            menuItems.push(new MenuItemForageExterne(docum.edcdsc, `${ApiConfig.ROOT}/gsecr-docum-client/${docum.edccleint}`, { iconButton: false }))
                        }
                    }

                    vm.menuMore = new MenuMore({}, new Menu(menuItems));
                });

                if (vm.multiOccurrence.fonctions.axesAffichageVisible && vm.multiOccurrence.axesAffichage) {
                    vm.menuAxesAffichage = [];

                    for (const axe of vm.multiOccurrence.axesAffichage.axes) {
                        vm.menuAxesAffichage.push(new MenuItem(axe.lblNom, () => toggleAxeAffichage(axe), {
                            icon: axe.icon,
                            iconButton: true,
                            directionTooltip: "bottom",
                            hidden: () => vm.multiOccurrence.axesAffichage.axeActive.axeNom === axe.axeNom
                        }));
                    }
                }
            }
        });
    }

    function isBoutonNouveauDisabled() {
        return !vm.multiOccurrence.initialized || !hasAccesNouveau() || !vm.multiOccurrence.isEveryChampsRequisModifiables(false) || (!vm.multiOccurrence.fonctions.editionStandard && !vm.multiOccurrence.editionRapideActive);
    }

    function openEcranAvis(event: MouseEvent) {
        const gs0069Route: IRoute = $injector.get('gs0069Route');
        const menuItemEcran = new MenuItemEcran(
            'G_LBL_FCT_TRANS_AVIS_COMM_EMP',
            gs0069Route.NAME,
            (data: any) => ({
                xyzcleint: data.xyzcleint
            })
        );

        const ids = vm.multiOccurrence.getParentIds();

        if (ids.length) {
            ids.pop();
        }

        const params: any = {
            srccod: vm.multiOccurrence.getNomSourceDetails({ baseOnly: true }),
            ...vm.multiOccurrence.stateParams,
            ...vm.multiOccurrence.getDataListQueryParams(),
            ids: ids.join(','),
            ...parametresSecurite(vm.multiOccurrence.stateParams)
        };

        if (params.limit !== 'undefined') {
            delete params.limit;
        }

        return AvisEmployesResource.get(params).$promise.then((result: any) => {
            menuItemEcran.action(event, result, vm.multiOccurrence);
        }).catch((error: any) => {
            if (error !== DialogStatus.FERMER) {
                notificationHandler.erreur({
                    error,
                    lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                    lblMessage: 'G_MSG_CHARG_DONNEES',
                    confirmAction: () => openEcranAvis(event)
                });
            }
        });
    }

    function toggleEditionRapide() {
        vm.multiOccurrence.toggleEditionRapide();
        $rootScope.$broadcast('exGridReajusterLargeurColonne', { force: true });
    }

    function toggleAxeAffichage(axeAffichage: IAxeAffichage) {
        const axeAnterieur = vm.multiOccurrence.axesAffichage.axeActive
        vm.multiOccurrence.activeRowCleint = undefined
        vm.multiOccurrence.closeAllSelectedRow();
        vm.multiOccurrence.axesAffichage.changeAffichage(axeAffichage.axeNom);
        vm.multiOccurrence.etatSelected.axeAffichageActif = axeAffichage.axeNom;
        if (vm.multiOccurrence.etatSelected.axeAffichageActif == AxesAffichage.AXES_AFFICHAGE.LIST) {
            setTimeout(() => {
                const filtre = vm?.multiOccurrence?.etatSelected?.filtres
                const date = vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.date
                if (filtre && Array.isArray(filtre) && filtre.length > 0 && filtre[0]?.colonne) {
                    const colonne = (filtre[0].colonne).toString();
                    $rootScope.$broadcast('axe-calendar.show.grid', { colonne: colonne, date: date });
                }
            });
        } else {
            //si on change de mode view list au calendar on met l'axe month par defaut
            if (axeAnterieur.axeNom == AxesAffichage.AXES_AFFICHAGE.LIST) {
                vm.multiOccurrence.axesAffichage.axeActive.options.viewMode = "dayGridMonth"
            }
        }
        vm.multiOccurrence.saveEtatTemporaire();
    }

    function showDialogColonne(ev: MouseEvent) {
        const selecteur = new Selecteur(
            vm.multiOccurrence.etatSelected.colonnesDisponibles,
            vm.multiOccurrence.etatSelected.colonnesVisibles,
            vm.multiOccurrence.colonnesEnteteTitre
        );

        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            multiple: true,
            locals: {
                selecteur,
                libelles: vm.multiOccurrence.libelles,
                multiOccurrence: vm.multiOccurrence,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSelecteur.show(dialogOptions).then((selecteur: ISelecteur) => {
            const colonnesVisiblesUpdate = !equals(selecteur.colonneRight, vm.multiOccurrence.etatSelected.colonnesVisibles);

            vm.multiOccurrence.etatSelected.colonnesDisponibles = selecteur.colonneLeft;
            vm.multiOccurrence.etatSelected.colonnesVisibles = selecteur.colonneRight;

            if (colonnesVisiblesUpdate) {
                vm.multiOccurrence.emit('colonnesVisiblesUpdate');
            }

            vm.multiOccurrence.defineEtatColonnesFiltrables(vm.multiOccurrence.etatSelected);
        });
    }

    function showDialogTri(ev: MouseEvent) {
        const colonnesDispo = Object.keys(vm.multiOccurrence.colonnesParametres).filter(col => !vm.multiOccurrence.etatSelected.tri.liste.find(colTri => colTri.nom === col)).sort((colA, colB) => getSortValue(colA) - getSortValue(colB));

        const selecteur = new Selecteur(
            colonnesDispo.map(col => new ColonneTri(col, '/asc')),
            vm.multiOccurrence.etatSelected.tri.liste.map(col => col.clone()),
            vm.multiOccurrence.colonnesEnteteTitre
        );
        const menusColonneDroite = [
            new MenuItem((ev: MouseEvent, data: IColonneTri) => {
                ev.preventDefault();
                data.switchTri(data.isAsc() ? '/desc' : '/asc');
            }, {
                icon: 'arrow_downward',
                iconButton: true,
                class: (data: IColonneTri) => data.isAsc() ? 'ex-recherche-menu-actions-tri-icon ex-recherche-menu-actions-tri-icon--asc' : 'ex-recherche-menu-actions-tri-icon'
            })
        ];

        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            multiple: true,
            locals: {
                selecteur,
                menusColonneDroite,
                icon: 'import_export',
                lblTitre: 'G_LBL_MOD_SELECT_TRI',
                lblSelecteurLeft: 'G_LBL_MOD_SELECT_TRI_COLS_DISPOS',
                lblSelecteurRight: 'G_LBL_MOD_SELECT_TRI_COLS_TRI',
                lblDescription: 'G_LBL_MOD_SELECT_TRI_DESC',
                libelles: vm.multiOccurrence.libelles,
                multiOccurrence: vm.multiOccurrence,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSelecteur.show(dialogOptions).then(function (selecteur: ISelecteur) {
            vm.multiOccurrence.etatSelected.tri.updateTri(selecteur.colonneRight);
        });
    }

    function getSortValue(col: string) {
        const VALUE_SORT_COLS_FIXES_GAUCHE = 0;
        const VALUE_SORT_COLS_FIXES_DROITE = vm.multiOccurrence.etatSelected.colonnesVisibles.length + 2;

        if (vm.multiOccurrence.colonnesFixesGauche.indexOf(col) === -1 && vm.multiOccurrence.colonnesFixesDroite.indexOf(col) === -1) {
            return vm.multiOccurrence.etatSelected.colonnesVisibles.indexOf(col) === -1 ?
                vm.multiOccurrence.etatSelected.colonnesVisibles.length + 3 :
                vm.multiOccurrence.etatSelected.colonnesVisibles.indexOf(col) + 1;
        } else if (vm.multiOccurrence.colonnesFixesGauche.indexOf(col) !== -1) {
            return VALUE_SORT_COLS_FIXES_GAUCHE;
        } else {
            return VALUE_SORT_COLS_FIXES_DROITE;
        }
    }

    function showDialogFiltre(ev: MouseEvent) {
        const selecteur = new Selecteur(
            vm.multiOccurrence.etatSelected.colonnesDisponibles,
            vm.multiOccurrence.etatSelected.colonnesFiltrables,
            vm.multiOccurrence.colonnesEnteteTitre
        );

        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            multiple: true,
            locals: {
                colonne: Filtre.CLE_RECHERCHE_GLOBALE,
                operateur: Operateur.EGALE,
                selecteur,
                multiOccurrence: vm.multiOccurrence,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogFiltre.show(dialogOptions).then((result: IDialogFiltreResultats) => {
            const nouveauFiltre = new Filtre({
                colonne: result.colonne,
                operateur: result.operateur,
                data: result.data,
                valeur: result.valeurs[0],
                valeur2: result.valeurs[1]
            });

            vm.multiOccurrence.etatSelected.addFiltre(nouveauFiltre);
        });
    }

    function showDialogEtatsAjout(ev: MouseEvent) {
        const nouvelEtat = vm.multiOccurrence.etatSelected.clone();

        if (nouvelEtat?.criteresMultiplesListe) {
            nouvelEtat.pmtcleint=vm.multiOccurrence.pmtcleint
        }
        // On enlève les données qui ne doivent pas être reproduites
        if (vm.multiOccurrence.etatSelectedOriginal.equal(vm.multiOccurrence.etatDefault) || vm.multiOccurrence.getEtatPredefiniById(vm.multiOccurrence.etatSelected.id)) {
            delete nouvelEtat.id;
            delete nouvelEtat.nom;
        }

        try {
            //On enlève les champs à non sauvegarder de criteres suggeres
            if (vm.multiOccurrence.champsNonSauvergarderEtat && Array.isArray(vm.multiOccurrence.champsNonSauvergarderEtat) && vm.multiOccurrence.champsNonSauvergarderEtat.length > 0) {
                vm.multiOccurrence.champsNonSauvergarderEtat.forEach((el) => {
                    Object.keys(nouvelEtat.criteresSuggeresData).forEach((item: string) => {
                        if (item.includes(el)) {
                            delete nouvelEtat.criteresSuggeresData[item];
                        }
                    })
                })
            }
        } catch (error) {
        }

        const dialogOptions: IDialogOptions = {
            targetEvent: ev,
            multiple: true,
            locals: {
                etat: nouvelEtat,
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    nouvelEtat.predefini = false;
                    return vm.multiOccurrence.saveEtat(nouvelEtat);
                }
            }
        };

        DialogEtatsAjout.show(dialogOptions);
    }

    function hasAccesNouveau() {
        if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
            return vm.multiOccurrence.fonctions.nouveau(vm.scopeColonnesHidden.data);
        } else {
            return vm.multiOccurrence.fonctions.nouveau;
        }
    }

    function downloadExcel() {
        DialogInfo.show({
            locals: {
                hideUsingCallbackOnly: true,
                lblTitre: 'G_LBL_EXPORT_EXCEL_EN_COURS_TITRE',
                lblDescription: 'G_LBL_EXPORT_EXCEL_EN_COURS'
            }
        });

        vm.multiOccurrence.downloadDataList(vm.scopeColonnesHidden.data)
            .catch(afficherDownloadErreur)
            .finally(() => {
                DialogInfo.hide();
                $scope.$applyAsync(() => {
                    DialogInfo.hide();
                });
            });
    }

    function afficherDownloadErreur(error: IApiError) {
        const dialogOptions: IDialogOptions = {
            locals: {
                error,
                lblTitre: 'G_LBL_BAR_EXPORT_EXCEL',
                lblMessage: 'G_MSG_CHARG_DONNEES',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    return vm.multiOccurrence.downloadDataList(vm.scopeColonnesHidden.data);
                }
            }
        };

        DialogConfirm.show(dialogOptions);
    }

    //delay, rester en attente du processus prb_change_item
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    const executeWait = async () => {
        await delay(500);
    }
    async function wait() {
        //mettre en attente ChangeItemResource.save doit finaliser
        await executeWait().then(() => { });
    }

    function saveEditionRapide(event?: JQueryEventObject) {
        if (event) {
            event.preventDefault();
        }
        if (vm.isSaving) return;

        vm.multiOccurrenceFormCtrl.$commitViewValue();

        const watchFormCtrl = $scope.$watch('vm.multiOccurrenceFormCtrl.$pending', async (value: any) => {
            if (!value) {
                watchFormCtrl();

                if (vm.multiOccurrenceFormCtrl.$valid) {
                    vm.isSaving = true;
                    //quand tout est correct on apelle au delay
                    await wait();
                    return vm.multiOccurrence.saveAll().then(() => {
                        notificationHandler.succes();

                        if (vm.multiOccurrence.successActionSauvegarde) {
                            vm.multiOccurrence.successActionSauvegarde();
                        }

                        //On notifie sur le multiOccurrence principal que la liste a été mise à jour.
                        vm.multiOccurrence.emit('dataListUpdate');

                        //declancheur pour l'infos monetaires
                        $rootScope.$broadcast('monoOccurrence.infosMonetaires.updateDataList');

                        //declancheur pour l'infos monetaires
                        $rootScope.$broadcast('monoOccurrence.infosMonetaires.updateDataList');

                        return vm.multiOccurrence.fetchValeursDefaut();
                    }).catch((error: IApiError) => {
                        let showConfirmAction;

                        if (error.data && error.data.details && error.data.details.row !== undefined) {
                            const enregistrementEnErreur = vm.multiOccurrence.dataList[error.data.details.row];

                            if (enregistrementEnErreur) {
                                enregistrementEnErreur.$messagesErreur = [getMessage(error)];
                            }

                            showConfirmAction = false;
                        }

                        afficherValidationErreur(error, showConfirmAction);
                    }).finally(() => {
                        vm.isSaving = false;
                    });
                }
            }
        });
    }

    function afficherValidationErreur(error: IApiError, showConfirmAction?: boolean) {
        const message = getMessage(error);

        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: message,
            confirmAction: showConfirmAction ? saveEditionRapide : null
        });
    }

    function getMessage(error: IApiError) {
        const customMessage = error.data && error.data.code && (error.data.code.startsWith('SOFE-') || error.data.code === 'ERREUR_DE_VALIDATION')

        return customMessage ? error.data.message : exLibelleFilter('G_MSG_SAUV_ENR', vm.multiOccurrence.libelles);
    }

    function hasFonctionsTransversales() {
        return Boolean(vm.multiOccurrence && (
            vm.multiOccurrence.fonctions.exportation ||
            vm.multiOccurrence.fonctions.importation ||
            (vm.multiOccurrence.schema && vm.multiOccurrence.schema.demcleint && vm.multiOccurrence.fonctions.avisEmploye && $injector.has('gs0069Route'))
        ));
    }

    function hasChangementsNonSauvegardes(): boolean {
        if (vm.multiOccurrence.editionRapideActive) {
            let hasChangements = false;
            vm.multiOccurrence?.dataList?.forEach((line: any) => {
                if (line?.$editionRapideCtrl) {
                    hasChangements = hasChangements || line.$editionRapideCtrl.$dirty;
                }
                if (line?.$deleted) {
                    hasChangements = hasChangements || line?.$deleted;
                }
            });

            return hasChangements;
        } else {
            return false;
        }
    }
}
