import {module} from 'angular';
import {DataTypeAlignment, IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IStatutDataTypeClass {
    new(options: IStatutDataTypeOptions): IStatutDataType;
}

export interface IStatutDataTypeOptions extends IDataTypeOptions {
}

export interface IStatutDataType extends IDataType {
}
export default module('core.services.statut-data-type', []).factory('StatutDataType', StatutDataTypeFactory);

/* @ngInject */
function StatutDataTypeFactory(DataType: IDataTypeClass): IStatutDataTypeClass {
    class StatutDataType extends DataType implements IStatutDataType {
        readonly template: string = `<div class="{{vm.dataType.params.statut[vm.data[vm.col]].class}}"><md-icon>{{vm.dataType.params.statut[vm.data[vm.col]].icon}}</md-icon></div>`;
        readonly alignment: DataTypeAlignment = 'center';
    }

    return StatutDataType;
}
