import {module, IPromise} from 'angular';
import {IMenuDivider} from './menu-divider.service';
import {IMenuItem} from './menu-item.service';
import {IMenuSection} from './menu-section.service';
import {IMenuMessage} from './menu-message.service';
import {IMenuErreur} from './menu-erreur.service';
import {ISelectionOptions} from '../../resources/actions-selection.resource';
import {IMenuItemFactoryFactory} from './menu-item-factory.service';

export type MenuElement = IMenuItem | IMenuDivider | IMenu | IMenuSection | IMenuMessage | IMenuErreur | IMenuItemFactoryFactory;

export interface IMenuClass {
    new(lblTitre: string|Function, listeMenuItem: Array<MenuElement>, option?: IMenuOptions): IMenu;
    new(listeMenuItem: Array<MenuElement>, option?: IMenuOptions): IMenu;
}

export interface IMenu {
    readonly listeMenuItem?: Array<MenuElement>;
    width?: number;
    icon?: string|Function;
    lblTitre?: string|Function;
    lblTooltip?: string;
    disabled?: boolean|Function;
    classItem?: string;
    onOpen?: () => (void|Promise<void>|IPromise<any>);
    positionFirst: boolean;
    disabledIcon: boolean;
    selection: ISelectionOptions;
    blocImportation?: string;
    get(index: number): MenuElement;
    getIndex(menuItem: MenuElement): number;
    remove(index: number): void
    setTitle(name: string): void
    setBlocImportation(bloc: string): void;
    add(menuItem: MenuElement): void;
    unshift(menuItem: MenuElement): void;
    edit(menuItem: MenuElement, index: number): void;
    replace(menuItems: Array<MenuElement>): void;
}

export interface IMenuOptions {
    icon?: string | Function;
    iconFirst?: boolean;
    positionFirst?: boolean;
    lblTooltip?: string;
    tooltipDirection?: string;
    onOpen?: () => (void|Promise<void>|IPromise<any>);
    disabled?: boolean | Function;
    classItem?: string;
    disabledIcon?: boolean;
    selection?: ISelectionOptions;
}

export default module('core.services.menu', []).factory('Menu', MenuFactory);

/* @ngInject */
function MenuFactory() {

    class Menu implements IMenu {
        readonly listeMenuItem: Array<MenuElement>;
        icon: string = 'more_vert';
        iconFirst: boolean;
        lblTitre: string|Function = '';
        lblTooltip: string = '';
        tooltipDirection: string = 'top';
        classItem: string = '';
        positionFirst: boolean = false;
        width: number = 3;
        disabled: boolean = false;
        disabledIcon: boolean = false;
        selection: ISelectionOptions;
        blocImportation?: string = '';
        onOpen: () => (void|Promise<void>|IPromise<any>);

        constructor() {
            if (typeof arguments[0] === 'string' || arguments.length === 3) {
                this.lblTitre = arguments[0];
                this.listeMenuItem = arguments[1];
                this.blocImportation = arguments[0];
                if (arguments[2]) {
                    this.disabled = arguments[2].disabled;
                    this.disabledIcon = arguments[2].disabledIcon;
                    this.onOpen = arguments[2].onOpen;
                    this.icon = arguments[2].icon || this.icon;
                    this.iconFirst = arguments[2].iconFirst;
                    this.lblTooltip = arguments[2].lblTooltip || this.lblTitre || this.lblTooltip;
                    this.selection = arguments[2].selection;
                    this.positionFirst = arguments[2].positionFirst;
                    this.classItem = arguments[2].classItem;
                    this.tooltipDirection = arguments[2].tooltipDirection || this.tooltipDirection;
                }
            } else {
                this.listeMenuItem = arguments[0];
                if (arguments[1]) {
                    this.disabled = arguments[1].disabled;
                    this.disabledIcon = arguments[1].disabledIcon;
                    this.onOpen = arguments[1].onOpen;
                    this.icon = arguments[1].icon || this.icon;
                    this.iconFirst = arguments[1].iconFirst;
                    this.positionFirst = arguments[1].positionFirst;
                    this.classItem = arguments[1].classItem;
                    this.lblTooltip = arguments[1].lblTooltip || this.lblTitre || this.lblTooltip;
                    this.selection = arguments[1].selection;
                    this.tooltipDirection = arguments[1].tooltipDirection || this.tooltipDirection;
                }
            }
        }

        public add(menuItem: MenuElement): void {
            this.listeMenuItem.push(menuItem);
        }

        public unshift(menuItem: MenuElement) {
            this.listeMenuItem.unshift(menuItem);
        }

        public setTitle(name: string): void {
            this.lblTitre = name;
            this.lblTooltip = name;
        }

        public edit(menuItem: MenuElement, index: number): void {
            this.listeMenuItem[index] = menuItem;
        }

        public get(index: number): MenuElement {
            return this.listeMenuItem[index];
        }

        public getIndex(menuItem: MenuElement): number {
            return this.listeMenuItem.indexOf(menuItem);
        }

        public remove(index: number): void {
            this.listeMenuItem.splice(index, 1);
        }

        public replace(menuItems: Array<MenuElement>): void {
            this.listeMenuItem.splice(0, this.listeMenuItem.length);
            this.listeMenuItem.push(...menuItems);
        }
        public setBlocImportation(bloc:string):void{
            this.blocImportation=bloc;
        }
    }
    return Menu;
}
