import {module} from 'angular';

export interface IMenuErreurClass {
    new (lblMessage: string): IMenuErreur;
}

export interface IMenuErreur {
    readonly lblMessage: string;
}


export default module('core.services.menu-erreur', []).factory('MenuErreur', MenuErreurFactory);

/* @ngInject */
function MenuErreurFactory() {
    class MenuErreur implements IMenuErreur {
        readonly lblMessage: string;

        constructor(lblMessage: string) {
            this.lblMessage = lblMessage;
        }
    }

    return MenuErreur;
}
