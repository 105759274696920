import { module } from 'angular';
import DialogSelecteurTitreController from './dialog-selecteur-titre.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('core.dialogs.dialog-selecteur-titre', ['core.services.dialog']).factory('DialogSelecteurTitre', DialogSelecteurTitreFactory);

/* @ngInject */
function DialogSelecteurTitreFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogSelecteurTitreController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-selecteur-titre.html')
    });
}
