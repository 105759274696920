import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputRechercheController from './ex-input-recherche.controller';

export default module('core.components.ex-input-recherche', []).component('exInputRecherche', InputRechercheFactory());

function InputRechercheFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            Binding.MULTI_OCCURRENCE.OPTIONAL,
            {
                keydownAction: '&?',
                blurAction: '&?',
                localSearch: '<?',
                localSearchFormCtrl: '<?',
                localSearchOptions: '<?',
                effacerRecherche: '&?'
            }
        ),
        require: {
            formCtrl: '?^^form'
        },
        controllerAs: 'vm',
        controller: InputRechercheController,
        template: require('./ex-input-recherche.html')
    };
}
