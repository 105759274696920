import {module} from 'angular';
import DialogCycleTransactionnelInformationsController from './dialog-cycle-transactionnel-informations.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-cycle-transactionnel-informations', [
    'core.services.dialog'
]).factory('DialogCycleTransactionnelInformations', DialogCycleTransactionnelInformationsFactory);

/* @ngInject */
function DialogCycleTransactionnelInformationsFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCycleTransactionnelInformationsController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-cycle-transactionnel-informations.html'),
        locals: {
            icon: 'info_outline',
            lblTitre: 'G_LBL_INFOS_CYCLE_TRANSACTIONNNEL',
            lblCancel: 'G_LBL_BTN_FERMER'
        }
    });
}
