/**
 * Composant représentant un libellé
 */
import {IComponentOptions, module} from 'angular';
import LibelleController from './ex-libelle.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-libelle', []).component('exLibelle', LibelleFactory());

function LibelleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                libelle: '@'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controller: LibelleController,
        controllerAs: 'vm',
        template: require('./ex-libelle.html')
    };
}
