import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputLovBoutonRadioController from './ex-input-lov-bouton-radio.controller';

export default module('core.components.ex-input-lov-bouton-radio', []).component('exInputLovBoutonRadio', InputLovBoutonRadioFactory());

function InputLovBoutonRadioFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.MULTI_OCCURRENCE,
            Binding.COL.OPTIONAL,
            Binding.DATA,
            Binding.VALUE,
            Binding.LIBELLES.OPTIONAL,
            Binding.SCHEMA_ITEM,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            {
                required: '<?ngRequired',
                resourceParams: '<?',
                srccodref: '@?',
                direction: '@?',
                titre: '@?lblTitre',
                raised: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controllerAs: 'vm',
        controller: InputLovBoutonRadioController,
        template: require('./ex-input-lov-bouton-radio.html')
    };
}
