import { IMenu } from "../services/menu/menu.service";
import { IMultiOccurrence, INavigatePageFunction } from "../services/multi-occurrence.service";

export interface IAxesAffichageOptions {
    nomAxeActif?: string;
    axes?: Array<IAxeAffichage>;
    actions?: IAxesAffichageActions;
    menus?: IAxesAffichageMenus;
}

export interface IAxeAffichageCalendrierOptions {
    description: Array<string>;
    colonneDateDebut: string;
    colonneDateFin: string;
    colonneHeureDebut?: string;
    colonneHeureFin?: string;
    navigatePage?: string | INavigatePageFunction;
    navigateParams?: (rowData: any, multiOccurrence: IMultiOccurrence) => any;
    setDate?: boolean;
    defaultDate?: string;
    viewMode?: string;
    showWeek?: boolean;
    showMonth?: boolean;
    showDay?: boolean;
    hideHours?: boolean;
}

interface IAxesAffichageActions {
    [key: string]: Function;
}

interface IAxesAffichageMenus {
    [axe: string]: IAxesAffichageMenu;
}

interface IAxesAffichageMenu {
    menu: IMenu;
    multiple?: boolean;
}

export interface IAxesAffichageDefaut {
    LIST: string;
    CALENDAR: string;
}

export interface IAxeAffichage {
    icon: string;
    lblNom: string;
    axeNom: string;
    noAnimation?: boolean;
    forageParams?: any;
    logiqueInactif?: (data?: any) => boolean;
    disabled?: (data?: any) => boolean;
    options?: IAxeAffichageCalendrierOptions;
}

export default class AxesAffichage {
    static readonly AXES_AFFICHAGE = {
        LIST: 'list',
        CALENDAR: 'calendar'
    };

    readonly axes: Array<IAxeAffichage> = [{
        icon: 'view_list',
        axeNom: AxesAffichage.AXES_AFFICHAGE.LIST,
        lblNom: 'G_LBL_LIST'
    }, {
        icon: 'calendar_today',
        axeNom: AxesAffichage.AXES_AFFICHAGE.CALENDAR,
        lblNom: 'G_LBL_CALENDAR'
    }];
    readonly actions: IAxesAffichageActions;
    readonly menus: IAxesAffichageMenus;
    axeActive: IAxeAffichage;

    constructor(options: IAxesAffichageOptions = {}) {
        if (options.axes) {
            this.axes = options.axes;
        }
        this.axeActive = this.getAffichage(options.nomAxeActif);
        this.actions = options.actions || {};
        this.menus = options.menus;

        //On applique l'axe de défaut
        if (!this.axeActive) {
            this.axeActive = this.axes[0];
        }
    }

    private getAffichage(nomAxe: string) {
        return this.axes.find(ele => nomAxe === ele.axeNom);
    }

    public changeAffichage(nomAffichage: string) {
        const axeActiveNew = this.getAffichage(nomAffichage);
        if (axeActiveNew) {
            this.axeActive = axeActiveNew;
        } else {
            throw new Error('Axe invalide');
        }
    }

    public clone() {
        return new AxesAffichage(this);
    }
}
