import * as angular from 'angular';
import { IAugmentedJQuery, IComponentController, IPromise, IScope } from 'angular';
import { IBlocBehavior } from '../../behaviors/ex-bloc/ex-bloc.controller';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IApiError } from '../../interfaces/api-error.interface';
import { ITranscludeSlotFunction } from '../../interfaces/transclude-function.interface';
import { IDialog } from '../../services/dialog.service';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMenuMoreClass, IMenuMoreTypes, IMenuMoreTypesOptions } from '../../services/menu/menu-more.service';
import { IMenuClass } from '../../services/menu/menu.service';
import { IMonoOccurrence, IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IFilterGroupBy } from '../../interfaces/group-by.interface';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IStateService = angular.ui.IStateService;
import IDialogOptions = angular.material.IDialogOptions;
import { IDataLinker } from '../../services/data-linker.service';
import { IMessagesErreurs } from '../../../apps/sofe/components/ex-multi-occurrence-analyse/ex-multi-occurrence-analyse.controller';
import { IDefaultsService } from '../../services/utils/defaults.service';
import isMobile from '../../constants/mobile.constant';

export interface IComposantMultiOccurrenceTemplate extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    forageOnClick: boolean;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    dataSourceCtrl: IDataSourceController;
    hasAccordeonInCard: boolean;
    hasTotalInCard: boolean;
    blocCtrl: IBlocBehavior;
    newOpened: boolean;
    messagesErreur: IMessagesErreurs;
    newMonoOccurrence: IMonoOccurrence;
    newData: any;
    saving: boolean;
    removeMenuMore: boolean;
    newActionMenuItem: IMenuItem;
    onCheckboxChange?: { (variables: { data: any }): { data: any } };
    btnActionFermer: IMenuItem;
    templateBrisInit: boolean;
    showMenuMore: boolean;
    showFormulaireTitle: boolean;
    draggable?: boolean;

    compileTemplate(index: number, rowScope: any, brisIndex: number): void;
    compileBrisTemplate(rowScope: any, brisIndex: number): void;
    compileAccordeonTemplate(index: number, rowScope: any): void;
    compileTotalTemplate(scope: any): void;
    hasCheckbox(data: any): boolean;
    retryErreur(): void;
    getDataList(): Array<any>;
    isLoading(): boolean;
    onClick(event: MouseEvent, data: any): void;
    isSelectRow(data: any): boolean;
    hasAccordeon(): boolean;
    hasTotal(): boolean;
    toggleAccordeon(data: any): void;
    displayNewFormulaire(): boolean;
    saveNewAction(savedData: any): IPromise<any>;
    saveNewAndNextAction(savedData: any): IPromise<any>;
    saveRowAction(monoOccurrence: IMonoOccurrence, savedData: any): IPromise<any>;
    closeRow(rowDetails: any): void;
    closeNew(): void;
    retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence): void;
    retryValeursDefautErreur(monoOccurrence: IMonoOccurrence): void;
    isActiveRow(data: any): boolean;
    checkboxChange(rowData: any): void;
    shouldDisableSave(data: any): boolean;
    onSaveNewAction(data: any): void;
    hasBoutonFermer(data: any): boolean;
    doubleClickRow(event: MouseEvent, data: any): void;
    isBrisTranscludePopulated(): boolean;
    keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence): void
}

/* @ngInject */
export default function MultiOccurrenceTemplateController(MultiOccurrence: IMultiOccurrenceClass,
    MonoOccurrence: IMonoOccurrenceClass,
    groupByFilter: IFilterGroupBy,
    exFocus: IFocusService,
    notificationHandler: INotificationHandler,
    $scope: IScope,
    $element: IAugmentedJQuery,
    $transclude: ITranscludeSlotFunction,
    $state: IStateService,
    Menu: IMenuClass,
    DialogSuiviModification: IDialog,
    DialogConfirm: IDialog,
    MenuMore: IMenuMoreClass,
    MenuItem: IMenuItemClass,
    defaults: IDefaultsService,
    dragulaService: any,
    dataLinker: IDataLinker) {
    const vm: IComposantMultiOccurrenceTemplate = this;

    vm.$onInit = $onInit;
    vm.hasCheckbox = hasCheckbox;
    vm.retryErreur = retryErreur;
    vm.getDataList = getDataList;
    vm.isLoading = isLoading;
    vm.onClick = onClick;
    vm.doubleClickRow = doubleClickRow;
    vm.initMsgErr = initMsgErr;
    vm.isSelectRow = isSelectRow;
    vm.hasAccordeon = hasAccordeon;
    vm.toggleAccordeon = toggleAccordeon;
    vm.displayNewFormulaire = displayNewFormulaire;
    vm.saveNewAction = saveNewAction;
    vm.saveNewAndNextAction = saveNewAndNextAction;
    vm.saveRowAction = saveRowAction;
    vm.closeRow = closeRow;
    vm.closeNew = closeNew;
    vm.retryMonoOccurrenceErreur = retryMonoOccurrenceErreur;
    vm.retryValeursDefautErreur = retryValeursDefautErreur;
    vm.isActiveRow = isActiveRow;
    vm.checkboxChange = checkboxChange;
    vm.hasTotal = hasTotal;
    vm.shouldDisableSave = shouldDisableSave;
    vm.isBrisTranscludePopulated = isBrisTranscludePopulated;
    vm.hasBoutonFermer = hasBoutonFermer;

    function $onInit() {
        defaults(vm, {
            showMenuMore: true
        });
        vm.isMobile = isMobile

        vm.showFormulaireTitle = vm.multiOccurrenceOptions.formulaire && typeof vm.multiOccurrenceOptions.formulaire.showTitle !== 'undefined' ? vm.multiOccurrenceOptions.formulaire.showTitle : true;
        vm.templateBrisInit = false;
        const options = vm.multiOccurrenceOptions.optionsNouveau && vm.multiOccurrenceOptions.optionsNouveau.options ? vm.multiOccurrenceOptions.optionsNouveau.options : {};
        vm.newActionMenuItem = new MenuItem(vm.multiOccurrenceOptions.optionsNouveau && vm.multiOccurrenceOptions.optionsNouveau.lblTitre || 'G_LBL_BTN_NOUVEAU', openNew, options);

        vm.newOpened = false
        vm.newMonoOccurrence = undefined

        // Voici le cas ou le multi-occurrence est à l'intérieur d'un mono-occurrence écran.
        if (vm.monoOccurrenceCtrl && vm.blocCtrl && isSousMulti() && !vm.multiOccurrenceCtrl) {
            vm.multiOccurrenceOptions.srccod = vm.monoOccurrenceCtrl.monoOccurrence.srccod;
            vm.multiOccurrenceOptions.bloc = vm.multiOccurrenceOptions.bloc || vm.blocCtrl.bloc;
            vm.multiOccurrenceOptions.monoOccurrenceParent = vm.monoOccurrenceCtrl.monoOccurrence;

            vm.monoOccurrenceCtrl.monoOccurrence.data.$promise && vm.monoOccurrenceCtrl.monoOccurrence.data.$promise.then(() => {
                // Il faut attendre que les données soit chargées. Le id à utilisé est possiblement différent de celui
                // qui est utilisé pour le forage
                const cleint = vm.monoOccurrenceCtrl.monoOccurrence.cleint;
                vm.multiOccurrenceOptions.parentId = vm.monoOccurrenceCtrl.monoOccurrence.data[cleint];
                const cycleCleint = vm.monoOccurrenceCtrl.monoOccurrence.cycleCleint;
                vm.multiOccurrenceOptions.cycleId = vm.monoOccurrenceCtrl.monoOccurrence.data[cycleCleint];

                // On charge le multi seulement en mode édition
                if (vm.multiOccurrenceOptions.parentId) {
                    iniMultiOccurrence();
                }
            });
        } else if (vm.multiOccurrenceCtrl && vm.blocCtrl) {
            const multiOccurrenceParent = (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence);
            vm.multiOccurrenceOptions.multiOccurrenceParent = multiOccurrenceParent;

            if (!vm.multiOccurrenceOptions.srccod) {
                vm.multiOccurrenceOptions.srccod = vm.multiOccurrenceCtrl.multiOccurrence.srccod;
                vm.multiOccurrenceOptions.bloc = vm.multiOccurrenceOptions.bloc || vm.blocCtrl.bloc;
            }

            const cleint = vm.multiOccurrenceCtrl.multiOccurrence.cleint;
            if (vm.dataSourceCtrl) {
                vm.multiOccurrenceOptions.cycleId = vm.dataSourceCtrl.data[vm.multiOccurrenceCtrl.multiOccurrence.cycleCleint];
                vm.multiOccurrenceOptions.parentId = vm.dataSourceCtrl.data[cleint];
            }

            iniMultiOccurrence();
        } else {
            iniMultiOccurrence();
        }

        vm.btnActionFermer = new MenuItem(
            'G_LBL_BTN_FERMER',
            (ev: MouseEvent, data: any) => {
                vm.closeRow(data);
            },
            {
                class: 'md-raised ex-button ex-button-secondary',
                iconButton: false
            }
        );
    }

    function iniMultiOccurrence() {
        const actionsDroite = (vm.multiOccurrenceOptions.actionsRangeeDroite) ? new Menu([...vm.multiOccurrenceOptions.actionsRangeeDroite.listeMenuItem]) : new Menu([]);

        vm.multiOccurrence = new MultiOccurrence({
            ...vm.multiOccurrenceOptions,
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            fonctions: {
                selectionnerTri: true,
                recherche: false,
                selectionnerUnEtat: false,
                rechercheParColonne: false,
                selectionnerDesColonnes: false,
                filtrer: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                exportation: false,
                ...vm.multiOccurrenceOptions.fonctions
            },
            actionsRangeeDroite: actionsDroite
        });

        vm.multiOccurrence.once('ready', () => {

            if (vm.multiOccurrence.fonctions.nouveau && !vm.multiOccurrenceOptions.actionsNouveaux) {
                if (vm.multiOccurrence.actionsNouveaux) {
                    vm.multiOccurrence.actionsNouveaux.add(vm.newActionMenuItem);
                } else {
                    vm.multiOccurrence.actionsNouveaux = new Menu([vm.newActionMenuItem]);
                }
            }

            if (!vm.removeMenuMore) {
                initActionsRangeeDroite();
            }
            if (vm.monoOccurrenceEcranCtrl && vm.multiOccurrence.bloc) {
                vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(vm.multiOccurrence);
            }

            if (vm.ecranContextCtrl.stateParams.error && vm.multiOccurrence.bloc) {
                const erreurData = vm.ecranContextCtrl.stateParams.error;

                if (erreurData.bloc === vm.multiOccurrence.bloc.toUpperCase()) {
                    $scope.$watch('::vm.newMonoOccurrence', () => {
                        if (vm.newMonoOccurrence) {
                            const confirmAction = () => saveNewEdition(erreurData.data);
                            openNew(undefined, erreurData.data);
                            afficherConfirmerSauvegarde(confirmAction, erreurData.error);
                        }
                    });
                    //On doit effacer l'erreur pour éviter qu'elle soit affichée plusieurs fois.
                    delete vm.ecranContextCtrl.stateParams.error;
                }
            }

            if (!vm.draggable) {
                //Si l'option n'est pas active, on s'assure de ne pas pouvoir "drag-n-drop" les lignes.
                dragulaService.options($scope, vm.draggable, {
                    moves: function () {
                        return !!vm.draggable;
                    }
                });
            }

            $scope.$on('$mdMenuOpen', (event, elem) => {
                elem.closest('.ex-multi-occurrence-template-row').addClass('ex-multi-occurrence-template--menu-visible');
            });

            $scope.$on('$mdMenuClose', () => {
                $element.find('.ex-multi-occurrence-template--menu-visible').removeClass('ex-multi-occurrence-template--menu-visible');
            });
        });
    }

    function initMsgErr(data: any): void {
        if (!vm.messagesErreur || !vm.messagesErreur[data[vm.multiOccurrence.cleint]]) {
            vm.messagesErreur = vm.messagesErreur || {};

            const messagesErreurCols = vm.multiOccurrence.getErrorColumnsName();

            const lstMessages = messagesErreurCols.map((ele: any) => {
                if (vm.multiOccurrence.dataTypes[ele].params.err) {
                    return data[vm.multiOccurrence.dataTypes[ele].params.err];
                }
            });

            Object.assign(vm.messagesErreur, lstMessages.filter((ele) => ele).reduce((messagesErreurs: IMessagesErreurs, message: string) => {
                if (!messagesErreurs[data.$id]) {
                    messagesErreurs[data.$id] = [message];
                } else if (!messagesErreurs[data.$id].indexOf(message)) {
                    messagesErreurs[data.$id].push(message);
                }

                return messagesErreurs;
            }, {}));
        }
    }

    function openNew(event: Event, data?: any) {
        if (vm.multiOccurrence.isView) {
            if (vm.multiOccurrence.optionsNouveau && vm.multiOccurrence.optionsNouveau.selectionSource) {
                // Dans le cas où on a plusieurs boutons nouveaux, on veut mettre à jour les valeurs par défauts
                // à chaque changement de 'bouton'
                vm.newMonoOccurrence.resetData(data);
                vm.newMonoOccurrence.once('dataUpdate', () => {
                    vm.newMonoOccurrence.id = undefined;
                    openNewAction(vm.newMonoOccurrence.valeursDefaut, data);
                });
            } else {
                vm.newMonoOccurrence.data = new vm.newMonoOccurrence.DataResource(data);
                vm.newMonoOccurrence.id = undefined;
                openNewAction(vm.newMonoOccurrence.valeursDefaut, data);
            }
        } else {
            vm.newMonoOccurrence.id = undefined
            vm.newMonoOccurrence.resetData(data);
            openNewAction(vm.multiOccurrence.valeursDefaut, data);
        }
    }

    function openNewAction(defaultValues: any, data?: any) {
        vm.newData = {...defaultValues, ...data};
        vm.newOpened = true;
        vm.newActionMenuItem.disabled = true;
    }

    function initActionsRangeeDroite() {
        const menuMoreTypes: IMenuMoreTypes = {};
        const menuMoreTypesOptions: IMenuMoreTypesOptions = {};

        if (vm.multiOccurrence.fonctions.edition && vm.multiOccurrence.formulaire) {
            menuMoreTypes.edition = (event: JQueryEventObject, rowDetails: any) => {
                if (vm.multiOccurrence.hasEdition(rowDetails)) {
                    toggleAccordeon(rowDetails);
                }
            };

            menuMoreTypesOptions.edition = {
                disabled: (data: any) => data.$opened
            };

            if (vm.multiOccurrence.fonctions.edition instanceof Function) {
                menuMoreTypesOptions.edition.visible = vm.multiOccurrence.fonctions.edition;
            }
        }

        if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
            const data = (vm.dataSourceCtrl) ?
                { ...vm.dataSourceCtrl.data } :
                (vm.monoOccurrenceCtrl) ? { ...vm.monoOccurrenceCtrl.monoOccurrence.data } : {};

            dataLinker.link($element, data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

            if (!vm.multiOccurrence.fonctions.nouveau(data)) {
                vm.newActionMenuItem.disabled = true;
            }
        }

        if (vm.multiOccurrence.hasAccesMenuEmploye) {
            menuMoreTypes.dossierEmploye = true;
        }

        if (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) {
            menuMoreTypes.suiviModification = (event: any, rowDetails: any) => {
                afficherSuiviModification(event, rowDetails);
            };
        }

        if (vm.multiOccurrence.fonctions.supprime) {
            menuMoreTypes.supprime = (event: MouseEvent, rowDetails: any) => {
                afficherConfirmerSuppression(event, rowDetails);
            };

            if (vm.multiOccurrence.fonctions.supprime instanceof Function) {
                menuMoreTypesOptions.supprime = {
                    visible: vm.multiOccurrence.fonctions.supprime
                };
            }
        }

        if (vm.showMenuMore && (vm.multiOccurrence.fonctions.supprime ||
            (vm.multiOccurrence.fonctions.edition && vm.multiOccurrence.formulaire) ||
            (vm.multiOccurrence.fonctions.suiviModification && vm.multiOccurrence.hasChampSuiviModification()) ||
            (vm.multiOccurrence.actionsMoreLigne && vm.multiOccurrence.actionsMoreLigne.listeMenuItem.length))) {
            vm.multiOccurrence.creerBoutonDupliquer(() => vm.newMonoOccurrence, () => vm.newOpened = true)
            const menuMore = new MenuMore(menuMoreTypes, vm.multiOccurrence.actionsMoreLigne, menuMoreTypesOptions);
            vm.multiOccurrence.actionsRangeeDroite.add(menuMore);
        }
        if (vm.multiOccurrenceOptions.navigatePage && !vm.forageOnClick) {
            vm.multiOccurrence.actionsRangeeDroite.add(
                new MenuItem('G_LBL_BTN_OUVRIR', getPageLink, {
                    icon: 'exit_to_app',
                    link: true,
                    fonction: `${vm.multiOccurrence.mnemonique}.BOUOUV`,
                    directionTooltip: 'bottom',
                    hidden: (data: any) => !getPageLink(data)
                })
            );
        }
    }

    function afficherSuiviModification(event: MouseEvent, rowDetails: any) {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                data: rowDetails,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogSuiviModification.show(dialogOptions);
    }

    function afficherConfirmerSuppression(event: MouseEvent, rowDetails: any) {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            multiple: true,
            locals: {
                lblTitre: 'G_LBL_BTN_SUPP_ENR',
                lblDescription: 'G_LBL_SUPP_ENR',
                lblConfirm: 'G_LBL_BTN_APPLIQUER',
                lblMessage: 'G_MSG_SUPP_ENR',
                icon: 'delete',
                ecranContext: vm.ecranContextCtrl,
                confirmAction() {
                    if (vm.multiOccurrence.isView) {
                        return deleteRowEdition(rowDetails);
                    } else {
                        return deleteRow(rowDetails);
                    }
                }
            }
        };

        DialogConfirm.show(dialogOptions);
    }

    function deleteRow(rowDetails: any): IPromise<any> {
        vm.saving = true;
        return vm.multiOccurrence.deleteRowDetails(rowDetails)
            .then(() => {
                notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
            })
            .finally(() => {
                vm.saving = false;
            });
    }

    function deleteRowEdition(rowDetails: any): IPromise<any> {
        const monoOccurrenceSupression = new MonoOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            multiOccurrenceParent: vm.multiOccurrence,
            srccod: vm.multiOccurrence.srccod,
            resourceParams: vm.multiOccurrence.getResourceParamsValues(),
            isEdition: true
        });
        return monoOccurrenceSupression.delete(rowDetails[vm.multiOccurrence.cleint]).then(() => {
            notificationHandler.succes('G_MSG_SUPP_ENR_SUCCES');
            vm.multiOccurrence.fetchDataList();

            if (vm.multiOccurrence.onDelete) {
                vm.multiOccurrence.onDelete()
            }
        });
    }

    function isSousMulti() {
        // Si le multi a un srrcod différent que son parent, il est indépendant
        return (!vm.multiOccurrenceOptions.srccod || vm.multiOccurrenceOptions.srccod == vm.monoOccurrenceCtrl.monoOccurrence.srccod);
    }

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.autoFetch = true;
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function getDataList(): Array<any> {
        if (vm.multiOccurrence.bris) {
            if (vm.multiOccurrence.dataList && vm.multiOccurrence.dataList.length) {
                return groupByFilter(vm.multiOccurrence.dataList, vm.multiOccurrence.bris);
            }
        }
        return [vm.multiOccurrence.dataList];
    }

    function isLoading(): boolean {
        return (
            (
                vm.multiOccurrence.initializing ||
                vm.multiOccurrence.fetchingDataList
            ) &&
            (
                !vm.multiOccurrence.initError &&
                !vm.multiOccurrence.dataListError
            )
        );
    }

    function onClick(event: MouseEvent, data: any) {
        if (isMobile || vm.forageOnClick) {
            doubleClickRow(event, data);
        }

        if (!angular.element(event.target).closest('.md-button').length
            // Regarde si on click dans l'accordéon.
            && !angular.element(event.target).closest('.ex-multi-occurrence-template-row-accordeon').length) {
            if (!vm.forageOnClick || !vm.multiOccurrence.navigatePage) {
                toggleAccordeon(data);
            } else {
                vm.multiOccurrence.activeRowCleint = data[vm.multiOccurrence.cleint];
            }
        }
    }

    function doubleClickRow(event: MouseEvent, data: any) {
        vm.multiOccurrence.doubleClickRow(event, data, vm.ecranContextCtrl.stateParams);
    }

    function isSelectRow(data: any): boolean {
        return data[vm.multiOccurrence.cleint] === vm.multiOccurrence.activeRowCleint;
    }

    function hasAccordeon() {
        return (typeof vm.hasAccordeonInCard !== 'undefined') ? vm.hasAccordeonInCard : $transclude.isSlotFilled('accordeon');
    }

    function hasTotal(): boolean {
        return (typeof vm.hasTotalInCard !== 'undefined') ? vm.hasTotalInCard : $transclude.isSlotFilled('total');
    }

    function toggleAccordeon(data: any) {
        if (vm.multiOccurrence.activeRowCleint !== data[vm.multiOccurrence.cleint]) {
            //On s'assure que l'activeRowCleint représente bien la bonne ligne du multi-occurrence
            vm.multiOccurrence.activeRowCleint = data[vm.multiOccurrence.cleint];
        }

        if (hasAccordeon() || (vm.multiOccurrence.hasEdition(data) && vm.multiOccurrence.formulaire) || vm.multiOccurrence.rowHasError(data)) {
            data.$opened = !data.$opened;
            initMsgErr(data);
        } else if (vm.multiOccurrence.fonctions.activeRowOnClick) {
            vm.multiOccurrence.activeRowCleint = data[vm.multiOccurrence.cleint];
        }
    }

    function getPageLink(data: any) {
        const params = vm.multiOccurrence.navigateParams ? vm.multiOccurrence.navigateParams(data, vm.multiOccurrence) : {};

        const pageName = vm.multiOccurrence.navigatePage instanceof Function ? vm.multiOccurrence.navigatePage(data) : vm.multiOccurrence.navigatePage;

        if (!pageName) return

        return $state.href(pageName, Object.assign({
            id: data[vm.multiOccurrence.forageCleint],
            menuId: vm.ecranContextCtrl.stateParams.menuId
        }, params));
    }

    function displayNewFormulaire() {
        return Boolean(
            vm.newOpened &&
            vm.multiOccurrence.schema &&
            vm.newMonoOccurrence.schema &&
            !vm.newMonoOccurrence.fetchingValeursDefaut &&
            !vm.newMonoOccurrence.valeursDefautError
        );
    }

    function saveNewAction(savedData: any): IPromise<any> {
        return saveNewEdition(savedData)
            .catch((error: IApiError) => {
                const confirmAction = () => saveNewEdition(savedData);
                afficherConfirmerSauvegarde(confirmAction, error);
            });
    }

    function saveNewEdition(savedData: any): IPromise<any> {
        return saveRowEdition(vm.newMonoOccurrence, savedData).then(() => {
            savedData.id = vm.newMonoOccurrence.id;
            vm.closeNew();

            if (vm.onSaveNewAction instanceof Function) {
                vm.onSaveNewAction(savedData);
            }
        });
    }

    function saveNewAndNextAction(savedData: any): IPromise<any> {
        return saveNewAndNext(savedData)
            .catch((error: IApiError) => {
                const confirmAction = () => saveNewAndNext(savedData);
                afficherConfirmerSauvegarde(confirmAction, error);
            });
    }

    function saveNewAndNext(savedData: any): IPromise<any> {
        return saveRowEdition(vm.newMonoOccurrence, savedData)
            .then(() => {
                const colSelectionSpecialisee = vm.multiOccurrence.optionsNouveau && vm.multiOccurrence.optionsNouveau.selectionSource;
                const defaultData = (colSelectionSpecialisee) ?
                    {
                        [colSelectionSpecialisee]: savedData[colSelectionSpecialisee],
                        ...(vm.multiOccurrence.dataTypes[colSelectionSpecialisee].params.valeurs || []).reduce((autresValeurs: any, col: string) => {
                            autresValeurs[col] = savedData[col];
                            return autresValeurs;
                        }, {})
                    } :
                    {};
                vm.multiOccurrence.formulaire.initFormData(defaultData, vm.newMonoOccurrence.dataTypes);
                openNew(undefined, defaultData);
                vm.newFormCtrl.$setPristine();
                vm.newFormCtrl.$setUntouched();
                exFocus($element.find('ex-edition'));
            });
    }

    function saveRowAction(monoOccurrence: IMonoOccurrence, savedData: any) {
        return saveRowEdition(monoOccurrence, savedData)
            .catch((error: IApiError) => {
                const confirmAction = () => saveRowEdition(monoOccurrence, savedData);
                return afficherConfirmerSauvegarde(confirmAction, error);
            });
    }

    function saveRowEdition(monoOccurrence: IMonoOccurrence, savedData: any): IPromise<any> {
        return monoOccurrence.save(savedData)
            .then(() => {
                // La liste utilise un service différent, alors il faut toujours rafraichir meme lors des updates
                vm.multiOccurrence.fetchDataList();

                notificationHandler.succes();

                if (savedData.$opened) {
                    closeRow(savedData);
                }
            });
    }

    function closeNew(): void {
        vm.newOpened = false;
        vm.newActionMenuItem.disabled = false;
    }

    function closeRow(data: any) {
        data.$opened = false;
    }

    function retryMonoOccurrenceErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchData();
        }
    }

    function retryValeursDefautErreur(monoOccurrence: IMonoOccurrence) {
        if (monoOccurrence.initError) {
            monoOccurrence.init();
        } else {
            monoOccurrence.fetchValeursDefaut();
        }
    }

    function afficherConfirmerSauvegarde(action: () => {}, error: IApiError): IPromise<any> {
        return notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblConfirm: 'G_LBL_BTN_APPLIQUER',
            lblMessage: 'G_MSG_SAUV_ENR',
            confirmAction() {
                return action();
            }
        });
    }

    function isActiveRow(data: any): boolean {
        return data[vm.multiOccurrence.cleint] === vm.multiOccurrence.activeRowCleint;
    }

    function checkboxChange(rowData: any) {
        vm.onCheckboxChange({ data: rowData });
    }

    vm.selectionPersistanteChange = function selectionPersistanteChange(rowData: any) {
        return vm.multiOccurrence.saveRowDetails(rowData).catch(() => {
            rowData[vm.multiOccurrence.selectionPersistante] = !rowData[vm.multiOccurrence.selectionPersistante];
        });
    }

    vm.selectionLocaleChange = function selectionLocaleChange(rowData: any) {
        const index = vm.multiOccurrence.selectionLocale.findIndex(item => rowData[vm.multiOccurrence.cleint] === item[vm.multiOccurrence.cleint]);
        if (index !== -1) {
            vm.multiOccurrence.selectionLocale.splice(index, 1);
        } else {
            vm.multiOccurrence.selectionLocale.push({ ...rowData, $$hashKey: undefined });
        }
    }

    vm.isSelectedLocal = function isSelectedLocal(rowData: any) {
        return vm.multiOccurrence.selectionLocale.some(item => rowData[vm.multiOccurrence.cleint] === item[vm.multiOccurrence.cleint])
    }

    function shouldDisableSave(data: any) {
        return (
            !vm.multiOccurrence.hasChampsModifiables(true, data) ||
            (vm.multiOccurrence.disableSave && vm.multiOccurrence.disableSave(data))
        );
    }

    function isBrisTranscludePopulated(): boolean {
        return $transclude.isSlotFilled('bris');
    }

    function hasBoutonFermer(data: any): boolean {
        return Boolean(!vm.multiOccurrence.hasEdition(data) && (vm.multiOccurrence.rowHasError(data) || vm.multiOccurrence.details));
    }

    function hasCheckbox(data: any): boolean {
        return vm.multiOccurrence.fonctions.selectionnerLocalement || (vm.multiOccurrence.selectionPersistante && vm.multiOccurrence.hasEdition(data)) || (vm.multiOccurrence.rangeesSelectionnables && !vm.multiOccurrence.selectionPersistante);
    }

    vm.getListMenuItems = function getListMenuItems() {
        return vm.multiOccurrence.actionsRangeeDroite.listeMenuItem.filter(menuItem => !isMobile || ((menuItem as any).icon !== 'exit_to_app'));
    }

    vm.keydownEdition = function keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence) {
        if (event.shiftKey && event.key === "F6" && vm.multiOccurrence.initialized && vm.multiOccurrence.fonctions.nouveau && vm.multiOccurrence.isEveryChampsRequisModifiables(false) && vm.multiOccurrence.fonctions.editionStandard && !vm.multiOccurrence.editionRapideActive) {
            event.preventDefault()
            vm.newMonoOccurrence.data = { ...monoOccurrence.data, [monoOccurrence.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }
            vm.newMonoOccurrence.id = undefined
            vm.newMonoOccurrence.emit("dataUpdate")
            vm.newOpened = true
        }
    }
}
