import {IComponentOptions, module} from 'angular';
import FormulaireInputController from './ex-formulaire-input.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-formulaire-input', [
]).component('exFormulaireInput', FormulaireInputFactory());

function FormulaireInputFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.OCCURRENCE,
            Binding.FORMULAIRE,
            Binding.DATA,
            Binding.DATA_TYPE,
            Binding.COL.OPTIONAL,
            {
                champ: '<',
                enregistrable: '<?',
                currentScope: '<?',
                onValueChange: '<?',
                rowIndex: '@?'
            }
        ),
        require: {
            templateCtrl: '?^exTemplate',
            formCtrl: '?^^form'
        },
        controller: FormulaireInputController,
        controllerAs: 'vm',
        template: require('./ex-formulaire-input.html')
    };
}
