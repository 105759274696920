import * as angular from 'angular';
import { IComposantCard } from '../ex-card/ex-card.controller';
import { IAugmentedJQuery, IComponentController, IScope, ITimeoutService } from 'angular';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { ICycleFinanceClass } from '../../resources/cycle-finance.resource';
import { ICycleRhClass, IActionPossibleRh } from '../../resources/cycle-rh.resource';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { IChangementManager } from '../../services/changement-manager.service';
import { IDialog } from '../../services/dialog.service';
import { IMessagesCycle, IMessagesCycleClass } from '../../services/messages-cycle.service';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import IDialogOptions = angular.material.IDialogOptions;
import IResourceArray = angular.resource.IResourceArray;

interface IComposantCardStickyCycle extends IComponentController {
    monoOccurrence: IMonoOccurrence;
    lblTitre: string;
    openInfos(event: MouseEvent): void;
    openHistorique(event: MouseEvent): void;
    openProchainEtat(event: MouseEvent): void;
    refreshProchainEtat(): void;
    stickyHasError(): void;
    hasActionsPossibles(): boolean;
    hasAccesProchaineEtape(): boolean;
    formData: any;
    messagesCycle: IMessagesCycle;
    ecranContextCtrl: IEcranContextController;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    flagActionsPossibles: boolean;
    etapeVisible?: boolean;
}
/* @ngInject */
export default function CardStickyCycleController(defaults: IDefaultsService,
    ecranDetails: IEcranDetailsResourcesEntite,
    DialogCycleEvenementInformations: IDialog,
    DialogCycleTransactionnelInformations: IDialog,
    DialogCycleTransactionnelHistorique: IDialog,
    DialogCycleEvenementHistorique: IDialog,
    DialogCycleTransactionnelActions: IDialog,
    parametresSecurite: IParametresSecuriteService,
    CycleRh: ICycleRhClass,
    CycleFinance: ICycleFinanceClass,
    MessagesCycle: IMessagesCycleClass,
    notificationHandler: INotificationHandler,
    $element: IAugmentedJQuery,
    $scope: IScope,
    $timeout: ITimeoutService,
    changementManager: IChangementManager,
    exLibelleFilter: IFilterLibelle,

) {

    const vm: IComposantCardStickyCycle = this;
    vm.openInfos = openInfos;
    vm.openHistorique = openHistorique;
    vm.openProchainEtat = openProchainEtat;
    vm.stickyHasError = stickyHasError;
    vm.hasActionsPossibles = hasActionsPossibles;
    vm.hasAccesProchaineEtape = hasAccesProchaineEtape;
    vm.$onInit = function $onInit() {
        vm.monoOccurrence.isPortailEmploye = (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.bandeauPortail) ? vm.monoOccurrenceEcranCtrl.bandeauPortail : null;
        defaults(vm, {
            lblTitre: ecranDetails.titre
        });

        if (!vm.messagesCycle) {
            vm.messagesCycle = new MessagesCycle({
                cycleCleint: vm.monoOccurrence.cycleCleint,
                id: vm.monoOccurrence.id
            })
        }
        vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams);
        stickyHasError();
        fetchAutresActionsRH();
        refreshProchainEtat();
    }

    function hasActionsPossibles() {
        //pour afficher ou non le boutton
        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint && vm.monoOccurrence.cycleCleint === 'trncleint') {
            return vm.flagActionsPossibles;
        }
        return true;
    }

    function hasAccesProchaineEtape() {
        //pour afficher ou non le boutton
        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint && vm.monoOccurrence.cycleCleint === 'trncleint') {
            return (vm.etapeVisible) ? vm.etapeVisible : false;
        }
        return true;
    }

    function openInfos(event: MouseEvent) {

        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                ecranContext: vm.ecranContextCtrl,
                monoOccurrence: vm.monoOccurrence,
                icon: 'device_hub'
            }
        };

        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint === 'trncleint') {
            DialogCycleTransactionnelInformations.show(dialogOptions);
        } else {
            DialogCycleEvenementInformations.show(dialogOptions);
        }
    }

    function refreshProchainEtat() {
        return vm.monoOccurrence.fetchEtatsCycle().then(() => {
            vm.etapeVisible=true;

            /*if (!(vm.monoOccurrence.isPortailEmploye) && !(vm.monoOccurrence.cycleCleint === 'trncleint')) {
                //dans le cas de doccleint on ajoute l'action 
                vm.etapeVisible = true;
            } else {
                //Si les indicateurs : ((référable = 0 et final = 0 et fermé = 0 et annulé = 0) ou (l’indicateur Accès Web MRCYR_ETAPE.CEPFLGACCEPL=1) )alors l’étape est visible (basée ou non)
                let etape = <IProchainEtatRh>vm.monoOccurrence.prochainEtat;
                if (etape.approbation) {
                    if ((!etape.cepflgstaref && !etape.cepflgstafin && !etape.cepflgstafer && !etape.cepflgstaann) || etape.cepflgaccepl) {
                        vm.etapeVisible = true;
                    }
                }
            }
            */
        }).catch(()=>{});
    }

    function openHistorique(event: MouseEvent) {

        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: {
                ecranContext: vm.ecranContextCtrl,
                monoOccurrence: vm.monoOccurrence
            }
        };

        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint === 'trncleint') {
            DialogCycleTransactionnelHistorique.show(dialogOptions);
        } else {
            DialogCycleEvenementHistorique.show(dialogOptions);
        }
    }

    function openProchainEtat(event: MouseEvent) {
        if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
            return;
        }

        const dialogOptions = {
            targetEvent: event,
            locals: {
                ecranContext: vm.ecranContextCtrl,
                monoOccurrence: vm.monoOccurrence
            }
        };
        DialogCycleTransactionnelActions.show(dialogOptions).then(function () { }, function (res: any) {
            if (res !== -1) {
                refreshEcran();
            }
            refreshProchainEtat();
            fetchAutresActionsRH();
        }).finally(() => { });
    }
    function refreshEcran() {
        // On veut que le load s'affiche sur le champ, malgré le délai que prend le reload
        if (vm.monoOccurrence.cycleCleint === 'trncleint' && vm.monoOccurrence.fonctions.refreshForageIdWithCycle) {
            const params = {
                id: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            };

            CycleRh.verifierForageId(params).$promise
                .then(({ trncleint }: any) => {
                    refreshEcranAction({ ...vm.ecranContextCtrl.stateParams, id: trncleint }, {
                        replace: vm.monoOccurrence.data.trncleint !== trncleint
                    });
                })
                .catch(() => {
                    refreshEcranAction(vm.ecranContextCtrl.stateParams);
                })
        } else {
            refreshEcranAction(vm.ecranContextCtrl.stateParams);
        }
        stickyHasError();
        refreshProchainEtat();
    }
    function refreshEcranAction(params: any, options: { replace?: boolean } = {}) {
        vm.monoOccurrence.fetchingData = true;
        vm.ecranContextCtrl.transitionTo(vm.ecranContextCtrl.stateParams.route.NAME, {
            ...params,
            verifierChangements: false
        }, {
            replace: options.replace,
            reload: true,
        });
    }

    function verifierChangementsNonSauvegardesImbriques() {
        const monoOccurrenceEcranElement = $element.closest('ex-mono-occurrence-ecran');
        const formulaireNonSauvegarde = angular.element(changementManager.getPremierFormulaireNonSauvegarde(monoOccurrenceEcranElement));
        if (formulaireNonSauvegarde.length) {
            const parentCards = formulaireNonSauvegarde.parents('ex-card');
            let titresBlocs: string;
            Array.from(parentCards).forEach((parentCard: HTMLElement) => {
                const cardController: IComposantCard = angular.element(parentCard).controller('exCard');
                cardController.opened = true;
                titresBlocs = titresBlocs ? `${titresBlocs}, ${cardController.lblTitre}` : cardController.lblTitre;
            });

            $timeout(() => scrollToElement(formulaireNonSauvegarde));

            return notificationHandler.erreur({
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: exLibelleFilter('G_MSG_CHANGEMENTS_ACTION', vm.ecranContextCtrl.ecranSourceDetails.libelles, false, titresBlocs)
            });
        }
    }

    function scrollToElement(element: IAugmentedJQuery) {
        const scrollContainer = $element
            .closest('ex-mono-occurrence-ecran')
            .find('.ex-mono-occurrence-ecran-content');
        const scrollContainerTop = scrollContainer.offset().top;
        const targetOffset = element.offset();
        const targetTop = targetOffset && targetOffset.top;

        scrollTo(targetTop - scrollContainerTop - 100);
    }

    function scrollTo(scrollTop: number) {
        return $element.closest('ex-mono-occurrence-ecran').find('[md-scroll-y]').animate({
            scrollTop
        }, 200);
    }
    function stickyHasError() {
        $timeout(() => {
            if (vm.messagesCycle && vm.messagesCycle.messagesErreur && vm.messagesCycle.messagesErreur[0].length) {
                $scope.$emit("vm.mono-occurrence.setSticky.setPadding", vm.messagesCycle)
            }
        }, 1000);
    }

    function fetchAutresActionsRH(trncleintFromData?: number) {
        const trncleint = Number(vm.monoOccurrence.id || trncleintFromData);

        const params = {
            trncleint,
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        vm.autresActionsBusy = true;
        CycleRh.queryActionsPossibles(params).$promise
            .then((actionsPossibles: IResourceArray<IActionPossibleRh>) => {
                let flagArray = false;
                if (actionsPossibles && actionsPossibles.length && actionsPossibles.length>0) {
                    flagArray = true;
                  /*actionsPossibles.map((action) => {
                        if ((!action.cepflgstaref && !action.cepflgstafin && !action.cepflgstafer && !action.cepflgstaann) || action.cepflgaccepl) {
                            //s'il y a au moins une action possible on mets le boutton visible
                            flagArray = true;
                        }
                    })*/
                }
                vm.flagActionsPossibles=flagArray;
            })
            .catch((err) => {
                vm.flagArray = true;
            })
    }
}