import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogConfirmController from './dialog-confirm.controller';

export default module('core.dialogs.dialog-confirm', [
    'core.services.dialog'
]).factory('DialogConfirm', DialogConfirmFactory);

/* @ngInject */
function DialogConfirmFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogConfirmController,
       controllerAs: 'vm',
       bindToController: true,
       multiple: true,
       template: require('./dialog-confirm.html'),
       focusOnOpen: false
   });
}
