/**
 * Composant représentant un menu
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MenuController from './ex-menu.controller';

export default module('core.components.ex-menu', []).component('exMenu', MenuFactory());

function MenuFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MENU,
            Binding.DATA.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            {
                offset: '@?',
                occurrence: '<?',
                menuBas: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MenuController,
        controllerAs: 'vm',
        template: require('./ex-menu.html')
    };
}
