import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import * as angular from 'angular';

export interface IParametreMultipleDataTypeClass {
    new(options?: IParametreMultipleDataTypeOptions): IParametreMultipleDataType;
}

export interface IParametreMultipleDataTypeOptions extends IDataTypeOptions {
    dataTypeChamps?: IDataType;
}

export interface IParametreMultipleDataType extends IDataType {
    dataTypeChamps: IDataType;
}

export default module('core.services.parametre-multiple-data-type', []).factory('ParametreMultipleDataType', ParametreMultipleDataTypeFactory);

/* @ngInject */
function ParametreMultipleDataTypeFactory(DataType: IDataTypeClass): IParametreMultipleDataTypeClass {
    class ParametreMultipleDataType extends DataType implements IParametreMultipleDataType {
        readonly dataTypeChamps: IDataType;
        constructor(options: IParametreMultipleDataTypeOptions) {
            super(angular.extend({}, options));
            this.dataTypeChamps = options.dataTypeChamps;
        }
    }

    return ParametreMultipleDataType;
}
