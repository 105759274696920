import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import {IFormulaire} from '../services/formulaire/formulaire.service';
import {IOccurrence} from '../services/occurrence.service';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;
import IActionHash = angular.resource.IActionHash;

export interface IActionsSelectionOptions {

}

export interface IActionsSelection {}

export interface IActionsSelectionAction {
    ctpcleint: number;
    ctpdscabr: string;
    ctpdsc: string;
    cytcleint: number;
}

export interface IActionsSelectionResponse {
    actions: Array<IActionsSelectionAction>;
}

export interface IActionsSelectionResource extends IResourceClass<any> {
    new(data: IActionsSelectionOptions): IResource<IActionsSelection>;
    getActions(params: any): IResource<any>;
    executeAction(params: any, data: any): IResource<any>;
    executeStaticAction(params: any, data: any): IResource<any>;
}

export interface ISelectionOptions {
    nom: string;
    lblConfirmationTitre?: string;
    confirmationFormulaire?: IFormulaire;
    confirmationData?: any;
    confirmationOccurrence?: IOccurrence;
    icon?: string;
    lblConfirm?: string
    largeurFormulaire?: number
}

export default module('core.resources.actions-selection', [
    'core.services.api-config'
]).factory('ActionsSelectionResource', ActionsSelectionResourceFactory);

/* @ngInject */
function ActionsSelectionResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IActionsSelectionResource {
    const params = {};

    const actions: IActionHash = {
        getActions: {
            method: 'GET'
        },
        executeAction: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/actions-selection/:srccod/:selection/:selections/action/:ctpcleint`
        },
        executeStaticAction: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/actions-selection/:srccod/:selection/:selections/fonction/:fonction`
        }
    };

    return <IActionsSelectionResource>$resource(`${ApiConfig.ROOT}/actions-selection/:srccod/:selection/:selections/:fonction?`, params, actions);
}

