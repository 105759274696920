import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';
import {ILovEntiteExterneDataTypeOptions} from './lov-entite-externe-data-type.service';
import * as angular from 'angular';

export interface ILovRequerantDataTypeClass {
    new(options?: ILovRequerantDataTypeOptions): ILovRequerantDataType;
}

export interface ILovRequerantDataTypeOptions extends IDataTypeOptions {
}

export interface ILovRequerantDataType extends IDataType {
}

export default module('core.services.lov-requerant-data-type', []).factory('LovRequerantDataType', LovRequerantDataTypeFactory);

/* @ngInject */
function LovRequerantDataTypeFactory(DataType: IDataTypeClass): ILovRequerantDataTypeClass {
    class LovRequerantDataType extends DataType implements ILovRequerantDataType {
        readonly template: string = `{{vm.col | exLov : vm.data : vm.dataType}}`;

        constructor(options: ILovEntiteExterneDataTypeOptions) {
            super(angular.extend({
                alignment: 'left'
            }, options));
        }
    }

    return LovRequerantDataType;
}
