import {module} from 'angular';
import {IFormulaire} from './formulaire.service';
import {IData} from '../data-linker.service';

export interface IFormulaireGroupeConditionnelClass{
    new(col: string, lblTitre: string, formulaire: IFormulaire, options?: IFormulaireGroupeConditionnelOptions): IFormulaireGroupeConditionnel;
}

export interface IFormulaireGroupeConditionnel {
    readonly col: string;
    readonly lblTitre: string;
    readonly formulaire: IFormulaire;
    readonly largeur: number;
    readonly modifAllow: boolean;
    readonly default?: any;
    readonly switch?: boolean;
    readonly hidden?: boolean | IFormulaireGroupeConditionnelHiddenFonction;
    readonly disabled?: boolean | IFormulaireGroupeConditionnelDisabledFonction;
}

interface IFormulaireGroupeConditionnelHiddenFonction {
    (data?: IData): boolean;
}

interface IFormulaireGroupeConditionnelDisabledFonction {
    (data?: IData): boolean;
}

export interface IFormulaireGroupeConditionnelOptions {
    readonly default?: any;
    readonly switch?: boolean;
    readonly modifAllow?: boolean;
    readonly hidden?: boolean | IFormulaireGroupeConditionnelHiddenFonction;
    readonly disabled?: boolean | IFormulaireGroupeConditionnelDisabledFonction;
}

export default module('core.services.formulaire-groupe-conditionnel', []).factory('FormulaireGroupeConditionnel', FormulaireGroupeConditionnelFactory);

/* @ngInject */
function FormulaireGroupeConditionnelFactory() {


    class FormulaireGroupeConditionnel implements IFormulaireGroupeConditionnel {

        readonly col: string;
        readonly lblTitre: string;
        readonly formulaire: IFormulaire;
        readonly largeur: number;
        readonly modifAllow: boolean = true;
        readonly default?: any;
        readonly switch?: boolean = false;
        readonly hidden?: boolean | IFormulaireGroupeConditionnelHiddenFonction;
        readonly disabled?: boolean | IFormulaireGroupeConditionnelDisabledFonction;

        constructor(col: string, lblTitre: string, formulaire: IFormulaire, options: IFormulaireGroupeConditionnelOptions = {}) {
            this.col = col;
            this.lblTitre = lblTitre;
            this.formulaire = formulaire;
            this.largeur = 100;

            if (typeof options.modifAllow !== 'undefined') {
                this.modifAllow = options.modifAllow;
            }

            if (typeof options.default !== 'undefined') {
                this.default = options.default;
            }

            if (typeof options.switch !== 'undefined') {
                this.switch = options.switch;
            }

            if (typeof options.hidden !== 'undefined') {
                this.hidden = options.hidden;
            }

            if (typeof options.disabled !== 'undefined') {
                this.disabled = options.disabled;
            }
        }
    }
    return FormulaireGroupeConditionnel;
}
