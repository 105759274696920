import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IFonctionTransversaleTitre {
    titre: string
}

export interface IFonctionTransversaleResourceClass extends IResourceClass<any> {
    new(data: any): IResource<any>;
    getTitre(options: any): IResource<IFonctionTransversaleTitre>;
}

export default module('core.resources.fonction-transversale', [
    'core.services.api-config'
]).factory('FonctionTransversaleResource', FonctionTransversaleResourceFactory);

/* @ngInject */
function FonctionTransversaleResourceFactory(ApiConfig: IApiConfig,
                                             $resource: IResourceService): IFonctionTransversaleResourceClass {
    const params = {};

    const actions = {
        getTitre: {
            method: 'GET',
            url: `${ApiConfig.ROOT}/fonction-transversale/titre/:cleref`
        }
    };

    return <IFonctionTransversaleResourceClass>$resource(`${ApiConfig.ROOT}/fonction-transversale`, params, actions);
}
