/**
 * Capture les erreurs d'authentification et redirige vers la page de login de CAS
 */
import { IHttpInterceptor, IHttpResponse, ILocationService, module } from 'angular';
import { IRoute } from '../../interfaces/route.interface';
import { IExceptions } from '../../constants/exceptions.constant';
import { IBasePathConstant } from '../../constants/base-path.constant';

export default module('core.services.interceptors.authentification-interceptor', []).factory('authentificationInterceptor', authentificationInterceptorFactory);

/* @ngInject */
function authentificationInterceptorFactory($location: ILocationService,
                                            Exceptions: IExceptions,
                                            basePath: IBasePathConstant,
                                            loginSsoRoute: IRoute): IHttpInterceptor {
    return {
        responseError(response: IHttpResponse<any>) {
            if (!location.href.includes('login') && response.status === Exceptions.UNAUTHORIZED.STATUS) {
                const gid = $location.search()["gid"];

                if (gid) {
                    //Création du cookie contenant le gid permettant de savoir quel est le serveur cas utilisé
                    document.cookie = `gid=${gid}; path=/; sameSite=Lax`;
                } else {
                    //Suppression du cookie de gid s'il en existe un pour être redirigé sur celui par défaut
                    const date = new Date(2000, 0, 1);
                    document.cookie = `gid=${gid}; expires=${date.toUTCString()}; path=/; sameSite=Lax`;
                }

                // On remplace les & par des ~~ pour ne pas les perdre en chemin à travers les redirections. Sinon il considère que le & est un paramètre autre que le return URL
                location.href = `${basePath + loginSsoRoute.URL}?returnurl=${$location.url().replace(/&/g, "~~")}`;

                // On fake une promise pour mettre la page en wait en attendant que le logout fasse la job du redirect
                return Promise.resolve(undefined);
            }
            return Promise.reject(response);
        }
    };
}
