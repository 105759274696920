import * as angular from 'angular';
import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IBooleanDataTypeClass {
    new(options?: IBooleanDataTypeOptions): IBooleanDataType;
}
export interface IBooleanDataTypeOptions extends IDataTypeOptions {
    inclusLibelle?:boolean;
}

export interface IBooleanDataType extends IDataType {
}

export default module('core.services.boolean-data-type', []).factory('BooleanDataType', BooleanDataTypeFactory);

/* @ngInject */
function BooleanDataTypeFactory(DataType: IDataTypeClass): IBooleanDataTypeClass {
    class BooleanDataType extends DataType implements IBooleanDataType {
        readonly template: string;
        constructor(options: IBooleanDataTypeOptions = {}) {
            super(angular.extend({
                alignment: 'center'
            }, options));

            if (this.params.coche) {
                this.template = `<ex-coche ng-if="vm.dataType.params.coche && vm.data[vm.col]" style="white-space: normal"></ex-coche>`;
            } else {
                this.template = `<span ng-if="!vm.dataType.params.coche" class="ex-boolean-data-type">
                    <span ng-if="::vm.context.inclusLibelleBoolean" style="margin-right: 2px;">{{::vm.col | exLibelle : vm.context.libelles}}</span>
                    <md-icon style="font-size: 17px; min-height: 17px; min-width: 17px; max-width: 17px; max-height: 17px;">
                        {{(vm.data[vm.col] === 1 || vm.data[vm.col] === '1') ? 'check_box' : 'check_box_outline_blank'}}
                    </md-icon>
                </span>`.trim();
            }
        }
    }

    return BooleanDataType;
}
