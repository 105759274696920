import {module} from 'angular';


/**
 * interface pour un dialog info
 */
export interface IDialogStatusInfo {
    FERMER: number;
}
/**
 * interface pour un dialog confirmation
 */
export interface IDialogStatusConfirm extends IDialogStatusInfo {
    APPLIQUER: number;
}

/**
 * Constante pour un dialog Question
 */
export interface IDialogStatusQuestion extends IDialogStatusInfo {
    OUI: number;
    NON: number;
}


export interface IDialogAllStatus extends IDialogStatusConfirm, IDialogStatusQuestion {}

const dialogStatus: IDialogAllStatus = {
    APPLIQUER: 2,
    OUI: 1,
    NON: 0,
    FERMER: -1
};

export default module('core.constants.dialog-status', []).constant('DialogStatus', dialogStatus);



