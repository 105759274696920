import {IComponentOptions, module} from 'angular';

import SommaireSectionsController from './ex-sommaire-sections.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-sommaire-sections', []).component('exSommaireSections', SommaireSectionsComponent());

function SommaireSectionsComponent(): IComponentOptions {
    return {
        bindings: Binding(
            {
                scrollContainer: '<?',
                recherche: '<?',
                sections: '<?',
                libelles: '<?'
            }
        ),
        controller: SommaireSectionsController,
        controllerAs: 'vm',
        template: require('./ex-sommaire-sections.html')
    };
}
