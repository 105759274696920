/**
 * Comportement permettant compiler un template angular
 */
import {IAugmentedJQuery, ICompileService, IDirective, isObject, module} from 'angular';

export default module('core.behaviors.ex-compile-template', []).directive('exCompileTemplate', CompileTemplateDirective);


/* @ngInject */
function CompileTemplateDirective($compile: ICompileService) : IDirective {
    return {
        scope: {
            template: '<exCompileTemplate',
            context: '<exCompileTemplateContext'
        },
        restrict: 'A',
        link
    };

    function link(scope: any, element: IAugmentedJQuery) {
        const template = isObject(scope.template) ? scope.template.template : scope.template;
        const context = isObject(scope.template) ? scope.template.context : null;

        // On utilise le contexte ou le template est compilé, mais on permet de passer un context additionnel
        const newScope = scope.$parent.$new();
        if (context) {
            // Pour l'instant, on n'observe pas le contexte lié. Ça pourrait être ajouté au besoin.
            Object.assign(newScope, context);
        }

        element.html(template);
        $compile(<any>(element.contents()))(newScope);

        // On s'assure que le scope soit bien détruit
        element.on('$destroy', () => {
            newScope.$destroy();
        });
    }
}
