import { IAugmentedJQuery, IComponentController } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';

interface IComposantCase extends IComponentController {
    col: string;
    cols: Array<string>;
    largeur: number | string;
    alignment: string;
    clear: boolean;
    hideVide: boolean;
    dataSourceCtrl: IDataSourceController;
    width: number | string;
    separator: string;
    format: string;
    prefix?: string | number;
    suffix?: string | number;
    cssprefix?: string;

    getWidth(): number | string;
    isVisibleCol(col: string): boolean;
}

/* @ngInject */
export default function CaseController($element: IAugmentedJQuery,
    defaults: IDefaultsService) {
    const vm: IComposantCase = this;

    vm.$onInit = $onInit;
    vm.isAnyColFilled = isAnyColFilled;
    vm.isVisibleCol = isVisibleCol;

    function $onInit() {
        if (vm.col) {
            vm.cols = [vm.col];
        }
        defaults(vm, {
            alignment: 'left',
            separator: '-',
            width: getWidth()
        });

        $element.css('float', vm.alignment);

        if (vm.clear) {
            $element.addClass('ex-case--clear');
        }
    }

    function getWidth(): number | string {
        // Si c'est un nombre sans unité, on ajoute "px"
        if (String(Number(vm.largeur)) === vm.largeur && Number(vm.largeur) >= 0) {
            return `${vm.largeur}px`;
        } else if (Number(vm.largeur) < 0) {
            $element.css('width', `calc(100% - ${(Number(vm.largeur)) * -1}px)`);
            return '100%';
        } else if (vm.largeur) {
            return vm.largeur;
        } else {
            return '100%';
        }
    }

    function isAnyColFilled() {
        return !vm.cols || vm.cols.some(col => isVisibleCol(col));
    }

    function isVisibleCol(col: string) {
        return !vm.dataSourceCtrl || vm.dataSourceCtrl.data[col] != null;
    }
}
