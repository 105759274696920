import * as angular from 'angular';
import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';


export interface IIntervalleDataTypeClass {
    new(options?: IIntervalleDataTypeOptions): IIntervalleDataType;
}

export interface IIntervalleDataTypeOptions extends IDataTypeOptions {
    dataTypeChamps?: IDataType;
}
export interface IIntervalleDataType extends IDataType {
    dataTypeChamps: IDataType;
}

export default module('core.services.intervalle-data-type', []).factory('IntervalleDataType', IntervalleDataTypeFactory);

/* @ngInject */
function IntervalleDataTypeFactory(DataType: IDataTypeClass): IIntervalleDataTypeClass {
    class IntervalleDataType extends DataType implements IIntervalleDataType {
        readonly template: string = `{{vm.data[vm.col]}}`;
        readonly dataTypeChamps: IDataType;
        constructor(options: IIntervalleDataTypeOptions = {}) {
            super(angular.extend({}, options));
            this.dataTypeChamps = options.dataTypeChamps;
        }
    }

    return IntervalleDataType;
}
