import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CaseEditionAssisteeController from './ex-case-edition-assistee.controller';

export default module('core.components.ex-case-edition-assistee', []).component('exCaseEditionAssistee', CaseEditionAssisteeFactory());

function CaseEditionAssisteeFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.COL,
            Binding.SCHEMAS,
            Binding.READONLY.OPTIONAL,
        ),
        require: {
            formulaireCtrl: '^^exFormulaire',
            multiOccurrenceCtrl: '^^exMultiOccurrence'
        },
        controller: CaseEditionAssisteeController,
        controllerAs: 'vm',
        template: require('./ex-case-edition-assistee.html')
    };
}
