import {module} from 'angular';
import DialogSelecteurController from './dialog-selecteur.controller';
import {IDialogClass} from '../../services/dialog.service';

export default module('core.dialogs.dialog-selecteur', [
    'core.services.dialog'
]).factory('DialogSelecteur', DialogSelecteurFactory);

/* @ngInject */
function DialogSelecteurFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogSelecteurController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-selecteur.html')
   });
}
