import {module} from 'angular';
import {IFilterLibelle} from './ex-libelle.filter';

export default module('core.filters.ex-file-size', ['core.filters.ex-libelle']).filter('exFileSize', fileSizeFilter);

export interface IFilterFileSize {
    (size: number): any;
}

/* @ngInject */
function fileSizeFilter(exLibelleFilter: IFilterLibelle): IFilterFileSize {
    return (size: number): any => {
        const unites = ['G_LBL_FICHIER_TAILLE_OCTETS', 'G_LBL_FICHIER_TAILLE_KILO', 'G_LBL_FICHIER_TAILLE_MEGA', 'G_LBL_FICHIER_TAILLE_GIGA', 'G_LBL_FICHIER_TAILLE_TERA'];
        if (size === 0) return exLibelleFilter('G_LBL_FICHIER_TAILLE_VIDE');
        const k = 1024;
        const decimales = 0;
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(decimales)) + ' ' + exLibelleFilter(unites[i]);
    }
}
