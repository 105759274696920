import {IFilterDate, module} from 'angular';

export default module('core.filters.ex-date-heure', []).filter('exDateHeure', dateHeureFilter);

export interface IFilterExDateHeure {
    (value: string): any;
}

/* @ngInject */
function dateHeureFilter(dateFilter: IFilterDate): IFilterExDateHeure {
    const format = 'yyyy-MM-dd HH:mm:ss';

    return (value: string): any => {
        return dateFilter(value, format)
    }
}
