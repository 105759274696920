import {module} from 'angular';
import {IProfil} from '../resources/profil.resource';
import {IRoute} from '../interfaces/route.interface';
import {IRouter} from '../providers/router.provider';
import IStateService = angular.ui.IStateService;
import IInjectorService = angular.auto.IInjectorService;

export default module('core.filters.ex-route', []).filter('exRoute', routeFilter);

export interface IFilterRoute {
    (srccod: string): any;
}

/* @ngInject */
function routeFilter($state: IStateService,
                     $injector: IInjectorService,
                     profil: IProfil,
                     Router: IRouter): IFilterRoute {
    return (srccod: string): any => {
        const route: IRoute = $injector.get(`${srccod}Route`);

        const {menuItem, profilAcces} = Router.getMenuByCode(`${route.SOURCE}-${route.ECRAN_DEFAUT}`, profil);

        const menucleref = [menuItem.vaesysenttrtmen, menuItem.mencleint, menuItem.vaesysenttrt, menuItem.medcleintref].join(',');

        return $state.href(route.NAME, {
            ecran: route.ECRAN_DEFAUT,
            menuId: `${profilAcces.pracleint}-${menucleref}`
        });
    }
}
