import IState = angular.ui.IState;
import {module} from 'angular';

export default module('core.layouts.vertical-navigation', []).constant('VerticalNavigationLayout', VerticalNavigationFactory());

function VerticalNavigationFactory(): IState {
    return {
        template: require('./vertical-navigation.html')
    };
}
