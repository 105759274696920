import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IDateDataTypeClass {
    new(options?: IDateDataTypeOptions): IDateDataType;
}

export interface IDateDataTypeOptions extends IDataTypeOptions{
}

export interface IDateDataType extends IDataType {
}

export default module('core.services.date-data-type', []).factory('DateDataType', DateDataTypeFactory);

/* @ngInject */
function DateDataTypeFactory(DataType: IDataTypeClass): IDateDataTypeClass {
    class DateDataType extends DataType implements IDateDataType {
        readonly template: string = `{{vm.data[vm.col] | date : vm.dataType.params.format || 'yyyy-MM-dd'}}`;
    }

    return DateDataType;
}
