import {module} from 'angular';

/**
 * Ce service permet de contrôler l'ouverture d'un `ex-expandable` de manière programmationnelle.
 */
export default module('core.services.expandable', []).factory('expandable', ExpandableFactory);

export interface IExpandableService {
    watch(name: string, callback: IExpandableWatcher): () => void;
}

interface IExpandableWatchers {
    [key: string]: Array<IExpandableWatcher>;
}

export interface IExpandableWatcher {
    (expanded: boolean): void;
}

function ExpandableFactory() {
    const watchers: IExpandableWatchers = {};

    return {
        watch, toggle
    };

    function watch(name: string, callback: IExpandableWatcher) {
        watchers[name] = watchers[name] || [];
        watchers[name].push(callback);

        return function stopWatching() {
            const index = watchers[name].indexOf(callback);
            watchers[name].splice(index, 1);
        };
    }

    function toggle(name: string, expand: boolean) {
        if (watchers[name]) {
            watchers[name].forEach(watcher => {
                watcher(expand);
            });
        }
    }
}
