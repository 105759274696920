import {IHttpInterceptor, IHttpResponse, IPromise, module} from 'angular';
import {INotificationHandler} from '../utils/notification-handler.service';

export default module('core.services.interceptors.message-handling-Interceptor', []).factory('messageHandlingInterceptor', messageHandlingInterceptorFactory);

/* @ngInject */
function messageHandlingInterceptorFactory(notificationHandler: INotificationHandler) : IHttpInterceptor {
    return {
        response(response: IHttpResponse<any>): IPromise<IHttpResponse<any>>|IHttpResponse<any> {
            const message = response.headers('Warning');

            if (message) {
                const matchResult = message.match(/([0-9]+) ([a-z0-9]+) "(.*)"/i)
                if (matchResult[2] === 'A') {
                    return notificationHandler
                        .avertissement({
                            lblMessage: decodeURIComponent(matchResult[3]),
                        })
                        .catch(() => response.data);
                } else if (matchResult[2] === 'I') {
                    return notificationHandler
                        .info({
                            lblMessage: decodeURIComponent(matchResult[3]),
                        })
                        .catch(() => response.data);
                }
            }

            return response.data;
        }
    };
}
