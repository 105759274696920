import { IComponentController } from 'angular';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IDialog } from '../../services/dialog.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IProfil } from '../../resources/profil.resource';
import { IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';

interface IComposantSelectionEmploye extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    col: string;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    dataType: IDataType;
    menuItemEmploye: IMenuItem;
    usrnom: string;
}
/* @ngInject */
export default function SelectionEmployeController(DialogSelectionEmploye: IDialog,
    profil: IProfil,
    FormulaireItem: IFormulaireItemClass,
    MenuItem: IMenuItemClass) {
    const vm: IComposantSelectionEmploye = this;

    vm.$onInit = function $onInit() {
        const formulaireItem = new FormulaireItem(vm.col);
        vm.usrnom = profil.preferences.usrnomcre;
        vm.dataType = vm.monoOccurrenceEcranCtrl.monoOccurrence.dataTypes[vm.col];
        vm.menuItemEmploye = new MenuItem('LBL_SELECTION_EMPLOYE', showDialogSelectionEmploye, {
            class: 'ex-button ex-button-action',
            iconButton: false,
            disabled: () => vm.monoOccurrenceEcranCtrl.monoOccurrence.isChampReadonly(formulaireItem, Boolean(vm.monoOccurrenceEcranCtrl.monoOccurrence.id), vm.monoOccurrenceEcranCtrl.formData, true),
            hidden: () => (vm.monoOccurrenceEcranCtrl.bandeauPortail || vm.monoOccurrenceEcranCtrl.monoOccurrence.data.flgnonmodemp)
        });
    }

    vm.getReclamationPour = function getReclamationPour() {
        if (!vm.monoOccurrenceEcranCtrl.formData) return ""
        let reclamationPour = "";
        for (const col of vm.dataType.params.description) {
            if (reclamationPour) reclamationPour += " - "
            reclamationPour += vm.monoOccurrenceEcranCtrl.formData[`${vm.col}__${col}`]
        }
        return reclamationPour
    }

    function showDialogSelectionEmploye(event: MouseEvent) {
        return DialogSelectionEmploye.show({
            locals: {
                srccodref: vm.monoOccurrenceEcranCtrl.monoOccurrence.getNomSourceDetails(),
                col: vm.col,
                lblTitre: 'G_LBL_SELECTION_EMPLOYE',
                targetEvent: event,
                dataType: vm.dataType,
                ecranContextCtrl: vm.ecranContextCtrl
            },
            multiple: true
        }).then((data: any) => {
            Object.assign(vm.monoOccurrenceEcranCtrl.formData, data);
        })
    }
}
