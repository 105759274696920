import {module} from 'angular';
import {IFonctionsEditionConfigMap} from '../resources/source-details.resource';

export interface IFonctionsEditionClass {
    new(): IFonctionsEdition;
    init(configs: IFonctionsEditionConfigMap): IFonctionsEdition;
}

export interface IFonctionsEdition {
    nouveau?: ((data: any) => boolean);
    edition?: ((data: any) => boolean);
    supprime?: ((data: any) => boolean);
}

export default module('core.services.fonctions-edition', []).factory('FonctionsEdition', FonctionsEditionFactory);

function FonctionsEditionFactory(): IFonctionsEditionClass {
    const TYPES_FONCTION_EDITION = ['nouveau', 'edition', 'supprime'];

    class FonctionsEdition implements IFonctionsEdition {
        nouveau: ((data: any) => boolean);
        edition: ((data: any) => boolean);
        supprime: ((data: any) => boolean);

        static init(configs: IFonctionsEditionConfigMap) {

            return Object.keys(configs).reduce((fonctionsEdition: IFonctionsEdition, key: string) => {
                const fonctions = key.split(',');

                fonctions
                    .filter((fonction) => TYPES_FONCTION_EDITION.includes(fonction))
                    .forEach((fonction) => {
                        (<any>fonctionsEdition)[fonction] = this.getCondition(key, configs)
                    });

                return fonctionsEdition
            }, {});
        }

        static getCondition(key: string, config: IFonctionsEditionConfigMap): Function {
            return Function('data', `return (${config[key]})(data)`);
        }
    }

    return FonctionsEdition;
}
