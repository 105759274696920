import {module} from 'angular';
import {IBooleanDataType, IBooleanDataTypeClass, IBooleanDataTypeOptions} from './boolean-data-type.service';
import * as angular from "angular";

export interface IIndicateurErreurDataTypeClass extends IBooleanDataTypeClass {
    new(options?: IIndicateurErreurDataTypeOptions): IIndicateurErreurDataType;
}

export interface IIndicateurErreurDataTypeOptions extends IBooleanDataTypeOptions{
}

export interface IIndicateurErreurDataType extends IBooleanDataType {
}

export default module('core.services.indicateur-erreur-data-type', []).factory('IndicateurErreurDataType', IndicateurErreurDataTypeFactory);

/* @ngInject */
function IndicateurErreurDataTypeFactory(BooleanDataType: IBooleanDataTypeClass): IIndicateurErreurDataTypeClass {
    class IndicateurErreurDataType extends BooleanDataType implements IIndicateurErreurDataType {
        readonly template: string = `<md-icon ng-if="vm.data[vm.col]" class="ex-rouge-500">warning</md-icon>`;
        constructor(options: IIndicateurErreurDataTypeOptions = {}) {
            super(angular.extend({}, options));
            this.template = `<md-icon ng-if="vm.data[vm.col]" class="${options.params.avertissement ? 'ex-ligne-en-attente' : 'ex-rouge-500' }">warning
            <md-tooltip ng-if="vm.dataType.params.err && vm.data[vm.dataType.params.err]">{{vm.data[vm.dataType.params.err]}}</md-tooltip></md-icon>`;
        }
    }

    return IndicateurErreurDataType;
}
