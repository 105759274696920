import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MenuSelectionSpecialiseeController from './ex-menu-selection-specialisee.controller';

export default module('core.components.ex-menu-selection-specialisee', []).component('exMenuSelectionSpecialisee', MenuSelectionSpecialiseeFactory());

function MenuSelectionSpecialiseeFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                col: '<',
                clickAction: '<',
                menuItem: '<?',
                disabled: '<?',
                colImgExt: '<?'
            }
        ),
        require: {
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MenuSelectionSpecialiseeController,
        controllerAs: 'vm',
        template: require('./ex-menu-selection-specialisee.html')
    };
}
