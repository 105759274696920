import {module} from 'angular';
import {IFormulaire} from './formulaire.service';
import {IFormulaireItemHiddenFonction} from './formulaire-item.service';

export interface IFormulaireTabSectionsClass {
    new(col: string, sections: Array<IFormulaireTabSectionOptions>, options?: IFormulaireTabSectionsOptions): IFormulaireTabSections;
    new(sections: Array<IFormulaireTabSectionOptions>, options?: IFormulaireTabSectionsOptions): IFormulaireTabSections;
}

export interface IFormulaireTabSections {
    readonly col: string;
    readonly valuesMap: IFormulaireTabSectionsValuesMap;
    readonly sections: Array<IFormulaireTabSectionOptions>;
    readonly largeur: number;
    readonly hidden: boolean | IFormulaireItemHiddenFonction;
}

interface IFormulaireTabSectionOptions {
    lblTitre: string;
    formulaire: IFormulaire;
    icon?: string;
}

export interface IFormulaireTabSectionsOptions {
    readonly valuesMap?: IFormulaireTabSectionsValuesMap;
    readonly largeur?: number;
    readonly hidden?: boolean | IFormulaireItemHiddenFonction;
}

interface IFormulaireTabSectionsValuesMap {
    [index: number]: string|number;
}

export default module('core.services.formulaire-tab-sections', []).factory('FormulaireTabSections', FormulaireTabSectionsFactory);

/* @ngInject */
function FormulaireTabSectionsFactory() {

    class FormulaireTabSections implements IFormulaireTabSections {

        readonly col: string;
        readonly sections: Array<IFormulaireTabSectionOptions>;
        readonly largeur: number;
        readonly hidden: boolean | IFormulaireItemHiddenFonction;
        readonly valuesMap: IFormulaireTabSectionsValuesMap;

        constructor(col: string, sections: Array<IFormulaireTabSectionOptions>, options: IFormulaireTabSectionsOptions = {}) {
            if (Array.isArray(col)) {
                col = null;
                sections = arguments[0];
                options = arguments[1];
            }

            this.col = col;
            this.sections = sections;

            if (typeof options.valuesMap !== 'undefined') {
                this.valuesMap = options.valuesMap;
            }

            if (typeof options.largeur !== 'undefined') {
                this.largeur = options.largeur;
            }

            if (typeof options.hidden !== 'undefined') {
                this.hidden = options.hidden;
            }
        }
    }
    return FormulaireTabSections;
}
