import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface ISelectionMultipleAvanceeDataTypeClass {
    new(options?: ISelectionMultipleAvanceeDataTypeOptions): ISelectionMultipleAvanceeDataType;
}

export interface ISelectionMultipleAvanceeDataTypeOptions extends IDataTypeOptions {
}

export interface ISelectionMultipleAvanceeDataType extends IDataType {
    readonly hideLibelle: boolean
}

export default module('core.services.selection-multiple-avancee-data-type', []).factory('SelectionMultipleAvanceeDataType', SelectionMultipleAvanceeDataTypeFactory);

/* @ngInject */
function SelectionMultipleAvanceeDataTypeFactory(DataType: IDataTypeClass): ISelectionMultipleAvanceeDataTypeClass {
    class SelectionMultipleAvanceeDataType extends DataType implements ISelectionMultipleAvanceeDataType {
        readonly hideLibelle: boolean = true;
    }

    return SelectionMultipleAvanceeDataType;
}
