/**
 * Composant représentant un menu
 *
 */
import {IComponentOptions, module} from 'angular';
import RechercheMenuActionsController from './ex-recherche-menu-actions.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-recherche-menu-actions', []).component('exRechercheMenuActions', RechercheMenuActionsFactory());

function RechercheMenuActionsFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                multiOccurrenceFormCtrl: '<?multiOccurrenceFormCtrl'
            }
        ),
        controller: RechercheMenuActionsController,
        controllerAs: 'vm',
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        template: require('./ex-recherche-menu-actions.html')
    };
}
