import {IAttributes, IAugmentedJQuery, IComponentController, IScope, module} from 'angular';
import {IComposantInputValidation} from '../../components/ex-input-validation/ex-input-validation.controller';

export default module('core.components.ex-input-validation-message', [])
    .directive('exInputValidationMessage', InputValidationMessageFactory);


interface InputValidationMessageComposant extends IComponentController  {
    InputValidationCtrl: IComposantInputValidation
}

function InputValidationMessageFactory() {
    return {
        restrict: 'A',
        bindings: {
            when: '?',
            message: '@',
            class: '<?'
        },
        require: {
          InputValidationCtrl: '^^exInputValidation'
        },
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, vm: InputValidationMessageComposant) {
        vm.InputValidationCtrl.registerMessage(attrs.when, attrs.message);
    }
}


