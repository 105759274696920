import { IAugmentedJQuery } from 'angular';
import { IBooleanDataTypeClass } from "../../services/data-types/boolean-data-type.service";
import { IDataTypeMap } from '../../services/data-types/data-type.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantDataList } from '../ex-data-list/ex-data-list.controller';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';

interface IComposantData {
    libelles: any;
    largeur: string;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    dataSourceCtrl: IDataSourceController;
    dataListCtrl: IComposantDataList;
    col: string;
    hideVide: boolean;
    data: any;
    dataTypes: IDataTypeMap;
    libelleValeur: boolean;
    listeDetailsCss: any;
    $onInit(): void;
    setCssToChamp(): void;
}

/* @ngInject */
export default function DataController(defaults: IDefaultsService, $element: IAugmentedJQuery, BooleanDataType: IBooleanDataTypeClass) {
    const vm: IComposantData = this;
    vm.setCssToChamp = setCssToChamp;
    vm.$onInit = function $onInit() {
        vm.dataTypes = vm.dataTypes || (vm.dataSourceCtrl && vm.dataSourceCtrl.dataTypes) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.dataTypes);

        defaults(vm, {
            largeur: 100,
            largeurTexte: 30,
            hasBottomPadding: true,
            hasTopPadding: true,
            hideVide: vm.hideVide !== undefined ? vm.hideVide : false
        });

        $element.addClass('layout-row');

        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);

        if (vm.hideVide && isEmpty(vm.data ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data)[vm.col])) {
            $element.hide();
        }
    }

    function isEmpty(valeur: any) {
        return valeur === '' || valeur == null || (vm.dataTypes[vm.col] instanceof BooleanDataType && valeur === 0)
    }
    //cette fonction valide si le champ actuel fait partie de la liste listeDetailsCss provenant d'une LOV dans le backend
    function setCssToChamp() {
        //on valide si le champ dois avoir une class css paticuliere
        if (typeof vm.listeDetailsCss !== "undefined" && Array.isArray(vm.listeDetailsCss)) {
            return vm.listeDetailsCss.includes(vm.col);
        }
        return false;
    }
}
