import { module } from 'angular';
import { IComportementConfig, IComportementConfigMap } from '../resources/source-details.resource';

export interface IComportementClass {
    new(): IComportement;
    init(configs: IComportementConfigMap): IComportementMap;
}

export interface IComportementMap {
    [cols: string]: IComportement;
}

export interface IComportement {
    readonly: boolean | ((data: any) => boolean);
    required: boolean | ((data: any) => boolean);
    disabled: boolean | ((data: any) => boolean);
    hidden: boolean | ((data: any) => boolean);
    config(config: IComportementConfig): void;
}

type TypeComportement = 'readonly' | 'required' | 'disabled' | 'hidden';

export default module('core.services.comportement', []).factory('Comportement', ComportementFactory);

function ComportementFactory(): IComportementClass {
    const TYPES_COMPORTEMENT = ['readonly', 'required', 'disabled', 'hidden'];

    class Comportement implements IComportement {
        readonly: boolean | ((data: any) => boolean);
        required: boolean | ((data: any) => boolean);
        disabled: boolean | ((data: any) => boolean);
        hidden: boolean | ((data: any) => boolean);

        static init(configs: IComportementConfigMap) {

            return Object.keys(configs).reduce((comportements: IComportementMap, key: string) => {
                const colonnes: Array<string> = key.split(',');

                colonnes.forEach((colonne: string) => {
                    const col = colonne.trim().toLowerCase();
                    comportements[col] = comportements[col] || new Comportement();
                    comportements[col].config(configs[key]);
                });

                return comportements
            }, {});
        }

        config(config: IComportementConfig) {
            TYPES_COMPORTEMENT.forEach((typeComportement: TypeComportement) => {
                const valeur = config[typeComportement];

                if (typeof valeur === 'boolean') {
                    this[typeComportement] = valeur;
                } else if (typeof valeur === 'string') {
                    const functionBody = `try {
                        return (${valeur})(data)
                    } catch(err){
                        console.log('Comportement "${typeComportement}" invalide', err, data);
                        return false;
                    }`;

                    (<any>this[typeComportement]) = Function('data', functionBody);
                }
            });
        }
    }

    return Comportement;
}
