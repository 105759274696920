import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputStringController from './ex-input-string.controller';

export default module('core.components.ex-input-string', []).component('exInputString', InputStringFactory());

function InputStringFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            Binding.LIBELLES.OPTIONAL,
            Binding.SCHEMA_ITEM,
            Binding.REGLES_VALIDATION_ITEM.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            Binding.MASQUE.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.MODEL_OPTIONS.OPTIONAL,
            Binding.DATA.OPTIONAL,
            {
                titre: '@',
                required: '<?ngRequired',
                enableCount: '<?',
                exMaxlength: '<?',
                onFocus: '<?',
                onBlur: '<?',
                onValueChange: '<?'
            }
        ),
        require: {
            formCtrl: '^form'
        },
        controllerAs: 'vm',
        controller: InputStringController,
        template: require('./ex-input-string.html')
    };
}
