import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface INumberDataTypeClass {
    new(options?: INumberDataTypeOptions): INumberDataType;
}

export interface INumberDataTypeOptions extends IDataTypeOptions{
}

export interface INumberDataType extends IDataType {
    decimals: number;
}

export default module('core.services.number-data-type', []).factory('NumberDataType', NumberDataTypeFactory);

/* @ngInject */
function NumberDataTypeFactory(DataType: IDataTypeClass): INumberDataTypeClass {
    const DEFAULT_DECIMALS = 2;
    class NumberDataType extends DataType implements INumberDataType {
        readonly template: string = '{{vm.data[vm.col] | exData : vm.dataType.schemaItem : vm.dataType}}';
        readonly decimals: number;

        constructor(options: INumberDataTypeOptions = {}) {
            super(Object.assign({
                alignment: 'right'
            }, options));

            this.decimals = (options.params && typeof options.params.decimals !== 'undefined') ?
                options.params.decimals :
                DEFAULT_DECIMALS;
        }
    }

    return NumberDataType;
}
