import {IComponentController} from 'angular';

interface IComposantToggleBox extends IComponentController {
    opened: boolean;
    icon: string;
}

export default function ToggleBoxController() {
    const vm: IComposantToggleBox = this;

    vm.$onInit = function $onInit() {
        vm.opened = vm.opened || false;
        vm.icon = vm.icon || 'keyboard_arrow_down';
    }
}
