import {IComponentOptions, module} from 'angular';
import CardSaisieController from './ex-card-saisie.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-card-saisie', []).component('exCardSaisie', CardSaisieFactory());

function CardSaisieFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            Binding.FORMULAIRE,
            Binding.MONO_OCCURRENCE_OPTIONS.OPTIONAL, // si le mono-occurrence est alimentée, cela veut dire qu'il y a probablement une autre source attachée (ex: cs0007)
            Binding.OPENED.OPTIONAL,
            Binding.NO_HEADER.OPTIONAL,
            {
                name: '@'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext',
            regroupementCtrl: '^^?exRegroupement'
        },
        controller: CardSaisieController,
        controllerAs: 'vm',
        template: require('./ex-card-saisie.html')
    };
}
