import {module} from 'angular';
import {IFormulaire} from './formulaire.service';
import {IFormulaireItemHiddenFonction} from './formulaire-item.service';

export interface IFormulaireGroupeClass {
    new(lblTitre: string, formulaire: IFormulaire, options?: IFormulaireGroupeOptions): IFormulaireGroupe;
    new(formulaire: IFormulaire, options?: IFormulaireGroupeOptions): IFormulaireGroupe;
}

export interface IFormulaireGroupe {
    readonly lblTitre: string;
    readonly formulaire: IFormulaire;
    readonly largeur: number;
    readonly hidden: boolean | IFormulaireItemHiddenFonction;
}

export interface IFormulaireGroupeOptions {
    readonly largeur?: number;
    readonly hidden?: boolean | IFormulaireItemHiddenFonction;
}

export default module('core.services.formulaire-groupe', []).factory('FormulaireGroupe', FormulaireGroupeFactory);

function FormulaireGroupeFactory() {
    class FormulaireGroupe implements IFormulaireGroupe {
        readonly lblTitre: string;
        readonly formulaire: IFormulaire;
        readonly largeur: number;
        readonly hidden: boolean | IFormulaireItemHiddenFonction;

        constructor(lblTitre: string, formulaire: IFormulaire, options: IFormulaireGroupeOptions = {}) {
            if (typeof lblTitre !== 'string') {
                lblTitre = null;
                formulaire = arguments[0];
                options = arguments[1] || {};
            }

            if (options.hidden !== undefined) {
                this.hidden = options.hidden;
            }

            this.lblTitre = lblTitre;
            this.formulaire = formulaire;

            this.largeur = options.largeur || 100;
        }
    }
    return FormulaireGroupe;
}
