import {IPromise, IQService, module} from 'angular';
import {ISourceDetails, ISourceDetailsMap, ISourceDetailsResourcesClass} from '../resources/source-details.resource';
import DataCache from './data-cache.service';

export interface ISourceDetailsManager {
    fetch(srccod: string, srccodref?: string, queryParams?: any): IPromise<ISourceDetails>;
}

export default module('core.services.source-details-manager', []).factory('sourceDetailsManager', SourceDetailsManagerFactory);

/* @ngInject */
function SourceDetailsManagerFactory(SourceDetailsResource: ISourceDetailsResourcesClass, $q: IQService) {
    class SourceDetailsManager implements ISourceDetailsManager {
        fetch(srccod: string, srccodref?: string, queryParams: any = {}) {
            srccod = srccod.toLowerCase();

            if (DataCache.has('sourceDetails', srccod) && !DataCache.get('sourceDetails', srccod).libellesDynamiques) {
                return $q.resolve(DataCache.get('sourceDetails', srccod));
            } else {
                const hasSourceDetails = DataCache.has('sourceDetails', srccod);
                let sourceDetailsBase = hasSourceDetails && {[srccod]: DataCache.get('sourceDetails', srccod)};

                const params = {
                    srccod: srccod,
                    srccodref,
                    ...queryParams,
                    updateLibellesOnly: sourceDetailsBase && sourceDetailsBase[srccod].libellesDynamiques
                };

                return SourceDetailsResource.get(params).$promise.then((sourceDetails: ISourceDetailsMap) => {
                    sourceDetailsBase = sourceDetailsBase || {};
                    Object.keys(sourceDetails)
                        .filter((key: string) => !key.startsWith('$'))
                        .forEach((srccod: string) => {
                            if (sourceDetailsBase[srccod]) {
                                // Si le source-details est déjà existant, on met uniquement les libellés à jour
                                Object.assign(sourceDetailsBase[srccod].libelles, sourceDetails[srccod].libelles, {$libelles: Object.assign(sourceDetailsBase[srccod].libelles.$libelles, sourceDetails[srccod].libelles.$libelles)});
                            } else {
                                sourceDetailsBase[srccod] = sourceDetails[srccod];
                            }

                            DataCache.set('sourceDetails', srccod, sourceDetailsBase[srccod]);
                        });

                    return sourceDetailsBase[srccod];
                });
            }
        }
    }

    return new SourceDetailsManager();
}
