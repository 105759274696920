import { IComponentController } from 'angular';
import {IDefaultsService} from '../../services/utils/defaults.service';

export interface IDialogInfo extends IComponentController {
    lblTitre: string;
    lblMessage: string;
    icon: string;
    backgroundColor: string;
}

/* @ngInject */
export default function DialogInfoController(defaults: IDefaultsService) {
    const vm: IDialogInfo = this;
    const ICON_INFO = 'info';
    const lblOk = 'G_LBL_BTN_OK';
    vm.$onInit = function $onInit() {
        defaults(vm, {
            icon: ICON_INFO,
            lblCancel: lblOk,
            hideUsingCallbackOnly: false
        });
    }
}
