import {module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';
import {IApiError} from '../../interfaces/api-error.interface';
import {IMonoOccurrence} from '../mono-occurrence.service';
import {IDownloadManagerService} from '../download-manager.service';
import {INotificationHandler} from '../utils/notification-handler.service';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';

export interface IMenuItemPieceJustificativeClass {
    new(monoOccurrence: IMonoOccurrence, option?: IMenuItemPieceJustificativeOptions): IMenuItemPieceJustificative;
}

export interface IMenuItemPieceJustificative extends IMenuItem {

}

export interface IMenuItemPieceJustificativeOptions extends IMenuItemOptions{

}

export default module('core.services.menu-item-piece-justificative', []).factory('MenuItemPieceJustificative', MenuItemPieceJustificativeFactory);

/* @ngInject */
function MenuItemPieceJustificativeFactory(MenuItem: IMenuItemClass,
                                           downloadManager: IDownloadManagerService,
                                           notificationHandler: INotificationHandler,
                                           exLibelleFilter: IFilterLibelle) {

    class MenuItemPieceJustificative extends MenuItem implements IMenuItemPieceJustificative {
        constructor(monoOccurrence: IMonoOccurrence, options: IMenuItemPieceJustificativeOptions = {
            fonction: 'BOUPIEJUS'
        }) {
            const lblTitre = 'G_LBL_CYCLE_PIECE_JUSTIFICATIVE';

            super(lblTitre, (event: MouseEvent) => {
                const {cycleCleint} = monoOccurrence;
                const id = monoOccurrence.data[cycleCleint];
                const typeCycle = (cycleCleint === 'doccleint') ? 'finance' : 'rh';

                const params = {
                    srccod: monoOccurrence.srccod
                };

                const stateParams = {
                    ...monoOccurrence.stateParams,
                    ecrcleint: monoOccurrence.ecranDetails.ecrcleint
                };

                return downloadManager.download(`/cycles/${typeCycle}/piece-justificative/${id}`, params, stateParams)
                    .catch((err: IApiError) => {
                        this.afficherMessageErreur(err, event);
                    });
            }, Object.assign({
                iconButton: true,
                icon: 'attach_file',
                directionTooltip: 'bottom'
            }, options));
        }

        afficherMessageErreur(error: IApiError, event: MouseEvent) {
            notificationHandler.erreur({
                targetEvent: event,
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: exLibelleFilter('G_MSG_ERREUR_ACTION'),
                error
            });
        }
    }

    return MenuItemPieceJustificative;
}
