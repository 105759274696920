import { IComponentController } from "angular";

interface IDialogSuiviModification extends IComponentController {
    lblTitre: string;
    lblCancel: string;
    icon: string;
    data:any;
}

/* @ngInject */
export default function SuiviModificationController() {
    const vm: IDialogSuiviModification = this;
    const lblDefaultCancel = 'G_LBL_BTN_OK';
    const iconDefault = 'history';
    const lblDefaultTitre = 'G_LBL_SUIVI_MOD_TITRE';

    vm.$onInit = function $onInit() {
        vm.icon = vm.icon || iconDefault;
        vm.lblCancel = vm.lblCancel || lblDefaultCancel;
        vm.lblTitre = vm.lblTitre || lblDefaultTitre;
    }
}


