import {module} from 'angular';
import {IUpdatableResource} from '../interfaces/updatable-resource.interface';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IPreferences extends IResource<IPreferences>, IUpdatableResource<IPreferences> {
    gsfcleint: number;
    usrcleint: number;
    usrnomcre: string;
    pracleint: number;
    ciecleint: number;
    vaeusrlng: string;
    theme: string;
    gsfsetglbrepcol: number;
    gsfflgpansom: number;
    gsfflgpannav: number;
    itvcleint: number;
    gsfflgnavnonsup: number;
}

export interface IPreferencesClass extends IResourceClass<IPreferences> {
    new(data: any): IPreferences;
}

export default module('core.resources.preferences', [
    'core.services.api-config'
]).factory('Preferences', PreferencesFactory);

/* @ngInject */
function PreferencesFactory(ApiConfig: IApiConfig,
                            $resource: IResourceService): IPreferencesClass {
    const actions = {
        update: {
            method: 'put'
        }
    };
    const paramDefaults = {};

    return <IPreferencesClass>$resource(`${ApiConfig.ROOT}/profil/preferences`, paramDefaults, actions);
}
