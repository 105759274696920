import {module} from 'angular';
import { IFormulaireItemHiddenFonction } from './formulaire-item.service';

export interface IFormulaireTemplateSlotClass {
    new(slot: string, option?: IFormulaireItemOptions): IFormulaireTemplateSlot;
}

export interface IFormulaireTemplateSlot {
    slot: string;
    largeur: number;
    hidden?: boolean | IFormulaireItemHiddenFonction;
}

export interface IFormulaireItemOptions {
    largeur?: number;
    hidden?: boolean | IFormulaireItemHiddenFonction;
}

export default module('core.services.formulaire-template-slot', []).factory('FormulaireTemplateSlot', FormulaireTemplateSlotFactory);

/* @ngInject */
function FormulaireTemplateSlotFactory() {

    class FormulaireTemplateSlot implements IFormulaireTemplateSlot {
        readonly slot: string;
        readonly largeur: number = 100;
        readonly hidden?: boolean | IFormulaireItemHiddenFonction;

        constructor(slot: string, options: IFormulaireItemOptions = {}) {
            this.slot = slot;
            this.largeur = options.largeur || this.largeur;

            if (options.hidden !== undefined) {
                this.hidden = options.hidden;
            }
        }
    }
    return FormulaireTemplateSlot;
}
