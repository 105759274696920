import * as angular from 'angular';
import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IBoutonDataTypeClass {
    new(options?: IBoutonDataTypeOptions): IBoutonDataType;
}

export interface IBoutonDataTypeOptions extends IDataTypeOptions {
}

export interface IBoutonDataType extends IDataType {
}

export default module('core.services.bouton-data-type', []).factory('BoutonDataType', BoutonDataTypeFactory);

/* @ngInject */
function BoutonDataTypeFactory(DataType: IDataTypeClass): IBoutonDataTypeClass {
    class BoutonDataType extends DataType implements IBoutonDataType {
        readonly template: string = `<ex-case-bouton data="vm.data" menu="::vm.menu" col="{{::vm.col}}" data-type="::vm.dataType"></ex-case-bouton>`;
        constructor(options: IBoutonDataTypeOptions = {}) {
            super(angular.extend({alignment: 'center'}, options));

            if (options.params.toujoursVisible) {
                this.template = `<ex-menu-item data="vm.data"
                                               ng-repeat="menuItem in ::vm.menu"
                                               libelles="::vm.libelles"
                                               menu-item="::menuItem"
                                               fonction="{{::vm.mnemonique}}.BOU_{{::vm.col}}"
                                               class="ex-data-type-bouton">
                                 </ex-menu-item>`;
            }
        }
    }

    return BoutonDataType;
}
