import {IAugmentedJQuery, module} from 'angular';
import {ICouleurThemeService} from '../couleur-theme.service';

export interface IHighlightService {
    (target: IAugmentedJQuery): void;
}

/**
 * Met l'emphase sur un élément visuellement
 */
export default module('core.services.utils.highlight', []).factory('highlight', HighlightFactory);

/* @ngInject */
function HighlightFactory(couleurTheme: ICouleurThemeService): IHighlightService {
    return function highlight(element: IAugmentedJQuery): void {

        if (element.prop('tagName') === 'MD-CARD') {
            const couleurAccent = couleurTheme.getCouleur(couleurTheme.theme, 'accent', 'hue-1');
            element
                .delay(200)
                .css({background: couleurAccent})
                .children()
                .animate({opacity: .75}, 500)
                .animate({opacity: 1}, 350, () => {
                    element.css({background: ''});
                });
        } else {
            const isAccordeon = element.prop('tagName') === 'EX-ACCORDEON';
            const isRegroupement = element.prop('tagName') === 'EX-REGROUPEMENT';
            const higlightContainer = (isRegroupement) ? element.find('.ex-regroupement').first() : element.find('md-input-container');
            const targetElement = (higlightContainer.length && !isAccordeon) ? higlightContainer : element;

            if (higlightContainer.length && !isAccordeon && !isRegroupement) {
                higlightContainer.find('input,select,textarea,md-radio-group').focus();
            }

            const couleurAccent = couleurTheme.getCouleur(
                couleurTheme.theme,
                'accent',
                'hue-1',
                {alpha: .25}
            );

            targetElement
                .addClass('highlighted')
                .delay(200)
                .css({transition: 'all .5s'})
                .css({
                    boxShadow: `0 0 20px ${couleurAccent}`,
                    background: `${couleurAccent}`
                })
                .delay(500)
                .queue((next: any) => {
                    targetElement.css({
                        transition: '',
                        boxShadow: '',
                        background: ''
                    });

                    targetElement.removeClass('highlighted');
                    next();
                });
        }
    };
}
