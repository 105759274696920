/**
 * Cet helper permet de définir les bindings des composants selon les constantes définies dans `constants.BindingsConfig`.
 * Le but de cette approche est de s'assurer de l'uniformité de nos composants.
 *
 * @note Ce module n'est pas une module angular car il doit pouvoir être utilisé dans une définition de composant
 *
 * @example
 * bindings: Binding(
 *    Binding.COL,
 *    Binding.LIBELLE,
 * )
 *
 * Les interfaces de bindings peuvent être aussi spécifiées comme étant optionelle:
 *
 * @example
 * bindings: Binding(
 *    Bindings.COL.OPTIONAL
 * )
 *
 * En général, la bonne pratique est de spécifier une interface TypeScript similaire pour le ViewModel du composant,
 * dans son controller.
 */
import {BindingsConfig, IBindingConfig, IBindingsConfig} from '../constants/bindings-config.constant';

interface IBinding {
    OPTIONAL?: IBindingConfig;
    [binding: string]: string|IBindingConfig;
}

interface IBindings extends IBindingsConfig<IBinding> {}

export interface IBindingService extends IBindings {
    (...bindings: Array<IBinding>): {[binding: string]: string};
    [binding: string]: IBinding;
}

const Binding = BindingFactory();
export default Binding;

function BindingFactory() {
    const Binding: IBindingService = <IBindingService> function (...bindings: Array<IBinding>): {[binding: string]: string} {
        return Object.assign(
            <IBindings>{},
            ...bindings
        );
    };

    Object.keys(BindingsConfig).forEach((bindingName: string) => {
        Binding[bindingName] = createBinding(BindingsConfig[bindingName]);
    });

    return Binding;
}

/**
 * Ajoute une propriété non énumérable `OPTIONAL` qui permet de spécifier un binding comme optinonel
 */
function createBinding(props: IBindingConfig): IBinding {
    const newBinding : IBinding = Object.assign(<IBinding>{}, props);

    Object.defineProperty(newBinding, 'OPTIONAL', {
        value: optional(newBinding)
    });

    return newBinding;
}

/**
 * Étend les propriétés de chaque attribut du binding et les marque optionel.
 */
function optional(binding: IBinding): IBinding {
    return Object.keys(binding).reduce((result: IBinding, attribut: string) => {
        result[attribut] = (<string>binding[attribut]).replace(/([[=<@*&]+)/i, '$1?');
        return result;
    }, <IBinding>{});
}
