import * as angular from 'angular';
import {IAugmentedJQuery, ICompileService, IDirective, IScope, module} from 'angular';
import FormulaireInputListController from '../components/ex-formulaire-input-list/ex-formulaire-input-list.controller';

export default module('core.behaviors.ex-template', [])
    .service('template', TemplateFactory);

export interface ITemplateService {
    directive: IDirective;
}

export interface ITemplateController {
    templates: ITemplateControllerTemplates;
    compile(bloc: string, slot: string, element: IAugmentedJQuery, context: any): void;
}

interface ITemplateControllerTemplates {
    [bloc: string]: {
        [slot: string]: IAugmentedJQuery,
    };
}

/* @ngInject */
function TemplateFactory($compile: ICompileService): ITemplateService {
    return {
        /**
         * Cette directive doit être appliquée sur chaque composant qui veut utiliser les templates
         */
        directive: {
            restrict: 'E',
            priority: 1,
            compile(tElement: IAugmentedJQuery) {
                const templates: ITemplateControllerTemplates = {};
                tElement.children().each((index: number, element: HTMLElement) => {
                    if (element.tagName.toLowerCase() === 'ex-template') {
                        const blocAttr = (element.getAttribute('bloc') || '$NONE$').toUpperCase();
                        const slot = element.getAttribute('slot');

                        if (!slot) {
                            throw new Error('Le slot est requis pour exTemplate');
                        }

                        templates[blocAttr] = templates[blocAttr] || {};
                        templates[blocAttr][slot] = angular.element(element).clone();
                    }
                });

                const vm: ITemplateController = {
                    templates,
                    compile(bloc: string, slot: string, element: IAugmentedJQuery, context: any) {
                        const template = vm.templates[(bloc || '$NONE$').toUpperCase()][slot].clone();
                        const newScope: any = getScope(context);
                        template.appendTo(element);
                        $compile(template)(newScope);

                        // On s'assure que le scope soit bien détruit
                        template.on('$destroy', () => {
                            newScope.$destroy();
                        });
                    }
                };
                let currentScope: IScope;

                // On simule l'existence d'un controller pour pouvoir l'obtenir via "require" à partir des enfants
                tElement.data('$exTemplateController', vm);

                return function (scope: IScope) {
                    // TODO UQ This is REALLY BAD but we need this with shitty angular for templates to work in pe-ecran-container
                    // Remove this ASAP
                    currentScope = scope;
                    (scope.$root as any).templateCtrl = vm;
                };

                function getScope(context: any) {
                    const newScope: any = (context.vm) ? context.$parent.$new() : context.$new();
                    //On veut conserver le vm original dans le vm du scope
                    newScope.vm = (currentScope as any).vm;

                    // On expose certaines propriétés pour usage rapide
                    if (context.vm instanceof FormulaireInputListController) {
                        newScope.data = context.vm.data;
                        newScope.libelles = context.vm.occurrence.libelles;
                    } else {
                        // Dans le cas où on est dans un multi-occurrence,
                        // on ajoute les propriétés du scope de la rangée au scope transcludé
                        const localProps = ['data', '$index', '$first', '$middle', '$last', '$event', '$odd'];
                        Object.assign(newScope, localProps.reduce((props: any, prop: string) => {
                            props[prop] = context[prop];
                            return props;
                        }, {}));

                        newScope.multiOccurrence = context.vm.multiOccurrence;
                        if (context.vm.multiOccurrence) {
                            newScope.libelles = context.vm.multiOccurrence.libelles;
                        }
                    }
                    return newScope;
                }
            }
        }
    };
}
