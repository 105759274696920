import { IAugmentedJQuery, IComponentController, IScope, ITimeoutService } from 'angular';
import isMobile from "../../constants/mobile.constant";
import { IRoute } from '../../interfaces/route.interface';
import { IProfil, IProfilAcces } from '../../resources/profil.resource';

interface NavigationComponent extends IComponentController {
    accueilRoute: IRoute;
    profilsAcces: Array<IProfilAcces>;
    navFilter: string;
    navigationOpened: boolean;
    accueilActif: boolean;
    onClickNavigation(): void;
}

/* @ngInject */
export default function NavigationController($scope: IScope,
    $element: IAugmentedJQuery,
    profil: IProfil,
    $timeout: ITimeoutService) {
    const vm: NavigationComponent = this;

    vm.$onInit = function $onInit() {
        vm.profilsAcces = profil.profilsAcces;

        if (!isMobile) {
            $scope.$watch("vm.navigationOpened", () => {
                if (vm.navigationOpened) {
                    $timeout(initResize)
                }
            });
        }
    }

    function initResize() {
        const sidenav = $element[0].getElementsByTagName('md-sidenav')[0] as HTMLElement;
        const navigationMenuContent = $element[0].getElementsByClassName('ex-navigation-menu-content')[0] as HTMLElement;
        const resizer = $element[0].getElementsByClassName('navigation-resizer')[0];
        resizer.addEventListener('mousedown', initResize, false);

        // Utilisation de la largeur précédemment utilisée pour le menu
        const savedSizeSelector = 'navigation-menu-size';
        const savedSize = localStorage.getItem(savedSizeSelector);
        if (savedSize) {
            sidenav.style.width = savedSize;
            navigationMenuContent.style.width = Number(savedSize.replace('px', '')) - 3 + 'px';
        }

        function initResize(e: DragEvent) {
            e.stopPropagation();
            //Empêche de sélectionner du texte ou une image pendant le redimensionnement du menu
            document.getElementsByTagName('body')[0].style.userSelect = 'none';

            window.addEventListener('mousemove', resize, false);
            window.addEventListener('mouseup', stopResize, false);
        }

        function resize(e: DragEvent) {
            sidenav.style.width = e.clientX + 'px';
            navigationMenuContent.style.width = (e.clientX - 3) + 'px';
        }

        function stopResize(e: DragEvent) {
            e.stopPropagation();
            document.getElementsByTagName('body')[0].style.userSelect = null;
            window.removeEventListener('mousemove', resize, false);
            window.removeEventListener('mouseup', stopResize, false);
            localStorage.setItem(savedSizeSelector, sidenav.style.width);

            // S'assure que tous les éléments à droite du menu sont informés du redimensionnement
            $scope.$parent.$broadcast('exGridReajusterLargeurColonne');
        }
    }
}
