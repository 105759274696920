import { module } from 'angular';
import { IMenuItem, IMenuItemClass } from './menu-item.service';
import { IMenuItemActionOptions } from './menu-item-action.service';
import { IMonoOccurrence } from '../mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceClass } from '../multi-occurrence.service';
import { IApiError } from '../../interfaces/api-error.interface';
import { IDialogFormulaireOptions } from '../../dialogs/dialog-formulaire/dialog-formulaire.controller';
import { IUploadManagerService } from '../upload-manager.service';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { INotificationHandler } from '../utils/notification-handler.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IParametresSecuriteService } from '../parametres-securite.service';
import { IDialog } from '../dialog.service';
import { IOccurrence } from '../occurrence.service';

export interface IMenuItemActionImportationClass {
    new(lblTitre: string, fonction: string, parametresGetter: IMenuItemActionImportationParametres, options: IMenuItemActionImportationOptions): IMenuItemActionImportation;
}

export interface IMenuItemActionImportation extends IMenuItem {
}

interface IMenuItemActionImportationParametres {
    (data: object): object;
}

export interface IMenuItemActionImportationOptions extends IMenuItemActionOptions {
    typesFichiers?: ITypesFichiersFunction | Array<string>;
}

export interface ITypesFichiersFunction {
    (data: any): Array<string>;
}

export default module('core.services.menu-item-action-importation', []).factory('MenuItemActionImportation', MenuItemActionImportationFactory);

/* @ngInject */
function MenuItemActionImportationFactory(uploadManager: IUploadManagerService,
    MultiOccurrence: IMultiOccurrenceClass,
    DialogFormulaire: IDialog,
    DialogStatus: IDialogAllStatus,
    notificationHandler: INotificationHandler,
    parametresSecurite: IParametresSecuriteService,
    exLibelleFilter: IFilterLibelle,
    MenuItem: IMenuItemClass) {

    class MenuItemActionImportation extends MenuItem implements IMenuItemActionImportation {
        constructor(lblTitre: string, fonction: string, parametresGetter: IMenuItemActionImportationParametres, options: IMenuItemActionImportationOptions) {
            // On détermine si un lblTitre a été passé
            if (typeof arguments[1] !== 'string') {
                lblTitre = '';
                fonction = arguments[0];
                parametresGetter = arguments[1];
                options = arguments[2];
            }
            super(lblTitre,
                (event: MouseEvent, data: any, occurrence: IMonoOccurrence | IMultiOccurrence) => executeAction(event, data, parametresGetter, fonction, options, occurrence),
                { fonction, lblTitreValidation: 'G_LBL_MOD_ERREUR_TITRE', ...options });
        }
    }
    return MenuItemActionImportation;

    function executeAction(event: MouseEvent,
        data: any,
        parametresGetter: IMenuItemActionImportationParametres,
        fonction: string,
        options: IMenuItemActionImportationOptions,
        occurrence: IMonoOccurrence | IMultiOccurrence) {
        const params: any = parametresGetter(data);
        const ids = occurrence.getParentIds();
        const id = params.id || data[occurrence.cleint];

        ids.pop();

        if (id) {
            ids.push(id)
        }

        if (occurrence instanceof MultiOccurrence && occurrence.criteresSuggeres) {
            data = Object.assign({}, (<IMultiOccurrence>occurrence).etatSelected.criteresSuggeresData, data);
        }

        params.fonction = fonction;

        const securityParams: any = {
            srccod: occurrence.getNomSourceDetails(),
            ...parametresSecurite(occurrence.stateParams)
        };

        if (ids.length) {
            securityParams.ids = ids.join(',');
        }

        options.formulaireConfirmationAction.formulaire.uploadFichier = true

        const dialogOptions: IDialogFormulaireOptions = {
            lblTitre: exLibelleFilter(options.formulaireConfirmationAction.lblTitre, occurrence.libelles),
            lblConfirm: options.formulaireConfirmationAction.lblConfirm || 'G_LBL_BTN_CONFIRMER',
            lblConfirmAndNext: options.formulaireConfirmationAction.lblConfirmAndNext,
            formulaire: options.formulaireConfirmationAction.formulaire,
            icon: (typeof options.icon === 'function') ? options.icon(data) : options.icon,
            largeur: options.formulaireConfirmationAction.largeurFormulaire,
            customClass: options.formulaireConfirmationAction.customClass,
            data: { ...params, ...data },
            occurrence: occurrence,
            closeOnError: false,
            typesFichiers: options.typesFichiers,
            ecranContext: {
                stateParams: occurrence.stateParams,
                ecranDetails: occurrence.ecranDetails,
                ecranSourceDetails: occurrence.ecranSourceDetails
            },
            confirmAction: (formData: any) => {
                const bodyData = (typeof formData !== 'undefined') ? { ...(data || {}), ...formData } : { ...(data || {}) };

                return uploadManager.upload(`/bouton-action/blob/${securityParams.srccod}/${params.fonction}/${securityParams.ids}`, bodyData, { ...securityParams, ...params }, occurrence.stateParams)
                    .catch((err: IApiError) => {
                        //On prend l'erreur en charge ici pour éviter la fermetture du DialogFormulaire
                        afficherMessageErreur(err, event, occurrence);
                        return Promise.reject(err);
                    });
            }
        };

        return DialogFormulaire.show({ locals: dialogOptions })
            .then((result: any = {}) => {
                const defaultLblMessage = (options.lblMessageSuccess) ? options.lblMessageSuccess : 'G_MSG_ACTION_SUCCES';
                const msgSucces = result.msg || exLibelleFilter(defaultLblMessage);
                notificationHandler.succes(msgSucces);

                if (options.successAction) {
                    options.successAction(result);
                }

                if (options.champsRechargement) {
                    const newData = options.champsRechargement.reduce((newData: any, col: string) => {
                        if (typeof result[col] !== 'undefined') {
                            newData[col] = result[col];
                        }
                        return newData;
                    }, {});

                    occurrence.emit('exMenuItemActionDataUpdate', newData);
                }

                return result;
            })
            .catch((error: any) => {
                if (error !== DialogStatus.FERMER) {
                    afficherMessageErreur(error, event, occurrence);
                }
            })
    }

    function afficherMessageErreur(error: IApiError, event: MouseEvent, occurrence: IOccurrence) {
        notificationHandler.erreur({
            targetEvent: event,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: exLibelleFilter('G_MSG_ERREUR_ACTION'),
            error
        });
    }
}
