import { module } from 'angular';
import { IBooleanDataTypeClass } from '../data-types/boolean-data-type.service';
import { IDataTypeMap } from '../data-types/data-type.service';
import { IImputationDataTypeClass } from '../data-types/imputation-data-type.service';
import { IMenu } from '../menu/menu.service';
import { IFormulaireItemSautDeLigne } from './formulaire-item-saut-de-ligne.service';
import { IFormulaireItem, IFormulaireReadOnlyFonction } from './formulaire-item.service';
import { IFormulaireGroupeConditionnelClass } from './formulaire-groupe-conditionnel.service';
import { IFormulaireItemTexte } from './formulaire-item-texte.service';
import { IFormulaireGroupe } from './formulaire-groupe.service';
import { IFormulaireLov } from './formulaire-lov.service';

export type FormulaireElement = IFormulaireItem | IFormulaire | IFormulaireItemSautDeLigne | IFormulaireItemTexte | IFormulaireGroupe | IFormulaireLov;

export interface IFormulaireClass {
    new(lblTitre: string, liste: Array<FormulaireElement>, options?: IFormulaireOptions): IFormulaire;
    new(liste: Array<FormulaireElement>, options?: IFormulaireOptions): IFormulaire;
}
export interface IModifierOrdre {
    initialPosition: number;
}

export interface IFormulaire {
    readonly listeOption: Array<FormulaireElement>;
    readonly liste: Array<FormulaireElement>;
    readonly flatListe: Array<FormulaireElement>;
    readonly lblTitre: string;
    readonly piecesJointes: boolean;
    readonly piecesJointesCollapsed: boolean
    readonly libelleUpload?: string;
    readonly modifierOrdre?: IModifierOrdre;
    uploadFichier: boolean | ((data: any) => boolean)
    uploadFichierRequired: boolean
    securityCycle: boolean;
    menu: IMenu;
    largeurDefaut: number;
    showTitle: boolean;
    get(index: number): FormulaireElement;
    initFormData(data: any, dataTypes: IDataTypeMap): void;
}

export interface IFormulaireOptions {
    largeurDefaut?: number;
    menu?: IMenu;
    piecesJointes?: boolean;
    piecesJointesCollapsed?: boolean;
    securityCycle?: boolean;
    uploadFichier?: boolean | ((data: any) => boolean);
    uploadFichierRequired?: boolean
    libelleUpload?: string;
    showTitle?: boolean;
    readonly?: boolean | IFormulaireReadOnlyFonction | any;
    modifierOrdre?: IModifierOrdre;
}

export default module('core.services.formulaire', []).factory('Formulaire', FormulaireFactory);

/* @ngInject */
function FormulaireFactory(FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    BooleanDataType: IBooleanDataTypeClass,
    ImputationDataType: IImputationDataTypeClass) {

    class Formulaire implements IFormulaire {
        readonly listeOption: Array<FormulaireElement>;
        readonly liste: Array<FormulaireElement>;
        readonly flatListe: Array<FormulaireElement>;
        readonly lblTitre: string = '';
        readonly piecesJointes: boolean = false;
        readonly piecesJointesCollapsed: boolean
        readonly securityCycle: boolean = true;
        readonly libelleUpload: string;
        uploadFichier: boolean | ((data: any) => boolean) = false;
        uploadFichierRequired: boolean = true
        readonly modifierOrdre?: IModifierOrdre;
        menu: IMenu;
        largeurDefaut: number = 50;
        showTitle: boolean = true;

        constructor(lblTitre: string, liste: Array<FormulaireElement>, options: IFormulaireOptions = {}) {
            if (Array.isArray(lblTitre)) {
                lblTitre = null;
                liste = arguments[0];
                options = arguments[1] || {};
            }

            this.lblTitre = lblTitre || null;
            this.listeOption = liste;
            this.flatListe = this.buildFlatListe(liste);
            this.liste = this.buildFormulaire(liste, options.readonly);

            if (options.largeurDefaut) {
                this.largeurDefaut = options.largeurDefaut;
            }

            this.menu = options.menu;

            if (options.piecesJointes) {
                this.piecesJointes = options.piecesJointes;
            }

            this.piecesJointesCollapsed = options.piecesJointesCollapsed !== undefined ? options.piecesJointesCollapsed : false;

            if (options.securityCycle !== undefined) {
                this.securityCycle = options.securityCycle;
            }

            this.libelleUpload = options.libelleUpload;
            this.modifierOrdre = options.modifierOrdre || options.modifierOrdre;
            if (options.showTitle !== undefined) {
                this.showTitle = options.showTitle;
            }

            if (options.uploadFichier) {
                this.uploadFichier = options.uploadFichier
            }
            if (options.uploadFichierRequired !== undefined) {
                this.uploadFichierRequired = options.uploadFichierRequired
            }
        }

        private buildFormulaire(listeOption: Array<FormulaireElement>, readonly: boolean | IFormulaireReadOnlyFonction) {
            // Si hidden est exactement true, on n'affiche pas du tout le champ. Il peut aussi être une fonction,
            // dans lequel cas on va le cacher dynamiquement
            const liste = listeOption.filter((formulaireItem: IFormulaireItem) => formulaireItem.hidden !== true);

            if (readonly) {
                for (const item of liste) {
                    if ((item as IFormulaireItem).readonly == null) {
                        (item as IFormulaireItem).readonly = readonly;
                    }
                }
            }

            return liste;
        }

        private buildFlatListe(listeOptions: Array<FormulaireElement>) {
            function getListeFormulaireItem(liste: Array<IFormulaireItem>, formulaireElement: FormulaireElement): any {
                if ((<IFormulaireGroupe>formulaireElement).formulaire) {
                    return (<IFormulaireGroupe>formulaireElement).formulaire.listeOption.reduce(getListeFormulaireItem, liste);
                }

                liste.push(<IFormulaireItem>formulaireElement);
                return liste;
            }

            return listeOptions.reduce(getListeFormulaireItem, []);
        }

        public get(index: number) {
            return this.liste[index];
        }

        public initFormData(data: any, dataTypes: IDataTypeMap) {
            this.listeOption.forEach((formulaireItem: IFormulaireItem) => {
                if (data[formulaireItem.col] == null || formulaireItem instanceof FormulaireGroupeConditionnel) {
                    this.setFormulaireItemDefault(formulaireItem, data, dataTypes);
                }
            });
        }

        private setFormulaireItemDefault(formulaireItem: IFormulaireItem, data: any, dataTypes: IDataTypeMap) {
            if (formulaireItem.default !== undefined) {
                if (formulaireItem instanceof FormulaireGroupeConditionnel) {
                    const formulaireItemConditionel = formulaireItem as any;
                    if (data[formulaireItemConditionel.col] === undefined) {
                        data[formulaireItemConditionel.col] = this.getDefaultValue(formulaireItem, data) ? 1 : 0;
                    }
                } else if (dataTypes[formulaireItem.col] instanceof BooleanDataType) {
                    data[formulaireItem.col] = Number(this.getDefaultValue(formulaireItem, data));
                } else if (dataTypes[formulaireItem.col] instanceof ImputationDataType && formulaireItem.default !== null) {
                    data[formulaireItem.col] = (typeof this.getDefaultValue(formulaireItem, data) === 'string') ? this.getDefaultValue(formulaireItem, data) : "";
                } else {
                    data[formulaireItem.col] = this.getDefaultValue(formulaireItem, data);
                    if (Array.isArray(data[formulaireItem.col]) && data[formulaireItem.col].length === 2) {
                        data[formulaireItem.col + '_min'] = data[formulaireItem.col][0];
                        data[formulaireItem.col + '_max'] = data[formulaireItem.col][1];
                    }
                }
            } else if (dataTypes[formulaireItem.col] instanceof BooleanDataType) {
                data[formulaireItem.col] = data[formulaireItem.col] ? 1 : 0;
            }
        }

        private getDefaultValue(formulaireItem: IFormulaireItem, data: any) {
            if (formulaireItem.default instanceof Function) {
                return formulaireItem.default(data);
            } else {
                return formulaireItem.default;
            }
        }
    }

    return Formulaire;
}
