import {IComponentController} from 'angular';
import {IMonoOccurrence} from '../../services/mono-occurrence.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {
    IMenuItemPieceJustificative,
    IMenuItemPieceJustificativeClass
} from '../../services/menu/menu-item-piece-justificative.service';
import {IMenuItem} from '../../services/menu/menu-item.service';
import {IMenuItemGenererPieceJustificativeClass} from '../../services/menu/menu-item-generer-piece-justificative.service';

export interface IDialogCycleEvenementInformationsController extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    menuItemPieceJustificative: IMenuItemPieceJustificative;
    menuItemGenererPieceJustificative: IMenuItem;
}

/* @ngInject */
export default function DialogCycleEvenementInformationsController(MenuItemPieceJustificative: IMenuItemPieceJustificativeClass,
                                                                   MenuItemGenererPieceJustificative: IMenuItemGenererPieceJustificativeClass) {
    const vm: IDialogCycleEvenementInformationsController = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.menuItemPieceJustificative = new MenuItemPieceJustificative(vm.monoOccurrence);
        vm.menuItemGenererPieceJustificative = new MenuItemGenererPieceJustificative(vm.monoOccurrence, vm.ecranContextCtrl);
    }
}
