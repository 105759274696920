/**
 * Comportement permettant de valider le type de donnée d'un champ.
 * Valeur attendue: Type de donnée
 *      1: Lettres seulement(tirets acceptés)
 *      2: Chiffres seulement(tirets acceptés)
 *      3: Tous les caractères sont acceptés
 */
import {IAttributes, IAugmentedJQuery, INgModelController, IScope, module} from 'angular';

export default module('core.behaviors.ex-type-donnee', [])
    .directive('exTypeDonnee', TypeDonneeDirective);

interface ITypeDonneeAttributes extends IAttributes {
    exTypeDonnee: string;
}
/* @ngInject */
function TypeDonneeDirective() {
    return {
        restrict: 'A',
        link,
        require: 'ngModel'
    };

    function link($scope: IScope, tElement: IAugmentedJQuery, attrs: ITypeDonneeAttributes, ngModelCtrl: INgModelController) {
        const typeDonnee = attrs.exTypeDonnee;

        //Si la valeur passée est invalide ou que tous les caractères sont acceptés, on arrête ici.
        if (typeDonnee !== '1' && typeDonnee !== '2') {
            return true;
        } else {
            if (typeDonnee === '1') {
                //On ajoute la validation du type de donnée au champ.
                ngModelCtrl.$validators['typedonneealpha'] = ($modelValue, $viewValue) => {
                    const value = $modelValue || $viewValue;
                    return !value ? true : RegExp('^[a-zA-Z-]+$').test(value);
                };
            } else {
                //On ajoute la validation du type de donnée au champ.
                ngModelCtrl.$validators['typedonneenum'] = ($modelValue, $viewValue) => {
                    const value = $modelValue || $viewValue;
                    return !value ? true : RegExp('^[0-9-]+$').test(value);
                };
            }
        }
    }
}
