import {IComponentController, IFormController, IScope} from 'angular';

export interface IComposantFormulaireLov extends IComponentController {
    dataList: Array<any>;
    formCtrl: IFormController;
    formCtrls: Array<IFormController>;
    data: any;
    col: string;
}

/* @ngInject */
export default function FormulaireLovController($scope: IScope) {
    const vm: IComposantFormulaireLov = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.dataList = vm.data[vm.col];
        vm.formCtrls = [];

        $scope.$watch(() => vm.formCtrls.every((form: IFormController) => form.$valid), (isValid: boolean) => {
            vm.formCtrl && vm.formCtrl[vm.col].$setValidity('captureInvalide', isValid);
        });
    }
}
