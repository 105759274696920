import { module } from 'angular';
import { IApiConfig } from '../interfaces/api-config.interface';
import { IParametresSecuriteService } from './parametres-securite.service';
import { IStateParamsService } from 'angular-ui-router';
import { INotificationHandler } from './utils/notification-handler.service';
import IResourceService = angular.resource.IResourceService;

export default module('core.services.is-forage-valide', []).factory('isForageValide', IsForageValideFactory);

export interface IIsForageValideService {
    isForageValide(ecrcodParent: string, ecrcodEnfant: string): boolean;
}

// Service temportaire pour empêcher les forages si l'écran n'est pas développé dans SAFIRH 2

/* @ngInject */
function IsForageValideFactory(ApiConfig: IApiConfig,
                                parametresSecurite: IParametresSecuriteService,
                                $stateParams: IStateParamsService,
                                $resource: IResourceService,
                                notificationHandler: INotificationHandler) {
    class IsForageValide {
        public readonly forages: {[key: string]:{ [key: string]: boolean }} = {};

        isForageValide(ecrcodParent: string, ecrcodEnfant: string) {
            if (!this.forages[ecrcodParent]) {
                this.forages[ecrcodParent] = {};
            }

            if (this.forages[ecrcodParent][ecrcodEnfant] == undefined) {
                this.forages[ecrcodParent][ecrcodEnfant] = false;
                const favorisResource = $resource(ApiConfig.ROOT + '/is-forage-valide/:ecrcod', {
                    ...parametresSecurite($stateParams)
                });
            
                favorisResource.get({ecrcod: ecrcodEnfant.toUpperCase() + '-00-2'}).$promise.then((res) => {
                    this.forages[ecrcodParent][ecrcodEnfant] = res.valide;
                }).catch((err) => {
                    notificationHandler.erreur({
                        lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                        lblMessage: 'G_MSG_ERREUR_ACTION'
                    });
                });
            }

            return this.forages[ecrcodParent][ecrcodEnfant];
        }
    }

    return new IsForageValide();
}
