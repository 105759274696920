/**
 * (PM1) Composant représentant une card contenant un multi-occurrence.
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CardMultiAdvancedController from './ex-card-multi-advanced.controller';

export default module('core.components.ex-card-multi-advanced', []).component('exCardMultiAdvanced', CardMultiAdvancedFactory());

function CardMultiAdvancedFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.OPENED.OPTIONAL,
            Binding.COLLAPSABLE.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            {
                lblTitre: '@',
                multiOccurrence: '=?multiOccurrenceOut'
            }
        ),
        require: {
          monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran'
        },
        transclude: {
            actions: '?exCardMultiAdvancedActions'
        },
        controller: CardMultiAdvancedController,
        controllerAs: 'vm',
        template: require('./ex-card-multi-advanced.html')
    };
}
