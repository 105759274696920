import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IDateHeureDataTypeClass {
    new(options?: IDateHeureDataTypeOptions): IDateHeureDataType;
}

export interface IDateHeureDataTypeOptions extends IDataTypeOptions{
}

export interface IDateHeureDataType extends IDataType {
}

export default module('core.services.date-heure-data-type', []).factory('DateHeureDataType', DateHeureDataTypeFactory);

/* @ngInject */
function DateHeureDataTypeFactory(DataType: IDataTypeClass): IDateHeureDataTypeClass {
    class DateHeureDataType extends DataType implements IDateHeureDataType {
        readonly template: string = `{{vm.data[vm.col] | exDateHeure}}`;
    }

    return DateHeureDataType;
}
