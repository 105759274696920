import * as angular from 'angular';
import { IAngularEvent, IAugmentedJQuery, IPromise, IQService, IRootScopeService, IWindowService, module } from 'angular';
import { IComposantFormulaire } from '../components/ex-formulaire/ex-formulaire.controller';
import { IOccurrence } from './occurrence.service';
import { IMultiOccurrenceClass } from './multi-occurrence.service';
import { IMonoOccurrenceClass } from './mono-occurrence.service';
import { IDialog } from './dialog.service';
import { IState, IStateService } from 'angular-ui-router';
import { ISchemaItem, ISchemas } from '../interfaces/schemas.interface';
import { IDataType, IDataTypeMap } from './data-types/data-type.service';
import { IHeureDataTypeClass } from './data-types/heure-data-type.service';
import * as moment from 'moment';
import IInjectorService = angular.auto.IInjectorService;
import { IRoute } from '../interfaces/route.interface';
import { IProfil } from '../resources/profil.resource';
import { IAppTitreHelper } from './app-titre-helper.service';
import { IBooleanDataTypeClass } from './data-types/boolean-data-type.service';

export default module('core.providers.changement-manager', [
]).service('changementManager', ChangementManagerFactory);

export interface IChangementManager {
    getPreviousURL(): string;
    watchStateChange(target: any): () => void;
    unwatchStateChange(target: any): void;
    confirmerQuitterPage(element: IAugmentedJQuery): IPromise<any>;
    getPremierFormulaireNonSauvegarde(element: IAugmentedJQuery): HTMLElement;
    compareChangements(data1: any, data2: any, schema: ISchemas, dataTypes: IDataTypeMap): boolean;
}

/* @ngInject */
function ChangementManagerFactory(appName: string,
    $injector: IInjectorService,
    $q: IQService,
    DialogConfirm: IDialog,
    $rootScope: IRootScopeService,
    $state: IStateService,
    $window: IWindowService,
    HeureDataType: IHeureDataTypeClass,
    accueilRoute: IRoute,
    profil: IProfil,
    AppTitreHelper: IAppTitreHelper,
    BooleanDataType: IBooleanDataTypeClass) {
    let previousURL: string = '';

    class ChangementManager implements IChangementManager {
        watchers: Array<any> = [];
        stateChangeConfirmed: boolean = false;

        constructor() {
            $rootScope.$on('$stateChangeSuccess', () => {
                this.resetWatchers();

                if ($state.current.name === accueilRoute.NAME) {
                    AppTitreHelper.setTitrePage({ titreApp: AppTitreHelper.getNomAppTitre(appName), cienomabr: profil.compagnie.cienomabr });
                }
            });

            $rootScope.$on('$locationChangeStart', function (event, current, previous) {
                previousURL = previous;
            });

            $rootScope.$on('$stateChangeStart', (event: IAngularEvent, toState: IState, toParams: any, fromState: IState, fromParams: any) => {
                if (this.watchers.length && !this.stateChangeConfirmed && toParams.verifierChangements !== false) {
                    this.verifyChangements(event, fromState, fromParams).then(() => {
                        if (event.defaultPrevented) {
                            this.stateChangeConfirmed = true;
                            if (previousURL.endsWith(decodeURIComponent($state.href(toState.name, toParams)))) {
                                $window.history.back();
                            } else {
                                $state.go(toState.name, toParams);
                            }
                        }
                    });
                }
            });
        }

        getPreviousURL(): string {
            return previousURL;
        }

        watchStateChange(target: any) {
            this.watchers.push(target);

            return () => {
                this.unwatchStateChange(target);
            };
        }

        unwatchStateChange(target: any) {
            const index = this.watchers.indexOf(target);

            if (index !== -1) {
                this.watchers.splice(index, 1);
            }
        }

        private resetWatchers() {
            this.watchers = [];
            this.stateChangeConfirmed = false;
        }

        private verifyChangements(event: IAngularEvent, fromState: IState, fromParams: any) {
            return this.verifyChangement(event, fromState, fromParams, this.watchers.length - 1);
        }

        verifyChangement(event: IAngularEvent, fromState: IState, fromParams: any, index: number): IPromise<any> {
            if (index < 0) {
                return $q.resolve();
            }

            const target = this.watchers[index];

            if (target instanceof Function) {
                target();
                return this.verifyChangement(event, fromState, fromParams, index - 1);
            } else {
                if (this.getPremierFormulaireNonSauvegarde(target)) {
                    event.preventDefault();
                    $state.go(fromState, fromParams);

                    return this.showQuitterPageConfirmation().then(() => {
                        if (index > 0) {
                            return this.verifyChangement(event, fromState, fromParams, index - 1)
                        }
                    });
                } else {
                    return this.verifyChangement(event, fromState, fromParams, index - 1);
                }
            }
        }

        getPremierFormulaireNonSauvegarde(element: IAugmentedJQuery) {
            const MonoOccurrence: IMonoOccurrenceClass = $injector.get('MonoOccurrence');
            const MultiOccurrence: IMultiOccurrenceClass = $injector.get('MultiOccurrence');

            const formulaires = element.find('ex-formulaire:not(.ex-criteres-suggeres-formulaire):not(.ex-grid-formulaire--readonly)');

            return Array.from(formulaires).find((element: HTMLElement) => {
                const formulaire = angular.element(element);
                const controller: IComposantFormulaire = formulaire.controller('exFormulaire');
                const formData = controller.formData;

                if (controller.criteresSuggeresCtrl) {
                    //On ne valide pas les critères suggérés
                    return false;
                }

                const occurrence: IOccurrence = controller.occurrence;

                if (occurrence instanceof MonoOccurrence) {
                    if (occurrence.fonctions.skipChangementsNonSauvegardes) return false;

                    // 'hasChangementsNonSauvegardes' ne fonctionne pas sur les nouveaux enregistrements
                    if (!formData[occurrence.cleint]) return controller.formCtrl.$dirty;

                    return occurrence.hasChangementsNonSauvegardes(formData);
                } else if (occurrence instanceof MultiOccurrence) {
                    if (occurrence.modeEditionRapide && typeof controller.indexFormulaire !== 'undefined') {
                        // Validation pour les lignes d'édition rapide
                        return controller.formCtrl.$dirty || occurrence.hasChangementsNonSauvegardes(formData, controller.indexFormulaire)
                    } else if (!formData[occurrence.cleint]) {
                        // 'hasChangementsNonSauvegardes' ne fonctionne pas sur les nouveaux enregistrements
                        return controller.formCtrl.$dirty;
                    } else {
                        const index = occurrence.dataList.findIndex((data: any) => data[occurrence.cleint] === formData[occurrence.cleint]);
                        if (index !== -1) {
                            return occurrence.hasChangementsNonSauvegardes(formData, index);
                        } else {
                            return false;
                        }
                    }
                }
            });
        }

        confirmerQuitterPage(element: IAugmentedJQuery) {
            //si un formulaire n'existe pas on verifie l'erreur de recherche
            try {
                if (this.getPremierFormulaireNonSauvegarde(element)) {
                    return this.showQuitterPageConfirmation();
                } else {
                    return $q.resolve();
                }
            } catch (ex) {
                return $q.resolve();
            }
        }

        compareChangements(data1: any, data2: any, schema: ISchemas, dataTypes: IDataTypeMap) {
            if (data1 instanceof Object) {
                return Object.keys(data1)
                    .filter((col) => !col.startsWith('t_') && dataTypes[col])
                    .some((col) => {
                        return (data1[col] || data2[col] || dataTypes[col]?.schemaItem?.type === "number") &&
                            this.compareChangementsString(data1[col], data2[col], dataTypes[col]) &&
                            !this.compareEmptyFields(data1[col], data2[col], dataTypes[col]) &&
                            !this.compareChangementsStringLength(data1[col], data2[col]) &&
                            !this.compareChangementsDate(data1[col], data2[col], schema[col], dataTypes[col]) &&
                            !this.compareChangementsBoolean(data1[col], data2[col], dataTypes[col]);
                    });
            }
            else {
                return false
            }

        }

        private compareChangementsBoolean(value1: any, value2: any, dataType: IDataType): boolean {
            if (!(dataType instanceof BooleanDataType)) {
                return false;
            }

            return Boolean(value1) === Boolean(value2);
        }

        private compareEmptyFields(value1: any, value2: any, dataType: IDataType): boolean {
            //s'il s'agit d'un numero et que le champ est vide on active le bouton enregistrer
            if (dataType && dataType.schemaItem && dataType?.schemaItem?.type === "number" && typeof value1 === "string" && value1.trim() === '') {
                return false
            }
            return value1 == null && value2 == null;
        }

        private compareChangementsString(value1: any, value2: any, dataType: IDataType) {
            if (dataType && dataType.schemaItem && dataType?.schemaItem?.type == "number" && typeof value1 === "string" && value1.trim() === '') {
                return true
            }
            return (typeof value1 !== typeof value2) || ((value1 || value1 === 0 || value1 === '') && (value2 || value2 === 0 || value2 === '') && value1.toString().trim() !== value2.toString().trim());
        }

        private compareChangementsStringLength(value1: any, value2: any) {
            return typeof value1 === 'string' && value2 === null && !value1.length;
        }

        private compareChangementsDate(value1: any, value2: any, schemaItem: ISchemaItem, dataType: IDataType) {
            if (!schemaItem || schemaItem.type !== 'date') {
                return false;
            }

            // Heure qui utilisent un champ de type date côté bd
            if (dataType instanceof HeureDataType) {
                const format = this.getHourFormat(dataType);
                const heure1 = moment(value1).format(format);
                const heure2 = moment(value2).format(format);

                return heure1 === heure2;
            }

            const time1 = new Date(value1).getTime();
            const time2 = new Date(value2).getTime();

            return time1 && time1 === time2;
        }

        private getHourFormat(dataType: IDataType) {
            const format = ['HH'];

            if (dataType.params.minutes) {
                format.push('mm');
            }

            if (dataType.params.secondes) {
                format.push('ss');
            }

            return format.join(':');
        }

        private showQuitterPageConfirmation() {
            /*let lblTitre = "G_LBL_TITRE_MODIFICATION_EN_COURS"
            let lblDescription = "G_MSG_QUITTER_MODIFICATION"
            let lblCancel = 'G_LBL_NON'
            let lblConfirm = 'G_LBL_OUI'
            try {
                const objPreferences: any = profil.preferences;
                if (objPreferences.vaesysapp == "EMP2") {
                    lblTitre = "G_LBL_QUITTER_SS"
                    lblDescription = "G_MSG_QUITTER_MODIFICATION_SS"
                    lblCancel = "G_LBL_BTN_RETOURNER"
                    lblConfirm = "G_BTN_QUITTER_SS"
                }
            } catch (error) {
            }*/
            return DialogConfirm.show({
                locals: {
                    lblTitre: 'G_LBL_TITRE_MODIFICATION_EN_COURS',
                    lblDescription: 'G_MSG_QUITTER_MODIFICATION',
                    lblConfirm: 'G_BTN_QUITTER_SS',
                    lblCancel: 'G_LBL_BTN_RETOURNER',
                    icon: 'warning',
                    setMaxWidth: true
                }
            });
        }
    }

    return new ChangementManager();
}
