import {IDataSourceController} from '../ex-data-source/ex-data-source.component';
import {IAugmentedJQuery, IComponentController} from 'angular';

interface IComposantCaseCoche extends IComponentController {
    col: string;
    dataSourceCtrl: IDataSourceController;
    alignment: string;
    hideVide: boolean;
}

/* @ngInject */
export default function CaseCocheController($element: IAugmentedJQuery) {
    const vm: IComposantCaseCoche = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.alignment = vm.alignment || 'left';
        $element.css('float', vm.alignment);

        // Si la case de coche est vide, on la cache
        if (vm.hideVide && vm.col && !vm.dataSourceCtrl.data[vm.col]) {
            $element.hide();
        }
    }
}
