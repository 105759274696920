import {module} from 'angular';
import DialogSelectionInterventionController from './dialog-selection-intervention.controller';
import {IDialogClass} from '../../services/dialog.service';

export default module('core.dialogs.dialog-selection-intervention', [
    'core.services.dialog'
]).factory('DialogSelectionIntervention', DialogSelectionInterventionFactory);

/* @ngInject */
function DialogSelectionInterventionFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogSelectionInterventionController,
        controllerAs: 'vm',
        focusOnOpen: false,
        bindToController: true,
        template: require('./dialog-selection-intervention.html')
    });
}
