/**
 * Composant représentant la fonction transversale du cycle evenement
 */
import {module} from 'angular';
import DialogCycleEvenementHistoriqueController from './dialog-cycle-evenement-historique.controller';
import { IDialogClass } from '../../services/dialog.service';

export default module('app.dialogs.dialog-cycle-evenement-historique', [
    'core.services.dialog'
]).factory('DialogCycleEvenementHistorique', DialogCycleEvenementHistoriqueFactory);

/* @ngInject */
function DialogCycleEvenementHistoriqueFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogCycleEvenementHistoriqueController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-cycle-evenement-historique.html'),
        locals: {
            icon: 'history',
            lblTitre: "G_LBL_HISTORIQUE_CYCLE_EVENEMENT",
            lblCancel: 'G_LBL_BTN_FERMER'
        }
    });
}
