import * as angular from 'angular';
import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import {IParametresSecuriteService} from './parametres-securite.service';
import IPromise = angular.IPromise;
import IQResolveReject = angular.IQResolveReject;
import IQService = angular.IQService;
import IDocumentService = angular.IDocumentService;
import ICookiesService = angular.cookies.ICookiesService;
import IIntervalService = angular.IIntervalService;
import IHttpParamSerializer = angular.IHttpParamSerializer;
import {IEcranDetailsResourcesEntite} from '../resources/ecran-details.resource';
import {IStateParamsService} from 'angular-ui-router';

export interface IDownloadManagerService {
    download(target: string, params?: any, stateParams?: IStateParamsService, ecranDetails?: IEcranDetailsResourcesEntite): IPromise<any>;
    directDownload(target: string, params?: any, stateParams?: any, ecranDetails?: IEcranDetailsResourcesEntite): IPromise<any>;
}

export default module('core.services.download', []).factory('downloadManager', DownloadManagerFactory);

/* @ngInject */
function DownloadManagerFactory($q: IQService,
                                ApiConfig: IApiConfig,
                                $document: IDocumentService,
                                $cookies: ICookiesService,
                                $interval: IIntervalService,
                                $httpParamSerializer: IHttpParamSerializer,
                                parametresSecurite: IParametresSecuriteService): IDownloadManagerService {

    class DownloadManager implements IDownloadManagerService {
        download(target: string, params: any = {}, stateParams: any = {}, ecranDetails?: IEcranDetailsResourcesEntite): IPromise<any> {
            return this.directDownload(target, params, stateParams, ecranDetails);
        }

        directDownload(target: string, params: any = {}, stateParams: any, ecranDetails?: IEcranDetailsResourcesEntite) {
            const cookieName = 'download-' + Math.random().toString().replace('0.', '');
            params.cookieName = cookieName;
            const serializeParams = $httpParamSerializer({
                ...Object.keys(params).reduce((result: any, param) => {
                    if (!param.startsWith('$')) {
                        result[param] = params[param];
                    }
                    return result;
                }, {}),
                ...parametresSecurite(stateParams, ecranDetails)
            });

            const targetUrl = `${ApiConfig.ROOT}${target}?${serializeParams}`;

            return this.downloadInIframe(targetUrl, cookieName);
        }

        private downloadInIframe(targetUrl: string, callbackCookie: string) {
            const iframe = angular.element(`<iframe src="${targetUrl}" style="position: absolute; left: -1000px;">`);
            $document.find('body').append(iframe);

            const delay = 200;
            // Max 10 minute
            const count = 3000;
            // Pour éviter des digests pour rien, on va s'en occuper nous même
            const invokeApply = false;

            // Il n'est pas possible d'ajouter un callback à un téléchargement, alors on attend qu'un cookie
            // soit défini par la requête.
            return new $q((resolve: IQResolveReject<any>, reject: IQResolveReject<any>) => {
                const interval = $interval(() => {
                    const result = $cookies.get(callbackCookie);
                    if (result) {
                        $interval.cancel(interval);
                        if (result === 'error') {
                            reject();
                        } else {
                            resolve();
                        }
                    }
                }, delay, count, invokeApply)
            }).finally(() => {
                iframe.remove();
            });
        }
    }

    return new DownloadManager();
}
