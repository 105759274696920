import {module} from 'angular';

export interface IDefaultsService {
    (target: any, object: any): any;
}

export default module('core.services.utils.defaults', []).factory('defaults', defaultsFactory);

/* @ngInject */
function defaultsFactory() : IDefaultsService {
    return function defaults(target: any, object: any): any {
        for (let prop in object) {
            if (target[prop] == null) {
                target[prop] = object[prop];
            }
        }
        return target;
    };
}
