import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CycleListActionsEtapesController from './ex-cycle-list-actions-etapes.controller';

export default module('app.components.ex-cycle-list-actions-etapes', []).component('exCycleListActionsEtapes', CycleListActionsEtapesFactory());

function CycleListActionsEtapesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: CycleListActionsEtapesController,
        controllerAs: 'vm',
        template: require('./ex-cycle-list-actions-etapes.html')
    };
}
