import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MultiOccurrenceController from './ex-multi-occurrence.controller';

export default module('core.components.ex-multi-occurrence', []).component('exMultiOccurrence', MultiOccurrenceFactory());

function MultiOccurrenceFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                lblRecherche: '@?'
            }
        ),
        transclude: {
            content: 'exMultiOccurrenceContent',
            trier: '?exMultiOccurrenceTrier'
        },
        require: {
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '^^?exMonoOccurrenceEcran'
        },
        controller: MultiOccurrenceController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence.html')
    };
}
