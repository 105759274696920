import {module} from 'angular';
import {IMenuItem} from './menu-item.service';

export interface IMenuItemFactoryClass {
    new(callback: IMenuItemFactoryCallback): IMenuItemFactoryFactory;
}

export interface IMenuItemFactoryFactory {
    callback: IMenuItemFactoryCallback
}

interface IMenuItemFactoryCallback {
    (data: any): IMenuItem;
}

export default module('core.services.menu-item-factory', []).factory('MenuItemFactory', MenuItemFactoryFactory);

/* @ngInject */
function MenuItemFactoryFactory() {

    class MenuItemFactory implements IMenuItemFactoryFactory {
        public callback: IMenuItemFactoryCallback;

        constructor(callback: IMenuItemFactoryCallback) {
            this.callback = callback;
        }
    }
    return MenuItemFactory;
}
