import {ISCEService, module} from 'angular';

export default module('core.filters.ex-trusted-html', []).filter('exTrustedHtml', trustedHtmlFilter);

export interface IFilterTrustedHtml {
    (value: string): any;
}

/* @ngInject */
function trustedHtmlFilter($sce: ISCEService): IFilterTrustedHtml {
    return (value: string): any => {
        return $sce.trustAsHtml(value);
    }
}
