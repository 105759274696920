class DataCache {
    cache: any = {};
    set(type: string, cle: string, data: any): void {
        type = type.toUpperCase();
        this.cache[type] = this.cache[type] || {};
        this.cache[type][cle] = data;
    }

    get(type: string, cle: string): any {
        type = type.toUpperCase();
        return this.cache[type] && this.cache[type][cle];
    }

    has(type: string, cle: string): any {
        type = type.toUpperCase();
        return Boolean(this.get(type, cle));
    }
}

export default new DataCache();
