import {IComponentOptions, module} from 'angular';
import DashboardCardController from './ex-dashboard-card.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-dashboard-card', []).component('exDashboardCard', DashboardCardFactory());

function DashboardCardFactory(): IComponentOptions {
    return {
        bindings: Binding(
            {
                left: '@?',
                right: '@?'
            }
        ),
        transclude: {
            contentLeft: '?exDashboardCardContentLeft',
            contentRight: '?exDashboardCardContentRight',
            contentBottom: '?exDashboardCardContentBottom'
        },
        controllerAs: 'vm',
        controller: DashboardCardController,
        template: require('./ex-dashboard-card.html')
    };
}
