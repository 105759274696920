import { IMultiOccurrence, IMultiOccurrenceClass } from '../../services/multi-occurrence.service';
import { ISelectionSpecialiseeDataType } from '../../services/data-types/selection-specialisee-data-type.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IComposantMultiOccurrence } from '../ex-multi-occurrence/ex-multi-occurrence.controller';
import { IComponentController } from 'angular';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IMonoOccurrence } from '../../services/mono-occurrence.service';
import { IMenuItem } from '../../services/menu/menu-item.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IComposantMenuSelectionSpecialisee extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrenceCtrl: IComposantMultiOccurrence;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    occurrenceParent: IMultiOccurrence | IMonoOccurrence;
    multiOccurrence: IMultiOccurrence;
    srccodref: string;
    col: string;
    dataType: ISelectionSpecialiseeDataType;
    boutonActif: any;
    disabled: boolean;
    menuItem: IMenuItem;
    buttonSelected: boolean;
    isDisabled(data: any): boolean;
    executeAction(event: MouseEvent, data: any): void;
    clickAction(event: MouseEvent, menuItem: IMenuItem, data: any): void;
    getUrlImage(id: number): string;
    getSecurityResourceParams(): any;
    retryErreur(): void;
    isButtonSelected(data: any): boolean;
}

/* @ngInject */
export default function MenuSelectionSpecialiseeController(MultiOccurrence: IMultiOccurrenceClass, ApiConfig: IApiConfig) {
    const vm: IComposantMenuSelectionSpecialisee = this;

    vm.$onInit = $onInit;
    vm.isDisabled = isDisabled;
    vm.getUrlImage = getUrlImage;
    vm.executeAction = executeAction;
    vm.getSecurityResourceParams = getSecurityResourceParams;
    vm.retryErreur = retryErreur;
    vm.isButtonSelected = isButtonSelected;
    function $onInit() {
        vm.srccodref = (vm.multiOccurrenceCtrl) ? vm.multiOccurrenceCtrl.multiOccurrence.getNomSourceDetails() : vm.ecranContextCtrl.stateParams.srccod
        vm.dataType = getDataType()

        initMultiOccurrence();
    }

    function isDisabled(data: any): boolean {
        if (vm.disabled) {
            return vm.disabled;
        } else {
            return vm.menuItem.disabled && vm.boutonActif === data[vm.col];
        }
    }

    function initMultiOccurrence() {
        const colonnesVisibles = [vm.col, ...vm.dataType.params.description];

        if (vm.dataType.valeurs) {
            colonnesVisibles.push(...vm.dataType.valeurs)
        }

        vm.multiOccurrence = new MultiOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            resourceParams: {
                srccod: vm.srccodref
            },
            srccod: `lov-${vm.dataType.params.source}`,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            colonnesVisibles
        })
        vm.buttonSelected = false;
    }

    function getDataType(): ISelectionSpecialiseeDataType {
        vm.occurrenceParent = (vm.multiOccurrenceCtrl) ? vm.multiOccurrenceCtrl.multiOccurrence : vm.monoOccurrenceCtrl.monoOccurrence;
        return <ISelectionSpecialiseeDataType>vm.occurrenceParent.dataTypes[vm.col];
    }

    function getUrlImage(id: number): string {
        if (vm.dataType.image) {
            const colImage = vm.dataType.image.blob;
            const colExtension = vm.dataType.image.ext;
            return `${ApiConfig.ROOT}/image/${vm.multiOccurrence.getNomSourceDetails()}/${id}/${colImage}/${colExtension}`;
        }
    }

    function getSecurityResourceParams(): any {
        return {
            srccodref: vm.srccodref
        }
    }

    function executeAction(event: MouseEvent, boutonData: any) {
        vm.boutonActif = boutonData[vm.col];
        const data = { [vm.col]: boutonData[vm.col] };

        vm.dataType.params.description.forEach((colDescription) => {
            data[`${vm.col}__${colDescription}`] = boutonData[colDescription];
        });

        if (vm.dataType.valeurs) {
            vm.dataType.valeurs.forEach((colValeur) => {
                data[colValeur] = boutonData[colValeur];
            });
        }

        vm.clickAction(event, vm.menuItem, data);
    }

    function isButtonSelected(data: any) {
        if (vm.disabled) {
            vm.buttonSelected = false;
        } else {
            vm.buttonSelected = isDisabled(data);
        }
        return vm.buttonSelected;
    }
    function retryErreur(): void {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }
}
