import IMenuService = angular.material.IMenuService;
import {IAugmentedJQuery, IComponentController, IQService, IScope, ITimeoutService} from 'angular';
import {IMenu, IMenuClass} from '../../services/menu/menu.service';
import {IMenuItem, IMenuItemClass} from '../../services/menu/menu-item.service';
import {IMenuDividerClass} from '../../services/menu/menu-divider.service';
import {IMenuSectionClass} from '../../services/menu/menu-section.service';
import {IMenuMessageClass} from '../../services/menu/menu-message.service';
import {IMenuErreurClass} from '../../services/menu/menu-erreur.service';
import {
    INotificationErreurOptions,
    INotificationHandler
} from '../../services/utils/notification-handler.service';

interface IComposantButtonMulti extends IComponentController {
    busy: boolean;
    action: IMenu|IMenuItem;
    classname: string;
    disabled: boolean;
    enregistrements: any;
    isMenuItem(menuItem: any): boolean;
    isMenuDivider(menuItem: any): boolean;
    isMenuSection(menuItem: any): boolean;
    isSecondaryButton(): boolean;
    isMenuMessage(menuItem: any): boolean;
    isMenuErreur(menuItem: any): boolean;
    onMenuOpen(openMenu: Function, event: MouseEvent, isOpened: boolean): void;
    onItemClick(menuItem: IMenuItem, event: MouseEvent): void;
}

/* @ngInject */
export default function ButtonMultiController(Menu: IMenuClass,
                                              MenuItem: IMenuItemClass,
                                              MenuDivider: IMenuDividerClass,
                                              MenuSection: IMenuSectionClass,
                                              MenuMessage: IMenuMessageClass,
                                              MenuErreur: IMenuErreurClass,
                                              $scope: IScope,
                                              $q: IQService,
                                              $mdMenu: IMenuService,
                                              $timeout: ITimeoutService,
                                              $element: IAugmentedJQuery,
                                              notificationHandler: INotificationHandler) {
    const vm: IComposantButtonMulti = this;

    vm.$onInit = $onInit;
    vm.isMenuItem = isMenuItem;
    vm.isMenuDivider = isMenuDivider;
    vm.isMenuSection = isMenuSection;
    vm.isSecondaryButton = isSecondaryButton;
    vm.isMenuMessage = isMenuMessage;
    vm.isMenuErreur = isMenuErreur;
    vm.onMenuOpen = onMenuOpen;
    vm.onItemClick = onItemClick;
    vm.numbersItems;

    function $onInit() {
        // Le bouton peut soit être basé sur un menu avec des options, ou un bouton simple sans menu
        if (vm.action instanceof Menu) {
            $scope.$on('$mdMenuOpen', (event, element) => {
                $element.addClass('ex-button-multi-container--opened');
            });

            $scope.$on('$mdMenuClose', () => {
                $element.removeClass('ex-button-multi-container--opened');
            });
        }
    }

    function isMenuItem(menuItem: any): boolean {
        return menuItem instanceof MenuItem;
    }

    function isMenuDivider(menuItem: any): boolean {
        return menuItem instanceof MenuDivider;
    }

    function isMenuSection(menuItem: any): boolean {
        return menuItem instanceof MenuSection;
    }

    function isSecondaryButton(): boolean {
        return vm.classname && vm.classname.split(' ').includes('ex-button-secondary');
    }

    function isMenuMessage(menuItem: any): boolean {
        return menuItem instanceof MenuMessage;
    }

    function isMenuErreur(menuItem: any): boolean {
        return menuItem instanceof MenuErreur;
    }

    /**
     * Gère l'ouverture du menu en prend en charge les erreurs d'ouveture
     */
    function onMenuOpen(openMenu: Function, event: MouseEvent, isOpened: boolean) {
        // Si on est en mode de menu simple, on exécute l'action tout de suite
        if (vm.action instanceof MenuItem) {
            return executeAction(vm.action, event);
        }

        if (isOpened) {
            return hideMenu();
        }

        if (vm.disabled) {
            return;
        }

        if (vm.action.onOpen) {
            executeOpenAction(openMenu, event)
                .catch((erreur: INotificationErreurOptions) => {
                    if (typeof erreur !== 'number') {
                        // On défini l'action de confirmation, sauf si l'action de confirmation existe déjà
                        if (typeof erreur.confirmAction === 'undefined') {
                            erreur.confirmAction = function() {
                                return executeOpenAction(openMenu, event)
                            };
                        }

                        notificationHandler.erreur(erreur);
                    }
                });
        } else {
            openMenu(event);
        }
    }

    /**
     * Execute l'action d'ouverture du menu
     */
    function executeOpenAction(openAction: Function, event: MouseEvent) {
        vm.busy = true;
        return $q.resolve((vm.action as IMenu).onOpen())
            .then(() => {
                // Le délai est requis pour laisser du temps au menu de se rendre, sinon il pourrait être mal aligné
                $timeout(() => {
                    openAction(event);
                }, 200);
            })
            .finally(() => {
                // Sans délai, l'icon du bouton d'action apparaît un instant avec l'icon "clear"
                $timeout(() => {
                    vm.busy = false;
                }, 300);
            });
    }

    /**
     * Gère le clique sur un item de menu
     */
    function onItemClick(menuItem: IMenuItem, event: MouseEvent) {
        menuItem.busy = true;
        executeAction(menuItem, event)
            .catch((erreur: INotificationErreurOptions) => {
                if (typeof erreur !== 'number') {
                    // On définit l'action de confirmation, sauf si l'action de confirmation existe déjà
                    if (typeof erreur.confirmAction === 'undefined') {
                        erreur.confirmAction = function () {
                            return executeAction(menuItem, event);
                        };
                    }

                    notificationHandler.erreur(erreur);
                }
            });
    }

    /**
     * Execute l'action d'un item du menu
     */
    function executeAction(menuItem: IMenuItem, event: MouseEvent) {
        menuItem.busy = true;

        return $q.resolve(menuItem.action(event, hideMenu))
            .finally(() => {
                hideMenu();
                menuItem.busy = false;
            });
    }

    function hideMenu() {
        $mdMenu.hide();
        $element.removeClass('ex-button-multi-container--opened');
    }
}
