import {IServiceProvider, module} from 'angular';
import {IRoute} from '../interfaces/route.interface';
import {IMenuNavigationItem} from '../interfaces/menu-navigation-item.interface';
import {IProfil, IProfilAcces} from '../resources/profil.resource';

export interface IRouterProvider extends IServiceProvider {
    registerRoute(route: IRoute): void;
}

export interface IRouter {
    getRouteByCode(code: string): IRoute;
    isAnyRouteForName(name: string): boolean;
    getMenuByCode(code: string, profil: IProfil): {menuItem: IMenuNavigationItem, profilAcces: IProfilAcces};
}

interface IRouteCodeMap {
    [code: string]: IRoute
}

export default module('core.providers.router', [
]).provider('Router', RouteProvider);

function RouteProvider(): IRouterProvider {
    const provider: IRouterProvider = this;

    const sourceCodeMap: IRouteCodeMap = {};
    const routesCodeMap: IRouteCodeMap = {};

    provider.registerRoute = registerRoute;

    provider.$get = RouteFactory;

    return this;

    function registerRoute(route: IRoute): void {
        if (route.ECRANS) {
            route.ECRANS.forEach(ecran => {
                sourceCodeMap[route.SOURCE] = route;
                routesCodeMap[`${route.SOURCE}-${ecran}`] = route;
            });
        }
    }

    function RouteFactory() {
        return {
            registerRoute,
            getRouteByCode,
            isAnyRouteForName,
            getMenuByCode
        };

        function getRouteByCode(code: string): IRoute {
            code = code.toUpperCase();

            return routesCodeMap[code] || sourceCodeMap[code];
        }

        function isAnyRouteForName(name: string) {
            if (!name || typeof(name)!=="string") return false;
            const code = name.split(".")[1].toUpperCase();

            return routesCodeMap[code] || sourceCodeMap[code]
        }


        /**
         * Cherche un écran dans une liste de menu
         */
        function getMenuByCode(ecrcod: string, profil: IProfil): {menuItem: IMenuNavigationItem, profilAcces: IProfilAcces} {
            let menuItem: IMenuNavigationItem;
            let profilAcces: IProfilAcces;
            let n = 0;
            while (!menuItem && n < profil.profilsAcces.length) {
                profilAcces = profil.profilsAcces[n];

                menuItem = findMenu(ecrcod, profilAcces.menus);

                n++;
            }

            return {menuItem, profilAcces};
        }

        function findMenu(ecrcod: string, navigationItems: Array<IMenuNavigationItem>): IMenuNavigationItem {
            let i = 0;

            while (i < navigationItems.length) {
                if (navigationItems[i].codref === ecrcod) {
                    return navigationItems[i];
                }

                if (navigationItems[i].items) {
                    const menuItem = findMenu(ecrcod, navigationItems[i].items);

                    if (menuItem) {
                        return menuItem;
                    }
                }

                i++;
            }
        }
    }
}
