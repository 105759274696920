import {IComponentOptions, module} from 'angular';
import PastilleFiltreController from './ex-pastille-filtre.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex--pastille-filtre', [
    'core.services.operateur'
]).component('exPastilleFiltre', PastilleFiltreFactory());

function PastilleFiltreFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            Binding.COL,
            {
                lblCol: '@?',
                lblSuffixValeur: '@?',
                lblRechercheGlobale: '<?',
                couleur: '@',
                valeur: '<',
                operateur: '<?',
                editAction: '&',
                removeAction: '&',
                supprimable: '<',
                modifiable: '<',
                isCritereSuggere: '<',
                isIgnoreEtat: '<?'
            }
        ),
        controller: PastilleFiltreController,
        controllerAs: 'vm',
        template: require('./ex-pastille-filtre.html')
    };
}
