import {IHttpInterceptor, IPromise, IRequestConfig, module} from 'angular';
import settingGlobal from "../settingGlobal.service";

export default module('core.services.interceptors.database-debug-active-interceptor', []).factory('databaseDebugActiveInterceptor', databaseDebugActiveInterceptorFactory);

function databaseDebugActiveInterceptorFactory(): IHttpInterceptor {
    return {
        request(config: IRequestConfig): IRequestConfig|IPromise<IRequestConfig> {
            if (settingGlobal.databaseDebugActive) {
                config.headers['X-Database-Debug-Active'] = settingGlobal.databaseDebugActive
            }
            return config;
        }
    };
}
