import {module} from 'angular';
import {IEcranDetailsResourcesEntite} from "../resources/ecran-details.resource";

export interface IParametresSecuriteService {
    (stateParams: any, ecranDetails?: IEcranDetailsResourcesEntite): IParametresSecuriteValeurs;
}

export interface IParametresSecuriteValeurs {
    pracleint: number;
    menucleref: number;
    ecrcleint: number;
}

export default module('core.services.parametres-securite', []).factory('parametresSecurite', ParametresSecuriteFactory);

/* @ngInject */
function ParametresSecuriteFactory(): IParametresSecuriteService {
    return function parametresSecurite(stateParams: any, ecranDetails: IEcranDetailsResourcesEntite): IParametresSecuriteValeurs {
        const [pracleint, menucleref] = (stateParams.menuId || '').split('-');

        return {
            pracleint: pracleint ? Number(pracleint) : null,
            menucleref: menucleref ? menucleref : null,
            ecrcleint: (ecranDetails && ecranDetails.ecrcleint) || stateParams.ecrcleint || null
        };
    }
}
