/**
 * Composant représentant un carte contenant le formulaire maître d'un MonoOccurrence
 *
 */
import {IComponentOptions, module} from 'angular';
import CardFormulaireMaitreController from './ex-card-formulaire-maitre.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-card-formulaire-maitre', []).component('exCardFormulaireMaitre', CardFormulaireMaitreFactory());

function CardFormulaireMaitreFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.FORMULAIRE,
            Binding.MONO_OCCURRENCE,
            {
                opened: '=?opened',
                formCtrl: '=?',
                formData: '=?',
                noHeader: '<?',
                collapsable: '<?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            content: '?exCardFormulaireMaitreContent'
        },
        controller: CardFormulaireMaitreController,
        controllerAs: 'vm',
        template: require('./ex-card-formulaire-maitre.html')
    };
}
