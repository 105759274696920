/**
 * Composante permettant d'afficher des messages d'erreurs à l'intérieur d'un accordéon
 */
import {IComponentOptions, module} from 'angular';

import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-erreur-messages-accordeon', []).component('exErreurMessagesAccordeon', ErreurMessagesAccordeonFactory());

function ErreurMessagesAccordeonFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA.OPTIONAL,
            {
                messagesErreur: '<'
            }
        ),
        controllerAs: 'vm',
        template: require('./ex-erreur-messages-accordeon.html')
    };
}

