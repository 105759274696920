import * as angular from 'angular';
import {IAugmentedJQuery, IComponentController, ITimeoutService} from 'angular';
import {IComposantMonoOccurrenceEcran} from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import { ILibellesClass } from '../../services/libelles.service';

interface IComposantSommaire extends IComponentController {
    sommaireRecherche: string;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    libelles: any;
    sections: Array<IComposantSommaireItem>;
}

export interface IComposantSommaireItem extends IComponentController {
    nom?: string;
    titre: string;
    element: IAugmentedJQuery;
    champs?: Array<IComposantSommaireItem>;
    accordeons?: Array<HTMLElement>;
}

/* @ngInject */
export default function SommaireController(defaults: IDefaultsService,
                                           $element: IAugmentedJQuery,
                                           Libelles: ILibellesClass,
                                           $timeout: ITimeoutService,
                                           exLibelleFilter: IFilterLibelle) {
    const vm: IComposantSommaire = this;
    const monoOccurrenceEcranElement = $element.closest('ex-mono-occurrence-ecran');

    const CHAMPS_SELECTEURS = [
        '[ex-sommaire-element="true"]',
        'ex-data[col]'
    ];

    vm.$onInit = $onInit;

    function $onInit() {
        defaults(vm, {
            sommaireRecherche: '',
            scrollContainer: monoOccurrenceEcranElement.find('.ex-mono-occurrence-ecran-content'),
            libelles: vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles
        });

        initSections();

        vm.monoOccurrenceEcranCtrl.monoOccurrence.on('refreshSommaire', (libelles) => {
            vm.libelles = new Libelles({...vm.libelles.$libelles, ...libelles.$libelles});
            $timeout(initSections);
        });
    }

    function initSections() {
        vm.sections = initSousSections(monoOccurrenceEcranElement);
    }

    function initSousSections(baseElement: IAugmentedJQuery, selecteur: string = 'md-card:visible,ex-regroupement:visible', regroupementBase?: any) {
        const sectionElements = Array.from(baseElement.find(selecteur));

        return sectionElements.reduce((result: any, sectionElement: HTMLElement) => {
            const element = angular.element(sectionElement);
            const section = createSection(element, regroupementBase);

            if (section) {
                result.push(section);
            }

            return result;
        }, []);
    }

    function createSection(element: JQuery<HTMLElement>, regroupementBase?: boolean): IComposantSommaireItem {
        const inMulti = element.closest('ex-multi-occurrence').length;
        const inRegroupement = element.closest('ex-regroupement').length;
        const isRegroupement = element.prop('tagName') === 'EX-REGROUPEMENT';

        if (isRegroupement) {
            const regroupementCtrl = element.controller('exRegroupement');

            return {
                titre: exLibelleFilter(regroupementCtrl.lblTitre, vm.libelles),
                champs: initSousSections(element, 'md-card', regroupementCtrl),
                element: element,
                accordeons: Array.from(element.parents('ex-accordeon'))
            };
        } else if (!inMulti && (!inRegroupement || regroupementBase)) {
            let currentAccordeonElement: HTMLElement;
            let currentAccordeon: IComposantSommaireItem;

            const champElements = getChampElementsInsideSection(element);

            const champs = champElements.reduce((result: Array<IComposantSommaireItem>, champElement: HTMLDivElement) => {
                const champ = createChamp(champElement);

                let accordeonElements = (inRegroupement) ?
                    Array.from(champ.element.parents('ex-accordeon:not(.ex-regroupement-accordeon)')) :
                    Array.from(champ.element.parents('ex-accordeon,ex-regroupement'));

                champ.accordeons = accordeonElements;

                if (accordeonElements.length > 0) {
                    const accordeonElement = accordeonElements[accordeonElements.length - 1];
                    if (accordeonElement !== currentAccordeonElement) {
                        currentAccordeonElement = accordeonElement;
                        currentAccordeon = createAccordeon(angular.element(currentAccordeonElement));

                        result.push(currentAccordeon);
                    }

                    currentAccordeon.champs.push(champ);

                    return result;
                } else {
                    currentAccordeonElement = null;
                    currentAccordeon = null;
                }

                result.push(champ);

                return result;
            }, []);

            const nomSection = element.find('> md-card-title .ex-card-title-text-lbl').text().trim();

            let sectionAccordeons: Array<HTMLElement>;
            if (inRegroupement) {
                sectionAccordeons = Array.from(element.parents('ex-accordeon'))
            }

            return {
                nom: nomSection,
                titre: exLibelleFilter(nomSection, vm.libelles),
                champs: champs,
                element: element,
                accordeons: sectionAccordeons
            };
        }
    }

    function createChamp(champElement: HTMLElement): IComposantSommaireItem {
        const nomChamp = champElement.getAttribute('name-element') ||
            champElement.getAttribute('name') ||
            champElement.getAttribute('col');

        const titleKey = champElement.getAttribute('ex-sommaire-element-titre') || nomChamp;

        return {
            nom: nomChamp,
            element: angular.element(champElement),
            titre: exLibelleFilter(titleKey, vm.libelles),
            accordeons: []
        };
    }

    function getChampElementsInsideSection(element: JQuery<HTMLElement>): Array<HTMLElement> {
        return Array.from(element.find(CHAMPS_SELECTEURS.join(',')))
            .filter((champElement: HTMLElement) => {
                return (
                    !angular.element(champElement).closest('ex-multi-occurrence').length && (
                        champElement.attributes.getNamedItem('data-show-in-summary') === null ||
                        champElement.attributes.getNamedItem('data-show-in-summary').value !== "false"
                    )
                );
            });
    }

    function createAccordeon(element: JQuery<HTMLElement>): IComposantSommaireItem {
        const titreAccordeon = element.find('.ex-accordeon-titre:first').text().trim();

        return {
            element: element,
            titre: titreAccordeon,
            champs: []
        };
    }

}
