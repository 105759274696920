import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IChampsEvenementsOptions {
    doccleint: number;
}

export interface IChampsEvenements {
    vaeceptypmod?: string,
    champsModifiables?: Array<string>;
}

export interface IChampsEvenementsResource extends IResourceClass<any> {
    new(data: IChampsEvenementsOptions): IResource<IChampsEvenements>;
}

export default module('core.resources.champs-evenements', [
    'core.services.api-config'
]).factory('ChampsEvenementsResource', ChampsEvenementsResourceFactory);

/* @ngInject */
function ChampsEvenementsResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IChampsEvenementsResource {
    return <IChampsEvenementsResource>$resource(`${ApiConfig.ROOT}/champs-evenements/:srccod/:cleint`);
}
