import {IAugmentedJQuery, module} from 'angular';

export interface IKeepInScrollService {
    (scrollContainer: IAugmentedJQuery, element: IAugmentedJQuery, howLong?: number): any;
}

export default module('core.services.utils.keep-in-scroll', []).factory('keepInScroll', keepInScrollFactory);

/* @ngInject */
function keepInScrollFactory() : IKeepInScrollService {
    const dataKey = 'exKeepInScrollInterval';

    return function keepElementInScroll(scrollContainer: IAugmentedJQuery, element: IAugmentedJQuery, howLong: number = 3000) {
        const inputContainer = element.find('md-input-container');
        const tagName = element.prop('tagName');
        const isAccordeon = tagName === 'EX-ACCORDEON';
        const isRegroupement = tagName === 'EX-REGROUPEMENT';
        const isCard = tagName === 'MD-CARD';
        const targetElement = (inputContainer.length && !isAccordeon && !isRegroupement && !isCard) ? inputContainer : element;

        const containerPosition = scrollContainer.offset().top;
        const frequence = 200;
        let howLongLeft = howLong;

        // Au cas ou il y a un ajustement de scorll en cours
        const existingInterval = scrollContainer.data(dataKey);
        if (existingInterval) {
            arretAjustement(existingInterval);
        }

        const interval: any = setInterval(() => {
            scrollToElement(targetElement);
            howLongLeft -= frequence;

            if (howLongLeft <= 0) {
                arretAjustement(interval);
            }
        }, frequence);

        // Pour éviter les ajustements concurents
        scrollContainer.data(dataKey, interval);

        // Si l'usager scroll, on arrête de positionner le scroll
        scrollContainer.off('scroll.keepElementInScroll');
        scrollContainer.on('scroll.keepElementInScroll', () => {
            arretAjustement(interval);
            scrollContainer.off('scroll.keepElementInScroll');
        });

        function scrollToElement(element: IAugmentedJQuery) {
            const margin = 16;
            const position = element.offset().top;
            const scrollTop = scrollContainer.scrollTop();
            scrollContainer.scrollTop(-containerPosition - margin + scrollTop + position);
        }

        function arretAjustement(interval: number) {
            clearInterval(interval);
            scrollContainer.removeData(dataKey);
        }
    }
    ;
}
