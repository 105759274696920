import {module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';
import {ISelectionOptions} from '../../resources/actions-selection.resource';

export interface IMenuItemSelectionClass {
    new(pageNom: string, fonction: string, options?: IMenuItemSelectionOptions): IMenuItemSelection;
}

export interface IMenuItemSelection extends IMenuItem {
    selection: ISelectionOptions;
    fonction: string;
    successAction?: Function;
}

export interface IMenuItemSelectionOptions extends IMenuItemOptions{
    selection: ISelectionOptions;
    successAction?: ISelectionSuccessAction;
}

interface ISelectionSuccessAction {
    (data?: any): void
}

export default module('core.services.menu-item-selection', []).factory('MenuItemSelection', MenuItemSelectionFactory);

/* @ngInject */
function MenuItemSelectionFactory(MenuItem: IMenuItemClass) {

    class MenuItemSelection extends MenuItem implements IMenuItemSelection {
        readonly selection: ISelectionOptions;
        readonly successAction: ISelectionSuccessAction;
        constructor(lblTitre: string, fonction: string, options: IMenuItemSelectionOptions) {
            super(lblTitre, () =>{}, options);
            this.selection = options.selection;
            this.fonction = fonction.toUpperCase();

            if (typeof options.successAction !== 'undefined') {
                this.successAction = options.successAction;
            }
        }
    }

    return MenuItemSelection;
}
