/**
 * Permet de sélectionner plusieurs éléments en mode édition
 */
import {IComponentOptions, module} from 'angular';
import SelectionMultipleAdvancedController from './ex-selection-multiple-advanced.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-selection-multiple-advanced', []).component('exSelectionMultipleAdvanced', SelectionMultipleAdvancedFactory());

function SelectionMultipleAdvancedFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA_TYPE,
            Binding.DATA,
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            {
                srccodref: '@',
                resourceParams: '<?',
                required: '<?ngRequired'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext',
            formCtrl: '^^form',
            monoOccurrenceCtrl: '^^exMonoOccurrence'
        },
        controllerAs: 'vm',
        controller: SelectionMultipleAdvancedController,
        template: require('./ex-selection-multiple-advanced.html')
    };
}
