/**
 * Composant représentant une boîte munie d'une entête et d'un contenu. Lorsque l'entête est cliquée, le contenu est révélé.
 */
import {IDirective, module} from 'angular';
import ToggleBoxController from './ex-toggle-box.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-toggle-box', []).directive('exToggleBox', ToggleIconDirective);

function ToggleIconDirective():IDirective {
    return {
        scope: Binding(
            Binding.OPENED.OPTIONAL,
            Binding.EXPANDABLE,
            Binding.DISABLED.OPTIONAL,
            Binding.ICON.OPTIONAL
        ),
        transclude: {
            header: 'exToggleBoxHeader',
            content: 'exToggleBoxContent'
        },
        controller: ToggleBoxController,
        bindToController: true,
        controllerAs: 'vm',
        template: require('./ex-toggle-box.html')
    };
}
