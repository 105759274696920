import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-source', []).component('exDataSource', DataSourceFactory());

export interface IDataSourceController {
    data: any;
    cleint: string;
    libelles: any;
    schemas: any;
    dataTypes: any;
    mnemonique: any;
    occurrence: any;
}

/**
 * Permet d'identifier une source de données que les composants peuvent obtenir avec un "require"
 */
function DataSourceFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA,
            Binding.LIBELLES,
            Binding.DATA_TYPES,
            Binding.SCHEMAS,
            Binding.OCCURRENCE.OPTIONAL,
            {
                cleint: '@',
                mnemonique: '@'
            }
        ),
        controllerAs: 'vm',
        controller() {}
    };
}
