import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';

export default module('core.services.api-config', []).service('ApiConfig', ApiConfigFactory);

/* @ngInject */
function ApiConfigFactory(appName: string) {
    const ApiConfig: IApiConfig = {
        HOST: (window as any).sofeApiHost || window.location.hostname,
        PORT: (window as any).sofeApiPort || 8080,
        PROTOCOL: (window as any).sofeApiProtocol || 'https',
        PATH: 'api',
        BASE: (appName === "portail-employe" ? (window as any).sofeApiBasePortail : (window as any).sofeApiBase) || appName
    };
    ApiConfig.SOCKET_URL = getURL({includePath: false, includeBase: false});
    ApiConfig.ROOT = getURL({includePath: true, includeBase: true});
    ApiConfig.URL = getURL();

    return ApiConfig;

    function getURL(options = {includePath: false, includeBase: true}) {
        const parts = [];

        if (ApiConfig.PROTOCOL) {
            parts.push(`${ApiConfig.PROTOCOL}://`);
        }

        if (ApiConfig.HOST) {
            if (!ApiConfig.PROTOCOL) {
                parts.push('//');
            }

            parts.push(ApiConfig.HOST);
        }

        if (ApiConfig.PORT) {
            parts.push(`:${ApiConfig.PORT}`);
        }

        if (options.includeBase && ApiConfig.BASE) {
            parts.push(`/${ApiConfig.BASE}`);
        }

        if (options.includePath && ApiConfig.PATH) {
            parts.push(`/${ApiConfig.PATH}`);
        }

        return parts.join('');
    }
}
