import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogEcranController from './dialog-ecran.controller';

export default module('core.dialogs.dialog-ecran', []).factory('DialogEcran', DialogEcranFactory);

/* @ngInject */
function DialogEcranFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogEcranController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-ecran.html'),
       autoWrap: false,
       multiple: true,
       focusOnOpen: false
   });
}
