import {IComponentController} from 'angular';
import {IMenuItem} from '../../services/menu/menu-item.service';

interface IComposantMenu extends IComponentController {
    menu: Array<IMenuItem>;
    data: any;
    col: string;
    isErr():boolean;
    doAction(event: JQueryEventObject): void;
}

/* @ngInject */
export default function CaseBoutonController() {
    const vm: IComposantMenu = this;

    vm.$onInit = $onInit;
    vm.doAction = doAction;
    vm.isErr = isErr;

    function $onInit() {
    }

    function isErr(): boolean {
        return vm.data[vm.dataType.params.err];
    }

    function doAction(event: JQueryEventObject) {
        if (vm.menu.length) {
            vm.menu[0].action(event, vm.data, vm.col);
        }
    }
}
