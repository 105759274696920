import { module } from 'angular';
import { IOperateur, IOperateurService } from '../operateur.service';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IColonne } from '../multi-occurrence.service';
import { IApiDataType } from './data-type-manager.service';
import { IMenuItem } from '../menu/menu-item.service';

export interface IDataTypeClass {
    new(options?: IDataTypeOptions): IDataType;
}

export type DataTypeAlignment = 'default' | 'left' | 'right' | 'center';

export interface IDataTypeMap {
    [col: string]: IDataType;
}

export interface IDataTypeOptions {
    schemaItem?: ISchemaItem;
    template?: string;
    alignment?: DataTypeAlignment;
    operateurs?: Array<IOperateur>;
    params?: any;
}

export interface IDataTypeParams {
    id: string;
    type: string;
    err: string;
    sousType: string;
    source: string,
    description: Array<string>;
    details: Array<string>;
    format: string;
    valeur: string;
    valeurs: Array<string>;
    actifs: boolean;
    autresValeurs: { [key: string]: string };
    affectations: { [key: string]: string };
    restrictions: { [key: string]: IRestriction | string };
    raffinements: { [key: string]: IRaffinement | string };
    boutonsRadio: Array<IBoutonRadioConfig>;
    statut: { icon: string, class: string };
    minutes: boolean;
    secondes: boolean;
    decimals: number;
    precision: number;
    iconActif: string;
    iconInactif: string;
    masque: string;
    demasqueOnSave: boolean;
    min: string | number;
    max: string | number;
    separateurMilliers: string;
    coche: boolean;
    cols: { [key: string]: string };
    context: { [key: string]: string };
    fetchOnChange: boolean;
    lovs: Array<string | IParamsLovsOptions>;
    blob: string;
    ext: string;
    width: string;
    valeursDefaut: { declencheurs: Array<string>, cols: { [col: string]: string } };
    bloc: string;
    groupBy: string;
    // Cette option permet d'afficher ou non en mode consultation (imputation)
    showView: boolean;
    // Indique une lov qui contient plusieurs valeurs
    multipleValeurs: boolean;
    // Liste de colonnes à copier lors d'une récupération
    colonnesCopie: { [col: string]: string };
    // Pour filtrer les champs de fusion de l'édition avancée
    vaebmtent: string;
    // La clé de référence pour la récupération
    cleintref: string;
    mnemonique: string;
    // Indicateur de saisie pour l'imputation
    indicateurSaisie: number;
    //Valeur de défaut à la sélection d'une ligne (Édition assistée)
    defaultOnSelection: any;
    colonnes: Array<IColonne>;
    dataTypeEdition: IApiDataType;
    separateur: string;
    toujoursVisible: boolean;
    longueur: number;
    dateFormat: boolean;
    //cette propriete a la liste de champs d'une LOV afin d'appliquer une class css particuliere 
    listeDetailsCss?:Array<string>;
}

export interface IDataType {
    readonly schemaItem?: ISchemaItem;
    readonly template: string;
    readonly operateurs: Array<IOperateur>;
    readonly params: IDataTypeParams;
    alignment: DataTypeAlignment;
    setGridData: () => void;
}

export interface IRestriction {
    nom: string;
    operateur?: string;
    skipFiltre?: boolean;
}

export interface IRaffinement {
    nom: string;
    useNullOnEmpty?: boolean;
}

export interface IBoutonRadioConfig {
    libelle: string;
    valeur: string | number;
    autresValeurs?: any;
    libelleAide?: string;
    menus?: Array<IMenuItem>;
    alignment?: string;
}

export interface IParamsLovsOptions {
    lov: string;
    colCopie: string;
    description: Array<string>;
    lblTitre: string;
    details?: Array<string>;
}

export default module('core.services.data-type', []).factory('DataType', DataTypeFactory);

/* @ngInject */
function DataTypeFactory(Operateur: IOperateurService): IDataTypeClass {
    const DEFAULT_TEMPLATE = `<span ng-bind-html="vm.data[vm.col] | exData : vm.schemas[vm.col]"></span>`;
    const DEFAULT_ALIGNMENT: DataTypeAlignment = 'left';

    class DataType implements IDataType {
        readonly schemaItem: ISchemaItem;
        template: string = DEFAULT_TEMPLATE;
        readonly operateurs: Array<IOperateur>;
        readonly params: any = {};
        alignment: DataTypeAlignment = DEFAULT_ALIGNMENT;

        constructor(options: IDataTypeOptions = {}) {
            this.schemaItem = options.schemaItem;
            this.operateurs = this.getOperateurs(options);

            if (options.template) {
                this.template = options.template;
            }

            if (options.alignment) {
                this.alignment = options.alignment;
            } else {
                this.alignment = (this.schemaItem && this.schemaItem.type === 'number') ? 'right' : 'left';
            }

            if (options.params) {
                this.params = options.params;
            }
        }

        //uniquement pour les données de ex-grid afin d'avoir la limite de caractères
        setGridData() {
            this.template = '<span ng-init="characteresMax=200; montrerPlus=false"><span ng-bind-html="(vm.data[vm.col] | exData : vm.schemas[vm.col]) | limitTo: characteresMax"></span><a ng-show="(vm.data[vm.col] | exData : vm.schemas[vm.col]).length > characteresMax" href ng-click="characteresMax=(vm.data[vm.col] | exData : vm.schemas[vm.col]).length; montrerPlus=true">Afficher plus...</a><a ng-show="montrerPlus" href ng-click="characteresMax = 200; montrerPlus=false">Afficher moins</a></span>';
        }

        getOperateurs(options: IDataTypeOptions) {
            if (options.operateurs) {
                return options.operateurs;
            } else if (this.schemaItem && this.schemaItem.type === 'string') {
                return Operateur.STRING_OPERATEURS;
            } else if (this.schemaItem) {
                return Operateur.BASIC_OPERATEURS;
            } else {
                return null;
            }
        }
    }

    return DataType;
}
