import { module } from 'angular';
import { IMenuItem } from '../menu/menu-item.service';
import { IFormulaireItemClass, IFormulaireItemOptions } from "./formulaire-item.service";

export default module('core.services.formulaire-item-menu-item', []).factory('FormulaireItemMenuItem', FormulaireItemMenuItemFactory);

export interface IFormulaireItemMenuItemClass {
    new(menuItem: IMenuItem, options?: IFormulaireItemOptions): IFormulaireItemMenuItem;
}

export interface IFormulaireItemMenuItem {
    menuItem: IMenuItem;
}

/* @ngInject */
function FormulaireItemMenuItemFactory(FormulaireItem: IFormulaireItemClass) {
    class FormulaireItemMenuItem extends FormulaireItem implements IFormulaireItemMenuItem {
        readonly menuItem: IMenuItem;

        constructor(menuItem: IMenuItem, options: IFormulaireItemOptions = {}) {
            super(undefined, options)
            this.menuItem = menuItem;
        }
    }

    return FormulaireItemMenuItem;
}
