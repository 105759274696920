import * as angular from 'angular';
import { IAugmentedJQuery, IComponentController, IFilterOrderBy, IRootScopeService, ITimeoutService, IScope } from 'angular';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { ISelecteurClass } from '../../services/selecteur.service';
import { IDialog } from '../../services/dialog.service';
import { IBoutonRadioDataTypeClass } from '../../services/data-types/bouton-radio-data-type.service';
import { IFilterExOuiNontous } from '../../filters/ex-oui-non-tous.filter';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { FormulaireElement } from '../../services/formulaire/formulaire.service';
import { IFormulaireItem, IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IDialogFiltreOptions } from '../../dialogs/dialog-filtre/dialog-filtre.controller';
import { IOperateurService } from '../../services/operateur.service';
import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IImputationDataTypeClass } from '../../services/data-types/imputation-data-type.service';
import { IProfil } from '../../resources/profil.resource';
import { INumberDataTypeClass } from '../../services/data-types/number-data-type.service';
import { IFilterExData } from '../../filters/ex-data.filter';
import { IFormulaireGroupe, IFormulaireGroupeClass } from '../../services/formulaire/formulaire-groupe.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IDateHeureDataTypeClass } from '../../services/data-types/date-heure-data-type.service';
import { IFilterExDateHeure } from '../../filters/ex-date-heure.filter';
import { ILovBoutonRadioDataTypeClass } from '../../services/data-types/lov-bouton-radio-data-type.service';
import IDialogOptions = angular.material.IDialogOptions;
import { IHeureDataTypeClass } from '../../services/data-types/heure-data-type.service';
import { IFilterExHeure } from '../../filters/ex-heure.filter';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantCriteresSuggeres } from '../ex-criteres-suggeres/ex-criteres-suggeres.controller';
import { IDataLinker } from '../../services/data-linker.service';
import { IStringDataTypeClass } from '../../services/data-types/string-data-type.service';
import AxesAffichage from '../../classes/axe-affichage.class';

interface IComposantListePastilleFiltre extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    colorPastilles: Array<string>;
    multiOccurrence: IMultiOccurrence;
    lblTitrePastille: string;
    criteresSuggeresCtrl: IComposantCriteresSuggeres;
    shouldDisplayFiltres: boolean;
    shouldDisplayCriteresSuggeres: boolean;
    shouldDisplayCriteresSuggeresMultiple: boolean;
    filtresNonSupprimables: Array<IFiltre>;
    filtresSupprimables: Array<IFiltre>;
    filtresCriteresSuggeres: Array<FormulaireElement>;
    criteresMultiplesListe: Array<any>;
    selectColor(index: number): string;
    editCritereSuggere(colonne: string, required: boolean, event: MouseEvent): void;
    removeEtatSuggere(colonne: string): void;
    afficherPastilleFiltre(filtre: IFiltre): boolean;
    removeFitre(filtre: IFiltre): void;
    removeFitreMultiple(critere: any): void;
    editFiltre(filtre: IFiltre, event: MouseEvent): void;
    getCritereSuggereValeur(colonne: string): string;
    getCriteresSuggeresListe(): Array<FormulaireElement>;
    getFiltreValeur(filtre: IFiltre): string;
    getFiltreOperateur(filtre: IFiltre): string;
    getFiltreSupprimable(filtre: IFiltre): boolean;
    getFiltreModifiable(filtre: IFiltre): boolean;
    filtrerCriteresSuggeres(formulaireElement: FormulaireElement): boolean
    afficherCriteresSuggeres(formulaireItem: IFormulaireItem): boolean;
    isCritereSuggereSupprimable(formulaireItem: IFormulaireItem): boolean;
    isCritereSuggereModifiable(formulaireItem: IFormulaireItem): boolean;
    isCritereSuggereDisabled(formulaireItem: IFormulaireItem): boolean;
    isCritereSuggereReadOnly(formulaireItem: IFormulaireItem): boolean;
    isCritereSuggereRequired(formulaireItem: IFormulaireItem): boolean;
}

/* @ngInject */
export default function ListePastilleFiltreController(defaults: IDefaultsService,
    dataLinker: IDataLinker,
    DialogFiltre: IDialog,
    Filtre: IFiltreClass,
    Selecteur: ISelecteurClass,
    Operateur: IOperateurService,
    NumberDataType: INumberDataTypeClass,
    StringDataType: IStringDataTypeClass,
    BoutonRadioDataType: IBoutonRadioDataTypeClass,
    LovBoutonRadioDataType: ILovBoutonRadioDataTypeClass,
    profil: IProfil,
    ImputationDataType: IImputationDataTypeClass,
    DateHeureDataType: IDateHeureDataTypeClass,
    HeureDataType: IHeureDataTypeClass,
    exDateHeureFilter: IFilterExDateHeure,
    exHeureFilter: IFilterExHeure,
    exOuiNonTousFilter: IFilterExOuiNontous,
    FormulaireItem: IFormulaireItemClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    exLibelleFilter: IFilterLibelle,
    exDataFilter: IFilterExData,
    $element: IAugmentedJQuery,
    orderByFilter: IFilterOrderBy,
    $timeout: ITimeoutService,
    $scope: IScope,
    $rootScope: IRootScopeService) {
    const COULEUR_FILTRE_PREDEFINI = 'blue-500';

    const vm: IComposantListePastilleFiltre = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.selectColor = selectColor;
    vm.editCritereSuggere = editCritereSuggere;
    vm.removeEtatSuggere = removeEtatSuggere;
    vm.afficherPastilleFiltre = afficherPastilleFiltre;
    vm.removeFitre = removeFitre;
    vm.removeFitreMultiple = removeFitreMultiple;
    vm.editFiltre = editFiltre;
    vm.getCritereSuggereValeur = getCritereSuggereValeur;
    vm.getCriteresSuggeresListe = getCriteresSuggeresListe;
    vm.getFiltreValeur = getFiltreValeur;
    vm.getFiltreOperateur = getFiltreOperateur;
    vm.getFiltreSupprimable = getFiltreSupprimable;
    vm.getFiltreModifiable = getFiltreModifiable;
    vm.filtrerCriteresSuggeres = filtrerCriteresSuggeres;
    vm.afficherCriteresSuggeres = afficherCriteresSuggeres;
    vm.isCritereSuggereSupprimable = isCritereSuggereSupprimable;
    vm.isCritereSuggereModifiable = isCritereSuggereModifiable;
    vm.isCritereSuggereDisabled = isCritereSuggereDisabled;
    vm.isCritereSuggereReadOnly = isCritereSuggereReadOnly;
    vm.isCritereSuggereRequired = isCritereSuggereRequired;

    function $onInit() {
        defaults(vm, {
            shouldDisplayFiltres: !vm.multiOccurrence.fonctions.customCriteresSuggeres || !vm.criteresSuggeresCtrl,
            shouldDisplayCriteresSuggeres: !vm.multiOccurrence.fonctions.customCriteresSuggeres || vm.criteresSuggeresCtrl,
            shouldDisplayCriteresSuggeresMultiple: true
        });

        vm.multiOccurrence.on('etatUpdate', updateFiltresRender);
        $timeout(updateFiltresRender);
    }

    function $onDestroy() {
        vm.multiOccurrence.removeListener('etatUpdate', updateFiltresRender);
    }

    function updateFiltresRender(): void {
        //pour la pastille de selection multiple
        getShouldDisplayCriteresSuggeresMultiple();
        vm.filtresNonSupprimables = getFiltresNonSupprimables();
        vm.filtresSupprimables = getFiltresSupprimables();
        vm.filtresCriteresSuggeres = getFiltresCriteresSuggeres();
        setDateAxeCalendar()
    }
    function setDateAxeCalendar() {
        try {
            const newDate = vm?.multiOccurrence?.etatSelected?.criteresSuggeresData?.date
            const oldDate = vm?.multiOccurrence?.etatSelectedOriginal?.criteresSuggeresData?.date
            const axe = vm?.multiOccurrence?.axesAffichage?.axeActive;
            //on fais l'update de calendar si l'axe est active
            if (newDate && axe) {
                if (axe?.axeNom == AxesAffichage.AXES_AFFICHAGE.CALENDAR && axe?.options?.setDate) {
                    $rootScope.$broadcast('ex-calendrier.setDate', { newDate: newDate, oldDate: oldDate });
                } else {
                    $rootScope.$broadcast('ex-calendrier.setDate', { newDate: newDate, oldDate: undefined });
                }
            }
        } catch (error) { }
    }

    function getShouldDisplayCriteresSuggeresMultiple() {
        const criteresMultiplesListe: any = []
        const data = vm?.multiOccurrence?.etatSelected?.criteresMultiplesListe
        vm.shouldDisplayCriteresSuggeresMultiple = false;
        //on valide s'il y a des données dans la liste de selection multiple afin d'afficher la pastille
        if (data && Array.isArray(data) && data.length > 0) {
            if (vm.multiOccurrence.hasItemsSelectionMultiple()) {
                data.forEach((el) => {
                    let lov = ("lov" + el.col + vm?.multiOccurrence?.srccod).trim()
                    if (vm.multiOccurrence.hasValuesItemSelectionMultiple(lov)) {
                        criteresMultiplesListe.push(el);
                        vm.shouldDisplayCriteresSuggeresMultiple = true;
                    }
                })
            }
        }
        //on ajoute ceux qui ont values
        vm.criteresMultiplesListe = criteresMultiplesListe;
    }

    //message entre composants pour afficher ou pas la pastille de selection multiple
    $scope.$on("ex.liste.pastille.update.selection.multiple", getShouldDisplayCriteresSuggeresMultiple)

    function getFiltresNonSupprimables() {
        const filtresNonSupprimables = vm.multiOccurrence.etatSelected.filtres.filter((filtre: IFiltre) => {
            return vm.afficherPastilleFiltre(filtre) && !vm.getFiltreSupprimable(filtre);
        });

        return orderByFilter(filtresNonSupprimables, [vm.getFiltreSupprimable, vm.getFiltreModifiable]);
    }

    function getFiltresSupprimables() {
        return vm.multiOccurrence.etatSelected.filtres.filter((filtre: IFiltre) => {
            return vm.afficherPastilleFiltre(filtre) && vm.getFiltreSupprimable(filtre);
        });
    }

    function getFiltresCriteresSuggeres() {
        const criteresSuggeresAffichables = vm.getCriteresSuggeresListe()
            .filter((formulaireItem: FormulaireElement) => vm.filtrerCriteresSuggeres(formulaireItem)
                && vm.afficherCriteresSuggeres(<IFormulaireItem>formulaireItem));

        return orderByFilter(criteresSuggeresAffichables, [vm.isCritereSuggereSupprimable, vm.isCritereSuggereModifiable]);
    }

    /**
     * Appeler dans la page HTML et attribut une classe css, afin ajouté une couleur à l'élément
     *
     * @param index
     */
    function selectColor(index: number): string {
        const filtre = vm.multiOccurrence.etatSelected.filtres[index];

        if (filtre.predefini || filtre.readOnly) {
            return COULEUR_FILTRE_PREDEFINI;
        }

        return vm.colorPastilles[index % vm.colorPastilles.length];
    }

    function editCritereSuggere(colonne: string, required: boolean, event: MouseEvent) {
        const selecteur = new Selecteur(
            [colonne],
            []
        );

        const options: IDialogFiltreOptions = {
            selecteur,
            colonne,
            required,
            data: vm.multiOccurrence.etatSelected.criteresSuggeresData,
            valeurs: [vm.multiOccurrence.etatSelected.criteresSuggeresData[colonne]],
            colonneModifiable: false,
            multiOccurrence: vm.multiOccurrence,
            isCritereSuggere: true,
            ecranContext: vm.ecranContextCtrl,
            lblTitre: vm.lblTitrePastille
        };

        if (!options.data.hasOwnProperty('valeur1')) {
            options.data['valeur1'] = options.data[options.colonne];
        }

        editTerm(event, options)
            .then((result: any) => {
                if (isLov(colonne) || isLovBoutonRadio(colonne)) {
                    vm.multiOccurrence.dataTypes[colonne].params.description.forEach((colonneDescription: string) => {
                        const cleDescription = `${colonne}__${colonneDescription}`;
                        vm.multiOccurrence.etatSelected.criteresSuggeresData[cleDescription] = result.data[cleDescription];
                    });

                    if (vm.multiOccurrence.dataTypes[colonne].params.autresValeurs) {
                        Object.keys(vm.multiOccurrence.dataTypes[colonne].params.autresValeurs).forEach((autreColonne: string) => {
                            vm.multiOccurrence.etatSelected.criteresSuggeresData[autreColonne] = result.data[autreColonne];
                        });
                    }
                } else if (isImputation(colonne)) {
                    const colsImputation = Object.assign({}, vm.multiOccurrence.dataTypes[colonne].params.cols);
                    Object.keys(colsImputation).forEach((col: string) => {
                        if (typeof result.data[colsImputation[col]] !== 'undefined') {
                            vm.multiOccurrence.etatSelected.criteresSuggeresData[colsImputation[col]] = result.data[colsImputation[col]];
                        }
                    });
                }

                vm.multiOccurrence.etatSelected.criteresSuggeresData[colonne] = result.valeurs[0];
            });
    }

    /**
     * Retire le paramètre suggéré en question
     */
    function removeEtatSuggere(colonne: string): void {
        delete vm.multiOccurrence.etatSelected.criteresSuggeresData[colonne];

        if (isLov(colonne) || isLovBoutonRadio(colonne)) {
            vm.multiOccurrence.dataTypes[colonne].params.description.forEach((colonneDescription: string) => {
                delete vm.multiOccurrence.etatSelected.criteresSuggeresData[`${colonne}__${colonneDescription}`];
            });

            if (vm.multiOccurrence.dataTypes[colonne].params.autresValeurs) {
                Object.keys(vm.multiOccurrence.dataTypes[colonne].params.autresValeurs).forEach((autreColonne: string) => {
                    delete vm.multiOccurrence.etatSelected.criteresSuggeresData[autreColonne];
                });
            }

            if (vm.multiOccurrence.dataTypes[colonne].params.restrictions) {
                Object.keys(vm.multiOccurrence.dataTypes[colonne].params.restrictions).forEach((restrictionColonne: string) => {
                    delete vm.multiOccurrence.etatSelected.criteresSuggeresData[`${colonne}__${restrictionColonne}`];
                });
            }
        } else if (isImputation(colonne)) {
            const colsImputation = vm.multiOccurrence.dataTypes[colonne].params.cols;
            Object.keys(colsImputation).forEach((col: string) => {
                delete vm.multiOccurrence.etatSelected.criteresSuggeresData[colsImputation[col]];
            });
        }
    }

    function afficherPastilleFiltre(filtre: IFiltre) {
        return Boolean(
            (
                filtre.visible &&
                !isImputation(filtre.colonne)
            ) ||
            (
                filtre.visible &&
                isImputation(filtre.colonne) &&
                filtre.getValeur()
            )
        );
    }

    /**
     * Retire le filtre en question
     *
     * @param index
     */
    function removeFitre(filtreToRemove: IFiltre): void {
        const index = vm.multiOccurrence.etatSelected.filtres.findIndex(filtre => filtre === filtreToRemove);
        vm.multiOccurrence.etatSelected.removeFiltre(index);
        vm.multiOccurrence.emit('filterRemoved', filtreToRemove);
        vm.multiOccurrence.emit('uqApplySearch');
    }

    function removeFitreMultiple(critere: any): void {
        const srccod = vm.multiOccurrence.srccod
        let ids = vm.multiOccurrence.getLovsMultiple(srccod, critere.col)
        vm.multiOccurrence.reinitialiserLovSelectionMultiple(srccod, ids).then(() => {
            setTimeout(() => {
                $rootScope.$broadcast('ex.input.lov.selection.multiple.valide.storage');
                vm.multiOccurrence.emit('uqApplySearch');
            }, 100)
        })
    }

    function editFiltre(filtreToEdit: IFiltre, event: MouseEvent): void {
        const index = vm.multiOccurrence.etatSelected.filtres.findIndex(filtre => filtre === filtreToEdit);
        const selecteur = new Selecteur(
            vm.multiOccurrence.etatSelected.colonnesDisponibles,
            vm.multiOccurrence.etatSelected.colonnesFiltrables,
            vm.multiOccurrence.colonnesEnteteTitre
        );

        const options: IDialogFiltreOptions = {
            selecteur,
            colonne: filtreToEdit.colonne,
            data: filtreToEdit.data,
            operateur: filtreToEdit.operateur,
            valeurs: [filtreToEdit.getValeur(), filtreToEdit.getValeur2()],
            multiOccurrence: vm.multiOccurrence,
            operateurModifiable: !filtreToEdit.predefini,
            colonneModifiable: !filtreToEdit.predefini,
            ecranContext: vm.ecranContextCtrl
        };

        editTerm(event, options)
            .then((result: any) => {
                const nouveauFiltre = new Filtre({
                    colonne: result.colonne,
                    data: result.data,
                    operateur: result.operateur,
                    valeur: result.valeurs[0],
                    valeur2: result.valeurs[1],
                    predefini: filtreToEdit.predefini,
                    readOnly: filtreToEdit.readOnly
                });
                vm.multiOccurrence.etatSelected.replaceFiltre(nouveauFiltre, index);
            });
    }

    function editTerm(event: MouseEvent, settings: IDialogFiltreOptions) {
        const dialogOptions: IDialogOptions = {
            targetEvent: event,
            locals: settings,
            multiple: true
        };

        return DialogFiltre.show(dialogOptions);
    }

    /**
     * Cette fonction sert a déterminer si le dataType d'une colonne est de type boolean
     */
    function isBoolean(colonne: string): boolean {
        return vm.multiOccurrence.isBooleanDataType(colonne);
    }

    /**
     * Cette fonction sert a déterminer si le dataType d'une colonne est de type numb
     * La première condition permet de gérer la différence entre DataTypes (String) et le Schema (Number)
     */
    function isNumber(colonne: string): boolean {
        return !(vm.multiOccurrence.dataTypes[colonne] instanceof StringDataType) && (
            (vm.multiOccurrence.schema[colonne] && vm.multiOccurrence.schema[colonne].type === 'number') ||
            (vm.multiOccurrence.dataTypes[colonne] instanceof NumberDataType)
        );
    }

    function formatNumber(colonne: string, valeur: string) {
        return exDataFilter(valeur, vm.multiOccurrence.schema[colonne], vm.multiOccurrence.dataTypes[colonne]);
    }

    /**
     * Cette fonction sert a déterminer si le dataType d'une colonne est de type bouton radio
     */
    function isRadioButton(colonne: string): boolean {
        return vm.multiOccurrence.dataTypes[colonne] instanceof BoutonRadioDataType;
    }

    /**
     * Cette fonction sert a déterminer si le dataType d'une colonne est de type LOV (ou un descendant)
     */
    function isLov(colonne: string): boolean {
        return vm.multiOccurrence.isChampDropdown(new FormulaireItem(colonne));
    }

    function isLovBoutonRadio(colonne: string): boolean {
        return vm.multiOccurrence.dataTypes[colonne] instanceof LovBoutonRadioDataType;
    }

    function isImputation(colonne: string): boolean {
        return vm.multiOccurrence.dataTypes[colonne] instanceof ImputationDataType;
    }

    function isDateHeure(colonne: string): boolean {
        return vm.multiOccurrence.dataTypes[colonne] instanceof DateHeureDataType;
    }

    function isHeure(colonne: string): boolean {
        return vm.multiOccurrence.dataTypes[colonne] instanceof HeureDataType;
    }

    function getBoutonRadioLibelle(colonne: string, valeur: any): string {
        const boutonsRadio = vm.multiOccurrence.dataTypes[colonne].params.boutonsRadio;
        const boutonRadio = boutonsRadio.find((boutonRadio: any) => boutonRadio.valeur === valeur);

        if (boutonRadio) {
            return boutonRadio.libelle;
        }
    }

    function getCritereSuggereValeur(colonne: string) {
        const valeur = vm.multiOccurrence.etatSelected.criteresSuggeresData[colonne];

        if (isLov(colonne) || isLovBoutonRadio(colonne)) {
            return vm.multiOccurrence.dataTypes[colonne].params.description
                .map((col: string) => vm.multiOccurrence.etatSelected.criteresSuggeresData[`${colonne}__${col}`] || vm.multiOccurrence.ecranDetails.valeurs[`${colonne}__${col}`])
                .filter((value: string) => value)
                .join(' - ');
        } else if (isBoolean(colonne)) {
            const libelle = (valeur) ? 'G_LBL_OUI' : 'G_LBL_NON';
            return exLibelleFilter(libelle);
        } else if (isRadioButton(colonne)) {
            return exLibelleFilter(getBoutonRadioLibelle(colonne, valeur), vm.multiOccurrence.libelles);
        } else if (isNumber(colonne)) {
            return formatNumber(colonne, valeur);
        } else if (isHeure(colonne)) {
            const minutes: boolean = vm.multiOccurrence.dataTypes[colonne].params && vm.multiOccurrence.dataTypes[colonne].params.minutes;
            const secondes: boolean = vm.multiOccurrence.dataTypes[colonne].params && vm.multiOccurrence.dataTypes[colonne].params.secondes;
            const [heures, mins, secs] = valeur.split(':');
            const formattedValeur = new Date().setHours(heures, mins, secs).toString();

            return exHeureFilter(formattedValeur, minutes, secondes);
        } else {
            return valeur;
        }
    }

    function getCriteresSuggeresListe() {
        if (vm.multiOccurrence.criteresSuggeres) {
            const criteresSuggeresListe = [...vm.multiOccurrence.criteresSuggeres.liste];
            const sousGroupes = vm.multiOccurrence.criteresSuggeres.liste.filter((champ) => champ.constructor === FormulaireGroupe);
            sousGroupes.forEach((formulaireGroupe: IFormulaireGroupe) => {
                formulaireGroupe.formulaire.liste.forEach((formulaireItem) => {
                    criteresSuggeresListe.push(formulaireItem);
                });
            });
            return criteresSuggeresListe;
        } else {
            return [];
        }
    }

    function getFiltreValeur(filtre: IFiltre) {
        if ((isLov(filtre.colonne) || isLovBoutonRadio(filtre.colonne)) && filtre.operateur.isRechercheExacteOperateur()) {
            const ecranData = vm.ecranContextCtrl.ecranDetails.valeurs;

            return vm.multiOccurrence.dataTypes[filtre.colonne].params.description
                .map((col: string) => {
                    const champ = `${filtre.colonne}__${col}`;
                    return filtre.data[champ] || ecranData[champ];
                })
                .filter((value: string) => value)
                .join(' - ');
        } else if (isBoolean(filtre.colonne)) {
            return exOuiNonTousFilter(filtre.getValeur(), filtre.operateur);
        } else if (isRadioButton(filtre.colonne)) {
            return exLibelleFilter(getBoutonRadioLibelle(filtre.colonne, filtre.getValeur()), vm.multiOccurrence.libelles);
        } else if (isNumber(filtre.colonne) && !filtre.operateur.isAucuneValeurOperateur()) {
            if (filtre.operateur.isRecherchePartielleOperateur() || filtre.operateur.isValeurDoubleOperateur()) {
                return filtre.toStringVal();
            } else {
                return formatNumber(filtre.colonne, filtre.toStringVal());
            }
        } else if (isDateHeure(filtre.colonne)) {
            if (filtre.operateur.isValeurDoubleOperateur()) {
                const langET = exLibelleFilter('G_LBL_ET');
                return `${exDateHeureFilter(filtre.getValeur())} ${langET} ${exDateHeureFilter(filtre.getValeur2())}`;
            } else if (!filtre.operateur.isRecherchePartielleOperateur()) {
                return exDateHeureFilter(filtre.getValeur());
            } else if (filtre.operateur.nom === 'CONTIENT') {
                const valeur = filtre.getValeur();

                if (valeur.includes('T')) {
                    return exDateHeureFilter(valeur);
                }
            }
        }

        return filtre.toStringVal();
    }

    function getFiltreOperateur(filtre: IFiltre): string {
        if (isBoolean(filtre.colonne)) {
            return Operateur.EGALE.texte;
        } else if (!vm.multiOccurrence.dataTypes[filtre.colonne] && filtre.operateur.textePluriel) {
            return filtre.operateur.textePluriel;
        } else {
            return filtre.operateur.texte;
        }
    }

    function getFiltreSupprimable(filtre: IFiltre): boolean {
        //mettre les états annulé et fermé figés si la colonne existe ainsi que les champs avec le flag nonSupprimable
        if (filtre.colonne && (filtre.colonne.trim() === "trnflgstaann" || filtre.colonne.trim() === "trnflgstafer" || filtre.nonSupprimable)) {
            return false;
        }
        return !(filtre.predefini || filtre.readOnly);
    }

    function getFiltreModifiable(filtre: IFiltre): boolean {
        return filtre.modifiable && !filtre.readOnly;
    }

    function filtrerCriteresSuggeres(formulaireElement: FormulaireElement) {
        return (formulaireElement instanceof FormulaireItem);
    }

    function afficherCriteresSuggeres(formulaireItem: IFormulaireItem) {
        return (
            !formulaireItem.affichage &&
            !isCritereSuggereHidden(formulaireItem) &&
            (
                !isImputation(formulaireItem.col) &&
                typeof vm.multiOccurrence.etatSelected.criteresSuggeresData[formulaireItem.col] !== 'undefined' &&
                vm.multiOccurrence.etatSelected.criteresSuggeresData[formulaireItem.col] !== null
            ) ||
            (
                isImputation(formulaireItem.col) &&
                vm.multiOccurrence.etatSelected.criteresSuggeresData[formulaireItem.col] &&
                vm.multiOccurrence.etatSelected.criteresSuggeresData[formulaireItem.col].length &&
                vm.multiOccurrence.etatSelected.criteresSuggeresData[formulaireItem.col].replace(new RegExp(`\\${profil.compagnie.imputation.separateur}`, 'g'), '').length
            )

        );
    }

    function isCritereSuggereHidden(formulaireItem: IFormulaireItem): boolean {
        linkData(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        if (formulaireItem.hidden instanceof Function) {
            return formulaireItem.hidden(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        } else {
            return formulaireItem.hidden;
        }
    }

    function isCritereSuggereSupprimable(formulaireItem: IFormulaireItem) {
        return (
            formulaireItem.supprimable ||
            (
                !isCritereSuggereReadOnly(formulaireItem) && !vm.isCritereSuggereDisabled(formulaireItem) &&
                !isCritereSuggereRequired(formulaireItem) && !isBoolean(formulaireItem.col) && !isRadioButton(formulaireItem.col)
            )
        );
    }

    function isCritereSuggereModifiable(formulaireItem: IFormulaireItem) {
        return (!vm.isCritereSuggereReadOnly(formulaireItem) && !vm.isCritereSuggereDisabled(formulaireItem));
    }

    function isCritereSuggereDisabled(formulaireItem: IFormulaireItem) {
        linkData(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        if (formulaireItem.disabled instanceof Function) {
            return formulaireItem.disabled(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        } else {
            return formulaireItem.disabled;
        }
    }

    function isCritereSuggereReadOnly(formulaireItem: IFormulaireItem) {
        linkData(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        if (formulaireItem.readonly instanceof Function) {
            return formulaireItem.readonly(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        } else {
            return formulaireItem.readonly;
        }
    }

    function isCritereSuggereRequired(formulaireItem: IFormulaireItem) {
        if (formulaireItem.required instanceof Function) {
            return formulaireItem.required(vm.multiOccurrence.etatSelected.criteresSuggeresData);
        } else {
            return formulaireItem.required;
        }
    }

    function linkData(data: any): void {
        if (!data.$linked) {
            //On s'assure que les données sont liées.
            dataLinker.link($element, data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
        }
    }
}
