/**
 * Ce filtre formatte une donnée en se basant sur les propriétés de son schéma.
 */
import {IFilterDate, IFilterNumber, module} from 'angular';
import {ISchemaItem} from '../interfaces/schemas.interface';
import {IDataType} from '../services/data-types/data-type.service';
import {INumberDataTypeClass} from '../services/data-types/number-data-type.service';
import {ISequenceDataTypeClass} from '../services/data-types/sequence-data-type.service';
import {IFilterExPadStart} from './ex-pad-start.filter';

export default module('core.filters.ex-data', []).filter('exData', dataFilter);

export interface IFilterExData {
    (value: any, schemaItem?: ISchemaItem, dataType?: IDataType): string;
}

/* @ngInject */
function dataFilter(numberFilter: IFilterNumber,
                    NumberDataType: INumberDataTypeClass,
                    SequenceDataType: ISequenceDataTypeClass,
                    dateFilter: IFilterDate,
                    exPadStartFilter: IFilterExPadStart): IFilterExData {
    const DEFAULT_DECIMALS = 2;

    return function (value: any, schemaItem: ISchemaItem = {}, dataType?: IDataType): string {
        if (schemaItem.type === 'date') {
            return dateFilter(value, 'yyyy-MM-dd');
        } else if (schemaItem.type === 'number' || dataType instanceof NumberDataType) {
            if (typeof value === 'string' && !value.length) {
                return value;
            }

            const decimals = getDecimals();

            let numberValue = numberFilter(value, decimals);

            if (dataType && dataType.params && typeof dataType.params.separateurMilliers !== 'undefined' && numberValue !== null && typeof numberValue !== 'undefined') {
                numberValue = numberValue.replace(new RegExp(',', 'g'), dataType.params.separateurMilliers)
            }

            if (dataType instanceof SequenceDataType) {
                return exPadStartFilter(numberValue, 3, '0', dataType.params.decimals)
            }

            return numberValue;
        }

        return value;

        function getDecimals() {
            if (schemaItem.type === 'number') {
                const dataTypeDecimals = dataType && dataType.params && dataType.params.decimals;
                return (schemaItem.decimals !== null) ?
                    schemaItem.decimals :
                    (typeof dataTypeDecimals !== 'undefined') ? dataTypeDecimals : DEFAULT_DECIMALS;
            } else {
                return dataType.params.decimals || 0;
            }
        }
    };
}
