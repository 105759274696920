import {ISchemaItem, ISchemas} from '../../interfaces/schemas.interface';
import {IDataType, IDataTypeMap} from '../../services/data-types/data-type.service';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IReglesValidationItem} from '../../interfaces/regles-validation.interface';
import {IAugmentedJQuery, IComponentController, IFormController, IScope} from 'angular';
import {IFocusService} from '../../behaviors/ex-focus/ex-focus.behavior';
import {IDialog} from '../../services/dialog.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IDialogOptions = angular.material.IDialogOptions;

interface IComposantInputDescription extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    enableCount: boolean;
    required: boolean;
    nameElement: string;
    schemaItem: ISchemaItem;
    dataType: IDataType;
    dataTypes: IDataTypeMap;
    schemas: ISchemas;
    libelles: any;
    formCtrl: IFormController;
    validateApi: string;
    srccodref: string;
    value: string;
    data: any;
    rows: number;
    disabled: boolean;
    reglesValidationItem: IReglesValidationItem;
    getDefaultMaxlength(): number;
    getCompleterAvecDesZeros(): number;
    getLongueurFixe(): number;
    isEditionAvancee(): boolean;
    openDialogEditionAvanceeAction(event: MouseEvent): void;
}

/* @ngInject */
export default function InputDescriptionController(defaults: IDefaultsService,
                                                   $scope: IScope,
                                                   $element: IAugmentedJQuery,
                                                   DialogEditionAvancee: IDialog,
                                                   exFocus: IFocusService) {
    const MAX_LENGTH_DEFAUT_INOUT_WITHOUT_SCHEMAS = 4000;
    const vm: IComposantInputDescription = this;

    vm.$onInit = $onInit;
    vm.getDefaultMaxlength = getDefaultMaxlength;
    vm.getCompleterAvecDesZeros = getCompleterAvecDesZeros;
    vm.getLongueurFixe = getLongueurFixe;
    vm.isEditionAvancee = isEditionAvancee;
    vm.openDialogEditionAvanceeAction = openDialogEditionAvanceeAction;

    function $onInit() {
        defaults(vm, {
            enableCount: vm.isEditionAvancee(),
            disabled: false,
            validateApi: '',
            rows: 1
        });

       // On attend que le formulaire soit accessible
        $scope.$watch('::vm.formCtrl[vm.nameElement]', () => {
            // Lorsque le champ a déjà une valeur, on veut afficher les erreurs de validations déjà existantes
            vm.formCtrl[vm.nameElement].$validate();
            if (vm.formCtrl[vm.nameElement].$viewValue && vm.formCtrl[vm.nameElement].$invalid) {
                vm.formCtrl[vm.nameElement].$setTouched();
                vm.formCtrl[vm.nameElement].$setDirty();
            }
        });

       $scope.$watch('::vm.formCtrl[vm.nameElement].$viewValue', (value: any) => {
            // Pour éviter un bug avec Angular où un champ vide est considéré comme $dirty au chargement,
            // on ajuste la valeur au chargement des données.
            if (value === null) {
                vm.formCtrl[vm.nameElement].$viewValue = '';
            }
        });
    }


    /**
     * Permet d'avoir le nombre maximal d'un champ
     */
    function getDefaultMaxlength(): number {
        if (vm.reglesValidationItem && vm.reglesValidationItem.lng) {
            return vm.reglesValidationItem.lng;
        } else if (vm.dataType && vm.dataType.params.max) {
            return Number(vm.dataType.params.max);
        } else if (vm.schemaItem && vm.schemaItem.length) {
            return vm.schemaItem.length;
        } else if (vm.schemaItem && vm.schemaItem.precision) {
            const precision = vm.schemaItem.precision;
            const decimals = vm.schemaItem.decimals;
            const sizeVirgule = ((precision - decimals) > 3) ? Math.floor(((precision - decimals) / 3)) : 0;
            //ex: 1,000,000.54 : precision(9) + virgule(2) + point(1)
            if (decimals > 0) {
                return precision + sizeVirgule + 1;
            }
            return precision + sizeVirgule;
        } else {
            return MAX_LENGTH_DEFAUT_INOUT_WITHOUT_SCHEMAS;
        }
    }

    /**
     * Permet de savoir si on doit compléter avec des zéros
     */
    function getCompleterAvecDesZeros(): number {
        if (vm.reglesValidationItem) {
            if (vm.reglesValidationItem.flgcplzer && vm.reglesValidationItem.lng && vm.reglesValidationItem.flglngfix) {
                return vm.reglesValidationItem.lng;
            } else {
                return null;
            }
        }
    }

    /**
     * Permet de savoir si on applique une longueur fixe
     */
    function getLongueurFixe(): number {
        if (vm.reglesValidationItem) {
            if (vm.reglesValidationItem.flglngfix) {
                return vm.reglesValidationItem.lng;
            } else {
                return null;
            }
        }
    }

    function isEditionAvancee() {
        return (vm.dataType && vm.dataType.params.sousType === 'editionAvancee');
    }

    function openDialogEditionAvanceeAction(event: MouseEvent): void {
        const dialogOptions: IDialogOptions = {
            locals: {
                targetEvent: event,
                data: vm.data,
                col: vm.nameElement,
                libelles: vm.libelles,
                value: vm.value,
                dataType: vm.dataType,
                srccodref: vm.srccodref,
                maxlength: vm.exMaxlength || vm.getDefaultMaxlength(),
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranContext: vm.ecranContextCtrl
            }
        };

        DialogEditionAvancee.show(dialogOptions)
            .then((value: string) => {
                vm.value = value;
            })
            .finally(() => {
                exFocus($element);
            });
    }
}
