import {module} from 'angular';
import {IDialogClass} from '../../services/dialog.service';
import DialogConfirmController from '../dialog-confirm/dialog-confirm.controller';

export default module('app.dialogs.dialog-etats-ajout', [
    'core.services.dialog'
]).factory('DialogEtatsAjout', DialogEtatsAjoutFactory);

/* @ngInject */
function DialogEtatsAjoutFactory(Dialog: IDialogClass) {
   return new Dialog({
       controller: DialogConfirmController,
       controllerAs: 'vm',
       bindToController: true,
       template: require('./dialog-etats-ajout.html'),
       locals: {
           icon: 'bookmark_border',
           lblTitre: 'G_LBL_MSL_MOD_ETA_TITRE',
           lblConfirm: 'G_LBL_BTN_APPLIQUER'
       },
       focusOnOpen: false
   });
}
