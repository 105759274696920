import {IDirective, IScope, module} from 'angular';

export default module('core.behaviors.ex-completer-avec-des-zeros', [])
    .directive('exCompleterAvecDesZeros', CompleterAvecDesZerosDirective);

/* @ngInject */
function CompleterAvecDesZerosDirective(): IDirective {

    return {
        restrict: 'A',
        bindToController: true,
        controller: () => {},
        link
    };

    function link(scope: IScope, element: JQuery, attrs: any) {
        if (attrs.exCompleterAvecDesZeros) {
            const longueur = parseInt(attrs.exCompleterAvecDesZeros, 10);

            element.bind('blur', function () {
                if (!element.prop('readOnly')) {
                    const val = String(element.val());

                    if (val !== '' && val.length < longueur) {
                        element.val(val.padStart(longueur, '0'));
                        element.trigger('change');
                    }
                }
            });
        }
    }
}
