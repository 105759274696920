import {IComponentController} from 'angular';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IDataImputation} from '../../dialogs/dialog-imputation/dialog-imputation.controller';
import {IDialog} from '../../services/dialog.service';
import {IDefaultsService} from '../../services/utils/defaults.service';
import {IProfil} from '../../resources/profil.resource';
import {IParametresSecuriteService} from '../../services/parametres-securite.service';
import {IDataType} from '../../services/data-types/data-type.service';
import IDialogOptions = angular.material.IDialogOptions;

interface IComposantInputImputation extends IComponentController {
    readonly isCritereRecherche: boolean;
    readonly champsImputation: Array<IChampImputation>;
    readonly icon: string;

    ecranContextCtrl: IEcranContextController;
    data: IDataImputation;
    dataType: IDataType;
    nameElement: string;
    col: string;
    srccodref: string;
    readonly: boolean;
    paramsSecurite: any;

    openDialogImputationAction(ev: MouseEvent): void;
}

interface IChampImputation {
    vaedeicle: string;
    deilib: string;
}

interface ILovParamsImputation {
    code: string;
    col: string;
    lblTitre: string;
}

/* @ngInject */
export default function BoutonImputationController(DialogImputation: IDialog,
                                                   defaults: IDefaultsService,
                                                   profil: IProfil,
                                                   parametresSecurite: IParametresSecuriteService) {
    const vm: IComposantInputImputation = this;

    vm.$onInit = $onInit;
    vm.openDialogImputationAction = openDialogImputationAction;

    function $onInit() {
        const compagnieImputation = profil.compagnie.imputation;

        defaults(vm, {
            enableCount: false,
            readonly: true,
            icon: 'featured_play_list',
            champsImputation: compagnieImputation.champs,
            paramsSecurite: Object.assign({}, parametresSecurite(vm.ecranContextCtrl.stateParams), {srccod: vm.srccodref})
        });
    }

    function openDialogImputationAction(ev: MouseEvent): void {
        openDialogImputation(ev);
    }

    function openDialogImputation(ev: MouseEvent): void {

        const resourceParams = {
            srccod: vm.ecranContextCtrl.stateParams.srccod
        };

        const dialogOptions: IDialogOptions = {
            locals: {
                targetEvent: ev,
                lovsParams: getImputationLovsParams(),
                data: vm.data,
                resourceParams,
                isCritereRecherche: vm.isCritereRecherche,
                readonly: vm.readonly,
                icon: vm.icon,
                lblCancel: 'G_LBL_BTN_FERMER',
                showConfirmAction: false,
                srccodref: vm.srccodref,
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranContext: vm.ecranContextCtrl,
                indsai: vm.dataType.params.indicateurSaisie,
                col: vm.col
            },
            multiple: true
        };
        DialogImputation.show(dialogOptions);
    }

    function getImputationLovsParams(): Array<ILovParamsImputation> {
        let lovsParams: Array<ILovParamsImputation> = [];

        vm.champsImputation.forEach((champ) => {
            const col = getColName(champ);
            lovsParams.push({
                col,
                code: champ.vaedeicle.toLowerCase(),
                lblTitre: champ.deilib
            });
        });
        return lovsParams;
    }

    function getColName(champ: IChampImputation): string {
        return Object.keys(vm.dataType.params.cols).filter(col => {
            return col.startsWith(champ.vaedeicle.toLowerCase());
        })[0];
    }
}
