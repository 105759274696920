import { IAugmentedJQuery, IComponentController, IRootScopeService, IScope } from 'angular';
import { ITemplateController } from '../../services/template.service';

interface IComposantTranscludeTemplate extends IComponentController {
    templateCtrl: ITemplateController;
    bloc: string;
    slot: string;
    scope: number;
}

/* @ngInject */
export default function TranscludeTemplateController($scope: IScope,
    $element: IAugmentedJQuery,
    $rootScope: IRootScopeService) {
    const vm: IComposantTranscludeTemplate = this;

    vm.$onInit = function $onInit() {
        const bloc = (vm.bloc || '$NONE$').toUpperCase();

        // TODO UQ This is REALLY BAD but we need this with shitty angular for templates to work in pe-ecran-container
        // Remove this ASAP
        vm.templateCtrl = vm.templateCtrl ? vm.templateCtrl : ($rootScope as any).templateCtrl;

        if (vm.templateCtrl && vm.templateCtrl.templates[bloc]) {
            vm.templateCtrl.compile(bloc, vm.slot, $element, vm.scope ? getParentScopeById() : $scope.$parent);
        }
    }

    function getParentScopeById() {
        let parent = $scope;
        do {
            parent = parent.$parent;
        } while(parent.$id !== vm.scope)
        return parent;
    }
}
