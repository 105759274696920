import { IRoute } from '../../interfaces/route.interface';
import { IState, IStateService } from 'angular-ui-router';
import * as angular from 'angular';
import {
    IAugmentedJQuery,
    ICompileService,
    IComponentController,
    IControllerService, ILogService,
    IQService,
    IScope,
    ITemplateCacheService,
    ITimeoutService
} from 'angular';
import { IExceptions } from '../../constants/exceptions.constant';
import { IEcranDetailsResourcesClass, IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { ISourceDetailsManager } from '../../services/source-details-manager.service';
import { ISourceDetails } from '../../resources/source-details.resource';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMenuItem } from '../../services/menu/menu-item.service';
import { IComposantMonoOccurrenceEcran } from '../../components/ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import isMobile from '../../constants/mobile.constant';

export interface IDialogEcran extends IDialogEcranOptions, IComponentController {
    titre: string;
    loading: boolean;
    initialized: boolean;
    loadingError: any;
    stateParams: any;
    ecranDetails: IEcranDetailsResourcesEntite;
    sourceDetails: ISourceDetails;
    context: IEcranContextController;
    ecranElement: IAugmentedJQuery;
    ecranScope: IDialogEcranScope;
    appliquerEnregistrement: boolean;
    contextAdditionnel?: any;
    menus: IMenuItem[];
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    prepareEcran(): void;
}

export interface IDialogEcranOptions {
    source: string;
    ecran: string;
    titre: string;
    state: IState;
    route: IRoute;
    params: any;
    optionsDialog: IDialogOptions;
    appliquerEnregistrement: boolean;
    showActionsInDialog: boolean;
    customLblFermer?: boolean;
    contextAdditionnel?: any;
}

export interface IDialogOptions {
    hideToolbar?: boolean;
    titre?: string | Function;
    iconToolbar?: string;
    largeur?: number;
    hauteur?: number;
    contextAdditionnel?: () => { [key: string]: any };
    staticHauteur?: number;
}

interface IDialogEcranScope extends IScope {
    vm: any;
}

/* @ngInject */
export default function IDialogEcran($compile: ICompileService,
    $scope: IScope,
    $element: IAugmentedJQuery,
    $stateParams: any,
    $q: IQService,
    $log: ILogService,
    Exceptions: IExceptions,
    sourceDetailsManager: ISourceDetailsManager,
    EcranDetailsResource: IEcranDetailsResourcesClass,
    $templateCache: ITemplateCacheService,
    $controller: IControllerService,
    $timeout: ITimeoutService,
    parametresSecurite: IParametresSecuriteService,
    $state: IStateService) {
    const vm: IDialogEcran = this;

    vm.prepareEcran = prepareEcran;
    vm.$onInit = function $onInit() {
        vm.menuId = ((vm.params && vm.params.menuId) || $stateParams.menuId || '');
        [vm.pracleint, vm.menucleref] = vm.menuId.split('-');
        prepareEcran();
    }

    function setDialogDimensions(): void {
        const dialogElement = $element.find('.ex-dialog-ecran');
        let cssText = '';

        if (vm.optionsDialog.largeur) {
            cssText += `width: calc(${vm.optionsDialog.largeur}vw - 32px) !important;`;
        }

        if (vm.optionsDialog.hauteur) {
            cssText += `height: max(calc(${vm.optionsDialog.hauteur}vh - 32px), 500px) !important;`;
        }

        dialogElement.css('cssText', cssText);
    }

    function setStaticProperty() {

        const dialogElement = $element.find('.ex-dialog-ecran');
        let cssText = '';
        
        if (vm.optionsDialog.staticHauteur) {
            cssText += `width: ${vm.optionsDialog.staticHauteur}px !important;min-width: ${vm.optionsDialog.staticHauteur}px !important;`;
        }
        
        dialogElement.css('cssText', cssText);
    }
    function prepareEcran() {
        vm.initialized = false;
        vm.loading = true;

        if (!isMobile && (vm.optionsDialog.largeur || vm.optionsDialog.hauteur)) {
            setDialogDimensions();
        }

        try {
            if (vm.optionsDialog.staticHauteur && vm.optionsDialog.staticHauteur !== 0) {
                setStaticProperty();
            }
        } catch (e) { }

        validateParams();

        fetchEcranDetails()
            .then((ecranDetails: IEcranDetailsResourcesEntite) => {
                vm.ecranDetails = ecranDetails;

                return fetchSourceDetails();
            })
            .then(() => {
                afficherEcran();

                delete vm.loadingError;
                vm.titre = vm.titre || vm.ecranDetails.titre;
                vm.initialized = true;
            })
            .catch((error: any) => {
                $log.error(error);
                vm.loadingError = error;
            })
            .finally(() => {
                vm.loading = false;
            });
    }

    function validateParams() {
        const missingParams = vm.route.QUERY_PARAMS.filter((param: string) => {
            // Les params optionnels commencent par ?
            if (param.startsWith('?')) {
                return false;
            } else {
                return typeof vm.params[param] === 'undefined';
            }
        });

        if (missingParams.length) {
            throw new Error(`Paramètres requis manquants : ${missingParams}`);
        }
    }

    function fetchSourceDetails() {
        return sourceDetailsManager.fetch(vm.source, null, {
            pracleint: vm.pracleint,
            menucleref: vm.menucleref,
            cleint: vm.params.id,
            ecrcleint: vm.ecranDetails.ecrcleint,
            ...parametresSecurite({
                menuId: vm.menuId,
                ecrcleint: vm.ecranDetails.ecrcleint
            })
        }).then((sourceDetails: ISourceDetails) => {
            vm.sourceDetails = sourceDetails;

            const fonction = vm.ecranDetails.fonctions[sourceDetails.mnemonique.toUpperCase()];


            if (fonction && (!fonction.flgacc || (vm.route.PARAMS.includes('id') && !vm.params.id && !fonction.flgins))) {
                return $q.reject(Exceptions.ERREUR_ACCESS_ECRAN);
            }
        });
    }

    function fetchEcranDetails() {
        return EcranDetailsResource.get({
            ecrcod: `${vm.route.SOURCE}-${vm.ecran}`,
            pracleint: vm.pracleint,
            menucleref: vm.menucleref,
            ...getRouteParams(vm.route.PARAMS),
            ...getRouteParams(vm.route.QUERY_PARAMS),
            id: vm.params.id,
            employe: vm.params.employe
        }).$promise;
    }

    function getRouteParams(paramSettings: Array<string>): any {
        const paramNames = Object.keys(vm.params);
        return paramSettings.reduce((params: any, paramName: string) => {
            const nomParam = paramName.replace(/^\?/, '');
            if (paramNames.includes(nomParam)) {
                params[nomParam] = vm.params[nomParam];
            }
            return params;
        }, {});
    }

    function afficherEcran() {
        const source = vm.source.toLowerCase();

        const template = $templateCache.get(`${source}.html`);

        vm.context = {
            ecranDetails: vm.ecranDetails,
            ecranSourceDetails: vm.sourceDetails,
            stateParams: {
                route: vm.route,
                srccod: vm.source,
                menuId: vm.menuId,
                pracleint: vm.pracleint,
                menucleref: vm.menucleref,
                ecrcleint: vm.ecranDetails.ecrcleint,
                ecran: vm.ecran,
                id: vm.params.id,
                employe: vm.params.employe,
                appliquerEnregistrement: vm.appliquerEnregistrement,
                showActionsInDialog: vm.showActionsInDialog,
                customLblFermer: (vm.customLblFermer) ? vm.customLblFermer : null,
                ...vm.params
            },
            transitionTo
        };

        const locals = {
            ecranDetails: vm.context.ecranDetails,
            ecranSourceDetails: vm.context.ecranSourceDetails,
            $stateParams: vm.context.stateParams
        };

        vm.ecranScope = <IDialogEcranScope>$scope.$new();

        vm.ecranScope.$on('exMonoOccurrenceEcranInit', () => {
            $element.find('md-dialog').addClass('ex-dialog-ecran--no-action');
        });

        vm.ecranScope.vm = $controller(`${source}Controller`, locals);

        vm.ecranScope.vm.context = vm.context;

        vm.ecranElement = angular.element(`<div class="layout-column layout-fill" ex-ecran-context="vm.context">${template}</div>`);

        const parentElement = $element.find('ex-dialog .ex-dialog-div-content[ng-transclude]');

        // On laisse le temps à l'élément parent de s'afficher
        $timeout(() => {
            vm.ecranElement.appendTo(parentElement);

            if (vm.ecranScope.vm.$onInit instanceof Function) {
                vm.ecranScope.vm.$onInit();
            }

            $compile(vm.ecranElement)(vm.ecranScope);
        });
    }

    function transitionTo(stateName: string, params: any, options: IDialogOptions = {}) {
        const state: IState = $state.get(stateName);
        const basePageNom = stateName.split('.').slice(0, -1).join('.');
        const baseState: IState = $state.get(basePageNom);
        const route: IRoute = baseState.params.route;

        vm.source = route.SOURCE;
        vm.ecran = params.ecran || route.ECRAN_DEFAUT;
        vm.state = state;
        vm.route = route;
        vm.params = params;
        vm.optionsDialog = {
            hideToolbar: (options.hideToolbar === undefined) ? true : options.hideToolbar,
            iconToolbar: options.iconToolbar,
            largeur: options.largeur,
            hauteur: options.hauteur,
            staticHauteur: (options.staticHauteur === undefined) ? 0 : options.staticHauteur
        };

        resetEcran();

        return prepareEcran();
    }

    function resetEcran() {
        $element.find('md-dialog').removeClass('ex-dialog-ecran--no-action');

        delete vm.titre;
        delete vm.loadingError;
        delete vm.context;

        vm.ecranScope.$destroy();
        delete vm.ecranScope;

        vm.ecranElement.remove();
    }
}
