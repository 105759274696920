import { ISchemas } from '../../interfaces/schemas.interface';
import { IDataTypeMap } from '../../services/data-types/data-type.service';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IDataSourceController } from '../ex-data-source/ex-data-source.component';
import { IComponentController } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IBooleanDataTypeClass } from '../../services/data-types/boolean-data-type.service';
import { IMenuItem } from "../../services/menu/menu-item.service";

interface IDataItem {
    texte: string;
    valeur: any;
}

export interface IComposantDataList extends IComponentController {
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    dataSourceCtrl: IDataSourceController;
    cols: Array<string | IComposantDataListColOptions>;
    colsOptions: Array<IComposantDataListColOptions>;
    colsExclu: Array<string>;
    data: any;
    list: Array<IDataItem>;
    libelles: any;
    schemas: ISchemas;
    dataTypes: IDataTypeMap;
    hideVide: boolean;
    largeur: number;
    secondaire: boolean;
    hideLibelle: boolean;
    libelleDroite: boolean;
    alignmentLibelle: string;
    alignmentValeur: string;
    libelleValeur: string;
    largeurTexte: number;
    menusMaxLength: number;
    classTexte?: string;
    classIcone?: string;
    filtrer(col: string): boolean;
    shouldShow(col: string): boolean;
    hasNoMenuVisible(menu: Array<IMenuItem>): boolean;
}

export interface IComposantDataListColOptions {
    nom: string;
    secondaire?: boolean;
    hideLibelle?: boolean;
    libelleDroite?: boolean;
    titre?: string;
    alignmentLibelle?: string;
    libelleValeur?: string;
    largeurTexte?: number;
    hideVide?: boolean;
    icon?: string;
    classTexte?: string;
    classIcone?: string;
}

/* @ngInject */
export default function DataListController(defaults: IDefaultsService, BooleanDataType: IBooleanDataTypeClass) {
    const vm: IComposantDataList = this;

    const DEFAULT_COL_OPTIONS = {
        secondaire: false,
        hideLibelle: false,
        libelleDroite: false,
        libelleValeur: false
    };

    vm.$onInit = $onInit;
    vm.filtrer = filtrer;
    vm.shouldShow = shouldShow;
    vm.hasNoMenuVisible = hasNoMenuVisible;

    function $onInit() {
        defaults(vm, {
            largeur: 100,
            hideVide: false,
            classTexte: '',
            classIcone: vm.classTexte || ''
        });

        const colOptionsBase = defaults({
            secondaire: vm.secondaire,
            hideLibelle: vm.hideLibelle,
            libelleDroite: vm.libelleDroite,
            alignmentLibelle: vm.alignmentLibelle,
            alignmentValeur: vm.alignmentValeur,
            hideVide: vm.hideVide,
            largeurTexte: vm.largeurTexte,
            classTexte: vm.classTexte,
            classIcone: vm.classIcone
        }, DEFAULT_COL_OPTIONS);

        vm.colsOptions = vm.cols.map((col: string | IComposantDataListColOptions) => {
            if (typeof col === 'string') {
                return {
                    nom: col,
                    ...colOptionsBase
                };
            } else {
                return { ...colOptionsBase, ...col };
            }
        });

        vm.data = vm.data ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.data) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.data);
        vm.libelles = vm.libelles ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.libelles) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.libelles);
        vm.schemas = vm.schemas ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.schemas) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.schema);
        vm.dataTypes = vm.dataTypes ||
            (vm.dataSourceCtrl && vm.dataSourceCtrl.dataTypes) ||
            (vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.dataTypes);

        if (vm.alignmentValeur === 'right' && vm.menus) {
            //Quand toutes les colonnes sont alignées à droite, on veut aligné les données ensemble (qu'il y ait un menu-item ou pas)
            const menusLengths = Object.keys(vm.menus).map((col: string) => vm.menus[col].length);
            const indexMax = menusLengths.indexOf(Math.max.apply(Math, menusLengths));
            vm.menusMaxLength = menusLengths[indexMax];
        }
    }

    function filtrer(col: string) {
        return !vm.colsExclu || !vm.colsExclu.includes(col);
    }

    function shouldShow(col: string) {
        const colOption: IComposantDataListColOptions = vm.colsOptions.find((colOption: IComposantDataListColOptions) => colOption.nom === col);
        const hideVide = vm.hideVide || colOption && colOption.hideVide;
        return (!hideVide || !isEmpty(vm.data[col], col));
    }

    function hasNoMenuVisible(menu: Array<IMenuItem>) {
        return menu.every((menuItem: IMenuItem) => {
            if (!menuItem.hidden) {
                return false;
            } else {
                return typeof menuItem.hidden !== 'function' || menuItem.hidden(vm.data)
            }
        });
    }

    function isEmpty(valeur: any, col: string) {
        return valeur === '' || valeur == null || (vm.dataTypes[col] instanceof BooleanDataType && valeur === 0)
    }
}
