import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IComponentController, IScope } from 'angular';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { ISourceDetails } from '../../resources/source-details.resource';
import { IFonctionTransversaleService } from '../../services/fonction-transversale.service';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';

interface IComposantCardMultiOccurrenceAdvanced extends IComponentController {
    libelles: any;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    multiOccurrence: IMultiOccurrence;
}

/* @ngInject */
export default function CardMultiAdvancedController(ecranSourceDetails: ISourceDetails,
    $scope: IScope,
    fonctionTransversale: IFonctionTransversaleService,
    ecranDetails: IEcranDetailsResourcesEntite) {
    const vm: IComposantCardMultiOccurrenceAdvanced = this;

    vm.$onInit = $onInit;
    vm.agrandirMultiOccurrence = agrandirMultiOccurrence;

    function $onInit() {
        vm.hasAccessFullScreen = vm.multiOccurrenceOptions.bloc && ecranDetails.fonctions && ecranDetails.fonctions[vm.multiOccurrenceOptions.bloc.toUpperCase() + ".BOUFULL"] && ecranDetails.fonctions[vm.multiOccurrenceOptions.bloc.toUpperCase() + ".BOUFULL"].flgacc;

        vm.libelles = vm.libelles ||
            (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.monoOccurrence.libelles) ||
            ecranSourceDetails.libelles;

        vm.multiOccurrenceOptions.fonctions = {
            importation: false,
            afficherMenuSettingDansEntete: true,
            suiviModification: true,
            selectionCycle: true,
            ...vm.multiOccurrenceOptions.fonctions
        }

        vm.multiOccurrenceOptions.pagination = Object.assign({
            nombreElementPage: 10,
            nbElementsPossibles: [10, 50, 100]
        }, vm.multiOccurrenceOptions.pagination);

        if (vm.monoOccurrenceEcranCtrl &&
            vm.monoOccurrenceEcranCtrl.monoOccurrenceOptions &&
            vm.monoOccurrenceEcranCtrl.monoOccurrenceOptions.infosMonetaires) {
            $scope.$watch('::vm.multiOccurrence', (multiOccurrence: IMultiOccurrence) => {
                if (multiOccurrence) {
                    vm.monoOccurrenceEcranCtrl.registerMultiOccurrenceEnfant(multiOccurrence);
                }
            });
        }
    }

    function agrandirMultiOccurrence() {
        fonctionTransversale.openMultiOccurrence(vm.multiOccurrenceOptions, vm.lblTitre, vm.multiOccurrence.etatSelected, $scope, function onClose() {
            vm.multiOccurrence.fetchEtats();
        });
    }
}
