import {IComponentOptions, module} from 'angular';
import EtatsController from './ex-etats.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-etats', []).component('exEtats', EtatsFactory());

function EtatsFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE,
            {
                lblTitre: '@?'
            }
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: EtatsController,
        controllerAs: 'vm',
        template: require('./ex-etats.html')
    };
}
