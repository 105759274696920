import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import {IParametresSecuriteService} from './parametres-securite.service';
import IPromise = angular.IPromise;
import IHttpParamSerializer = angular.IHttpParamSerializer;
import IQResolveReject = angular.IQResolveReject;
import IQService = angular.IQService;

export interface IUploadManagerService {
    upload(path: string, data: any, params: any, stateParams: any): IPromise<any>
}

export default module('core.services.upload', []).factory('uploadManager', UploadManagerFactory);

/* @ngInject */
function UploadManagerFactory(ApiConfig: IApiConfig,
                              $q: IQService,
                              $httpParamSerializer: IHttpParamSerializer,
                              parametresSecurite: IParametresSecuriteService): IUploadManagerService {

    class UploadManager implements IUploadManagerService {
        upload(path: string, data: any, params: any, stateParams: any): IPromise<any> {
            return $q((resolve: IQResolveReject<any>, reject: IQResolveReject<any>) => {
                const formData = new FormData();
                const xhr = new XMLHttpRequest();

                Object.keys(data).forEach((field) => {
                    if (!field.startsWith('$')) {
                        const fieldData = data[field];
                        if (fieldData instanceof File) {
                            formData.append(field, fieldData, fieldData.name);
                        } else {
                            formData.append(field, fieldData);
                        }
                    }
                });

                const serializeParams = $httpParamSerializer(Object.assign(parametresSecurite(stateParams), params));

                const url = path.startsWith('http') ?
                    path :
                    `${ApiConfig.ROOT}${path}`;

                xhr.open('POST', `${url}?${serializeParams}`, true);

                xhr.withCredentials = true;

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(this.getResponseData(xhr));
                    } else {
                        reject({
                            request: xhr,
                            data: this.getResponseData(xhr)
                        });
                    }
                };

                xhr.send(formData);
            });
        }

        private getResponseData(xhr: XMLHttpRequest) {
            try {
                return JSON.parse(xhr.responseText);
            } catch (err) {
                return {};
            }
        }
    }

    return new UploadManager();
}
