import {EventEmitter} from 'events';

export class SettingGlobal extends EventEmitter {
    private multiOccurrenceDistributionWidths: boolean = true;
    databaseDebugActive: boolean = false

    constructor() {
        super()
        if (localStorage.getItem("databaseDebugActive")) {
            this.databaseDebugActive = true
        }
    }

    setMultiOccurrenceDistributionWidths(actif: boolean): void {
        this.multiOccurrenceDistributionWidths = actif;
        this.emit('multiOccurrenceDistributionWidthsChange', actif);
    }

    getMultiOccurrenceDistributionWidths(): boolean {
        return this.multiOccurrenceDistributionWidths;
    }

    toggleDatabaseDebugActive() {
        this.databaseDebugActive = !this.databaseDebugActive
        if (this.databaseDebugActive) {
            localStorage.setItem("databaseDebugActive", "true")
        } else {
            localStorage.removeItem("databaseDebugActive")
        }
    }
}

export default new SettingGlobal()
