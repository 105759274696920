import {IComponentOptions, module} from 'angular';
import InputFileUploadController from './ex-input-file-upload.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-input-file-upload', [])
    .component('exInputFileUpload', InputFileUploadFactory())

function InputFileUploadFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.VALUE,
            {
                required: '<?ngRequired',
                inputFileUploadPattern: '<?',
                errorMessages: '<?',
                allowsMultiple: '<?',
                data: '<?',
                libelleUpload: '<?',
                saveAction: '<?',
                autoSave: '<?',
                formCtrl: '<'
            }
        ),
        controllerAs: 'vm',
        controller: InputFileUploadController,
        template: require('./ex-input-file-upload.html')
    };
}

