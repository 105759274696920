import {IDefaultsService} from "../../services/utils/defaults.service";
import {IComponentController} from "angular";

interface IComposantCoche extends IComponentController {

}

/* @ngInject */
export default function CocheController(defaults: IDefaultsService) {
    const vm: IComposantCoche = this;

    vm.$onInit = $onInit;

    function $onInit() {
        defaults(vm, {
            hideLibelle: false
        });
    }
}
