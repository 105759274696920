/**
 * Composant représentant un item de menu, lié à une instance d'un MenuItem
 */
import {IComponentOptions, module} from 'angular';
import MenuItemController from './ex-menu-item.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-menu-item', []).component('exMenuItem', MenuItemFactory());

function MenuItemFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.DISABLED.OPTIONAL,
            {
                menuItem: '<',
                busy: '=?busyOut',
                fonction: '@',
                occurrence: '<?',
                lblTitre: '<?',
                tertiary:'<'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            dataSourceCtrl: '?^exDataSource',
            menuCtrl: '?^exMenu',
            boutonNouveauCtrl: '?^exBoutonNouveau',
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: MenuItemController,
        controllerAs: 'vm',
        template: require('./ex-menu-item.html')
    };
}
