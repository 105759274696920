import {IPromise, IQService, module} from 'angular';
import {IMenuItem, IMenuItemClass, IMenuItemOptions} from './menu-item.service';
import {IDialog} from '../dialog.service';
import {IDialogRecuperationOptions} from '../../../apps/sofe/dialogs/dialog-recuperation/dialog-recuperation.controller';
import {IColonne, IMultiOccurrenceClass} from '../multi-occurrence.service';
import {IFormulaire} from '../formulaire/formulaire.service';
import {IFiltreOptions} from '../filtre.service';
import {IDataType} from '../data-types/data-type.service';
import {IMonoOccurrence, IMonoOccurrenceClass} from '../mono-occurrence.service';
import {IOccurrence} from '../occurrence.service';
import {IApiError} from '../../interfaces/api-error.interface';
import {INotificationHandler} from '../utils/notification-handler.service';

export interface IMenuItemRecuperationClass {
    new(options: IMenuItemRecuperationOptions): IMenuItemRecuperation;
}

export interface IMenuItemRecuperation extends IMenuItem {}

export interface IMenuItemRecuperationOptions extends IMenuItemOptions {
    lblTitre?: string;
    nom: string;
    lblTitreDialog?: string;
    resourceParams?: {};
    filtres?: Array<IFiltreOptions>;
    criteresSuggeres?: IFormulaire;
    colonnesVisibles: Array<IColonne>;
    icon?: string | ((data: any) => string);
    secondaire?: boolean;
    successAction?: Function;
}

export default module('core.services.menu-item-recuperation', []).factory('MenuItemRecuperation', MenuItemRecuperationFactory);

/* @ngInject */
function MenuItemRecuperationFactory(MenuItem: IMenuItemClass,
                                     DialogRecuperation: IDialog,
                                     MonoOccurrence: IMonoOccurrenceClass,
                                     MultiOccurrence: IMultiOccurrenceClass,
                                     $q: IQService,
                                     notificationHandler: INotificationHandler) {

    class MenuItemRecuperation extends MenuItem implements IMenuItemRecuperation {
        constructor(options: IMenuItemRecuperationOptions) {
            super((options.lblTitre || 'G_LBL_RECUPERER'), lancerRecuperation, {
                icon: options.icon || 'content_copy',
                iconButton: options.iconButton,
                secondaire: options.secondaire,
                saveFirst: true
            });

            function lancerRecuperation(event: JQueryEventObject, data: any, occurrence: IOccurrence) {
                getDestination(occurrence, data).then((destination: IMonoOccurrence) => {
                    ouvrirDialogue(destination, occurrence, data);
                });
            }

            function ouvrirDialogue(destination: IMonoOccurrence, occurrence: IOccurrence, data: any) {
                const nom = options.nom.toLowerCase();
                const recuperationDataType: IDataType = destination.dataTypes[nom];

                const dialogOptions: IDialogRecuperationOptions = {
                    nom,
                    lblTitre: options.lblTitreDialog || options.nom,
                    destination: destination,
                    srccod: recuperationDataType.params.source,
                    dataType: recuperationDataType,
                    datmod: (data && data.datmod) || destination.data.datmod,
                    libelles: destination.libelles,
                    criteresSuggeres: options.criteresSuggeres,
                    filtres: options.filtres,
                    resourceParams: options.resourceParams,
                    colonnesVisibles: options.colonnesVisibles,
                    srccodref: destination.getSrccodref(),
                    ecranContext: {
                        stateParams: occurrence.stateParams,
                        ecranDetails: occurrence.ecranDetails,
                        ecranSourceDetails: occurrence.ecranSourceDetails
                    }
                };

                return DialogRecuperation.show({locals: dialogOptions})
                    .then(() => {
                        if (occurrence instanceof MultiOccurrence) {
                            occurrence.fetchDataList();
                        }

                        if (options.successAction) {
                            options.successAction();
                        }
                    });
            }

            function getDestination(occurrence: IOccurrence, data: any): IPromise<IMonoOccurrence> {
                if (occurrence instanceof MultiOccurrence) {
                    // Maitre détails
                    if (occurrence.multiOccurrenceParent && occurrence.multiOccurrenceParent.multiOccurrenceOptionsEnfant) {
                        const destination = new MonoOccurrence({
                            stateParams: occurrence.stateParams,
                            ecranDetails: occurrence.ecranDetails,
                            ecranSourceDetails: occurrence.ecranSourceDetails,
                            multiOccurrenceParent: occurrence.multiOccurrenceParent.multiOccurrenceParent,
                            srccod: occurrence.multiOccurrenceParent.srccod,
                            isEdition: occurrence.multiOccurrenceParent.isView,
                            id: occurrence.multiOccurrenceParent.activeRowCleint
                        });

                        return destination.ready
                            .then(() => {
                                return destination.data.$promise.then(() => {
                                    return destination;
                                });
                            })
                            .catch((error: IApiError) => {
                                afficherChargementErreur(error, destination, occurrence, data);

                                return $q.reject(error);
                            });
                    } else {
                        return $q.resolve(occurrence.monoOccurrenceParent);
                    }
                } else {
                    return $q.resolve(<IMonoOccurrence>occurrence);
                }
            }

            function afficherChargementErreur(error: IApiError, destination: IMonoOccurrence, occurrence: IOccurrence, data: any): void {
                notificationHandler.erreur({
                    error,
                    lblTitre: 'G_LBL_RECUPERER',
                    lblMessage: 'G_MSG_CHARG_DONNEES',
                    confirmAction() {
                        return retryMonoOccurrenceErreur(destination, occurrence)
                            .then(() => {
                                ouvrirDialogue(destination, occurrence, data);
                            });
                    }
                });
            }

            function retryMonoOccurrenceErreur(destination: IMonoOccurrence, occurrence: IOccurrence) {
                if (destination.initError) {
                    destination.init();

                    return destination.ready;
                } else {
                    destination.fetchData();

                    return destination.data.$promise;
                }
            }
        }
    }

    return MenuItemRecuperation;
}
