import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IPasswordDataTypeClass {
    new(options?: IPasswordDataTypeOptions): IPasswordDataType;
}

export interface IPasswordDataTypeOptions extends IDataTypeOptions {}

export interface IPasswordDataType extends IDataType {}

export default module('core.services.password-data-type', []).factory('PasswordDataType', PasswordDataTypeFactory);

/* @ngInject */
function PasswordDataTypeFactory(DataType: IDataTypeClass): IPasswordDataTypeClass {
    class PasswordDataType extends DataType implements IPasswordDataType {
        readonly template: string = `&#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;`;
    }

    return PasswordDataType;
}
