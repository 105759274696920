import {module, IScope} from 'angular';
import {IMonoOccurrence} from './mono-occurrence.service';
import {IInfoFinanciere} from '../../apps/sofe/components/ex-card-infos-financieres/ex-card-infos-financieres.controller';
import { IMultiOccurrenceOptions } from './multi-occurrence.service';
import { IFilterLibelle } from '../filters/ex-libelle.filter';
import { IEtatsResourcesEntite } from '../resources/etat.resource';

export interface IFonctionTransversaleService {
    fonctionsTransversales: IFonctionsTransversales;
    fonctionTransversaleChoisie: IFonctionTransversale;
    fonctionTransversaleChoisieData: any;
    closeFonctionTransversale(): void;
    openInfosFinancieres(index: number, infosFinancieres: Array<IInfoFinanciere>): void;
    openMultiOccurrence(multiOccurrenceOptions: IMultiOccurrenceOptions, lblTitre: string, etatSelected: IEtatsResourcesEntite, scope: IScope, onCloseMultiOccurrence: () => void): void;
}

interface IFonctionTransversale {
    icon: string;
    titre: string | ((monoOccurrence: IMonoOccurrence) => string);
}

interface IFonctionsTransversales {
    infosFinancieres?: IFonctionTransversale;
    multiOccurrence?: IFonctionTransversale;
}

export default module('core.services.fonction-transversale', []).factory('fonctionTransversale', FonctionTransversaleFactory);

/* @ngInject */
function FonctionTransversaleFactory(exLibelleFilter: IFilterLibelle): IFonctionTransversaleService {

    class FonctionTransversale {
        fonctionsTransversales: IFonctionsTransversales;
        fonctionTransversaleChoisie: IFonctionTransversale;
        fonctionTransversaleChoisieData: any;

        constructor() {
            this.fonctionsTransversales = {
                infosFinancieres: {
                    icon: 'sort',
                    titre: 'G_LBL_INFOS_FINANCIERES'
                },
                multiOccurrence: {
                    icon: 'format_list_bulleted',
                    titre: (monoOccurrence: IMonoOccurrence) => {
                        return exLibelleFilter(this.fonctionTransversaleChoisieData.lblTitre, monoOccurrence.libelles);
                    }
                }
            };
        }

        closeFonctionTransversale() {
            if (this.fonctionTransversaleChoisieData && this.fonctionTransversaleChoisieData.onClose) {
                this.fonctionTransversaleChoisieData.onClose();
            }
            this.fonctionTransversaleChoisie = null;
            this.fonctionTransversaleChoisieData = null;        
        }

        openInfosFinancieres(index: number, infosFinancieres: Array<IInfoFinanciere>) {
            this.fonctionTransversaleChoisieData = {
                index,
                infosFinancieres
            };

            this.fonctionTransversaleChoisie = this.fonctionsTransversales.infosFinancieres;
        }

        openMultiOccurrence(multiOccurrenceOptions: IMultiOccurrenceOptions, lblTitre: string, etatSelected: IEtatsResourcesEntite, scope: IScope, onClose: () => void) {
            this.fonctionTransversaleChoisieData = {
                multiOccurrenceOptions,
                lblTitre,
                onClose
            };
            this.fonctionTransversaleChoisie = this.fonctionsTransversales.multiOccurrence;

            const watchMultiOccurrence = scope.$watch(() => this.fonctionTransversaleChoisieData.multiOccurrence, () => {
                if (this.fonctionTransversaleChoisieData.multiOccurrence) {
                    watchMultiOccurrence();
                    this.fonctionTransversaleChoisieData.multiOccurrence.on('ready', () => {
                        this.fonctionTransversaleChoisieData.multiOccurrence.etatSelected = etatSelected;
                    });
                }
            });
        }
    }

    return new FonctionTransversale();
}
