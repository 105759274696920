import {module} from 'angular';
import {IStringDataType, IStringDataTypeClass, IStringDataTypeOptions} from './string-data-type.service';

export interface ITexteDataTypeClass {
    new(options?: ITexteDataTypeOptions): ITexteDataType;
}

export interface ITexteDataTypeOptions extends IStringDataTypeOptions {
    padStart?: {
        longueur: number;
        caractere: string;
    }
}

export interface ITexteDataType extends IStringDataType {
}

export default module('core.services.texte-data-type', []).factory('TexteDataType', TexteDataTypeFactory);

/* @ngInject */
function TexteDataTypeFactory(StringDataType: IStringDataTypeClass): ITexteDataTypeClass {
    class TexteDataType extends StringDataType implements ITexteDataType {
        constructor(options: ITexteDataTypeOptions = {}) {
            super(options);
        }
    }

    return TexteDataType;
}
