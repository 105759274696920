import {fromJson, IHttpHeadersGetter, module} from 'angular';
import {ISchemas} from '../interfaces/schemas.interface';
import {ILibelles, ILibellesClass} from '../services/libelles.service';
import {IDataTypeManager} from '../services/data-types/data-type-manager.service';
import {IDataTypeMap} from '../services/data-types/data-type.service';
import {IApiConfig} from '../interfaces/api-config.interface';
import {IReglesValidation} from '../interfaces/regles-validation.interface';
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;
import IResourceService = angular.resource.IResourceService;
import IResourceOptions = angular.resource.IResourceOptions;

export interface ISourceDetails {
    cleint: string;
    cycleCleint: string;
    cycleCleintParent: string;
    mnemonique: string;
    libelles: ILibelles;
    libellesDynamiques: boolean;
    messages: IMessages;
    schema: ISchemas;
    validatedFields: Array<string>;
    listenedFields: Array<string>;
    validationRules: IReglesValidation;
    dataTypes: IDataTypeMap;
    comportements: IComportementConfigMap;
    fonctionsEdition: IFonctionsEditionConfigMap;
    cycleParent: string;
    selections: Array<ISourceDetailsSelection>;
    procedureValeursDefautDefinie: boolean;
    colonnesEnteteTitre: any;
    isView: boolean;
    sourceDeveloppement: boolean;
    table: string;
}

export interface ISourceDetailsMap {
    [srccod: string]: ISourceDetails;
}

export interface ISourceDetailsResourcesEntite extends IResource<ISourceDetailsMap> {
    schema: ISchemas;
}

export interface IMessages {
    [cle: string]: string
}

interface ISourceDetailsSelection {
    nom: string;
    isDynamique: boolean;
}

export interface IComportementConfigMap {
    [cols: string]: IComportementConfig;
}

export interface IFonctionsEditionConfigMap {
    [cols: string]: IFonctionsEditionConfig;
}

export interface IFonctionsEditionConfig {
    [type: string]: boolean|string;
    nouveau: string;
    edition: string;
    supprime: string;
}

export interface IComportementConfig {
    readonly: boolean|string;
    required: boolean|string;
    disabled: boolean|string;
    hidden: boolean|string;
}

export interface ISourceDetailsResourcesClass extends IResourceClass<ISourceDetailsResourcesEntite> {
    new(data: any): ISourceDetailsResourcesEntite;
}

export default module('core.resources.source-details', [
    'core.services.api-config'
]).factory('SourceDetailsResource', SourceDetailsResourceFactory);

/* @ngInject */
function SourceDetailsResourceFactory(ApiConfig: IApiConfig,
                                      $resource: IResourceService,
                                      dataTypeManager: IDataTypeManager,
                                      Libelles: ILibellesClass): ISourceDetailsResourcesClass {
    const actions = {
        get: {
            method: 'get',
            transformResponse(data: string, headers: IHttpHeadersGetter, status: number) {
                const JSON = fromJson(data);

                if (status === 200) {
                    Object.keys(JSON).forEach((srccod: string) => {
                        const sourceDetails = JSON[srccod];
                        sourceDetails.libelles = new Libelles(sourceDetails.libelles);
                        sourceDetails.dataTypes = dataTypeManager.fromJson(sourceDetails.dataTypes, sourceDetails.schema);
                    });
                }
                return JSON;
            }
        }
    };
    const options: IResourceOptions = {};

    return <ISourceDetailsResourcesClass> $resource(`${ApiConfig.ROOT}/source-details/:srccod`, {}, actions, options);
}
