import {IDefaultsService} from '../../services/utils/defaults.service';
import {IApiConfig} from '../../interfaces/api-config.interface';
import {IAugmentedJQuery, IComponentController, IFormController, IPromise, IScope} from 'angular';
import {IParametresSecuriteService} from '../../services/parametres-securite.service';
import IResourceService = angular.resource.IResourceService;
import {IErrorTypes} from '../../constants/error-types.constant';

interface IDialogRequerant extends IComponentController {
    data: any;
    col: string
    cleint: string;
    formCtrl: IFormController;
    newValue: IRequerant;
    resourceParams: any;
    srccodref: string;
    stateParams: any;
    confirm(): any;
}

export interface IRequerant {
    intcleintreq: number;
    intcleintreq__intcod: string;
    intcleintreq__intnom: string;
}

/* @ngInject */
export default function DialogRequerantController(defaults: IDefaultsService,
                                                  ApiConfig: IApiConfig,
                                                  $scope: IScope,
                                                  $element: IAugmentedJQuery,
                                                  $resource: IResourceService,
                                                  ErrorTypes: IErrorTypes,
                                                  parametresSecurite: IParametresSecuriteService) {
    const vm: IDialogRequerant = this;

    vm.confirm = confirm;
    vm.$onInit = function $onInit() {
        defaults(vm, {
            newValue: {},
            resourceParams: {intcleintreq: vm.data.intcleintreq}
        });
    }

    $scope.$watch(`vm.newValue.intcleintreq`, () => {
        if (vm.newValue.intcleintreq) {
            return validateData()
                .catch((err) => {
                    vm.formCtrl[vm.col].$setValidity('validateApi', false);
                    if (err.data && err.data.code === 'SOFE-20004') {
                        vm.formCtrl[vm.col]['validateApi'] = err.data.message;
                        vm.formCtrl[vm.col]['$$SofeValidationMessage'] = err.data.message;
                        vm.formCtrl[vm.col]['$$SofeValidationType'] = ErrorTypes.INFORMATION;
                    }
                });
        } else if (vm.formCtrl) {
            vm.formCtrl[vm.col].$setValidity('validateApi', true);
            delete vm.formCtrl[vm.col]['$$SofeValidationMessage'];
            delete vm.formCtrl[vm.col]['$$SofeValidationType'];
        }
    });

    function confirm() {
        return vm.newValue;
    }

    function validateData(): IPromise<any> {
        const data = {
            cleintref: vm.data[vm.cleint],
            tydcleint: vm.data['tydcleint'],
            intcleintreq: vm.newValue.intcleintreq,
            srccod: vm.srccodref,
            ...parametresSecurite(vm.stateParams)
        };

        const validate = $resource(`${ApiConfig.ROOT}/actions-bouton-requerant/validate/`, data);

        return validate.get().$promise
            .then((result) => {
                if (!(result.err && result.err.length)) {
                    vm.formCtrl[vm.col].$setValidity('validateApi', true);
                    delete vm.formCtrl[vm.col]['$$SofeValidationMessage'];
                    delete vm.formCtrl[vm.col]['$$SofeValidationType'];
                } else {
                    vm.formCtrl[vm.col].$setValidity('validateApi', false);
                    vm.formCtrl[vm.col]['validateApi'] = result.err;
                    vm.formCtrl[vm.col]['$$SofeValidationMessage'] = result.err;
                    vm.formCtrl[vm.col]['$$SofeValidationType'] = ErrorTypes.ERREUR;
                }
            });
    }
}
