import {IFilterService, module} from 'angular';
import {IClonable} from '../interfaces/clonable.interface';
import {IOperateur, IOperateurService} from './operateur.service';

export interface IFiltreClass {
    new(options: IFiltreOptions): IFiltre;

    BOOLEAN_VALUES: { OUI: string, NON: string, TOUS: string };
    CLE_RECHERCHE_GLOBALE: string;
}

export interface IFiltre extends IClonable<IFiltre> {
    readonly id: number;
    readonly colonne: string;
    readonly operateur: IOperateur;
    readonly valeur: string | Array<string> | IFiltreValueFonction;
    readonly valeur2: string | IFiltreValueFonction;
    readonly readOnly: boolean;
    // Détermine un filtre dont la valeur est liée à un paramètre du URL
    readonly parametre: boolean;
    // Indique si le filtre est purement pour l'affichage sans appliquer un filtre sur les données. Uniquement valide
    // pour les filtres paramétrés pour l'instant.
    readonly affichage: boolean;

    //Visible permet d'afficher la pastille ou non
    readonly visible: boolean;
    readonly modifiable: boolean;
    readonly nonSupprimable: boolean;
    readonly data: any;

    predefini: boolean;
    toUrlAttribut(colonne?: string): string;
    toStringVal(): string;
    getValeur(): string;
    getValeur2(): string;
}

export interface IFiltreValueFonction {
    (): any;
}

export interface IFiltreOptions {
    colonne: string | Array<string>;
    data?: any;
    operateur?: IOperateur;
    valeur?: string | Array<string> | IFiltreValueFonction;
    valeur2?: string | IFiltreValueFonction;
    predefini?: boolean;
    readOnly?: boolean;
    parametre?: boolean;
    affichage?: boolean;
    visible?: boolean;
    modifiable?: boolean;
    nonSupprimable?: boolean;
}

export default module('core.services.filtre', [
    'core.services.operateur'
]).factory('Filtre', FiltreFactory);

/* @ngInject */
function FiltreFactory(exLibelleFilter: IFilterService,
                       Operateur: IOperateurService) {
    let count = 0;

    class Filtre implements IFiltre {
        readonly id: number;
        readonly colonne: string;
        readonly operateur: IOperateur;
        readonly valeur: string|Array<string>|IFiltreValueFonction;
        readonly valeur2: string | IFiltreValueFonction;
        readonly readOnly: boolean;
        readonly parametre: boolean;
        readonly affichage: boolean;
        readonly visible: boolean;
        readonly modifiable: boolean;
        readonly nonSupprimable: boolean;
        readonly data: any = {};

        predefini: boolean;

        static readonly BOOLEAN_VALUES = {
            OUI: 'oui',
            NON: 'non',
            TOUS: 'tous'
        };

        static readonly CLE_RECHERCHE_GLOBALE = '*';

        constructor(options: IFiltreOptions | IFiltre) {
            this.id = count++;
            this.colonne = (Array.isArray(options.colonne)) ? options.colonne.join(',') : options.colonne;
            this.operateur = options.operateur || Operateur.EGALE;
            this.valeur = (typeof options.valeur !== 'undefined') ? options.valeur : '';
            this.valeur2 = (typeof options.valeur2 !== 'undefined') ? options.valeur2 : '';
            this.predefini = options.predefini || false;
            this.readOnly = options.readOnly || false;
            this.parametre = options.parametre || false;
            this.affichage = options.affichage || false;
            this.visible = (typeof options.visible !== 'undefined') ? options.visible : true;
            this.modifiable = (typeof options.modifiable !== 'undefined') ? options.modifiable : true;
            this.nonSupprimable = (typeof options.nonSupprimable !== 'undefined') ? options.nonSupprimable : false;
            this.data = options.data || {[this.colonne]: this.valeur};
        }

        public toUrlAttribut(colonne: string = this.colonne): string {
            let path: string;

            if (this.operateur.isAucuneValeurOperateur()) {
                path = this.operateur.format(colonne);
            } else if (this.operateur.isValeurDoubleOperateur()) {
                path = this.operateur.format(colonne, this.getValeur(), this.getValeur2());
            } else {
                path = this.operateur.format(colonne, this.getValeur());
            }
            return path;
        }

        public getValeur(): string {
            return this.getData(this.valeur);
        }

        public getValeur2(): string {
            return this.getData(this.valeur2);
        }

        public toStringVal(): string {
            // Pas de valeur pour null et pas null
            if (this.operateur.isAucuneValeurOperateur()) {
                return '';
            }

            let tmpStr: string = this.getValeur();

            if (this.operateur.isValeurDoubleOperateur()) {
                const langET = exLibelleFilter('G_LBL_ET');
                tmpStr += ` ${langET} ${this.getValeur2()}`;
            }

            return tmpStr;
        }

        public clone(): IFiltre {
            return new Filtre(this);
        }

        private getData(data: string | Array<string> | IFiltreValueFonction) {
            if (typeof data === 'function') {
                return (<Function>data)();
            } else {
                return data;
            }
        }
    }
    return Filtre;
}
