/**
 * Composant permettant de genérer un select.
 *
 */
import {IComponentOptions, module} from 'angular';
import SelectColonneController from './ex-select-colonne.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-select-colonne', []).component('exSelectColonne', SelectColonneFactory());

function SelectColonneFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.SELECTEUR,
            Binding.LIBELLES,
            Binding.MODEL,
            Binding.DISABLED,
            Binding.MULTI_OCCURRENCE,
            {
                lblColonneLeft: '@?',
                lblColonneRight: '@?',
                lblPlaceholder: '@?',
                closeAction: '&',
                required:'<?'
            }
        ),
        require: {
            formCtrl: '^form',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: true,
        controller: SelectColonneController,
        controllerAs: 'vm',
        template: require('./ex-select-colonne.html')
    };
}
