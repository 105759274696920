import {module} from 'angular';
import {IClonable} from '../interfaces/clonable.interface';
import {TypeTri} from './tri.service';

export interface IColonneTriClass {
    new(nom: string, typeTri: TypeTri): IColonneTri;
}

export interface IColonneTri extends IClonable<IColonneTri> {
    nom: string;
    typeTri: TypeTri;
    triParDefaut?: boolean;
    switchTri(triCible?: TypeTri): void;
    isAsc(): boolean;
    isDesc(): boolean;
}

export interface IColonneTriOptions {
    nom: string;
    typeTri?: TypeTri;
    triParDefaut?: boolean;
}

const TYPE_TRI = {
    INITIAL: '' as TypeTri,
    ASC: '/asc' as TypeTri,
    DESC: '/desc' as TypeTri
};

export default module('core.services.colonne-tri', []).factory('ColonneTri', ColonneTriFactory);

/* @ngInject */
function ColonneTriFactory() {

    class ColonneTri implements IColonneTri {

        constructor(public nom: string,
                    public typeTri: TypeTri) {
        }

        clone(): IColonneTri {
            return new ColonneTri(this.nom, this.typeTri);
        }

        switchTri(triCible?: TypeTri): void {
            if (typeof triCible !== 'undefined') {
                this.typeTri = triCible;
            } else {
                if (this.typeTri === TYPE_TRI.ASC) {
                    this.typeTri = TYPE_TRI.DESC;
                } else if (this.typeTri === TYPE_TRI.DESC) {
                    this.typeTri = TYPE_TRI.INITIAL;
                } else {
                    this.typeTri = TYPE_TRI.ASC;
                }
            }
        }

        isAsc(): boolean {
            return this.typeTri === TYPE_TRI.ASC;
        }

        isDesc(): boolean {
            return this.typeTri === TYPE_TRI.DESC;
        }

    }

    return ColonneTri;
}
