/**
 * Pour une ché donnée, affiche la valeur dans un objet de libellé.
 */
import {IFilterUppercase, module} from 'angular';
import {ILibellesClass} from '../services/libelles.service';

export default module('core.filters.ex-libelle', []).filter('exLibelle', libelleFilter);

export interface IFilterLibelle {
    (key: string, libelles?: any, isHtml?: boolean, replacedValue?: string): string;
}

/* @ngInject */
function libelleFilter(uppercaseFilter: IFilterUppercase, Libelles: ILibellesClass): IFilterLibelle {
    return (key: string, libelles: any, isHtml?: boolean, replacedValue?: string): string => {
        if (typeof key === 'string') {
            // permet d'assigner les libellés globaux....
            if (!libelles) {
                libelles = Libelles.libellesGlobaux;
            }

            const formattedKey = uppercaseFilter(key);
            let libelleFound = key;

            if (libelles && libelles.$libelles && typeof libelles.$libelles[formattedKey] !== 'undefined') {
                libelleFound = libelles[formattedKey]
            } else if (Libelles.libellesGlobaux[formattedKey]) {
                libelleFound = Libelles.libellesGlobaux[formattedKey]
            }

            libelleFound = libelleFound.toString();

            if (!isHtml) {
                libelleFound = libelleFound.replace(/<\/?br\s?\/?>/gi, ' ').replace(/ +(?= )/g,'');
            }

            if (typeof replacedValue !== 'undefined') {
                libelleFound = libelleFound.replace(/\|V\|/gi, replacedValue);
            }

            return libelleFound;
        }
    }
}
