import { IComponentController, IFormController } from 'angular';
import { IDialog } from '../../services/dialog.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IData } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IDialogOptions = angular.material.IDialogOptions;

interface IComposantInputLovIntervenant extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    dataType: IDataType;
    srccodref: string;
    col: string;
    data: IData;
    icon: string;
    lblTitre: string;
    loading: boolean;
    formCtrl: IFormController;
    resourceParams: any;
    openDialogSelectionIntervenantAction(ev: MouseEvent): void;
}

/* @ngInject */
export default function InputLovIntervenantController(defaults: IDefaultsService,
    DialogSelectionIntervenant: IDialog) {
    const vm: IComposantInputLovIntervenant = this;

    vm.$onInit = $onInit;
    vm.openDialogSelectionIntervenantAction = openDialogSelectionIntervenantAction;

    function $onInit() {
        defaults(vm, {
            lblTitre: 'G_LBL_SELECTION_INTERVENANT',
            icon: 'featured_play_list'
        });
    }

    function openDialogSelectionIntervenantAction(ev: MouseEvent): void {
        if (!vm.updating) {
            openDialogSelectionIntervenant(ev);
        } else {
            //On doit attendre que le champ soit mis à jour.
            setTimeout(() => {
                openDialogSelectionIntervenantAction(ev);
            });
        }
    }

    function openDialogSelectionIntervenant(ev: MouseEvent): void {
        const dialogOptions: IDialogOptions = {
            locals: {
                srccodref: vm.srccodref,
                col: vm.col,
                lblTitre: vm.lblTitre,
                targetEvent: ev,
                dataType: vm.dataType,
                ecranContextCtrl: vm.ecranContextCtrl
            },
            multiple: true
        };

        DialogSelectionIntervenant.show(dialogOptions).then((data: any) => {
            vm.loading = true;
            updateValues(data);
        }).finally(() => vm.loading = false);
    }

    function updateValues(data: any) {
        Object.assign(vm.data, data);
        vm.formCtrl[vm.nameElement].$viewValue = data;
        vm.formCtrl[vm.nameElement].$commitViewValue();
        vm.formCtrl[vm.nameElement].$render();//Affiche le changement dans le input
    }
}
