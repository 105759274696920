import { IComponentController, IRootScopeService, IScope } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { ITimeoutService } from 'angular';
export interface ISaisieController extends IComponentController {
    showTitle: boolean;
    isCritereSuggere: boolean;
    lblTitre: any;
    resetTitre: any;
    annulerAction(): void;
    getLblTitre(): string;
}

/* @ngInject */
export default function SaisieController(defaults: IDefaultsService,
    $rootScope: IRootScopeService,
    $timeout: ITimeoutService,
    $scope: IScope) {
    const vm: ISaisieController = this;

    vm.$onInit = $onInit;
    vm.annulerAction = annulerAction;
    vm.getLblTitre = getLblTitre;
    function $onInit() {
        defaults(vm, {
            showTitle: true
        });
    }
    function annulerAction() {
        $rootScope.$broadcast('annulerActionCriteresSuggeres');
    }
    function getLblTitre() {
        //la propriete resetTitre sert à enlever la valeur par defaut "- Saisie" (G_LBL_SAISIE)
        //qui est mis dans le template <ex-edition {{vm.lblTitre}} - {{'G_LBL_SAISIE' | exLibelle}}
        if (vm.resetTitre) {
            const result = vm.lblTitre.split("-")
            if (result && Array.isArray(result) && result.length > 0) {
                vm.lblTitre = result[0]
            }
        }
        return vm.lblTitre;
    }
}
