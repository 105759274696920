/**
 * Ces constantes définissent des interfaces pour les attributs de nos composants. Le but est de s'assurer de
 * l'uniformité de nos interfaces au niveau des bindings.
 *
 * Ces constantes ne devraient pas être accédées directement, mais à travers le service `Binding`.
 *
 * Règles à suivre:
 *
 * - Les noms définis dans ces constantes ne peuvent pas être réutilisé dans d'autre cas.
 * - Si un composant à un callback principal, appeler cet attribut 'action'
 * - Si un composant à plusieurs callbacks, terminer le nom de l'attribut avec 'Action', e.g. confirmAction
 * - Les libellés commence par 'lbl', e.g. `lblConfirm`. Ils doivent être des bindings de type string (@).
 *   La valeur est soit : 1) le texte à afficher ; 2) ou une clé sur un object libellés passé via 'libelles'.
 * - À noter: Angular enlève le prefix 'data-' lorsque présent, alors il faut prendre en compte
 */
import {module} from 'angular';

export interface IBindingConfig {
    [binding: string]: string
}

export interface IBindingsConfig<T> {
    API_NAME: T;
    CHAMPS_TRANSACTIONNELS: T;
    CHANGE_ACTION: T;
    CLEINT: T;
    COL: T;
    COLLAPSABLE: T;
    COLS: T;
    COLS_EXCLU: T;
    COLSPAN: T;
    DATA: T;
    DATA_ITEM: T;
    DATA_ITEM_LIST: T;
    DATA_LIST: T;
    DATA_TYPE: T;
    DATA_TYPES: T;
    DISABLED: T;
    EXPANDABLE: T;
    FILTRE: T;
    FORMULAIRE: T;
    GAP: T;
    HEIGHT: T;
    ICON: T;
    LAYOUT: T;
    LIBELLES: T;
    LIMITE: T;
    MENU: T;
    MENUS: T;
    MODEL: T;
    MODEL_OPTIONS: T;
    MASQUE: T;
    MONO_OCCURRENCE: T;
    MONO_OCCURRENCE_OPTIONS: T;
    MULTI_OCCURRENCE: T;
    MULTI_OCCURRENCE_OPTIONS: T;
    NAME_ELEMENT: T;
    NO_HEADER: T;
    OCCURRENCE: T;
    OPENED: T;
    READONLY: T;
    RECHERCHE: T;
    REGLES_VALIDATION: T;
    REGLES_VALIDATION_ITEM: T;
    SCHEMA_ITEM: T;
    SCHEMAS: T;
    SELECTEUR: T;
    TRI: T;
    VALUE: T;
    VISIBLE: T;
    WIDTH: T;
    LISTE_DETAILS_CSS: T;
    [binding: string]: T
}

export const BindingsConfig: IBindingsConfig<IBindingConfig> = {
    /** Un tri de type `services.Tri` */
    TRI: {tri: '<'},

    /** Un selecteur de type `services.Selecteur` */
    FILTRE: {filtre: '<'},

    /** Un selecteur de type `services.Selecteur` */
    SELECTEUR: {selecteur: '<'},

    /** Un multiOccurrence de type `services.MultiOccurrence` */
    MULTI_OCCURRENCE: {multiOccurrence: '<'},

    /** Les options d'un MultiOccurrence */
    MULTI_OCCURRENCE_OPTIONS: {multiOccurrenceOptions: '<'},

    /** Un monoOccurrence de type `services.MonoOccurrence` */
    MONO_OCCURRENCE: {monoOccurrence: '<'},

    /** Les options d'un MonoOccurrence */
    MONO_OCCURRENCE_OPTIONS: {monoOccurrenceOptions: '<'},

    /** Un monoOccurrence ou un multiOccurrence */
    OCCURRENCE: {occurrence: '<'},

    /** Une recherche de type `services.Recherche` */
    RECHERCHE: {recherche: '<'},

    /** Un menu de type `services.menu` */
    MENU: {menu: '<'},

    /** Une map de Menu ou MenuItem */
    MENUS: {menus: '<'},

    /** Un formulaire de type `service.formulaire` */
    FORMULAIRE: {formulaire: '<'},

    /** Contient Le nom d'une colonne de BD */
    COL: {col: '@'},

    /** Contient Une list de nom de colonne de BD */
    COLS: {cols: '<'},

    /** Contient Une list de nom de colonne de BD à exclure */
    COLS_EXCLU: {colsExclu: '<'},

    /** Une map de libellés */
    LIBELLES: {libelles: '<'},

    /** Limite (par exemple pour un tableau) */
    LIMITE: {limite: '@'},

    /** Contient le nom d'un element HTML */
    NAME_ELEMENT: {nameElement: '@'},

    /** Si le header du composant est optionnel */
    NO_HEADER: {noHeader: '<'},

    /** Si un composant est collapsable */
    COLLAPSABLE: {collapsable: '='},

    /** Contrôle l'ouverture d'un élément */
    OPENED: {opened: '<'},

    /** Fonctionne qui permet le on change*/
    CHANGE_ACTION: {changeAction: '&'},
    /** Si le composant est expandable via `behaviors.ex-expandable`. La valeur passée est le nom de l'expandable.  */
    EXPANDABLE: {expandable: '@'},

    /** Un icône material */
    ICON: {icon: '@'},

    /** Le nombre de colonnes sur laquel un composant s'étend */
    COLSPAN: {
        colspan: '<',
        smColspan: '<'
    },

    /** La taille du gap d'un composant en pixels */
    GAP: {
        gap: '<'
    },

    /** Un schemas de BD */
    SCHEMAS: {
        schemas: '<'
    },

    /** Un schemas item de BD */
    SCHEMA_ITEM: {
        schemaItem: '<'
    },

    /** Un object de données */
    DATA: {
        data: '<'
    },

    /** Une liste d'object de données */
    DATA_LIST: {
        dataList: '<list'
    },

    /** Un IDataItem */
    DATA_ITEM: {
        dataItem: '<item'
    },

    /** Une liste de IDataItem */
    DATA_ITEM_LIST: {
        dataItemList: '<itemList'
    },

    /** La clé interne */
    CLEINT: {
        cleint: '@'
    },

    /** La valeur d'un contrôle qui collecte le input d'un utilisateur */
    VALUE: {
        value: '='
    },

    /** Le nom d'un API serveur */
    API_NAME: {
        apiName: '@'
    },

    /** Un model de type `ng-model` */
    MODEL: {
        model: '='
    },

    /** Un model options de type `ng-model-options` */
    MODEL_OPTIONS: {
        modelOptions: '<'
    },

    /** Un model de type `ng-model` */
    MASQUE: {
        masque: '<'
    },

    /** Un object constitué de noms de colonnes et leur type, qui est une instance dérivant de Colonne*/
    DATA_TYPES: {
        dataTypes: '<types'
    },

    /** Un object constitué d'un nom de colonnes et d'un type, qui est une instance dérivant de Colonne*/
    DATA_TYPE: {
        dataType: '<type'
    },

    /** Un composant qui défini une hauteur */
    HEIGHT: {
        height: '<'
    },

    /** Le type de layout du composant */
    LAYOUT: {
        layout: '@'
    },

    /** Si le composant est désactivé */
    DISABLED: {
        disabled: '<ngDisabled'
    },

    /** Si le composant est en lecture seule */
    READONLY: {
        readonly: '<exReadonly'
    },

    /** Règles de validation de tous les champs */
    REGLES_VALIDATION: {
        reglesValidation: '<'
    },

    /** Règles de validation d'un champ */
    REGLES_VALIDATION_ITEM: {
        reglesValidationItem: '<'
    },

    /** Champs transactionnels */
    CHAMPS_TRANSACTIONNELS: {
        champsTransactionnels: '<'
    },

    VISIBLE: {
        visible: '<'
    },

    /** Un composant qui défini une largeur */
    WIDTH: {
        width: '<'
    },
    
    /** Contient une liste des champs à utiliser dans l'écran pour appliquer une class css particuliere*/
    LISTE_DETAILS_CSS:{
        listeDetailsCss:'@'
    }
};

export default module('core.constants.bindings-config', []).constant('BindingsConfig', BindingsConfig);
