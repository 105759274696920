import { IComponentController, IFormController, IScope } from 'angular';
import {
    IMultiOccurrence,
    IMultiOccurrenceClass,
    IMultiOccurrenceOptions
} from '../../services/multi-occurrence.service';
import { IDataType } from '../../services/data-types/data-type.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IData } from '../../services/data-linker.service';
import { IFormulaireItemResourceParamsFonction } from '../../services/formulaire/formulaire-item.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IPaginationClass } from '../../services/pagination.service';

export interface IComposantSelectionMultipleAdvanced extends IComponentController {
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    multiOccurrence: IMultiOccurrence;
    data: IData;
    dataType: IDataType;
    srccodref: string;
    resourceParams: any | IFormulaireItemResourceParamsFonction;
    ecranContextCtrl: IEcranContextController;
    required: boolean;
    formCtrl: IFormController;
    nameElement: string;
    value: Array<any>;
}

/* @ngInject */
export default function SelectionMultipleAdvancedController(ApiConfig: IApiConfig,
    MultiOccurrence: IMultiOccurrenceClass,
    Pagination: IPaginationClass,
    $scope: IScope) {
    const vm: IComposantSelectionMultipleAdvanced = this;

    vm.$onInit = function $onInit() {
        vm.multiOccurrenceOptions = {
            srccod: `lov-${vm.dataType.params.source}`,
            nomSourceDetails: `lov-${vm.dataType.params.source}`,
            multiOccurrenceParent: vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.multiOccurrenceParent,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            colonnesVisibles: [...vm.dataType.params.colonnes],
            rangeesSelectionnables: true,
            fonctions: {
                colonnesToujoursVisibles: true,
                recherche: false,
                selectionnerUnEtat: false,
                pagination: false,
                selectionnerToutesLesRangees: false,
                limiteTotalSurRangeesSelectionnees: true
            },
            pagination: new Pagination({
                nombreElementPage: 1000,
                nbElementsPossibles: [1000]
            }),
            resourceParams: {
                srccod: vm.srccodref,
            },
            resourceParamsDynamique: () => ({
                ...getResourceParamsValues(),
            })
        };

        vm.multiOccurrence = new MultiOccurrence(Object.assign({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails
        }, vm.multiOccurrenceOptions));

        vm.multiOccurrence.once('ready', () => {
            if (vm.value) {
                initDefaultValue();
            }

            if (vm.monoOccurrenceCtrl) {
                vm.multiOccurrence.listenedFields.push(...vm.monoOccurrenceCtrl.monoOccurrence.listenedFields.filter((col: string) => col.startsWith(`${vm.multiOccurrence.mnemonique.toLowerCase()}_`)));
            }

            vm.multiOccurrence.on('rowSelectionChange', (id: number) => {
                updateSelection(id);
            });
        });

        $scope.$watch('::vm.formCtrl[vm.nameElement]', () => {
            if (vm.formCtrl[vm.nameElement]) {
                vm.formCtrl[vm.nameElement].$validators.required = function () {
                    return !vm.required || (vm.value && vm.value.length);
                };
            }
        });

        $scope.$on('$destroy', () => {
            vm.multiOccurrence.removeAllListeners();
        });
    }

    function getResourceParamsValues(): any {
        if (vm.resourceParams instanceof Function) {
            return vm.resourceParams(vm.data);
        } else {
            return vm.resourceParams;
        }
    }

    function updateSelection(id: number) {
        selectUpdatedRow(id);
        const values = vm.multiOccurrence.dataList
            .filter((rowDetails: any) => rowDetails[vm.multiOccurrence.selectionColonne])
            .map((rowDetails: any) => {
                return Object.keys(rowDetails)
                    .reduce((data: any, key: string) => {
                        if (!key.startsWith('$')) {
                            data[key] = rowDetails[key];
                        }
                        return data;
                    }, {});
            });

        vm.value = (values && values.length) ? values : null;
    }

    function selectUpdatedRow(id: number) {
        const cleint = vm.multiOccurrence.cleint;
        const row = vm.multiOccurrence.dataList.find((row: any) => row[cleint] === id);

        if (row && typeof row[vm.multiOccurrence.selectionColonne] === 'undefined') {
            row[vm.multiOccurrence.selectionColonne] = true;
        }
    }

    function initDefaultValue() {
        vm.multiOccurrence.once('dataListUpdate', () => {
            const cleint = vm.multiOccurrence.cleint;
            vm.value.forEach((row: any) => {
                const rowId = row[cleint];
                const rowData = vm.multiOccurrence.dataList.find((rowDetails) => rowDetails[cleint] === rowId);

                if (rowData) {
                    rowData[vm.multiOccurrence.selectionColonne] = true;
                    Object.assign(rowData, row);
                }
            });
        });
    }
}
