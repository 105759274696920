import {module} from 'angular';

export interface IErrorTypes {
    AVERTISSEMENT: string;
    ERREUR: string;
    INFORMATION: string;
}
const ERROR_TYPES  = {
    AVERTISSEMENT: 'A',
    ERREUR: 'E',
    INFORMATION: 'I'
};

export default module('core.constants.error-types', []).constant('ErrorTypes', ERROR_TYPES);
