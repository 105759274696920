import * as angular from 'angular';
import {module} from 'angular';
import {IDataType} from './data-type.service';
import {IStringDataTypeClass, IStringDataTypeOptions} from './string-data-type.service';

export interface ISequenceDataTypeClass {
    new(options: ISequenceDataTypeOptions): ISequenceDataType;
}

export interface ISequenceDataTypeOptions extends IStringDataTypeOptions {
    params: ISequenceDataTypeParams;
}

export interface ISequenceDataTypeParams {
    type: string;
    decimals?: number;
}

export interface ISequenceDataType extends IDataType {
}

export default module('core.services.sequence-data-type', []).factory('SequenceDataType', SequenceDataTypeFactory);

/* @ngInject */
function SequenceDataTypeFactory(StringDataType: IStringDataTypeClass): ISequenceDataTypeClass {
    class SequenceDataType extends StringDataType implements ISequenceDataType {

        constructor(options: ISequenceDataTypeOptions) {
            super(angular.extend({}, options, {
                padStart: {
                    longueur:  3,
                    caractere: '0',
                    decimales: options.params.decimals
                }
            }));
        }
    }

    return SequenceDataType;
}
