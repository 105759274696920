import {IDirective, IScope, module} from 'angular';

export default module('core.behaviors.ex-type-lettre', [])
    .directive('exTypeLettre', TypeLettreDirective);

/* @ngInject */
function TypeLettreDirective(): IDirective {

    return {
        restrict: 'A',
        bindToController: true,
        controller: () => {},
        link
    };

    function link(scope: IScope, element: JQuery, attrs: any) {
        const typeLettre = attrs.exTypeLettre;
        const majuscule = '1';
        const minuscule = '2';

        if (typeLettre === majuscule || typeLettre === minuscule) {
            element.bind('blur', function () {
                const valeur = String(element.val());

                if (valeur) {
                    let nouvelleValeur;

                    if (typeLettre === majuscule) {
                        nouvelleValeur = valeur.toUpperCase();
                    } else if (typeLettre === minuscule) {
                        nouvelleValeur = valeur.toLowerCase();
                    }

                    if (valeur !== nouvelleValeur) {
                        element.val(nouvelleValeur);
                        element.trigger('change');
                    }
                }
            });
        }
    }
}
