import {module} from 'angular';
import {ISourceDetailsResourcesClass, ISourceDetailsResourcesEntite} from '../resources/source-details.resource';

export default module('core.services.ecran-source-details', [
]).factory('ecranSourceDetails', EcranSourceDetailsServiceFactory);

/* @ngInject */
function EcranSourceDetailsServiceFactory(SourceDetailsResource: ISourceDetailsResourcesClass): ISourceDetailsResourcesEntite {
    return new SourceDetailsResource();
}
