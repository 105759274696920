const autoScroll = require('dom-autoscroller');

interface IAutoScrollOptions {
    margin?: number;
    maxSpeed?: number;
}

export function applyAutoScroll(domElements: Array<HTMLElement>, options: IAutoScrollOptions = {}): void {
    autoScroll(domElements,
        {
            margin: options.margin || 5,
            maxSpeed: options.maxSpeed || 10,
            scrollWhenOutside: true,
            autoScroll: function () {
                //Only scroll when the pointer is down
                return this.down;
            }
        });
}
