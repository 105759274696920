import {module} from 'angular';
import {IFormulaire} from './formulaire.service';

export interface IFormulaireLovClass {
    new(col: string, source: string, formulaire: IFormulaire, options?: IFormulaireLovOptions): IFormulaireLov;
}

export interface IFormulaireLov {
    readonly col: string;
    readonly source: string;
    readonly formulaire: IFormulaire;
}

export interface IFormulaireLovOptions {
    readonly largeur?: number | string;
}

export default module('core.services.formulaire-lov', []).factory('FormulaireLov', FormulaireLovFactory);

/* @ngInject */
function FormulaireLovFactory() {

    class FormulaireLov implements IFormulaireLov {
        readonly col: string;
        readonly source: string;
        readonly formulaire: IFormulaire;
        readonly largeur: number | string = 100;

        constructor(bloc: string, source: string, formulaire: IFormulaire, options: IFormulaireLovOptions = {}) {
            this.col = bloc;
            this.source = source;
            this.formulaire = formulaire;

            if (typeof options.largeur !== 'undefined') {
                this.largeur = options.largeur;
            }
        }
    }

    return FormulaireLov;
}
