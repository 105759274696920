import {IComponentOptions, module} from 'angular';
import CaseDescriptionController from './ex-case-description.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-case-description', []).component('exCaseDescription', CaseDescriptionFactory());

/**
 * Représente une case contenant une description
 */
function CaseDescriptionFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            {
                secondaire: '<?'
            }
        ),
        transclude: true,
        require: {
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: CaseDescriptionController,
        template: require('./ex-case-description.html')
    };
}
