import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import SuiviModificationController from './ex-suivi-modification.controller';

export default module('core.components.ex-suivi-modification', []).component('exSuiviModification', SuiviModificationFactory());

function SuiviModificationFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA
        ),
        controllerAs: 'vm',
        controller: SuiviModificationController,
        template: require('./ex-suivi-modification.html')
    };
}
