import {IComponentController, IAugmentedJQuery, IFormController, ITimeoutService, IScope} from 'angular';
import {ITypesFichiersFunction} from "../../services/menu/menu-item-action-importation.service";
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import Dropzone, { DropzoneFile } from 'dropzone';

interface IComposantInputFileUpload extends IComponentController {
    nameElement: string;
    allowsMultiple: boolean;
    data: any;
    inputFileUploadPattern?: Array<string>|ITypesFichiersFunction;
    value: File|Array<File>;
    autoSave: boolean;
    dropzone: Dropzone;
    formCtrl: IFormController;
}

/* @ngInject */
export default function InputFileUploadController(exLibelleFilter: IFilterLibelle,
                                                  $element: IAugmentedJQuery,
                                                  $timeout: ITimeoutService,
                                                  $scope: IScope) {
    const vm: IComposantInputFileUpload = this;

    vm.$onInit = $onInit;

    function $onInit() {
        vm.dropzone = new Dropzone($element.find('.dropzone')[0], {
            //On a besoin d'une url ici, sinon Dropzone panique. Par contre on ne s'en sert pas, on gère manuellement avec autoProcessQueue: false
            url: '.',
            autoProcessQueue: false,
            addRemoveLinks: !vm.autoSave,
            dictRemoveFile: exLibelleFilter('G_LBL_BTN_SUPPRIMER'),
            dictMaxFilesExceeded: exLibelleFilter('G_LBL_1FICHIER_MAX'),
            dictInvalidFileType: exLibelleFilter('G_LBL_TYPE_FICHIER_NON_PERMIS'),
            maxFiles: vm.allowsMultiple ? Number.MAX_VALUE : 1,
            acceptedFiles: vm.inputFileUploadPattern ? getTypesFichiers() : undefined,
            hiddenInputContainer: $element[0],
            createImageThumbnails: !vm.autoSave
        });

        vm.dropzone.on('addedfile', async (file) => {
            saveFile(file, file);
        });

        vm.dropzone.on("removedfile", (file) => {
            if (!vm.saveAction || !vm.autoSave) {
                if (!vm.allowsMultiple || (vm.value && (vm.value as File[]).length === 1)) {
                    vm.value = null;
                } else if (vm.value) {
                    (vm.value as File[]).splice((vm.value as File[]).findIndex((f) => f.name === file.name), 1);
                }

                forceValidation();
            }
        })

        $scope.$watch('vm.value', () => {
            if (!vm.value) {
                vm.dropzone.removeAllFiles()
            }
        })
    }

    async function saveFile(file: DropzoneFile, croppedFile: File) {
        if (vm.saveAction && vm.autoSave) {
            await vm.saveAction([croppedFile])
            vm.dropzone.removeFile(file);
        } else {
            if (!vm.allowsMultiple) {
                vm.value = croppedFile;
            } else if (!vm.value) {
                vm.value = [croppedFile];
            } else {
                (vm.value as File[]).push(croppedFile);
            }

            forceValidation();
        }
    }

    // C'est nécessaire pour que le fichier (vm.value) ait eu le temps de se rendre dans le formCtrl à jour :'(
    function forceValidation() {
        $timeout(() => {
            if (vm.formCtrl['fichier']) {
                vm.formCtrl['fichier'].$validate();
            } else if (vm.formCtrl['newPieceJointe']) {
                vm.formCtrl['newPieceJointe'].$validate();
            }
        });
    }

    function getTypesFichiers(): string {
        if (vm.inputFileUploadPattern instanceof Function) {
            return vm.inputFileUploadPattern(vm.data)
                .map((type: string) => {
                    const ext = type.toLowerCase();
                    return (ext.startsWith('.') || ext === '*') ? ext : `.${ext}`;
                })
                .join(',')
        } else {
            return vm.inputFileUploadPattern.join(',');
        }
    }
}
