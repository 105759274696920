import {
    IAugmentedJQuery,
    ICompileService,
    IDocumentService,
    IHttpResponse,
    INgModelController,
    IPromise,
    IQService,
    IScope,
    ITimeoutService,
    IWindowService,
    module,
    INgModelOptions
} from 'angular';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IOperateurService } from '../../services/operateur.service';
import { IPaginationClass } from '../../services/pagination.service';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IDataType } from '../../services/data-types/data-type.service';

interface IComportementIntrospection extends IScope {
    visible: boolean;
    opened: boolean;
    loading: boolean;
    inputDisabled: boolean;
    multiOccurrence: IMultiOccurrence;
    schemaItem: ISchemaItem;
    suggestions: Array<string>;
    model: INgModelController;
    modelOptions: INgModelOptions;
    activeSuggestion: number;
    gettingMore: boolean;
    hasMore: boolean;
    disabled: boolean;
    dataType: IDataType;
    selectSuggestion(suggestion: string): void;
    toggleSuggestions(): void;
    getMore(): void;
    isStringColonne(): boolean;
}

interface IComportementIntrospectionOptions {
    dataType: IDataType;
    onSelect: Function;
}

interface IComportementIntrospectionCache {
    [col: string]: {
        [val: string]: {
            valeur: Array<string>,
            page: number,
            hasMore: boolean
        }
    };
}

export default module('core.behaviors.ex-introspection', [])
    .directive('exIntrospection', IntrospectionDirective);

/* @ngInject */
function IntrospectionDirective($compile: ICompileService,
    $document: IDocumentService,
    $timeout: ITimeoutService,
    $window: IWindowService,
    Filtre: IFiltreClass,
    Operateur: IOperateurService,
    Pagination: IPaginationClass,
    $mdUtil: any,
    $q: IQService,
    keyCodes: IKeyCodes) {

    const SUGGESTIONS_PAR_PAGE = 10;

    const INTROSPECTION_ACTIVE_CLASS = 'ex-introspection--active';
    const INTROSPECTION_CONTENT_CLASS = 'ex-introspection-content';

    return {
        restrict: 'A',
        link
    };

    function link(scope: IScope, element: IAugmentedJQuery, attrs: any) {
        // Parce qu'une seule directive peut avoir un scope isolé par élément, il faut le gérer nous-même
        const vm: IComportementIntrospection = <IComportementIntrospection>scope.$new(true);

        // On garde les données de recherche pour éviter de faire plusieurs requêtes identiques
        const cache: IComportementIntrospectionCache = {};

        let options: IComportementIntrospectionOptions = scope.$eval(attrs.exIntrospectionOptions) || {};
        let autocomplete: IAugmentedJQuery;
        let pagination = new Pagination({
            pageCourante: 1,
            nombreElementPage: SUGGESTIONS_PAR_PAGE
        });
        let lastValue: string;
        const container = $document.find('body');
        // On peut appliquer l'introspection sur le champ lui-même, ou sur un parent.
        const input: IAugmentedJQuery = (element[0].tagName.toLowerCase() === 'input') ?
            element :
            element.find('input');
        const ngModelCtrl: INgModelController = input.controller('ngModel');
        const ngModelOptions: INgModelOptions = input.controller('ngModelOptions');
        // On ne veut pas mettre à jour les données quand l'usager tape rapidement, alors on limite le débit des appels
        const debouncedFetchData = $mdUtil.debounce(fetchData, 150);
        // L'évènement "resize" est lancé trop souvent, alors on limite le débit
        const debouncedResize = $mdUtil.debounce(positionAutocomplete, 100);

        vm.model = ngModelCtrl;
        vm.modelOptions = getModelOptions(ngModelOptions);
        vm.activeSuggestion = -1;
        vm.gettingMore = false;
        vm.hasMore = false;
        vm.selectSuggestion = selectSuggestion;
        vm.toggleSuggestions = toggleSuggestions;
        vm.getMore = getMore;
        vm.isStringColonne = isStringColonne;

        vm.opened = false;

        scope.$watch(attrs.schemaItem, (schemaItem: ISchemaItem) => {
            vm.schemaItem = schemaItem;

            if (schemaItem) {
                // On prépare le cache
                cache[schemaItem.column] = cache[schemaItem.column] || {};
            }
        });

        scope.$watch(attrs.exIntrospectionOptions, (options: any) => {
            if (options && options.dataType) {
                vm.dataType = options.dataType;
            }
        });

        scope.$watch(attrs.exIntrospection, (multiOccurrence: IMultiOccurrence) => {
            vm.multiOccurrence = multiOccurrence;

            if (!multiOccurrence.fonctions.introspection) {
                vm.disabled = true;
            } else {
                initUpdateListeners();
            }
        });

        scope.$watch(() => input.prop('disabled'), (disabled: boolean) => {
            vm.inputDisabled = disabled;
        });

        scope.$watch(() => (input[0] as HTMLInputElement).value, (value) => {
            if (!value) {
                closeAutocomplete()
            }
        });

        element.on('$destroy', () => {
            vm.multiOccurrence.removeListener('dataListUpdate', fetchData);
            input.off();
            autocomplete.off();
            vm.$destroy();
        });
        autocomplete = $compile(`<div class="ex-introspection" ng-show="opened">
            <md-progress-circular ng-if="!suggestions && loading" class="p-8 layout-column layout-align-center-center" md-diameter="20"></md-progress-circular>
            <div class="${INTROSPECTION_CONTENT_CLASS}">
                <div class="ex-introspection-item" ng-repeat="suggestion in suggestions"
                    ng-class="{'ex-introspection-item--active': activeSuggestion === $index}"
                    ng-click="selectSuggestion(suggestion)"
                    name="{{$index}}">{{ suggestion | exMasque: dataType.params.masque }}</div>
            </div>
            <span ex-busy="vm.gettingMore"></span>
        </div>`)(vm);

        const buttons = $compile(`<md-button ng-if="isStringColonne()" class="md-icon-button ex-introspection-button" ng-click="toggleSuggestions()" ng-disabled="inputDisabled"><md-icon>arrow_drop_down</md-icon></md-button>`)(vm);

        container.append(autocomplete);
        input.after(buttons);

        // Quand la fenêtre change de taille, le champ risque de changer de position et de taille. On doit donc
        // repositionner l'autocomplete.
        $window.addEventListener('resize', debouncedResize);

        // Quand le scope est détruit, il faut s'assurer de détruire les listenners globaux
        element.on('$destroy', () => {
            autocomplete.remove();
            $window.removeEventListener('resize', debouncedResize);
        });

        function positionAutocomplete(): void {
            autocomplete.width(element.outerWidth());
            const inputOffset = input.offset();
            autocomplete.css({
                left: inputOffset.left,
                top: inputOffset.top + input.outerHeight(),
            });
        }

        function getModelOptions(modelOptions: any): INgModelOptions {
            if (!modelOptions) { return null; }
            return modelOptions['$options']['$$options'] as INgModelOptions;
        }

        input.on('input', function (event: JQueryEventObject, params: { exInstrospection?: boolean } = {}) {
            const debounce = vm.modelOptions ? vm.modelOptions.debounce as number : 0;

            // Si 'exInstrospection' est défini, c'est un évènement que nous générons et que l'on veut ignorer
            if (!params.exInstrospection) {
                setTimeout(() => {
                    // Si les suggestions ne sont pas ouvertes, et que la longueur est au moins 3 caractères, on
                    // affiche les suggestions
                    if (!vm.opened && String(vm.model.$modelValue || '').length >= 3) {
                        // On est en dehors d'un digest, alors il faut l'appliquer
                        scope.$applyAsync(() => {
                            openAutocomplete();
                        });
                    } else if (vm.opened && lastValue !== vm.model.$modelValue) {
                        // On est en dehors d'un digest, alors il faut l'appliquer
                        scope.$applyAsync(() => {
                            pagination.pageCourante = 1;
                            autocomplete.scrollTop(0);

                            // Si les données ont changées et que c'est vide, c'est probable que le champ vient d'être vidé. Dans ce
                            // cas, l'expérience est meilleure si on recharge tout de suite
                            if (!vm.model.$modelValue) {
                                fetchData();
                            } else {
                                debouncedFetchData();
                            }
                        });
                    }
                }, debounce);
            }
        });

        input.on('click', () => {
            // Si les suggestions sont ouvertes et que l'on clique dans le champ, on enlève le focus des suggestions
            if (vm.opened && vm.activeSuggestion > -1) {
                scope.$applyAsync(() => {
                    vm.activeSuggestion = -1;
                });
            }
        });

        input.on('focusin', () => {
            // On est en dehors d'un digest, alors il faut l'appliquer
            scope.$applyAsync(() => {
                // Si on focus et que la valeur est déjà plus grande que 3 caratères, on affiche les suggestions
                if (!vm.opened && String(vm.model.$modelValue || '').length >= 3) {
                    openAutocomplete();
                }
            });
        });

        // On peut ouvrir ou fermer les suggestions en double-cliquant, quand le champ est vide
        input.on('dblclick', () => {
            // On est en dehors d'un digest, alors il faut l'appliquer
            scope.$applyAsync(() => {
                if (!vm.model.$modelValue) {
                    toggleSuggestions();
                }
            });
        });

        input.on('keydown', (event: JQueryEventObject) => {
            switch (event.which) {
                case keyCodes.ENTER:
                    if (vm.opened) {
                        if (vm.activeSuggestion !== -1) {
                            selectSuggestion(vm.suggestions[vm.activeSuggestion]);
                        }
                        closeAutocomplete();

                        return input.focusout();
                    }
                    break;

                case keyCodes.UP:
                    if (vm.opened && vm.activeSuggestion >= 0) {
                        // On est en dehors d'un digest, alors il faut l'appliquer
                        scope.$applyAsync(() => {
                            vm.activeSuggestion--;
                            scrollItemIntoView();

                            // On ne stoppe pas la recherche principale si le focus est sur le champ
                            input.toggleClass(INTROSPECTION_ACTIVE_CLASS, (vm.activeSuggestion > -1));
                        });
                    }
                    break;

                case keyCodes.DOWN:
                    if (!vm.opened) {
                        // On est en dehors d'un digest, alors il faut l'appliquer
                        scope.$applyAsync(() => {
                            openAutocomplete();
                            vm.activeSuggestion = -1;
                        });
                    }
                    // On permet 1 de plus que la limite pour le bouton "Voir plus", si il y a plus de résultats à afficher
                    else if (vm.suggestions && (vm.activeSuggestion < vm.suggestions.length - 1 || (vm.hasMore && vm.activeSuggestion < vm.suggestions.length))) {
                        // On est en dehors d'un digest, alors il faut l'appliquer
                        scope.$applyAsync(() => {
                            input.addClass(INTROSPECTION_ACTIVE_CLASS);
                            vm.activeSuggestion++;
                            scrollItemIntoView();
                        });
                    }
                    break;
            }
        });

        autocomplete.on('scroll', (e: JQueryEventObject) => {
            const scrollElement = e.currentTarget as HTMLDivElement;
            scope.$applyAsync(() => {
                if ((scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop) < 1 && vm.suggestions && vm.hasMore) {
                    vm.getMore();
                }
            });
        });

        function fetchData(options: { append?: boolean, datalistUpdated?: boolean } = {}): IPromise<any> {
            if (!vm.schemaItem) {
                return $q.resolve({});
            }

            // On garde la dernière valeur pour savoir quand elle a changée
            lastValue = String(vm.model.$modelValue || '');

            const col = vm.schemaItem.column;
            const val = String(vm.model.$modelValue || '').toLowerCase().trim();

            // Si les données ont déjà été chargées pour ce champ et cette valeur, on ne les recharge pas
            if (cache[vm.schemaItem.column][val] && !options.datalistUpdated && !options.append && !vm.multiOccurrence.etatsPredefinis.length) {
                vm.suggestions = cache[vm.schemaItem.column][val].valeur;
                pagination.pageCourante = cache[vm.schemaItem.column][val].page;
                vm.hasMore = cache[vm.schemaItem.column][val].hasMore;

                return $q.resolve(vm.suggestions);
            } else {
                const filters = [
                    new Filtre({
                        colonne: col,
                        operateur: Operateur.PAS_NULL
                    })
                ];

                if (val) {
                    filters.push(
                        new Filtre({
                            colonne: col,
                            operateur: Operateur.CONTIENT,
                            valeur: val
                        })
                    );
                }

                if (vm.multiOccurrence.etatsPredefinis.length) {
                    const etatPredefini = vm.multiOccurrence.etatsPredefinis.find((etat) => etat.nom === vm.multiOccurrence.etatSelected.nom);

                    if (etatPredefini) {
                        const filtresEtatPredefini = etatPredefini.filtres.map(filtre => filtre.colonne);
                        const filtresEtatPredefiniActif = vm.multiOccurrence.etatSelected.filtres
                            .filter((filtre: IFiltre) => {
                                return (
                                    !filtre.affichage &&
                                    (
                                        (filtre.getValeur() !== '') ||
                                        filtre.operateur.isAucuneValeurOperateur()
                                    ) &&
                                    filtresEtatPredefini.includes(filtre.colonne)
                                );
                            });
                        filters.push(...filtresEtatPredefiniActif);
                    }
                }

                const filtresMulti = vm.multiOccurrence.etatSelected.filtres.filter((filtre: IFiltre) => {
                    return Boolean((!filtre.visible || filtre.readOnly) && !filtre.affichage);
                });

                filters.push(...filtresMulti);

                const parameters = {
                    sortby: col,
                    filter: filters.map(filters => filters.toUrlAttribut()).join(';'),
                    // On va chercher un élément de plus pour savoir s'il y a une autre page à afficher
                    limit: pagination.getLimit() + 1,
                    start: pagination.getStart(),
                    cols: col,
                    // On veut les données uniques
                    distinct: true
                };

                // On ajoute les critères suggérés
                Object.assign(
                    parameters,
                    vm.multiOccurrence.etatSelected.criteresSuggeresData,
                    vm.multiOccurrence.getParentQueryParams(),
                    vm.multiOccurrence.getResourceParamsValues()
                );

                if (vm.multiOccurrence.bloc) {
                    // On ajoute l'id du parent dans le cas où la recherche s'effectue à l'intérieur d'un bloc
                    Object.assign(parameters, { parentId: vm.multiOccurrence.parentId });
                }

                vm.loading = true;
                return vm.multiOccurrence.DataResource.query(parameters).$promise
                    .then((result: any) => {
                        const response = <IHttpResponse<any>>result;
                        // S'il y a plus de 10 résultats, on sait qu'il y a une autre page
                        vm.hasMore = (response.data.length > 10);
                        // Mais on en garde seulement basé sur notre limite
                        const suggestions = response.data.map((data: any) => data[col]).slice(0, SUGGESTIONS_PAR_PAGE);

                        if (options.append) {
                            // Il est important de s'assurer que les données n'existent pas, ou Angular va causer une
                            // erreur. En général, grâce à "distinct" ça ne se produira pas, mais si de nouvelles
                            // données sont ajoutées pendant l'utilisation, la pagination va se décaler et des données
                            // dupliquées pourraient apparaître.
                            suggestions.forEach((suggestion: string) => {
                                if (!vm.suggestions.includes(suggestion)) {
                                    // En gardant la référence, le cache se fait mettre à jour par le fait même
                                    vm.suggestions.push(suggestion);
                                }
                            });
                            cache[vm.schemaItem.column][val].page = pagination.pageCourante;
                            cache[vm.schemaItem.column][val].hasMore = vm.hasMore;
                        } else {
                            vm.suggestions = suggestions;
                            cache[vm.schemaItem.column][val] = {
                                page: pagination.pageCourante,
                                valeur: vm.suggestions,
                                hasMore: vm.hasMore
                            };
                        }

                        vm.activeSuggestion = -1;
                        input.removeClass(INTROSPECTION_ACTIVE_CLASS);

                        vm.loading = false;

                        return vm.suggestions;
                    });
            }
        }

        function getMore() {
            if (!vm.gettingMore) {
                pagination.pageCourante++;

                vm.gettingMore = true;

                fetchData({ append: true })
                    .finally(() => {
                        vm.gettingMore = false;
                    });

                // Le focus a été enlevé par le clique, alors on le restaure
                $mdUtil.nextTick(() => {
                    input.focus();
                });
            }
        }

        function selectSuggestion(suggestion: string): void {
            suggestion = suggestion.replace(/(?:\r\n|\r|\n)/g, ' ');

            input
                .val(suggestion)
                .trigger('input', { exInstrospection: true });

            closeAutocomplete();

            if (options.onSelect) {
                $timeout(() => {
                    options.onSelect(suggestion);
                }, 100);
            }
        }

        function scrollItemIntoView(): void {
            const height = autocomplete.outerHeight();

            if (vm.suggestions && vm.activeSuggestion === vm.suggestions.length) {
                autocomplete.scrollTop(autocomplete[0].scrollHeight);
            } else if (vm.activeSuggestion !== -1) {
                const position = autocomplete
                    .find('.ex-introspection-item')
                    .eq(vm.activeSuggestion)
                    .position();

                const scrollTop = autocomplete.scrollTop();

                if (position.top > height - 35) {
                    autocomplete.scrollTop(scrollTop + position.top - height + 35);
                } else if (position.top < 0) {
                    autocomplete.scrollTop(scrollTop + position.top);
                }
            }
        }

        function openAutocomplete(): void {
            if (isStringColonne() && !vm.opened) {
                vm.activeSuggestion = -1;
                pagination.pageCourante = 1;
                vm.visible = true;
                vm.opened = true;

                $timeout(() => {
                    autocomplete.scrollTop(0);
                    positionAutocomplete();
                });
                vm.suggestions = null;
                fetchData();
                input.focus();
            }
        }

        function closeAutocomplete() {
            input.removeClass(INTROSPECTION_ACTIVE_CLASS);
            vm.opened = false;
        }

        function toggleSuggestions(): void {
            if (!vm.opened) {
                openAutocomplete();
            } else {
                vm.visible = false;
                closeAutocomplete();
            }
        }

        function isStringColonne() {
            return vm.schemaItem && vm.schemaItem.type === 'string' && !vm.disabled;
        }

        function initUpdateListeners() {
            vm.multiOccurrence.on('dataListUpdate', () => fetchData({ datalistUpdated: true }));
        }
    }
}
