import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface ISelectionIntervenantOptions {}

export interface ISelectionIntervenant extends IResource<ISelectionIntervenant>  {}

export interface ISelectionIntervenantResource extends IResourceClass<any> {
    new(data: ISelectionIntervenantOptions): IResource<ISelectionIntervenant>;
    create(data: any, params: any): IResource<ISelectionIntervenant>;
}

export default module('core.resources.selection-intervenant', [
    'core.services.api-config'
]).factory('SelectionIntervenantResource', SelectionIntervenantResourceFactory);

/* @ngInject */
function SelectionIntervenantResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): ISelectionIntervenantResource {
    const params = {};
    const options = {};

    const actions = {
        create: {
            method: 'POST',
            url: `${ApiConfig.ROOT}/intervenant/`
        },
        delete: {
            method: 'DELETE',
            url: `${ApiConfig.ROOT}/intervenant/:id`
        }
    };

    return <ISelectionIntervenantResource>$resource(`${ApiConfig.ROOT}/intervenant/`, params, actions, options);
}
