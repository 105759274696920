import {
    IComponentController,
    IFormController,
    INgModelController,
    IScope
} from 'angular';
import { IFilterMasque } from '../../behaviors/ex-masque/ex-masque.behavior';
import { IReglesValidationItem } from '../../interfaces/regles-validation.interface';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IDataType } from '../../services/data-types/data-type.service';
import { IDateHeureDataTypeClass } from '../../services/data-types/date-heure-data-type.service';
import { IPasswordDataTypeClass } from '../../services/data-types/password-data-type.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { ILibelles } from '../../services/libelles.service';
import { ICodePostalDataTypeClass } from "../../services/data-types/code-postal-data-type.service";
import { IFilterCodePostal } from "../../filters/ex-code-postal.filter";

interface IComposantInputString extends IComponentController {
    controller: INgModelController;
    enableCount: boolean;
    disabled: boolean;
    nameElement: string;
    masque: string;
    schemaItem: ISchemaItem;
    validateApi: string;
    dataType: IDataType;
    formCtrl: IFormController;
    reglesValidationItem: IReglesValidationItem;
    data: any;
    onFocus: Function;
    value: string;
    libelles: ILibelles;
    onBlurAction(): void;
    getValidatonMasque(): string;
    getDefaultMaxlength(): number;
    getCompleterAvecDesZeros(): number;
    getLongueurFixe(): number;
    getType(): string;
    onFocusAction(): void;
}

/* @ngInject */
export default function InputStringController(defaults: IDefaultsService,
    DateHeureDataType: IDateHeureDataTypeClass,
    PasswordDataType: IPasswordDataTypeClass,
    CodePostalDataType: ICodePostalDataTypeClass,
    $scope: IScope,
    exMasqueFilter: IFilterMasque,
    exCodePostalFilter: IFilterCodePostal) {
    const vm: IComposantInputString = this;

    vm.$onInit = $onInit;
    vm.getDefaultMaxlength = getDefaultMaxlength;
    vm.getCompleterAvecDesZeros = getCompleterAvecDesZeros;
    vm.getValidatonMasque = getValidatonMasque;
    vm.getLongueurFixe = getLongueurFixe;
    vm.getType = getType;
    vm.onBlurAction = onBlurAction;
    vm.onFocusAction = onFocusAction;

    function $onInit() {
        defaults(vm, {
            enableCount: false,
            disabled: false,
            validateApi: ''
        });

        // On attend que le formulaire soit accessible
        $scope.$watch('::vm.formCtrl[vm.nameElement]', (controller: INgModelController) => {
            vm.controller = controller;
            if (vm.readonly) {
                controller.$validators = [] as any
            }
            controller.$parsers.push(parsers)
            controller.$formatters.push(formatters);
        });
    }

    function parsers(modelValue: string) {
        if (modelValue && (vm.masque || hasDataTypeMask())) {
            const masque = vm.masque || vm.dataType.params.masque;
            return exMasqueFilter(modelValue, masque, vm.dataType && vm.dataType.params && vm.dataType.params.demasqueOnSave);
        }
        return modelValue
    }

    function formatters(modelValue: string) {
        if (modelValue && vm.dataType instanceof CodePostalDataType) {
            return exCodePostalFilter(modelValue);
        }

        if (modelValue && (vm.masque || hasDataTypeMask())) {
            const masque = vm.masque || vm.dataType.params.masque;
            const valeurMasquer = exMasqueFilter(modelValue, masque);

            vm.controller.$setViewValue(valeurMasquer);
            vm.controller.$render();
            return valeurMasquer;
        }

        return modelValue;
    }

    function getValidatonMasque() {
        return vm.readonly ? undefined : vm.masque && vm.dataType && vm.dataType.params && vm.dataType.params.demasqueOnSave ? vm.masque.replace(/\s/g, '') : vm.masque;
    }

    function onBlurAction(): void {
        let newVal;
        if (vm.dataType instanceof CodePostalDataType) {
            newVal = exCodePostalFilter(vm.formCtrl[vm.nameElement].$viewValue);
        }

        if (vm.masque) {
            newVal = exMasqueFilter(vm.formCtrl[vm.nameElement].$viewValue, vm.masque);
        }

        if (newVal && vm.formCtrl[vm.nameElement].$viewValue !== newVal) {
            vm.formCtrl[vm.nameElement].$setViewValue(newVal);
            vm.formCtrl[vm.nameElement].$render();
            if (vm.value) {
                vm.value = newVal;
            }
        }

        vm.onBlur?vm.onBlur(vm.data):null;
    }

    /**
     * Permet d'avoir le nombre maximal d'un champ
     */
    function getDefaultMaxlength(): number {
        if (vm.dataType && vm.dataType instanceof DateHeureDataType) {
            return 19;
        }

        if (vm.schemaItem && vm.schemaItem.type === "date") {
            return 10;
        }

        if (vm.reglesValidationItem && vm.reglesValidationItem.lng) {
            return vm.reglesValidationItem.lng;
        } else if (vm.masque || hasDataTypeMask()) {
            const masque = vm.masque || vm.dataType.params.masque;
            // S'il y a un masque, la longueur acceptée dans le champ peut être plus longue que la largeur de la bd
            // puisque des caractères seront enlevés
            return masque.length;
        } else if (vm.schemaItem && vm.schemaItem.length) {
            return vm.schemaItem.length;
        } else if (vm.schemaItem && vm.schemaItem.precision) {
            const precision = vm.schemaItem.precision;
            const decimals = vm.schemaItem.decimals;
            const sizeVirgule = ((precision - decimals) > 3) ? Math.floor(((precision - decimals) / 3)) : 0;
            //ex: 1,000,000.54 : precision(9) + virgule(2) + point(1)
            if (decimals > 0) {
                return precision + sizeVirgule + 1;
            }
            return precision + sizeVirgule;
        } else {
            return 999;
        }
    }

    function hasDataTypeMask() {
        return vm.dataType && vm.dataType.params && vm.dataType.params.masque;
    }

    /**
     * Permet de savoir si on doit compléter avec des zéros
     */
    function getCompleterAvecDesZeros(): number {
        if (vm.reglesValidationItem) {
            if (vm.reglesValidationItem.flgcplzer && vm.reglesValidationItem.lng && vm.reglesValidationItem.flglngfix) {
                return vm.reglesValidationItem.lng;
            }
        }
    }

    /**
     * Permet de savoir si on applique une longueur fixe
     */
    function getLongueurFixe(): number {
        if (vm.reglesValidationItem && vm.reglesValidationItem.flglngfix) {
            return vm.reglesValidationItem.lng;
        }
    }

    function getType() {
        if (vm.dataType instanceof PasswordDataType) {
            return 'password';
        } else {
            return 'text';
        }
    }

    function onFocusAction() {
        if (vm.onFocus) {
            vm.onFocus(vm.data);
        }
    }
}
