/**
 * Composant représentant une liste de données sous forme text-valeur.
 *
 * @param {object} data Un object de données.
 * @param {object} [libelles] Un object de libellés pour utiliser pour le texte de chaque item.
 * @param {Array<string>} [cols] Une liste de colonnes à afficher. Si absent, toutes les colonnes sont affichées.
 * @param {Array<string>} [colsExclu] Une liste de colonnes à exclure.
 * @param {boolean} [hideVide] Les items dont les valeurs sont vides (null, undefined, '') sont exclus.
 *
 * @example Base
 * <ex-data-list data={val1: 'foo', val2: 'bar'}></ex-data-list>
 *
 * @example Libellées
 * <ex-data-list data="{val1: 'foo', val2: 'bar'}" libelles="{val1: 'Valeur 1', val2: 'Valeur 2'}"></ex-data-list>
 *
 * @example Cols
 * <ex-data-list data="{val1: 'foo', val2: 'bar', val2: 'egg'}" cols="['val1', 'val3']"></ex-data-list>
 *
 * @example ColsExclu
 * <ex-data-list data="{val1: 'foo', val2: 'bar', val2: 'egg'}" cols-exclu="['val2']"></ex-data-list>
 *
 * @example hideVide
 * <ex-data-list data="{val1: 'foo', val2: '', val2: null}" hide-vide="true"></ex-data-list>
 */
import {IComponentOptions, module} from 'angular';
import DataListController from './ex-data-list.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-data-list', []).component('exDataList', DataListFactory());

function DataListFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.DATA.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.SCHEMAS.OPTIONAL,
            Binding.COLS,
            Binding.COLS_EXCLU.OPTIONAL,
            Binding.DATA_TYPES.OPTIONAL,
            Binding.MENUS.OPTIONAL,
            {
                largeur: '<',
                hideVide: '<?',
                classValue: '@?',
                alignmentLibelle: '@?',
                alignmentValeur: '@?',
                secondaire: '<?',
                hideLibelle: '<?',
                libelleDroite: '<?',
                largeurTexte: '<?',
                classTexte: '@?',
                classIcone: '@?'
            }
        ),
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controllerAs: 'vm',
        controller: DataListController,
        template: require('./ex-data-list.html')
    };
}
