import {IComponentOptions, module} from 'angular';
import FormulaireLovController from './ex-formulaire-lov.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-formulaire-lov', [
]).component('exFormulaireLov', FormulaireLovFactory());

function FormulaireLovFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.OCCURRENCE,
            Binding.FORMULAIRE,
            Binding.DATA,
            {
                col: '<'
            }
        ),
        controller: FormulaireLovController,
        controllerAs: 'vm',
        template: require('./ex-formulaire-lov.html')
    };
}
