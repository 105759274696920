import {module} from 'angular';
import {IMenu} from '../menu/menu.service';
import {IMenuItem} from '../menu/menu-item.service';
import {IMenuItemForage} from '../menu/menu-item-forage.service';
import {IFormulaireItemHiddenFonction} from './formulaire-item.service';

export interface IFormulaireItemTexteClass {
    new(texte: string|Function, option?: IFormulaireItemOptions): IFormulaireItemTexte;
}

export interface IFormulaireItemTexte {
    readonly texte: string|Function;
    readonly customClass: string;
    readonly noPadding: boolean;
    readonly icon: string;
    readonly largeur: number | string;
    readonly menu: Array<IMenu | IMenuItemForage>;
    readonly hidden: boolean | IFormulaireItemHiddenFonction;
}

export interface IFormulaireItemOptions {
    menu?: Array<IMenu | IMenuItem>;
    icon?: string;
    customClass?: string;
    noPadding?: boolean;
    largeur?: number | string;
    hidden?: boolean | IFormulaireItemHiddenFonction;
}

export default module('core.services.formulaire-item-texte', []).factory('FormulaireItemTexte', FormulaireItemTexteFactory);

/* @ngInject */
function FormulaireItemTexteFactory() {

    class FormulaireItemTexte implements IFormulaireItemTexte {
        readonly texte: string|Function;
        readonly customClass: string;
        readonly noPadding: boolean;
        readonly icon: string;
        readonly largeur: number | string = null;
        readonly menu: Array<IMenu | IMenuItemForage>;
        readonly hidden: boolean | IFormulaireItemHiddenFonction;

        constructor(texte: string, options: IFormulaireItemOptions = {}) {
            this.texte = texte;

            if (typeof options.largeur !== 'undefined') {
                this.largeur = options.largeur;
            }

            if (typeof options.menu !== 'undefined') {
                this.menu = options.menu;
            }

            if (typeof options.hidden !== 'undefined') {
                this.hidden = options.hidden;
            }

            if (typeof options.customClass !== 'undefined') {
                this.customClass = options.customClass;
            }

            if (typeof options.noPadding !== 'undefined') {
                this.noPadding = options.noPadding;
            }

            if (typeof options.icon !== 'undefined') {
                this.icon = options.icon;
            }
        }
    }
    return FormulaireItemTexte;
}
