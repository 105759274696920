import {IComponentOptions, module} from 'angular';
import CardStickyCycleController from './ex-card-sticky-cycle.controller';
import Binding from '../../helpers/binding.helper';

export default module('app.components.ex-card-sticky-cycle', []).component('exCardStickyCycle', CardStickyCycleFactory());

function CardStickyCycleFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.COL,
            Binding.MONO_OCCURRENCE,
            {
                formData: '<',
                messagesCycle: '<'
            } 
        ),
        require: {
            //monoOccurrenceEcranCtrl: '^^exMonoOccurrenceEcran',
            ecranContextCtrl: '^^exEcranContext',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran'
        },
        transclude: true,
        controller: CardStickyCycleController,
        controllerAs: 'vm',
        template: require('./ex-card-sticky-cycle.html')
    };
}