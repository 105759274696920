import {fromJson, IHttpHeadersGetter, IQService, IRequestConfig, module} from 'angular';
import {IPreferences, IPreferencesClass} from './preferences.resource';
import {ICompagnie} from '../interfaces/compagnie.interface';
import {IMenuNavigationItem} from '../interfaces/menu-navigation-item.interface';
import {IRoute} from '../interfaces/route.interface';
import {IRouter} from '../providers/router.provider';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResourceOptions = angular.resource.IResourceOptions;
import IResource = angular.resource.IResource;
import {IHyperlien} from '../interfaces/hyperliens.interface';

export interface IProfilAcces {
    pracleint: number;
    dscmnu: string;
    usrcleint?: number;
    usecleint?: number;
    guplflgouv: number;
    menus: Array<IMenuNavigationItem>;
    menusEmploye: Array<IMenuNavigationItem>;
}

export interface IProfil extends IResource<IProfil> {
    blAuthInfos?: IBlAuthInfos; // Pour l'autentification BL.
    usecleint?: number;
    nomUsager?: string;
    nomEmployeRH: string;
    nomEmployeUSURH?: string;
    nomEmployeACRH?: string;
    accesUsager: IAccesUsager;
    preferences: IPreferences;
    profilsAcces: Array<IProfilAcces>;
    profilAcces: IProfilAcces;
    compagnie: ICompagnie;
    compagnies: Array<ICompagnie>;
    parametres: any;
    hyperliens: IHyperlien[];
    sessionCourante?: string|number;
}

interface IBlAuthInfos {
    clientId: string;
    url: string;
    realm: string;
    cookieKey: string;
}

interface IAccesUsager {
    verificationFinGrh: boolean;
    selectionIntervention: boolean;
}

export default module('core.resources.profil', []).factory('profil', ProfilResourceFactory);

/* @ngInject */
function ProfilResourceFactory(ApiConfig: IApiConfig,
                               $q: IQService,
                               $resource: IResourceService,
                               Preferences: IPreferencesClass,
                               Router: IRouter,
                               accueilRoute: IRoute): IProfil {
    const actions = {
        get: {
            method: 'get',
            interceptor: {
                request(config: IRequestConfig) {
                    config.headers = config.headers || {};
                    return $q.resolve(config);
                }
            },
            transformResponse(data: string, headers: IHttpHeadersGetter, status: number) {
                const JSON = fromJson(data);
                if (status === 200) {
                    JSON.profilsAcces.forEach((profilAcces: IProfilAcces) => {
                        injectStateName(profilAcces.menus);

                        if (profilAcces.menusEmploye) {
                            injectStateName(profilAcces.menusEmploye);
                        }
                    });

                    JSON.preferences = new Preferences(JSON.preferences);
                }
                return JSON;
            }
        }
    };
    const paramDefaults = {};

    const options: IResourceOptions = {};
    const ProfilResource = $resource(`${ApiConfig.ROOT}/profil`, paramDefaults, <any>actions, options);

    // Nous créons un singleton puisqu'il y seulement un profil
    return <IProfil> new ProfilResource();

    function injectStateName(data: Array<IMenuNavigationItem>) {
        data.forEach(item => {
            item.stateName = getStateName(item);
            if (item.items) {
                injectStateName(item.items);
            }
        });
    }

    function getStateName(item: IMenuNavigationItem): string {
        // Pour les index de section, il n'y a pas de codref
        if (item.codref) {
            const route = Router.getRouteByCode(item.codref);
            if (route) {
                return route.NAME;
            } else {
                return accueilRoute.NAME;
            }
        }
    }
}
