import {module} from 'angular';

export interface IMenuDividerClass {
    new(option?:IMenuDividerOption): IMenuDivider;
}

export interface IMenuDivider {
    visible: boolean;
}

export interface IMenuDividerOption {
    visible?: boolean;
}

export default module('core.services.menu-divider', []).factory('MenuDivider', MenuDividerFactory);

/* @ngInject */
function MenuDividerFactory() {

    class MenuDivider implements IMenuDivider {
        public visible: boolean = true;

        constructor(option:IMenuDividerOption = {}) {

            if (typeof option.visible !== 'undefined') {
                this.visible = option.visible;
            }
        }
    }
    return MenuDivider;
}
