import {IFilterDate, module} from 'angular';

export default module('core.filters.ex-date', []).filter('exDate', dateFilter);

export interface IFilterExDate {
    (value: string|number|Date): any;
}

/* @ngInject */
function dateFilter(dateFilter: IFilterDate): IFilterExDate {
    const format = 'yyyy-MM-dd';

    return (value: string): any => {
        return dateFilter(value, format)
    }
}
