import {module} from 'angular';
import {IFilterLibelle} from './ex-libelle.filter';

export default module('core.filters.ex-entete-libelle', ['core.filters.ex-libelle']).filter('exEnteteLibelle', enteteLibelleFilter);

export interface IFilterEnteteLibelle {
    (key: string, libelles: any, entetesTitre: any, showEntete: boolean, enteteReplacedValues?: any, replacedValue?: string): string;
}

/* @ngInject */
function enteteLibelleFilter(exLibelleFilter: IFilterLibelle): IFilterEnteteLibelle {
    return (key: string, libelles: any, entetesTitre: any, showEntete: boolean = true, enteteReplacedValues: any = {}, replacedValue?: string): string => {
        if (typeof key === 'string') {

            const colLibelle = exLibelleFilter(key, libelles, false, replacedValue);

            if (showEntete) {
                for (const colonnesRegroupement in entetesTitre) {
                    if (entetesTitre[colonnesRegroupement].includes(key)) {
                        return `${exLibelleFilter(colonnesRegroupement, libelles, false, enteteReplacedValues[colonnesRegroupement.toUpperCase()])} - ${colLibelle}`;
                    }
                }
            }
            return colLibelle;
        }
    }
}
