import {module} from 'angular';
import {IOperateurService} from './operateur.service';
import {IFiltreOptions} from './filtre.service';
import {IEcranDetailsResourcesEntite} from '../resources/ecran-details.resource';

export interface IParametresRechercheService {
    (ecranDetails: IEcranDetailsResourcesEntite): Array<IFiltreOptions>;
}

export default module('core.services.parametres-recherche', []).factory('parametresRecherche', ParametresRechercheFactory);

/* @ngInject */
function ParametresRechercheFactory(Operateur: IOperateurService): IParametresRechercheService {

    return function parametresRecherche(ecranDetails: IEcranDetailsResourcesEntite): Array<IFiltreOptions> {
        return [
            {
                colonne: 'trnflgstaann',
                operateur: ecranDetails.valeurs.pmrflgexcann ? Operateur.EGALE_PAS : Operateur.EGALE,
                valeur: ecranDetails.valeurs.pmrflgexcann ? '1' : ''
            },
            {
                colonne: 'trnflgstafer',
                operateur: ecranDetails.valeurs.pmrflgexcfer ? Operateur.EGALE_PAS : Operateur.EGALE,
                valeur: ecranDetails.valeurs.pmrflgexcfer ? '1' : ''
            }
        ];
    }
}
