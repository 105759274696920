/**
 * Comportement permettant le focus sur un element de façon déclaration ou bien de façons imperative
 */
import {IAugmentedJQuery, IScope, ITimeoutService, module} from 'angular';

export interface IFocusService {
    (element: IAugmentedJQuery, options ?: IExFocusOptions): () => {};
}

export interface IExFocusOptions {
    types?: Array<string>;
    separateurTypes?: string;
    filtres?: Array<string>;
    disabled?: boolean;
}

export default module('core.behaviors.ex-focus', [])
    .factory('exFocus', FocusFactory)
    .directive('exFocus', FocusDirective);

/* @ngInject */
function FocusFactory($timeout: ITimeoutService) {
    return (element: IAugmentedJQuery, options: IExFocusOptions = {}) => {
        $timeout(() => {
            if (element && !options.disabled) {
                //Par défaut, le focus se fait sur le premier champ.
                const separateur = options.separateurTypes || ',';
                const typesRecherche = (options.types) ? options.types.join(separateur) : 'input,textarea,md-select,md-checkbox';
                const filtre = (options.filtres) ? ':' + options.filtres.join(':') : ':not([disabled]):not([readOnly]):visible:first';
                const cible = element.find(typesRecherche).filter(filtre);

                cible.focus();

                if (cible.is('md-checkbox')) {
                    //Dans le cas d'une case à cocher, il faut ajouter la classe manuellement.
                    cible.addClass('md-focused');
                }
            }
        });
    };
}

/* @ngInject */
function FocusDirective(exFocus: IFocusService) {
    return (scope: IScope, element: IAugmentedJQuery, attr: any) => {
        exFocus(element, scope.$eval(attr.exFocusOptions));
    };
}
