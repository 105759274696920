import {module} from 'angular';
import * as moment from 'moment';

export interface IDateLocalManager {
    initDateLocal(libellesGlobaux: any): void;
}

export default module('core.services.date-local-manager', []).factory('dateLocalManager', DateLocalManagerFactory);

/* @ngInject */
function DateLocalManagerFactory($mdDateLocale: any) {
    const DATE_FORMAT = 'YYYY-MM-DD';

    class DateLocalManager implements IDateLocalManager {

        public initDateLocal(libellesGlobaux: any): void {
            $mdDateLocale.months = [
                libellesGlobaux['G_LBL_CALM_JANVIER'],
                libellesGlobaux['G_LBL_CALM_FEVRIER'],
                libellesGlobaux['G_LBL_CALM_MARS'],
                libellesGlobaux['G_LBL_CALM_AVRIL'],
                libellesGlobaux['G_LBL_CALM_MAI'],
                libellesGlobaux['G_LBL_CALM_JUIN'],
                libellesGlobaux['G_LBL_CALM_JUILLET'],
                libellesGlobaux['G_LBL_CALM_AOUT'],
                libellesGlobaux['G_LBL_CALM_SEPTEMBRE'],
                libellesGlobaux['G_LBL_CALM_OCTOBRE'],
                libellesGlobaux['G_LBL_CALM_NOVEMBRE'],
                libellesGlobaux['G_LBL_CALM_DECEMBRE'],
            ];

            $mdDateLocale.shortMonths = [
                libellesGlobaux['G_LBL_CALM_JAN'],
                libellesGlobaux['G_LBL_CALM_FEV'],
                libellesGlobaux['G_LBL_CALM_MAR'],
                libellesGlobaux['G_LBL_CALM_AVR'],
                libellesGlobaux['G_LBL_CALM_MAI'],
                libellesGlobaux['G_LBL_CALM_JUN'],
                libellesGlobaux['G_LBL_CALM_JUI'],
                libellesGlobaux['G_LBL_CALM_AOU'],
                libellesGlobaux['G_LBL_CALM_SEP'],
                libellesGlobaux['G_LBL_CALM_OCT'],
                libellesGlobaux['G_LBL_CALM_NOV'],
                libellesGlobaux['G_LBL_CALM_DEC']
            ];
            $mdDateLocale.days = [
                libellesGlobaux['G_LBL_CALJ_DIMANCHE'],
                libellesGlobaux['G_LBL_CALJ_LUNDI'],
                libellesGlobaux['G_LBL_CALJ_MARDI'],
                libellesGlobaux['G_LBL_CALJ_MERCREDI'],
                libellesGlobaux['G_LBL_CALJ_JEUDI'],
                libellesGlobaux['G_LBL_CALJ_VENDREDI'],
                libellesGlobaux['G_LBL_CALJ_SAMEDI']
            ];
            $mdDateLocale.shortDays = [
                libellesGlobaux['G_LBL_CALJ_DIM'],
                libellesGlobaux['G_LBL_CALJ_LUN'],
                libellesGlobaux['G_LBL_CALJ_MAR'],
                libellesGlobaux['G_LBL_CALJ_MER'],
                libellesGlobaux['G_LBL_CALJ_JEU'],
                libellesGlobaux['G_LBL_CALJ_VEN'],
                libellesGlobaux['G_LBL_CALJ_SAM']
            ];
            // Can change week display to start on Monday.
            $mdDateLocale.firstDayOfWeek = 0;

            $mdDateLocale.formatDate = function (date: any) {
                const m = moment(date, DATE_FORMAT);
                return m.isValid() ? m.format(DATE_FORMAT) : '';
            };

            $mdDateLocale.parseDate = function (date: any) {
                const strict = true;
                const m = moment(date, DATE_FORMAT, strict);
                return m.isValid() ? m.toDate() : new Date(NaN);
            };

            $mdDateLocale.monthHeaderFormatter = function (date: any) {
                return $mdDateLocale.months[date.getMonth()] + ' ' + date.getFullYear();
            };

            $mdDateLocale.weekNumberFormatter = function (weekNumber: any) {
                return libellesGlobaux['G_LBL_CAL_SEMAINE'] + weekNumber;
            };

            moment.locale('fr', <any>{
                months : $mdDateLocale.months,
                monthsShort : $mdDateLocale.shortMonths,
                monthsParseExact : true,
                weekdays : $mdDateLocale.days,
                weekdaysShort : $mdDateLocale.shortDays,
                weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
                weekdaysParseExact : true,
                longDateFormat : {
                    LT : 'HH:mm',
                    LTS : 'HH:mm:ss',
                    L : 'DD/MM/YYYY',
                    LL : 'D MMMM YYYY',
                    LLL : 'D MMMM YYYY HH:mm',
                    LLLL : 'dddd D MMMM YYYY HH:mm'
                },
                calendar : {
                    sameDay : '[Aujourd’hui à] LT',
                    nextDay : '[Demain à] LT',
                    nextWeek : 'dddd [à] LT',
                    lastDay : '[Hier à] LT',
                    lastWeek : 'dddd [dernier à] LT',
                    sameElse : 'L'
                },
                relativeTime : {
                    future : 'dans %s',
                    past : 'il y a %s',
                    s : 'quelques secondes',
                    m : 'une minute',
                    mm : '%d minutes',
                    h : 'une heure',
                    hh : '%d heures',
                    d : 'un jour',
                    dd : '%d jours',
                    M : 'un mois',
                    MM : '%d mois',
                    y : 'un an',
                    yy : '%d ans'
                },
                dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
                ordinal : function (number: number) {
                    return number + (number === 1 ? 'er' : 'e');
                },
                meridiemParse : /PD|MD/,
                isPM : function (input: string) {
                    return input.charAt(0) === 'M';
                },
                // In case the meridiem units are not separated around 12, then implement
                // this function (look at locale/id.js for an example).
                // meridiemHour : function (hour, meridiem) {
                //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                // },
                meridiem : function (hours: number, minutes: number, isLower: boolean) {
                    return hours < 12 ? 'PD' : 'MD';
                },
                week : {
                    dow : 1, // Monday is the first day of the week.
                    doy : 4  // The week that contains Jan 4th is the first week of the year.
                }
            });
        }
    }

    return new DateLocalManager;
}

