import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IChangeItemOptions {}

export interface IChangeItem {}

export interface IChangeItemResource extends IResourceClass<any> {
    new(data: IChangeItemOptions): IResource<IChangeItem>;
}

export default module('core.resources.change-item', [
    'core.services.api-config'
]).factory('ChangeItemResource', ChangeItemResourceFactory);

/* @ngInject */
function ChangeItemResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IChangeItemResource {
    return <IChangeItemResource>$resource(`${ApiConfig.ROOT}/change-item/:srccod/:champ/:ids?`);
}
