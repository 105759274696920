import {
    IAugmentedJQuery,
    IComponentController,
    IDocumentService,
    IRootScopeService,
    ITimeoutService,
    IWindowService
} from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IProfil } from '../../resources/profil.resource';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import IDialogService = angular.material.IDialogService;
import { IChangementManager } from '../../services/changement-manager.service';
import { ISourceDetails } from "../../resources/source-details.resource";

interface IComposantBandeauPage extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    activeNavBack: boolean;
    hasPanneauPage: boolean;
    panneauOpened: boolean;
    hauteurMinMargeVertical: number;
    height: number;
    menu: Array<any>;
    titrePage: string;
    width: number | string;
    requiresIntervention: boolean;
    codeInterventionActive: string;
    naviguerPageRetour(): void;
    hasPageRetour(): boolean;
    ouvrirPanneau(): void;
    fermerDialog(): void;
}

/* @ngInject */
export default function BandeauPageController($window: IWindowService,
    $document: IDocumentService,
    defaults: IDefaultsService,
    $timeout: ITimeoutService,
    profil: IProfil,
    $mdDialog: IDialogService,
    ecranDetails: IEcranDetailsResourcesEntite,
    DialogStatus: IDialogAllStatus,
    changementManager: IChangementManager,
    ecranSourceDetails: ISourceDetails,
    $element: IAugmentedJQuery,
    $rootScope: IRootScopeService) {
    const vm: IComposantBandeauPage = this;

    vm.$onInit = $onInit;
    vm.naviguerPageRetour = naviguerPageRetour;
    vm.hasPageRetour = hasPageRetour;
    vm.ouvrirPanneau = ouvrirPanneau;
    vm.fermerDialog = fermerDialog;

    function $onInit() {
        defaults(vm, {
            hauteurMinMargeVertical: 45,
            height: 200,
            width: '',
            titrePage: 'titre',
            activeNavBack: true,
            // Dans la modale écran, le panneau est toujours fermé par défaut
            panneauOpened: Boolean(profil.preferences.gsfflgpansom && vm.ecranContextCtrl.ecranDetails.ecrcleint === ecranDetails.ecrcleint),
            requiresIntervention: profil.accesUsager.selectionIntervention && ecranSourceDetails.sourceDeveloppement
        });
    }

    function naviguerPageRetour() {
        sessionStorage.setItem("ForageBack","1");
        $window.history.back();
    }

    function hasPageRetour() {
        return Boolean($window.history.length);
    }

    function ouvrirPanneau() {
        vm.panneauOpened = !vm.panneauOpened;

        if (vm.panneauOpened) {
            $timeout(() => {
                $document.find('ex-panneau-page input').focus();
            }, 350);
        }

        if (vm.ecranContextCtrl.ecranDetails.ecrcleint === ecranDetails.ecrcleint) {
            profil.preferences.gsfflgpansom = vm.panneauOpened ? 1 : 0;
            profil.preferences.$update().catch(function () {
                // On veut un échec silencieux, ce n'est pas assez important pour aviser l'usager
            });
        }

        $rootScope.$broadcast('exGridReajusterLargeurColonne')
    }

    function fermerDialog() {
        const monoOccurrenceEcranCtrl = $element.controller("exMonoOccurrenceEcran");
        if (monoOccurrenceEcranCtrl && monoOccurrenceEcranCtrl.monoOccurrence && monoOccurrenceEcranCtrl.monoOccurrence.saving) return
        changementManager.confirmerQuitterPage($element).then(() => {
            $mdDialog.cancel(DialogStatus.FERMER);
        });
    }
}
