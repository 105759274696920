import * as angular from 'angular';
import {IAugmentedJQuery, IComponentController, IPromise, IScope, ITimeoutService} from 'angular';
import {IComposantMonoOccurrenceEcran} from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import {IDialogConfirmOptions} from '../../dialogs/dialog-confirm/dialog-confirm.controller';
import {
    IActionPossibleFinance,
    ICycleFinanceClass,
    IProchainEtatFinance
} from '../../resources/cycle-finance.resource';
import {
    IActionPossibleRh,
    ICycleRhClass,
    IProchainEtatRh,
    ITacheRh
} from '../../resources/cycle-rh.resource';
import {IMenuItem, IMenuItemClass} from '../../services/menu/menu-item.service';
import {IMenu, IMenuClass, MenuElement} from '../../services/menu/menu.service';

import {IMessagesCycle} from '../../services/messages-cycle.service';
import {IMonoOccurrence, IMonoOccurrenceClass} from '../../services/mono-occurrence.service';
import {INotificationHandler} from '../../services/utils/notification-handler.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import {IParametresSecuriteService} from '../../services/parametres-securite.service';
import {IDialog} from '../../services/dialog.service';
import {IDialogFormulaireOptions} from '../../dialogs/dialog-formulaire/dialog-formulaire.controller';
import {IFormulaireClass} from '../../services/formulaire/formulaire.service';
import {IFormulaireItemClass} from '../../services/formulaire/formulaire-item.service';
import {IFormulaireItemDividerClass} from '../../services/formulaire/formulaire-item-divider.service';
import {IDialogAllStatus} from '../../constants/dialog.constant';
import {IComposantCard} from '../ex-card/ex-card.controller';
import {IMenuItemEcranClass} from '../../services/menu/menu-item-ecran.service';
import {IRoute} from '../../interfaces/route.interface';
import {IChangementManager} from '../../services/changement-manager.service';
import IResourceArray = angular.resource.IResourceArray;
import { IMenuItemPieceJustificativeClass } from '../../services/menu/menu-item-piece-justificative.service';
import { IBooleanDataTypeClass } from '../../services/data-types/boolean-data-type.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import isMobile from '../../constants/mobile.constant';

export interface IComposantActionsCycle extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    messagesCycle: IMessagesCycle;
    prochainEtatMenuItem: IMenuItem;
    autresActions: IMenu;
    taches: IMenu;
    tachesBusy: boolean;
    tachesError: boolean;
    autresActionsBusy: boolean;
    autresActionsError: boolean;
    formData: any;
    isModalOpen: boolean;
    isMobile: boolean;
    isPortail: boolean;
    fetchTaches(): void;
    refreshFetchAutresActionsPossibles(): void;
    refreshProchainEtat(): void;
    openInfos(event: MouseEvent): void;
    openHistorique(event: MouseEvent): void;
}

/* @ngInject */
export default function CardCycleController(Menu: IMenuClass,
                                            MenuItem: IMenuItemClass,
                                            MonoOccurrence: IMonoOccurrenceClass,
                                            DialogStatus: IDialogAllStatus,
                                            Formulaire: IFormulaireClass,
                                            FormulaireItem: IFormulaireItemClass,
                                            FormulaireItemDivider: IFormulaireItemDividerClass,
                                            DialogFormulaire: IDialog,
                                            MenuItemEcran: IMenuItemEcranClass,
                                            mr1001Route: IRoute,
                                            notificationHandler: INotificationHandler,
                                            CycleRh: ICycleRhClass,
                                            CycleFinance: ICycleFinanceClass,
                                            $element: IAugmentedJQuery,
                                            $scope: IScope,
                                            parametresSecurite: IParametresSecuriteService,
                                            $timeout: ITimeoutService,
                                            changementManager: IChangementManager,
                                            BooleanDataType: IBooleanDataTypeClass,
                                            MenuItemPieceJustificative: IMenuItemPieceJustificativeClass,
                                            exLibelleFilter: IFilterLibelle,
                                            DialogCycleTransactionnelInformations: IDialog,
                                            DialogCycleEvenementInformations: IDialog,
                                            DialogCycleTransactionnelHistorique: IDialog,
                                            DialogCycleEvenementHistorique: IDialog) {
    const vm: IComposantActionsCycle = this;

    vm.$onInit = $onInit;
    vm.fetchTaches = fetchTaches;
    vm.refreshFetchAutresActionsPossibles = refreshFetchAutresActionsPossibles;
    vm.refreshProchainEtat = refreshProchainEtat;
    vm.openInfos = openInfos;
    vm.openHistorique = openHistorique;

    function $onInit() {
        vm.tachesError = false;
        vm.autresActionsError = false;
        vm.isModalOpen = false;
        vm.isMobile = isMobile;
        vm.isPortail = vm.monoOccurrenceEcranCtrl.bandeauPortail;

        if (vm.monoOccurrence.id || vm.monoOccurrence.noId && vm.ecranContextCtrl.stateParams.employe) {
            vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams);

            if (vm.monoOccurrence.cycleCleint === 'trncleint') {
                initBandeauCycleRH();
            } else {
                initBandeauCycleFinance();
            }
        }

        const params = {
            coche: 1
        };

        const flgrefOptionDataType = {
            schemaItem: {
                column: "flgref",
                decimals: 0,
                precision: 1,
                required: true,
                type: "number"
            },
            params
        };

        const flgverfinOptionDataType = {
            schemaItem: {
                column: "flgref",
                decimals: 0,
                precision: 1,
                required: true,
                type: "number"
            },
            params
        };

        vm.dataTypes = {
            flgref: new BooleanDataType(flgrefOptionDataType),
            flgverfin: new BooleanDataType(flgverfinOptionDataType)
        };

        vm.menuItemPieceJustificative = new MenuItemPieceJustificative(vm.monoOccurrence);
    }

    function openInfos(event: MouseEvent) {
        const dialogOptions = {
            targetEvent: event,
            locals: {
                ecranContext: vm.ecranContextCtrl,
                monoOccurrence: vm.monoOccurrence
            }
        };

        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint === 'trncleint') {
            DialogCycleTransactionnelInformations.show(dialogOptions);
        } else {
            DialogCycleEvenementInformations.show(dialogOptions);
        }
    }

    function openHistorique(event: MouseEvent) {
        const dialogOptions = {
            targetEvent: event,
            locals: {
                ecranContext: vm.ecranContextCtrl,
                monoOccurrence: vm.monoOccurrence
            }
        };

        if (vm.monoOccurrence && vm.monoOccurrence.cycleCleint === 'trncleint') {
            DialogCycleTransactionnelHistorique.show(dialogOptions);
        } else {
            DialogCycleEvenementHistorique.show(dialogOptions);
        }
    }

    function initBandeauCycleFinance() {
        // Le prochain état est chargé par le service monoOccurrence
        vm.prochainEtatMenuItem = getProchainEtatMenuItemFinance();

        fetchAutresActionsFinance();
    }

    function initBandeauCycleRH() {
        // Le prochain état est chargé par le service monoOccurrence
        vm.prochainEtatMenuItem = getProchainEtatMenuItemRH();

        if (vm.monoOccurrence.id) {
            fetchAutresActionsRH();
            fetchTaches();
        } else if (vm.monoOccurrence.noId) {
            const removeWatcher = $scope.$watch('vm.monoOccurrence.data.$resolved', (resolved: boolean) => {
                if (resolved) {
                    fetchAutresActionsRH(vm.monoOccurrence.data.trncleint);
                    fetchTaches(vm.monoOccurrence.data.trncleint);
                    removeWatcher();
                }
            })
        }
    }

    function refreshProchainEtat() {
        return vm.monoOccurrence.fetchEtatsCycle();
    }

    function refreshFetchAutresActionsPossibles() {
        if (vm.monoOccurrence.cycleCleint === 'trncleint') {
            fetchAutresActionsRH();
        } else {
            fetchAutresActionsFinance();
        }
    }

    function getProchainEtatMenuItemFinance() {
        return new MenuItem((event: MouseEvent) => {
            if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
                return;
            }

            return executeProchainEtatActionFinance();
        });
    }

    function getProchainEtatMenuItemRH() {
        return new MenuItem((event: MouseEvent) => {
            if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
                return;
            }

            return executeProchainEtatActionRH()
                .then(() => {
                    refreshEcran();
                })
                .catch((erreur: IDialogConfirmOptions) => {
                    if (erreur != DialogStatus.FERMER) {
                        // On défini l'action de confirmation, sauf si l'action de confirmation existe déjà
                        if (typeof erreur.confirmAction === 'undefined') {
                            erreur.confirmAction = function () {
                                return executeProchainEtatActionRH();
                            };
                        }

                        notificationHandler.erreur(erreur);

                        if (vm.monoOccurrence.cycleErrorReloadBlocs) {
                            vm.monoOccurrence.cycleErrorReloadBlocs.forEach((bloc: string) => {
                                if (vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc]) {
                                    vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc].fetchDataList();
                                }
                            });
                        }
                    }

                    allerHautDePage();

                    vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams)
                    vm.monoOccurrence.softUpdateData()
                });
        });
    }

    function allerHautDePage() {
        scrollTo(0);
    }

    function scrollTo(scrollTop: number) {
        return $element.closest('ex-mono-occurrence-ecran').find('[md-scroll-y]').animate({
            scrollTop
        }, 200);
    }

    function showModalConfirmationCycleFinance(vaecypact: string, docflgaih: boolean, confirmAction: (data?: any) => IPromise<any>) {
        vm.isModalOpen = true;
        const dialogFormulaireOptions: IDialogFormulaireOptions = {
            lblTitre: 'LBL_TITRE',
            icon: 'device_hub',
            data: {
                flgselmul: 0,
                vaecypact,
                docflgaih
            },
            confirmAction,
            occurrence: new MonoOccurrence({
                bloc: 'NOOP',
                stateParams: vm.monoOccurrence.stateParams,
                ecranDetails: vm.monoOccurrence.ecranDetails,
                ecranSourceDetails: vm.monoOccurrence.ecranSourceDetails,
                srccod: 'mc1001'
            }),
            showConfirmAction: true,
            largeur: 40,
            formulaire: new Formulaire([
                new FormulaireItem('peccleint', {largeur: 50}),
                new FormulaireItemDivider('LBL_DIV_COM'),
                new FormulaireItem('com', {largeur: 100}),
                new FormulaireItem('flgdem', {largeur: 100}),
                new FormulaireItem('flgini'),
                new FormulaireItem('flgreq'),
                new FormulaireItem('flgapr'),
                new FormulaireItem('intcleint', {largeur: 50, longueurFixe: 6}),
                new FormulaireItem('adrcou', {largeur: 50})
            ], {largeurDefaut: 33})
        };
        const dialogOptions = {
            locals: dialogFormulaireOptions,
            multiple: true
        };
        return DialogFormulaire.show(dialogOptions).finally(() => {vm.isModalOpen = false});
    }

    function showModalConfirmationCycleRh(eclcleint: number) {
        vm.isModalOpen = true;
        const menuItem = new MenuItemEcran('LBL_TITRE', mr1001Route.NAME, () => ({
            id: vm.formData.trncleint,
            trncleint: vm.formData.trncleint,
            numcol: [vm.formData.trncleint],
            eclcleint,
            flgselmul: 0
        }), {
            appliquer: true,
            showActionsInDialog: true,
            optionsDialog: {
                hauteur: 54,
                largeur: 50
            }
        });

        return menuItem.action(null, vm.formData).finally(() => {vm.isModalOpen = false});
    }

    function showModalConfirmationCycleTaches(confirmAction: (data?: any) => IPromise<any>) {
        const dialogFormulaireOptions: IDialogFormulaireOptions = {
            lblTitre: 'LBL_TITRE',
            icon: 'device_hub',
            data: {},
            confirmAction,
            occurrence: new MonoOccurrence({
                bloc: 'NOOP',
                stateParams: vm.monoOccurrence.stateParams,
                ecranDetails: vm.monoOccurrence.ecranDetails,
                ecranSourceDetails: vm.monoOccurrence.ecranSourceDetails,
                srccod: 'mr1001'
            }),
            showConfirmAction: true,
            largeur: 40,
            formulaire: new Formulaire([
                new FormulaireItem('com', {largeur: 100})
            ], {
                largeurDefaut: 33
            })
        };
        const dialogOptions = {
            locals: dialogFormulaireOptions,
            multiple: true
        };
        return DialogFormulaire.show(dialogOptions);
    }

    function executeProchainEtatAction(action: (patams: any) => any) {
        const params = {
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        if (vm.prochainEtatMenuItem) {
            vm.prochainEtatMenuItem.busy = true;
        }
        return action(params)
            .then(() => {
                onActionSucces('G_MSG_ACTION_SUCCES');

                refreshEcran();
            })
            .catch((erreur: any) => {
                // On défini l'action de confirmation, sauf si l'action de confirmation existe déjà
                if (typeof erreur.confirmAction === 'undefined') {
                    erreur.confirmAction = function () {
                        return executeProchainEtatAction(action);
                    };
                } else if (erreur.confirmAction === null) {
                    // On affiche les erreurs que s'il ne s'agit pas d'une erreur "normale" du cycle.
                    notificationHandler.erreur(erreur);
                }

                if (vm.monoOccurrence.cycleErrorReloadBlocs) {
                    vm.monoOccurrence.cycleErrorReloadBlocs.forEach((bloc: string) => {
                        if (vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc]) {
                            vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc].fetchDataList();
                        }
                    });
                }

                allerHautDePage();

                vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams);
            })
            .finally(() => {
                vm.prochainEtatMenuItem.busy = false;
            });
    }

    function executeProchainEtatActionFinance() {
        return showModalConfirmationCycleFinance((<IProchainEtatFinance>vm.monoOccurrence.prochainEtat).vaecyeact,
            (<IProchainEtatFinance>vm.monoOccurrence.prochainEtat).docflgaih,
            (formData: any) => {
                const action = {
                    doccleint: vm.monoOccurrence.id,
                    eclcleint: (<IProchainEtatFinance>vm.monoOccurrence.prochainEtat).cleintpro,
                    vaecyeact: (<IProchainEtatFinance>vm.monoOccurrence.prochainEtat).vaecyeact,
                    ...formData
                };

                return executeProchainEtatAction((params: any) => {
                    return CycleFinance.executeAction(params, action).$promise;
                });
            });
    }

    function executeProchainEtatActionRH() {
        const eclcleint = (<IProchainEtatRh>vm.monoOccurrence.prochainEtat).cleintpro;

        return showModalConfirmationCycleRh(eclcleint);
    }

    /**
     * Les actions dans le bouton autres actions pour les cycles Finance
     */
    function fetchAutresActionsFinance() {
        const doccleint = Number(vm.monoOccurrence.id);

        const params = {
            doccleint,
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        vm.autresActionsBusy = true;

        CycleFinance.queryActionsPossibles(params).$promise
            .then((actionsPossibles: IResourceArray<IActionPossibleFinance>) => {
                vm.autresActionsError = false;

                return getActionsPossiblesMenuItemsFinance(doccleint, actionsPossibles);
            })
            .then((autresActionsItems: Array<MenuElement>) => {
                // Il faut pouvoir rafraîchir le autres actions possibles
                if (!vm.autresActions) {
                    vm.autresActions = new Menu('G_LBL_AUTRES_ACTIONS', autresActionsItems);
                } else {
                    vm.autresActions.listeMenuItem.splice(0, vm.autresActions.listeMenuItem.length, ...autresActionsItems);
                }
            })
            .catch(() => {
                vm.autresActionsError = true;
            })
            .finally(() => {
                vm.autresActionsBusy = false;
            });
    }

    /**
     * Les actions dans le bouton autres actions pour les cycles RH
     */
    function fetchAutresActionsRH(trncleintFromData?: number) {
        const trncleint = Number(vm.monoOccurrence.id || trncleintFromData);

        const params = {
            trncleint,
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        vm.autresActionsBusy = true;

        CycleRh.queryActionsPossibles(params).$promise
            .then((actionsPossibles: IResourceArray<IActionPossibleRh>) => {
                vm.autresActionsError = false;

                return getActionsPossiblesMenuItemsRH(actionsPossibles);
            })
            .then((autresActionsItems: Array<MenuElement>) => {
                // Il faut pouvoir rafraîchir le autres actions possibles
                if (!vm.autresActions) {
                    vm.autresActions = new Menu('G_LBL_AUTRES_ACTIONS', autresActionsItems, {
                        classItem: 'ex-button-cycle'
                    });
                } else {
                    vm.autresActions.listeMenuItem.splice(0, vm.autresActions.listeMenuItem.length, ...autresActionsItems);
                }
            })
            .catch(() => {
                vm.autresActionsError = true;
            })
            .finally(() => {
                vm.autresActionsBusy = false;
            });
    }

    function getActionsPossiblesMenuItemsFinance(doccleint: number, actionsPossibles: IResourceArray<IActionPossibleFinance>) {
        return actionsPossibles.map((actionPossible: IActionPossibleFinance) => {
            return new MenuItem(actionPossible.vaedsc, (event: MouseEvent, hideMenu: () => void) => {
                hideMenu();
                showModalConfirmationCycleFinance(actionPossible.vaecypact, actionPossible.docflgaih, (formData: any) => {
                    if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
                        return;
                    }

                    const params = {
                        srccod: vm.ecranContextCtrl.stateParams.srccod,
                        ...parametresSecurite(vm.ecranContextCtrl.stateParams)
                    };

                    const action = {
                        doccleint,
                        vaecypact: actionPossible.vaecypact,
                        ...formData
                    };

                    return CycleFinance.executeAction(params, action).$promise
                        .then(() => {
                            onActionSucces('G_MSG_ACTION_SUCCES');

                            refreshEcran();
                        })
                        .catch((erreur: any) => {
                            if (erreur !== DialogStatus.FERMER) {
                                // On défini l'action de confirmation, sauf si l'action de confirmation existe déjà
                                if (typeof erreur.confirmAction === 'undefined') {
                                    erreur.confirmAction = function () {
                                        return executeProchainEtatActionFinance();
                                    };
                                } else if (erreur.confirmAction === null) {
                                    // On affiche les erreurs que s'il ne s'agit pas d'une erreur "normale" du cycle.
                                    notificationHandler.erreur(erreur);
                                }

                                if (vm.monoOccurrence.cycleErrorReloadBlocs) {
                                    vm.monoOccurrence.cycleErrorReloadBlocs.forEach((bloc: string) => {
                                        if (vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc]) {
                                            vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants[bloc].fetchDataList();
                                        }
                                    });
                                }
                            }
                            allerHautDePage();

                            vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams);
                        });
                });
            }, {
                iconButton: false,
                directionTooltip: 'bottom'
            });
        });
    }

    function getActionsPossiblesMenuItemsRH(actionsPossibles: IResourceArray<IActionPossibleRh>) {
        return actionsPossibles.map((actionPossible: IActionPossibleRh) => {
            const eclcleint = actionPossible.eclcleintcib;

            return new MenuItem(actionPossible.ecldscact, (event: MouseEvent, hideMenu: () => void) => {
                if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
                    return;
                }

                if (hideMenu instanceof Function) {
                    hideMenu();
                }

                showModalConfirmationCycleRh(eclcleint)
                    .then((status: number) => {
                        if (status === DialogStatus.APPLIQUER) {
                            refreshEcran();
                        }
                    })
                    .catch((err: any) => {
                        vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.ecranContextCtrl.stateParams)
                        if (err !== -1) {
                            notificationHandler.erreur(err)
                        }
                    });
            }, {
                iconButton: false,
                class: 'md-raised ex-button',
                tooltip: actionPossible.eclcom
            });
        });
    }

    function fetchTaches(trncleintFromData?: number) {
        const trncleint = Number(vm.monoOccurrence.id || trncleintFromData);

        const params = {
            trncleint,
            srccod: vm.ecranContextCtrl.stateParams.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        vm.tachesBusy = true;

        CycleRh.queryTaches(params).$promise
            .then((taches: IResourceArray<ITacheRh>) => {
                vm.tachesError = false;
                const tachesMenuItems = getTachesMenuItems(trncleint, taches);

                const tachesCompletees = taches.filter((tache) => { return tache.thtflgeff });

                vm.taches = new Menu(`${exLibelleFilter('G_LBL_TACHES')} (${tachesCompletees.length}/${taches.length})`, tachesMenuItems);
            })
            .catch(() => {
                vm.tachesError = true;
            })
            .finally(() => {
                vm.tachesBusy = false;
            });
    }

    function getTachesMenuItems(trncleint: number, taches: Array<ITacheRh>) {
        return taches.map((tache: ITacheRh) => {
            return new MenuItem(tache.ctadsc, (event: MouseEvent, hideMenu: () => void) => {
                hideMenu();

                if (!vm.monoOccurrence.verifierChangementsNonSauvegardes(vm.formData, event) || verifierChangementsNonSauvegardesImbriques()) {
                    return;
                }

                showModalConfirmationCycleTaches((data: any) => {
                    const params = {
                        srccod: vm.ecranContextCtrl.stateParams.srccod,
                        ...parametresSecurite(vm.ecranContextCtrl.stateParams)
                    };

                    const action = {
                        trncleint,
                        ctacleint: tache.ctacleint,
                        com: data.com
                    };

                    return CycleRh.executeTache(params, action).$promise
                        .then(() => {
                            fetchTaches(trncleint);
                            onActionSucces('G_MSG_TACHE_SUCCES');
                        });
                });
            }, {
                icon: tache.thtflgeff ? 'check' : ' ',
                iconColor: 'primary',
                tooltip: tache.ctacom
            });
        });
    }

    /**
     * Gère le succès de l'exécution d'une action
     */
    function onActionSucces(message: string): void {
        notificationHandler.succes(message);

        refreshProchainEtat();
    }

    function verifierChangementsNonSauvegardesImbriques() {
        const monoOccurrenceEcranElement = $element.closest('ex-mono-occurrence-ecran');
        const formulaireNonSauvegarde = angular.element(changementManager.getPremierFormulaireNonSauvegarde(monoOccurrenceEcranElement));

        if (formulaireNonSauvegarde.length) {
            const parentCards = formulaireNonSauvegarde.parents('ex-card');
            let titresBlocs: string;
            Array.from(parentCards).forEach((parentCard: HTMLElement) => {
                const cardController: IComposantCard = angular.element(parentCard).controller('exCard');
                cardController.opened = true;
                titresBlocs = titresBlocs ? `${titresBlocs}, ${cardController.lblTitre}` : cardController.lblTitre;
            });

            $timeout(() => scrollToElement(formulaireNonSauvegarde));

            return notificationHandler.erreur({
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: exLibelleFilter('G_MSG_CHANGEMENTS_ACTION', vm.ecranContextCtrl.ecranSourceDetails.libelles, false, titresBlocs)
            });
        }
    }

    function scrollToElement(element: IAugmentedJQuery) {
        const scrollContainer = $element
            .closest('ex-mono-occurrence-ecran')
            .find('.ex-mono-occurrence-ecran-content');
        const scrollContainerTop = scrollContainer.offset().top;
        const targetOffset = element.offset();
        const targetTop = targetOffset && targetOffset.top;

        scrollTo(targetTop - scrollContainerTop - 100);
    }

    
    function refreshEcran() {
        // On veut que le load s'affiche sur le champ, malgré le délai que prend le reload
        if (vm.monoOccurrence.cycleCleint === 'trncleint' && vm.monoOccurrence.fonctions.refreshForageIdWithCycle) {
            const params = {
                id: vm.monoOccurrence.data[vm.monoOccurrence.cleint],
                srccod: vm.ecranContextCtrl.stateParams.srccod,
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            };

            CycleRh.verifierForageId(params).$promise
                .then(({trncleint}: any) => {
                    refreshEcranAction({...vm.ecranContextCtrl.stateParams, id: trncleint}, {
                        replace: vm.monoOccurrence.data.trncleint !== trncleint
                    });
                })
                .catch(() => {
                    refreshEcranAction(vm.ecranContextCtrl.stateParams);
                })
        } else {
            refreshEcranAction(vm.ecranContextCtrl.stateParams);
        }
    }

    function refreshEcranAction(params: any, options: {replace?: boolean} = {}) {
        vm.monoOccurrence.fetchingData = true;
        vm.ecranContextCtrl.transitionTo(vm.ecranContextCtrl.stateParams.route.NAME, {
            ...params,
            verifierChangements: false
        }, {
            replace: options.replace,
            reload: true,
        });
    }
}
