import {module} from 'angular';
import DialogEditionAvanceeController from './dialog-edition-avancee.controller';
import {IDialogClass} from '../../services/dialog.service';

export default module('app.dialogs.dialog-edition-avancee', [
    'core.services.dialog'
]).factory('DialogEditionAvancee', DialogEditionAvanceeFactory);

/* @ngInject */
function DialogEditionAvanceeFactory(Dialog: IDialogClass) {
    return new Dialog({
        controller: DialogEditionAvanceeController,
        controllerAs: 'vm',
        bindToController: true,
        template: require('./dialog-edition-avancee.html'),
        fullscreen: true,
        autoWrap: true,
        multiple: true,
        focusOnOpen: false
    });
}
