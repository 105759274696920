/**
 * Ce comportement identifie un 'bloc'
 */
import {IAttributes, IAugmentedJQuery, IScope, module} from 'angular';
import BlocController from './ex-bloc.controller';
import {IEcranContextController} from '../ex-ecran-context/ex-ecran-context.behavior';
import {IRegroupementController} from '../../components/ex-regroupement/ex-regroupement.controller';

export default module('core.behaviors.ex-bloc', [])
    .directive('exBloc', BlocDirective);

interface IBlocRequiredControllers {
    ecranContextCtrl: IEcranContextController;
    regroupementCtrl: IRegroupementController;
}

/* @ngInject */
function BlocDirective() {
    return {
        require: {
            ecranContextCtrl: '^^exEcranContext',
            regroupementCtrl: '^^?exRegroupement'
        },
        compile() {
            return {
                pre(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, controllers: IBlocRequiredControllers) {
                    const fonctions = controllers.ecranContextCtrl.ecranDetails.fonctions;

                    const bloc = attrs.exBloc.toUpperCase();
                    const fonction = fonctions[bloc];

                    if (fonction && !fonction.flgacc) {
                        element.remove();
                    } else if (controllers.regroupementCtrl) {
                        controllers.regroupementCtrl.register(bloc);
                    }
                }
            };
        },
        controller: BlocController
    };
}
