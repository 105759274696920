import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IBoutonActionOptions {
    srccod: string;
    ids: string;
    fonction: string
}

export interface IBoutonActionResource extends IResourceClass<any> {
    new(data: IBoutonActionOptions): IResource<any>;
}

export default module('core.resources.bouton-action', [
    'core.services.api-config'
]).factory('BoutonActionResource', BoutonActionResourceFactory);

/* @ngInject */
function BoutonActionResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IBoutonActionResource {
    return <IBoutonActionResource>$resource(`${ApiConfig.ROOT}/bouton-action/:srccod/:fonction/:ids`);
}
