import {module} from 'angular';
import {IApiConfig} from '../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import IResource = angular.resource.IResource;
import IResourceClass = angular.resource.IResourceClass;

export interface IChampsTransactionnelsOptions {
    trncleint: number;
}

export interface IChampsTransactionnels {
    vaeceptypmod?: string,
    champsModifiables?: Array<string>;
}

export interface IChampsTransactionnelsResource extends IResourceClass<any> {
    new(data: IChampsTransactionnelsOptions): IResource<IChampsTransactionnels>;
}

export default module('core.resources.champs-transactionnels', [
    'core.services.api-config'
]).factory('ChampsTransactionnelsResource', ChampsTransactionnelsResourceFactory);

/* @ngInject */
function ChampsTransactionnelsResourceFactory(ApiConfig: IApiConfig, $resource: IResourceService): IChampsTransactionnelsResource {
    return <IChampsTransactionnelsResource>$resource(`${ApiConfig.ROOT}/champs-transactionnels/:srccod/:cleint`);
}
