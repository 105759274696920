import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IImageDataTypeClass extends IDataTypeClass {
    new(options?: IImageDataTypeOptions): IImageDataType;
}

export interface IImageDataTypeOptions extends IDataTypeOptions {
}

export interface IImageDataType extends IDataType {
}

export default module('core.services.image-data-type', []).factory('ImageDataType', ImageDataTypeFactory);

/* @ngInject */
function ImageDataTypeFactory(DataType: IDataTypeClass): IImageDataTypeClass {
    class ImageDataType extends DataType implements IImageDataType {
        readonly template: string = `<ex-image multi-occurrence="vm.multiOccurrenceCtrl.multiOccurrence"
            mono-occurrence="vm.monoOccurrenceCtrl.monoOccurrence" 
            data-type="vm.dataType" 
            data="vm.data" 
            col="{{vm.col}}">
        </ex-image>`;
    }

    return ImageDataType;
}
