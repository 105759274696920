/**
 * (PM2) Composant représentant une card contenant un multi-occurrence template.
 */
import {IAugmentedJQuery, IComponentController, IDirective, IScope, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CardMultiTemplateController, {IComposantCardMultiOccurrenceTemplate} from './ex-card-multi-template.controller';
import {ITranscludeSlotFunction} from '../../interfaces/transclude-function.interface';

export default module('core.components.ex-card-multi-template', [])
    .directive('exCardMultiTemplate', CardMultiTemplateFactory);

interface ICardMultiTemplateScope extends IScope {
    vm: IComposantCardMultiOccurrenceTemplate;
}

function CardMultiTemplateFactory(): IDirective {
    return {
        restrict: 'E',
        scope: Binding(
            Binding.ICON.OPTIONAL,
            Binding.MULTI_OCCURRENCE_OPTIONS,
            Binding.COLLAPSABLE.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.MENU.OPTIONAL,
            Binding.NO_HEADER.OPTIONAL,
            {
                etatErreur: '<?',
                etatAvertissement: '<?',
                lblTitre: '@',
                multiOccurrence: '=?multiOccurrenceOut',
                closeAction: '<?',
                hasAccordeon: '<?',
                opened: '=?',
                onSaveNewAction: '<?',
                onCheckboxChange: '&?'
            }
        ),
        require: {
            monoOccurrenceEcranCtrl: '?^^exMonoOccurrenceEcran'
        },
        transclude: {
            row: 'exCardMultiTemplateRow',
            beforeTable: '?exCardMultiTemplateBeforeTable',
            accordeon: '?exCardMultiTemplateAccordeon',
            actions: '?exCardMultiTemplateActions',
            total: '?exCardMultiTemplateTotal'
        },
        link(scope: ICardMultiTemplateScope, element: IAugmentedJQuery, attrs: any, controller: IComponentController, transcludeFn: ITranscludeSlotFunction) {
            scope.vm.transcludeFn = transcludeFn;
        },
        bindToController: true,
        controller: CardMultiTemplateController,
        controllerAs: 'vm',
        template: require('./ex-card-multi-template.html')
    };
}
