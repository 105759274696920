import {IComponentController} from 'angular';
import {IMonoOccurrence} from '../../services/mono-occurrence.service';
import {IMultiOccurrence, IMultiOccurrenceOptions} from '../../services/multi-occurrence.service';
import {IEcranContextController} from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';

export interface IDialogCycleEvenementHistoriqueController extends IComponentController {
    ecranContext: IEcranContextController;
    monoOccurrence: IMonoOccurrence;
    historiqueEtatMultiOccurrenceOptions: IMultiOccurrenceOptions;
    courrielMultiOccurrenceOptions: IMultiOccurrenceOptions;
    historiqueEtatMultiOccurrence: IMultiOccurrence;
    afficherMultiCourriel: boolean;
    initMultiOccurrenceEnfant(data: any): void;
}

/* @ngInject */
export default function DialogCycleEvenementHistoriqueController() {
    const vm: IDialogCycleEvenementHistoriqueController = this;

    vm.$onInit = $onInit;
    vm.initMultiOccurrenceEnfant = initMultiOccurrenceEnfant;

    function $onInit() {
        const paramsSecurite = {
            srccod: vm.monoOccurrence.stateParams.srccod
        };

        vm.historiqueEtatMultiOccurrenceOptions = {
            srccod: 'mc1003',
            resourceParams: {
                doccleint: vm.monoOccurrence.id,
                ...paramsSecurite
            },
            colonnesVisibles: [
                {nom: 'datcre', largeur: 100},
                {nom: 'usrnom', largeur: 100},
                {nom: 'usrcod', largeur: 100},
                {nom: 'usrposte', largeur: 100},
                {nom: 'etddsc', largeur: 100},
                {nom: 'intcod', largeur: 100},
                {nom: 'intnom', largeur: 100},
                {nom: 'intposte', largeur: 100},
                {nom: 'dheflggen', largeur: 100},
                {nom: 'etddscpro', largeur: 100},
                {nom: 'dhecom', largeur: 100},
                {nom: 'dheflgaprint', largeur: 100},
                {nom: 'dheflgverfin', largeur: 100},
                {nom: 'dheflgconfin', largeur: 100},
                {nom: 'dheflgcen', largeur: 100},
                {nom: 'dheflgref', largeur: 100},
                {nom: 'dheflgveraut', largeur: 100},
                {nom: 'flgcou', largeur: 100},
                {nom: 'intcodcib', largeur: 100},
                {nom: 'intnomcib', largeur: 100},
                {nom: 'dhenomreq', largeur: 100},
                {nom: 'aihcod', largeur: 100},
                {nom: 'aihdsc', largeur: 100}
            ],
            colonnesCachees: [
                'dhecleint'
            ],
            fonctions: {
                edition: false,
                nouveau: false,
                supprime: false,
                suiviModification: false
            }
        };
    }

    function initMultiOccurrenceEnfant(data: any): void {
        const paramsSecurite = {
            srccod: vm.monoOccurrence.stateParams.srccod
        };

        vm.courrielMultiOccurrenceOptions = {
            srccod: 'mc1003',
            bloc: 'dhe',
            resourceParams: {
                dhecleint: data.dhecleint,
                doccleint: vm.monoOccurrence.id,
                ...paramsSecurite
            },
            colonnesVisibles: [
                {nom: 'vaedhcpro_dsc', largeur: 110},
                {nom: 'dhcadrcou', largeur: 170},
                {nom: 'intcod', largeur: 110},
                {nom: 'intnom', largeur: 170},
                {nom: 'usrcod', largeur: 140},
                {nom: 'usrnoma', largeur: 175}
            ],
            fonctions: {
                edition: false,
                nouveau: false,
                supprime: false,
                recherche: false,
                selectionnerUnEtat: false,
                enregistrerUnEtat: false,
                filtrer: false,
                exportation: false,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                reinitialiser: false
            }
        };
    }
}
