import {module} from 'angular';
import {ISelectionOptions} from '../../resources/actions-selection.resource';

export interface IMenuSectionClass {
    new(lblTitre: string, options?: IMenuSectionOptions): IMenuSection;
}

export interface IMenuSection {
    lblTitre: string;
    selection: ISelectionOptions;
}

export interface IMenuSectionOptions {
    selection?: ISelectionOptions;
}

export default module('core.services.menu-section', []).factory('MenuSection', MenuSectionFactory);

/* @ngInject */
function MenuSectionFactory() {

    class MenuSection implements IMenuSection {
        public lblTitre: string;
        public selection: ISelectionOptions;

        constructor(lblTitre: string, options: IMenuSectionOptions = {}) {
            this.lblTitre = lblTitre;

            if (typeof options.selection !== 'undefined') {
                this.selection = options.selection;
            }
        }
    }
    return MenuSection;
}
