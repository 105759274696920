/**
 * Composant représentant un multi-occurrence avancé de type PM1 (ex-card-multi-advanced).
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import MultiOccurrenceAdvancedController from './ex-multi-occurrence-advanced.controller';

export default module('core.components.ex-multi-occurrence-advanced', []).component('exMultiOccurrenceAdvanced', MultiOccurrenceAdvancedFactory());

function MultiOccurrenceAdvancedFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MULTI_OCCURRENCE_OPTIONS,
            {
                lblTitreEdition: '@?',
                multiOccurrence: '=?multiOccurrenceOut',
                clickRowAction: '&?',
                customSaveAction: '<?',
                editionOptions: '<?',
                customDeleteAction: '<?'
            }
        ),
        require: {
            blocCtrl: '?^^exBloc',
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            monoOccurrenceEcranCtrl: '?^exMonoOccurrenceEcran',
            multiOccurrenceCtrl: '?^exMultiOccurrence',
            dataSourceCtrl: '?^exDataSource',
            templateCtrl: '?^exTemplate',
            ecranContextCtrl: '^^exEcranContext'
        },
        transclude: {
            accordeon: '?exMultiOccurrenceAdvancedAccordeon'
        },
        controller: MultiOccurrenceAdvancedController,
        controllerAs: 'vm',
        template: require('./ex-multi-occurrence-advanced.html')
    };
}
