import {ILogService, module} from 'angular';
import {IKeyCodes} from '../constants/key-codes.constant';

export default module('core.providers.raccourci', [
]).service('raccourci', RaccourcisFactory);

export interface IRaccourciService {
    (config: IRaccourciConfig, event: JQueryEventObject, ...args: Array<any>): void;
}

export interface IRaccourciConfig {
    [config: string]: (...args: Array<any>) => any;
}

/* @ngInject */
function RaccourcisFactory(keyCodes: IKeyCodes, $log: ILogService) {
    const specialKeys: {[key: string]: string} = {
        CTRL: 'ctrlKey',
        SHIFT: 'shiftKey',
        META: 'metaKey'
    };

    const specialKeysList = Object.keys(specialKeys);

    return function racourci(config: IRaccourciConfig, event: JQueryEventObject, ...args: Array<any>) {
        Object.keys(config).forEach((combinaisonsKeys: string) => {
            const combinaisons = parseCombinaisons(combinaisonsKeys);

            if (testCombinaison(combinaisons)) {
                config[combinaisonsKeys](event, ...args);
            }
        });

        function testCombinaison(combinaisons: Array<string>) {
            return combinaisons.some((raccourci: string) => {
                const keys = parseKeys(raccourci)

                return testSpecialsKeys(event, keys) && testNormalKey(event, keys);
            });
        }

        function parseCombinaisons(config: string) {
            return config
                .split(',')
                .map(combinaison => combinaison.trim());
        }

        function parseKeys(raccourci: string) {
            return raccourci
                .toUpperCase()
                .split('+');
        }

        function testSpecialsKeys(event: JQueryEventObject,  keys: Array<string>) {
            return specialKeysList.every((specialKey) => {
                const keyProp = specialKeys[specialKey];
                return keys.includes(specialKey) === (<any>event)[keyProp]
            });
        }

        function testNormalKey(event: JQueryEventObject,  keys: Array<string>) {
            const normalKeys = keys.filter(key => !specialKeys[key]);

            if (normalKeys.length !== 1) {
                $log.error(`Raccourcis invalide. Une et une seule touche normale doit être fournie`);
                return false;
            }

            const normalKey = normalKeys[0];

            if (keyCodes[normalKey] == null) {
                $log.error(`Raccourcis inconnu. Veuillez ajouter la touche à la constante "keyCodes"`);
                return false;
            }

            return event.which === keyCodes[normalKey];
        }
    }
}
