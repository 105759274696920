import IState = angular.ui.IState;
import {module} from 'angular';

export default module('core.layouts.content-only', []).constant('ContentOnlyLayout', ContentOnlyLayoutFactory());

function ContentOnlyLayoutFactory() : IState {
    return {
        template: require('./content-only.html')
    };
}
