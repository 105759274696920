import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface ICourrielDataTypeClass {
    new(options?: ICourrielDataTypeOptions): ICourrielDataType;
}

export interface ICourrielDataTypeOptions extends IDataTypeOptions{
}

export interface ICourrielDataType extends IDataType {
}

export default module('core.services.courriel-data-type', []).factory('CourrielDataType', CourrielDataTypeFactory);

/* @ngInject */
function CourrielDataTypeFactory(DataType: IDataTypeClass): ICourrielDataTypeClass {
    class CourrielDataType extends DataType implements ICourrielDataType {
        readonly template: string = `<a ng-if="vm.data[vm.col]" href="mailto: {{vm.data[vm.col]}}">{{vm.data[vm.col]}}</a>`;
    }

    return CourrielDataType;
}
