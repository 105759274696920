import { IComponentController, IRootScopeService } from 'angular';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import AxesAffichage, { IAxesAffichageDefaut } from '../../classes/axe-affichage.class';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMonoOccurrence } from "../../services/mono-occurrence.service";
import { IFiltre, IFiltreClass } from '../../services/filtre.service';
import { IOperateurService } from '../../services/operateur.service';

interface IComposantAxeAffichage extends IComponentController {
    axesAffichageDefaut: IAxesAffichageDefaut;
    multiOccurrence: IMultiOccurrence;
    btnActionFermer: IMenuItem;
    isAxeAffichageActif(nomAxeActif: string): boolean;
    hasBoutonFermer(data: any): boolean;
    keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence): void
}

/* @ngInject */
export default function AxeAffichageController(MenuItem: IMenuItemClass,
    $rootScope: IRootScopeService,
    Filtre: IFiltreClass,
    Operateur: IOperateurService) {
    const vm: IComposantAxeAffichage = this;

    vm.axesAffichageDefaut = AxesAffichage.AXES_AFFICHAGE;
    vm.btnActionFermer = new MenuItem('G_LBL_BTN_FERMER', (ev: MouseEvent, data: any) => vm.closeRow(data), {
        class: 'md-raised ex-button ex-button-secondary',
        iconButton: false
    });

    $rootScope.$on('axe-calendar.show.grid', (event, obj) => {
        if (!event.defaultPrevented) {
            event.defaultPrevented = false;
            const axe = vm?.multiOccurrence?.axesAffichage?.axeActive?.axeNom;
            if (axe && axe === AxesAffichage.AXES_AFFICHAGE.LIST) {
                const colonnes = vm?.multiOccurrence?.etatSelected?.filtres;
                if (colonnes && Array.isArray(colonnes) && colonnes.length > 0 && obj?.colonne) {
                    const filtreDate = colonnes.find((filtre: IFiltre) => filtre.colonne === obj.colonne && !filtre.visible)
                    //on supprime le filtre si lui n'est pas incluit dans criteres suggeres
                    if (filtreDate) {
                        const index = vm.multiOccurrence.etatSelected.filtres.indexOf(filtreDate);
                        const nouveauFiltre = new Filtre({
                            colonne: [obj.colonne.split(",")],
                            operateur: Operateur.ENTRE,
                            valeur: obj.date,
                            valeur2: obj.date,
                            visible: false
                        });
                        vm.multiOccurrence.etatSelected.replaceFiltre(nouveauFiltre, index);
                    }
                }
                vm.multiOccurrence.fetchDataList({ forceFetch: true })
            } else {
                if (axe) {
                    vm.multiOccurrence.emit('etatUpdate');
                }
            }
        }
    });

    vm.isAxeAffichageActif = (nomAxeActif: string) => vm.multiOccurrence.axesAffichage.axeActive.axeNom === nomAxeActif

    vm.hasBoutonFermer = function hasBoutonFermer(data: any) {
        return Boolean(!vm.multiOccurrence.hasEdition(data) && (vm.hasTranscludeDetails() || vm.multiOccurrence.rowHasError(data) || vm.multiOccurrence.details));
    }

    vm.keydownEdition = function keydownEdition(event: KeyboardEvent, monoOccurrence: IMonoOccurrence) {
        if (event.shiftKey && event.key === "F6" && vm.multiOccurrence.initialized && vm.multiOccurrence.fonctions.nouveau && vm.multiOccurrence.isEveryChampsRequisModifiables(false) && vm.multiOccurrence.fonctions.editionStandard && !vm.multiOccurrence.editionRapideActive) {
            event.preventDefault()
            vm.newMonoOccurrence.data = { ...monoOccurrence.data, [monoOccurrence.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }
            vm.newMonoOccurrence.id = undefined
            vm.newMonoOccurrence.emit("dataUpdate")
            vm.newOpened = true
        }
    }
}
