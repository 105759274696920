import {module} from 'angular';
import {IDataType, IDataTypeClass} from './data-type.service';

export interface ICodePostalDataTypeClass {
    new(options: ICodePostalDataTypeOptions): ICodePostalDataType;
}

export interface ICodePostalDataType extends IDataType{
}
export interface ICodePostalDataTypeOptions extends IDataType{
}

export default module('core.services.code-postal-data-type', []).factory('CodePostalDataType', CodePostalDataTypeFactory);

/* @ngInject */
function CodePostalDataTypeFactory(DataType: IDataTypeClass): ICodePostalDataTypeClass {
    class CodePostalDataType extends DataType implements ICodePostalDataType {
        readonly template: string = `{{::vm.data[vm.col] | exCodePostal}}`;
    }
    return CodePostalDataType;
}
