import {IDefaultsService} from '../../services/utils/defaults.service';
import {IExpandableBehaviorController} from '../../behaviors/ex-expandable/ex-expandable.behavior';
import {IAngularEvent, IAugmentedJQuery, IComponentController, IScope} from 'angular';

interface IToggleIconModel extends IComponentController {
    opened: boolean;
    icon: string;
    expandableCtrl: IExpandableBehaviorController;
}

/* @ngInject */
export default function ToggleIconController(defaults: IDefaultsService, $element: IAugmentedJQuery, $scope: IScope) {
    const vm: IToggleIconModel = this;
    const OPENED_CLASS = 'ex-toggle-icon--opened';

    //init
    vm.$onInit = function $onInit() {
        defaults(vm, {
            icon: 'keyboard_arrow_down'
        });

        const iconElement = $element.find('md-icon');
        iconElement.toggleClass(OPENED_CLASS, vm.isExpanded());

        const stopListenning = vm.expandableCtrl.onExpandableChange((event: IAngularEvent, expanded: boolean) => {
            iconElement.toggleClass(OPENED_CLASS, expanded);
        });

        $scope.$on('$destroy', () => {
            stopListenning();
        });
        $scope.$watch("vm.expanded", (expanded: boolean) => {
            iconElement.toggleClass(OPENED_CLASS, expanded);
        })
    }

    vm.isExpanded = () => vm.expanded || vm.expandableCtrl.expanded
}
