import {module} from 'angular';

export interface ISelecteurClass {
    new(colonnes: Array<any>, colonnesAct: Array<any>, colonneRegroupement?: Array<any>): ISelecteur;
}
export interface ISelecteur {
    colonneLeft: Array<any>;
    colonneRight: Array<any>;
    colonneRegroupement: Array<any>;
    transferAllLeftToRight(): void;
    transferAllRightToLeft(): void;
    updateColonnes(colonneLeft?: Array<any>, colonneRight?: Array<any>, colonneRegroupement?: Array<any>): void;
}

export default module('core.services.selecteur', []).factory('Selecteur', SelecteurFactory);

/* @ngInject */
function SelecteurFactory() {

    class Selecteur implements ISelecteur {
        public colonneLeft: Array<any>;
        public colonneRight: Array<any>;
        public colonneRegroupement: any;

        constructor(colonneLeft: Array<any> = [], colonneRight: Array<any> = [], colonneRegroupement: any = {}) {
            this.updateColonnes(colonneLeft, colonneRight, colonneRegroupement);
        }

        public updateColonnes(colonneLeft?: Array<any>, colonneRight?: Array<any>, colonneRegroupement?: any) {
            if (colonneLeft) {
                this.colonneLeft = colonneLeft.concat();
            }
            if (colonneRight) {
                this.colonneRight = colonneRight.concat();
            }
            if (colonneRegroupement) {
                this.colonneRegroupement = colonneRegroupement;
            }
        }

        private moveElements(source: Array<any>, target: Array<any>, elements: Array<any> = source) {
            target.push(...elements);
            if (source.length === elements.length) {
                source.splice(0, elements.length);
            } else {
                source = source.filter((item: any) => elements.indexOf(item) === -1);
            }
        }

        public transferAllLeftToRight(): void {
            this.moveElements(this.colonneLeft, this.colonneRight);
        }

        public transferAllRightToLeft(): void {
            this.moveElements(this.colonneRight, this.colonneLeft);
        }

    }
    return Selecteur;
}
