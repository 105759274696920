import {IAttributes, IDirective, INgModelController, IScope, module} from 'angular';
import * as moment from 'moment';
import {IComposantInputHour} from '../../components/ex-input-hour/ex-input-hour.controller';
import IProvideService = angular.auto.IProvideService;

interface IHourAsStringScope extends IScope {
    vm: IComposantInputHour;
}

export default module('core.behaviors.ex-hour-as-string', []).directive('exHourAsString', HourAsStringDirective)
    .config($mdpTimePickerDecorator);

/* @ngInject */
function HourAsStringDirective(): IDirective {
    return {
        restrict: 'A',
        bindToController: true,
        controller: () => {},
        require: 'ngModel',
        link
    };

    function link(scope: IHourAsStringScope, element: JQuery, attrs: IAttributes, ngModelCtrl: INgModelController) {
        const format = scope.vm.format;
        const API_DATE_FORMAT = 'HH:mm:ss';
        const input = element.find('input');

        // On remplace le validation qui valide le viewValue à la place du model...
        ngModelCtrl.$validators.format = function(modelValue) {
            return !ngModelCtrl.$viewValue || moment(modelValue, API_DATE_FORMAT, true).isValid();
        };

        // Permet de contourner le parsing strict du composant
        ngModelCtrl.$parsers.unshift((val: any)=> {
            const cleanedValue = String(val).replace(/[^0-9]/g, ':');
            const m = moment(cleanedValue, format);
            return m.isValid() ? m.format(format) : null;
        });

        // Permet d'utiliser un format string à la place d'un format date
        ngModelCtrl.$parsers.push((val: any)=> {
            const m = moment(val, format);
            return m.isValid() ? m.format(API_DATE_FORMAT) : null;
        });

        input
            // On enlève les évènements sur mdp-time-picker qui interèrent avec notre composant
            .off('reset input')
            .on('blur', onBlurHandler);

        ngModelCtrl.$formatters.push((val: any)=> {
            if (val) {
                if (!isNaN(Date.parse(val)) && val.indexOf('T') !== -1) {
                    val = val.split('T').pop();
                }

                const m = moment(val, format);
                return m.isValid() ? m.toDate() : val;
            }

            return val;
        });

        element.on('$destroy', () => {
            input.off('blur', onBlurHandler)
        });

        function onBlurHandler() {
            const value = String(input.val());
            let cleanedValue = value.replace(/[^0-9]/g, ':');

            // Accepte les formats du genre 730 qui sont interprétés comme 73:00 par défaut
            if (cleanedValue.length && !cleanedValue.includes(':')) {
                const heuresNombre = Number(cleanedValue.slice(0, 2));
                const heuresMinutes = Number(cleanedValue.slice(1));
                if (heuresNombre >= 24 && heuresMinutes < 60) {
                    cleanedValue = cleanedValue.slice(0, 1) + ':' + cleanedValue.slice(1)
                }
            }

            const date = moment(cleanedValue, format);


            if (date.isValid()) {
                const formattedValue = date.format(format);
                if (value !== formattedValue) {
                    input.val(formattedValue);
                    ngModelCtrl.$setViewValue(formattedValue);
                }
            }
        }
    }
}

/* @ngInject */
function $mdpTimePickerDecorator($provide: IProvideService) {
    /* @ngInject */
    $provide.decorator('$mdpTimePicker', function ($delegate: any) {
        const API_DATE_FORMAT = 'HH:mm:ss';

        // Il faut que la valeur passée à l'horloge soit sous format date
        return function (currentDate: string, options: any) {
            const date = moment(currentDate, API_DATE_FORMAT, true);
            const value = date.isValid() ? date.toDate() : null;
            return $delegate(value, options);
        };
    });
}
