import {module} from 'angular';
import {IDataType, IDataTypeClass, IDataTypeOptions} from './data-type.service';

export interface IBoutonRadioDataTypeClass {
    new(options?: IBoutonRadioDataTypeOptions): IBoutonRadioDataType;
}

export interface IBoutonRadioDataTypeOptions extends IDataTypeOptions {
}

export interface IBoutonRadioDataType extends IDataType {
}

export default module('core.services.bouton-radio-data-type', []).factory('BoutonRadioDataType', BoutonRadioDataTypeFactory);

/* @ngInject */
function BoutonRadioDataTypeFactory(DataType: IDataTypeClass): IBoutonRadioDataTypeClass {
    class BoutonRadioDataType extends DataType implements IBoutonRadioDataType {
        readonly template: string = `{{::vm.col | exRadio : vm.data : vm.dataType : vm.libelles}}`;
    }

    return BoutonRadioDataType;
}
