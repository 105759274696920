/**
 * Composant représentant la liste des étapes d'un cycle
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import CycleListEtapesController from './ex-cycle-list-etapes.controller';

export default module('app.components.ex-cycle-list-etapes', []).component('exCycleListEtapes', CycleListEtapesFactory());

function CycleListEtapesFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.MONO_OCCURRENCE
        ),
        require: {
            ecranContextCtrl: '^^exEcranContext'
        },
        controller: CycleListEtapesController,
        controllerAs: 'vm',
        template: require('./ex-cycle-list-etapes.html')
    };
}
