import {IAugmentedJQuery, IComponentController, IFormController, IScope, ITimeoutService} from 'angular';
import {IDataType} from '../../services/data-types/data-type.service';
import {IFilterLibelle} from '../../filters/ex-libelle.filter';
import * as moment from 'moment';
import {IKeyCodes} from '../../constants/key-codes.constant';
import {ISchemaItem} from "../../interfaces/schemas.interface";
import {IComposantInputDateHour} from "../ex-input-date-hour/ex-input-date-hour.controller";

export interface IComposantInputHour extends IComponentController {
    nameElement: string;
    formCtrl: IFormController;
    value: any;
    required: boolean;
    dataType: IDataType;
    format: string;
    formatMessage: string;
    schemaItem: ISchemaItem;
    inputDateHourCtrl: IComposantInputDateHour;
    change(valeurs: { $value: string }): void;
    onChange(): void;
}

/* @ngInject */
export default function InputHourController($scope: IScope,
                                            $element: IAugmentedJQuery,
                                            $timeout: ITimeoutService,
                                            exLibelleFilter: IFilterLibelle,
                                            keyCodes: IKeyCodes) {
    const vm: IComposantInputHour = this;
    const CLOCK_BUTTON_SELECTOR = '.md-icon-button';

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onChange = onChange;

    function $onInit() {
        vm.format = getFormat();
        vm.formatMessage = getFormatMessage();

        // Pour les heures en format "date"
        if (typeof vm.value === 'string' && moment(vm.value).isValid()) {
            vm.value = moment(vm.value).format(vm.format);
        }

        // On attend que le formulaire soit accessible
        $scope.$applyAsync(() => {
            vm.formCtrl[vm.nameElement].$validators.required = function (value: any) {
                return !vm.required || value;
            };

            $scope.$watch('vm.required', (value: boolean) => {
                if (!value || vm.formCtrl[vm.nameElement].$dirty) {
                    // On valide le champ dans le cas où l'option required est modifiée
                    vm.formCtrl[vm.nameElement].$validate();
                }
            });

            // Lorsque le champ a déjà une valeur, on veut afficher les erreurs de validations déjà existantes
            if (vm.formCtrl[vm.nameElement].$viewValue) {
                vm.formCtrl[vm.nameElement].$validate();
                if (vm.formCtrl[vm.nameElement].$invalid) {
                    vm.formCtrl[vm.nameElement].$setTouched();
                    vm.formCtrl[vm.nameElement].$setDirty();
                }
            }
        });

        // On ne veut pas taber sur le bouton d'ouverture
        $timeout(() => {
            $element.find(CLOCK_BUTTON_SELECTOR)
                .attr('tabindex', -1);
        });

        // On ajoute un raccourci pour l'ouverture du calendrier
        $element.on('keydown', (event: JQueryEventObject) => {
            const shouldOpen = (
                (event.which === keyCodes.UP || event.which === keyCodes.DOWN) &&
                !event.shiftKey &&
                !event.ctrlKey &&
                !event.metaKey
            );

            if (shouldOpen) {
                $element.find(CLOCK_BUTTON_SELECTOR).click();
            }
        });
    }

    function $onDestroy() {
        $element.off();
    }

    function getFormat() {
        const format = ['HH'];

        if (vm.dataType.params.minutes) {
            format.push('mm');
        }

        if (vm.dataType.params.secondes) {
            format.push('ss');
        }

        return format.join(':');
    }

    function onChange() {
        if (((vm.schemaItem && vm.schemaItem.type === 'date') || vm.dataType.params.dateFormat) && !vm.inputDateHourCtrl) {
            if (vm.value && vm.value.length) {
                //On ajuste le format selon le dataType du schema.
                const [heures, minutes, secondes] = vm.value.split(':');
                vm.value = moment(
                    new Date()
                        .setHours(Number(heures) || 0, Number(minutes) || 0, Number(secondes) || 0))
                    .format(`YYYY-MM-DDT${vm.format}`);
            }
        }
        vm.formCtrl[vm.nameElement].$validate();

        if ((!vm.value || vm.formCtrl[vm.nameElement].$valid) && vm.change) {
            vm.change({$value: vm.value})
        }
    }

    function getFormatMessage() {
        return exLibelleFilter('G_MSG_HEURE_INVALIDE').replace('HH:MM', vm.format.toUpperCase());
    }
}
