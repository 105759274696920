import {IComponentOptions, module} from 'angular';
import CardController from './exp-card.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components-experimental.card', [])
    .component('exExpCard', CardComponent());

function CardComponent(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.LIBELLES.OPTIONAL,
            Binding.MENU.OPTIONAL,
            {
                contentClass: '<?',
                noHeader: '<?',
                iconTitre: '@?',
                lblTitre: '@?',
                color: '@?'
            }
        ),
        transclude: {
            actions: '?exExpCardActions',
            content: 'exExpCardContent',
            titre: '?exExpCardTitre'
        },
        require: {
            monoOccurrenceCtrl: '?^exMonoOccurrence',
            dataSourceCtrl: '?^exDataSource'
        },
        controller: CardController,
        controllerAs: 'vm',
        template: require('./exp-card.html')
    };
}
