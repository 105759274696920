import {IAttributes, IDirective, INgModelController, IScope, module} from 'angular';
import * as moment from 'moment/moment';

export default module('core.behaviors.ex-date-as-string', []).directive('exDateAsString', DateAsStringDirective);

/* @ngInject */
function DateAsStringDirective(): IDirective {
    const DATE_FORMAT = 'YYYY-MM-DD';

    return {
        restrict: 'A',
        bindToController: true,
        controller: () => {},
        require: 'ngModel',
        link
    };

    function link(scope: IScope, element: JQuery, attrs: IAttributes, ngModelCtrl: INgModelController) {
        ngModelCtrl.$parsers.push((val: any)=> {
            const m = moment(val, DATE_FORMAT);
            return m.isValid() ? m.format(DATE_FORMAT) : null;
        });

        ngModelCtrl.$formatters.push((val: any)=> {
            if (val) {
                const m = moment(val, DATE_FORMAT);
                return m.isValid() ? m.toDate() : val;
            }

            return val;
        });
    }
}
