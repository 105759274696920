import {IComponentOptions, module} from 'angular';

import SommaireController from './ex-sommaire.controller';

export default module('core.components.ex-sommaire', []).component('exSommaire', SommaireComponent());

function SommaireComponent(): IComponentOptions {
    return {
        require: {
            monoOccurrenceEcranCtrl: '^^exMonoOccurrenceEcran'
        },
        controller: SommaireController,
        controllerAs: 'vm',
        template: require('./ex-sommaire.html')
    };
}
